import React, { memo, useState } from 'react';
import { YES } from '../../../shared/globalConstants';
import { toTitleCase } from '../../../shared/utils/globalUtils';
import { formatPhoneNumber } from '../global-header/contact-us/contactUsUtils';
import './FindYourDoctorCard.scss';
import { splitSpecialisations } from './findYourDoctorCardUtils';

const FindYourDoctorCard: React.FC<FindYoourDoctorCardProps> = ({
  index,
  provider,
  showSelect,
  showDelete,
  isSelected,
  isSaved,
  labels,
  onSelectionChange
}) => {
  const [showAdditional, setShowAdditional] = useState(false);
  const { providerName, specialtyList, address } = provider;

  const getAdditionalSpecialisations = (additionalSpecialisations: Speciality[]) => {
    if (additionalSpecialisations.length > 0) {
      return (
        <div className="additional-specialities">
          {showAdditional &&
            additionalSpecialisations.map((speciality) => {
              return (
                <span key={speciality.specialtyId} id="doctor-card-speciality" className="speciality-name">
                  {speciality.specialtyName}
                </span>
              );
            })}
          <span id="doctor-card-show-more-less" className="show-more-label" onClick={() => setShowAdditional(!showAdditional)}>
            {showAdditional ? labels.LESS : labels.MORE}
          </span>
        </div>
      );
    }

    return <div />;
  };

  const renderSpecialities = () => {
    const { initial, additional } = splitSpecialisations(specialtyList);
    return (
      <div className="speciality-container">
        {initial.map((speciality) => {
          return (
            <span key={speciality.specialtyId} id="doctor-card-speciality" className="speciality-name">
              {speciality.specialtyName}
            </span>
          );
        })}

        {getAdditionalSpecialisations(additional)}
      </div>
    );
  };

  return (
    <div className="card-wrapper">
      {showSelect && (
        <input
          className="right-xs"
          id="doctor-select"
          value={''}
          type="checkbox"
          checked={isSelected}
          disabled={isSaved ? isSaved : false}
          onClick={() => {
            const status = isSelected ? isSelected : false;

            onSelectionChange(provider, status);
          }}
          onChange={() => { }}
          data-testid="doctor-select"
        />
      )}
      <div className="details-container">
        <div>
          <h4 className="provider-name">
            {toTitleCase(providerName)}
          </h4>
          {renderSpecialities()}
          <div className="new-patient-label-container">
            {provider.acceptsNewPatients === YES && <span id="doctor-card-new-patient-label">{labels.ACCEPT_NEW_PATIENT}</span>}
          </div>
        </div>
        <div className="contact-container">
          <div className="address-container">
            <div className="icon-padding">
              <span id="find-your-doctor-location-icon" className="sae-icon sae-icon-marker location-icon right-xxs" />
            </div>
            <div className="address-value-container">
              <span id="doctor-card-address">{address.addressOne}</span>
              <span id="doctor-card-address">{address.addressTwo}</span>
            </div>
          </div>
          <div className="address-container padding-top">
            <div className="icon-padding">
              <span id="find-your-doctor-phone-icon" className="sae-icon sae-icon-phone location-icon right-xxs" />
            </div>

            <span id="doctor-card-address" className="phone-number">
              {formatPhoneNumber(provider.address.phone)}
            </span>
          </div>
        </div>
        {address.distance !== undefined ? (
          <div className="distance-container">
            <span id="doctor-card-address">{`${address.distance} ${labels.MILES}`}</span>
          </div>
        ) : (
          <div className="distance-container"></div>
        )}
      </div>
      {showDelete && (
        <div
          className="delete-container"
          onClick={() => {
            onSelectionChange(provider, true);
          }}
        >
          <span id="find-your-doctor-form-heading" className="sae-icon sae-icon-trash delete-icon right-xxs"></span>
          {labels.REMOVE}
        </div>
      )}
    </div>
  );
};

export default memo(FindYourDoctorCard);
