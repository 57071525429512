const ALPHA_NUMERIC_REGX: RegExp = /^[a-zA-Z0-9@]+$/;
const INPUT_TYPES = {
  USERNAME: 'USERNAME',
  PASSWORD: 'PASSWORD'
};
const LOGIN_CONSTANTS = {
  APP: 'ACA',
  MARKET_SEGMENT: 'UNDER_65',
  ROLE: 'SHOPPER' //May change
};
const LOGIN_RESPONSE_CODES = {
  AUTHENTICATION_SUCCESSFULL: 'USERLOGIN000',
  AUTHENTICATION_FAILED: 'USERLOGIN001',
  ACCOUNT_LOCKED: 'USERLOGIN002',
  ACCOUNT_DISABLED: 'USERLOGIN003',
  INVALID_USER_ROLE: 'USERLOGIN004', //Need to understand what is this
  NOT_A_SHOPPER_OR_A_MEMBER: 'USERLOGIN005',
  ACCOUNT_DOES_NOT_EXIST: 'USERLOGIN006',
  TWO_FA_NEEDED: 'USERLOGIN015',
  CONTACT_DETAILS_UNAVAILABLE: 'USERLOGIN017',
  MAXIMUM_AMOUNT_REACHED: 'USERLOGIN018',
  TWO_FA_NEEDED_CONTACT_MISSIG: 'USERLOGIN019',
  LOGIN_THREAT_DETECTED: 'USERLOGIN099',
  SYSTEM_EXCEPTION: 'USERLOGIN100',
  MEMBER_INFO_SUCCESS: ['MEMBERINFO000', 'MEMBERINFO003']
};
const USER_AUTHENTICATED_RESPONSE_CODES = [LOGIN_RESPONSE_CODES.AUTHENTICATION_SUCCESSFULL, LOGIN_RESPONSE_CODES.TWO_FA_NEEDED];

export { ALPHA_NUMERIC_REGX, INPUT_TYPES, LOGIN_CONSTANTS, LOGIN_RESPONSE_CODES, USER_AUTHENTICATED_RESPONSE_CODES };
