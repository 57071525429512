import { AxiosError, isAxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HTTP_STATUS_CODES, STATUSES } from '../../../api/constants';
import { generateTokenAPI, getBuildVersion } from '../../../api/services/tokenService';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import { NAV_PATHS } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { USER_AUTHENTICATED_RESPONSE_CODES } from '../login/login-modal/constants';
import { getInProgressApplication, getLoginSuccessPayload, getNavigationInfo, getWebAccount, populateUserDetails } from '../login/login-modal/loginUtils';
import { getMemberSSOInfo } from './MemberSSOLoginService';

const MemberSSOLogin: React.FC = () => {
    const { updateRole, updateLoginResponse, updateLoginStatus, updateWebAccount, updateFavourites, updateDemographicInfo, updateQuotes } = useUserStore((state) => state);
    const { updateMemberInfo, updateCurrentCoverageMedicalPlan, updateCurrentCoverageDentalPlan, updateCurrentCoverageVisionPlan, updateCoverageType, updateZipCode,
        updateAgentInfo, updateZipCodeResponse, updateZipCodeFormDetails, updatePlanTypes, updateApplicantFormDetails, updateSelectedPlan, updateSelectedDentalPlan,
        updateSelectedVisionPlan, updateFavouriteMedicalPlans, updateFavouriteDentalPlans, updateFavouriteVisionPlans } = useGlobalStore((state) => state);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState({
        hasError: false,
        responseCode: '',
        message: ''
    });
    const initiate = async () => {
        setLoading(true);
        const isSuccess = await getToken();
        if (isSuccess) {
            getMemberSSOInfo().then((response) => {
                const data: LoginResponse = response.data;
                if (response.status === HTTP_STATUS_CODES.SUCCESS && data.status === STATUSES.SUCCESS) {
                    const { message, responseCode } = data.responseMessage;
                    if (USER_AUTHENTICATED_RESPONSE_CODES.includes(responseCode)) {
                        handleUserLogin(data);
                    } else {
                        setLoginError({
                            hasError: true,
                            responseCode: responseCode,
                            message: message
                        });
                        setLoading(false);
                    }
                } else {
                    handleLoginServerError(null);
                }
            }).catch(error => {
                console.error('error', error);
                setLoginError({
                    hasError: true,
                    responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
                    message: `<b>Something went wrong. Please try again later.</b> `
                });
            });
        }
    };

    useEffect(() => {
        initiate();
    }, []);

    const getToken = async (): Promise<boolean> => {
        let returnVal = false;
        try {
            const response = await getBuildVersion();
            if (response.status === 200) {
                try {
                    const tokenResponse = await generateTokenAPI();
                    if (tokenResponse?.data?.token) {
                        useUserStore.getState().updateJWTToken({ jwtToken: tokenResponse.data.token });
                        returnVal = true;
                    } else {
                        returnVal = false;
                        setTokenError(tokenResponse.data.responseMessage ? tokenResponse.data.responseMessage.responseCode : null, tokenResponse.data.responseMessage ? tokenResponse.data.responseMessage.message : null)
                    }
                } catch (error: any) {
                    returnVal = false;
                    setTokenError(error.response?.data?.error?.errorKey, error.response?.data?.error?.message);
                }
            }
        }
        catch (error: any) {
            returnVal = false;
            setTokenError(error.response?.data?.error?.errorKey, error.response?.data?.error?.message);
        }
        finally {
            return returnVal;
        }
    }

    const setTokenError = (responseCode, responseMessage) => {
        setLoginError({
            hasError: true,
            responseCode: responseCode || HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: responseMessage || `<b>Something went wrong. Please try again later.</b> `
        });

    }

    const handleLoginServerError = (error: AxiosError<LoginError> | null) => {
        if (isAxiosError(error)) {
            const errorObj = error.response?.data?.error;
            const errorKey = errorObj?.errorKey ?? '';
            const loginErrorObj = {
                hasError: true,
                responseCode: errorKey,
                message: 'Something went wrong. Please try again later.'
            };
            setLoginError(loginErrorObj);
        } else {
            setLoginError({
                hasError: true,
                responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
                message: `<b>Something went wrong. Please try again later.</b> `
            });
        }

        setLoading(false);
    };
    const handleUserLogin = async (data: LoginResponse) => {
        const loginSuccesspayload: LoginSuccessPayload = getLoginSuccessPayload(data, '');
        const updateWA = getWebAccount(data);
        updateWebAccount(updateWA);
        updateLoginResponse(loginSuccesspayload);
        try {
            await populateUserDetails(data);
        } catch (error) {
            console.warn('Error in MemberSSOLogin.tsx :', error);
            setLoginError({
                hasError: true,
                responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
                message: `<b>Something went wrong. Please try again later.</b> `
            });
            setLoading(false);
        }
        const inProgressApplications = await getInProgressApplication(data.loginResponse.webAccountGUID);
        if (inProgressApplications) {
            setLoading(false);
            navigate(NAV_PATHS.DASHBOARD)
        } else {
            const getURL = await getNavigationInfo(data.shopperProfile?.demographicInfo?.demographicId, useUserStore.getState().role);
            setLoading(false);
            navigate(getURL);
        }
    };


    return (<div>{(loading && !loginError.hasError) && <FullPageLoader />}
        {(!loading && loginError.hasError) && <div dangerouslySetInnerHTML={{ __html: loginError.message }}></div>}
    </div>)
}

export default MemberSSOLogin;