
import React from 'react';
import './PlanCard.scss';
const PlanCardInner = (props) => {
    const paddingValue = (props.membericon) ? "padding" : "no-padding";

    return (
        <div className="fwc-row top-sm plan-card">
            <div className="animate-desktop-only">

                <div className='fwc-card-circle'>
                    <div className={paddingValue}>
                        {props.membericon && (
                            <div className="circle-bg">
                                <div className="circle-white">
                                    <div className="circle-img" style={{ backgroundImage: `url(${props.membericon})` }}></div>
                                </div>
                            </div>)}
                        <div className='fwc-card-generic'>

                            {props.membertitle && (<h3 className={paddingValue + " content circle-card-header"}>{props.membertitle}</h3>)}
                            {props.memberbody && (<div className="circle-card-desc" dangerouslySetInnerHTML={{ __html: props.memberbody }}>
                            </div>)}
                            {props.primaryMemberLink && (
                                <span className="cta-wrapper center-stack ">
                                    <a target="_blank" data-analytics-name={props.membertitle} href={props.primaryMemberLink} rel="noreferrer noopener" className="fwc-btn fwc-btn-primary circle-card-cta-primary ctaBtn"> {props.primaryMemberLinkLabel}</a>
                                </span>
                            )}
                        </div>
                    </div>

                </div>
            </div>

        </div>

    );
};

const PlanCard = (props) => {
    return <PlanCardInner {...props} />;
};
export default PlanCard;
export const PlanCardIsEmpty = (props) => !props.membertitle && !props.memberbody;

