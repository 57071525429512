const UPDATE_SHOPPER_QUESTION_ANSWER_RESPONSE_CODES = {
  RESPONSE_CODE: 'UPDSHOPPERACCT000'
};

const REQUIRED_FIELD = 'REQUIRED_FIELD';
const ANSWERFIELDS = { answer1: '', answer2: '', answer3: '' };
const ANSWER_POSSIBLE_ERROR = {
  answer1: {
    isempty: false,
    minValueValidation: false,
    isSpaceAdded: false,
    specialCharacters: false,
    isQuestionAdded: false,
    id: 'answer1'
  },
  answer2: {
    isempty: false,
    minValueValidation: false,
    isSpaceAdded: false,
    specialCharacters: false,
    isQuestionAdded: false,
    id: 'answer2'
  },
  answer3: {
    isempty: false,
    minValueValidation: false,
    isSpaceAdded: false,
    specialCharacters: false,
    isQuestionAdded: false,
    id: 'answer3'
  }
};

const SECURITY_QUESTIONS = (Strings:any) => {
  const securityQuestions = [
  {
    questionId: '',
    label: Strings.DROPDOWN_LABEL,
    value: 'Select',
    isSelected: false
  },
  {
    questionId: '',
    label: Strings.QUESTION_1,
    value: Strings.QUESTION_1,
    isSelected: false
  },
  {
    questionId: '',
    label: Strings.QUESTION_2,
    value: Strings.QUESTION_2,
    isSelected: false
  },
  {
    questionId: '',
    label: Strings.QUESTION_3,
    value: Strings.QUESTION_3,
    isSelected: false
  },
  {
    questionId: '',
    label: Strings.QUESTION_4,
    value: Strings.QUESTION_4,
    isSelected: false
  },
  {
    questionId: '',
    label: Strings.QUESTION_5,
    value: Strings.QUESTION_5,
    isSelected: false
  },
  {
    questionId: '',
    label: Strings.QUESTION_6,
    value: Strings.QUESTION_6,
    isSelected: false
  },
  {
    questionId: '',
    label: Strings.QUESTION_7,
    value: Strings.QUESTION_7,
    isSelected: false
  },
  {
    questionId: '',
    label: Strings.QUESTION_8,
    value: Strings.QUESTION_8,
    isSelected: false
  },
  {
    questionId: '',
    label: Strings.QUESTION_9,
    value: Strings.QUESTION_9,
    isSelected: false
  },
  {
    questionId: '',
    label: Strings.QUESTION_10,
    value: Strings.QUESTION_10,
    isSelected: false
  }
];
return securityQuestions
}
const QUESTIONFIELDS = { question1: '', question2: '', question3: '' };
const SPECIAL_CHARACTERS = /[#%^&()+\-=\[\]{};':"\\,.<>`~_\/]+/;
export {
  ANSWERFIELDS,
  ANSWER_POSSIBLE_ERROR,
  QUESTIONFIELDS,
  REQUIRED_FIELD,
  SECURITY_QUESTIONS,
  SPECIAL_CHARACTERS,
  UPDATE_SHOPPER_QUESTION_ANSWER_RESPONSE_CODES
};

