import React from "react";
import './ToolTip.scss';

function ToolTip(props: any) {
    return (
        <div>
            <div className='sae-icon sae-icon-question-tip ht'>
                <span className="tooltipNew">{props.message}</span>
            </div>
        </div>
    );
}
export default ToolTip;