import { Button } from '@sydney-broker-ui/ios';
import React from 'react';

import { getAddressLine, getAgentFirstName, getAgentLastName, getAgentName, toTitleCase } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { formatPhoneNumber } from "../global-header/contact-us/contactUsUtils";
import './AgentDetailsModal.scss';

const AgentDetailsModal: React.FC<AgentDetailsModalProps> = ({ labels, onClose }) => {
    const { agentInfo, isPublicAgentConnect, deepLinkInfo } = useGlobalStore((state) => state)

    const agentName = getAgentName(agentInfo ?? {} as AgentOfRecord, deepLinkInfo.agentConnectData, isPublicAgentConnect)
    const agentFirstName = getAgentFirstName(agentInfo ?? {} as AgentOfRecord, deepLinkInfo.agentConnectData, isPublicAgentConnect);
    const agentLastName = getAgentLastName(agentInfo ?? {} as AgentOfRecord, deepLinkInfo.agentConnectData, isPublicAgentConnect);
    const agencyName = agentInfo?.agency ? agentInfo?.agency : agentInfo?.agencyName ?? null;
    const agencyConnectName = deepLinkInfo?.agentConnectData?.agency ? deepLinkInfo?.agentConnectData?.agency : null;


    return (
        <div className="agent-details-wrapper">
            {isPublicAgentConnect &&
                <div className="agent-details-inner-wrapper">
                    <div className="agent-details-header">
                        <div className="agent-avatar-div">
                            <span
                                className="agent-avatar-with-initials">{(deepLinkInfo?.agentConnectData?.firstName ?? '')?.charAt(0) + (agentInfo?.lastName ?? '')?.charAt(0)}</span>
                        </div>
                        <div className="agent-name-div">
                            <span className="agent-name">{agentName ?? labels?.NA}</span>
                            <span className="agent-desc">{agencyConnectName ? toTitleCase(agencyConnectName) : labels?.NA}</span>
                        </div>
                    </div>
                    <div className="agent-details-body">
                        <div className="agent-info-left-div">
                            <div className="agent-info-item agent-info-with-icon">
                                <span id="agent-phone-icon" className={'sae-icon sae-icon-phone agent-info-icon'} />
                                <div className="agent-info-col">
                                    <span className="agent-info-label">{labels?.PHONE}</span>
                                    <span
                                        className="agent-info-value">{deepLinkInfo?.agentConnectData?.phone ? formatPhoneNumber(deepLinkInfo?.agentConnectData?.phone) : labels?.NA}</span>
                                </div>
                            </div>
                            <div className="agent-info-item agent-info-with-icon">
                                <span id="agent-email-icon" className={'sae-icon sae-icon-message agent-info-icon'} />
                                <div className="agent-info-col">
                                    <span className="agent-info-label">{labels?.EMAIL}</span>
                                    <span
                                        className="agent-info-value agent-email">{deepLinkInfo?.agentConnectData?.email ? deepLinkInfo?.agentConnectData?.email : labels?.NA}</span>
                                </div>
                            </div>
                            <div className="agent-info-item agent-info-with-icon">
                                <span id="agent-location-icon" className={'sae-icon sae-icon-marker agent-info-icon'} />
                                <div className="agent-info-col">
                                    <span className="agent-info-label">{labels?.ADDRESS}</span>
                                    <span className="agent-info-value">
                                        {deepLinkInfo?.agentConnectData?.address.addressLine1 + ', ' + deepLinkInfo?.agentConnectData?.city + ', ' + deepLinkInfo?.agentConnectData?.state + ' ' + deepLinkInfo?.agentConnectData?.zip}</span>
                                </div>
                            </div>
                        </div>
                        <div className="agent-info-right-div">
                            <div className="agent-info-item">
                                <span className="agent-info-label">{labels?.LICENSE_NUMBER}</span>
                                <span
                                    className="agent-info-value">{deepLinkInfo?.agentConnectData?.agentId ? deepLinkInfo?.agentConnectData?.agentId : labels?.NA}</span>
                            </div>
                            <div className="agent-info-item">
                                <span className="agent-info-label">{labels?.NPN}</span>
                                <span className="agent-info-value">{deepLinkInfo?.agentConnectData?.npn ? deepLinkInfo?.agentConnectData?.npn : labels?.NA}</span>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {!isPublicAgentConnect &&
                <div className="agent-details-inner-wrapper">
                    <div className="agent-details-header">
                        <div className="agent-avatar-div">
                            <span
                                className="agent-avatar-with-initials">{(agentFirstName ?? '')?.charAt(0) + (agentLastName ?? '')?.charAt(0)}</span>
                        </div>
                        <div className="agent-name-div">
                            <span className="agent-name">{agentName ?? labels?.NA}</span>
                            <span className="agent-desc">{agencyName ? toTitleCase(agencyName) : labels?.NA}</span>
                        </div>
                    </div>
                    <div className="agent-details-body">
                        <div className="agent-info-left-div">
                            <div className="agent-info-item agent-info-with-icon">
                                <span id="agent-phone-icon" className={'sae-icon sae-icon-phone agent-info-icon'} />
                                <div className="agent-info-col">
                                    <span className="agent-info-label">{labels?.PHONE}</span>
                                    <span
                                        className="agent-info-value">{agentInfo?.phone ? agentInfo?.phone : labels?.NA}</span>
                                </div>
                            </div>
                            <div className="agent-info-item agent-info-with-icon">
                                <span id="agent-email-icon" className={'sae-icon sae-icon-message agent-info-icon'} />
                                <div className="agent-info-col">
                                    <span className="agent-info-label">{labels?.EMAIL}</span>
                                    <span
                                        className="agent-info-value agent-email">{agentInfo?.email ? agentInfo?.email : labels?.NA}</span>
                                </div>
                            </div>
                            <div className="agent-info-item agent-info-with-icon">
                                <span id="agent-location-icon" className={'sae-icon sae-icon-marker agent-info-icon'} />
                                <div className="agent-info-col">
                                    <span className="agent-info-label">{labels?.ADDRESS}</span>
                                    <span
                                        className="agent-info-value">{agentInfo?.address ? ` ${getAddressLine(agentInfo?.address.addressLine1, false)} ${getAddressLine(agentInfo?.address.city, false)} ${getAddressLine(agentInfo?.address.county, false)}
                                    ${getAddressLine(agentInfo?.address.state, false)} ${getAddressLine(agentInfo?.address.postalCode, true)}` : labels?.NA}</span>
                                </div>
                            </div>
                        </div>
                        <div className="agent-info-right-div">
                            <div className="agent-info-item">
                                <span className="agent-info-label">{labels?.LICENSE_NUMBER}</span>
                                <span
                                    className="agent-info-value">{agentInfo?.licenseNO ? agentInfo?.licenseNO : labels?.NA}</span>
                            </div>
                            <div className="agent-info-item">
                                <span className="agent-info-label">{labels?.NPN}</span>
                                <span className="agent-info-value">{agentInfo?.npn ? agentInfo?.npn : labels?.NA}</span>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="agent-modal-close-btn">
                <Button id="agent-details-modal-close" onClick={() => onClose()} >
                    {labels?.CLOSE}
                </Button>
            </div>
        </div>
    );
}

export default AgentDetailsModal;
