const ACCOUNT_RECOVERY_USE_CASES = {
  FORGOT_USERNAME: 'FORGOT_USERNAME',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD'
};

const REGEXS = {
  NAME: /^(?![_.,\d\s-])[-a-zA-Z\s]+(?<![-\s])$/,
  EMAIL: /^[\w.-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9]+)*(?:\.[a-zA-Z]+)$/,
  IDENTIFICATION_NUMBER: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]+$/
};

const INVALID_DATES = ['', null, undefined, 'Invalid date'];
const REQUIRED_FIELD = 'REQUIRED_FIELD';

const FORGOT_USERNAME_RESPONSE_CODES = {
  USER_FIND_SUCCESSFULL: 'FORGOTUSER000'
};

const FORGOT_PASSWORD_RESPONSE_CODES = {
  USER_FIND_SUCCESSFULL: 'FORGOTPWD000'
};

const FORGOT_USERNAME_ERROR_CODES = {
  INVALID_INPUTS: 'FORGOTUSER001'
};

const FORGOT_PASSWORD_ERROR_CODES = {
  INVALID_INPUTS: 'FORGOTPWD001',
  RESET_LINK_EXPIRED: 'VALIDATERESETPWDID001'
};

const VALIDATERESETPWDID_RESPONSE_CODES = {
  TWO_FA_NEEDED: 'VALIDATERESETPWDID000'
};

export {
  ACCOUNT_RECOVERY_USE_CASES,
  FORGOT_PASSWORD_ERROR_CODES,
  FORGOT_PASSWORD_RESPONSE_CODES,
  FORGOT_USERNAME_ERROR_CODES,
  FORGOT_USERNAME_RESPONSE_CODES,
  INVALID_DATES,
  REGEXS,
  REQUIRED_FIELD,
  VALIDATERESETPWDID_RESPONSE_CODES
};
