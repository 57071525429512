import React from 'react';

import './NoResultsFound.scss';

const NoResultsFound: React.FC<NoResultsFoundProps> = ({
  label,
  description,
  sublabel,
  hints,
}) => {
  return (
    <div className='fwc-col-12 sm-12 no-result-wrapper'>
      <h2 className='btm-xxs title'>
        <span
          id='find-your-doctor-form-heading'
          className='sae-icon sae-icon-error icon-color right-xxs'
        />
        {label}
      </h2>
      <p className='btm-sm'>{description}</p>
      <label className='fwc-label'>{sublabel}</label>
      <ul className='description-list'>
        {hints.map((hint) => (
          <li>{hint}</li>
        ))}
      </ul>
    </div>
  );
};

export default React.memo(NoResultsFound);
