import { STATE_VALUES } from '../../../../components/common/mac-redirection-popup/constants';

const NAVIGATION_POPUP_USECASES = {
  NOT_REGISTERED: 'NOT_REGISTERED',
  FORGOT_USERNAME: 'FORGOT_USERNAME',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD'
};

const ACCOUNT_RECOVERY_REDIRECTION_URLS = {
  [STATE_VALUES.NEW_YORK]: 'https://www.anthembluecross.com/forgot-username-password/',
  [STATE_VALUES.CALIFORNIA]: 'https://www.anthem.com/ca/forgot-username-password/',
  [STATE_VALUES.ALL_OTHER_STATES]: 'https://www.anthem.com/forgot-username-password/'
};

const REDIRECTION_URLS = {
  [NAVIGATION_POPUP_USECASES.NOT_REGISTERED]: {
    [STATE_VALUES.NEW_YORK]: 'https://www.anthembluecross.com/register/',
    [STATE_VALUES.CALIFORNIA]: 'https://www.anthem.com/ca/register/',
    [STATE_VALUES.ALL_OTHER_STATES]: 'https://www.anthem.com/register/'
  },
  [NAVIGATION_POPUP_USECASES.FORGOT_USERNAME]: ACCOUNT_RECOVERY_REDIRECTION_URLS,
  [NAVIGATION_POPUP_USECASES.FORGOT_PASSWORD]: ACCOUNT_RECOVERY_REDIRECTION_URLS
};

const MAC_LOGIN_WITH_CRED_URL_PARAMS = {
  PROPOSAL_ID: 'proposalID'
};

const MAC_LOGIN_WITH_CRED_REDIRECTION_USE_CASES = {
  HPA_PROPOSAL: 'HPA_PROPOSAL'
};

export { MAC_LOGIN_WITH_CRED_REDIRECTION_USE_CASES, MAC_LOGIN_WITH_CRED_URL_PARAMS, NAVIGATION_POPUP_USECASES, REDIRECTION_URLS };

