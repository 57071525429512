import React from 'react';
import FindYourDoctorCard from '../find-your-doctor-card/FindYourDoctorCard';
import { getFormHeaderClassName } from '../find-your-doctor-form/findYourDoctorFormUtils';

const FindYourDoctoSelectedDoctors: React.FC<SelectedDoctorsProps> = ({
  title,
  label1,
  label2,
  label3,
  selectedProviders,
  labels,
  useCase,
  onSelectionChange
}) => {
  return (
    <div className="fwc-col-12 sm-12">
      <h2 className="btm-xxs title">
        <span id="find-your-doctor-form-heading" className={getFormHeaderClassName(useCase)}></span>
        {title}
      </h2>
      <p className="btm-sm">{label1}</p>
      <p className="btm-sm">{label2}</p>
      <p className="btm-sm" dangerouslySetInnerHTML={{ __html: label3 }}></p>

      <div className="doctor-list-container">
        {selectedProviders.map((provider, index) => {
          return (
            <FindYourDoctorCard
              index={index}
              key={provider.providerIdentifier + provider.address.addressId}
              provider={provider}
              showSelect={false}
              showDelete={true}
              labels={labels}
              onSelectionChange={onSelectionChange}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FindYourDoctoSelectedDoctors;
