import React from 'react';
const ColumnCardsInner = (props) => {
  const classValue = (props.enableMemberCard === "true" && props.enableThinkingCard === "true" && props.enableMedicareCard === "true") ? "fwc-col-4 sm-4 md-4" :
    (props.enableMemberCard === "true" && props.enableThinkingCard === "true") || (props.enableMemberCard === "true" && props.enableMedicareCard === "true") || (props.enableThinkingCard === "true" && props.enableMedicareCard === "true") ? "fwc-col-6 sm-6 md-6" :
      "fwc-col-12 sm-12 md-12";

  return (
    <div className="fwc-row margin-top-zero margin-bottom-zero m-margin-top-zero m-margin-bottom-zero
               padding-top-zero padding-bottom-64 m-padding-top-zero m-padding-bottom-32">
      {props.enableMemberCard === "true" && (
        <div className={classValue}>
          <div className="plan-cards">
            <div className="margin-top-zero margin-bottom-zero m-margin-top-zero m-margin-bottom-zero tni-tile-wrapper text-n-image-card ">
              <div data-key-inputs="/_jcr_content/data/master.json" className="anthem-tni tni-tile tni-tile--com lazyload-fade-up animate-desktop-only      no-animation" data-loaded="true">
                <div className="animate-desktop-only fwc-anim-img-scaleIn-wrapper in-view" data-loaded="true">
                  <picture className="tni-tile__img lazyload-img-scalein animate-desktop-only no-animation" data-alt="Market Leadership image" data-loaded="true">

                    <noscript>
                      <img src={props.membericon} alt="image 1" className="tni-tile__img lazyload-img-scalein animate-desktop-only" />
                    </noscript>
                    <img src={props.membericon} alt="image 1" />

                  </picture>
                </div>
                <div className="tni-tile__content">
                  <h3 className="tni-tile__title" data-default-size="24px">{props.membertitle}</h3>
                  <div className="richTextEditor secondary" dangerouslySetInnerHTML={{ __html: props.memberbody }}>
                    {/* <p data-default-size="14px">Anthem’s leadership position across the Medicare, Medicaid, and Commercial markets creates synergy and opens new avenues for your growth.</p> */}

                  </div>
                  <div className="cta-wrapper ">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.enableThinkingCard === "true" && (
        <div className={classValue}>
          <div className="plan-cards">
            <div className="margin-top-zero margin-bottom-zero m-margin-top-zero m-margin-bottom-zero tni-tile-wrapper text-n-image-card ">
              <div data-key-inputs="/_jcr_content/data/master.json" className="anthem-tni tni-tile tni-tile--com lazyload-fade-up animate-desktop-only      no-animation" data-loaded="true">
                <div className="animate-desktop-only fwc-anim-img-scaleIn-wrapper in-view" data-loaded="true">
                  <picture className="tni-tile__img lazyload-img-scalein animate-desktop-only no-animation" data-alt="We’ve Added 91 Counties To Our Service Area " data-loaded="true">

                    <noscript>
                      <img src={props.thinkingicon} alt="Image 2" className="tni-tile__img lazyload-img-scalein animate-desktop-only" />
                    </noscript>
                    <img src={props.thinkingicon} alt="Image 2" /></picture>
                </div>
                <div className="tni-tile__content">
                  {/* <h3 className="tni-tile__title" id="e7ae9231-4afc-419f-b048-806f993d2540Card" data-default-size="24px">We’ve Added 91 Counties To Our Service Area </h3> */}
                  <h3 className="tni-tile__title" data-default-size="24px">{props.thinkingtitle} </h3>

                  <div className="richTextEditor secondary" dangerouslySetInnerHTML={{ __html: props.thinkingbody }}>
                    {/* <p data-default-size="14px">Our larger geographical footprint gives you more opportunities to grow your book of business.</p> */}


                  </div>

                  <div className="cta-wrapper ">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.enableMedicareCard === "true" && (
        <div className={classValue}>
          <div className="plan-cards">
            <div className="margin-top-zero margin-bottom-zero m-margin-top-zero m-margin-bottom-zero tni-tile-wrapper text-n-image-card ">
              <div data-key-inputs="/_jcr_content/data/master.json" className="anthem-tni tni-tile tni-tile--com lazyload-fade-up animate-desktop-only      no-animation" data-loaded="true">
                <div className="animate-desktop-only fwc-anim-img-scaleIn-wrapper in-view" data-loaded="true">
                  <picture className="tni-tile__img lazyload-img-scalein animate-desktop-only no-animation" data-alt="Customer Experience image" data-loaded="true">

                    <noscript>
                      <img src={props.medicareicon} alt="Image 3" className="tni-tile__img lazyload-img-scalein animate-desktop-only" />
                    </noscript>
                    <img src={props.medicareicon} alt="Image 3" /></picture>
                </div>
                <div className="tni-tile__content">
                  <h3 className="tni-tile__title" data-default-size="24px">{props.medicaretitle}</h3>

                  <div className="richTextEditor secondary" dangerouslySetInnerHTML={{ __html: props.medicarebody }}>
                    {/* <p data-default-size="14px">Clearer, more descriptive product and benefit names help your clients avoid confusion and make your conversations more productive.</p> */}
                  </div>

                  <div className="cta-wrapper ">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ColumnCards = (props) => {

  return <ColumnCardsInner {...props} />;
};
export default ColumnCards;
export const ColumnCardsIsEmpty = (props) => props.membertitle == null || props.membertitle.trim().length === 0;

