const deleteInProgressDashboardCardPayload = (inProgressACN: string, cancelReason: string, partnerId: string, userId: string): deleteApplicationPayload => {
  return {
    acn: inProgressACN,
    cancelReason: cancelReason,
    partnerId: partnerId,
    userId: userId
  };
};

const getApplicationDashboardCardPayload = (acn: string, partnerId: string): getApplicationPayload => {
  return {
    acn: acn,
    partnerId: partnerId
  };
};

const setApplicationDashboardCardPayload = (partnerId: string, userId: string, shopperRole: string, data: getApplicationResponse): SetApplicationPayload => {
  return {
    partnerId: partnerId,
    user: {
      userId: userId,
      shopperRole: shopperRole
    },
    application: data.response.application,
    isSubmit: null
  };
};

const submitApplicationDashboardCardPayload = (partnerId: string, userId: string, acn: string): submitApplicationPayload => {
  return {
    partnerId: partnerId,
    userId: userId,
    acn: acn
  };
};

export { deleteInProgressDashboardCardPayload, getApplicationDashboardCardPayload, setApplicationDashboardCardPayload, submitApplicationDashboardCardPayload };
