import React from 'react';
import { getTitleIcon } from '../../../pages/public/provider-details/ProviderDetailsUtils';
import { PLAN_TYPES_NAMES } from '../../../shared/globalConstants';
import './addProduct.scss';

const AddProduct: React.FC<AddProductProps> = ({ labels,
    useCase,
    onAddProductClick,
}) => {

    return (
        <div className="add-product-container" onClick={(e) => onAddProductClick(e.currentTarget)}>

            <span id="add-product-icon"
                className={`sae-icon ${getTitleIcon(useCase === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? PLAN_TYPES_NAMES.DENTAL : PLAN_TYPES_NAMES.VISION)} add-product-icon`}></span>
            <div className="add-product-label-container">
                <span className="add-product-label">{useCase === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? labels.ADD_DENTAL_PLANS : labels.ADD_VISION_PLANS}</span>
                <span className="add-product-optional">{`(${labels.OPTIONAL})`}</span>
                <span className="add-product-plus-icon">+</span>
            </div>

        </div>
    )
}

export default AddProduct