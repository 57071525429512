import { EditableComponent, MapTo } from '@adobe/aem-react-editable-components';
import React from 'react';

import { withConditionalPlaceHolder } from '../core/util/withConditionalPlaceholder';
import { withStandardBaseCssClass } from '../core/util/withStandardBaseCssClass';
import PlanCard, { PlanCardIsEmpty } from './PlanCard';


const RESOURCE_TYPE = 'ios/components/plancard';

const EditConfig = {
    // Define your edit configuration here
    emptyLabel: 'Plan Card',
    isEmpty: PlanCardIsEmpty,
    resourceType: RESOURCE_TYPE
};
export const WrappedPlanCard = (props) => {
    const Wrapped = withConditionalPlaceHolder(withStandardBaseCssClass(PlanCard, 'plancard-container'), PlanCardIsEmpty, 'Plan Card');
    return <Wrapped {...props} />;
};

const EditablePlanCard = (props) => (
    <EditableComponent config={EditConfig} {...props}>
        <WrappedPlanCard />
    </EditableComponent>
);


MapTo(RESOURCE_TYPE)(EditablePlanCard);

export default EditablePlanCard;