import { MEDICAL_DEDUCTIBLE_SORT_OPTION, MONTHLY_COST_SORT_OPTION } from '../medical-plans/constants';

const DENTAL_PLAN_BENEFITS = {
  DEDUCTIBLE: 'DENTDEDUCTIBLE',
  ANNUAL_MAX: 'DENTANNUALMAX',
  OUT_OF_POCKET_MAX: 'DENTOOPMAX',
  DIAGNOSTICS_PREVENTIVE: 'DENTDIAGPREV'
};

const DEFAULT_DENTAL_CURRENT_COVERAGE: Contract = {
  contractCode: '',
  contractEffectiveDate: '',
  tiersType: '',
  onExchange: false,
  poPlan: false,
  coloradoOptions: false,
  contractExpiryDate: '',
  planYear: '',
  ehbIndTypes: [],
  planMarketingName: '',
  avValue: '',
  rateData: {
    rate: {
      totalPremium: 0.0,
      totSubsidizedPrem: 0.0,
      subsidyAmtApplied: 0.0
    }
  },
  plan: [
    {
      planID: '',
      planDisplayName: '',
      planName: '',
      planType: 'DENTAL',
      costCoverages: [],
      planBenefits: [
        {
          planBenefitDisplayName: 'Deductible ',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DENTAL',
          planBenefitSortOrder: 200,
          planBenefitName: 'Deductible ',
          planBenefitCode: 'DENTDEDUCTIBLE'
        },
        {
          planBenefitDisplayName: 'Annual Max',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DENTAL',
          planBenefitSortOrder: 201,
          planBenefitName: 'Annual Max',
          planBenefitCode: 'DENTANNUALMAX'
        },
        {
          planBenefitDisplayName: 'Out of Pocket Max',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DENTAL',
          planBenefitSortOrder: 202,
          planBenefitName: 'Out of Pocket Max',
          planBenefitCode: 'DENTOOPMAX'
        },
        {
          planBenefitDisplayName: 'Diagnostics & Preventive (Cleanings, Exams & X-rays)',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DENTAL',
          planBenefitSortOrder: 203,
          planBenefitName: 'Diagnostics & Preventive (Cleanings, Exams & X-rays)',
          planBenefitCode: 'DENTDIAGPREV'
        },
        {
          planBenefitDisplayName: 'Basic Restorative (Fillings)',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DENTAL',
          planBenefitSortOrder: 206,
          planBenefitName: 'Basic Restorative (Fillings)',
          planBenefitCode: 'DENTFILLNGS'
        },
        {
          planBenefitDisplayName: 'Periodontics (Scaling & Root Planing, Periodontal Surgery)',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DENTAL',
          planBenefitSortOrder: 207,
          planBenefitName: 'Periodontics (Scaling & Root Planing, Periodontal Surgery)',
          planBenefitCode: 'DENTSCLING'
        },
        {
          planBenefitDisplayName: 'Endodontic Treatment (Root Canal Therapy)',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DENTAL',
          planBenefitSortOrder: 208,
          planBenefitName: 'Endodontic Treatment (Root Canal Therapy)',
          planBenefitCode: 'DENTROOTCNL'
        },
        {
          planBenefitDisplayName: 'Oral Surgery (Extractions)',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DENTAL',
          planBenefitSortOrder: 209,
          planBenefitName: 'Oral Surgery (Extractions)',
          planBenefitCode: 'DENTEXTRACTION'
        },
        {
          planBenefitDisplayName: 'Prosthodontics (Bridges, Dentures)',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DENTAL',
          planBenefitSortOrder: 210,
          planBenefitName: 'Prosthodontics (Bridges, Dentures)',
          planBenefitCode: 'DENTPROSTHO'
        },
        {
          planBenefitDisplayName: 'Orthodontic (Braces and Retentions)',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DENTAL',
          planBenefitSortOrder: 211,
          planBenefitName: 'Orthodontic (Braces and Retentions)',
          planBenefitCode: 'DENTBRACES'
        }
      ],
      productType: ''
    }
  ],
  providerNetworkId: '',
  qHPVariation: '',
  qHPId: '',
  companyDesc: '',
  gradeLevels: [],
  qhpvariation: '',
  pcp: false
};

//Labels below are hard coded temporarily but will be made dynamic with AEM integration

const DENTAL_SORT_OPTIONS = [MONTHLY_COST_SORT_OPTION, MEDICAL_DEDUCTIBLE_SORT_OPTION];

export { DEFAULT_DENTAL_CURRENT_COVERAGE, DENTAL_PLAN_BENEFITS, DENTAL_SORT_OPTIONS };
