import React from 'react';
import { EMEDDED_HEALTH_BENEFITS } from '../../../pages/public/medical-plans/constants';
import { PLAN_TYPES_NAMES } from '../../../shared/globalConstants';
import { getBlueCardBenefitValue, getSpecialistVisitBenefitValue } from '../product-card/productCardUtils';
import { getBenefitLabelFive, getBenefitLabelSeven, getBenefitLabelThree, getBenefitLabelTwo, getProductBenefitFiveDescription, getProductBenefitSevenDescription, getProductBenefitThreeDescription, getProductBenefitTwoDescription } from './productBenefits.utils';

import './ProductBenefits.scss';

const ProductBenefits: React.FC<ProductBenefitsProps> = ({ labels, productType, contract, showMore, isWellpoint }) => {
    const [plan] = contract.plan;

    const getPlanIncudes = () => {
        if (contract?.ehbIndTypes && contract?.ehbIndTypes.includes(EMEDDED_HEALTH_BENEFITS.DEN_EHB)) {
            return (
                <div className='embedded-bnenfits'>
                    <span
                        id={'product-card-benefit-plan-type-row-one-value-' + contract.contractCode}
                        className="benefit-label"
                    >{labels.EMBEDDED + '\n'}</span>
                    <span
                        id={'product-card-benefit-plan-type-row-two-value-' + contract.contractCode}
                        className="benefit-label"
                    >{labels.PEDIATRICS_BENEFITS}</span>
                </div>
            )
        }
    }

    return (
        <div className="benefits-container">
            <div className="benefit-column plan-includes">
                <span id="plan-card-benefit-deductible" className="benefit-bold-label">
                    {labels.PLAN_INCLUDES}
                </span>
                {getPlanIncudes()}
            </div>
            <div className="benefit-column deductible">
                <div className="benefit-row">
                    <span id="plan-card-benefit-primary-care-visit" className="benefit-bold-label">
                        {getBenefitLabelTwo(labels, productType)}
                    </span>
                    <div
                        id={'product-card-benefit-deductible-value-' + contract.contractCode}
                        className="benefit-label"
                        dangerouslySetInnerHTML={{ __html: getProductBenefitTwoDescription(plan, showMore) }}
                    />
                </div>
                <div className="benefit-row">
                    <span id="plan-card-benefit-primary-care-visit" className="benefit-bold-label">
                        {getBenefitLabelThree(labels, productType)}
                    </span>
                    <div
                        id={'product-card-benefit-out-of-pocket-value-' + contract.contractCode}
                        className="benefit-label"
                        dangerouslySetInnerHTML={{ __html: getProductBenefitThreeDescription(plan, showMore) }}
                    />
                </div>
                {!isWellpoint && productType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT && <div className="benefit-row">
                    <span id="plan-card-benefit-primary-care-visit" className="benefit-bold-label">
                        {labels.BLUE_CARD}
                    </span>
                    <div
                        id={'product-card-blue-card-value-' + contract.contractCode}
                        className="benefit-label"
                        dangerouslySetInnerHTML={{ __html: getBlueCardBenefitValue(plan, showMore) }}
                    />
                </div>
                }
            </div>
            <div className="benefit-column primary-care">
                <div className="benefit-row">
                    <span id="plan-card-benefit-preventive-care" className="benefit-bold-label">
                        {getBenefitLabelFive(labels, productType)}
                    </span>
                    <div
                        id={'product-primary-care-value-' + contract.contractCode}
                        className="benefit-label"
                        dangerouslySetInnerHTML={{ __html: getProductBenefitFiveDescription(plan, showMore) }}
                    />
                </div>
                {productType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT && <div className="benefit-row">
                    <span id="plan-card-benefit-preventive-care" className="benefit-bold-label">
                        {labels.SPECIALIST_VISIT}
                    </span>
                    <div
                        id={'product-specialist-visit-' + contract.contractCode}
                        className="benefit-label"
                        dangerouslySetInnerHTML={{ __html: getSpecialistVisitBenefitValue(plan, showMore) }}
                    />
                </div>
                }

            </div>
            <div className="benefit-column tier-drugs">
                <span id="plan-card-benefit-preventive-care" className="benefit-bold-label">
                    {getBenefitLabelSeven(labels, productType)}
                </span>
                <div
                    id={'product-tier-one-drug-' + contract.contractCode}
                    className="benefit-label"
                    dangerouslySetInnerHTML={{ __html: getProductBenefitSevenDescription(plan, showMore) }}
                />
            </div>
        </div>
    )
}

export default ProductBenefits