import { StateCreator } from 'zustand';

import { VISION_SORT_OPTIONS } from '../pages/public/vision-plans/constants';

export const createVisionPlansSlice: StateCreator<VisionPlansSlice> = (set, get) => ({
  visionPlans: [],
  comparedVisionPlans: [],
  selectedVisionPlan: {
    plan: null,
    applicants: [],
    matchedProviders: []
  },
  visionSortOptions: VISION_SORT_OPTIONS,
  favouriteVisionPlans: [],
  showVisionFavourites: false,
  currentCoverageVisionPlan: {} as Contract,
  mappedVisionContractCode: '',
  visionRecommendations: {
    recommendedPlanContractCodes: [],
    originalVisionPlans: []
  },
  updateVisionPlans: (plans: Contracts) => set(() => ({ visionPlans: plans })),
  updateComparedVisionPlans: (plans: ComparedPlans) => set(() => ({ comparedVisionPlans: plans })),
  updateSelectedVisionPlan: (selectedPlan: SelectedPlan) => set(() => ({ selectedVisionPlan: selectedPlan })),
  updateVisionSortOptions: (sortOptions: SortOption[]) => set(() => ({ visionSortOptions: sortOptions })),
  updateFavouriteVisionPlans: (favouritePlansContractCodes: string[]) => set(() => ({ favouriteVisionPlans: favouritePlansContractCodes })),
  updateShowVisionFavourites: (value: boolean) => set(() => ({ showVisionFavourites: value })),
  updateCurrentCoverageVisionPlan: (plan: Contract, mappedContractCode: string) =>
    set(() => ({ currentCoverageVisionPlan: plan, mappedVisionContractCode: mappedContractCode })),
  resetVisionPlanStates: () =>
    set(() => ({
      visionPlans: [],
      comparedVisionPlans: [],
      selectedVisionPlan: {
        plan: null,
        applicants: [],
        matchedProviders: []
      },
      visionSortOptions: VISION_SORT_OPTIONS,
      favouriteVisionPlans: [],
      showVisionFavourites: false,
      visionRecommendations: {
        recommendedPlanContractCodes: [],
        originalVisionPlans: []
      }
    })),
  resetCurrentCoverageVisionPlan: () =>
    set(() => ({
      currentCoverageVisionPlan: {} as Contract,
      mappedVisionContractCode: ''
    })),
  updateVisionRecommendations: (recommendedPlanContractCodes: string[], originalVisionContracts: Contracts) =>
    set(() => ({ visionRecommendations: { recommendedPlanContractCodes: recommendedPlanContractCodes, originalVisionPlans: originalVisionContracts } }))
});
