import { EditableComponent, MapTo } from '@adobe/aem-react-editable-components';
import React from 'react';

import { withConditionalPlaceHolder } from '../core/util/withConditionalPlaceholder';
import { withStandardBaseCssClass } from '../core/util/withStandardBaseCssClass';
import ColumnCards, { ColumnCardsIsEmpty } from "./ColumnCards";

const RESOURCE_TYPE = 'ios/components/column-cards';  

const EditConfig = {
    // Define your edit configuration here
    emptyLabel: 'Column Cards',
    isEmpty: ColumnCardsIsEmpty,
    resourceType: RESOURCE_TYPE
};
export const WrappedQuote = (props) => {
    const Wrapped = withConditionalPlaceHolder(withStandardBaseCssClass(ColumnCards, 'columncards-container'), ColumnCardsIsEmpty, 'Column Cards');
    return <Wrapped {...props} />;
  };
  
  const EditableColumnCards = (props) => (
    <EditableComponent config={EditConfig} {...props}>
      <WrappedQuote />
    </EditableComponent>
  );

  
MapTo(RESOURCE_TYPE)(EditableColumnCards);

export default EditableColumnCards;