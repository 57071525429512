import { PASSWORD_VALIDATION_CHECK, USERNAME_VALIDATION_CHECK } from './constants';

const hasPasswordContainsUsername = (username: string, password: string): boolean => {
  const lowerCaseUsername = username.toLowerCase();
  const lowerCasePassword = password.toLowerCase();

  for (let i = 0; i + 2 < lowerCaseUsername.length; i++) {
    const substring = lowerCaseUsername.substring(i, i + 3);
    if (lowerCasePassword.includes(substring)) {
      return true;
    }
  }
  return false;
};

const updateUserNameRules = (userName: string, pageContent: any) => {
  const updatedUserNameRules = USERNAME_VALIDATION_CHECK(pageContent).map((item) => {
    if (item.id !== 'rule4') {
      const updatedItem = Object.assign({}, item);
      updatedItem.regex.test(userName) ? (updatedItem.isPassed = true) : (updatedItem.isPassed = false);
      return updatedItem;
    } else {
      return item;
    }
  });
  return updatedUserNameRules;
};

const updatePasswordRules = (userName: string, password: string, pageContent: any) => {
  const updatedPasswordRules = PASSWORD_VALIDATION_CHECK(pageContent).map((item) => {
    if (item.id !== 'rule4') {
      const updatedItem = Object.assign({}, item);
      updatedItem.regex.test(password) ? (updatedItem.isPassed = true) : (updatedItem.isPassed = false);
      return updatedItem;
    } else if (item.id === 'rule4') {
      const updatedItem = Object.assign({}, item);
      if (hasPasswordContainsUsername(userName, password)) {
        updatedItem.isPassed = false;
      } else {
        updatedItem.isPassed = true;
      }
      return updatedItem;
    } else {
      return item;
    }
  });

  return updatedPasswordRules;
};

export { hasPasswordContainsUsername, updatePasswordRules, updateUserNameRules };
