import { Button, Modal } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCMSStaticContent } from '../../../../api/cms/cmsUtil';
import { HTTP_STATUS_CODES } from '../../../../api/constants';
import FullPageLoader from '../../../../components/common/full-page-loader/FullPageLoader';
import SuccessModalBody from '../../../../components/common/success-modal-body/SuccessModalBody';
import { useShopperContext } from '../../../../context/shopper-context/ShopperContext';
import { LANGUAGE_CODES, SCREEN_NAMES } from '../../../../shared/globalConstants';
import { useGlobalStore } from '../../../../store/globalStore';
import './ChangePassword.scss';
import { changeShopperPassword } from './changePasswordServices';
import { getChangePasswordPayload, hasPasswordContainsUsername } from './changePasswordUtils';
import { default as Content } from './changepassword.json';
import { CHANGE_SHOPPER_PASSWORD_RESPONSE_CODES, PASSWORD_VALIDATION_CHECK } from './constants';


const ChangePassword = ({ userName, password, webAccountGUID }: { userName: string; password: string; webAccountGUID: string }) => {
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const { brand, zipCodeRes } = useGlobalStore((store) => store);
  const [passwordRules, setPasswordRules] = useState<any>([]);
  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.CHANGE_PASSWORD);
      content = response.data.data.iospagecontentList.items[0].pageContent?.ChangePassword;
    } catch (error) {
      content = Content.data.iospagecontentList.items[0].pageContent.ChangePassword;
      return false;
    } finally {
      setPageContent(content);
      setContentLoaded(true);
      if (content) {
        setPasswordRules(PASSWORD_VALIDATION_CHECK(content));
      }
    }
    return true;
  }, []);

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    initiate();
  }, []);
  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('');
  const [updateShopperProfileInfoError, setUpdateShopperProfileInfoError] = useState({
    hasError: false,
    errorStatus: '',
    message: ''
  });
  const navigate = useNavigate();
  const { setUserPassword } = useShopperContext()

  const checkValidCurrentPassword = () => {
    if (currentPassword === password) {
      setCurrentPasswordError('');
      return true;
    } else {
      setCurrentPasswordError(pageContent?.ERROR_MESSAGES.CONFIRM_PASSWORD_MATCH);
      return false;
    }
  };
  const handleCurrentPassword = (e: { target: { value: React.SetStateAction<string> } }) => {
    setCurrentPasswordError('');
    setCurrentPassword(e.target.value);
  };

  const handlePasswordChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setNewPasswordError('');
    setNewPassword(e.target.value);
    const updatedPasswordRules = passwordRules.map((item) => {
      if (item.id !== 'rule4') {
        const updatedItem = Object.assign({}, item);
        updatedItem.regex.test(e.target.value.toString()) ? (updatedItem.isPassed = true) : (updatedItem.isPassed = false);
        return updatedItem;
      } else if (item.id === 'rule4') {
        const updatedItem = Object.assign({}, item);
        if (hasPasswordContainsUsername(userName, e.target.value.toString())) {
          updatedItem.isPassed = false;
        } else {
          updatedItem.isPassed = true;
        }
        return updatedItem;
      } else {
        return item;
      }
    });

    e.target.value.length > 0 ? setPasswordRules(updatedPasswordRules) : setPasswordRules(PASSWORD_VALIDATION_CHECK(pageContent));
  };

  const checkValidPassword = () => { };

  const handleConfirmPasswordChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setConfirmNewPasswordError('');
    setConfirmNewPassword(e.target.value);
  };

  const checkValidConfirmPassword = () => {
    if (confirmNewPassword === newPassword) {
      setConfirmNewPasswordError('');
      return true;
    } else {
      setConfirmNewPasswordError(pageContent?.ERROR_MESSAGES.CONFIRM_PASSWORD_MATCH);
      return false;
    }
  };

  const onClickOfCancelOption = (e) => {
    e.preventDefault();
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setNewPasswordError('');
    setCurrentPasswordVisible(false);
    setPasswordVisible(false);
    setPasswordRules(PASSWORD_VALIDATION_CHECK(pageContent));
    setConfirmNewPasswordError('');
    navigate(-1);

  };

  const onClickSaveOption = (e) => {
    e.preventDefault();
    const isPassedResultsPwd = passwordRules.map((item) => item.isPassed);
    if (!isPassedResultsPwd.includes(false) && checkValidConfirmPassword()) {
      newPasswordError || confirmNewPasswordError === ''
        ? setUserPassword(
          newPassword
        )
        : checkValidConfirmPassword();

      const payload: ChangeShopperPasswordPayload = getChangePasswordPayload(webAccountGUID, currentPassword, newPassword);
      changeShopperPassword(payload)
        .then((response) => {
          const data: ChangeShopperPasswordSucessResponse = response.data;
          if (
            response.status === HTTP_STATUS_CODES.SUCCESS &&
            data.responseMessage.responseCode === CHANGE_SHOPPER_PASSWORD_RESPONSE_CODES.RESPONSE_CODE
          ) {
            //alert('Password Changed');
            setShowSuccessModel(true);
          } else {
            const errorData: ChangeShopperPasswordFaliureResponse = response.data;
            setUpdateShopperProfileInfoError({
              hasError: true,
              errorStatus: errorData.status,
              message: errorData.error.errorMessage
            });

          }
        })
    }
  };

  function closeModal() {

    setShowSuccessModel(false);

  }

  return (!contentLoaded ? <FullPageLoader /> :
    <div className={'passwordContainer'}>
      <div className={'tab-title-container'}>
        <h3 id="tab-title">
          <span className="sae-icon sae-icon-lock-2 right-xxs"></span>
          {pageContent?.CREATE_USERNAME_PASSWORD}
        </h3>
      </div>
      <hr />

      <div className={'password-content'}>
        <div className="fwc-input">
          <label id="update-password-creation-currentpassword-label" className="fwc-label" htmlFor={'update-password-creation-currentpassword-input'}>
            {pageContent?.CURRENT_PASSWORD}
          </label>
          <div className="profile-password-field-wrapper">
            <input
              aria-label={'Create Username'}
              id="update-password-creation-username-input"
              type={currentPasswordVisible ? 'text' : 'password'}
              value={currentPassword}
              onChange={handleCurrentPassword}
              onBlur={() => checkValidCurrentPassword()}
            />
            <span
              id="update-show-password-icon"
              onClick={() => setCurrentPasswordVisible(!currentPasswordVisible)}
              className={currentPasswordVisible ? 'sae-icon sae-icon-visualy-impaired password-icon' : 'sae-icon sae-icon-eye password-icon'}
            />
          </div>
          {currentPasswordError && (
            <span id="update-login-modal-username-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
              {currentPasswordError}
            </span>
          )}
        </div>
        <div className="fwc-input">
          <label className="fwc-label" htmlFor={'update-password-creation-password-input'}>
            {pageContent?.PASSWORD}
          </label>
          <div className="profile-password-field-wrapper">
            <input
              aria-label={'Create Password'}
              id="update-password-creation-password-input"
              type={passwordVisible ? 'text' : 'password'}
              value={newPassword}
              onChange={handlePasswordChange}
              onBlur={() => checkValidPassword()}
            />
            <span
              id="update-show-password-icon"
              onClick={() => setPasswordVisible(!passwordVisible)}
              className={passwordVisible ? 'sae-icon sae-icon-visualy-impaired password-icon' : 'sae-icon sae-icon-eye password-icon'}
            />
          </div>
          {newPasswordError && (
            <span id="lupdate_ogin-modal-username-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
              {newPasswordError}
            </span>
          )}
        </div>
        <div className="btm-sm">
          {passwordRules.map((item) => {
            return (
              <div className="fwc-col-6 fwc-radio rule-checkbox-container">
                <span
                  className={
                    item.isPassed ? 'sae-icon sae-icon-circle-checked-inverse right-xxs rule-checkbox' : 'sae-icon sae-icon-circle right-xxs rule-checkbox'
                  }
                ></span>
                <label className="rule-validation-text">{item.label}</label>
              </div>
            );
          })}
        </div>
        <div className="fwc-input">
          <label id="update-password-creation-confirm-password-label" className="fwc-label" htmlFor={'update-password-creation-confirm-password-input'}>
            {pageContent?.CONFIRM_PASSWORD}
          </label>
          <input
            aria-label={'Confirm Password'}
            id="update-password-creation-confirm-password-input"
            type="password"
            value={confirmNewPassword}
            onChange={handleConfirmPasswordChange}
            onBlur={() => checkValidConfirmPassword()}
          />
          {confirmNewPasswordError.length > 0 && (
            <div className="fwc-row fwc-row-bm fwc-col-12">
              <div className="fwc-input">
                <span id="update-personal-info-lastname-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                  {' '}
                  {confirmNewPasswordError}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className={'fwc-flex j-center btm-md top-sm'}>
          <Button btnType="secondary" data-analytics="cancelBtnPasswordProfile" id="update-up-cancel" onClick={(e) => onClickOfCancelOption(e)}>
            {pageContent?.CANCEL_BUTTON}
          </Button>
          <Button id="update-up-save" data-analytics="saveBtnPasswordTabProfile" onClick={(e) => onClickSaveOption(e)}>
            {pageContent?.SAVE_BUTTON}
          </Button>
        </div>
      </div>
      <Modal open={showSuccessModel}>
        <Modal.Body>
          {showSuccessModel && <SuccessModalBody
            heading={pageContent?.UP_SUCCESS_MODAL.HEADING}
            description={pageContent?.UP_SUCCESS_MODAL.DESC}
            closeButtonText={pageContent?.UP_SUCCESS_MODAL.CB_TEXT}
            closeModal={() => {
              closeModal();
            }}
          />
          }
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ChangePassword;
