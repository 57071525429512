import { AxiosRequestConfig } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { getCMSStaticContent } from '../../../../api/cms/cmsUtil';
import FullPageLoader from '../../../../components/common/full-page-loader/FullPageLoader';
import { useShopperContext } from '../../../../context/shopper-context/ShopperContext';
import { LANGUAGE_CODES, SCREEN_NAMES, USER_DOMAINS, USER_NAME_LENGTH } from '../../../../shared/globalConstants';
import { useGlobalStore } from '../../../../store/globalStore';
import { getUser } from '../registrationServices';
import './PasswordCreation.scss';
import { PASSWORD_VALIDATION_CHECK, USERNAME_VALIDATION_CHECK } from './constants';
import { updatePasswordRules, updateUserNameRules } from './passwordCreationUtils';
import { default as Content } from './passwordcreation.json';
const PasswordCreation = ({
  nextClickToggle,
  setNextClickToggle,
  updateCredentialsStatus
}: {
  nextClickToggle: boolean;
  setNextClickToggle: () => void;
  updateCredentialsStatus: () => void;
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userNameError, setUserNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [userNameRules, setUserNameRules] = useState<any>();
  const [passwordRules, setPasswordRules] = useState<any>();
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);

  const { userPassword: password, setUserPassword } = useShopperContext()
  const { username: userName, updateUsername, zipCodeRes } = useGlobalStore((state) => state);

  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.PASSWORD_CREATION);
      let cmsResponse = response.data;
      content = cmsResponse.data.iospagecontentList.items[0].pageContent?.PasswordCreation;
    } catch (error) {
      content = Content?.data.iospagecontentList.items[0].pageContent?.PasswordCreation;

      return false;
    }
    finally {
      setPasswordRules(PASSWORD_VALIDATION_CHECK(content));
      setUserNameRules(USERNAME_VALIDATION_CHECK(content));
      setPageContent(content);
      setContentLoaded(true);
    }
    return true;
  }, []);

  useEffect(() => {
    initiate();
  }, []);

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    if (nextClickToggle) {
      validateUsernamePassword();
      setNextClickToggle();
    }
  }, [nextClickToggle]);

  useEffect(() => {
    if (userName && pageContent) {
      const updatedUserNameRules = updateUserNameRules(userName, pageContent)
      checkIsUniqueUserName(updatedUserNameRules);
    }
  }, [userName, pageContent])

  const handleUserNameChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setUserNameError('');
    updateUsername(e.target.value.toString());
    const updatedUserNameRules = updateUserNameRules(e.target.value.toString(), pageContent);
    e.target.value.length > 0 ? setUserNameRules(updatedUserNameRules) : setUserNameRules(USERNAME_VALIDATION_CHECK(pageContent));
  };

  useEffect(() => {
    if (password && pageContent) {
      const updatedPasswordRules = updatePasswordRules(userName, password, pageContent);
      setPasswordRules(updatedPasswordRules);
    }
  }, [password, pageContent, userName])


  const handlePasswordChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setPasswordError('');
    setUserPassword(e.target.value.toString());
    const updatedPasswordRules = updatePasswordRules(userName, e.target.value.toString(), pageContent);

    e.target.value.length > 0 ? setPasswordRules(updatedPasswordRules) : setPasswordRules(PASSWORD_VALIDATION_CHECK(pageContent));
  };

  const checkValidPassword = () => { };
  const checkIsUniqueUserName = (updatedUserNameRules?) => {
    const rules = updatedUserNameRules ? updatedUserNameRules : userNameRules;
    const header = {
      userName: userName,
      userRole: USER_DOMAINS.ROLE
    };
    const config: AxiosRequestConfig = {
      headers: header
    };

    getUser(config)
      .then((response) => {
        const updatedUserNameRules = rules.map((item) => {
          if (item.id === 'rule4') {
            const updatedItem = Object.assign({}, item);
            updatedItem.isPassed = false;
            return updatedItem;
          } else {
            return item;
          }
        });
        userName.length > 0 ? setUserNameRules(updatedUserNameRules) : setUserNameRules(USERNAME_VALIDATION_CHECK(pageContent));
      })
      .catch((error) => {
        console.warn('Get user error', error);
        const updatedUserNameRules = rules.map((item) => {
          if (item.id === 'rule4') {
            const updatedItem = Object.assign({}, item);
            updatedItem.isPassed = true;
            return updatedItem;
          } else {
            return item;
          }
        });
        userName.length > 0 ? setUserNameRules(updatedUserNameRules) : setUserNameRules(USERNAME_VALIDATION_CHECK(pageContent));
      });
  };

  const handleConfirmPasswordChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setConfirmPasswordError('');
    setConfirmPassword(e.target.value);
  };

  const checkValidConfirmPassword = () => {
    if (confirmPassword === password) {
      setConfirmPasswordError('');
      return true;
    } else {
      setConfirmPasswordError(pageContent?.ERROR_MESSAGES.CONFIRM_PASSWORD_MATCH);
      return false;
    }
  };

  const validateUsernamePassword = () => {
    if (userName === '') {
      setUserNameError(pageContent?.ERROR_MESSAGES.REQUIRED_FIELD);
    }
    if (password === '') {
      setPasswordError(pageContent?.ERROR_MESSAGES.REQUIRED_FIELD);
    }
    if (confirmPassword === '') {
      setConfirmPasswordError(pageContent?.ERROR_MESSAGES.REQUIRED_FIELD);
    }
    const isPassedResultsUserNm = userNameRules.map((item) => item.isPassed);
    const isPassedResultsPwd = passwordRules.map((item) => item.isPassed);
    if (!isPassedResultsUserNm.includes(false) && !isPassedResultsPwd.includes(false) && checkValidConfirmPassword()) {
      updateUsername(userName);
      setUserPassword(password);
      updateCredentialsStatus();
    }
  };

  return (!contentLoaded ? <FullPageLoader /> :
    <div className="content-wrapper">
      <div className="fwc-row fwc-row-bm">
        <h4 className="btm-xxs top-sm title">
          <span id="password-creation-create-username-password-title" className="sae-icon sae-icon-lock-2 right-xxs"></span>
          {pageContent?.CREATE_USERNAME_PASSWORD}
        </h4>
      </div>
      <div className="fwc-row fwc-row-bm div-container-gap">
        <div className="fwc-col-6">
          <div className="fwc-input">
            <label id="password-creation-username-label" className="fwc-label" htmlFor={'password-creation-username-input'}>
              {pageContent?.USERNAME}
            </label>
            <input
              aria-label={'Create Username'}
              id="password-creation-username-input"
              type="text"
              value={userName}
              onChange={handleUserNameChange}
              onBlur={() => checkIsUniqueUserName()}
              maxLength={USER_NAME_LENGTH}
            />
            {userNameError && (
              <span id="login-modal-username-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                {userNameError}
              </span>
            )}
          </div>
        </div>
        <div className="fwc-col-6 fwc-row-bm">
          <div className="fwc-input">
            <label className="fwc-label" htmlFor={'password-creation-password-input'}>
              {pageContent?.PASSWORD}
            </label>
            <div className="profile-password-field-wrapper">
              <input
                aria-label={'Create Password'}
                id="password-creation-password-input"
                type={passwordVisible ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                onBlur={() => checkValidPassword()}
              />
              <span
                id="show-password-icon"
                onClick={() => setPasswordVisible(!passwordVisible)}
                className={passwordVisible ? 'sae-icon sae-icon-visualy-impaired password-icon' : 'sae-icon sae-icon-eye password-icon'}
              />
            </div>
            {passwordError && (
              <span id="login-modal-username-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                {passwordError}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="fwc-row fwc-row-bm div-container-gap">
        <div className="fwc-col-6">
          {userNameRules.map((item) => {
            return (
              <div key={item.id} className="fwc-col-6 fwc-radio rule-checkbox-container">
                <span
                  className={
                    item.isPassed ? 'sae-icon sae-icon-circle-checked-inverse right-xxs rule-checkbox' : 'sae-icon sae-icon-circle right-xxs rule-checkbox'
                  }
                ></span>
                <label className="rule-validation-text">{item.label}</label>
              </div>
            );
          })}
        </div>
        <div className="fwc-col-6 fwc-row-bm">
          {passwordRules.map((item) => {
            return (
              <div key={item.id} className="fwc-col-6 fwc-radio rule-checkbox-container">
                <span
                  className={
                    item.isPassed ? 'sae-icon sae-icon-circle-checked-inverse right-xxs rule-checkbox' : 'sae-icon sae-icon-circle right-xxs rule-checkbox'
                  }
                ></span>
                <label className="rule-validation-text">{item.label}</label>
              </div>
            );
          })}
        </div>
      </div>

      <div className="fwc-row fwc-row-bm confirm-password-container">
        <div className="fwc-col-6 ">
          <div className="fwc-input">
            <label id="password-creation-confirm-password-label" className="fwc-label" htmlFor={'password-creation-confirm-password-input'}>
              {pageContent?.CONFIRM_PASSWORD}
            </label>
            <input
              aria-label={'Confirm Password'}
              id="password-creation-confirm-password-input"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              onBlur={() => checkValidConfirmPassword()}
            />
            {confirmPasswordError.length > 0 && (
              <div className="fwc-row fwc-row-bm fwc-col-12">
                <div className="fwc-input">
                  <span id="personal-info-lastname-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                    {' '}
                    {confirmPasswordError}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PasswordCreation;
