const INPUT_TYPES = {
  USERNAME: 'USERNAME',
  PASSWORD: 'PASSWORD',
};

const INFO_ALERT = {
  type: 'info',
  content:
    "<div>After you create your Profile we'll send you <a>back to the last page you were on.</a></div>",
  area: 'global',
};

const REGISTER_SHOPPER_RESPONSE_CODES = {
  TWO_FA_NEEDED: 'REGISTER002',
};
export { INPUT_TYPES, INFO_ALERT, REGISTER_SHOPPER_RESPONSE_CODES };
