import React from 'react';
import { CALIFORNIA_CODE } from '../../../shared/globalConstants';
import './LearnMoreModal.scss';

export default function LearnMoreModal(props) {
    const { stateCode, Strings } = props
    return (
        <div className='learn-more-modal-container' id='lear-more-div'>
            <p className="fwc-row-bm">{stateCode === CALIFORNIA_CODE ? Strings?.CA.DESC : Strings?.NON_CA.DESC}</p>
            <h5>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_1.QUESTION_1 : Strings?.NON_CA.ROW_1.QUESTION_1}</h5>
            <p className="fwc-row-bm">{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_1.ANSWER_1 : Strings?.NON_CA.ROW_1.ANSWER_1}</p>

            <h5>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_2.QUESTION_1 : Strings?.NON_CA.ROW_2.QUESTION_1}</h5>
            <p className="fwc-row-bm">{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_2.ANSWER_1 : Strings?.NON_CA.ROW_2.ANSWER_1}</p>
            <p className="fwc-row-bm">{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_2.ANSWER_2 : Strings?.NON_CA.ROW_2.ANSWER_2}</p>
            <p className="fwc-row-bm">{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_2.ANSWER_3 : Strings?.NON_CA.ROW_2.ANSWER_3}</p>

            <h5>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_3.QUESTION_1 : Strings?.NON_CA.ROW_3.QUESTION_1}</h5>
            <ol>
                <li className="fwc-row-bm"><span className='modal-label'>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_3.ANSWER_1.LABEL : Strings?.NON_CA.ROW_3.ANSWER_1.LABEL}</span>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_3.ANSWER_1.DESC : Strings?.NON_CA.ROW_3.ANSWER_1.DESC} </li>
                <li className="fwc-row-bm"><span className='modal-label'>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_3.ANSWER_2.LABEL : Strings?.NON_CA.ROW_3.ANSWER_2.LABEL}</span>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_3.ANSWER_2.DESC : Strings?.NON_CA.ROW_3.ANSWER_2.DESC} </li>
            </ol>

            <h5>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_4.QUESTION_1 : Strings?.NON_CA.ROW_4.QUESTION_1}</h5>
            <p className="fwc-row-bm">{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_4.ANSWER_1 : Strings?.NON_CA.ROW_4.ANSWER_1}</p>

            <h5>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_5.QUESTION_1 : Strings?.NON_CA.ROW_5.QUESTION_1}</h5>
            <p className="fwc-row-bm">{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_5.ANSWER_1 : Strings?.NON_CA.ROW_5.ANSWER_1}</p>

            <h5>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_6.QUESTION_1 : Strings?.NON_CA.ROW_6.QUESTION_1}</h5>
            <p className="fwc-row-bm">{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_6.ANSWER_1 : Strings?.NON_CA.ROW_6.ANSWER_1}</p>

            <ul>
                <li>
                    <h6>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_6.SUB_ROW_1.QUESTION : Strings?.NON_CA.ROW_6.SUB_ROW_1.QUESTION}</h6>
                    <p>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_6.SUB_ROW_1.ANSWER : Strings?.NON_CA.ROW_6.SUB_ROW_1.ANSWER}</p>
                </li>
                <li>
                    <h6>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_6.SUB_ROW_2.QUESTION : Strings?.NON_CA.ROW_6.SUB_ROW_2.QUESTION}</h6>
                    <p>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_6.SUB_ROW_2.ANSWER : Strings?.NON_CA.ROW_6.SUB_ROW_2.ANSWER}</p>
                </li>
                <li>
                    <h6>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_6.SUB_ROW_3.QUESTION : Strings?.NON_CA.ROW_6.SUB_ROW_3.QUESTION}</h6>
                    <p>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_6.SUB_ROW_3.ANSWER : Strings?.NON_CA.ROW_6.SUB_ROW_3.ANSWER}</p>
                </li>
                <li>
                    <h6>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_6.SUB_ROW_4.QUESTION : Strings?.NON_CA.ROW_6.SUB_ROW_4.QUESTION}</h6>
                    <p>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_6.SUB_ROW_4.ANSWER : Strings?.NON_CA.ROW_6.SUB_ROW_4.ANSWER}</p>
                </li>
            </ul>

            <h5>{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_7.QUESTION_1 : Strings?.NON_CA.ROW_7.QUESTION_1}</h5>
            <p className="fwc-row-bm">{stateCode === CALIFORNIA_CODE ? Strings?.CA.ROW_7.ANSWER_1 : Strings?.NON_CA.ROW_7.ANSWER_1}</p>

            <br />
            <br />
            <div>
                <p className="fwc-row-bm footer-font">{stateCode === CALIFORNIA_CODE ? Strings?.CA.FOOTER_1 : Strings?.NON_CA.FOOTER_1}</p>
                <p className="footer-font">{stateCode === CALIFORNIA_CODE ? Strings?.CA.FOOTER_2 : Strings?.NON_CA.FOOTER_2}</p>
            </div>
        </div>
    )
}
