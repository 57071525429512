import { Tooltip } from "@sydney-broker-ui/ios";
import React, { useEffect, useState } from 'react';
import { GRADE_LEVELS } from '../../../pages/public/medical-plans/constants';
import { getPrimaryProductTypeByCoverageType } from '../../../pages/secure/product-selection/productSelectionUtils';
import { PLAN_TYPES_NAMES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { PLAN_MARKET_TYPES } from '../compare-tray/constant';
import { getApplicableGrade, getGradeLabel, isMappedContract } from '../plan-card/planCardUtils';
import './ProductReviewTray.scss';

const ProductReviewTray: React.FC<ProductReviewTrayProps> = ({ labels, handleReviewClick }) => {
    const { comparedProducts,
        agentCoverageType,
        currentCoverageMedicalPlan,
        currentCoverageDentalPlan,
        currentCoverageVisionPlan,
        updateComparedProducts } = useGlobalStore(state => state)
    const [collapsed, setCollapsed] = useState(false);
    const [comparedPlans, setComparedPlans] = useState([] as any);

    useEffect(() => {
        let medicalPlans: Contract[] = [];
        let dentalPlans: Contract[] = [];
        let visionPlans: Contract[] = [];
        comparedProducts.map((product) => {
            medicalPlans.push(product?.medicalProduct!);
            dentalPlans.push(product?.dentalProduct!)
            visionPlans.push(product?.visionProduct!)
        })
        switch (getPrimaryProductTypeByCoverageType(agentCoverageType)) {
            case PLAN_TYPES_NAMES.MEDICAL_PRODUCT:
                setComparedPlans(medicalPlans);
                break;
            case PLAN_TYPES_NAMES.DENTAL_PRODUCT:
                setComparedPlans(dentalPlans);
                break;
            case PLAN_TYPES_NAMES.VISION_PRODUCT:
                setComparedPlans(visionPlans);
                break;

        }
    }, [comparedProducts]);

    const getiIsCurrentCoveragePlan = (contractCode: string) => {
        const planType: string = getPrimaryProductTypeByCoverageType(agentCoverageType);
        let isCurrentCoveragePlan =
            planType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT ? currentCoverageMedicalPlan?.contractCode === contractCode :
                planType === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? currentCoverageDentalPlan?.contractCode === contractCode :
                    currentCoverageVisionPlan?.contractCode === contractCode;
        return isCurrentCoveragePlan
    }

    const deleteSelection = (plan: Contract) => {
        const planType = getPrimaryProductTypeByCoverageType(agentCoverageType);
        comparedProducts.find(product => {
            if (planType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT &&
                product?.medicalProduct?.contractCode === plan?.contractCode) {
                const newArray = comparedProducts.filter((product) => product?.medicalProduct?.contractCode !== plan?.contractCode);
                updateComparedProducts(newArray);
            }
            else if (planType === PLAN_TYPES_NAMES.DENTAL_PRODUCT &&
                product?.dentalProduct?.contractCode === plan?.contractCode) {
                const newArray = comparedProducts.filter((product) => product?.dentalProduct?.contractCode !== plan?.contractCode);
                updateComparedProducts(newArray);
            }
            else if (planType === PLAN_TYPES_NAMES.VISION_PRODUCT &&
                product?.visionProduct?.contractCode === plan?.contractCode) {
                const newArray = comparedProducts.filter((product) => product?.visionProduct?.contractCode !== plan?.contractCode);
                updateComparedProducts(newArray);
            }
        })
    }

    const marketTagClass = (poPlan: boolean, market: boolean) => {
        if (poPlan) {
            return PLAN_MARKET_TYPES.COLARADO_CONNECT
        } else if (market) {
            return PLAN_MARKET_TYPES.MARKET_PLACE;
        } else {
            return PLAN_MARKET_TYPES.OFF_MARKET;
        }
    }

    const renderGradetag = (gradeLevels: GradeLevel[], isCurrentCoveragePlan: boolean = false, contract: Contract) => {
        if (isCurrentCoveragePlan) {
            return (
                <div className={'grade-wrapper current-coverage-grade-container'}>
                    <span className="grade-label">{labels?.CURRENT_COVERAGE}</span>
                </div>
            );
        } else if (isMappedContract(contract)) {
            return (
                <div className="transition-coverage-info-wrapper">
                    <div className={'grade-wrapper transition-coverage-grade-container'}>
                        <span className="grade-label">{labels?.PLAN_CARD_LABELS?.TRANSITION_COVERAGE}</span>
                    </div>
                    <div className="plan-card-transition-tooltip">
                        <Tooltip icon message={labels?.PLAN_CARD_LABELS?.TRANSITION_PLAN_TOOLTIP_CONTENT} />
                    </div>
                </div>
            );

        } else {
            const applicableGrade = getApplicableGrade(gradeLevels);
            switch (applicableGrade) {
                case GRADE_LEVELS.RECOMMENDED:
                case GRADE_LEVELS.POPULAR:
                    return (
                        <div className={applicableGrade === GRADE_LEVELS.RECOMMENDED ? 'grade-wrapper recommended-grade-container' : 'grade-wrapper popular-grade-level'}>
                            <span className="grade-label">{getGradeLabel(applicableGrade, labels?.PLAN_CARD_LABELS)}</span>
                        </div>
                    );
                default:
                    return null;
            }
        }
    };

    const planHeaders = () => {
        return (
            <div className="compare-tray-container">
                <div className={'fwc-row fwc-flex-column'}>
                    <div className="compare-tray-plan-header">
                        <div className="toggle-container">
                            <div
                                id="plan-coverage-toggle"
                                onClick={() => setCollapsed(!collapsed)}
                                className={collapsed ? 'sae-icon sae-icon-angle-down toggle-icon' : 'sae-icon sae-icon-angle-up toggle-icon'}
                            />
                        </div>
                        <div className='review-tray-title'>{labels?.MAX_PLANS}</div>
                        <button
                            id="product-selection-review"
                            className="footer-button fwc-btn fwc-btn-primary flex-align-right"
                            onClick={() => {
                                handleReviewClick()
                            }}
                        >
                            {labels?.REVIEW_SELECTED_PLANS}
                        </button>
                    </div>
                    {!collapsed && <div className={'compare-tray-content'}>
                        {comparedPlans?.map((plan: Contract) => (
                            <div key={plan?.contractCode} className="compare-tray-plan fwc-col-4 fwc-card-generic">
                                <div className="compare-tray-plan-header">
                                    <div id="plan-card-grade" className="grade-container">
                                        {renderGradetag(plan?.gradeLevels, getiIsCurrentCoveragePlan(plan?.contractCode), plan)}
                                    </div>
                                    <div className={'market-tag ' + marketTagClass(plan?.poPlan, plan?.onExchange)}>{marketTagClass(plan?.poPlan, plan?.onExchange)}</div>
                                    <div onClick={() => { deleteSelection(plan) }} className={'fwc-icon fwc-icon-delete flex-align-right'} />
                                </div>
                                <div className='compare-tray-plan-title '>
                                    {plan?.planMarketingName}
                                </div>
                            </div>
                        ))}
                        {(comparedPlans.length === 1 || comparedPlans.length === 2) &&
                            <div className="compare-tray-plan placeholder-plan fwc-col-4 fwc-card-generic"></div>}
                        {comparedPlans.length === 1 &&
                            <div className="compare-tray-plan placeholder-plan fwc-col-4 fwc-card-generic"></div>}
                    </div>

                    }
                </div>
            </div>
        )
    }
    return (
        <div >
            <div >
                {planHeaders()}
            </div>
        </div>
    )
}

export default ProductReviewTray