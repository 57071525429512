import { toTitleCase } from '../../../shared/utils/globalUtils';

import { DENTAL_PLAN_BENEFITS } from '../../../pages/public/dental-plans/constants';
import { GRADE_LEVELS, PLAN_BENEFIT_CODES } from '../../../pages/public/medical-plans/constants';
import { VISION_PLAN_BENEFITS } from '../../../pages/public/vision-plans/constants';
import { PLAN_TYPES_NAMES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import {
  MAX_WORDS_ON_SHOW_LESS_DEDUCTIBLE,
  MAX_WORDS_ON_SHOW_LESS_DENTAL_BENEFITS,
  MAX_WORDS_ON_SHOW_LESS_EYEGLASS_FRAMES,
  MAX_WORDS_ON_SHOW_LESS_PREVENTIVE_CARE,
  MAX_WORDS_ON_SHOW_LESS_PRIMARY_CARE_VISIT,
  MAX_WORDS_ON_SHOW_LESS_ROUTINE_EYE_EXAM
} from './constants';

const getApplicableGrade = (gradeLevels: GradeLevel[]) => {
  let applicableGradeLevel = '';

  const reccommendedObj = gradeLevels.find((gradeLevel) => gradeLevel.grade === GRADE_LEVELS.RECOMMENDED);
  const populardObj = gradeLevels.find((gradeLevel) => gradeLevel.grade === GRADE_LEVELS.POPULAR);

  if (reccommendedObj) {
    applicableGradeLevel = GRADE_LEVELS.RECOMMENDED;
  } else if (populardObj) {
    applicableGradeLevel = GRADE_LEVELS.POPULAR;
  }

  return applicableGradeLevel;
};

const getGradeLabel = (applicableGrade: string, labels: PlanCardLabels) => {
  switch (applicableGrade) {
    case GRADE_LEVELS.RECOMMENDED:
      return labels.recommended;
    case GRADE_LEVELS.POPULAR:
      return labels.mostPopular;
    default:
      return '';
  }
};

const getDeductibleValue = (plan: Plan, showMore: boolean) => {
  const { planBenefits } = plan;
  let planBenefitObj = planBenefits.find((benefit) => benefit.planBenefitCode === PLAN_BENEFIT_CODES.DEDUCTIBLE);
  if (!planBenefitObj) {
    planBenefitObj = planBenefits.find((benefit) => benefit.planBenefitCode === PLAN_BENEFIT_CODES.MEDICAL_DEDUCTIBLE);
  }
  const value = planBenefitObj?.planBenefitValue ? planBenefitObj?.planBenefitValue : '';

  if (showMore) {
    return value;
  } else {
    const words = value.split(' ');
    if (words.length <= MAX_WORDS_ON_SHOW_LESS_DEDUCTIBLE) {
      return value;
    } else {
      return words.slice(0, MAX_WORDS_ON_SHOW_LESS_DEDUCTIBLE).join(' ') + '...';
    }
  }
};

const getDentalDeductibleValue = (plan: Plan, showMore: boolean) => {
  const { planBenefits } = plan;
  const planBenefitObj = planBenefits.find((benefit) => benefit.planBenefitCode === DENTAL_PLAN_BENEFITS.DEDUCTIBLE);
  const value = planBenefitObj?.planBenefitValue ? planBenefitObj?.planBenefitValue : '';

  if (showMore) {
    return value;
  } else {
    const words = value.split(' ');
    if (words.length <= MAX_WORDS_ON_SHOW_LESS_DENTAL_BENEFITS) {
      return value;
    } else {
      return words.slice(0, MAX_WORDS_ON_SHOW_LESS_DENTAL_BENEFITS).join(' ') + '...';
    }
  }
};

const getRoutineEyeExamValue = (plan: Plan, showMore: boolean) => {
  const { planBenefits } = plan;
  const planBenefitObj = planBenefits.find((benefit) => benefit.planBenefitCode === VISION_PLAN_BENEFITS.ROUTINE_EYE_EXAM);
  const value = planBenefitObj?.planBenefitValue ? planBenefitObj?.planBenefitValue : '';

  if (showMore) {
    return value;
  } else {
    const words = value.split(' ');
    if (words.length <= MAX_WORDS_ON_SHOW_LESS_ROUTINE_EYE_EXAM) {
      return value;
    } else {
      return words.slice(0, MAX_WORDS_ON_SHOW_LESS_ROUTINE_EYE_EXAM).join(' ') + '...';
    }
  }
};

const getBenefitOneDescription = (plan: Plan, showMore: boolean): string => {
  const { planType } = plan;

  switch (planType) {
    case PLAN_TYPES_NAMES.MEDICAL:
      return getDeductibleValue(plan, showMore);
    case PLAN_TYPES_NAMES.DENTAL:
      return getDentalDeductibleValue(plan, showMore);
    case PLAN_TYPES_NAMES.VISION:
      return getRoutineEyeExamValue(plan, showMore);
    default:
      return '';
  }
};

const getPrimaryCareVisitValue = (plan: Plan, showMore: boolean) => {
  const { planBenefits } = plan;
  const planBenefitObj = planBenefits.find((benefit) => benefit.planBenefitCode === PLAN_BENEFIT_CODES.PRIMARY_CARE_VISIT);
  const value = planBenefitObj?.planBenefitValue ? planBenefitObj?.planBenefitValue : '';

  if (showMore) {
    return value;
  } else {
    const words = value.split(' ');
    if (words.length <= MAX_WORDS_ON_SHOW_LESS_PRIMARY_CARE_VISIT) {
      return value;
    } else {
      return words.slice(0, MAX_WORDS_ON_SHOW_LESS_PRIMARY_CARE_VISIT).join(' ') + '...';
    }
  }
};

const getAnualMaxValue = (plan: Plan, showMore: boolean) => {
  const { planBenefits } = plan;
  const planBenefitObj = planBenefits.find((benefit) => benefit.planBenefitCode === DENTAL_PLAN_BENEFITS.ANNUAL_MAX);
  const value = planBenefitObj?.planBenefitValue ? planBenefitObj?.planBenefitValue : '';

  if (showMore) {
    return value;
  } else {
    const words = value.split(' ');
    if (words.length <= MAX_WORDS_ON_SHOW_LESS_DENTAL_BENEFITS) {
      return value;
    } else {
      return words.slice(0, MAX_WORDS_ON_SHOW_LESS_DENTAL_BENEFITS).join(' ') + '...';
    }
  }
};

const getEyeglassLensesValue = (plan: Plan, showMore: boolean) => {
  const { planBenefits } = plan;
  const planBenefitObj = planBenefits.find((benefit) => benefit.planBenefitCode === VISION_PLAN_BENEFITS.EYE_GLASS_LENSES);
  const value = planBenefitObj?.planBenefitValue ? planBenefitObj?.planBenefitValue : '';

  if (showMore) {
    return value;
  } else {
    const words = value.split(' ');
    if (words.length <= MAX_WORDS_ON_SHOW_LESS_ROUTINE_EYE_EXAM) {
      return value;
    } else {
      return words.slice(0, MAX_WORDS_ON_SHOW_LESS_ROUTINE_EYE_EXAM).join(' ') + '...';
    }
  }
};

const getBenefitTwoDescription = (plan: Plan, showMore: boolean): string => {
  const { planType } = plan;

  switch (planType) {
    case PLAN_TYPES_NAMES.MEDICAL:
      return getPrimaryCareVisitValue(plan, showMore);
    case PLAN_TYPES_NAMES.DENTAL:
      return getAnualMaxValue(plan, showMore);
    case PLAN_TYPES_NAMES.VISION:
      return getEyeglassLensesValue(plan, showMore);
    default:
      return '';
  }
};

const getPreventiveCareValue = (plan: Plan, showMore: boolean) => {
  const { planBenefits } = plan;
  const planBenefitObj = planBenefits.find((benefit) => benefit.planBenefitCode === PLAN_BENEFIT_CODES.PREVENTIVE_CARE);
  const value = planBenefitObj?.planBenefitValue ? planBenefitObj?.planBenefitValue : '';

  if (showMore) {
    return value;
  } else {
    const words = value.split(' ');
    if (words.length <= MAX_WORDS_ON_SHOW_LESS_PREVENTIVE_CARE) {
      return value;
    } else {
      return words.slice(0, MAX_WORDS_ON_SHOW_LESS_PREVENTIVE_CARE).join(' ') + '...';
    }
  }
};

const getOutOfPocketMaxValue = (plan: Plan, showMore: boolean) => {
  const { planBenefits } = plan;
  const planBenefitObj = planBenefits.find((benefit) => benefit.planBenefitCode === DENTAL_PLAN_BENEFITS.OUT_OF_POCKET_MAX);
  const value = planBenefitObj?.planBenefitValue ? planBenefitObj?.planBenefitValue : '';

  if (showMore) {
    return value;
  } else {
    const words = value.split(' ');
    if (words.length <= MAX_WORDS_ON_SHOW_LESS_DENTAL_BENEFITS) {
      return value;
    } else {
      return words.slice(0, MAX_WORDS_ON_SHOW_LESS_DENTAL_BENEFITS).join(' ') + '...';
    }
  }
};

const getEyeglassFramesValue = (plan: Plan, showMore: boolean) => {
  const { planBenefits } = plan;
  const planBenefitObj = planBenefits.find((benefit) => benefit.planBenefitCode === VISION_PLAN_BENEFITS.EYE_GLASS_FRAMES);
  const value = planBenefitObj?.planBenefitValue ? planBenefitObj?.planBenefitValue : '';

  if (showMore) {
    return value;
  } else {
    const words = value.split(' ');
    if (words.length <= MAX_WORDS_ON_SHOW_LESS_EYEGLASS_FRAMES) {
      return value;
    } else {
      return words.slice(0, MAX_WORDS_ON_SHOW_LESS_EYEGLASS_FRAMES).join(' ') + '...';
    }
  }
};

const getContactLensesValue = (plan: Plan, showMore: boolean) => {
  const { planBenefits } = plan;
  const planBenefitObj = planBenefits.find((benefit) => benefit.planBenefitCode === VISION_PLAN_BENEFITS.CONTACT_LENSES);
  const value = planBenefitObj?.planBenefitValue ? planBenefitObj?.planBenefitValue : '';

  if (showMore) {
    return value;
  } else {
    const words = value.split(' ');
    if (words.length <= MAX_WORDS_ON_SHOW_LESS_EYEGLASS_FRAMES) {
      return value;
    } else {
      return words.slice(0, MAX_WORDS_ON_SHOW_LESS_EYEGLASS_FRAMES).join(' ') + '...';
    }
  }
};

const getDiagnosticsPreventiveValue = (plan: Plan, showMore: boolean) => {
  const { planBenefits } = plan;
  const planBenefitObj = planBenefits.find((benefit) => benefit.planBenefitCode === DENTAL_PLAN_BENEFITS.DIAGNOSTICS_PREVENTIVE);
  const value = planBenefitObj?.planBenefitValue ? planBenefitObj?.planBenefitValue : '';

  if (showMore) {
    return value;
  } else {
    const words = value.split(' ');
    if (words.length <= MAX_WORDS_ON_SHOW_LESS_EYEGLASS_FRAMES) {
      return value;
    } else {
      return words.slice(0, MAX_WORDS_ON_SHOW_LESS_EYEGLASS_FRAMES).join(' ') + '...';
    }
  }
};

const getBenefitThreeDescription = (plan: Plan, showMore: boolean): string => {
  const { planType } = plan;

  switch (planType) {
    case PLAN_TYPES_NAMES.MEDICAL:
      return getPreventiveCareValue(plan, showMore);
    case PLAN_TYPES_NAMES.DENTAL:
      return getOutOfPocketMaxValue(plan, showMore);
    case PLAN_TYPES_NAMES.VISION:
      return getEyeglassFramesValue(plan, showMore);
    default:
      return '';
  }
};

const getProviderNames = (providers: Provider[]): string[] => {
  return providers.map((provider) => toTitleCase(provider.providerName));
};

const getMedicationNames = (medications: DrugDetails[]): string[] => {
  return medications.map((drug) => toTitleCase(drug.drugLabel));
};

const isMappedContract = (contract: Contract): boolean => {
  const { mappedMedicalContractCode, mappedDentalContractCode, mappedVisionContractCode, year, agentYear, enrollmentYear, agentSSOInfo } =
    useGlobalStore.getState();
  const yearValue = agentSSOInfo.isAtk ? agentYear : year;
  if (yearValue === enrollmentYear) {
    switch (contract?.plan[0]?.planType) {
      case PLAN_TYPES_NAMES.MEDICAL:
        return contract?.contractCode === mappedMedicalContractCode;
      case PLAN_TYPES_NAMES.DENTAL:
        return contract?.contractCode === mappedDentalContractCode;
      case PLAN_TYPES_NAMES.VISION:
        return contract?.contractCode === mappedVisionContractCode;
      default:
        return false;
    }
  } else {
    return false;
  }
};

export {
  getAnualMaxValue,
  getApplicableGrade,
  getBenefitOneDescription,
  getBenefitThreeDescription,
  getBenefitTwoDescription,
  getContactLensesValue,
  getDeductibleValue,
  getDiagnosticsPreventiveValue,
  getEyeglassFramesValue,
  getEyeglassLensesValue,
  getGradeLabel,
  getMedicationNames,
  getOutOfPocketMaxValue,
  getPrimaryCareVisitValue,
  getProviderNames,
  getRoutineEyeExamValue,
  isMappedContract
};
