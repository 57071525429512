import { Popover } from '@sydney-broker-ui/ios';
import React, { useState } from 'react';
import { toTitleCase } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import './MatchingListCard.scss';

const MatchingListCard: React.FC<MatchingListCardProps> = ({ data, providers, icon, label, showMoreText, showLessText, tooltipMessage = '', impNoticeText, isCompare = false, scrolltoImprtantNotice }) => {
  const [itemsToShow, setItemsToShow] = useState(2);
  const [anchor, setAnchor] = useState<React.SetStateAction<null> | (EventTarget & HTMLSpanElement)>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const { updateProviderMetaData } = useGlobalStore(state => state)

  const numberOfItems = providers ? providers?.length : data?.length ? data?.length : 0;
  const showmore = () => {
    setItemsToShow(numberOfItems);
  };

  const showless = () => {
    setItemsToShow(2);
  };

  const handleHelpClick = (currentTarget: React.SetStateAction<null> | (EventTarget & HTMLSpanElement)) => {
    setAnchor(currentTarget);
    setPopoverOpen(!popoverOpen);
  };

  const getProviderDetails = (provider: Provider) => {
    updateProviderMetaData(provider)
  }
  return (
    <div className="matching-list-container">
      <h6 className="matching-list-label">
        <span id="matching-list-label-icon" className={`sae-icon ${icon}`} />
        {`${numberOfItems} ${label}`}
        {tooltipMessage && (
          <>
            {popoverOpen && anchor && (
              <Popover id="list-card-popover" classplacement={'top'} anchorEl={anchor} onClickOutside={() => setPopoverOpen(!popoverOpen)}>
                <p className="list-popover-content"> {tooltipMessage}</p>
              </Popover>
            )}
            {isCompare && (
              <div className={'popover-wrapper'}>
                {popoverOpen && anchor && (
                  <Popover id="list-card-popover" classplacement={'top'} anchorEl={anchor} onClickOutside={() => setPopoverOpen(!popoverOpen)}>
                    <p className="list-popover-content"> {tooltipMessage}</p>
                  </Popover>)}
              </div>
            )}
            <span id="list-tooltip-icon" className="sae-icon sae-icon-help help-icon" onClick={(e) => handleHelpClick(e.currentTarget)} />
          </>
        )}
      </h6>
      <div className="matching-list-wrapper">
        <ul>
          {providers &&
            providers?.slice(0, itemsToShow).map((value, index) => {
              return (
                <li key={index} >
                  <span id="matching-list-icon" className="sae-icon sae-icon-checkmark" /> <span className="provider-name" data-testid='show-provider-details-button' onClick={() => getProviderDetails(value)}>{toTitleCase(value.providerName)}</span>
                </li>
              );
            })}

          {data && data?.slice(0, itemsToShow).map((value, index) => {
            return (
              <li key={index} >
                <span id="matching-list-icon" className="sae-icon sae-icon-checkmark" /> {value}
              </li>
            );
          })}
        </ul>
      </div>
      {numberOfItems > 2 && (
        <div className="show-more-less-wrapper">
          {itemsToShow === 2 ? <span onClick={showmore}>{showMoreText}</span> : <span onClick={showless}>{showLessText}</span>}
        </div>
      )}
      {impNoticeText && numberOfItems > 0 && <a className="important-notice" onClick={scrolltoImprtantNotice ? scrolltoImprtantNotice : undefined}>{impNoticeText}</a>}
    </div>
  );
};

export default MatchingListCard;
