const EYEDOCTOR_NAME_LABELS = (Strings: any) => {
  return {
    label1: Strings.DOCTOR_NAME,
    label2: Strings.OPTIONAL,
    label3: Strings.PLEASE_ENTER,
    placeHolder: Strings.EXAMPLE_NAME
  };
};

const EYEDOCTORS_NOT_FOUND_LABELS = (Strings: any): NoResultsFoundProps => {
  return {
    label: Strings.DOCTOR_NOT_FOUND,
    description: Strings.WE_ARE_ALWAYS_WORKING,
    sublabel: Strings.PLEASE_SEARCH_AGAIN,
    hints: [Strings.CHECK_THE_SPELLING, Strings.WIDEN_YOUR_SEARCH, Strings.EXPAND_THE_SEARCH_RADIUS, Strings.CHECK_THE_ZIP_CODE]
  }
};

export { EYEDOCTORS_NOT_FOUND_LABELS, EYEDOCTOR_NAME_LABELS };

