import { PLAN_TYPES_NAMES } from '../../../shared/globalConstants';
import { getFormattedCurrency } from '../../../shared/utils/globalUtils';

const getIcon = (planType: string) => {
  switch (planType) {
    case PLAN_TYPES_NAMES.MEDICAL:
      return 'sae-icon-heart-health';
    case PLAN_TYPES_NAMES.DENTAL:
      return 'sae-icon-tooth';
    case PLAN_TYPES_NAMES.VISION:
      return 'sae-icon-eye';
    default:
      return 'sae-icon-heart-health';
  }
};

const getTotalAmount = (plans: Contracts) => {
  const amount = plans?.map((plan) => (plan?.rateData?.rate?.totSubsidizedPrem ? plan?.rateData?.rate?.totSubsidizedPrem : 0.0));
  const sum = amount.reduce((total, currentValue) => total + currentValue, 0);
  return getFormattedCurrency(sum);
};

export { getIcon, getTotalAmount };
