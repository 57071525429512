import React, { useCallback, useEffect, useState } from 'react';

import FullPageLoader from '../full-page-loader/FullPageLoader';
import GlobalFooter from '../global-footer/GlobalFooter';
import GlobalHeader from '../global-header/GlobalHeader';

import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { resetUserData } from '../../../pages/secure/logout/LogoutPupupUtils';

import { LANGUAGE_CODES, SCREEN_NAMES } from '../../../shared/globalConstants';
import { default as Content } from './atkSessionEnd.json';
import './atkSessionEnd.scss';

const AtkSessionEnd = () => {
    const [pageContent, setPageContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);

    useEffect(() => {
        resetUserData();
        getContent();
    }, [])

    const getContent = useCallback(async (): Promise<boolean> => {
        let content: any = null;
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.ATK_SESSION_END);
            let cmsResponse = response.data;
            content = cmsResponse.data.iospagecontentList.items[0].pageContent?.AtkSessionEnd

        } catch (error) {
            console.error('AtkSessionEnd.tsx getContent error: ', error);
            content = Content?.data.iospagecontentList.items[0].pageContent?.AtkSessionEnd;

            return false;
        }
        finally {
            setPageContent(content)
            setContentLoaded(true);
        }
        return true;
    }, []);

    if (!contentLoaded) {
        return <FullPageLoader />
    } else {
        return (
            <div className="atk-session-end-container">
                <GlobalHeader showOnlyLogo={true} notClickable={true} />
                <div className="atk-session-message-wrapper">
                    <div className="atk-session-message-inner-wrapper">
                        <span className='sae-icon sae-icon-checked success-icon'></span>
                        <p className="session-end-message">{pageContent?.COMMON_MESSAGE}</p>
                    </div>
                </div>
                <GlobalFooter />
            </div>
        )
    }
}

export default AtkSessionEnd;