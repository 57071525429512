const validatePin = (value: string, labels: any) => {
  const errorConfig = {
    isValidPin: true,
    pinErrorMessage: ''
  };

  if (value.length === 0) {
    errorConfig.isValidPin = false;
    errorConfig.pinErrorMessage = labels.REQUIRED_FIELD;
  }

  return errorConfig;
};

export { validatePin };
