import { API_NAME_LIST } from '../../../api/api-name-list';
import axiosInstance from '../../../api/axiosWrapper/axiosWrapper';

const fetchPlanList = (payload: PlanSummaryPayload) => {
  return axiosInstance.post(API_NAME_LIST.GET_MEDICAL_PLANS, payload);
};
const fetchAlphaPrefix = (payload: AlpaPrefixReq) => {
  return axiosInstance.post(API_NAME_LIST.ALPHA_PREFIX, payload);
};

const getProviderListById = (payload) => {
  return axiosInstance.post(API_NAME_LIST.GET_PROVIDER_LIST_BY_ID, payload);
};
export { fetchAlphaPrefix, fetchPlanList, getProviderListById };
