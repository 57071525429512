import { StateCreator } from 'zustand';
import { initialAgentSSOInfo } from '../shared/agentSSOInfoDefault';

export const createAgentSSOInfoSlice: StateCreator<AgentSSOInfoStore> = (set, get) => ({
  agentSSOInfo: initialAgentSSOInfo,
  isHPAUser: false,
  isHCIDFlow: false,
  isRenewalFlow: false,
  updateAgentSSOInfo: (agentSSOInfo: AgentSSOInfo) => set({ agentSSOInfo: agentSSOInfo }),
  resetAgentSSOInfo: () => set({ agentSSOInfo: initialAgentSSOInfo }),
  updateIsHPAUser: (isHPAUser: boolean) => set({ isHPAUser: isHPAUser }),
  updateIsHCIDFlow: (isHCIDFlow: boolean) => set({ isHCIDFlow: isHCIDFlow }),
  updateIsRenewalFlow: (isRenewalFlow: boolean) => set({ isRenewalFlow: isRenewalFlow })
});
