import React from 'react';
import './ContentBlock.scss';

function ContentBlock(props) {
  return (
    <div className="content-block">
      <div className="cb-cont">
        <div className="cb-icon fwc-col-2">
          <div className={'sae-icon ' + props.icon}></div>
        </div>
        <div className="cb-content fwc-col-10">
          <h4 className="title btm-xxs">{props.title}</h4>
          {props?.modal ? (
            <div>
              <p>
                {' '}
                <a data-analytics="learnMoreLinkLpIos" href={'javascript:void(0)'} id={'learn-more'} onClick={() => props.clickLearnMoreModal()}>
                  {props.linkLabel}
                </a>{' '}
                {props.description}
              </p>
            </div>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: props.body }}></div>
          )}
        </div>
      </div>
    </div>
  );
}
export default ContentBlock;
