import { API_NAME_LIST } from '../../../api/api-name-list';
import axiosInstance from '../../../api/axiosWrapper/axiosWrapper';

const getDrugList = (payload: SearchDrugsPayload) => {
  return axiosInstance.post(API_NAME_LIST.GET_DRUGLIST, payload);
};

const getGenericDrugList = (payload: SearchGenericDrugsPayload) => {
  return axiosInstance.post(API_NAME_LIST.GET_GENERICDRUGLIST, payload);
};

const getDrugsPlanSummary = (payload: DrugSummaryPayload) => {
  return axiosInstance.post(API_NAME_LIST.GET_DRUGS_PLAN_SUMMARY, payload);
};

export { getDrugList, getDrugsPlanSummary, getGenericDrugList };
