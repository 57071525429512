const getUpdatedSwitchFilters = (options: SwitchFilter[], updatedOption: SwitchFilter): SwitchFilter[] => {
  const { key, enabled } = updatedOption;

  return options.map((filterObj) => ({
    ...filterObj,
    enabled: filterObj.key === key ? !enabled : filterObj.enabled
  }));
};

const getResetSwitchFilters = (options: SwitchFilter[]): SwitchFilter[] => {
  return options.map((filterObj) => ({
    ...filterObj,
    enabled: false
  }));
};

export { getResetSwitchFilters, getUpdatedSwitchFilters };
