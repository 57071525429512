import { MEDICAL_PLAN_TYPES, PLAN_TYPES, PLAN_TYPES_NAMES } from '../../../shared/globalConstants';
import { useUserStore } from '../../../store/userStore';

const enableEditCoverage = (planType, coverageType) => {
  const { isWellpoint } = useUserStore.getState();
  let showEdit = isWellpoint ? false : true;

  if (MEDICAL_PLAN_TYPES.includes(coverageType) && planType === PLAN_TYPES_NAMES.MEDICAL) {
    showEdit = false;
  }
  if (PLAN_TYPES.DENTAL === coverageType && planType === PLAN_TYPES_NAMES.DENTAL) {
    showEdit = false;
  }
  if (PLAN_TYPES.VISION === coverageType && planType === PLAN_TYPES_NAMES.VISION) {
    showEdit = false;
  }

  return showEdit;
};

export { enableEditCoverage };
