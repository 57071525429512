import { FORMHEADER_ICON_CLASSNAMES, PROVIDER_TYPE_USECASES } from '../../../pages/public/find-your-doctor/constants';

const getFormHeaderClassName = (useCase: string) => {
  switch (useCase) {
    case PROVIDER_TYPE_USECASES.DOCTOR:
      return FORMHEADER_ICON_CLASSNAMES.DOCTOR_ICON;
    case PROVIDER_TYPE_USECASES.DENTIST:
      return FORMHEADER_ICON_CLASSNAMES.DENTIST_ICON;
    case PROVIDER_TYPE_USECASES.VISION:
      return FORMHEADER_ICON_CLASSNAMES.VISION_ICON;
    default:
      return FORMHEADER_ICON_CLASSNAMES.DOCTOR_ICON;
  }
};

export { getFormHeaderClassName };
