const COMPENSATED_PDF_URLS = {
  OH: 'https://file.anthem.com/OH_ACA_80124.pdf',
  GA: 'https://file.anthem.com/GA_ACA_52499.pdf',
  CA: 'https://file.anthem.com/CA_ACA_58152.pdf',
  CO: 'https://file.anthem.com/CO_ACA_32337.pdf',
  CT: 'https://file.anthem.com/CT_ACA_12254.pdf',
  IN: 'https://file.anthem.com/IN_ACA_90259.pdf',
  KY: 'https://file.anthem.com/KY_ACA_72965.pdf',
  MO: 'https://file.anthem.com/MO_ACA_94576.pdf',
  NH: 'https://file.anthem.com/NH_ACA_44990.pdf',
  NV: 'https://file.anthem.com/NV_ACA_25758.pdf',
  WI: 'https://file.anthem.com/WI_ACA_49578.pdf',
  VA: 'https://file.anthem.com/VA_ACA_73082.pdf',
  ME: 'https://file.anthem.com/ME_ACA_21099.pdf'
};

export { COMPENSATED_PDF_URLS };
