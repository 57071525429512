const splitSpecialisations = (
  specialisations: Speciality[]
): { initial: Speciality[]; additional: Speciality[] } => {
  if (specialisations.length <= 2) {
    return {
      initial: specialisations,
      additional: [],
    };
  } else {
    return {
      initial: specialisations.slice(0, 2),
      additional: specialisations.slice(2),
    };
  }
};

export { splitSpecialisations };
