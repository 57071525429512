import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { STATUSES } from '../../../api/constants';
import { SET_APPLICATION_CONSTANTS } from '../../../shared/globalConstants';
import { initialMemberInfo } from '../../../shared/memberInfoDefault';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { LOGIN_RESPONSE_CODES } from '../../public/login/login-modal/constants';
import { getMemberInfoHCID } from '../../public/login/login-modal/loginServices';
import { handleCurrentCoverages } from '../../public/login/login-modal/loginUtils';

interface AgentHCIDComponentProps {
    pageContent: {
        FORM_CONTENT: {
            LABELS: {
                HCID: string;
            };
            PLACE_HOLDERS: {
                HCID: string;
            };
            ERROR_MESSAGES: {
                REQUIRED_FIELD: string;
                MAX_LENGTH_HCID: string;
                INVALID_HCID: string;
            };
        };
    };
    memberInfo: (MemberInfoResponse) => void;
}

const AgentHCIDComponent: React.FC<AgentHCIDComponentProps> = ({ pageContent, memberInfo }) => {
    const [hcidError, setHcidError] = useState(false);

    const { register, watch, setValue, formState: { errors } } = useFormContext();

    /* --------- stores ------------*/

    const stores = {
        globalStore: useGlobalStore(),
        userStore: useUserStore(),
    };

    /* --------- stores ------------*/


    /*---------- HCID getmember info ------------*/

    const fetchMemberInfo = async (webGUID: string = '', hcid: string = ''): Promise<MemberInfo> => {
        try {
            const data = await getMemberInfoHCID(hcid);
            const memberInfoResponse: MemberInfoResponse = data.data;
            if (memberInfoResponse.status === STATUSES.SUCCESS && LOGIN_RESPONSE_CODES.MEMBER_INFO_SUCCESS.includes(memberInfoResponse.responseMessage.responseCode)) {
                return memberInfoResponse.member;
            } else {
                return initialMemberInfo;
            }
        } catch (error) {
            return initialMemberInfo;
        }
    };

    /*---------- HCID getmember info ------------*/

    /*---------- watch -----------*/

    const hcidWatch = watch('hcid');

    useEffect(() => {
        const fetchData = async () => {
            if ((stores.globalStore.agentSSOInfo.memberInfo && stores.globalStore.agentSSOInfo.memberInfo?.hcid !== '') || (hcidWatch && hcidWatch?.length >= 9) && hcidWatch !== stores.globalStore.agentHCID) {
                const memeberInfo = await fetchMemberInfo('', (stores.globalStore.agentSSOInfo.memberInfo?.hcid) || hcidWatch);
                if (memeberInfo.cmcEligible === SET_APPLICATION_CONSTANTS.YES) {
                    stores.globalStore.updateMemberInfo(memeberInfo);
                    handleCurrentCoverages(memeberInfo?.currentCoverages);
                    stores.globalStore.agentUpdateMacPlanTypes(memeberInfo.currentCoverages);
                    stores.globalStore.agentUpdateApplicantFormDetails(memeberInfo.demographicInfo.applicant);
                    memberInfo(memeberInfo);
                    stores.globalStore.agentUpdateHCIDBlocks('zipcodeBlock', true);
                    setHcidError(false);
                    setValue('hcid', memeberInfo.hcid);
                    stores.globalStore.agentUpdateHCID(memeberInfo.hcid);
                } else {
                    setHcidError(true);
                    setValue('hcid', stores.globalStore.agentSSOInfo.memberInfo?.hcid);
                    stores.globalStore.agentUpdateHCIDBlocks('zipcodeBlock', false);
                    stores.globalStore.resetAgentBasicLandingStates();
                }
            } else if (hcidWatch === stores.globalStore.agentHCID && hcidWatch !== '') {
                stores.globalStore.agentUpdateHCIDBlocks('zipcodeBlock', true);
            }
        };
        fetchData();
    }, [stores.globalStore.agentSSOInfo.memberInfo?.hcid, hcidWatch]);
    /*---------- watch -----------*/


    const handleHCIDChange = (e) => {
        if (e.length < 9 || e.length > 10) {
            setHcidError(false);
            return false;
        } else {
            return true
        }
    }

    return (
        <>
            <div className='fwc-row row-relative'>
                <div className='fwc-col-12'>
                    <div className='fwc-row'>
                        <div className='fwc-col-4 md-6 sm-12'>
                            <div className='form-group more'>
                                <div className="fwc-input">
                                    <label className="fwc-label" htmlFor="hcid">
                                        {pageContent?.FORM_CONTENT.LABELS.HCID}
                                    </label>
                                    <div className='zipcode-wrapper'>
                                        <input
                                            id="hcid"
                                            maxLength={10}
                                            placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.HCID}
                                            type="text"
                                            readOnly={((stores.globalStore.agentSSOInfo?.memberInfo && stores.globalStore.agentSSOInfo?.memberInfo?.hcid !== '') ? true : false) || stores.globalStore.isRenewalFlow}
                                            {...register('hcid', {
                                                required: {
                                                    value: true,
                                                    message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD
                                                },
                                                validate: (value) => handleHCIDChange(value)
                                            })}
                                        />
                                    </div>
                                    {errors.hcid && errors.hcid.type === "validate" && (
                                        <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="zip_error">
                                            {pageContent?.FORM_CONTENT.ERROR_MESSAGES.MAX_LENGTH_HCID}
                                        </span>
                                    )}
                                    {errors && errors.hcid && errors.hcid && errors.hcid.type === "required" && (
                                        <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                            {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                hcidError && <div className="fwc-row">
                    <div className="fwc-col-12 form-group">
                        <div className="error-callout">
                            <div className="fwc-flex a-start">
                                <div className="fwc-inline-icon fwc-icon-info error-icon"></div>
                                <div>
                                    <div id="zip_error" dangerouslySetInnerHTML={{ __html: pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_HCID }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default AgentHCIDComponent;