import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getZipcodeValidation } from '../api/services/utilService';
import { AGENT_BROKER_LOGIN_USE_CASES } from '../pages/public/login/agent-broker-login/constants';
import { logoutRedirect } from '../pages/public/login/login-modal/loginServices';
import { MAC_LOGIN_WITH_CRED_REDIRECTION_USE_CASES, MAC_LOGIN_WITH_CRED_URL_PARAMS } from '../pages/public/login/mac-login-with-creds/constants';
import { STANDALONE_LOGIN_REDIRECTION_USE_CASES, STANDALONE_LOGIN_URL_PARAMS } from '../pages/public/login/standalone-login/constants';
import { getEvendorInfo } from '../pages/public/login/standalone-login/standaloneLoginServices';
import { resetUserData } from '../pages/secure/logout/LogoutPupupUtils';
import { useGlobalStore } from '../store/globalStore';
import { GUEST_LOGIN_URL_PARAMS, NAV_PATHS } from './globalConstants';
import { validateEvendorResponse } from './utils/globalUtils';

const GlobalUrlRedirections: React.FC = () => {
  const navigate = useNavigate();
  const {
    updateZipCode,
    updateSelectedCounty,
    updateDeepLinkCounty,
    updateDeepLinkZipcode,
    updateDeepLinkState,
    updateDeepLinkRefSource,
    updateDeepLinkLocale,
    updateDeepLinkYear,
    updateDeepLinkQuoteId,
    updateDeepLinkProposalId,
    updateDeepLinkAcn,
    updateDeepLinkAgentBrokerLoginUseCase,
    updateDeepLinkMedicalContractCode,
    updateDeepLinkDentalContractCode,
    updateDeepLinkVisionContractCode,
    updateDeepLinkQuoteSrc,
    updateDeepLinkLanguage,
    updateDeepLinkDemographicsId,
    updateDeepLinkStandaloneLoginUseCase,
    updateDeepLinkBrand,
    updateDeepLinkHouseholdIncome,
    updateDeepLinkAgentData,
    setIsPublicAgentConnect,
    updateDeepLinkEvendorId,
    updateDeepLinkMacWithCredLoginUseCase,
    updateDeepLinkEvendorInfo,
    updateDeepLinkSource,
    updateHouseholdMemberValues,
    updateZipCodeFormDetails,
    updatePlanTypes,
    updateZipCodeResponse,
    updateIsAgentConnectFlow,
    updateDeepLinkRefID,
    updateDeepLinkCall,
    updateYear
  } = useGlobalStore((state) => state);
  const location = useLocation();
  const previousLocationRef = useRef(location);

  useEffect(() => {
    if (previousLocationRef.current.pathname !== NAV_PATHS.ATK_SESSION_END) {
      previousLocationRef.current = location;
    }

    if (previousLocationRef.current.pathname === NAV_PATHS.ATK_SESSION_END) {
      navigate(NAV_PATHS.ATK_SESSION_END);
    }
  }, [location.pathname]);

  const checkBrand = (): string => {
    const urlParams = new URLSearchParams(location.search);
    let brand = urlParams.get('brand')?.toUpperCase();
    if (brand && window.location.href.includes('anthem.com/') && brand !== 'ABCBS' && brand !== 'ABC') {
      brand = 'ABCBS';
    }

    return brand || '';
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.has('agentType')) {
      const agentData = {
        agentId: urlParams.get('id') || '',
        agentType: urlParams.get('agentType')?.toLocaleUpperCase() || '',
        firstName: urlParams.get('fname') || '',
        lastName: urlParams.get('lname') || '',
        email: urlParams.get('email') || '',
        phone: urlParams.get('phone') || '',
        state: urlParams.get('state') || '',
        rI: urlParams.get('rI') || '',
        address: {
          addressLine1: urlParams.get('addr') || '',
          city: urlParams.get('city') || '',
          state: urlParams.get('state') || '',
          zip: urlParams.get('zip') || ''
        },
        city: urlParams.get('city') || '',
        zip: urlParams.get('zip') || '',
        paidTIN: urlParams.get('paidTIN') || '',
        channel: urlParams.get('channel') || '',
        brand: checkBrand(),
        npn: urlParams.get('npn') || '',
        language: urlParams.get('language') || '',
        parentTin: urlParams.get('parenttin') || '',
        licenseNo: urlParams.get('licenseNO') || '',
        agencyName: urlParams.get('agency') || ''
      };
      updateDeepLinkAgentData(agentData);
      setIsPublicAgentConnect(true);
      updateIsAgentConnectFlow(true);
    } else if (urlParams.has('evendorid') && location.pathname.includes('/profile/login-page')) {
      checkEvendorResponse(urlParams.get(STANDALONE_LOGIN_URL_PARAMS.EVENDORID));
    } else if (urlParams.has(STANDALONE_LOGIN_URL_PARAMS.QUOTE_ID) && location.pathname.includes('/profile/login-page')) {
      updateDeepLinkQuoteId(urlParams.get(STANDALONE_LOGIN_URL_PARAMS.QUOTE_ID) ?? '');
      updateDeepLinkStandaloneLoginUseCase(STANDALONE_LOGIN_REDIRECTION_USE_CASES.AGENT_TELESALES_PROPOSAL);
    } else if (urlParams.has(MAC_LOGIN_WITH_CRED_URL_PARAMS.PROPOSAL_ID)) {
      updateDeepLinkProposalId(urlParams.get(MAC_LOGIN_WITH_CRED_URL_PARAMS.PROPOSAL_ID) ?? '');
      updateDeepLinkMacWithCredLoginUseCase(MAC_LOGIN_WITH_CRED_REDIRECTION_USE_CASES.HPA_PROPOSAL);
    } else if (urlParams.has(GUEST_LOGIN_URL_PARAMS.ACN)) {
      updateDeepLinkAcn(urlParams.get(GUEST_LOGIN_URL_PARAMS.ACN) ?? '');
      updateDeepLinkAgentBrokerLoginUseCase(AGENT_BROKER_LOGIN_USE_CASES.APPLICATION_TRANSFER);
    } else if (urlParams.has(STANDALONE_LOGIN_URL_PARAMS.SOURCE)) {
      updateDeepLinkSource(urlParams.get(STANDALONE_LOGIN_URL_PARAMS.SOURCE) ?? '');
      updateDeepLinkStandaloneLoginUseCase(STANDALONE_LOGIN_REDIRECTION_USE_CASES.USER_LOGIN);
    } else if (urlParams.has(STANDALONE_LOGIN_URL_PARAMS.DEMOGRAPHICS_ID) && location.pathname.includes('/profile/login-page')) {
      updateDeepLinkDemographicsId(urlParams.get(STANDALONE_LOGIN_URL_PARAMS.DEMOGRAPHICS_ID) ?? '');
      updateDeepLinkMedicalContractCode(urlParams.get(STANDALONE_LOGIN_URL_PARAMS.MEDICAL_CONTRACT) ?? '');
      updateDeepLinkDentalContractCode(urlParams.get(STANDALONE_LOGIN_URL_PARAMS.DENTAL_CONTRACT) ?? '');
      updateDeepLinkVisionContractCode(urlParams.get(STANDALONE_LOGIN_URL_PARAMS.VISION_CONTRACT) ?? '');
      updateDeepLinkStandaloneLoginUseCase(STANDALONE_LOGIN_REDIRECTION_USE_CASES.SELF_SENT_EMAIL);
    } else if (location.pathname.includes('/profile/login-page')) {
      updateDeepLinkStandaloneLoginUseCase(STANDALONE_LOGIN_REDIRECTION_USE_CASES.USER_LOGIN);
    }
    if (location.search.includes('healthcompass')) {
      setupZipcodeRes(urlParams);
    }
    if (urlParams.has('cid')) {
      updateDeepLinkRefSource('healthCompass');
      if (location.search.includes('healthcompass') && urlParams.get('cid') === '1K57T') {
        updateDeepLinkRefID('1L62R');
      } else {
        updateDeepLinkRefID(urlParams.get('cid') ?? '');
      }
     
      updateDeepLinkCall(urlParams.get('cidPhone') ?? '');
    }

    if (location.pathname.includes('logoutSM')) {
      resetUserData();
      logoutRedirect(urlParams.get('userRole')?.toUpperCase() || '');
    } // for logging out when redirected from legacy to new reimagined site

    urlParams.forEach((value, key) => {
      if (key === 'zipcode') {
        updateZipCode(value);
        updateDeepLinkZipcode(value);
      } else if (key === 'county') {
        updateSelectedCounty(value);
        updateDeepLinkCounty(value);
      } else if (key === 'state') {
        updateDeepLinkState(value);
      } else if (key === 'refSource') {
        updateDeepLinkRefSource(value);
      } else if (key === 'locale') {
        updateDeepLinkLocale(value);
      } else if (key === 'year') {
        updateYear(value);
        updateDeepLinkYear(value);
      } else if (key === 'brand') {
        updateDeepLinkBrand(checkBrand());
      } else if (key === STANDALONE_LOGIN_URL_PARAMS.LANGUAGE) {
        updateDeepLinkLanguage(value);
      } else if (key === 'householdIncome') {
        updateHouseholdMemberValues({ houseHoldIncome: value, dependents: '0' });
        updateDeepLinkHouseholdIncome(value);
      }
    });
  }, []);

  const setupZipcodeRes = async (urlParams) => {
    const zipcodeResp = await fetchDataForCounty(urlParams.get('zipcode'));
    updateZipCodeFormDetails(urlParams.get('zipcode'), urlParams.get('county'), urlParams.get('year'));
    updatePlanTypes(urlParams.get('county'), urlParams.get('year'));
  };

  const checkEvendorResponse = async (evendorid) => {
    if (!evendorid) {
      navigate(NAV_PATHS.APP_ERROR_PAGE);
      return;
    }
    const evendorIdResponse = await loadEvendorInfo(evendorid);
    if (!evendorIdResponse || !validateEvendorResponse(evendorIdResponse)) {
      navigate(NAV_PATHS.APP_ERROR_PAGE);
    } else {
      updateDeepLinkEvendorId(evendorid);
      updateDeepLinkEvendorInfo(evendorIdResponse.eVendorInfo);
      updateDeepLinkStandaloneLoginUseCase(STANDALONE_LOGIN_REDIRECTION_USE_CASES.EVENDOR);
    }
  };

  const loadEvendorInfo = async (evendorid: string): Promise<EvenderInfoResponse | null> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await getEvendorInfo(evendorid);
        const data: EvenderInfoResponse = response.data;
        resolve(data);
      } catch (error) {
        console.warn('GlobalUrlRedirection loadEvendorInfo error :', error);
        resolve(null);
      }
    });
  };

  const fetchDataForCounty = async (zipcode): Promise<boolean> => {
    let returnval = false;
    try {
      const response = await getZipcodeValidation({ zipCode: zipcode, marketSegment: 'UNDER_65' });
      if (response.data.zipCodeResponse.responseMessage.message === 'SUCCESSFULLY FETCHED') {
        updateZipCodeResponse(response.data.zipCodeResponse.zipCode);
        returnval = true;
      } else {
        console.error('Error fetching county data globalUrlRedirection');
        returnval = false;
      }
    } catch (error) {
      console.error('Error fetching county data globalUrlRedirection:', error);
      returnval = false;
    } finally {
      return returnval;
    }
  };
  return null;
};
export default GlobalUrlRedirections;
