const getInitialProductTypeFilters = (productTypeFilters: CheckBoxFilterOption[], plansForTab: Contracts, labels: any): CheckBoxFilterOption[] => {
  return productTypeFilters.map((filter) => {
    return { ...filter, disabled: getPlansByProductType(plansForTab, filter.value).length == 0, label: labels[filter.key] };
  });
};

const getPlansByProductType = (products: Contracts, productType: string): Contracts => {
  return products.filter((product) => product.plan[0].productType === productType);
};

export { getInitialProductTypeFilters, getPlansByProductType };
