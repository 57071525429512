import { EditableComponent, MapTo } from '@adobe/aem-react-editable-components';
import React from 'react';

import { withConditionalPlaceHolder } from '../core/util/withConditionalPlaceholder';
import { withStandardBaseCssClass } from '../core/util/withStandardBaseCssClass';
import MediaCard, { MediaCardIsEmpty } from "./MediaCard";

const RESOURCE_TYPE = 'ios/components/mediacard';

const EditConfig = {
  // Define your edit configuration here
  emptyLabel: 'Media Card',
  isEmpty: MediaCardIsEmpty,
  resourceType: RESOURCE_TYPE
};
export const WrappedMediaCard = (props) => {
  const Wrapped = withConditionalPlaceHolder(withStandardBaseCssClass(MediaCard, 'mediacard-container'), MediaCardIsEmpty, 'Media Card');
  return <Wrapped {...props} />;
};

const EditableMediaCard = (props) => (
  <EditableComponent config={EditConfig} {...props}>
    <WrappedMediaCard />
  </EditableComponent>
);


MapTo(RESOURCE_TYPE)(EditableMediaCard);

export default EditableMediaCard;