import { Button, Modal } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from 'react';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { CONFLICT_OPTION_LABELS, ERROR_ALERT_CONFIG, LANGUAGE_CODES, SCREEN_NAMES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';

import InfoBar from '../../../components/common/info-bar/InfoBar';
import Content from './AgentConflictPopup.json';
import './AgentConflictPopup.scss';

const AgentConflictPopup: React.FC<AgentConflictPopupProps> = ({ showPopup, currentAgentDetails, previousAgentConnectDetails, onClick, popupClose }) => {
    const content = Content.data.iospagecontentList.items[0].pageContent.AgentConflictPopup;
    const [selectedOption, setSelectedOption] = useState('');
    const [pageContent, setContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const { zipCodeRes } = useGlobalStore(state => state);
    const [labels, setLabels] = useState<any>();
    const [notSelectedError, setNotSelectedError] = useState(false);

    const getContent = useCallback(async (): Promise<boolean> => {
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.AGENT_CONFLICT_POPUP);
            let cmsResponse = response.data;
            setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.AgentConflictPopup);
            // setContent(content)
        } catch (error) {
            setContent(content);

            return false;
        } finally {
            setContentLoaded(true);
        }
        return true;
    }, []);

    const handleContinue = () => {
        if (selectedOption) {
            popupClose();
        }
        else {
            setNotSelectedError(true);
        }
    };

    const handleOptionChange = (selectedVal: string) => {
        setSelectedOption(selectedVal);
    };

    const initiate = async () => {
        await getContent();
    }

    useEffect(() => {
        initiate();
    }, []);


    useEffect(() => {
    }, [selectedOption]);

    useEffect(() => {
        if (contentLoaded) {

            setLabels(pageContent?.POPUP_LABELS);

        }
    }, [contentLoaded]);

    const getAgentDetailsinHTMLFormat = (agentDetails: AgentConnect | AgentOfRecord) => {
        return (
            agentDetails?.firstName && agentDetails?.lastName ? (
                <div>
                    <div>
                        <div className="agentconnect-name">
                            <span>{agentDetails.firstName}{' '}
                                {agentDetails.lastName}</span></div>
                    </div>
                </div>)
                :
                (
                    <div className="agencyconnect-name">
                        <span>{agentDetails.agencyName}</span>
                        <span>{agentDetails.phone}</span>
                        <span>{agentDetails.email}</span>
                        <span>{agentDetails.address?.addressLine1}</span>
                        <span>{agentDetails.address?.city}</span>
                        <span>{agentDetails.address?.state}</span>
                        <span>{(agentDetails as AgentOfRecord).address.postalCode ? (agentDetails as AgentOfRecord).address.postalCode : (agentDetails as AgentConnect).address.zip}</span>
                        <span>{agentDetails.npn}</span>
                        {(agentDetails as AgentConnect).licenseNo && <span>{(agentDetails as AgentConnect).licenseNo}</span>}
                    </div>
                )

        );
    };


    return (
        !contentLoaded ? <div></div> : (
            <div className='logout-popup-wraper'>
                <Modal open={showPopup} onClose={() => popupClose()} title={labels?.TITLE} >
                    <Modal.Body className="popup-body" data-testid="quote-conflict">
                        <fieldset className="fwc-radio-group-conflict-wrapper">
                            <legend>{labels?.LEGEND}</legend>
                            <div className="fwc-radio-group-conflict">
                                <div className={`radio-box-shadow ${selectedOption === CONFLICT_OPTION_LABELS.OPTION1 ? "selected-radio" : ''}`}
                                    onClick={(e) => {
                                        handleOptionChange(CONFLICT_OPTION_LABELS.OPTION1);
                                        onClick(CONFLICT_OPTION_LABELS.OPTION1);
                                    }}>
                                    <div className="fwc-radio " >
                                        <input
                                            type="radio"
                                            value={CONFLICT_OPTION_LABELS.OPTION1}
                                            checked={selectedOption === CONFLICT_OPTION_LABELS.OPTION1}
                                            onChange={(e) => {
                                                handleOptionChange(CONFLICT_OPTION_LABELS.OPTION1)
                                            }}
                                        />
                                        <label className="fwc-label" htmlFor="option1">
                                            <span>
                                                <i className="sae-icon sae-icon-circled"></i>
                                            </span>
                                            <span>
                                                <span className='option-name'>
                                                    {labels?.OPTION1}</span>
                                                <p className='description'>
                                                    {getAgentDetailsinHTMLFormat(currentAgentDetails)}
                                                </p>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className='text-or-class'> {pageContent?.OR}</div>
                                <div className={`radio-box-shadow ${selectedOption === CONFLICT_OPTION_LABELS.OPTION2 ? "selected-radio" : ''}`} onClick={(e) => {
                                    handleOptionChange(CONFLICT_OPTION_LABELS.OPTION2);
                                    onClick(CONFLICT_OPTION_LABELS.OPTION2);
                                }}>
                                    <div className="fwc-radio " >
                                        <input
                                            type="radio"
                                            value={CONFLICT_OPTION_LABELS.OPTION2}
                                            checked={selectedOption === CONFLICT_OPTION_LABELS.OPTION2}
                                            onChange={(e) => {
                                                handleOptionChange(CONFLICT_OPTION_LABELS.OPTION2)
                                            }}
                                        />
                                        <label className="fwc-label" htmlFor="option2">
                                            <span>
                                                <i className="sae-icon sae-icon-circled"></i>
                                            </span>
                                            <span>
                                                <span className='option-name'>  {labels?.OPTION2}</span>
                                                <p className='description'>{getAgentDetailsinHTMLFormat(previousAgentConnectDetails)}</p>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <span className='description' dangerouslySetInnerHTML={{ __html: labels?.NOTE }}></span>
                        <Modal.Footer>
                            <div className="popup-buttons" id="popup-buttons-wrapper">
                                <Button btnType="primary" id="continue-button" onClick={handleContinue}
                                    data-testid="continue-button">
                                    {labels?.CONTINUE}
                                </Button>
                            </div>
                        </Modal.Footer>
                        {notSelectedError && (
                            <div className="error-container">
                                <InfoBar
                                    area={ERROR_ALERT_CONFIG.AREA}
                                    backgroundColor={'error'}
                                    labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
                                    type={ERROR_ALERT_CONFIG.ERROR}
                                    onClose={() => {
                                        setNotSelectedError(false);
                                    }}
                                >
                                    <p className="message">{pageContent?.ALERT}</p>
                                </InfoBar>
                            </div >

                        )}
                    </Modal.Body>
                </Modal>


            </div >
        )
    );
};

export default AgentConflictPopup;