import dayjs from 'dayjs';
import React, { useState } from 'react';
import DateInput from '../../../components/common/date-input/DateInput';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import './PinDobValidation.scss';

import { validateDob } from '../../../pages/public/login/mac-login-with-anthem-card/macLoginWithAnthemCardUtils';
import { DATE_FORMATS, ERROR_ALERT_CONFIG } from '../../../shared/globalConstants';
import { formatDateToMMDDYYY } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import InfoBar from '../info-bar/InfoBar';
import { COMPENSATED_PDF_URLS } from './constant';
import { validatePin } from './pinDobValidationUtils';


const PinDobValidation: React.FC<PinDobValidationProps> = ({ labels, error, hideContinueButton, initiatePinDobValidation, onErrorPopupClose }) => {
    const [pin, setPin] = useState('');
    const [pinNumberError, setPinNumberError] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [dobError, setDOBError] = useState('');
    const [loading, setLoading] = useState(false);

    const { deepLinkInfo } = useGlobalStore((state) => state);
    const { state } = deepLinkInfo;

    const handlePINNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPinNumberError('');
        onErrorPopupClose();
        setPin(e.target.value);
    };

    const handleDateOfBirthChange = (dob) => {
        setDOBError('');
        onErrorPopupClose();
        if (dob) {
            const updatedDobValue = formatDateToMMDDYYY(dob);
            setDateOfBirth(updatedDobValue);
        } else {
            setDateOfBirth('');
        }
    };

    const resetErrors = () => {
        setPinNumberError('');
        setDOBError('');
    };

    const checkPinNumber = () => {
        const { isValidPin, pinErrorMessage } = validatePin(pin, labels);

        if (!isValidPin) {
            setPinNumberError(pinErrorMessage);
        } else {
            setPinNumberError('');
        }

        return isValidPin;
    };

    const checkDob = (date: any, isContinueClicked = false) => {
        const { isValidDob, dobErrorMessage } = validateDob(date?.target?.value ? date?.target?.value : dateOfBirth, isContinueClicked, labels);

        if (!isValidDob) {
            setDOBError(dobErrorMessage);
        } else {
            setDOBError('');
        }

        return isValidDob;
    };


    const validateCredentials = () => {
        resetErrors();
        const isValidPin = checkPinNumber();
        const isValidDob = checkDob(dateOfBirth, true);

        if (isValidPin && isValidDob) {
            initiatePinDobValidation(pin, dateOfBirth);
            //Local validations passed
        }
    };

    const getURL = () => {
        return COMPENSATED_PDF_URLS[state];
    }

    return (
        <div className="pin-dob-validation-outer-wrapper">
            <div className='fwc-row'>
                <div className="pin-dob-validation-form-wrapper">
                    {loading && <FullPageLoader />}
                    <div className="pin-dob-validation-heading">
                        <h2 className="heading">{labels.VALIDATION_FORM_HEADER}</h2>
                        <span className="description">{labels.VALIDATION_FORM_DESCRIPTION}</span>
                    </div>
                    <div className={'fwc-input input-container'}>
                        <label id="validation-pin-label" className="fwc-label" htmlFor={'validation-pin-input'}>
                            {labels.PIN_FROM_YOUR_EMAIL}
                        </label>
                        <input id="validation-pin-input" type="text" value={pin} onChange={(e) => handlePINNumber(e)} maxLength={12} />
                        {pinNumberError?.length > 0 && (
                            <span id="validation-pin-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                                {pinNumberError}
                            </span>
                        )}
                    </div>
                    <div className={'fwc-input validation-dob-input-container'}>
                        <label id="validation-dob-label" className="fwc-label" htmlFor={'validation-dob-input'}>
                            {labels.DATE_OF_BIRTH} <span className="validation-dob-helper-text">{labels.DOB_FORMAT}</span>
                        </label>
                        <DateInput
                            className="validation-dob-input"
                            data-testid="validation-dob-input"
                            id="validation-dob-input"
                            name="dob"
                            defaultDate={dateOfBirth}
                            maxDate={dayjs().format(DATE_FORMATS.YYYYMMDD)}
                            onDateChange={handleDateOfBirthChange}
                        />
                        {dobError && (
                            <span id="validation-dob-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                                {dobError}
                            </span>
                        )}
                    </div>
                    {error.hasError && !loading && (
                        <InfoBar
                            area={ERROR_ALERT_CONFIG.AREA}
                            backgroundColor={'error'}
                            labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
                            type={ERROR_ALERT_CONFIG.ERROR}
                            handleClose={() => {
                                onErrorPopupClose();
                            }}
                        >
                            <p className="message" dangerouslySetInnerHTML={{ __html: error.message }} />
                        </InfoBar>
                    )}
                    <div className="pin-dob-validation-button-wrapper">
                        {!hideContinueButton && <button
                            id="pin-dob-validation-button"
                            className={'fwc-btn fwc-btn-primary fwc-col-12 button-text'}
                            onClick={() => {
                                validateCredentials();
                            }}
                        >
                            {labels.VALIDATION_BUTTON}
                        </button>}
                    </div>
                    {Object.keys(COMPENSATED_PDF_URLS).includes(state) &&
                        <div className="compensated-wrp">
                            <p className="compensated-text">{labels.COMPENSATED_TEXT}{' '}<span className="pdf-link" onClick={() => window.open(`${getURL()}`, "_blank")}>{labels.HERE}</span></p>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default PinDobValidation