import dayjs from 'dayjs';
import React from 'react';
import DateInput from '../date-input/DateInput';
import './AccountRecoveryForm.scss';

const AccountRecoveryForm = ({
  formHeading,
  firstNameConfig,
  lastNameConfig,
  emailConfig,
  dobConfig
}: {
  formHeading: string;
  firstNameConfig: {
    value: string;
    label: string;
    error: string;
    onChange: (e: { target: { value: React.SetStateAction<string> } }) => void;
    onBlur: () => void;
  };
  lastNameConfig: {
    value: string;
    label: string;
    error: string;
    onChange: (e: { target: { value: React.SetStateAction<string> } }) => void;
    onBlur: () => void;
  };
  emailConfig: {
    label: string;
    value: string;
    error: {
      title: string;
      label1: string;
      label2: string;
      label3: string;
    };
    onChange: (e: { target: { value: React.SetStateAction<string> } }) => void;
    onBlur: () => void;
  };
  dobConfig: {
    label: string;
    error: string;
    value: string;
    onChange: (dob: any) => void;
    onBlur: (date: string, isNextClicked: boolean) => void;
  };
}) => {
  return (
    <div className="forgot-UP-form-wrapper">
      <div className="fwc-row form-label">
        <h3>{formHeading}</h3>
      </div>
      <div className="fwc-row forgot-UP-field-wrapper">
        <div className="fwc-col-6 sm-12 fwc-row-bm forgot-UP-field">
          <div className="fwc-input">
            <label className="fwc-label" htmlFor='firstName-input'>{firstNameConfig.label}</label>
            <input
              id={'firstName-input'}
              name={'firstName'}
              type="text"
              value={firstNameConfig.value}
              onChange={firstNameConfig.onChange}
              onBlur={firstNameConfig.onBlur}
            />
            {firstNameConfig.error && (
              <span id="login-modal-username-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                {firstNameConfig.error}
              </span>
            )}
          </div>
        </div>

        <div className="fwc-col-6 sm-12 fwc-row-bm forgot-UP-field">
          <div className="fwc-input">
            <label className="fwc-label" htmlFor='lastName-input'>{lastNameConfig.label}</label>
            <input
              id={'lastName-input'}
              name={'lastName'}
              type="text"
              value={lastNameConfig.value}
              onChange={lastNameConfig.onChange}
              onBlur={lastNameConfig.onBlur}
            />
            {lastNameConfig.error && (
              <span id="login-modal-username-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                {lastNameConfig.error}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="fwc-row forgot-UP-field-wrapper">
        <div className="fwc-col-6 sm-12 fwc-row-bm forgot-UP-field">
          <div className="fwc-input">
            <label className="fwc-label" htmlFor='email-input'>{emailConfig.label}</label>
            <input
              id={'email-input'}
              name={'email'}
              type="text"
              maxLength={Math.max(0, 100)}
              value={emailConfig.value}
              onChange={emailConfig.onChange}
              onBlur={emailConfig.onBlur}
            />
            {emailConfig.error.title && (
              <div>
                <span id="account-recovery-email-error" role="alert" className="fwc-inline-icon fwc-icon-delete padding-top">
                  {emailConfig.error.title}
                </span>
                <ul>
                  {emailConfig.error.label1 && <li className="error-message">{emailConfig.error.label1}</li>}
                  {emailConfig.error.label2 && <li className="error-message">{emailConfig.error.label2}</li>}
                  {emailConfig.error.label3 && <li className="error-message">{emailConfig.error.label3}</li>}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="fwc-col-6 sm-12 fwc-row-bm forgot-UP-field forgot-UP-field-dob">
          <div className="fwc-input">
            <label className="fwc-label" htmlFor="dob">
              {dobConfig.label}
            </label>
            <DateInput
              className="account-recovery-date-picker"
              id="dob"
              name="dob"
              reset={dobConfig.value === ''}
              defaultDate={dobConfig.value}
              maxDate={dayjs().format('YYYY-MM-DD')}
              onBlur={(e) => dobConfig.onBlur(e.target.value, false)}
            />
            {dobConfig.error && (
              <span id="account-recovery-dob-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                {dobConfig.error}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountRecoveryForm;
