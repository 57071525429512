import React from 'react';

import CheckBoxListFilter from '../../../components/common/check-box-list-filter/CheckBoxListFilter';
import SwitchListFilter from '../../../components/common/switch-list-filter/SwitchListFilter';

import './MoreFilter.scss';

const MoreFilters: React.FC<MoreFilterProps> = ({ labels, switchFilters, checkBoxFilters, onSwitchFilterApply, onCheckBoxFilterApply }) => {
  return (
    <div className="more-filter-container">
      <div className="switch-filter-container">
        <SwitchListFilter switchFilters={switchFilters} onSwitchFilterApply={onSwitchFilterApply} />
      </div>
      <div className="benefit-tier-filter-container">
        <h5 className="checkbox-filter-label">
          {labels.BENEFIT_TIER}
        </h5>
        <CheckBoxListFilter filterOptions={checkBoxFilters} showFooterButtons={false} onApplyClick={onCheckBoxFilterApply} />
      </div>
    </div>
  );
};

export default MoreFilters;
