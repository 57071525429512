import { Button, Modal, Paragraph, Popover } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EmptyPlans from '../../../components/common/empty-plans/EmptyPlans';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import GlobalHeader from '../../../components/common/global-header/GlobalHeader';
import PlanCoverageAccordion from '../../../components/common/plan-coverage-accordion/PlanCoverageAccordion';
import ProductCard from '../../../components/common/product-card/ProductCard';
import ProductReviewTray from '../../../components/common/product-review-tray/ProductReviewTray';
import ShopperTabs from '../../../components/common/shopperTabs/ShopperTabs';
import DentalPlanDetails from '../../public/dental-plan-details/DentalPlanDetails';
import FindYourDentist from '../../public/find-your-dentist/findYourDentist';
import FindYourDoctor from '../../public/find-your-doctor/FindYourDoctor';
import FindYourEyeDoctor from '../../public/find-your-eye-doctor/findYourEyeDoctor';
import MedicalPlanDetails from '../../public/medical-plan-details/MedicalPlanDetails';
import ProviderDetails from '../../public/provider-details/ProviderDetails';
import ShowMyMedications from '../../public/show-my-medications/showMyMedications';
import VisionPlanDetails from '../../public/vision-plan-details/VisionPlanDetails';
import ProductSelectionFilters from './Filters/ProductSelectionFilters';

import { getCMSStaticContent, getCMSdynamicContent } from '../../../api/cms/cmsUtil';
import { getFormattedCurrency } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { getUpdatedMacLoginTabs } from '../../public/login/mac-login/macLoginUtils';
import { displayStars } from '../../public/medical-plan-details/medicalPlanDetailsUtils';
import { fetchPlanList } from '../../public/medical-plans/medicalPlansServices';
import { getActiveSortValue, getContractCodes, getEmptyPlansUseCase, getFormattedApplicantDetails, getMatchedDoctorsForPlan, getMatchedMedicationsForPlan, getPlansForTab, getSortedPlans } from '../../public/medical-plans/medicalPlansUtils';
import { getDrugsPlanSummary } from '../../public/show-my-medications/medicationSearchServices';
import { getContractCodesInFormat } from '../../public/show-my-medications/medicationServicesUtils';
import { atkSaveProposal } from './productSelectionServices';
import { deleteSelectedDentalProduct, deleteSelectedVisionProduct, getATKsaveProposalRequestPayload, getDentalProducutsPayload, getFilteredMedicalProducts, getMedicalProducutsPayload, getOptionalProducts, getPlanTypeLabel, getPrimaryProductTypeByCoverageType, getUpdatedProductsForReview, getUpdatedSelectedProductsByCoverageType, getVisionProductsPayload, updatedSelectedDentalProducts, updatedSelectedVisionProducts } from './productSelectionUtils';

import { EMPTY_PLANS_USE_CASES } from '../../../components/common/empty-plans/constants';
import { getDentalProduct, getVisionProduct } from '../../../components/common/product-card/productCardUtils';
import { AGENT_ROLES, LANGUAGE_CODES, NAV_PATHS, NOT_RATED_PLAN, NV_STATE, OVERVIEW_RATING_URL, PAGE_BLOCKS, PAGE_IDS, PLAN_TYPES, PLAN_TYPES_NAMES, SCREEN_NAMES, STATE_CODES } from '../../../shared/globalConstants';
import { MONTHLY_COST_SORT_OPTION } from '../../public/medical-plans/constants';
import { getStandAloneVisionPlans } from '../../public/vision-plans/visionPlansUtils';
import './ProductSelection.scss';
import { COLARADO_CONNECT_TAB, MARKET_PLANS_TAB, OFF_MARKET_PLANS_TAB } from './constants';
import { default as Content } from './productSelection.json';

const ProductSelection = () => {
    const [loading, setLoading] = useState(false)
    const [pageContent, setPageContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [showFindYourDoctor, setShowFindYourDoctor] = useState(false);
    const [showFindYourDentist, setShowFindYourDentist] = useState(false);
    const [showFindYourEyeDoctor, setShowFindYourEyeDoctor] = useState(false);
    const [showMyMedications, setShowMyMedications] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [productSelectionTabs, setProductSelectionTabs] = useState([OFF_MARKET_PLANS_TAB, MARKET_PLANS_TAB])
    const [drugList, setDrugList] = useState('');
    const [filteredProducts, setFilteredProducts] = useState<Contracts>([]);
    const [clickedProduct, setClickedProduct] = useState<Contract>({} as Contract);
    const [ratingDetailsModalContent, setRatingDetailsModalContent] = useState<QualityRating | boolean>(false);
    const [showOverallRatingModal, setShowOverallRatingModal] = useState(false);
    const [productAnchor, setProductAnchor] = useState<React.SetStateAction<null> | (EventTarget & HTMLSpanElement)>(null);
    const [productpopoverOpen, setProductPopoverOpen] = useState(false);
    const [dropdownUseCase, setDropdownUseCase] = useState({ useCase: '', primaryProductContractCode: '' });
    const [primaryProductInFocus, setPrimaryProductInFocus] = useState({} as Contract);

    const importantNotice = useRef<HTMLDivElement>(null);
    const divRef = useRef(null);
    const [loginError, setLoginError] = useState({
        hasError: false,
        responseCode: '',
        message: ''
    });
    const navigate = useNavigate()

    const {
        selectedProductTab,
        medicalProducts,
        productTypeFilters,
        productsSortOptions,
        productsSwitchFilters,
        productsBenefitsTiers,
        savedProviders,
        matchProvidersToPlans,
        matchMedicationsToPlans,
        drugsList,
        coveredMedicationsList,
        agentBrand,
        comparedProducts,
        providerMetaData,
        selectedProduct,
        agentState,
        agentYear,
        agentApplicantFormDetails,
        agentCoverageType,
        savedDentists,
        matchDentistsToPlans,
        savedEyeDoctors,
        matchEyeDoctorsToPlans,
        dentalProducts,
        visionProducts,
        selectedDentalProducts,
        selectedVisionProducts,
        agentSSOInfo,
        agentZipCodeRes,
        isHCIDFlow,
        updateATKsaveProposalResponse,
        updateSelectedDentalProducts,
        updateSelectedVisionProducts,
        updateMedicalProducts, updateDentalProducts, updateVisionProducts, updateSelectedProductTab, updateComparedProducts, updateSelectedProduct, updatedCoveredMedicationsList, aTKsaveProposalResponse } = useGlobalStore(state => state)
    const { isWellpoint } = useUserStore()
    const primaryProductType = getPrimaryProductTypeByCoverageType(agentCoverageType)

    useEffect(() => {
        initiate();
    }, []);

    useEffect(() => {
        if (drugsList.length && !coveredMedicationsList.length) {
            let payload: DrugSummaryPayload = { planList: { plan: getContractCodesInFormat(getContractCodes(useGlobalStore.getState().medicalProducts)) }, drugList: { drug: drugsList } };
            getDrugsPlanSummary(payload)
                .then((response) => {
                    const data: DrugsSummaryResponse = response.data;
                    const planSummaryList: MedicationsPlanSummary[] = data.response.planSummaryList.planSummary;
                    updatedCoveredMedicationsList(planSummaryList);
                })
                .catch((error) => {
                    console.error('retrieving the matched drugs info error :', error);
                });
        }
    }, [matchMedicationsToPlans, medicalProducts])

    const updateReviewedProducts = () => {
        const updatedComparedProducts: SelectedProducts = [];
        comparedProducts.forEach((comparedProduct: SelectedProduct) => {
            let primaryProduct = primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT ? comparedProduct.medicalProduct : comparedProduct.dentalProduct ?? comparedProduct.visionProduct;
            primaryProduct = primaryProduct ?? {} as Contract
            const updatedComparedProduct: SelectedProduct = {
                ...comparedProduct,
                matchedDoctors: getMatchedDoctorsForPlan(matchProvidersToPlans, savedProviders, comparedProduct?.medicalProduct?.contractCode ?? ''),
                matchedMedications: getMatchedMedicationsForPlan(matchMedicationsToPlans, drugsList, comparedProduct?.medicalProduct?.contractCode ?? '', coveredMedicationsList),
                dentalProduct: primaryProductType === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? comparedProduct.dentalProduct : getDentalProduct(primaryProduct)?.dentalProduct,
                dentalApplicants: useGlobalStore.getState().selectedProduct.dentalApplicants,
                matchedDentists: primaryProductType === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? getMatchedDoctorsForPlan(matchDentistsToPlans, savedDentists, comparedProduct.dentalProduct?.contractCode ?? '') : getMatchedDoctorsForPlan(matchDentistsToPlans, savedDentists, getDentalProduct(primaryProduct)?.dentalProduct?.contractCode ?? ''),
                visionProduct: primaryProductType === PLAN_TYPES_NAMES.VISION_PRODUCT ? comparedProduct.visionProduct : getVisionProduct(primaryProduct)?.visionProduct,
                visionApplicants: useGlobalStore.getState().selectedProduct.visionApplicants,
                matchedEyeDoctors: primaryProductType === PLAN_TYPES_NAMES.VISION_PRODUCT ? getMatchedDoctorsForPlan(matchEyeDoctorsToPlans, savedEyeDoctors, comparedProduct?.visionProduct?.contractCode ?? '') : getMatchedDoctorsForPlan(matchEyeDoctorsToPlans, savedEyeDoctors, getVisionProduct(primaryProduct)?.visionProduct?.contractCode ?? '')
            }

            updatedComparedProducts.push(updatedComparedProduct)

        })
        updateComparedProducts(updatedComparedProducts)
    }

    useEffect(() => {
        //When a new dental or vision product is added or removed
        updateReviewedProducts()
    }, [selectedDentalProducts, selectedVisionProducts])

    useEffect(() => {
        //When a new medication is added or removed
        if (matchMedicationsToPlans) {
            updateReviewedProducts()
        }
    }, [drugsList, matchMedicationsToPlans])

    useEffect(() => {
        // when a new provider is added or removed
        if (matchProvidersToPlans) {
            updateReviewedProducts()
        }
    }, [savedProviders, matchProvidersToPlans])

    useEffect(() => {
        //when a new dentist is added or removed
        if (matchDentistsToPlans) {
            updateReviewedProducts()
        }
    }, [savedDentists, matchDentistsToPlans])

    useEffect(() => {
        //when a new eye doctor is added or removed
        if (matchEyeDoctorsToPlans) {
            updateReviewedProducts()
        }
    }, [savedEyeDoctors, matchEyeDoctorsToPlans])


    const getDrugList = async () => {
        const queryParams = ';state=' + agentState + ';brand=' + agentBrand + ';year=' + agentYear;
        await getCMSdynamicContent(PAGE_IDS.DRUG_LIST_ALL, PAGE_BLOCKS.DRUG_LIST, queryParams)
            .then((response) => {
                setDrugList(response?.data?.data?.iosdynamiccontentList?.items[0]?.textsummary);
            })
            .catch((error) => {
                console.warn('Product Selection getDrugList error: ', error);
                // check what to do here
            });
    };

    const getContent = useCallback(async (): Promise<boolean> => {
        let content: any = null;
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.PRODUCT_SELECTION);
            let cmsResponse = response.data;
            content = cmsResponse.data.iospagecontentList.items[0].pageContent?.ProductSelection

        } catch (error) {
            console.warn('ProductSelection.tsx getContent error: ', error);
            content = Content?.data.iospagecontentList.items[0].pageContent?.ProductSelection;

            return false;
        }
        finally {
            let tabs: ShopperTab[] = [];
            if (checkIfAllApplicantsMedicaidEligible() && agentState !== STATE_CODES.CO && primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT) {
                tabs = [OFF_MARKET_PLANS_TAB];
            } else if (checkIfAllApplicantsMedicaidEligible() && agentState === STATE_CODES.CO && primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT) {
                tabs = [OFF_MARKET_PLANS_TAB, COLARADO_CONNECT_TAB];
            } else if (agentState === STATE_CODES.CO && primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT) {
                if (checkIfMarketPlaceEligible()) {
                    tabs = [OFF_MARKET_PLANS_TAB, MARKET_PLANS_TAB, COLARADO_CONNECT_TAB];
                } else {
                    tabs = [OFF_MARKET_PLANS_TAB, COLARADO_CONNECT_TAB];
                }
            } else {
                if (checkIfMarketPlaceEligible()) {
                    tabs = [OFF_MARKET_PLANS_TAB, MARKET_PLANS_TAB];
                } else {
                    tabs = [OFF_MARKET_PLANS_TAB];
                }
            }
            setPageContent(content)
            setProductSelectionTabs(getUpdatedMacLoginTabs(tabs, content))
            setContentLoaded(true);
        }
        return true;
    }, []);

    const checkIfMarketPlaceEligible = () => {
        const agentType = agentSSOInfo.agentType;
        const stateCode = agentZipCodeRes.stateCode;
        const selectedStateObj = agentSSOInfo.agentInfo.agent[0].states.state.find((state) => state.stateName === stateCode);
        if (agentType === AGENT_ROLES.HPA) {
            return true;
        } else if ((agentType !== AGENT_ROLES.HPA && stateCode === NV_STATE && (selectedStateObj && selectedStateObj.licenseNumber && selectedStateObj.licenseNumber !== '') && (selectedStateObj && selectedStateObj.FFM && selectedStateObj.FFM !== '')) ||
            (agentType !== AGENT_ROLES.HPA && ((selectedStateObj && selectedStateObj.licenseNumber && selectedStateObj.licenseNumber !== '') && ((selectedStateObj && selectedStateObj.FFM && selectedStateObj.FFM !== '') || (selectedStateObj && selectedStateObj.certNo && selectedStateObj.certNo !== ''))))) {
            return true;
        } else {
            return false;
        }
    }

    const checkIfAllApplicantsMedicaidEligible = (): boolean => {
        return agentApplicantFormDetails.every((applicant) => applicant?.isMedicAidEligible)
    }

    const initiate = async () => {
        setLoading(true);

        updateDependents()
        setServerError(false)
        await getContent();
        await getDrugList();
        await getPlans()
    };

    const updateDependents = () => {
        const updatedSelectedProducts = getUpdatedSelectedProductsByCoverageType(selectedProduct, primaryProductType, agentApplicantFormDetails)

        updateSelectedProduct(updatedSelectedProducts)
    }

    const closeCurrentTab = () => {
        try {
            navigate(NAV_PATHS.ATK_SESSION_END);
        } catch (error) {
            console.warn('ProductSelection.tsx closeCurrentTab error: ', error)
        }
    }

    const sortMedicalProducts = (sortOption: SortOption) => {
        setLoading(true)
        const sortedPlans = getSortedPlans(filteredProducts, sortOption);
        setFilteredProducts(sortedPlans);
        setLoading(false)
    };

    const parseInitialMedicalProducts = (medicalProducts: Contracts, updatePlansToStore: boolean = true) => {
        const filterObj: ProductFilters = {
            selectedProductTab,
            productTypeFilters,
            productsSwitchFilters,
            productsBenefitsTiers,
        };

        if (updatePlansToStore) {
            updateMedicalProducts(medicalProducts);
        }

        if (primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT) {
            applyFilters(filterObj, MONTHLY_COST_SORT_OPTION, medicalProducts);
        }
    };

    const parseInitialDentalOrVisionPlans = (plans: Contracts, isDental: boolean) => {
        const sortedPlans = getSortedPlans(plans, MONTHLY_COST_SORT_OPTION);
        const filteredPlans = primaryProductType === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? getPlansForTab(selectedProductTab, sortedPlans) : sortedPlans

        isDental ? updateDentalProducts(sortedPlans) : updateVisionProducts(sortedPlans);

        if ((primaryProductType === PLAN_TYPES_NAMES.DENTAL_PRODUCT && isDental) || primaryProductType === PLAN_TYPES_NAMES.VISION_PRODUCT) {
            setFilteredProducts(filteredPlans)
        }

        setLoading(false);
    };

    const applyFilters = (filtersObj: ProductFilters, sortOption: SortOption, medicalPlans: Contracts = useGlobalStore.getState().medicalProducts) => {
        setLoading(true);
        const applicablePlans = medicalPlans.length > 0 ? medicalPlans : medicalProducts;
        const filteredProducts = getFilteredMedicalProducts(
            filtersObj,
            applicablePlans,
            savedProviders,
            matchProvidersToPlans,
            matchMedicationsToPlans,
            drugsList,
            coveredMedicationsList
        );
        const sortedPlans = getSortedPlans(filteredProducts, sortOption);

        setFilteredProducts(sortedPlans);
        setLoading(false);
    };

    const loadMedicalPlans = async () => {
        const payload = getMedicalProducutsPayload(useGlobalStore.getState());

        setServerError(false)
        setLoading(true);

        fetchPlanList(payload)
            .then((response) => {
                const data: PlanSummaryResponse = response.data;
                const medicalPlans = data.planSummaryResponse.plans.contract;

                parseInitialMedicalProducts(medicalPlans);
            })
            .catch((error) => {
                console.error('ProductSelection.tsx loadMedicalPlans error :', error)
                setLoading(false);
                setServerError(true)
            });
    }

    const loadDentalPlans = async () => {
        const payload = getDentalProducutsPayload(useGlobalStore.getState())

        setServerError(false)

        fetchPlanList(payload)
            .then((response) => {
                const data: PlanSummaryResponse = response.data;
                const dentalPlans = data.planSummaryResponse.plans.contract;

                parseInitialDentalOrVisionPlans(dentalPlans, true)
            })
            .catch((error) => {
                console.error('ProductSelection.tsx loadDentalPlans error :', error)
                setServerError(true)
                setLoading(false);
            });
    }

    const loadVisionPlans = async () => {
        const payload = getVisionProductsPayload(useGlobalStore.getState());

        setServerError(false)

        fetchPlanList(payload)
            .then((response) => {
                const data: PlanSummaryResponse = response.data;
                let visionPlans;
                if (agentCoverageType === PLAN_TYPES.VISION && !isHCIDFlow) {
                    visionPlans = getStandAloneVisionPlans(data.planSummaryResponse.plans.contract);
                } else {
                    visionPlans = data.planSummaryResponse.plans.contract
                }

                parseInitialDentalOrVisionPlans(visionPlans, false);
            })
            .catch((error) => {
                console.error('ProductSelection.tsx loadVisionPlans error :', error)
                setServerError(true)
                setLoading(false);
            });
    }

    const getPlans = async () => {
        // API call to get plans
        setLoading(true);

        try {
            if (isWellpoint) {
                await loadMedicalPlans();
            } else {
                switch (agentCoverageType) {
                    case PLAN_TYPES.MDV:
                    case PLAN_TYPES.MEDICAL:
                        await loadMedicalPlans();
                        await loadDentalPlans();
                        await loadVisionPlans();
                        break;
                    case PLAN_TYPES.DENTAL:
                        await loadDentalPlans();
                        await loadVisionPlans();
                        break;
                    case PLAN_TYPES.VISION:
                        await loadVisionPlans();
                        break;
                    default:
                        break;
                }

            }
        } catch (error) {
            console.error('ProductSelection.tsx getPlans error :', error)
            setLoading(false);
        }
    }

    const handleTabChange = (tab: string) => {
        const filterObj: ProductFilters = {
            selectedProductTab: tab,
            productTypeFilters: [],
            productsSwitchFilters: [],
            productsBenefitsTiers: [],
        };
        updateSelectedProductTab(tab);

        if (primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT) {
            applyFilters(filterObj, MONTHLY_COST_SORT_OPTION);
        } else {
            const plans = primaryProductType === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? useGlobalStore.getState().dentalProducts : useGlobalStore.getState().visionProducts;
            setFilteredProducts(getPlansForTab(tab, plans))
        }
    };

    const scrolltoImprtantNotice = () => {
        importantNotice.current && importantNotice.current.scrollIntoView({ behavior: 'smooth' })
    }

    const renderFilters = () => {
        if (primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT) {
            return (
                <div className="fwc-row">
                    <ProductSelectionFilters labels={pageContent?.FILTER_LABELS} medicalProducts={medicalProducts} onFiltersChange={(filters) => applyFilters(filters, getActiveSortValue(productsSortOptions))} onSortChange={(sortOption) => sortMedicalProducts(sortOption)} />
                </div>
            )
        }
    }

    const showDropdown = (currentTarget: React.SetStateAction<null> | (EventTarget & HTMLSpanElement), useCase: string, primaryProduct: Contract) => {
        setDropdownUseCase({ useCase: useCase, primaryProductContractCode: primaryProduct.contractCode })
        setPrimaryProductInFocus(primaryProduct)
        setProductAnchor(currentTarget);
        setProductPopoverOpen(!productpopoverOpen);
    };

    const renderMonthlyPremium = (amount: number) => {
        if ((amount || amount === 0) && amount !== -1) {
            return getFormattedCurrency(amount);
        } else {
            return pageContent.RATE_NOT_AVAILABLE;
        }
    };

    const updateSelectedOptionalProducts = (product: Contract, primaryProductContractCode: string, useCase: string) => {
        setProductPopoverOpen(!productpopoverOpen);

        if (useCase === PLAN_TYPES_NAMES.DENTAL_PRODUCT) {
            const dentalProducts: SelectedDentalProducts = updatedSelectedDentalProducts(product, primaryProductContractCode);
            updateSelectedDentalProducts(dentalProducts);

        } else if (useCase === PLAN_TYPES_NAMES.VISION_PRODUCT) {
            const visionProducts: SelectedVisionProducts = updatedSelectedVisionProducts(product, primaryProductContractCode);
            updateSelectedVisionProducts(visionProducts)
        }
    }

    const deleteSeletedProduct = (selectedProduct: Contract, useCase: string, primaryProductContractCode: string) => {
        if (useCase === PLAN_TYPES_NAMES.DENTAL_PRODUCT) {
            const dentalProducts: SelectedDentalProducts = deleteSelectedDentalProduct(selectedProduct, primaryProductContractCode);
            updateSelectedDentalProducts(dentalProducts);

        } else if (useCase === PLAN_TYPES_NAMES.VISION_PRODUCT) {
            const visionProducts: SelectedVisionProducts = deleteSelectedVisionProduct(selectedProduct, primaryProductContractCode);
            updateSelectedVisionProducts(visionProducts)
        }
    }

    const renderPopoverProducts = (products: Contracts) => {
        const applicableProducts = getOptionalProducts(primaryProductInFocus, products);

        return (
            <Paragraph>
                <span className="add-product-popover-container">
                    {applicableProducts?.map((product) => {
                        return <li key={product.contractCode} onClick={() => updateSelectedOptionalProducts(product, dropdownUseCase.primaryProductContractCode, dropdownUseCase.useCase)}><span className="product-name">{product.planMarketingName + ' ' + product.contractCode}</span><div><span className="product-cost">{renderMonthlyPremium(product?.rateData?.rate?.totSubsidizedPrem ?? -1)}</span><span className="product-cost-permonth">/{pageContent?.PRODUCT_CARD_LABELS?.MONTH}</span></div></li>
                    })}
                </span>
            </Paragraph>
        );
    };

    const handleSaveATKProposal = async () => {
        setLoading(true);
        const payload = await getATKsaveProposalRequestPayload(useGlobalStore.getState().comparedProducts);
        try {
            await atkSaveProposal(payload).then((response) => {
                updateATKsaveProposalResponse(response.data);
                setLoading(false);
                navigate(NAV_PATHS.REVIEW_PLANS)

            }
            )
        }
        catch (error) {
            setLoading(false);
            console.warn('error', error)
        }
    }

    if (!contentLoaded) {
        return <FullPageLoader />
    } else {
        return (
            <div className='product-selection-container' ref={divRef}>
                {loading && <FullPageLoader />}
                {showFindYourDoctor && (
                    <FindYourDoctor
                        showFindYourDoctor={showFindYourDoctor}
                        contractCodes={getContractCodes(medicalProducts)}
                        closeFindYourDoctor={() => setShowFindYourDoctor(false)}
                    />
                )}
                {showMyMedications && (
                    <ShowMyMedications
                        showSlider={showMyMedications}
                        onSubmit={() => { }}
                        contractCodes={getContractCodes(medicalProducts)}
                        onChange={() => setShowMyMedications(false)}
                    />
                )}

                {clickedProduct.contractCode && clickedProduct.plan[0].planType === PLAN_TYPES_NAMES.MEDICAL && (
                    <MedicalPlanDetails
                        isCurrentCoveragePlan={false}
                        closeMedicalPlanDetails={() => setClickedProduct({} as Contract)}
                        contract={clickedProduct}
                        divRef={divRef}
                    />
                )}
                {showFindYourDentist && (<FindYourDentist
                    showFindYourDentist={showFindYourDentist}
                    contractCodes={getContractCodes(dentalProducts)}
                    closeFindYourDentist={() => setShowFindYourDentist(false)} />
                )}
                {clickedProduct.contractCode && clickedProduct.plan[0].planType === PLAN_TYPES_NAMES.DENTAL && (
                    <DentalPlanDetails
                        isCurrentCoveragePlan={false}
                        closeDentalPlanDetails={() => setClickedProduct({} as Contract)}
                        contract={clickedProduct}
                    />
                )}
                {showFindYourEyeDoctor && (<FindYourEyeDoctor
                    showFindYourEyeDoctor={showFindYourEyeDoctor}
                    contractCodes={getContractCodes(visionProducts)}
                    closeFindYourEyeDoctor={() => setShowFindYourEyeDoctor(false)} />
                )}
                {clickedProduct.contractCode && clickedProduct.plan[0].planType === PLAN_TYPES_NAMES.VISION && (
                    <VisionPlanDetails
                        isCurrentCoveragePlan={false}
                        contract={clickedProduct}
                        closeVisionPlanDetails={() => setClickedProduct({} as Contract)}
                    />
                )}
                <GlobalHeader />
                <div className='product-selection-body'>
                    <div className='fwc-row'>
                        <h1 id='product-selection-title'>
                            {pageContent?.PRODUCT_SELECTION}
                        </h1>
                    </div>
                    <div className="fwc-row coverage-accordion-container">
                        <PlanCoverageAccordion planType={primaryProductType} labels={pageContent?.ACCORDION_LABELS} colaradoPlans={false} applicants={getFormattedApplicantDetails(agentApplicantFormDetails)} />
                    </div>
                    <div className="fwc-row j-between title-container" data-testid='title-id'>
                        <b className='padding-bottom product-slection-title-labels-bold' id='product-selection-secondary-title'>{pageContent ? pageContent[primaryProductType]?.SELECT_THE_MEDICAL : null}</b>
                        {!isWellpoint && <p className='product-slection-title-labels' id='product-selection-title-detal-or-vision'>{pageContent ? pageContent[primaryProductType]?.DENTAL_AND_OR_VISION : null}</p>}
                        {!isWellpoint && <span className='product-slection-title-labels padding-top' id='product-selection-title-detal-blue'>
                            <b>{pageContent ? pageContent[primaryProductType]?.PLEASE_NOTE + ': ' : null}</b>
                            {pageContent ? pageContent[primaryProductType]?.THE_DENTAL_BLUE : null}
                        </span>
                        }
                    </div>
                    <div className="fwc-row important-label-container">
                        <span id="product-selection-coverage-help-icon" className={'sae-icon sae-icon-info info-icon'} />
                        <p id="product-selection-coverage-important-text" className="btm-sm">
                            {pageContent?.SEE + ' '}
                            <a data-analytics="importantNoticeMedicalPlansIos" href={'javascript:void(0)'} onClick={() => {
                                importantNotice.current && importantNotice.current.scrollIntoView({ behavior: 'smooth' })
                            }} data-testid='important-link'>{pageContent?.IMPORTANT}</a>
                            {' ' + pageContent?.NOTICE_ABOUT}
                        </p>
                    </div>
                    {(primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT || primaryProductType === PLAN_TYPES_NAMES.DENTAL_PRODUCT) && <div className='fwc-row tabs-container'>
                        <ShopperTabs tabs={productSelectionTabs} activeTab={selectedProductTab} onChange={handleTabChange} />
                    </div>
                    }
                    {!loading && filteredProducts.length === 0 ? (<div className="filters-list-outer-wrapper">
                        {getEmptyPlansUseCase(serverError, medicalProducts) === EMPTY_PLANS_USE_CASES.NO_PLANS_AVAILABLE_WITH_FILTERS && renderFilters()}
                        <EmptyPlans useCase={getEmptyPlansUseCase(serverError, medicalProducts)} labels={pageContent?.EMPTY_PLANS} /></div>) : (
                        <div>
                            <div className='fwc-row utility-container'>
                                <h4>
                                    {pageContent?.INDIVIDUAL_PLANS}
                                </h4>
                                <div className='utility-buttons'>
                                    {primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT && <a data-analytics="showDoctorMedicalPlansIos" href={'javascript:void(0);'} id="product-selection-show-my-doctor" className="utility-option" data-testid='find-your-doctor-button' onClick={() => setShowFindYourDoctor(true)}>
                                        <span id="plan-coverage-help" className={'sae-icon sae-icon-doctors-bag utility-icon'} />
                                        <span>{pageContent?.SHOW_MY_DOCTOR}</span>
                                    </a>
                                    }
                                    {primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT && <a data-analytics="showMyMedsMedicalPlansIos" href={'javascript:void(0);'} id="product-selection-show-my-medications" className="utility-option" onClick={() => setShowMyMedications(true)}>
                                        <span className={'sae-icon sae-icon-prescription-pill-bottle utility-icon'} />
                                        <span>{pageContent?.SHOW_MY_MEDICATIONS}</span>
                                    </a>
                                    }
                                    {primaryProductType === PLAN_TYPES_NAMES.DENTAL_PRODUCT && <a data-analytics="showDentistDentalPlansIos" href={'javascript:void(0)'} id="dental-products-show-my-doctor" className="utility-option" data-testid='show-my-dentist-button' onClick={() => setShowFindYourDentist(true)}>
                                        <span id="plan-coverage-help" className={'sae-icon sae-icon-tooth utility-icon'} />
                                        <span>{pageContent?.SHOW_MY_DENTIST}</span>
                                    </a>
                                    }
                                    {primaryProductType === PLAN_TYPES_NAMES.VISION_PRODUCT && <a data-analytics="showEyeDrVisionPlansIos" href={'javascript:void(0)'} id="vision-plans-show-my-doctor" className="utility-option" onClick={() => setShowFindYourEyeDoctor(true)}>
                                        <span id="plan-coverage-help" className={'sae-icon sae-icon-eye utility-icon'} />
                                        <span>{pageContent?.SHOW_MY_EYE_DOCTOR}</span>
                                    </a>}
                                </div>
                            </div>
                            <div className='filters-list-outer-wrapper'>
                                {renderFilters()}
                                <div className="fwc-row label-sort-wrapper">
                                    <span>
                                        {pageContent?.YOU_ARE_VIEWING + ' '}
                                        <span className="medical-plans-label">
                                            {filteredProducts.length} {getPlanTypeLabel(primaryProductType, pageContent)}
                                        </span>
                                    </span>
                                </div>
                                <div className="fwc-row">
                                    <div className="product-list-container">
                                        {productpopoverOpen && productAnchor && (
                                            <Popover className="css-1niekxx-cssPopover add-product-popover" id="add-product-card-popover" placement={'top'} anchorEl={productAnchor} onClickOutside={() => setProductPopoverOpen(!productpopoverOpen)}>
                                                {renderPopoverProducts(dropdownUseCase.useCase === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? dentalProducts : dropdownUseCase.useCase === PLAN_TYPES_NAMES.VISION_PRODUCT ? visionProducts : [])}
                                            </Popover>
                                        )}
                                        {filteredProducts.map((product: Contract) => {
                                            return <ProductCard
                                                key={product.contractCode}
                                                primaryProductType={primaryProductType}
                                                labels={pageContent.PRODUCT_CARD_LABELS}
                                                contract={product}
                                                drugList={drugList}
                                                matchedProviders={getMatchedDoctorsForPlan(matchProvidersToPlans, savedProviders, product.contractCode)}
                                                matchedProvidersFlag={matchProvidersToPlans}
                                                matchedMedications={getMatchedMedicationsForPlan(matchMedicationsToPlans, drugsList, product.contractCode, coveredMedicationsList)}
                                                matchedMedicationsFlag={matchMedicationsToPlans}
                                                matchedDentists={getMatchedDoctorsForPlan(matchDentistsToPlans, savedDentists, product.contractCode)}
                                                matchedDentistsFlag={matchDentistsToPlans}
                                                matchedEyeDoctors={getMatchedDoctorsForPlan(matchEyeDoctorsToPlans, savedEyeDoctors, product.contractCode)}
                                                matchedEyeDoctorsFlag={matchEyeDoctorsToPlans}
                                                reviewedProducts={comparedProducts}
                                                onProductNameClick={(clickedProduct: Contract) => { setClickedProduct(clickedProduct); }}
                                                onApplyClick={(appliedProduct: SelectedProduct) => {
                                                    updateSelectedProduct({
                                                        ...selectedProduct,
                                                        ...appliedProduct
                                                    })
                                                    navigate(NAV_PATHS.AGENT_PLAN_SELECTION_SUMMARY)
                                                }}
                                                scrolltoImprtantNotice={() => scrolltoImprtantNotice()}
                                                onReiviewClick={(selectedProduct: SelectedProduct) => { updateComparedProducts(getUpdatedProductsForReview(primaryProductType, selectedProduct, comparedProducts)) }}
                                                setRatingDetailsModalContent={(content: QualityRating) => setRatingDetailsModalContent(content)}
                                                setShowOverallRatingModal={() => setShowOverallRatingModal(!showOverallRatingModal)}
                                                onAddProductClick={(currentTarget, useCase, primaryProduct) => showDropdown(currentTarget, useCase, primaryProduct)}
                                                onDeleteClick={(selectedProduct, useCase, primaryProductContractCode) => deleteSeletedProduct(selectedProduct, useCase, primaryProductContractCode)}
                                                addProviders={(useCase) => { useCase === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? setShowFindYourDentist(true) : setShowFindYourEyeDoctor(true) }}
                                            />
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="footer-wrapper">
                        <div className="fwc-row footer-buttons-container" ref={importantNotice}>
                            <div className="fwc-row footer-buttons-container" ref={importantNotice}>
                                <button
                                    id="product-selection-cancel"
                                    className="footer-button fwc-btn fwc-btn-secondary"
                                    onClick={closeCurrentTab}
                                    data-analytics="backBtnMedicalPlansIos"
                                >
                                    {pageContent?.CANCEL}
                                </button>
                                <div className='right-buttons'>
                                    <button
                                        id="product-selection-back"
                                        className="footer-button fwc-btn fwc-btn-secondary"
                                        data-analytics="goToTopBtnMedicalPlansIos"
                                        onClick={() => {
                                            navigate(-1)
                                        }}
                                    >
                                        {pageContent?.BACK}
                                    </button>
                                    <button
                                        id="product-selection-review"
                                        className="footer-button fwc-btn fwc-btn-primary"
                                        data-analytics="goToTopBtnMedicalPlansIos"
                                        disabled={comparedProducts.length < 1}
                                        onClick={() => {
                                            handleSaveATKProposal()
                                        }}
                                    >
                                        {pageContent?.REVIEW_SELECTED_PLANS}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div id="product-selection-desclaimer-text" className='fwc-row disclaimer-container'>
                            <p>{'*' + pageContent?.DISCLAIMER + ':'}</p>
                            <p>{pageContent?.ALL_BENEFITS}</p>
                        </div>
                        <div className="fwc-row" >
                            <div className="important-message" id="product-selection-important-text" >
                                <p>
                                    <b>{pageContent?.IMPORTANT}</b>
                                </p>
                                <p dangerouslySetInnerHTML={{ __html: pageContent?.IMPORTNAT_NOTICE_PARA_ONE }}></p>
                                <p dangerouslySetInnerHTML={{ __html: pageContent?.IMPORTNAT_NOTICE_PARA_TWO }}></p>
                                <p dangerouslySetInnerHTML={{ __html: pageContent?.IMPORTNAT_NOTICE_PARA_THREE }}></p>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    providerMetaData && (
                        <ProviderDetails
                            useCase={PLAN_TYPES_NAMES.MEDICAL}
                        />
                    )
                }

                {
                    (showOverallRatingModal) && <Modal
                        onClose={() => setShowOverallRatingModal(false)}
                        title={pageContent?.PRODUCT_CARD_LABELS.OVERALL_RATING}
                        open={showOverallRatingModal}
                        class="more-popup-header"
                    >
                        <Modal.Body className="more-popup-body">
                            <p>{pageContent?.PRODUCT_CARD_LABELS.SUMARRY}</p>
                            <p id='more-type-link' className='more-detail-link' onClick={() => window.open(OVERVIEW_RATING_URL, "_blank")}>
                                {pageContent?.PRODUCT_CARD_LABELS.MORE_DETAILS}
                            </p>
                        </Modal.Body>
                    </Modal>
                }

                {
                    ratingDetailsModalContent && <Modal
                        onClose={() => setRatingDetailsModalContent(false)}
                        title={pageContent?.PRODUCT_CARD_LABELS.START_RATINGS}
                        open={ratingDetailsModalContent ? true : false}
                    >
                        <Modal.Body>
                            <div className='ratings-container'>
                                {
                                    ratingDetailsModalContent && Object.keys(ratingDetailsModalContent).map((item, index) => {
                                        return (
                                            <div className='fwc-row fwc-row-bm' key={index}>
                                                <div className='fwc-col-10'>
                                                    <h6>{pageContent?.PRODUCT_CARD_LABELS.STAR_RATINGS[item].label}</h6>
                                                    <p>{pageContent?.PRODUCT_CARD_LABELS.STAR_RATINGS[item].desc}</p>
                                                </div>
                                                <div className='fwc-col-2'>
                                                    {
                                                        (ratingDetailsModalContent && ratingDetailsModalContent[item] && ratingDetailsModalContent[item] !== NOT_RATED_PLAN) ?
                                                            <div dangerouslySetInnerHTML={{ __html: displayStars(Number(ratingDetailsModalContent[item])) }}>
                                                            </div> : <h6>{pageContent?.PRODUCT_CARD_LABELS.NOT_RATED}</h6>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button id='modal-slide-select' onClick={() => setRatingDetailsModalContent(false)}>
                                {pageContent?.PRODUCT_CARD_LABELS.CLOSE}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }
                {comparedProducts.length >= 1 &&
                    <ProductReviewTray
                        labels={pageContent?.REVIEW_TRAY_LABELS}
                        handleReviewClick={handleSaveATKProposal}
                    />}

            </div >
        )
    }
}


export default ProductSelection