import { API_NAME_LIST } from '../../../../api/api-name-list';
import axiosInstance from '../../../../api/axiosWrapper/axiosWrapper';

const saveShopperAgentConnect = (payload: SaveShopperAgentConnect, webGUID: string) => {
  return axiosInstance.post(API_NAME_LIST.SAVE_SHOPPER_AGENT_CONNECT, payload, {
    headers: {
      webGUID: webGUID
    }
  });
};

const deleteShopperAgentConnect = (payload: string) => {
  return axiosInstance.post(API_NAME_LIST.DELETE_SHOPPER_AGENT_CONNECT + payload);
};

export { deleteShopperAgentConnect, saveShopperAgentConnect };
