import { StateCreator } from 'zustand';

export const createAgentSlice: StateCreator<AgentStore> = (set) => ({
  agentInfo: null,
  aTKsaveProposalResponse: null,
  openATKDepModal: {
    planType: '',
    show: false,
    isMacFlow: false,
    popUpType: ''
  },
  updateOpenATKDepModal: (planType: string, show: boolean, isMacFlow: boolean, popUpType: string) => {
    set({
      openATKDepModal: {
        planType: planType,
        show: show,
        isMacFlow: isMacFlow,
        popUpType: popUpType
      }
    });
  },
  updateAgentInfo: (newAgentInfo: AgentOfRecord) => set({ agentInfo: newAgentInfo }),
  resetAgentInfo: () => set({ agentInfo: null, aTKsaveProposalResponse: null, isPublicAgentConnect: false }),
  isPublicAgentConnect: false,
  setIsPublicAgentConnect: (isPublicAgentConnect: boolean) => set({ isPublicAgentConnect }),
  updateATKsaveProposalResponse: (newATKsaveProposalResponse: ATKsaveProposalResponse) => set({ aTKsaveProposalResponse: newATKsaveProposalResponse })
});
