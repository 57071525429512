import { MEDICAL_DEDUCTIBLE_SORT_OPTION, MONTHLY_COST_SORT_OPTION } from '../medical-plans/constants';

const VISION_PLAN_BENEFITS = {
  ROUTINE_EYE_EXAM: 'VISRTNEYEEXM',
  EYE_GLASS_LENSES: 'VISEYEGLSLENS',
  EYE_GLASS_FRAMES: 'VISEYEGLSFRMS',
  CONTACT_LENSES: 'VISCNTCTLENS'
};

const VISION_SORT_OPTIONS = [MONTHLY_COST_SORT_OPTION, MEDICAL_DEDUCTIBLE_SORT_OPTION];

const DEFAULT_VISION_CURRENT_COVERAGE: Contract = {
  contractCode: '',
  contractEffectiveDate: '',
  tiersType: '',
  onExchange: false,
  poPlan: false,
  coloradoOptions: false,
  contractExpiryDate: '',
  planYear: '',
  ehbIndTypes: [],
  planMarketingName: '',
  avValue: '',
  rateData: {
    rate: {
      totalPremium: 0.0,
      totSubsidizedPrem: 0.0,
      subsidyAmtApplied: 0.0
    }
  },
  plan: [
    {
      planID: '',
      planDisplayName: '',
      planName: '',
      planType: 'VISION',
      costCoverages: [],
      planBenefits: [
        {
          planBenefitDisplayName: 'Routine Eye Exam',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'VISION',
          planBenefitSortOrder: 300,
          planBenefitName: 'Routine Eye Exam',
          planBenefitCode: 'VISRTNEYEEXM'
        },
        {
          planBenefitDisplayName: 'Eyeglass Lenses',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'VISION',
          planBenefitSortOrder: 301,
          planBenefitName: 'Eyeglass Lenses',
          planBenefitCode: 'VISEYEGLSLENS'
        },
        {
          planBenefitDisplayName: 'Eyeglass Frames',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'VISION',
          planBenefitSortOrder: 302,
          planBenefitName: 'Eyeglass Frames',
          planBenefitCode: 'VISEYEGLSFRMS'
        },
        {
          planBenefitDisplayName: 'Contact Lenses',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'VISION',
          planBenefitSortOrder: 303,
          planBenefitName: 'Contact Lenses',
          planBenefitCode: 'VISCNTCTLENS'
        }
      ],
      productType: ''
    }
  ],
  providerNetworkId: 'VISION',
  qHPVariation: '',
  qHPId: '',
  companyDesc: '',
  gradeLevels: [],
  qhpvariation: '',
  pcp: false
};

const EHBIND_TYPES = {
  VIS_STD: 'VIS_STD'
};
const PLAN_ERROR_CODES = {
  NO_PLANS_FOUND: 'PLANSUMMARY002'
};

export { DEFAULT_VISION_CURRENT_COVERAGE, EHBIND_TYPES, PLAN_ERROR_CODES, VISION_PLAN_BENEFITS, VISION_SORT_OPTIONS };
