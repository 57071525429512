import axios from 'axios';
import { API_NAME_LIST } from '../api-name-list';

export const postSiteMetrics = (payload: SiteMetricsPayload) => {
    console.log(API_NAME_LIST.BASEURL);
    return axios.post(API_NAME_LIST.SITE_METRICS, {
      ...payload
    });
};

interface SiteMetricsPayload {
  sessionId: string;
  eventType: string;
  userInfo?: {
    webGUID?: string;
    role: string;
  };
  pageURL: string;
  userAgent?: {
    deviceType?: string;
    browserName?: string;
    browserVersion?: string;
    platform?: string;
  };
  sessionObject: {
    firstname: string;
    email?: string;
    dob?: string;
    phone?: string;
    state?: string;
    coveragetype?: string;
    acn?: string;
    zipcode?: string;
    brand?: string;
    quoteid?: string;
    refid?: string;
  };
}
