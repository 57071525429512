import { formatDateToYYYYMMDD } from '../../../shared/utils/globalUtils';

import { LANGUAGE_CODES, PLAN_TYPES_NAMES, USER_DOMAINS } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { GRADE_LEVELS, PLAN_TIERS_ALL } from '../medical-plans/constants';
import { getFormattedApplicantDetails, setDomesticPartnerAsSpouse } from '../medical-plans/medicalPlansUtils';
import { EHBIND_TYPES } from './constants';

const getVisionPlanCardLabels = (labels: any): PlanCardLabels => {
  return {
    mostPopular: labels?.MOST_POPULAR,
    recommended: labels?.RECOMMENDED,
    currentCoverage: labels?.CURRENT_COVERAGE,
    monthlyPremium: labels?.MONTHLY_PREMIUM,
    benefitLabelOne: labels?.ROUTINE_EYE_EXAM,
    benefitLabelTwo: labels?.EYE_GLASS_LENSES,
    benefitLabelThree: labels?.EYE_GLASS_FRAMES,
    referToSbc: labels?.REFER_TO_SBC,
    select: labels?.SELECT,
    deselect: labels?.DESELECT,
    showMore: labels?.SHOW_MORE,
    showLess: labels?.SHOW_LESS,
    addToFavourites: labels?.ADD_TO_FAVOURITES,
    removeFromFavourites: labels?.REMOVE_FROM_FAVOURITES,
    compareEmailPrint: labels?.COMPARE_EMAIL_PRINT,
    monthlyCostBreakdown: labels?.MONTHLY_COST_BREAKDOWN,
    totalPlanCost: labels?.TOTAL_PLAN_COST,
    subsidyApplied: labels?.SUBSIDY_APPLIED,
    totalMemberResponsibility: labels?.TOTAL_MEMBER_RESPONSIBILITY,
    rateNotAvailable: labels?.RATE_NOT_AVAILABLE,
    doctorsListLabel: labels?.VISION_LIST_LABEL,
    doctors: labels?.EYE_DOCTORS,
    matchedDoctorsTooltipText: labels?.MATCHED_DOCTORS_TOOLTIP_TEXT,
    transitionCoverage: labels?.TRANSITION_COVERAGE,
    transitionCoverageTooltip: labels?.TRANSITION_PLAN_TOOLTIP_CONTENT
  };
};

const getVisionPlansPayload = (globalStore: GlobalStore, selectedDentalPlan: Contract | null, selectedMedicalPlan: Contract | null): PlanSummaryPayload => {
  const selectedPlan = selectedDentalPlan ? selectedDentalPlan : selectedMedicalPlan;

  let payload: PlanSummaryPayload = {
    brand: globalStore.brand,
    planTypes: [PLAN_TYPES_NAMES.VISION],
    metalTierTypes: PLAN_TIERS_ALL,
    marketSegment: USER_DOMAINS.MARKET_SEGMENT_FOR_PLAN_SUMMARY,
    zipCode: globalStore.zipcode,
    county: globalStore.county,
    state: globalStore.state,
    coverageEffectiveDate: formatDateToYYYYMMDD(globalStore.coverageDate), //Update the format
    planYear: globalStore.year,
    applicants: setDomesticPartnerAsSpouse(getFormattedApplicantDetails(globalStore.selectedVisionPlan.applicants ?? globalStore.applicantFormDetails, false)),
    subsidy: {
      subsidyAmt: null,
      costShareReduction: null
    },
    language: LANGUAGE_CODES.EN,
    countyCode: globalStore.countyCode
  };

  if (selectedPlan) {
    payload.onExchange = selectedPlan.onExchange;
  }

  return payload;
};

const getCurrentCoverageVisionPlan = (currentCoverages: CurrentCoverages) => {
  return currentCoverages.find((coverage) => coverage.planType === PLAN_TYPES_NAMES.VISION);
};

const updateVisionPlansWithRecommendation = (plans: Contracts, visionRecommendations: string[]) => {
  const originalVisionPlans: Contracts = [];
  const updatedVisionPlans = plans.map((plan) => {
    if (visionRecommendations.includes(plan.contractCode)) {
      originalVisionPlans.push(plan);
      return { ...plan, gradeLevels: [{ grade: GRADE_LEVELS.RECOMMENDED, level: 1 }] };
    }
    return plan;
  });

  return { updatedVisionPlans, originalVisionPlans };
};

const getselectedVisionPlan = (plan: Contract, visionRecommendations: VisionRecommendations): Contract => {
  const originalPlan = visionRecommendations.originalVisionPlans.find((originalPlan) => originalPlan.contractCode === plan.contractCode);
  return originalPlan ?? plan;
};

const getStandAloneVisionPlans = (plans: Contracts) => {
  return plans.filter((plan) => plan.ehbIndTypes.includes(EHBIND_TYPES.VIS_STD));
};

const checkForOnExchange = () => {
  const { selectedDentalPlan, selectedPlan } = useGlobalStore.getState();
  const plan = selectedDentalPlan.plan ? selectedDentalPlan.plan : selectedPlan.plan;
  return plan?.onExchange && !plan?.poPlan;
};

export {
  checkForOnExchange,
  getCurrentCoverageVisionPlan,
  getStandAloneVisionPlans,
  getVisionPlanCardLabels,
  getVisionPlansPayload,
  getselectedVisionPlan,
  updateVisionPlansWithRecommendation
};
