const getUpdatedMacLoginTabs = (tabs: ShopperTab[], pageContent: any) => {
  return tabs.map((tab) => {
    return {
      ...tab,
      label: pageContent[tab.value]
    };
  });
};

export { getUpdatedMacLoginTabs };
