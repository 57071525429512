const getUpdatedSelectedDrugsList = (drugInfo: DrugDetails, isSelected: boolean, selectedDrugsList: DrugDetails[]): DrugDetails[] => {
  let updatedSelectedDrugsList: DrugDetails[] = [];

  if (isSelected) {
    updatedSelectedDrugsList.push(drugInfo);
  } else {
    updatedSelectedDrugsList = selectedDrugsList.filter(
      (DrugObj) => DrugObj.genericProductId + DrugObj.drugLabel !== drugInfo.genericProductId + drugInfo.drugLabel
    );
  }

  return updatedSelectedDrugsList;
};

const getContractCodesInFormat = (codes) => {
  let formattedCodes = codes.map((planId) => ({ planId }));
  return formattedCodes;
};

export { getContractCodesInFormat, getUpdatedSelectedDrugsList };
