import { StateCreator } from 'zustand';
import { REMEMBER_MY_DEVICE_NO } from '../shared/globalConstants';

const initialState = {
  username: '',
  person: {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    emailAddress: '',
    emailConsent: REMEMBER_MY_DEVICE_NO,
    phone: {
      type: '',
      number: ''
    }
  },
  secretQuestionAnswers: [],
  triggerLoginModal: false
};

export const createRegistrationStoreSlice: StateCreator<RegistrationStore> = (set, get) => ({
  ...initialState,
  updatePersonalInfo: (personalInfo: PersonInfo) =>
    set({
      person: personalInfo
    }),
  updateUsername: (username: string) =>
    set({
      username: username
    }),
  updateSecretQAndAs: (SecretQAndAs: SecurityQAndA[]) =>
    set({
      secretQuestionAnswers: SecretQAndAs
    }),
  updateTriggerLoginModal: (triggerLoginModal: boolean) => set({ triggerLoginModal: triggerLoginModal }),
  resetRegistrationStore: () =>
    set({
      ...initialState
    })
});
