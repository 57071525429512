import { StateCreator } from 'zustand';

export const createProviderStoreSlice: StateCreator<ProviderStore> = (set, get) => ({
  savedProviders: [],
  matchProvidersToPlans: false,
  providerMetaData: null,
  updateSavedProviders: (providers: Provider[]) => set({ savedProviders: providers }),
  updateMatchProvidersToPlansStatus: (status: boolean) => set({ matchProvidersToPlans: status }),
  updateProviderMetaData: (provider: Provider | null) => set({ providerMetaData: provider }),
  resetProviderStates: () => set({ savedProviders: [], matchProvidersToPlans: false, providerMetaData: null })
});
