//Import 3rd party libs
// import { Link, useNavigate } from 'react-router-dom';
import { PreLoader } from '@sydney-broker-ui/ios';
import { AxiosError, isAxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
//Import functions/stores
import { getCMSStaticContent } from "../../../../api/cms/cmsUtil";
import { HTTP_STATUS_CODES, STATUSES } from '../../../../api/constants';
//Custom components
import InfoBar from '../../../../components/common/info-bar/InfoBar';
import { CALIFORNIA_CODE, CONFLICT_OPTION_LABELS, CONFLICT_USE_CASES, ERROR_ALERT_CONFIG, FORGOT_PASSWORD_REDIRECTION_URLS, LQE_NY_STATE, NAV_PATHS, NON_CONFLICT_SCREENS, REGISTRATION_REDIRECTION_URLS } from '../../../../shared/globalConstants';
import { useGlobalStore } from '../../../../store/globalStore';
import { useUserStore } from '../../../../store/userStore';
import { INPUT_TYPES, LOGIN_RESPONSE_CODES, USER_AUTHENTICATED_RESPONSE_CODES } from './constants';
import { default as message } from './loginModal.json';
//Import constants and css
import { generateTokenAPI, getBuildVersion } from '../../../../api/services/tokenService';
import QuoteConflict from '../../../../components/common/conflict-popups/QuoteConflict';
import { useShopperContext } from '../../../../context/shopper-context/ShopperContext';
import AgentConflictPopup from '../../../secure/agent-conflict-popup/AgentConflictPopup';
import { DELETE_PAYLOAD_INPUT } from '../../../secure/dashboard/DashboardCard/constants';
import { cancelInProgressApplication } from '../../../secure/dashboard/DashboardCard/dashboardCardServices';
import { deleteInProgressDashboardCardPayload } from '../../../secure/dashboard/DashboardCard/dashboardCardUtils';
import { resetSessionData } from '../../../secure/logout/LogoutPupupUtils';
import { saveShopperAgentConnect } from '../../basics/basics-landing/BasicsLandingService';
import './LoginModal.scss';
import { loginUser } from './loginServices';
import { getAgentConnectPayload, getConflictInfo, getErrorMessage, getErrorObj, getInProgressApplication, getLoginSuccessPayload, getNavigationInfo, getWebAccount, populateUserDetails, processDemographicInfo, validateAgentConflict } from './loginUtils';

const { REACT_APP_ENV } = process.env;

function LoginModal({
  showModal = false,
  save = false,
  closeLoginModal = () => { },
  initiateTwoFa = () => { },
  loginDesc,
  profileDesc
}: {
  showModal: boolean;
  save?: boolean;
  closeLoginModal: () => void;
  initiateTwoFa: () => void;
  loginDesc?: string;
  profileDesc?: any;
}) {
  const [userName, setUserName] = useState('');
  const [validUserName, setValidUserName] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [validPassword, setValidPassword] = useState(true);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState({
    hasError: false,
    responseCode: '',
    message: ''
  });
  const { userPassword, setUserPassword } = useShopperContext()
  const { updateLoginResponse, updateLoginStatus, updateWebAccount, updateDemographicInfo, resetUserStore, updateQuotes, updateFavourites, updateRole, isPHILogin } = useUserStore((state) => state);
  const [pageContent, setContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [quoteConflictpopup, setQuoteConflictpopup] = useState(false);
  const [conflictUseCase, setConflictUseCase] = useState('')
  const [userLoginResponseData, setUserLoginResponseData] = useState<LoginResponse>();
  const [selectedConflictValue, setSelectedConflictValue] = useState('');
  const [inProgressApplication, setInProgressApplication] = useState<Application>({} as Application);
  const [isAgentConflict, setIsAgentConflict] = useState(false);
  const [selectedAgentConflictValue, setSelectedAgentConflictValue] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const { selectedPlan, brand, state, subsidyresponse, updateAgentInfo, updateCurrentCoverageMedicalPlan,
    updateCurrentCoverageDentalPlan, updateCurrentCoverageVisionPlan,
    updateChangeDemographicFlag, updateIsApplyNowClicked, zipCodeRes, memberInfo, saveandResumeLaterFlag, deepLinkInfo, isPublicAgentConnect, setIsPublicAgentConnect
  } = useGlobalStore((state) => state);

  const handleQuoteConflictClick = (value: string) => {
    setSelectedConflictValue(value);
  };

  const handleAgentConflictClick = (value: string) => {
    setSelectedAgentConflictValue(value)
  }

  const getContent = useCallback(async (): Promise<boolean> => {
    try {
      const response = await getCMSStaticContent("en_US", "login");
      let cmsResponse = response.data;
      setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.Login);
    } catch (error) {
      setContent(message.data.iospagecontentList.items[0].pageContent.Login);

      return false;
    }
    finally {
      setContentLoaded(true);
    }
    return true;
  }, []);

  const initiate = async () => {
    await getContent();
    setLoading(false);
    setUserName('');
    setPassword('');
    setValidUserName(true);
    setValidPassword(true);
    resetLoginError();
  }

  useEffect(() => {
    if (showModal) {
      initiate();
    }
  }, [showModal]);

  useEffect(() => {
    if (!quoteConflictpopup) {
      handleConflictResolution();
    }
  }, [quoteConflictpopup]);

  useEffect(() => {
    if (!isAgentConflict) {
      handleAgentConflictResolution();
    }
  }, [isAgentConflict]);

  const handleAgentConflictResolution = async () => {
    if (selectedAgentConflictValue === CONFLICT_OPTION_LABELS.OPTION1) {
      //User has chosen current working agent
      try {
        const agentPayload = getAgentConnectPayload(deepLinkInfo.agentConnectData);
        await saveShopperAgentConnect(agentPayload, useUserStore.getState().webAccountGUID);
        updateAgentInfo(deepLinkInfo.agentConnectData);
        closeLoginModal();
        updateLoginStatus(true);
        setLoading(false);
      }
      catch (error) {
        console.warn('LoginModal.tsx handleAgentConflictResolution error :', error);
        setLoginError({
          hasError: true,
          responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
          message: `<b>${pageContent?.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.SYSTEM_EXCEPTION_MESSAGE}`
        });
        setLoading(false);
      }
    }
    else if (selectedAgentConflictValue === CONFLICT_OPTION_LABELS.OPTION2) {
      //previous agent or already worked with agent
      try {
        await populateUserDetails(userLoginResponseData as LoginResponse);
        const getURL = await getNavigationInfo((userLoginResponseData as LoginResponse).shopperProfile?.demographicInfo?.demographicId, useUserStore.getState().role);
        closeLoginModal();
        updateIsApplyNowClicked(false);
        navigate(getURL);
        setLoading(false);
        setIsPublicAgentConnect(false);
      }
      catch (error) {
        console.warn('LoginModal.tsx handleConflictResolution error :', error);
        setLoginError({
          hasError: true,
          responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
          message: `<b>${pageContent?.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.SYSTEM_EXCEPTION_MESSAGE}`
        });
        setLoading(false);
      }

    }

  }

  const handleConflictResolution = async () => {
    if (conflictUseCase === CONFLICT_USE_CASES.APPLICATION && selectedConflictValue === CONFLICT_OPTION_LABELS.OPTION1) {
      //User has chosen existing application
      resetSessionData()
      closeLoginModal();
      updateLoginStatus(true);
      navigate(NAV_PATHS.DASHBOARD)
    } else if (conflictUseCase === CONFLICT_USE_CASES.APPLICATION && selectedConflictValue === CONFLICT_OPTION_LABELS.OPTION2) {
      //To do
      //Delete the in progress application from server
      if (inProgressApplication && inProgressApplication.acn) {
        const payload: deleteApplicationPayload = deleteInProgressDashboardCardPayload(inProgressApplication.acn, DELETE_PAYLOAD_INPUT.CANCELREASON, DELETE_PAYLOAD_INPUT.PARTNETID, inProgressApplication.accessControlList[0]?.user?.userId);
        await cancelInProgressApplication(payload)
      }
      updateChangeDemographicFlag(true);
      setQuoteConflictpopup(false);
      updateLoginStatus(true);
      closeLoginModal();
      navigate(location.pathname);
    }
    else if (selectedConflictValue === CONFLICT_OPTION_LABELS.OPTION2 && userLoginResponseData) {
      resetSessionData();
      setLoading(true);

      try {
        await populateUserDetails(userLoginResponseData);
        const getURL = await getNavigationInfo(userLoginResponseData.shopperProfile?.demographicInfo?.demographicId, useUserStore.getState().role);
        closeLoginModal();
        updateIsApplyNowClicked(false);
        navigate(getURL);
        setLoading(false);
      }
      catch (error) {
        console.warn('LoginModal.tsx handleConflictResolution error :', error);
        setLoginError({
          hasError: true,
          responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
          message: `<b>${pageContent?.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.SYSTEM_EXCEPTION_MESSAGE}`
        });
        setLoading(false);
      }
    }
    else if (selectedConflictValue === CONFLICT_OPTION_LABELS.OPTION1 && conflictUseCase === CONFLICT_USE_CASES.QUOTE) {
      //Save the Quote I'm working on Now -- inactivate the saved quote by calling saveQuoteandDemographic API with empty quote data.
      // and reset the userStore.quotes and userStore.favourites
      updateChangeDemographicFlag(true);
      setQuoteConflictpopup(false);
      updateLoginStatus(true);
      setLoading(false);
      closeLoginModal();
      navigate(location.pathname);
    }
    setConflictUseCase('');

  };

  const handleUserNameChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setValidUserName(true);
    setUserName(e.target.value);
  };

  const handlePasswordChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setValidPassword(true);
    setPassword(e.target.value);
  };

  const validateCredentials = () => {
    resetLoginError();
    setLoading(true);

    setValidUserName(userName.length > 0);
    setValidPassword(password.length > 0);

    if (userName.length > 0 && password.length > 0) {
      //Local validations passed
      authenticateUser();
    } else {
      //local validations failed
      setLoading(false);
    }
  };

  const authenticateUser = () => {
    loginUser(userName, password)
      .then((response) => {
        const data: LoginResponse = response.data;

        if (response.status === HTTP_STATUS_CODES.SUCCESS && data.status === STATUSES.SUCCESS) {
          const { message, responseCode } = data.responseMessage;

          if (USER_AUTHENTICATED_RESPONSE_CODES.includes(responseCode)) {
            //User has logged in
            handleUserLogin(data);
          } else {
            //User has not logged in due to an error
            //Add UI to handle user account locked etc

            setLoginError({
              hasError: true,
              responseCode: responseCode,
              message: message
            });

            setLoading(false);
          }
        } else {
          //Handle server error

          handleLoginServerError(null);
        }
      })
      .catch((error: AxiosError<LoginError>) => {
        console.warn('LoginModal.tsx validateCredentials error :', error);
        //Handle server error

        handleLoginServerError(error);
      });
  };

  const handleLoginServerError = (error: AxiosError<LoginError> | null) => {
    if (isAxiosError(error)) {
      const errorObj = error.response?.data?.error;
      const errorKey = errorObj?.errorKey ?? '';
      const loginErrorObj = getErrorObj(errorKey, pageContent);
      setLoginError(loginErrorObj);
    } else {
      setLoginError({
        hasError: true,
        responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
        message: `<b>${pageContent?.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.SYSTEM_EXCEPTION_MESSAGE}`
      });
    }

    setLoading(false);
  };

  const resetLoginError = () => {
    setLoginError({
      hasError: false,
      message: '',
      responseCode: ''
    });
  };


  const handleUserLogin = async (data: LoginResponse) => {
    const loginSuccesspayload: LoginSuccessPayload = getLoginSuccessPayload(data, userName);

    setUserPassword(password)
    updateLoginResponse(loginSuccesspayload);

    // setLoading(false);

    if (data.responseMessage.responseCode === LOGIN_RESPONSE_CODES.TWO_FA_NEEDED && loginSuccesspayload.contacts.length > 0) {
      //User must complete 2fa to login
      initiateTwoFa();
    } else {
      //User doesn't need 2fa
      let tokenGenerated = await getToken();
      if (tokenGenerated || REACT_APP_ENV === 'local') {
        setUserLoginResponseData(data);
        data?.shopperProfile?.demographicInfo && !useGlobalStore.getState().applicantFormDetails.length && await processDemographicInfo(data?.shopperProfile?.demographicInfo)
        const updateWA = getWebAccount(data);
        updateWebAccount(updateWA);
        if (data.shopperProfile?.agentConnect) {
          updateAgentInfo(data.shopperProfile?.agentConnect!);
        }
        else if (isPublicAgentConnect && !data.shopperProfile?.agentConnect) {
          try {
            const agentPayload = getAgentConnectPayload(deepLinkInfo.agentConnectData);
            await saveShopperAgentConnect(agentPayload, useUserStore.getState().webAccountGUID);
            updateAgentInfo(deepLinkInfo.agentConnectData);
          }
          catch (error) {
            console.warn('LoginModal.tsx handleAgentConflictResolution error :', error);
            setLoginError({
              hasError: true,
              responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
              message: `<b>${pageContent?.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.SYSTEM_EXCEPTION_MESSAGE}`
            });
            setLoading(false);
          }
        }
        //Check if there is any in progress application
        const currentInProgressApplication = await getInProgressApplication(data.loginResponse.webAccountGUID);
        currentInProgressApplication && setInProgressApplication(currentInProgressApplication)
        const application_conflict_flag = currentInProgressApplication && !NON_CONFLICT_SCREENS.includes(location.pathname)

        if (window._satellite) {
          window._satellite.track('loginSuccessModalIos');
        } else {
          console.warn('window._satellite is not defined');
        }

        if (currentInProgressApplication && !application_conflict_flag) {
          //In Progress apps are avialable without conflict
          //So navigate to dashboard
          updateLoginStatus(true);
          setLoading(false)
          navigate(NAV_PATHS.DASHBOARD)
        } else if (application_conflict_flag) {
          //There is a conflict with application in progress and local session data
          setQuoteConflictpopup(true);
          setConflictUseCase(CONFLICT_USE_CASES.APPLICATION)
        } else if (deepLinkInfo.agentConnectData && data?.shopperProfile?.agentConnect
          && validateAgentConflict(deepLinkInfo.agentConnectData, data?.shopperProfile?.agentConnect)
        ) {
          // agent conflict it will check with agent connect data from redirection URL
          setIsAgentConflict(true);
        }
        else {
          //Check for conflict with saved quote
          const quote_conflict_flag = await getConflictInfo(location.pathname, selectedPlan);

          if (quote_conflict_flag && data.shopperProfile?.quotes?.[0]?.quoteLineItem.length) {
            //There is a conflict with saved quote and local session data
            setQuoteConflictpopup(true);
            setConflictUseCase(CONFLICT_USE_CASES.QUOTE)
          } else {
            // if user quote data is not available , will stay on same page if current page is from medical/dental/plan summary 
            //and don't call populateUserDetails function 
            if ((!data.shopperProfile?.quotes?.[0]?.quoteLineItem.length)
              && (location.pathname === NAV_PATHS.PLAN_SUMMARY
                || location.pathname === NAV_PATHS.MEDICAL_LISTING
                || location.pathname === NAV_PATHS.DENTAL_LISTING
                || location.pathname === NAV_PATHS.VISION_LISTING)) {
              updateLoginStatus(true);
              if (saveandResumeLaterFlag) {
                updateChangeDemographicFlag(true);
              }
              navigate(location.pathname)
            }
            else {
              try {
                await populateUserDetails(data);
                let getURL = await getNavigationInfo(data.shopperProfile?.demographicInfo?.demographicId, useUserStore.getState().role);
                navigate(getURL);
              } catch (error) {
                setLoginError({
                  hasError: true,
                  responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
                  message: `<b>${pageContent?.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.SYSTEM_EXCEPTION_MESSAGE}`
                });
              }
            }
            setLoading(false)
            closeLoginModal();
          }
        }
      }
      else {
        setLoading(false);
      }
    }
  };

  const onErrorPopupClose = () => {
    resetLoginError();
  };

  const getToken = async (): Promise<boolean> => {
    let returnVal = false;
    try {
      const response = await getBuildVersion();
      if (response.status === 200) {
        try {
          const tokenResponse = await generateTokenAPI();
          if (tokenResponse && tokenResponse.data.token) {
            useUserStore.getState().updateJWTToken({ jwtToken: tokenResponse.data.token });
            returnVal = true;
          } else {
            returnVal = false;
            setTokenError(tokenResponse.data.responseMessage ? tokenResponse.data.responseMessage.responseCode : null, tokenResponse.data.responseMessage ? tokenResponse.data.responseMessage.message : null)
          }
        } catch (error: any) {
          returnVal = false;
          setTokenError(error.response?.data?.error?.errorKey, error.response?.data?.error?.message);
        }
      }
    }
    catch (error: any) {
      returnVal = false;
      setTokenError(error.response?.data?.error?.errorKey, error.response?.data?.error?.message);
    }
    finally {
      return returnVal;
    }
  }

  const setTokenError = (responseCode, responseMessage) => {
    setLoginError({
      hasError: true,
      responseCode: responseCode ? responseCode : HTTP_STATUS_CODES.SERVER_ERROR.toString(),
      message: responseMessage ? responseMessage : `<b>${pageContent?.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.SYSTEM_EXCEPTION_MESSAGE}`
    });

  }

  const handleForgotUserName = () => {
    let url: string;
    if (isPHILogin) {
      switch (zipCodeRes?.stateCode) {
        case LQE_NY_STATE: url = FORGOT_PASSWORD_REDIRECTION_URLS.NEW_YORK;
          break;
        case CALIFORNIA_CODE:
          url = FORGOT_PASSWORD_REDIRECTION_URLS.CALIFORNIA;
          break;
        default:
          url = FORGOT_PASSWORD_REDIRECTION_URLS.ALL_OTHER_STATES;
      }
      window.open(url, '_blank');
    }
    else {
      navigate(NAV_PATHS.FORGOT_USERNAME);
    }
    closeLoginModal();
  }

  const handleForgotPassword = () => {
    let url: string;
    if (isPHILogin) {
      switch (zipCodeRes?.stateCode) {
        case LQE_NY_STATE: url = FORGOT_PASSWORD_REDIRECTION_URLS.NEW_YORK;
          break;
        case CALIFORNIA_CODE:
          url = FORGOT_PASSWORD_REDIRECTION_URLS.CALIFORNIA;
          break;
        default:
          url = FORGOT_PASSWORD_REDIRECTION_URLS.ALL_OTHER_STATES;
      }
      window.open(url, '_blank');
    }
    else {
      navigate(NAV_PATHS.FORGOT_PASSWORD);
    }
    closeLoginModal();
  }

  const handleRegistration = () => {
    let url: string;
    if (isPHILogin) {
      switch (zipCodeRes?.stateCode) {
        case LQE_NY_STATE: url = REGISTRATION_REDIRECTION_URLS.NEW_YORK;
          break;
        case CALIFORNIA_CODE:
          url = REGISTRATION_REDIRECTION_URLS.CALIFORNIA;
          break;
        default:
          url = REGISTRATION_REDIRECTION_URLS.ALL_OTHER_STATES;
      }
      window.open(url, '_blank');
    }
    else {
      navigate(NAV_PATHS.REGISTRATION);
    }
    closeLoginModal();
  }

  return (
    <div>
      {!contentLoaded ? (
        <PreLoader id={'login-modal-loader'} />
      ) : (<div>
        <div className={'modal-body-top'}>
          <div className="login-descriptor-container">
            <p id="login-modal-description" className={'login-description'}>
              {loginDesc ? loginDesc : (save ? pageContent.SAVE_AND_RESUME_LATER_DESCRIPTION : pageContent.LOGIN_DESCRIPTION)}
              {/* {save ? pageContent.SAVE_AND_RESUME_LATER_DESCRIPTION : pageContent.LOGIN_DESCRIPTION} */}
            </p>
          </div>
          {!loading && (
            <div>
              <div className={'fwc-input input-container'}>
                <label id="login-modal-username-label" className="fwc-label" htmlFor={'login-modal-username-input'}>
                  {pageContent.USER_NAME}
                </label>
                <input id="login-modal-username-input" type="text" value={userName} onChange={handleUserNameChange} />
                {!validUserName && (
                  <div className="fwc-row fwc-row-bm fwc-col-12">
                    <div className="fwc-input">
                      <span id="login-modal-username-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {' '}
                        {getErrorMessage(pageContent, INPUT_TYPES.USERNAME, userName)}
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <div className={'fwc-input input-container'}>
                <label id="login-modal-password-label" className="fwc-label" htmlFor={'login-modal-password-input'}>
                  {pageContent.PASSWORD}
                </label>
                <div className="password-input-container password-container-position">
                  <input id="login-modal-password-input" type={passwordVisible ? 'text' : 'password'} value={password} onChange={handlePasswordChange} />

                  <span
                    id="login-modal-show-password-icon"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    className={passwordVisible ? 'sae-icon sae-icon-visualy-impaired password-icon' : 'sae-icon sae-icon-eye password-icon'}
                  />
                </div>
                {!validPassword && (
                  <div className="fwc-row fwc-row-bm fwc-col-12">
                    <div className="fwc-input">
                      <span id="login-modal-password-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {' '}
                        {getErrorMessage(pageContent, INPUT_TYPES.PASSWORD, password)}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {loginError.hasError && !loading && (
            <InfoBar
              area={ERROR_ALERT_CONFIG.AREA}
              backgroundColor={'error'}
              labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
              type={ERROR_ALERT_CONFIG.ERROR}
              handleClose={() => {
                onErrorPopupClose();
              }}
            >
              <p className="message" dangerouslySetInnerHTML={{ __html: loginError.message }} />
            </InfoBar>
          )}

          {loading ? (
            <PreLoader id={'login-modal-loader'} />
          ) : (
            <button
              id="login-modal-login-button"
              data-analytics="loginBtnLoginModalIos"
              className={'fwc-btn fwc-btn-primary fwc-col-12 button-text'}
              onClick={() => {
                validateCredentials();
              }}
            >
              {pageContent.LOGIN_BUTTON}
            </button>
          )}

          <div className="hyper-link-container">
            <a
              id="login-modal-forgot-username"
              data-analytics="forgotUsernameLoginModalIos"
              href={'javascript:void(0)'}
              onClick={() => {
                handleForgotUserName();
              }}
            >
              {pageContent.FORGOT_USERNAME}
            </a>
            <label id="login-modal-forgot-password" className={'fwc-label seperator-text'}>
              {pageContent.OR}
            </label>
            <a
              id="login-modal-forgot-password"
              href={'javascript:void(0)'}
              data-analytics="forgotPasswordLoginModalIos"
              onClick={() => {
                handleForgotPassword()
              }}
            >
              {pageContent.FORGOT_PASSWORD}
            </a>
          </div>
        </div>
        <div className={'modal-body-btm'}>
          <h3 id="login-modal-create-profile-label" className="bottome-title">
            {pageContent.DO_NOT_HAVE_A_PROFILE_YET}
          </h3>
          <div className="create-profile-desc-container">
            <p id="login-modal-create-profile-description" className={'login-description'}>
              {profileDesc?.DESC1 ? profileDesc.DESC1 : pageContent.CREATE_PROFILE_DESCRIPTION}
            </p>
            {
              profileDesc?.DESC2 &&
              <p id="login-modal-create-profile-description" className={'login-description'}>
                {profileDesc?.DESC2}
              </p>
            }
          </div>
          <button
            id="login-modal-create-profile-button"
            data-analytics="createProfileLoginModalIos"
            onClick={() => {
              handleRegistration()
            }}
            className={'fwc-btn fwc-btn-secondary fwc-col-12 button-text'}
          >
            {pageContent.CREATE_PROFILE}
          </button>
        </div>
      </div>)}
      {quoteConflictpopup &&
        <QuoteConflict showPopup={quoteConflictpopup} popupClose={() => { setQuoteConflictpopup(false) }} useCase={conflictUseCase}
          onClick={handleQuoteConflictClick}
        />
      }
      {isAgentConflict &&
        <AgentConflictPopup showPopup={isAgentConflict} popupClose={() => { setIsAgentConflict(false) }}
          currentAgentDetails={deepLinkInfo.agentConnectData || {} as AgentConnect}
          previousAgentConnectDetails={userLoginResponseData?.shopperProfile?.agentConnect || {} as AgentOfRecord}

          onClick={handleAgentConflictClick}
        />
      }
    </div>
  );
}
export default LoginModal;

