const MAC_LOGIN_TABS_VALUES = {
  LOGIN: 'LOGIN',
  MY_ANTHEM_CARD: 'MY_ANTHEM_CARD'
};

const MAC_LOGIN_TABS: ShopperTab[] = [
  {
    value: MAC_LOGIN_TABS_VALUES.LOGIN,
    label: ''
  },
  {
    value: MAC_LOGIN_TABS_VALUES.MY_ANTHEM_CARD,
    label: ''
  }
];

const PHI_LOGIN_SUCCESS_CODES = {
  PHI_LOGIN_SUCCESS: 'MEMBERPHILOGIN000'
};

const PHI_LOGIN_ERROR_CODES = {
  PHI_LOGIN_AUTHENTICATION_FAILED: 'MEMBERPHILOGIN200'
};

const BROWSER_TAB_TITLE = 'Change My Coverage';

export { BROWSER_TAB_TITLE, MAC_LOGIN_TABS, MAC_LOGIN_TABS_VALUES, PHI_LOGIN_ERROR_CODES, PHI_LOGIN_SUCCESS_CODES };
