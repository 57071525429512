import { PHONE_FORMATTED } from '../../../../shared/globalConstants';
import { default as strings } from '../../account-recovery/accountRecovery.json';
import { default as Content } from './personalinfo.json';

const content = strings.data.iospagecontentList.items[0].pageContent.AccountRecovery;

const Strings = Content.data.iospagecontentList.items[0].pageContent.PersonalInfo;

const validatePhoneNumber = (value: string, phoneNumberType: string) => {
  const errorConfig = {
    isValidPhoneNumber: true,
    phoneNumberErrorMessage: ''
  };
  if (value.length === 0) {
    errorConfig.isValidPhoneNumber = false;
    errorConfig.phoneNumberErrorMessage = content.REQUIRED_FIELD;
  } else if (!PHONE_FORMATTED.test(value)) {
    errorConfig.isValidPhoneNumber = false;
    errorConfig.phoneNumberErrorMessage = Strings.ERROR_MESSAGES.INVALID_PHONE;
  } else if (!phoneNumberType) {
    errorConfig.isValidPhoneNumber = false;
    errorConfig.phoneNumberErrorMessage = Strings.SELECT_PHONE_TYPE;
  }
  return errorConfig;
};

const unFormatePhoneNumber = (value: string) => {
  const unFormatedNumber = value.match(/\d/g)?.join('');
  return unFormatedNumber ? unFormatedNumber : '';
};

export { unFormatePhoneNumber, validatePhoneNumber };

