const getUpdatedStateOptions = (states: States, labels: any) => {
  const updatedStates = states.map((state) => {
    return {
      ...state,
      label: labels[state.key]
    };
  });

  return updatedStates;
};

export { getUpdatedStateOptions };
