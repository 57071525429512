import { StateCreator } from 'zustand';

import { MEDICAL_PLANS_TABS, SORT_OPTIONS } from '../pages/public/medical-plans/constants';
import { PRODUCT_FILTER_OPTIONS } from '../pages/secure/product-selection/constants';

const initialState = {
  selectedProductTab: MEDICAL_PLANS_TABS.OFF_MARKET_PLANS,
  medicalProducts: [],
  dentalProducts: [],
  visionProducts: [],
  selectedDentalProducts: [],
  selectedVisionProducts: [],
  selectedProduct: {
    medicalProduct: {} as Contract,
    medicalApplicants: [],
    dentalApplicants: [],
    visionApplicants: []
  },
  comparedProducts: [],
  productTypeFilters: PRODUCT_FILTER_OPTIONS.PRODUCT_TYPES,
  productsSortOptions: SORT_OPTIONS,
  productsSwitchFilters: PRODUCT_FILTER_OPTIONS.MORE_FILTERS.SWITCH_FILTERS,
  productsBenefitsTiers: PRODUCT_FILTER_OPTIONS.MORE_FILTERS.BENEFITS_TIER
};

export const createProductStoreSlice: StateCreator<ProductStoreSlice> = (set) => ({
  ...initialState,
  updateSelectedProductTab: (selectedProductTab: string) => set(() => ({ selectedProductTab: selectedProductTab })),
  updateMedicalProducts: (medicalProducts: Contracts) => set(() => ({ medicalProducts: medicalProducts })),
  updateDentalProducts: (dentalProducts: Contracts) => set(() => ({ dentalProducts: dentalProducts })),
  updateVisionProducts: (visionProducts: Contracts) => set(() => ({ visionProducts: visionProducts })),
  updateSelectedDentalProducts: (selectedDentalProducts: SelectedDentalProducts) => set(() => ({ selectedDentalProducts: selectedDentalProducts })),
  updateSelectedVisionProducts: (selectedVisionProducts: SelectedVisionProducts) => set(() => ({ selectedVisionProducts: selectedVisionProducts })),
  updateSelectedProduct: (selectedProduct: SelectedProduct) => set(() => ({ selectedProduct: selectedProduct })),
  updateComparedProducts: (comparedProducts: SelectedProducts) => set(() => ({ comparedProducts: comparedProducts })),
  updateProductsSortOptions: (productsSortOptions: SortOption[]) => set(() => ({ productsSortOptions: productsSortOptions })),
  updateProductSwitchFilters: (productsSwitchFilters: SwitchFilter[]) => set(() => ({ productsSwitchFilters: productsSwitchFilters })),
  updateProductsBenefitsTiers: (productsBenefitsTiers: CheckBoxFilterOption[]) => set(() => ({ productsBenefitsTiers: productsBenefitsTiers })),
  updateProductTypeFilters: (productTypeFilters: CheckBoxFilterOption[]) => set(() => ({ productTypeFilters: productTypeFilters })),
  resetProductStates: () => set(() => ({ ...initialState }))
});
