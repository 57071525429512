import { AGENT_ROLES, SET_APPLICATION_CONSTANTS } from '../../../../../shared/globalConstants';

const validatePin = (value: string, labels: any) => {
  const errorConfig = {
    isValidPin: true,
    pinErrorMessage: ''
  };

  if (value.length === 0) {
    errorConfig.isValidPin = false;
    errorConfig.pinErrorMessage = labels.REQUIRED_FIELD;
  }

  return errorConfig;
};

const getTransferApplicationPayload = (acn: string, webguid: string = '', useAcnAsUserId: boolean = false) => {
  return {
    acn: acn,
    targetRole: AGENT_ROLES.CONSUMER,
    shopperId: useAcnAsUserId ? acn : webguid,
    partnerId: SET_APPLICATION_CONSTANTS.OLS,
    agentProfile: {
      userId: useAcnAsUserId ? acn : webguid
    }
  };
};

export { getTransferApplicationPayload, validatePin };
