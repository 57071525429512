import axiosInstance from '../../../api/axiosWrapper/axiosWrapper';

import { API_NAME_LIST } from '../../../api/api-name-list';

const getAgentSSOInfo = (ssoID: string) => {
  return axiosInstance.get(API_NAME_LIST.AGENT_SSO_INFO + ssoID);
};

const initiateAgentAuthentication = (payload: AgentAuthenticationPayload, userID: string) => {
  return axiosInstance.post(API_NAME_LIST.AGENT_AUTHENTICATION, payload, {
    headers: {
      userId: userID
    }
  });
};

export { getAgentSSOInfo, initiateAgentAuthentication };
