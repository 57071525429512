import { API_NAME_LIST } from '../../../api/api-name-list';
import axiosInstance from '../../../api/axiosWrapper/axiosWrapper';

const setApplicationApi = (payload: SetApplicationPayload) => {
  return axiosInstance.post(API_NAME_LIST.SET_APPLICATION, payload);
};

const getEncryptAcn = (payload: EncryptacnPayload) => {
  return axiosInstance.post(API_NAME_LIST.ENCRYPT_ACN, payload);
};

const updateQuoteStatus = (payload: UpdateQuoteStatusPayload) => {
  return axiosInstance.post(API_NAME_LIST.UPDATE_QUOTE_STATUS, payload);
};

export { getEncryptAcn, setApplicationApi, updateQuoteStatus };
