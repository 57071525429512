import React from "react";


function NoMedicationsFound({ title, description }: NoMedicationsFoundProps) {
  return (
    <div className="fwc-col-12 sm-12">
      <h2 className="btm-xxs title">
        <span className="sae-icon right-xxs"></span>
        {title}
      </h2>

      <div
        className="startpageContent"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </div>
  );
}

export default React.memo(NoMedicationsFound);
