const STATE_VALUES = {
  SELECT_YOUR_STATE: 'Select',
  NEW_YORK: 'New York',
  CALIFORNIA: 'California',
  ALL_OTHER_STATES: 'All Other States'
};

const STATES_OPTIONS = [
  {
    key: 'SELECT_YOUR_STATE',
    label: '',
    value: STATE_VALUES.SELECT_YOUR_STATE
  },
  {
    key: 'NEW_YORK',
    label: '',
    value: STATE_VALUES.NEW_YORK
  },
  {
    key: 'CALIFORNIA',
    label: '',
    value: STATE_VALUES.CALIFORNIA
  },
  {
    key: 'ALL_OTHER_STATES',
    label: '',
    value: STATE_VALUES.ALL_OTHER_STATES
  }
];

export { STATES_OPTIONS, STATE_VALUES };
