import { ChangeEvent } from 'react';
import {
  SPECIAL_CHARACTERS,
  ANSWER_POSSIBLE_ERROR,
  SECURITY_QUESTIONS,
  QUESTIONFIELDS,
  ANSWERFIELDS,
} from './constants';

const getUpdatedAnswerErrors = (
  id: string,
  values = [{ label: '', value: '' }],
  answers = { answer1: '', answer2: '', answer3: '' },
  showAnswerError = ANSWER_POSSIBLE_ERROR,
  username: string,
  password: string
) => {
  const questions = values.map((question) => question.value);
  const updatedAnswerError = { ...showAnswerError };
  updatedAnswerError[id] = {
    isempty: answers[id] === '',
    minValueValidation: answers[id]?.length <= 3,
    isSpaceAdded: answers[id]?.startsWith(' ') || answers[id]?.endsWith(' '),
    specialCharacters: SPECIAL_CHARACTERS.test(answers[id]),
    isQuestionAdded: questions.includes(answers[id]),
    isUsernameAdded: answers[id] === username,
    isPasswordAdded: answers[id] === password,
    id: id,
  };
  return updatedAnswerError;
};

const getUpdatedQuestions = (
  questions = SECURITY_QUESTIONS,
  e: ChangeEvent<HTMLSelectElement>
) => {
  const updatedQuestions = questions.map((question) => {
    if (question.value === e.target.value) {
      question.questionId = e.target.name;
      question.isSelected = true;
    } else if (question.questionId === e.target.name) {
      question.questionId = '';
      question.isSelected = false;
    }
    return question;
  });
  return updatedQuestions;
};

const checkAnswerHasError = (showAnswerError = ANSWER_POSSIBLE_ERROR) => {
  let hasError = false;
  Object.values(showAnswerError).forEach((item) => {
    if (Object.values(item).includes(true)) {
      hasError = true;
    }
  });
  return hasError;
};

const checkQuestionHasError = (
  selectedquestions = QUESTIONFIELDS,
  answers = ANSWERFIELDS
) => {
  return (
    Object.values(selectedquestions).some((value) =>
      ['', 'Select'].includes(value)
    ) || Object.values(answers).some((value) => value === '')
  );
};

const getSecretQuestionAnswers = (
  selectedquestions = QUESTIONFIELDS,
  answers = ANSWERFIELDS
) => {
  return [
    {
      securityQuestion: selectedquestions.question1,
      securityAnswer: answers.answer1,
    },
    {
      securityQuestion: selectedquestions.question2,
      securityAnswer: answers.answer2,
    },
    {
      securityQuestion: selectedquestions.question3,
      securityAnswer: answers.answer3,
    },
  ];
};
export {
  getUpdatedAnswerErrors,
  getUpdatedQuestions,
  checkAnswerHasError,
  checkQuestionHasError,
  getSecretQuestionAnswers,
};
