import { useUserStore } from '../../store/userStore';
import { API_NAME_LIST } from '../api-name-list';
import axiosInstance from '../axiosWrapper/axiosWrapper';
export const generateTokenAPI = () => {
  return axiosInstance.post(API_NAME_LIST.GENERATE_TOKEN, {});
};
export const getBuildVersion = () => {
  return axiosInstance.get(API_NAME_LIST.GET_BUILD_VERSION, {});
};

export const refreshTokenAPI = () => {
  return axiosInstance.post(API_NAME_LIST.REFRESH_TOKEN, {});
};

export const generateToken = async () => {
  generateTokenAPI().then((response) => {
    const data: any = response.data;
    if (data && data.token) {
      useUserStore.getState().updateJWTToken({ jwtToken: data.token });
    } else {
      console.error('Token not generated');
    }
  });
};

export const refreshToken = async () => {
  refreshTokenAPI().then((response) => {
    const data: any = response.data;
    if (data && data.token) {
      useUserStore.getState().updateJWTToken({ jwtToken: data.token });
    } else {
      console.error('Token not generated');
    }
  });
};
