import { StateCreator } from 'zustand';

export const createEstimateSavingsStore: StateCreator<EstimateSavingsStore> = (set) => ({
  subsidyresponse: {
    correlationId: '',
    transactionId: '',
    status: '',
    subsidyCalc: [],
    responseMessage: {
      responseCode: '',
      message: ''
    }
  },
  subsidyValue: -1,
  householdMemberValues: {
    houseHoldIncome: '',
    dependents: '0'
  },
  updateEstimateSavingsResponse: (subsidyresponse: EstimateSavingsResponse) =>
    set({
      subsidyresponse: subsidyresponse
    }),
  updateSubsidyValue: (value) =>
    set({
      subsidyValue: value ? value : 0
    }),
  updateHouseholdMemberValues: (householdValues: HouseholdMemberDetails) =>
    set({
      householdMemberValues: householdValues
    }),
  resetHouseHoldMemberValues: () => set({ householdMemberValues: { houseHoldIncome: '', dependents: '0' } }),
  resetEstimateSavings: () =>
    set({
      subsidyresponse: {
        correlationId: '',
        transactionId: '',
        status: '',
        subsidyCalc: [],
        responseMessage: {
          responseCode: '',
          message: ''
        }
      },
      subsidyValue: -1,
      householdMemberValues: {
        houseHoldIncome: '',
        dependents: '0'
      }
    })
});
