import { Button, Tooltip } from '@sydney-broker-ui/ios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { ALPHA_REGX_WITH_SPACE_HYPEN, CALIFORNIA_CODE, DEFAULT_YEAR, MEDICAL_PLAN_TYPES, PARENT_VALUES, PLAN_TYPES, PLAN_TYPES_CONTENT, RELATIONSHIP, SET_APPLICATION_CONSTANTS, TOBACCO_STATE_CODES } from '../../../shared/globalConstants';
import { convertAgentApiDatatoFormDataFormat, convertApiDatatoFormDataFormat, isApplicantDateInRange, isApplicantSpouseInRange, isDateinFuture, isDependentChildDateInRange, isInValidDate } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { REGEXS } from '../../public/account-recovery/constants';
import "./AgentHCIDBasicsLanding.scss";

interface AgentHCIDApplicationInfoProps {
    pageContent: any;
    memberInfo: any;
    errors: any;
    dependentsDisable: boolean;
}

const AgentHCIDApplicationInfo: React.FC<AgentHCIDApplicationInfoProps> = ({ pageContent, memberInfo, errors, dependentsDisable }) => {


    const [showApplicationBlock, setShowApplicationBlock] = useState<boolean>(false);
    const [showParentTextBlock, setShowParentTextBlock] = useState(false);

    const { register, watch, setValue, getValues, setError, control, trigger } = useFormContext();

    /* --------- stores ------------*/

    const stores = {
        globalStore: useGlobalStore(),
        userStore: useUserStore(),
    };

    /* --------- stores ------------*/

    /*----------------- store data --------------*/

    useEffect(() => {
        if (memberInfo && memberInfo.demographicInfo) {
            let storeFormData = convertAgentApiDatatoFormDataFormat(memberInfo.demographicInfo.applicant);
            setValue('applicant', storeFormData.applicant);
            setValue('spouse', storeFormData.spouse);
            setValue('dependents', storeFormData.dependents);
        }
    }, [memberInfo]);

    useEffect(() => {
        if (stores.globalStore.agentHCIDBlocks.applicantBlock && stores.globalStore.agentHCIDBlocks.coveragesBlock) {
            setShowApplicationBlock(true);
        }
    }, [stores.globalStore.agentHCIDBlocks.applicantBlock, stores.globalStore.agentHCIDBlocks.coveragesBlock])

    useEffect(() => {
        if (dependentsDisable || ((stores.globalStore.agentMacPlanTypes.length === 1 && stores.globalStore.agentMacPlanTypes[0] === PLAN_TYPES_CONTENT.V) && (getValues("coverageType") === PLAN_TYPES.DENTAL || getValues("coverageType") === PLAN_TYPES.VISION))) {
            let storeFormData = convertApiDatatoFormDataFormat(stores.globalStore.memberInfo.demographicInfo.applicant, stores.globalStore.memberInfo.demographicInfo.state);
            setValue('applicant', storeFormData.applicant);
            setValue('spouse', storeFormData.spouse);
            setValue('dependents', storeFormData.dependents);
        }
    }, [dependentsDisable])

    /*----------------- store data --------------*/

    /* ---- Field Array Methods -----*/
    const {
        fields: dependentFields,
        append: dependentAppend,
        remove: dependentRemove,
    } = useFieldArray({ control, name: "dependents" });
    const {
        fields: spouseFields,
        append: spouseAppend,
        remove: spouseRemove,
    } = useFieldArray({ control, name: "spouse" });

    const spouseFieldsWatch = watch("spouse");
    useEffect(() => {
        if (spouseFieldsWatch.length > 0 && spouseFields.length === 0) {
            setValue('spouse', spouseFieldsWatch);
        }
    }, [spouseFieldsWatch]);
    const dependentFieldsWatch = watch("dependents");
    useEffect(() => {
        if (dependentFieldsWatch.length > 0 && dependentFields.length === 0) {
            setValue('dependents', dependentFieldsWatch);
        }
    }, [dependentFieldsWatch]);
    /* ---- Field Array Methods -----*/

    const checkforParentValue = () => {
        if ((MEDICAL_PLAN_TYPES.includes(stores.globalStore.agentCoverageType) && stores.globalStore.agentState === CALIFORNIA_CODE)) {
            let dependentValues = getValues().dependents.length > 0 ? getValues().dependents : stores.globalStore.agentApplicantFormDetails.filter((data) => data.applicantType === "DEPENDENT");
            if (dependentValues?.length && dependentValues.some(e => PARENT_VALUES.includes(e.applicantType))) {
                setShowParentTextBlock(true);
            } else {
                setShowParentTextBlock(false);
            }
        } else {
            setShowParentTextBlock(false);
        }
    }

    /* ---- form Methods -----*/
    const validateFirstName = (value) => {
        return ALPHA_REGX_WITH_SPACE_HYPEN.test(value);
    }


    const validateTobacco = (value) => {
        if (value) {
            return value === SET_APPLICATION_CONSTANTS.YES || value === SET_APPLICATION_CONSTANTS.NO;
        } else {
            return pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD;
        }
    }

    const validateRealationShipType = (id: 'applicant' | `dependents.${any}` | `spouse.${any}`, value) => {
        checkforParentValue();
        if (getValues(`${id}.dateOfBirth`) !== '') {
            trigger(`${id}.dateOfBirth`);
        }
        if (value === RELATIONSHIP.PARENTINLAW && spouseFields.length === 0) {
            setError(`${id}.applicantType`, { type: 'validate' });
            return false;
        }
        return value !== '';
    }

    const validateAge = (type, value, id?: 'applicant' | `dependents.${any}` | `spouse.${any}`, relationType?) => {
        let spouseLength = spouseFields.length;
        let invalidDate = isInValidDate(value);
        if (value === '' && invalidDate) {
            return pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD;
        } else if (invalidDate) {
            return pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_DATE;
        } else if (isDateinFuture(stores.globalStore.agentZipCodeRes.currentDateTimeInZone, value)) {
            return pageContent?.FORM_CONTENT.ERROR_MESSAGES.FUTURE_DATE;
        } else if (type === 'applicant' && value) {
            if (spouseLength > 0) {
                let val1 = isApplicantDateInRange(stores.globalStore.agentCoverageDate, value);
                if (val1) {
                    let val = isApplicantSpouseInRange(stores.globalStore.agentCoverageDate, value);
                    if (!val) {
                        return pageContent?.FORM_CONTENT.ERROR_MESSAGES.APPLICANT_SPOUSE_DOB_ERROR;
                    }
                } else {
                    return stores.globalStore.agentCoverageType ? (MEDICAL_PLAN_TYPES.includes(stores.globalStore.agentCoverageType) ? pageContent?.FORM_CONTENT.ERROR_MESSAGES.APPLICANT_ONLY_DOB_MEDICAL_ERROR : pageContent?.FORM_CONTENT.ERROR_MESSAGES.APPLICANT_ONLY_DOB_DENTAL_VISION_ERROR) : '';
                }
                return val1;
            } else {
                let val = isApplicantDateInRange(stores.globalStore.agentCoverageDate, value);
                if (!val) {
                    return stores.globalStore.agentCoverageType ? (MEDICAL_PLAN_TYPES.includes(stores.globalStore.agentCoverageType) ? pageContent?.FORM_CONTENT.ERROR_MESSAGES.APPLICANT_ONLY_DOB_MEDICAL_ERROR : pageContent?.FORM_CONTENT.ERROR_MESSAGES.APPLICANT_ONLY_DOB_DENTAL_VISION_ERROR) : '';
                }
                return val;
            }
        } else if (type === 'spouse' && value) {
            let val1 = isApplicantDateInRange(stores.globalStore.agentCoverageDate, value);
            let val = isApplicantSpouseInRange(stores.globalStore.agentCoverageDate, value);
            if (val1) {
                if (getValues('applicant.dateOfBirth') !== '') {
                    trigger('applicant.dateOfBirth');
                }
                if (!val) {
                    return pageContent?.FORM_CONTENT.ERROR_MESSAGES.APPLICANT_SPOUSE_DOB_ERROR;
                }
            } else {
                return stores.globalStore.agentCoverageType ? (MEDICAL_PLAN_TYPES.includes(stores.globalStore.agentCoverageType) ? pageContent?.FORM_CONTENT.ERROR_MESSAGES.APPLICANT_ONLY_DOB_MEDICAL_ERROR : pageContent?.FORM_CONTENT.ERROR_MESSAGES.APPLICANT_ONLY_DOB_DENTAL_VISION_ERROR) : '';
            }
            return val;
        } else if (type === 'dependent' && value) {
            //isDependentChildDateInRange
            if (stores.globalStore.agentState === CALIFORNIA_CODE && MEDICAL_PLAN_TYPES.includes(stores.globalStore.agentCoverageType)) {
                if ((relationType === RELATIONSHIP.CHILD || relationType === RELATIONSHIP.OTHER)) {
                    let val = isDependentChildDateInRange(stores.globalStore.agentCoverageDate, value);
                    if (!val) {
                        return pageContent?.FORM_CONTENT.ERROR_MESSAGES.DEPENDENT_DOB_ERROR;
                    }
                    return val;
                } else {
                    let val = isApplicantDateInRange(stores.globalStore.agentCoverageDate, value);
                    if (!val) {
                        return pageContent?.FORM_CONTENT.ERROR_MESSAGES.APPLICANT_ONLY_DOB_MEDICAL_ERROR;
                    } else {
                        return true;
                    }
                }
            } else {
                let val = isDependentChildDateInRange(stores.globalStore.agentCoverageDate, value);
                if (!val) {
                    return pageContent?.FORM_CONTENT.ERROR_MESSAGES.DEPENDENT_DOB_ERROR;
                }
                return val;
            }
        }
    }

    const validateEmailAddress = (value) => {
        setValue('applicant.email', value);
        return REGEXS.EMAIL.test(value);
    }

    /* ---- form Methods -----*/

    /* ---- Add Spouse and Dependents Method -----*/
    const addDepSpouseFormFields = (e, type: string) => {
        let length = spouseFields.length + dependentFields.length;
        e.preventDefault();
        if (type === "dependent") {
            dependentAppend({
                applicantType: (stores.globalStore.agentState === CALIFORNIA_CODE && MEDICAL_PLAN_TYPES.includes(stores.globalStore.agentCoverageType)) ? "" : RELATIONSHIP.CHILD,
                firstName: "",
                lastName: "",
                dateOfBirth: "",
                isTabaccoUser: (stores.globalStore.agentState && stores.globalStore.agentState === SET_APPLICATION_CONSTANTS.KY && stores.globalStore.agentYear !== DEFAULT_YEAR) ? SET_APPLICATION_CONSTANTS.NO : !((stores.globalStore.agentState && TOBACCO_STATE_CODES.includes(stores.globalStore.agentState))) ? SET_APPLICATION_CONSTANTS.NO : '',
                applicantId: (length === 0) ? (1).toString() : (length + 1).toString()
            });
        } else {
            spouseAppend({
                applicantType: "SPOUSE",
                firstName: "",
                lastName: "",
                dateOfBirth: "",
                isTabaccoUser: (stores.globalStore.agentState && stores.globalStore.agentState === SET_APPLICATION_CONSTANTS.KY && stores.globalStore.agentYear !== DEFAULT_YEAR) ? SET_APPLICATION_CONSTANTS.NO : !((stores.globalStore.agentState && TOBACCO_STATE_CODES.includes(stores.globalStore.agentState))) ? SET_APPLICATION_CONSTANTS.NO : '',
                applicantId: (length === 0) ? (1).toString() : (length + 1).toString()
            });
        }
    };
    /* ---- Add Spouse and Dependents Method -----*/

    return (
        <>
            <div className='demographic-data'>
                {!showApplicationBlock && <div className='overlayCustom'></div>}
                <div className='fwc-row'>
                    <div className='fwc-col-12'>
                        <h4 className='sub-title'>{pageContent?.CONTENT.APPLICANT_DEMOGRAPHICS}</h4>
                    </div>
                </div>
                <div className={"fwc-row nomargin " + (getValues('applicant.isPolicyApplicant') === SET_APPLICATION_CONSTANTS.YES ? "whiteBg " : "")}>
                    <div className='fwc-col-5 md-6 sm-12'>
                        <div className='form-group'>
                            <div className="fwc-input">
                                <label className="fwc-label" htmlFor="firstName">
                                    {pageContent?.FORM_CONTENT.LABELS.FIRST_NAME}
                                </label>
                                <input
                                    id="firstName"
                                    placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.FIRST_NAME}
                                    type="text"
                                    readOnly={getValues('applicant.isPolicyApplicant') === SET_APPLICATION_CONSTANTS.YES}
                                    {...register('applicant.firstName', {
                                        required: {
                                            value: true,
                                            message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD
                                        },
                                        validate: validateFirstName
                                    })}
                                />
                                {errors && errors.applicant?.firstName && errors.applicant?.firstName && errors.applicant?.firstName.type === "required" && (
                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                        {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                    </span>
                                )}
                                {errors?.applicant?.firstName && 'type' in errors.applicant.firstName && errors.applicant.firstName.type === "validate" && (
                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                        {pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_CHARACTER}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <Controller
                        control={control}
                        defaultValue={'0'}
                        render={({ field }) => (
                            <input type="hidden" {...field} />
                        )}
                        name={`applicant.applicantId`}
                    />
                    <div className='fwc-col-5 md-6 sm-12'>
                        <div className='form-group'>
                            <div className="fwc-input">
                                <label className="fwc-label" htmlFor="lastName">
                                    {pageContent?.FORM_CONTENT.LABELS.LAST_NAME}
                                </label>
                                <input
                                    id="lastName"
                                    placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.LAST_NAME}
                                    type="text"
                                    readOnly={getValues('applicant.isPolicyApplicant') === SET_APPLICATION_CONSTANTS.YES}
                                    {...register('applicant.lastName', {
                                        required: {
                                            value: true,
                                            message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD
                                        },
                                        validate: validateFirstName
                                    })}
                                />
                                {errors && errors.applicant?.lastName && errors.applicant?.lastName && errors.applicant?.lastName.type === "required" && (
                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                        {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                    </span>
                                )}
                                {errors && errors.applicant?.lastName && errors.applicant?.lastName && errors.applicant?.lastName.type === "validate" && (
                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                        {pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_CHARACTER}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"fwc-row wrap-flex " + (getValues('applicant.isPolicyApplicant') === SET_APPLICATION_CONSTANTS.YES ? "whiteBg " : "")}>
                    <div className='fwc-col-5 md-6 sm-12'>
                        <div className='fwc-row'>
                            <div className='fwc-col-6'>
                                <div className='form-group'>
                                    <div className="fwc-input dob">
                                        <label className="fwc-label" htmlFor="applicant.dateOfBirth">
                                            {pageContent?.FORM_CONTENT.LABELS.DATE_OF_BIRTH}
                                        </label><span><Tooltip icon message={pageContent?.FORM_CONTENT.LABELS.HELP_DATE_OF_BIRTH} /></span>
                                        <input type="date" id="applicant.dateOfBirth" readOnly={getValues('applicant.isPolicyApplicant') === SET_APPLICATION_CONSTANTS.YES}
                                            placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.DOB} min={dayjs('1900-01-01').format('YYYY-MM-DD')} max={dayjs().format('YYYY-MM-DD')} {...register(`applicant.dateOfBirth`, {
                                                validate: (value) => validateAge('applicant', value, `applicant`),
                                            })} />
                                        {errors && errors.applicant?.dateOfBirth && errors.applicant?.dateOfBirth && errors.applicant?.dateOfBirth.type === "validate" && (
                                            <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                {errors.applicant?.dateOfBirth.message}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {((stores.globalStore.agentState && stores.globalStore.agentState === SET_APPLICATION_CONSTANTS.KY ? (TOBACCO_STATE_CODES.includes(stores.globalStore.agentState) && stores.globalStore.agentYear === DEFAULT_YEAR) : TOBACCO_STATE_CODES.includes(stores.globalStore.agentState))) && <div className='fwc-col-5 md-6 sm-12'>
                        <div className='form-group'>
                            <fieldset className='tobacco-fieldset'>
                                <legend className="fwc-label tobacco">
                                    {pageContent?.FORM_CONTENT.LABELS.TOBACCO}<span></span>
                                </legend>
                                <div className="fwc-radio">
                                    <input
                                        {...register('applicant.isTabaccoUser', {
                                            validate: (value) => validateTobacco(value)
                                        })}
                                        type="radio"
                                        value="YES"
                                        id="applicant-tobacco-yes"
                                    />
                                    <label htmlFor="applicant-tobacco-yes">Yes</label>
                                </div>
                                <div className="fwc-radio">
                                    <input
                                        {...register('applicant.isTabaccoUser', {
                                            validate: (value) => validateTobacco(value)
                                        })}
                                        type="radio"
                                        value="NO"
                                        id="applicant-tobacco-no"
                                    />
                                    <label htmlFor="applicant-tobacco-no">No</label>
                                </div>
                            </fieldset>
                            {errors && errors.applicant && errors.applicant.isTabaccoUser && errors.applicant.isTabaccoUser.type === "validate" && (
                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                </span>
                            )}
                        </div>
                    </div>}
                    {!((stores.globalStore.agentState && TOBACCO_STATE_CODES.includes(stores.globalStore.agentState))) && (
                        <Controller
                            control={control}
                            defaultValue={'NO'}
                            render={({ field }) => (
                                <input type="hidden" {...field} />
                            )}
                            name={`applicant.isTabaccoUser`}
                        />
                    )}
                    <div className='fwc-col-5 md-6 sm-12'>
                        <div className='form-group'>
                            <div className="fwc-input">
                                <label className="fwc-label" htmlFor="email">
                                    {pageContent?.FORM_CONTENT.LABELS.EMAIL}
                                </label>
                                <input
                                    id="email"
                                    placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.EMAIL}
                                    type="email"
                                    {...register('applicant.email', {
                                        required: {
                                            value: true,
                                            message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD
                                        },
                                        validate: (value) => {
                                            const trimmedValue = value?.trim();
                                            return trimmedValue === '' || validateEmailAddress(trimmedValue)
                                        }
                                    })}
                                />
                                {errors && errors.applicant?.email && errors.applicant?.email && errors.applicant?.email.type === "required" && (
                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                        {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                    </span>
                                )}
                                {errors && errors.applicant?.email && errors.applicant?.email && errors.applicant?.email.type === "validate" && (
                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                        {pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_EMAIL}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {spouseFields.map((element, index) => {
                    return (
                        <>
                            <div className={"fwc-row nomargin " + (getValues(`spouse.${index}.isPolicyApplicant`) === SET_APPLICATION_CONSTANTS.YES ? "whiteBg " : "")} key={element.id}>
                                <div className="fwc-col-5 md-6 sm-12">
                                    <div className='form-group'>
                                        <div className="fwc-input">
                                            <label
                                                className="fwc-label"
                                                htmlFor={`spouse.${index}.firstName`}
                                            >
                                                {pageContent?.FORM_CONTENT.LABELS.SPOUSE_DOMESTIC_PARTNER.FIRST_NAME}
                                            </label>

                                            <input
                                                readOnly={getValues(`spouse.${index}.isPolicyApplicant`) === SET_APPLICATION_CONSTANTS.YES}
                                                {...register(`spouse.${index}.firstName`, {
                                                    required: {
                                                        value: true,
                                                        message: pageContent?.REQUIRED_FIELD,
                                                    },
                                                    validate: validateFirstName,
                                                })}
                                                id={`spouse.${index}.firstName`}
                                                maxLength={30}
                                                placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.FIRST_NAME}
                                                type="text"
                                            />
                                            {errors.spouse?.[index]?.firstName?.type === "required" && (
                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                </span>
                                            )}
                                            {errors.spouse?.[index]?.firstName?.type === "validate" && (
                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_CHARACTER}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="fwc-col-5 md-6 sm-12">
                                    <div className='form-group'>
                                        <div className="fwc-input">
                                            <label
                                                className="fwc-label"
                                                htmlFor={`spouse.${index}..lastName`}
                                            >
                                                {pageContent?.FORM_CONTENT.LABELS.SPOUSE_DOMESTIC_PARTNER.LAST_NAME}
                                            </label>

                                            <input
                                                readOnly={getValues(`spouse.${index}.isPolicyApplicant`) === SET_APPLICATION_CONSTANTS.YES}
                                                {...register(`spouse.${index}.lastName`, {
                                                    required: {
                                                        value: true,
                                                        message: pageContent?.REQUIRED_FIELD,
                                                    },
                                                    validate: validateFirstName,
                                                })}
                                                id={`spouse.${index}.lastName`}
                                                maxLength={30}
                                                placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.LAST_NAME}
                                                type="text"
                                            />
                                            {errors.spouse?.[index]?.lastName?.type === "required" && (
                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                </span>
                                            )}
                                            {errors.spouse?.[index]?.lastName?.type === "validate" && (
                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_CHARACTER}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="fwc-col-2 md-6 sm-12 desktop">
                                    <i
                                        className="sae-icon sae-icon-trash trash-icon"
                                        id={`remove-spouse${index}`}
                                        onClick={() => { spouseRemove(index); }}
                                    ></i>
                                </div>
                            </div>
                            <div className={"fwc-row dateofbirth " + (getValues(`spouse.${index}.isPolicyApplicant`) === SET_APPLICATION_CONSTANTS.YES ? "whiteBg " : "")}>
                                <div className="fwc-col-5 md-6 sm-12">
                                    <div className="fwc-row">
                                        <div className="fwc-col-5 md-6 sm-12">
                                            <div className='form-group'>
                                                <div className="fwc-input dob">
                                                    <label
                                                        className="fwc-label"
                                                        htmlFor={`spouse.${index}.dateOfBirth`}
                                                    >
                                                        {pageContent?.FORM_CONTENT.LABELS.DATE_OF_BIRTH}{" "}
                                                    </label><span>
                                                        <Tooltip icon message={pageContent?.FORM_CONTENT.LABELS.HELP_DATE_OF_BIRTH} /></span>
                                                    <input type="date" min={dayjs('1900-01-01').format('YYYY-MM-DD')} max={dayjs().format('YYYY-MM-DD')} readOnly={getValues(`spouse.${index}.isPolicyApplicant`) === SET_APPLICATION_CONSTANTS.YES} {...register(`spouse.${index}.dateOfBirth`, {
                                                        validate: (value) => validateAge('spouse', value, `spouse.${index}`),
                                                    })} />
                                                    {errors.spouse?.[index]?.dateOfBirth?.type === "required" && (
                                                        <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                            {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                        </span>
                                                    )}
                                                    {errors.spouse?.[index]?.dateOfBirth?.type === "validate" && (
                                                        <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                            {errors.spouse[index]?.dateOfBirth?.message}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {((stores.globalStore.agentState && stores.globalStore.agentState === SET_APPLICATION_CONSTANTS.KY ? (TOBACCO_STATE_CODES.includes(stores.globalStore.agentState) && stores.globalStore.agentYear === DEFAULT_YEAR) : TOBACCO_STATE_CODES.includes(stores.globalStore.agentState))) && (

                                    <div className='fwc-col-5 md-6 sm-12'>
                                        <div className='form-group'>
                                            <fieldset className='tobacco-fieldset mt20'>
                                                <legend className="fwc-label tobacco">
                                                    {pageContent?.FORM_CONTENT.LABELS.TOBACCO}<span><Tooltip icon message={pageContent?.HELP_TOBACCO} /></span>
                                                </legend>
                                                <div className="fwc-radio">
                                                    <input
                                                        {...register(`spouse.${index}.isTabaccoUser`, {
                                                            validate: (value) => validateTobacco(value)
                                                        })}
                                                        type="radio"
                                                        value="YES"
                                                        id={`spouse-${index}-tobacco-yes`}
                                                    />
                                                    <label htmlFor={`spouse-${index}-tobacco-yes`}>Yes</label>
                                                </div>
                                                <div className="fwc-radio">
                                                    <input
                                                        {...register(`spouse.${index}.isTabaccoUser`, {
                                                            validate: (value) => validateTobacco(value)
                                                        })}
                                                        type="radio"
                                                        value="NO"
                                                        id={`spouse-${index}-tobacco-no`}
                                                    />
                                                    <label htmlFor={`spouse-${index}]-tobacco-no`}>No</label>
                                                </div>
                                            </fieldset>
                                            {errors.spouse?.[index]?.isTabaccoUser?.type === "validate" && (
                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                )}
                            </div>
                            <div className='fwc-row'>

                                <div className="fwc-col-4 md-6 sm-12 mobile">
                                    <Button
                                        btnType="secondary"
                                        id={`remove-spouse${index}`}
                                        onClick={() => { spouseRemove(index); }}
                                    >Remove Spouse</Button>
                                </div>
                            </div>
                            <div className='fwc-row divider'>
                                <div className='fwc-col-12 '>
                                    <hr />
                                </div>
                            </div>
                        </>
                    );
                })}

                {dependentFields.map((element, index) => {
                    return (
                        <>
                            <div className={"fwc-row nomargin " + (getValues(`dependents.${index}.isPolicyApplicant`) === SET_APPLICATION_CONSTANTS.YES ? "whiteBg " : "")} key={element.id}>
                                <div className="fwc-col-5 md-6 sm-12">
                                    <div className='form-group'>
                                        <div className="fwc-input">
                                            <label className="fwc-label" htmlFor={`dependents.${index}.firstName`}>
                                                {pageContent?.FORM_CONTENT.LABELS.DEPENDENTS.FIRST_NAME}
                                            </label>
                                            <input
                                                readOnly={getValues(`dependents.${index}.isPolicyApplicant`) === SET_APPLICATION_CONSTANTS.YES}
                                                {...register(`dependents.${index}.firstName`, {
                                                    required: true,
                                                    validate: validateFirstName,
                                                })}
                                                id={`dependents.${index}.firstName`}
                                                maxLength={30}
                                                placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.FIRST_NAME}
                                                type="text"
                                            />
                                            {errors.dependents?.[index]?.firstName?.type === "required" && (
                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                </span>
                                            )}
                                            {errors.dependents?.[index]?.firstName?.type === "validate" && (
                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_CHARACTER}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="fwc-col-5 md-6 sm-12">
                                    <div className='form-group'>
                                        <div className="fwc-input">
                                            <label className="fwc-label" htmlFor={`dependents.${index}.lastName`}>
                                                {pageContent?.FORM_CONTENT.LABELS.DEPENDENTS.LAST_NAME}
                                            </label>
                                            <input
                                                readOnly={getValues(`dependents.${index}.isPolicyApplicant`) === SET_APPLICATION_CONSTANTS.YES}
                                                {...register(`dependents.${index}.lastName`, {
                                                    required: true,
                                                    validate: validateFirstName,
                                                })}
                                                id={`dependents.${index}.lastName`}
                                                maxLength={30}
                                                placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.LAST_NAME}
                                                type="text"
                                            />
                                            {errors.dependents?.[index]?.lastName?.type === "required" && (
                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                </span>
                                            )}
                                            {errors.dependents?.[index]?.lastName?.type === "validate" && (
                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_CHARACTER}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="fwc-col-2 md-12 sm-12 desktop">
                                    <i
                                        className="sae-icon sae-icon-trash trash-icon"
                                        id={`remove-dependent${index}`}
                                        onClick={() => { dependentRemove(index) }}
                                    ></i>
                                </div>
                            </div>
                            <div className={"fwc-row " + (getValues(`dependents.${index}.isPolicyApplicant`) === SET_APPLICATION_CONSTANTS.YES ? "whiteBg " : "")}>
                                <div className="fwc-col-5 md-6 sm-12">
                                    <div className="fwc-row dateofbirth">
                                        <div className="fwc-col-6">
                                            <div className='form-group'>
                                                <div className="fwc-input dob">
                                                    <label
                                                        className="fwc-label"
                                                        htmlFor={`dependents.${index}.dateOfBirth`}
                                                    >
                                                        {pageContent?.FORM_CONTENT.LABELS.DATE_OF_BIRTH}{" "}
                                                    </label> <span>
                                                        <Tooltip icon message={pageContent?.FORM_CONTENT.LABELS.HELP_DATE_OF_BIRTH} /></span>
                                                    <input type="date" min={dayjs('1900-01-01').format('YYYY-MM-DD')} max={dayjs().format('YYYY-MM-DD')} readOnly={getValues(`dependents.${index}.isPolicyApplicant`) === SET_APPLICATION_CONSTANTS.YES} {...register(`dependents.${index}.dateOfBirth`, {
                                                        validate: (value) => validateAge('dependent', value, `dependents.${index}`, getValues(`dependents.${index}.applicantType`)),
                                                    })} />
                                                    {errors.dependents && errors.dependents?.[index]?.dateOfBirth?.type === "required" && (
                                                        <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                            {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                        </span>
                                                    )}
                                                    {errors.dependents && errors.dependents?.[index]?.dateOfBirth?.type === "validate" && errors.dependents?.[index]?.dateOfBirth?.message && (
                                                        <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                            {errors.dependents?.[index]?.dateOfBirth?.message}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {((stores.globalStore.agentState && stores.globalStore.agentState === SET_APPLICATION_CONSTANTS.KY ? (TOBACCO_STATE_CODES.includes(stores.globalStore.agentState) && stores.globalStore.agentYear === DEFAULT_YEAR) : TOBACCO_STATE_CODES.includes(stores.globalStore.agentState))) && (
                                    <div className='fwc-col-5 md-6 sm-12'>
                                        <div className='form-group'>
                                            <fieldset className='tobacco-fieldset mt20'>
                                                <legend className="fwc-label tobacco">
                                                    {pageContent?.FORM_CONTENT.LABELS.TOBACCO}<span><Tooltip icon message={pageContent?.HELP_TOBACCO} /></span>
                                                </legend>
                                                <div className="fwc-radio">
                                                    <input
                                                        {...register(`dependents.${index}.isTabaccoUser`, {
                                                            validate: (value) => validateTobacco(value)
                                                        })}
                                                        type="radio"
                                                        value="YES"
                                                        id={`dependents-${index}-tobacco-yes`}
                                                    />
                                                    <label htmlFor={`dependents-${index}-tobacco-yes`}>Yes</label>
                                                </div>
                                                <div className="fwc-radio">
                                                    <input
                                                        {...register(`dependents.${index}.isTabaccoUser`, {
                                                            validate: (value) => validateTobacco(value)
                                                        })}
                                                        type="radio"
                                                        value="NO"
                                                        id={`dependents-${index}-tobacco-no`}
                                                    />
                                                    <label htmlFor={`dependents-${index}-tobacco-no`}>No</label>
                                                </div>
                                            </fieldset>
                                            {errors.dependents && errors.dependents?.[index]?.isTabaccoUser?.type === "validate" && (
                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {(stores.globalStore.agentState === CALIFORNIA_CODE) && (stores.globalStore.agentCoverageType && MEDICAL_PLAN_TYPES.includes(stores.globalStore.agentCoverageType)) && (
                                    <div className="fwc-col-3  md-6 sm-12">
                                        <div className='form-group'>
                                            <div className="fwc-select-list">
                                                <label className="fwc-label" htmlFor={`dependents.${index}.relationship`}>
                                                    {pageContent?.FORM_CONTENT.LABELS.DEPENDENTS.RELATIONSHIP_APPLICANT}
                                                </label>
                                                <select
                                                    className="text-trim"
                                                    id={`dependents.${index}.relationship`}
                                                    {...register(`dependents.${index}.applicantType`, {
                                                        required: true,
                                                        validate: (value) => value === '' || validateRealationShipType(`dependents.${index}`, value)
                                                    })}
                                                >
                                                    <option value="">{pageContent?.FORM_CONTENT.LABELS.DEPENDENTS.SELECT}</option>
                                                    <option value={RELATIONSHIP.CHILD}>{pageContent?.FORM_CONTENT.LABELS.DEPENDENTS.CHILD}</option>
                                                    <option value={RELATIONSHIP.PARENT}>{pageContent?.FORM_CONTENT.LABELS.DEPENDENTS.PARENT}</option>
                                                    <option value={RELATIONSHIP.STEPPARENT}>{pageContent?.FORM_CONTENT.LABELS.DEPENDENTS.STEPPARENT}</option>
                                                    <option value={RELATIONSHIP.PARENTINLAW}>{pageContent?.FORM_CONTENT.LABELS.DEPENDENTS.PARENT_IN_LAW}</option>
                                                    <option value={RELATIONSHIP.OTHER}>{pageContent?.FORM_CONTENT.LABELS.DEPENDENTS.OTHER}</option>
                                                </select>
                                                {errors.dependents && errors.dependents?.[index]?.applicantType?.type === "required" && (
                                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                        {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                    </span>
                                                )}
                                                {errors.dependents && errors.dependents?.[index]?.applicantType?.type === "validate" && (
                                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                        {pageContent?.FORM_CONTENT.ERROR_MESSAGES.PARENT_IN_LAW_ERROR}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                            <div className='fwc-row'>
                                <div className="fwc-col-4 md-6 sm-12 mobile">
                                    <Button
                                        btnType="secondary"
                                        className=""
                                        id={`remove-dependent${index}`}
                                        onClick={() => { dependentRemove(index) }}
                                    >Remove Dependent</Button>
                                </div>
                            </div>
                            <div className='fwc-row divider'>
                                <div className='fwc-col-12 '>
                                    <hr />
                                </div>
                            </div>
                        </>
                    );
                })}
                {<div className='fwc-row'>
                    <div className='fwc-col-12 addApplicantandOthers'>
                        <Button
                            btnType="secondary"
                            name="add-dependent"
                            id="add-dependent"
                            disabled={(spouseFields.length === 1 ? dependentFields.length === 18 : dependentFields.length === 19) || dependentsDisable || !showApplicationBlock}
                            onClick={(e) => { addDepSpouseFormFields(e, 'dependent'); }}
                        >
                            + {pageContent?.FORM_CONTENT.LABELS.ADD_DEPENDENT}
                        </Button>
                        <Button btnType="secondary" name="add-spouse" id="add-spouse" disabled={(spouseFields.length === 1 || dependentFields.length === 19) || dependentsDisable || !showApplicationBlock}
                            onClick={(e) => addDepSpouseFormFields(e, 'spouse')}>
                            + {pageContent?.FORM_CONTENT.LABELS.ADD_SPOUSE}
                        </Button>
                    </div>
                </div>}
                <div className='fwc-row'>
                    <div className='fwc-col-12 ca-related-content'>
                        {showParentTextBlock && (
                            <div id="ca-rel-2" dangerouslySetInnerHTML={{ __html: pageContent?.CA_PARENT_IN_LAW }}>
                            </div>
                        )}
                    </div>
                </div>
                <div className='fwc-row'>
                    {dependentsDisable && <p className="custom-alert">
                        <span className="sae-icon sae-icon-error"></span>
                        {pageContent?.ALERTS.DEPENDENTS}
                    </p>}
                </div>
            </div>
        </>
    );
};

export default AgentHCIDApplicationInfo;