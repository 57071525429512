import { StateCreator } from 'zustand';

const createDrugStoreSlice: StateCreator<DrugStore> = (set, get) => ({
  drugsList: [],
  updatedDrugsList: (druglist: DrugDetails[]) => set({ drugsList: druglist }),
  matchMedicationsToPlans: false,
  updateMatchMedicationsToPlansStatus: (status: boolean) => set({ matchMedicationsToPlans: status }),
  coveredMedicationsList: [],
  updatedCoveredMedicationsList: (coveredList: MedicationsPlanSummary[]) => set({ coveredMedicationsList: coveredList }),
  resetDrugStoreStates: () => set({ drugsList: [], matchMedicationsToPlans: false, coveredMedicationsList: [] })
});

export { createDrugStoreSlice };
