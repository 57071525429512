import { StateCreator } from 'zustand';
import { deepLinkInfoInit } from './initialValues/deepLinkInfoInit';

export const createDeepLinkInfoSlice: StateCreator<DeepLinkInfoStore> = (set, get) => ({
  deepLinkInfo: deepLinkInfoInit,
  updateDeepLinkBrand: (brand: string) => {
    set((state) => {
      return {
        ...state,
        deepLinkInfo: {
          ...state.deepLinkInfo,
          brand: brand
        }
      };
    });
  },
  updateDeepLinkCounty: (county: string) => {
    set((state) => {
      return {
        ...state,
        deepLinkInfo: {
          ...state.deepLinkInfo,
          county: county
        }
      };
    });
  },
  updateDeepLinkEs: (es: string) => {
    set((state) => {
      return {
        ...state,
        deepLinkInfo: {
          ...state.deepLinkInfo,
          es: es
        }
      };
    });
  },

  updateDeepLinkLocale: (locale: string) => {
    set((state) => {
      return {
        ...state,
        deepLinkInfo: {
          ...state.deepLinkInfo,
          locale: locale
        }
      };
    });
  },

  updateDeepLinkRefSource: (refSource: string) => {
    set((state) => {
      return {
        ...state,
        deepLinkInfo: {
          ...state.deepLinkInfo,
          refSource: refSource
        }
      };
    });
  },

  updateDeepLinkSite: (site: string) => {
    set((state) => {
      return {
        ...state,
        deepLinkInfo: {
          ...state.deepLinkInfo,
          site: site
        }
      };
    });
  },

  updateDeepLinkState: (state: string) => {
    set((prevState) => ({
      ...prevState,
      deepLinkInfo: {
        ...prevState.deepLinkInfo,
        state: state
      }
    }));
  },
  updateDeepLinkYear: (year: string) => {
    set((state) => {
      return {
        ...state,
        deepLinkInfo: {
          ...state.deepLinkInfo,
          year: year
        }
      };
    });
  },

  updateDeepLinkZipcode: (zipcode: string) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        zipcode: zipcode
      }
    }));
  },

  updateDeepLinkQuoteId: (quoteId: string) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        quoteId: quoteId
      }
    }));
  },

  updateDeepLinkProposalId: (proposalId: string) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        proposalID: proposalId
      }
    }));
  },
  updateDeepLinkAcn: (acn: string) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        acn: acn
      }
    }));
  },
  updateDeepLinkMedicalContractCode: (medicalContractCode: string) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        medicalContractCode: medicalContractCode
      }
    }));
  },
  updateDeepLinkDentalContractCode: (dentalContractCode: string) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        dentalContractCode: dentalContractCode
      }
    }));
  },
  updateDeepLinkVisionContractCode: (visionContractCode: string) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        visionContractCode: visionContractCode
      }
    }));
  },
  updateDeepLinkQuoteSrc: (quoteSrc: string) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        quoteSrc: quoteSrc
      }
    }));
  },
  updateDeepLinkLanguage: (language: string) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        language: language
      }
    }));
  },
  updateDeepLinkDemographicsId: (demographicsId: string) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        demographicsId: demographicsId
      }
    }));
  },
  updateDeepLinkStandaloneLoginUseCase: (standaloneLoginUseCase: string) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        standaloneLoginUseCase: standaloneLoginUseCase
      }
    }));
  },
  updateDeepLinkHouseholdIncome: (householdIncome: string) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        householdIncome: householdIncome
      }
    }));
  },
  updateDeepLinkAgentData: (agentData: {
    agentId: string;
    agentType: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    state: string;
    rI: string;
    address: { addressLine1: string; city: string; state: string; zip: string };
    city: string;
    zip: string;
    paidTIN: string;
    channel: string;
    agency: string;
    brand: string;
    npn: string;
    language: string;
  }) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        agentConnectData: agentData
      }
    }));
  },
  updateDeepLinkEvendorId: (evendorid: string) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        evendorid: evendorid
      }
    }));
  },
  updateDeepLinkEvendorInfo: (evendorInfo: EvenderInfo) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        evendorInfo: evendorInfo
      }
    }));
  },
  updateDeepLinkMacWithCredLoginUseCase: (macLoginWithCredUseCase: string) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        macLoginWithCredUseCase: macLoginWithCredUseCase
      }
    }));
  },
  updateDeepLinkAgentSSOInfo: (ssoID, channel, action) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        agentSSOInfo: {
          ssoID: ssoID,
          channel: channel,
          action: action
        }
      }
    }));
  },
  updateDeepLinkAgentBrokerLoginUseCase: (agentBrokerLoginUseCaseLogin: string) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        agentBrokerLoginUseCase: agentBrokerLoginUseCaseLogin
      }
    }));
  },
  updateDeepLinkSource: (source: string) => {
    set((state) => ({
      ...state,
      deepLinkInfo: {
        ...state.deepLinkInfo,
        source: source
      }
    }));
  },
  updateDeepLinkCall: (callNo: string) => {
    set((state) => {
      return {
        ...state,
        deepLinkInfo: {
          ...state.deepLinkInfo,
          call: callNo
        }
      };
    });
  },
  updateDeepLinkRefID: (refID: string) => {
    set((state) => {
      return {
        ...state,
        deepLinkInfo: {
          ...state.deepLinkInfo,
          refID: refID
        }
      };
    });
  },
  updateIsAgentConnectFlow: (isAgentConnectFlow: boolean) => {
    set((state) => {
      return {
        ...state,
        deepLinkInfo: {
          ...state.deepLinkInfo,
          isAgentConnectFlow: isAgentConnectFlow
        }
      };
    });
  },
  resetDeepLinkInfoStore: () => {
    set((state) => ({
      deepLinkInfo: deepLinkInfoInit
    }));
  }
});
