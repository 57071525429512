import { StateCreator } from 'zustand';

export const createStateConfigSlice: StateCreator<StateConfigStore> = (set) => ({
  coverageRule: [],
  enrollmentYear: '',
  globalEnrollmentPeriods: [],
  showSpanishContent: false,
  updateStateCofig: (coverageRule: coverageRule[]) => set({ coverageRule: coverageRule }),
  updateEnrollmentYear: (enrollmentYear: string) => set({ enrollmentYear: enrollmentYear }),
  updateGlobalEnrollmentPeriods: (coverageRule: coverageRule[]) => set({ globalEnrollmentPeriods: coverageRule }),
  updateShowSpanishContent: (showSpanishContent: boolean) => set({ showSpanishContent: showSpanishContent })
});
