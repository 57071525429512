import dayjs from 'dayjs';
import { getAgentDetailsByState, getAtkProposalAgentPayload } from '../../../components/common/email-print/emailPrintUtils';
import { generateSubsidyPayload, getApplicantsForPlans, getProviderType } from '../../../components/common/public-nav-bar/SaveAndResumeLater.utils';
import {
  AGENT_ROLES,
  BOOLEAN_VALUES,
  DATE_FORMATS,
  ENTITY_TYPES,
  LANGUAGE_CODES,
  PLAN_TYPES,
  PLAN_TYPES_NAMES,
  QUOTE_STATUS,
  QUOTE_TYPES,
  USER_DOMAINS,
  USER_ROLES,
  YES_NO_VALUES
} from '../../../shared/globalConstants';
import { formatDateToYYYYMMDD } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { MEDICAL_PLANS_TABS, PLAN_TIERS_ALL } from '../../public/medical-plans/constants';
import {
  getApplicableMetalTiers,
  getBenefitTierFilteredPlans,
  getCSR,
  getFormattedApplicantDetails,
  getFormattedApplicantDetailsForAtksaveQuote,
  getPlansForTab,
  getPrimaryApplicant,
  getSubsidyAmount,
  getSwitchFilteredPlans,
  isFilterApplied,
  setDomesticPartnerAsSpouse
} from '../../public/medical-plans/medicalPlansUtils';
import { agentTypeAOR } from '../atk-plan-selection-summary/AtkPlanSelectionSummaryUtils';
import { getPlansByProductType } from './Filters/productSelectionFiltersUtils';

const getProductTypeFilteredPlans = (filters: CheckBoxFilterOption[], plans: Contracts): Contracts => {
  const isProductTypeFilterApplied = isFilterApplied(filters);
  let filteredPlans: Contracts = [];

  if (isProductTypeFilterApplied) {
    filters.forEach((filterOption) => {
      const { isChecked, value } = filterOption;

      if (isChecked) {
        const plansForProductType = getPlansByProductType(plans, value);

        filteredPlans = filteredPlans.concat(plansForProductType);
      }
    });
  } else {
    filteredPlans = plans;
  }

  return filteredPlans;
};

const getFilteredMedicalProducts = (
  filters: ProductFilters,
  plans: Contracts,
  savedProviders: Provider[],
  matchProvidersToPlans: boolean,
  matchMedicationsToPlans: boolean,
  drugList: DrugDetails[],
  coveredMedicationsList: MedicationsPlanSummary[]
) => {
  const plansForTab = getPlansForTab(filters.selectedProductTab, plans);
  const productTypeFilteredPlans = getProductTypeFilteredPlans(filters.productTypeFilters, plansForTab);
  const switchFilteredPlans = getSwitchFilteredPlans(
    filters.productsSwitchFilters,
    productTypeFilteredPlans,
    savedProviders,
    matchProvidersToPlans,
    matchMedicationsToPlans,
    drugList,
    coveredMedicationsList
  );
  const filteredPlans = getBenefitTierFilteredPlans(filters.productsBenefitsTiers, switchFilteredPlans);

  return filteredPlans;
};

const getProductIndex = (primaryProductType: string, selectedProduct: SelectedProduct, currentlyReviewedProducts: SelectedProducts): number => {
  let productIndex = -1;

  switch (primaryProductType) {
    case PLAN_TYPES_NAMES.MEDICAL_PRODUCT:
      productIndex = currentlyReviewedProducts.findIndex((product) => product?.medicalProduct?.contractCode === selectedProduct?.medicalProduct?.contractCode);
      break;
    case PLAN_TYPES_NAMES.DENTAL_PRODUCT:
      productIndex = currentlyReviewedProducts.findIndex((product) => product?.dentalProduct?.contractCode === selectedProduct?.dentalProduct?.contractCode);
      break;
    case PLAN_TYPES_NAMES.VISION_PRODUCT:
      productIndex = currentlyReviewedProducts.findIndex((product) => product?.visionProduct?.contractCode === selectedProduct?.visionProduct?.contractCode);
      break;
    default:
      productIndex = -1;
      break;
  }

  return productIndex;
};

const getUpdatedProductsForReview = (
  primaryProductType: string,
  selectedProduct: SelectedProduct,
  currentlyReviewedProducts: SelectedProducts
): SelectedProducts => {
  const productIndex = getProductIndex(primaryProductType, selectedProduct, currentlyReviewedProducts);

  if (productIndex !== -1) {
    // Product is already in the list, so remove it
    return currentlyReviewedProducts.filter((_, index) => index !== productIndex);
  } else {
    // Product is not in the list, so add it, but ensure the list doesn't exceed 3 items
    const updatedProducts = [...currentlyReviewedProducts, selectedProduct];
    return updatedProducts.length > 3 ? updatedProducts.slice(1) : updatedProducts;
  }
};

const getMedicalProducutsPayload = (globalStoreObj: GlobalStore): PlanSummaryPayload => {
  return {
    brand: globalStoreObj.agentBrand,
    planTypes: [PLAN_TYPES_NAMES.MEDICAL],
    metalTierTypes: getApplicableMetalTiers(getFormattedApplicantDetails(globalStoreObj.agentApplicantFormDetails)),
    marketSegment: USER_DOMAINS.MARKET_SEGMENT_FOR_PLAN_SUMMARY,
    zipCode: globalStoreObj.agentZipcode,
    county: globalStoreObj.agentCounty,
    state: globalStoreObj.agentState,
    coverageEffectiveDate: formatDateToYYYYMMDD(globalStoreObj.agentCoverageDate),
    planYear: globalStoreObj.agentYear,
    applicants: setDomesticPartnerAsSpouse(getFormattedApplicantDetails(globalStoreObj.agentApplicantFormDetails, false)),
    subsidy: getSubsidyAmountForMedicaidApplicants(globalStoreObj),
    language: LANGUAGE_CODES.EN,
    countyCode: globalStoreObj.agentCountyCode
  };
};

const getSubsidyAmountForMedicaidApplicants = (globalStoreObj: GlobalStore) => {
  if (globalStoreObj.agentApplicantFormDetails.some((applicant) => applicant?.isMedicAidEligible)) {
    return { subsidyAmt: null, costShareReduction: null };
  }
  return { subsidyAmt: getSubsidyAmount(globalStoreObj.subsidyresponse), costShareReduction: getCSR(globalStoreObj.subsidyresponse) };
};

const getDentalProducutsPayload = (globalStoreObj: GlobalStore): PlanSummaryPayload => {
  return {
    brand: globalStoreObj.agentBrand,
    planTypes: [PLAN_TYPES_NAMES.DENTAL],
    metalTierTypes: PLAN_TIERS_ALL,
    marketSegment: USER_DOMAINS.MARKET_SEGMENT_FOR_PLAN_SUMMARY,
    zipCode: globalStoreObj.agentZipcode,
    county: globalStoreObj.agentCounty,
    state: globalStoreObj.agentState,
    coverageEffectiveDate: formatDateToYYYYMMDD(globalStoreObj.agentCoverageDate),
    planYear: globalStoreObj.agentYear,
    applicants: setDomesticPartnerAsSpouse(
      getFormattedApplicantDetails(globalStoreObj.selectedProduct.dentalApplicants ?? globalStoreObj.agentApplicantFormDetails, false)
    ),
    subsidy: {
      subsidyAmt: null,
      costShareReduction: null
    },
    language: LANGUAGE_CODES.EN,
    countyCode: globalStoreObj.agentCountyCode
  };
};

const getVisionProductsPayload = (globalStoreObj: GlobalStore): PlanSummaryPayload => {
  return {
    brand: globalStoreObj.agentBrand,
    planTypes: [PLAN_TYPES_NAMES.VISION],
    metalTierTypes: PLAN_TIERS_ALL,
    marketSegment: USER_DOMAINS.MARKET_SEGMENT_FOR_PLAN_SUMMARY,
    zipCode: globalStoreObj.agentZipcode,
    county: globalStoreObj.agentCounty,
    state: globalStoreObj.agentState,
    coverageEffectiveDate: formatDateToYYYYMMDD(globalStoreObj.agentCoverageDate), //Update the format
    planYear: globalStoreObj.agentYear,
    applicants: setDomesticPartnerAsSpouse(
      getFormattedApplicantDetails(globalStoreObj.selectedProduct.visionApplicants ?? globalStoreObj.agentApplicantFormDetails, false)
    ),
    subsidy: {
      subsidyAmt: null,
      costShareReduction: null
    },
    language: LANGUAGE_CODES.EN,
    countyCode: globalStoreObj.agentCountyCode
  };
};

async function getATKQuoteFlowDetails(plans: ContractAndApplicant[], applicantDetails: Applicant[]) {
  const quoteLineItem: QuoteLineItem[] = plans.reduce((acc: QuoteLineItem[], plan) => {
    if (plan?.contract?.contractCode) {
      const contractCode: any = plan?.contract.contractCode;
      const planType: any = plan?.contract.plan[0].planType;
      const quoteItem: QuoteLineItem = {
        contract: {
          contractCode: contractCode,
          planType: planType,
          portFolio: plan?.contract?.onExchange ? BOOLEAN_VALUES.TRUE : BOOLEAN_VALUES.FALSE
        },
        applicant: plan?.applicant ? getApplicantsForPlans(plan?.applicant, applicantDetails) : applicantDetails,
        status: QUOTE_STATUS.ACTIVE
      };
      acc.push(quoteItem);
    }
    return acc;
  }, []);
  return quoteLineItem;
}

const getTotalAmount = (plans: Contracts) => {
  const amount = plans.map((plan) => (plan?.rateData?.rate?.totSubsidizedPrem ? plan?.rateData?.rate?.totSubsidizedPrem : 0.0));
  const sum = amount.reduce((total, currentValue) => total + currentValue, 0);
  return sum.toFixed(2);
};

const generateAgentQuoteInfoPayload = async (selectedProduct: SelectedProduct, quoteId?: string, demographicInfo?: DemographicInfo): Promise<Quotes> => {
  //for quoteLineItem have to call getQuoteDetails function from saveandResumeLater utils file
  let plans: Contract[] = [];
  let PlansAndApplicants: ContractAndApplicant[] = [];
  const product = selectedProduct;

  if (product?.medicalProduct) {
    plans.push(product.medicalProduct);
    PlansAndApplicants.push({ contract: product.medicalProduct, applicant: product.medicalApplicants });
  }
  if (product?.dentalProduct) {
    plans.push(product.dentalProduct);
    PlansAndApplicants.push({ contract: product.dentalProduct, applicant: product.dentalApplicants });
  }
  if (product?.visionProduct) {
    plans.push(product.visionProduct);
    PlansAndApplicants.push({ contract: product.visionProduct, applicant: product.visionApplicants });
  }

  const quoteLineItems: QuoteLineItem[] = await getATKQuoteFlowDetails(
    PlansAndApplicants,
    getFormattedApplicantDetails(useGlobalStore.getState().agentApplicantFormDetails)
  );
  const totalPremium = getTotalAmount(plans);
  let quote = {
    demographicId: demographicInfo ? parseInt(demographicInfo.demographicId ?? '0') : 0,
    quoteId: quoteId ? quoteId : '0',
    packageSkuId: '',
    quoteLineItem: quoteLineItems,
    acn: '',
    type: QUOTE_TYPES.QUOTE,
    status: QUOTE_STATUS.ACTIVE,
    totalPremium: totalPremium,
    currentPlan: '',
    shopperId: demographicInfo ? demographicInfo.shopperId : '0',
    renewalType: null
  };

  return quote;
};

const generateAgentDemographicInfoPayload = (selectedProduct: SelectedProduct, currentDemographic?: DemographicInfo): DemographicInfo => {
  const globalStore = useGlobalStore.getState();
  const { agentSSOInfo } = globalStore;
  const subsidyId = useUserStore.getState().demographicInfo?.subsidy?.subsidyId || '';
  let demographicInfo: DemographicInfo = {
    applicant: currentDemographic ? currentDemographic.applicant : getFormattedApplicantDetailsForAtksaveQuote(globalStore.agentApplicantFormDetails),
    brand: globalStore.agentBrand,
    zipCode: globalStore.agentZipcode,
    county: globalStore.agentCounty,
    countyCode: globalStore.agentCountyCode,
    city: '',
    state: globalStore.agentState,
    coverageEffectiveDate: dayjs(globalStore.agentCoverageDate).format(DATE_FORMATS.YYYYMMDD),
    coverageType: globalStore.agentCoverageType,
    marketSegment: USER_DOMAINS.MARKET_SEGMENT,
    demographicId: currentDemographic ? currentDemographic.demographicId : '',
    hmcFilters: '',
    eid: '',
    coverageTypeFlag: globalStore.agentCoverageType,
    agentId: globalStore.agentInfo?.agentId,
    shopperId: currentDemographic ? currentDemographic.shopperId : '',
    channel: '',
    customerRefNumber: agentSSOInfo?.applicantInfo?.customerRefNumber ?? '',
    vendorLeadId: agentSSOInfo?.applicantInfo?.vendorLeadId ?? '',
    cid: agentSSOInfo?.applicantInfo?.marketingRefId ?? '',
    status: 'ACTIVE',
    proposalIdRef: '',
    hpaId: '',
    language: LANGUAGE_CODES.ENGLISH,
    emailConsent: '',
    source: '',
    subsidy:
      currentDemographic && currentDemographic?.subsidy
        ? currentDemographic.subsidy
        : generateSubsidyPayload(
          globalStore.subsidyresponse,
          globalStore.householdMemberValues,
          globalStore.applicantFormDetails.length,
          '0',
          subsidyId.toString()
        ),
    savedUserEntity: generateSavedUserEntityPayload(selectedProduct)
  };
  return demographicInfo;
};

const generateAgentUserInfoPayload = (): UserInfo => {
  const { agentApplicantFormDetails, agentSSOInfo, agentHCID } = useGlobalStore.getState();
  const [primaryApplicant] = getPrimaryApplicant(agentApplicantFormDetails);

  let user: UserInfo = {
    type: USER_ROLES.SHOPPER, //To be made dynamic for hpa flow and external agent flow based on member info object
    email: primaryApplicant.email,
    firstName: primaryApplicant?.firstName,
    lastName: primaryApplicant?.lastName,
    status: 'ACTIVE',
    dob: formatDateToYYYYMMDD(primaryApplicant.dateOfBirth), //Primary applicant's dob
    hcid: agentHCID ?? '' //To be made dynamic for hpa flow and external agent flow based on member info object
  };
  return user;
};

const generateHPAProposalPayload = (agentSSOInfo: AgentSSOInfo, state: string): HPAProposal => {
  const { agentUserID, writingTIN, writingTINAgentType, NPN, agentFName, agentLName, agencyName, webAcctId } = agentSSOInfo.agentInfo.agent[0];
  const agentDetailsByState = getAgentDetailsByState(agentSSOInfo, state);
  let hpaInfo: HPAProposal = {
    agentType: agentSSOInfo.agentType ?? '',
    writingTIN: writingTIN,
    writingTINAgentType: writingTINAgentType,
    npn: NPN,
    agentFirstName: agentFName,
    agentLastName: agentLName,
    email: agentDetailsByState?.email ?? '',
    phone: agentDetailsByState?.phone ?? '',
    phoneExt: agentDetailsByState?.phoneExt ?? '',
    fax: agentDetailsByState?.fax ?? '',
    licenseNumber: agentDetailsByState?.licenseNumber ?? '',
    address: {
      addressLine1: agentDetailsByState?.agentAddressInfo?.agentAddress?.address1 ?? '',
      city: agentDetailsByState?.agentAddressInfo?.agentAddress?.city ?? '',
      state: agentDetailsByState?.agentAddressInfo?.agentAddress?.state ?? '',
      zip: agentDetailsByState?.agentAddressInfo?.agentAddress?.zip ?? ''
    },
    agentWebGuid: agentSSOInfo.agentType === AGENT_ROLES.DELEGATE || agentSSOInfo.agentType === AGENT_ROLES.TELEASSISTANT ? agentUserID : '', //for DELEGATE or TELEASSISTANT send agentUserID else send ''
    webAcctId: webAcctId
    //webAcctId: '143697'
  };

  return hpaInfo;
};

const getAgentOfRecordPayload = (memberInfo: MemberInfo): AgentOfRecord => {
  return {
    agency: memberInfo.agentOfRecord?.agency ?? '',
    agentFirstName: memberInfo.agentOfRecord?.firstName ?? '',
    agentLastName: memberInfo.agentOfRecord?.lastName ?? '',
    email: memberInfo.agentOfRecord?.email ?? '',
    phone: memberInfo.agentOfRecord?.phone ?? '',
    lobState: memberInfo.agentOfRecord?.lobState ?? '',
    licenseNO: memberInfo.agentOfRecord?.licenseNO ?? '',
    npn: memberInfo.agentOfRecord?.npn ?? '',
    agentCode: memberInfo.agentOfRecord?.agentCode ?? '',
    agentType: agentTypeAOR(memberInfo.agentOfRecord?.parentTin ?? ''),
    address: {
      addressLine1: memberInfo.agentOfRecord?.address.addressLine1 ?? '',
      addressLine2: memberInfo.agentOfRecord?.address.addressLine2 ?? '',
      city: memberInfo.agentOfRecord?.address.city ?? '',
      state: memberInfo.agentOfRecord?.address.state ?? '',
      postalCode: memberInfo.agentOfRecord?.address.postalCode ?? ''
    },
    paidTIN: memberInfo.agentOfRecord?.paidTIN ?? '',
    parentTin: memberInfo.agentOfRecord?.parentTin ?? '',
    writingTIN: memberInfo.agentOfRecord?.agentId ?? '',
    certificateId: memberInfo.agentOfRecord?.certificateId ?? '',
    agentTIN: memberInfo.agentOfRecord?.agentId ?? ''
  };
};

const getPayloadForAgentQuote = async (
  selectedProduct: SelectedProduct,
  quoteId?: string,
  demographicInfo?: DemographicInfo
): Promise<AgentQuoteRequestPayload> => {
  const { agentState, agentSSOInfo, memberInfo } = useGlobalStore.getState();
  let payload: AgentQuoteRequestPayload = {
    quote: await generateAgentQuoteInfoPayload(selectedProduct, quoteId, demographicInfo),
    demographicInfo: generateAgentDemographicInfoPayload(selectedProduct, demographicInfo),
    user: generateAgentUserInfoPayload(),
    agent: getAtkProposalAgentPayload(agentSSOInfo, agentState) /// anil's function
  };

  if (agentSSOInfo?.agentType === AGENT_ROLES.HPA) {
    // agent SSO details in hpa object
    // AOR is available , agent object, hpa object..
    // not agent object is null, hpa object is same
    payload = {
      ...payload,
      agent: memberInfo.agentOfRecord?.agentType ? getAgentOfRecordPayload(memberInfo) : null,
      hpa: generateHPAProposalPayload(agentSSOInfo, agentState)
    };
  }
  return payload;
};

const getATKsaveProposalRequestPayload = async (selectedProducts: SelectedProducts): Promise<ATKSaveProposalRequestPayload> => {
  const { aTKsaveProposalResponse, agentState, agentSSOInfo, memberInfo } = useGlobalStore.getState();
  const selectedProduct = selectedProducts[0];
  let payload: ATKSaveProposalRequestPayload = {
    quotes: {
      quote: await Promise.all(selectedProducts.map((product) => generateAgentQuoteInfoPayload(product)))
    },
    demographicInfo: generateAgentDemographicInfoPayload(selectedProduct),
    user: generateAgentUserInfoPayload(),
    agent: getAtkProposalAgentPayload(agentSSOInfo, agentState),
    proposalId: aTKsaveProposalResponse?.proposalId ?? '',
    status: 'CREATED',
    pinId: '',
    email: ''
  };

  if (agentSSOInfo?.agentType === AGENT_ROLES.HPA) {
    payload = {
      ...payload,
      agent: memberInfo.agentOfRecord?.agentType ? getAgentOfRecordPayload(memberInfo) : null,
      hpa: generateHPAProposalPayload(agentSSOInfo, agentState)
    };
  }
  return payload;
};

const getUpdatedSelectedProductsByCoverageType = (selectedProduct: SelectedProduct, coverageType: string, applicants: Applicant[]): SelectedProduct => {
  switch (coverageType) {
    case PLAN_TYPES_NAMES.MEDICAL_PRODUCT:
      return {
        ...selectedProduct,
        medicalApplicants: getFormattedApplicantDetails(applicants)
      };
    case PLAN_TYPES_NAMES.DENTAL_PRODUCT:
      return {
        ...selectedProduct,
        dentalApplicants: getFormattedApplicantDetails(applicants)
      };
    case PLAN_TYPES_NAMES.VISION_PRODUCT:
      return {
        ...selectedProduct,
        visionApplicants: getFormattedApplicantDetails(applicants)
      };
    default:
      return {
        ...selectedProduct,
        medicalApplicants: getFormattedApplicantDetails(applicants)
      };
  }
};

const getPrimaryProductTypeByCoverageType = (coverageType: string): string => {
  switch (coverageType) {
    case PLAN_TYPES.MEDICAL:
    case PLAN_TYPES.MDV:
      return PLAN_TYPES_NAMES.MEDICAL_PRODUCT;
    case PLAN_TYPES.DENTAL:
      return PLAN_TYPES_NAMES.DENTAL_PRODUCT;
    case PLAN_TYPES.VISION:
      return PLAN_TYPES_NAMES.VISION_PRODUCT;
    default:
      return PLAN_TYPES_NAMES.MEDICAL_PRODUCT;
  }
};

const getPlanTypeLabel = (primaryProductType: string, labels: any) => {
  switch (primaryProductType) {
    case PLAN_TYPES_NAMES.MEDICAL_PRODUCT:
      return labels?.MEDICAL_PLANS;
    case PLAN_TYPES_NAMES.DENTAL_PRODUCT:
      return labels?.DENTAL_PLANS;
    case PLAN_TYPES_NAMES.VISION_PRODUCT:
      return labels?.VISION_PLANS;
    default:
      return labels?.MEDICAL_PLANS;
  }
};

const generateSavedUserEntityPayload = (selectedProduct: SelectedProduct) => {
  const globalStore = useGlobalStore.getState();
  let savedUserEntity: SavedUserEntity[] = [];
  const allMatchedProviders: Provider[] = [
    ...(selectedProduct.matchedDoctors || []),
    ...(selectedProduct.matchedDentists || []),
    ...(selectedProduct.matchedEyeDoctors || [])
  ];
  // save matched providers
  allMatchedProviders.map((providers) => {
    savedUserEntity.push({
      entityId: providers.providerIdentifier,
      entityType: ENTITY_TYPES.PROVIDER,
      entitySubType: getProviderType(providers.providerTypeCodeList[0]), // get the first provider type
      compositeEntityId: providers.address.addressId,
      entityLabel: globalStore.zipcode,
      isGenericOptAvail: '',
      isMatched: '',
      tierName: ''
    });
  });
  // save matched drugs list
  globalStore.drugsList.map((drug: DrugDetails) => {
    savedUserEntity.push({
      entityId: drug.formularyId.toString(),
      entityType: ENTITY_TYPES.DRUG,
      entitySubType: drug.drugType,
      compositeEntityId: drug.genericProductId,
      entityLabel: drug.drugLabel,
      isGenericOptAvail: drug.isGenericOptionsAvailable === BOOLEAN_VALUES.TRUE.toUpperCase() ? YES_NO_VALUES.YES : YES_NO_VALUES.NO,
      isMatched: '',
      tierName: ''
    });
  });
  // save HMC filters
  globalStore.hmcFilter.questionaires.map((questionaire) => {
    savedUserEntity.push({
      entityId: questionaire.code,
      entityType: ENTITY_TYPES.HMC,
      entitySubType: '',
      compositeEntityId: questionaire.value,
      entityLabel: '',
      isGenericOptAvail: '',
      isMatched: '',
      tierName: ''
    });
  });
  return savedUserEntity;
};

const updatedSelectedDentalProducts = (product: Contract, primaryProductContractCode: string): SelectedDentalProducts => {
  const { selectedDentalProducts } = useGlobalStore.getState();
  const dentalProducts: SelectedDentalProducts = JSON.parse(JSON.stringify(selectedDentalProducts));
  const index = dentalProducts.findIndex((dentalProduct) => dentalProduct.primaryProductContractCode === primaryProductContractCode);

  if (index !== -1) {
    dentalProducts[index].dentalProduct = product;
  } else {
    dentalProducts.push({
      primaryProductContractCode: primaryProductContractCode,
      dentalProduct: product,
      matchedDentists: []
    });
  }
  return dentalProducts;
};

const updatedSelectedVisionProducts = (product: Contract, primaryProductContractCode: string): SelectedVisionProducts => {
  const { selectedVisionProducts } = useGlobalStore.getState();
  const visionProducts: SelectedVisionProducts = JSON.parse(JSON.stringify(selectedVisionProducts));
  const index = visionProducts.findIndex((visionProduct) => visionProduct.primaryProductContractCode === primaryProductContractCode);

  if (index !== -1) {
    visionProducts[index].visionProduct = product;
  } else {
    visionProducts.push({
      primaryProductContractCode: primaryProductContractCode,
      visionProduct: product,
      matchedEyeDoctors: []
    });
  }
  return visionProducts;
};

const deleteSelectedDentalProduct = (selectedProduct: Contract, primaryProductContractCode: string): SelectedDentalProducts => {
  const { selectedDentalProducts } = useGlobalStore.getState();
  const dentalProducts: SelectedDentalProducts = JSON.parse(JSON.stringify(selectedDentalProducts));
  const index = dentalProducts.findIndex((dentalProduct) => dentalProduct.primaryProductContractCode === primaryProductContractCode);
  if (index !== -1) {
    dentalProducts.splice(index, 1);
  }
  return dentalProducts;
};

const deleteSelectedVisionProduct = (selectedProduct: Contract, primaryProductContractCode: string): SelectedVisionProducts => {
  const { selectedVisionProducts } = useGlobalStore.getState();
  const visionProducts: SelectedVisionProducts = JSON.parse(JSON.stringify(selectedVisionProducts));
  const index = visionProducts.findIndex((visionProduct) => visionProduct.primaryProductContractCode === primaryProductContractCode);
  if (index !== -1) {
    visionProducts.splice(index, 1);
  }
  return visionProducts;
};

const getOptionalProducts = (primaryProduct: Contract, optionalProducts: Contracts) => {
  if (primaryProduct.poPlan) {
    return [];
  } else if (primaryProduct.onExchange) {
    return getPlansForTab(MEDICAL_PLANS_TABS.MARKET_PLANS, optionalProducts);
  } else {
    return getPlansForTab(MEDICAL_PLANS_TABS.OFF_MARKET_PLANS, optionalProducts);
  }
};

export {
  deleteSelectedDentalProduct,
  deleteSelectedVisionProduct,
  generateHPAProposalPayload, getAgentOfRecordPayload, getATKsaveProposalRequestPayload, getDentalProducutsPayload,
  getFilteredMedicalProducts,
  getMedicalProducutsPayload,
  getOptionalProducts,
  getPayloadForAgentQuote,
  getPlanTypeLabel,
  getPrimaryProductTypeByCoverageType,
  getProductIndex,
  getUpdatedProductsForReview,
  getUpdatedSelectedProductsByCoverageType,
  getVisionProductsPayload,
  updatedSelectedDentalProducts,
  updatedSelectedVisionProducts
};

