const INPUT_TYPES = {
  EXAMPLE: 'EXAMPLE'
};

const MEDICATIONS_NOT_FOUND = (Strings:any) => {
  const medicationsNotFound = 
    {
      title: Strings.LABELS.NOTFOUND,
      description: Strings.LABELS.DRUGNOTFOUND
    }
  return medicationsNotFound;
};
const MEDICATIONS_SEARCH_LABELS = (Strings:any) => {
  const medicationsSearchLabels =
    {
  title: Strings.TITLE.SEARCH,
  description: Strings.startpageHTML
    }
}
const MIN_CHARACTERS = 3;

//export { INPUT_TYPES, MEDICATIONS_NOT_FOUND, MEDICATIONS_SEARCH_LABELS, MIN_CHARACTERS };
export { INPUT_TYPES, MEDICATIONS_NOT_FOUND, MIN_CHARACTERS };

