import React from 'react';

import './PlanListingTitles.scss';

const PlanListingTitles: React.FC<PlanListingTitlesProps> = ({ title, description, renderSubText }) => {
  return (
    <div className="plan-listing-titles-container">
      <h2 id="plan-listing-title" className="btm-xxs title">
        {title}
      </h2>
      {renderSubText && renderSubText()}
      {description && (
        <p id="plan-listing-description" className="btm-sm">
          {description}
        </p>
      )}
    </div>
  );
};

export default PlanListingTitles;
