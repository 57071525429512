import React, {useEffect} from "react";
import {css, html, LitElement} from "lit";
import {IosFwcBase, sharedStyles, fwcConfig} from "./SharedBase";
import wellpointBorder from "../../../images/wellpoint_border.svg";
import image from "../../../images/hero1.jpeg";

function MotifHero(props) {
    useEffect(() => {
        const fwcModal = new IosFwcHero();
        fwcModal.build();
    });
    return (
        <span>
            <ios-fwc-hero no-padding={props.noPadding} title={props.title} image={props.image} image-position-y={props.yPosition} image-position-x={props.xPosition}>
                <div slot="fwc-hero-content" >
                    {props.children}
                </div>
            </ios-fwc-hero>
        </span>
    );
}
export default MotifHero;

class IosFwcHeroComponent extends LitElement {
    static get styles() {
        return [
            sharedStyles,
            css`
        @keyframes scaleIn {
          0% {
            transform: scale(1.75);
            opacity: 0;
          }
          10% {
            opacity: 1;
          }
          100% {
            transform: scale(1);
            opacity: 1;
          }
        }
        :host {
          position: relative;
          overflow: hidden;
          background-color: var(--color-white);
          --gradient: rgba(var(--color-rgb-white), 1) 0%, rgba(var(--color-rgb-white), 0.8) 66%, rgba(var(--color-rgb-white), 0) 100%;
        }

        h1 {
          margin-bottom: calc(1.5 * var(--fwcu));
          color: var(--color-navy);
          font-family: var(--font-sans-serif-semibold, sans-serif);
        }

        picture {
          width: 100%;
          height: 100%;
          display: flex;
          background-color: var(--color-white);
        }

        img {
          background-color: var(--color-white);
          object-fit: cover;
          width: 100%;
          height: auto;
          min-height: calc(35 * var(--fwcu));
          opacity: 0;
        }
        .fwc-container {
          padding: calc(5 * var(--fwcu)) 0;
        }
        .fwc-hero-content {
          width: 50%;
        }
        .img-container {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          overflow: hidden;
        }

        .mobile-bottom-fade {
          position: absolute;
          display: none;
          bottom: 0;
          width: 100%;
          height: 50%;
          background-image: linear-gradient(to bottom, rgba(var(--color-rgb-white), 0), rgba(var(--color-rgb-white), 1) 100%);
        }

        .image-fade {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          background-image: linear-gradient(to right, var(--gradient));
        }
        .image-fade.right {
          background-image: linear-gradient(to left, var(--gradient));
        }

        .scale-in {
          animation-fill-mode: forwards;
          animation-name: scaleIn;
          animation-duration: 7s;
          animation-timing-function: ease-out;
          width: 100%;
          height: auto;
        }

        .content {
          position: relative;
          text-align: left;
          z-index: 1;
        }

        @media screen and (max-width: 900px) {
          .fwc-container {
            padding: 0;
            padding-bottom: calc(2 * var(--fwcu));
          }

          .content {
            padding: 0 calc(2 * var(--fwcu));
          }

          .img-container {
            position: relative;
          }

          .mobile-bottom-fade {
            display: block;
          }

          .image-fade {
            display: none;
          }
        }

        @media screen and (max-width: 600px) {
          .content {
            padding: 0;
          }
        }
      `,
        ];
    }

    static get properties() {
        return {
            alignContent: { attribute: "align-content", type: String }, // left | right
            config: { type: Object },
            noPadding: { attribute: "no-padding", type: Boolean },
            holdAnim: { attribute: "hold-anim", type: Boolean },
            imagePositionX: { attribute: "image-position-x", type: String },
            imagePositionY: { attribute: "image-position-y", type: String },
            image: { type: String },
            mobileImage: { attribute: "mobile-image", type: String },
            overlay: { type: Boolean },
            title: { type: String },
        };
    }

    constructor() {
        super();
        this.alignContent = "left";
        this.holdAnim = false;
        this.config = fwcConfig;
        this.imagePositionX = "center";
        this.imagePositionY = "center";
        this.image = null;
        this.mobileImage = null;
        this.overlay = false;
        this.noPadding = false;
    }

    firstUpdated() {
        if (this.mobileImage === null) {
            this.mobileImage = this.image;
        }
    }

    _startImageAnimation(e) {
        if (!e.target.classList.contains("scale-in")) {
            e.target.classList.add("scale-in");
        }
    }

    _onImageLoad(e) {
        if (!this.holdAnim) {
            this._startImageAnimation(e);
        }
    }

    triggerAnimation() {
        if (this.holdAnim) {
            const img = this.shadowRoot.getElementById("Hero");
            this._startImageAnimation({ target: img });
        }
    }

    _renderTitle() {
        if (this.title) return html`<h1>${this.title}</h1>`;
    }

    _contentAligned() {
        const { alignContent } = this;
        const { noPadding } = this;
        const padding = html`<div class="${noPadding ? "" : "fwc-col-1 swap md-0"}"></div>`;
        return html`
      <div class="fwc-row ${alignContent === "right" ? "j-end" : ""}">
        ${alignContent === "left" ? padding : ""}
        <div class="fwc-col-12 md-12 sm-12">
          <div class="content">
              <div class="fwc-row">${this._renderTitle()}</div>
            <slot name="fwc-hero-content"></slot>
          </div>
        </div>
        ${alignContent === "right" ? padding : ""}
      </div>
    `;
    }

    _overlay() {
        if (this.overlay) {
            return html`<div class="image-fade ${this.alignContent}"></div>`;
        }
    }

    render() {
        return html`
      <link rel="stylesheet" href="${this.config.assetsPath}fwc-assets/styles/grid.css" />
      <link rel="stylesheet" href="${this.config.assetsPath}fwc-assets/styles/typography.css" />

      <div class="fwc-container">
        <div class="img-container">
          <picture>
            <source srcset=${this.mobileImage} media="(max-width: 600px)" />
            <img @load=${this._onImageLoad} id="Hero" src=${this.image} alt="Hero Image" style="object-position: ${this.imagePositionX} ${this.imagePositionY}" />
          </picture>
          <div class="mobile-bottom-fade"></div>
        </div>

        ${this._overlay()} ${this._contentAligned()}
      </div>
    `;
    }
}

class IosFwcHero extends IosFwcBase {
    constructor() {
        super("ios-fwc-hero", IosFwcHeroComponent);
    }
}
