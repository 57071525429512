export const initialAgentSSOInfo: AgentSSOInfo = {
  id: '',
  isAtk: false,
  eventHeader: {
    transactionId: '',
    product: '',
    senderApp: '',
    smUserID: '',
    brokerUserID: '',
    partnerID: '',
    proposalID: '',
    eventTimestamp: ''
  },
  agentType: '',
  memberInfo: null,
  agentInfo: {
    agent: []
  },
  applicantInfo: {
    customerRefNumber: '',
    zipCode: '',
    county: '',
    primaryEmailAddress: '',
    homePhoneNumber: '',
    mobilePhoneNumber: '',
    marketingRefId: '',
    vendorLeadId: '',
    policyEffectiveDate: '',
    maritalStatus: '',
    applicants: {
      applicant: []
    },
    applicantAddressInfo: {
      address: []
    }
  }
};

export const initialAgentSSODataforLocal: AgentSSOInfo = {
  isAtk: true,
  id: '6690128933fe147b38fc2017',
  eventHeader: {
    transactionId: 'abc-1256655',
    product: 'ACA',
    senderApp: 'PTB',
    smUserID: 'Telesalesallstates',
    brokerUserID: 'telesalesallstates',
    partnerID: 'ATK',
    proposalID: '36475',
    eventTimestamp: '2024-07-11T13:12:41.889'
  },
  agentType: 'TELESALES',
  memberInfo: null,
  agentInfo: {
    agent: [
      {
        agentUserID: 'telesalesallstates',
        writingTIN: 'DLJPLSRMLZ',
        writingTINAgentType: 'TELESALES',
        NPN: '15159219',
        agentFName: 'CHARLES',
        agentLName: 'MARTIN',
        agencyName: '',
        agentType: 'TELESALES',
        states: {
          state: [
            {
              stateName: 'TX',
              FFM: '',
              certNo: '',
              email: 'dl-bp_neptune_developers@anthem.com',
              phone: '8553712236',
              phoneExt: '',
              fax: '',
              faxExt: '',
              licenseNumber: '471394',
              agentAddressInfo: {
                agentAddress: {
                  address1: '3075 VANDERCAR WAY',
                  address2: '',
                  city: 'CINCINNATI',
                  state: 'TX',
                  zip: '77070'
                }
              },
              agencyInfo: {
                agency: {
                  paidTIN: 'HCDFGHJKLY',
                  parentTIN: 'HCDFGHJKLY',
                  agentCode: 'AAA372511',
                  agencyName: 'DIRECT SALES'
                }
              }
            },
            {
              stateName: 'OH',
              FFM: '',
              certNo: '54321',
              email: 'dl-bp_neptune_developers@anthem.com',
              phone: '8553712236',
              phoneExt: '',
              fax: '',
              faxExt: '',
              licenseNumber: '2483197',
              agentAddressInfo: {
                agentAddress: {
                  address1: '3075 VANDERCAR WAY',
                  address2: '',
                  city: 'CINCINNATI',
                  state: 'OH',
                  zip: '45209'
                }
              },
              agencyInfo: {
                agency: {
                  paidTIN: 'HCDFGHJKLY',
                  parentTIN: 'HCDFGHJKLY',
                  agentCode: '818',
                  agencyName: 'DIRECT SALES'
                }
              }
            },
            {
              stateName: 'KY',
              FFM: 'FFMSATZIMPL01004',
              certNo: '',
              email: 'dl-bp_neptune_developers@anthem.com',
              phone: '8553712236',
              phoneExt: '',
              fax: '',
              faxExt: '',
              licenseNumber: 'DOI-716729',
              agentAddressInfo: {
                agentAddress: {
                  address1: '3075 VANDERCAR WAY',
                  address2: '',
                  city: 'CINCINNATI',
                  state: 'OH',
                  zip: '45209'
                }
              },
              agencyInfo: {
                agency: {
                  paidTIN: 'HCDFGHJKLY',
                  parentTIN: 'HCDFGHJKLY',
                  agentCode: '',
                  agencyName: ''
                }
              }
            },
            {
              stateName: 'ME',
              FFM: 'FFMSATZIMPL01004',
              certNo: '',
              email: 'dl-bp_neptune_developers@anthem.com',
              phone: '8553712236',
              phoneExt: '',
              fax: '',
              faxExt: '',
              licenseNumber: 'PRN245924',
              agentAddressInfo: {
                agentAddress: {
                  address1: '3075 VANDERCAR WAY',
                  address2: '',
                  city: 'CINCINNATI',
                  state: 'OH',
                  zip: '45209'
                }
              },
              agencyInfo: {
                agency: {
                  paidTIN: 'HCDFGHJKLY',
                  parentTIN: 'HCDFGHJKLY',
                  agentCode: '818',
                  agencyName: 'DIRECT SALES'
                }
              }
            },
            {
              stateName: 'MO',
              FFM: '',
              certNo: '',
              email: 'dl-bp_neptune_developers@anthem.com',
              phone: '8553712236',
              phoneExt: '',
              fax: '',
              faxExt: '',
              licenseNumber: '8040983',
              agentAddressInfo: {
                agentAddress: {
                  address1: '3075 VANDERCAR WAY',
                  address2: '',
                  city: 'CINCINNATI',
                  state: 'OH',
                  zip: '45209'
                }
              },
              agencyInfo: {
                agency: {
                  paidTIN: 'HCDFGHJKLY',
                  parentTIN: 'HCDFGHJKLY',
                  agentCode: 'SR7451',
                  agencyName: 'DIRECT SALES'
                }
              }
            },
            {
              stateName: 'NV',
              FFM: '',
              certNo: '',
              email: 'dl-bp_neptune_developers@anthem.com',
              phone: '8553712236',
              phoneExt: '',
              fax: '',
              faxExt: '',
              licenseNumber: '1015624',
              agentAddressInfo: {
                agentAddress: {
                  address1: '3075 VANDERCAR WAY',
                  address2: '',
                  city: 'CINCINNATI',
                  state: 'OH',
                  zip: '45209'
                }
              },
              agencyInfo: {
                agency: {
                  paidTIN: 'HCDFGHJKLY',
                  parentTIN: 'HCDFGHJKLY',
                  agentCode: 'AAA372512',
                  agencyName: 'DIRECT SALES'
                }
              }
            },
            {
              stateName: 'NY',
              FFM: '',
              certNo: '',
              email: 'dl-bp_neptune_developers@anthem.com',
              phone: '8553712236',
              phoneExt: '',
              fax: '',
              faxExt: '',
              licenseNumber: 'LA-1330759',
              agentAddressInfo: {
                agentAddress: {
                  address1: '3075 VANDERCAR WAY',
                  address2: '',
                  city: 'CINCINNATI',
                  state: 'OH',
                  zip: '45209'
                }
              },
              agencyInfo: {
                agency: {
                  paidTIN: '',
                  parentTIN: '',
                  agentCode: '',
                  agencyName: ''
                }
              }
            },
            {
              stateName: 'OH',
              FFM: '',
              certNo: '',
              email: 'dl-bp_neptune_developers@anthem.com',
              phone: '8553712236',
              phoneExt: '',
              fax: '',
              faxExt: '',
              licenseNumber: '1012640',
              agentAddressInfo: {
                agentAddress: {
                  address1: '3075 VANDERCAR WAY',
                  address2: '',
                  city: 'CINCINNATI',
                  state: 'OH',
                  zip: '45209'
                }
              },
              agencyInfo: {
                agency: {
                  paidTIN: 'HCDFGHJKLY',
                  parentTIN: 'HCDFGHJKLY',
                  agentCode: 'V795',
                  agencyName: 'DIRECT SALES'
                }
              }
            },
            {
              stateName: 'VA',
              FFM: '',
              certNo: '',
              email: 'dl-bp_neptune_developers@anthem.com',
              phone: '8553712236',
              phoneExt: '',
              fax: '',
              faxExt: '',
              licenseNumber: '895360',
              agentAddressInfo: {
                agentAddress: {
                  address1: '3075 VANDERCAR WAY',
                  address2: '',
                  city: 'CINCINNATI',
                  state: 'OH',
                  zip: '45209'
                }
              },
              agencyInfo: {
                agency: {
                  paidTIN: 'HCDFGHJKLY',
                  parentTIN: 'HCDFGHJKLY',
                  agentCode: '026101282',
                  agencyName: 'DIRECT SALES'
                }
              }
            }
          ]
        },
        webAcctId: '',
        webGUID: '',
        userId: '',
        userRole: ''
      }
    ]
  },
  applicantInfo: {
    customerRefNumber: '0013F00000XTUk7QAH',
    zipCode: '40241',
    county: 'JEFFERSON',
    primaryEmailAddress: 'renganathan.ramananvenkata@anthem.com',
    homePhoneNumber: '',
    mobilePhoneNumber: '9999999999',
    marketingRefId: 'AX02',
    vendorLeadId: 'SFDC1767883',
    policyEffectiveDate: '',
    maritalStatus: '',
    applicants: {
      applicant: [
        {
          salesforceId: '0013F00000XTUk7QAH',
          relationship: 'SELF',
          firstName: 'Ford',
          midInitial: '',
          lastName: 'James',
          gender: 'M',
          dateOfBirth: '1985-01-01',
          tobaccoUsage: 'N',
          SSN: ''
        },
        {
          salesforceId: 'a0we0000001VnfRAAS',
          relationship: 'DOMESTICPARTNER',
          firstName: 'Susan',
          midInitial: '',
          lastName: 'James',
          gender: 'F',
          dateOfBirth: '1988-01-01',
          tobaccoUsage: 'N',
          SSN: ''
        }
      ]
    },
    applicantAddressInfo: {
      address: [
        {
          addressType: 'MAILING',
          address1: '5th st',
          address2: '',
          city: 'ARVADA',
          state: 'CO',
          zip: '80002',
          county: ''
        },
        {
          addressType: 'HOME',
          address1: '5th st',
          address2: '',
          city: 'ARVADA',
          state: 'CO',
          zip: '80002',
          county: ''
        }
      ]
    }
  }
};
