/*
Copyright 2022 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it.
*/
import { SBUIProvider } from '@sydney-broker-ui/ios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { ModelManager } from "@adobe/aem-spa-page-model-manager";

// Initialize the ModelManager before invoking root.render(..).
ModelManager.initializeAsync();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <SBUIProvider assetsPath={'/individuals/'}>
      <App />
    </SBUIProvider>
  </React.StrictMode>
);
