import React, { useState } from 'react';

import { EASY_PRICING_KEY } from '../../../shared/globalConstants';
import './EasyPricingTag.scss';

const EasyPricingTag: React.FC<EasyPricingTagProps> = ({ labels, planName, onExchange, displayPopoverAtBottom }) => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const handleMouseEnter = () => {
        setIsPopupVisible(true);
    };

    const handleMouseLeave = () => {
        setIsPopupVisible(false);
    };

    const handleClose = () => {
        setIsPopupVisible(false);
    };

    if (planName.toLocaleLowerCase().includes(EASY_PRICING_KEY)) {
        return (
            <div
                className='easy-pricing-container'
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className='icon-container '><div className='icon-bacnkground'> <span className={'sae-icon sae-icon-price-tag icon-style'} /></div><span className='description-label'>{labels.EASY_PRICING}</span></div>
                {isPopupVisible && (
                    <div className={!displayPopoverAtBottom ? 'popover-container' : 'bottom-popover-container'}>
                        <p className='margin-bottom description-label'>
                            {onExchange ? labels?.PLANS_MARKED : labels?.OFF_MARKET_PLACE_PLANS} <b>{labels?.EASY_PRICING_LABEL}</b>
                        </p>
                        <div className='list-container'>
                            <ul>
                                <li><p className='description-label'>{labels?.INCLUDE_SOME_BENEFITS}</p></li>
                                <ul>
                                    <li>
                                        <p className='description-label'>{labels?.DOCTORA_AND}</p>
                                    </li>
                                    <li>
                                        <p className='description-label'>{labels?.URGENT_CARE}</p>
                                    </li>
                                    <li>
                                        <p className='description-label'>{labels?.PHYSICAL_SPEECH}</p>
                                    </li>
                                    <li>
                                        <p className='description-label'>{labels?.GENERIC_AND} <b>{labels?.MOST}</b> {labels?.PREFERRED_DRUGS}</p>
                                    </li>
                                </ul>
                                <li><p className='description-label'>{labels?.ARE_EASIER_TO_COMPARE}</p></li>
                                <ul>
                                    <li>
                                        <p className='description-label'>{labels?.DEDUCTIBLES_LABEL}</p>
                                    </li>
                                    <li>
                                        <p className='description-label'>{labels?.OUT_OF_POCKET_MAXIMUMS}</p>
                                    </li>
                                    <li>
                                        <p className='description-label'>{labels?.COPAYMENT}</p>
                                    </li>
                                </ul>

                            </ul>
                        </div>
                    </div>
                )}
            </div>
        );
    } else {
        return <div />
    }
}

export default EasyPricingTag