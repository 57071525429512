const getUpdatedFilters = (options: CheckBoxFilterOption[], updatedOption: CheckBoxFilterOption): CheckBoxFilterOption[] => {
  const { key, isChecked } = updatedOption;

  return options.map((filterObj) => ({
    ...filterObj,
    isChecked: filterObj.key === key ? !isChecked : filterObj.isChecked
  }));
};

const getResetFilters = (options: CheckBoxFilterOption[]): CheckBoxFilterOption[] => {
  return options.map((filterObj) => ({
    ...filterObj,
    isChecked: false
  }));
};

export { getResetFilters, getUpdatedFilters };
