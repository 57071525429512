const VISION_RECOMMENDATION_BENEFITS = {
  RICHER_BENEFITS: 'RICHER_BENEFITS',
  LOWER_BENEFITS: 'LOWER_BENEFITS'
};

const RECOMMENDATION_OPTIONS = [
  {
    key: VISION_RECOMMENDATION_BENEFITS.RICHER_BENEFITS,
    label: '',
    value: 'High'
  },
  {
    key: VISION_RECOMMENDATION_BENEFITS.LOWER_BENEFITS,
    label: '',
    value: 'Low'
  }
];

const VISION_RECOMMENDATION_RESPONSE_CODES = {
  SUCCESS: 'PKGRETRIEVALINFO000'
};

export { RECOMMENDATION_OPTIONS, VISION_RECOMMENDATION_BENEFITS, VISION_RECOMMENDATION_RESPONSE_CODES };
