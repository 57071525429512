import { Paragraph, Popover } from '@sydney-broker-ui/ios';
import React, { useState } from 'react';
import { getTitleIcon } from '../../../pages/public/provider-details/ProviderDetailsUtils';
import { PLAN_TYPES_NAMES } from '../../../shared/globalConstants';
import { getFormattedCurrency } from '../../../shared/utils/globalUtils';
import MatchingListCard from '../matching-list-card/MatchingListCard';
import './productCardOptional.scss';

const ProductCardOptional: React.FC<ProductCardOptionalProps> = ({ labels,
    contract,
    matchedDentists,
    matchedDentistsFlag,
    matchedEyeDoctors,
    matchedEyeDoctorsFlag,
    useCase,
    onProductNameClick,
    onChangeProductClick,
    onDeleteClick,
    addProviders
}) => {
    const [anchor, setAnchor] = useState<React.SetStateAction<null> | (EventTarget & HTMLSpanElement)>(null);
    const [popoverOpen, setPopoverOpen] = useState(false);

    const providerFlag = useCase === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? matchedDentistsFlag : matchedEyeDoctorsFlag;

    const renderMonthlyPremium = () => {
        if (contract?.rateData?.rate?.totSubsidizedPrem || contract?.rateData?.rate?.subsidyAmtApplied || contract?.rateData?.rate?.totSubsidizedPrem === 0) {
            return getFormattedCurrency(contract?.rateData?.rate?.totSubsidizedPrem);
        } else {
            return labels.RATE_NOT_AVAILABLE;
        }
    };

    const renderPopoverContent = (rateObj: { totalPremium: number; totSubsidizedPrem: number; subsidyAmtApplied: number; } | undefined) => {
        return (
            <Paragraph>
                <div className="popover-container">
                    <span className="label-amount-container-margin-btm">
                        <b>{labels.MONTHLY_COST_BREAKDOWN}</b>
                    </span>
                    <div className="amount-label-container">
                        <p>{labels.TOTAL_PLAN_COST}</p>
                        <span>
                            <b>{getFormattedCurrency(rateObj?.totalPremium)}</b>
                        </span>
                    </div>
                    <div className="amount-label-container label-amount-container-margin-btm">
                        <p>{labels.SUBSIDY_APPLIED}</p>
                        <span>
                            <b>{getFormattedCurrency(rateObj?.subsidyAmtApplied)}</b>
                        </span>
                    </div>
                    <div className="amount-label-container">
                        <span className="label-margin-right">
                            <b>{labels.TOTAL_MEMBER_RESPONSIBILITY}</b>
                        </span>
                        <span className="total-subsidised-amount">
                            <b>{getFormattedCurrency(rateObj?.totSubsidizedPrem)}</b>
                        </span>
                    </div>
                </div>
            </Paragraph>
        );
    };

    const handleHelpClick = (currentTarget: React.SetStateAction<null> | (EventTarget & HTMLSpanElement)) => {
        setAnchor(currentTarget);
        setPopoverOpen(!popoverOpen);
    };

    return (
        <div className="optional-product-card-wrapper">
            <div className="product-name-cost-container">

                <h4
                    id="product-card-plan-name"
                    className="product-name"
                    data-testid="product-name"
                    onClick={() => {
                        onProductNameClick(contract);
                    }}
                >
                    <span className={`sae-icon ${getTitleIcon(useCase === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? PLAN_TYPES_NAMES.DENTAL : PLAN_TYPES_NAMES.VISION)} product-icon`}></span>  {contract.planMarketingName + ' ' + contract.contractCode}
                </h4>

                <div className="monthly-premium-container">
                    <div className="monthly-premium">
                        <h4 className="label-margin">{renderMonthlyPremium()}</h4>
                        {popoverOpen && anchor && (
                            <Popover id="product-card-plan-amount-popover" placement={'top'} anchorEl={anchor} onClickOutside={() => setPopoverOpen(!popoverOpen)}>
                                {renderPopoverContent(contract?.rateData?.rate)}
                            </Popover>
                        )}
                        <span id="product-card-amount-tooltip" data-testid="product-amount-help-click" className="sae-icon sae-icon-help help-icon" onClick={(e) => handleHelpClick(e.currentTarget)} />
                    </div>
                </div>
            </div>
            <div className="matching-list-outer-wrapper">
                {providerFlag && (
                    <MatchingListCard
                        icon={'sae-icon-doctors-bag'}
                        label={useCase === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? labels.DENTISTS_LIST_LABEL : labels.VISION_LIST_LABEL}
                        showMoreText={`${labels.SHOW_MORE} ${useCase === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? labels?.DENTISTS : labels.EYE_DOCTORS}`}
                        showLessText={`${labels.SHOW_LESS} ${useCase === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? labels?.DENTISTS : labels.EYE_DOCTORS}`}
                        providers={useCase === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? matchedDentists : matchedEyeDoctors}
                        tooltipMessage={labels.MATCHED_DOCTORS_TOOLTIP_TEXT}
                    />
                )}
            </div>
            <div className="add-dentist-change-plan-sec">
                <span id="product-add-dentist-btn" className="add-dentists-btn" onClick={() => addProviders(useCase)}>{useCase === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? labels.ADD_DENTISTS : labels.ADD_EYE_DOCTORS}</span>
                <span id="change-product-option" className="change-product" onClick={(e) => onChangeProductClick(e.currentTarget)}>{labels.CHANGE_PLAN}</span>
                <span id="product-card-delete" className="sae-icon sae-icon-trash product-delete-icon" onClick={() => onDeleteClick(contract)}></span>
            </div>

        </div>
    )
}

export default ProductCardOptional