import { Button, Modal } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from "react";
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { LANGUAGE_CODES, SCREEN_NAMES, USER_DOMAINS } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import FullPageLoader from '../full-page-loader/FullPageLoader';
import './SubmitApplication.scss';
import { default as Content } from './submitApplicationPopup.json';



const SubmitApplicationPopup: React.FC<SubmitApplicationPopupProps> = ({ showPopup, popupClose, cancelStartOver, removeAgentSubmit }) => {

    const [pageContent, setPageContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const { updateUsername, zipCodeRes } = useGlobalStore((state) => state);


    const getContent = useCallback(async (): Promise<boolean> => {
        try {
            const response = await getCMSStaticContent(USER_DOMAINS.BRAND, zipCodeRes.stateCode, LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.DELETEAPPLICATIONCONTENT);
            let cmsResponse = response.data;
            setPageContent(cmsResponse.data.iospagecontentList.items[0].pageContent?.submitapplicationpopup);
        } catch (error) {
            setPageContent(Content?.data.iospagecontentList.items[0].pageContent?.submitapplicationpopup);

            return false;
        }
        finally {
            setContentLoaded(true);
        }
        return true;
    }, []);

    useEffect(() => {
        initiate();
    }, []);

    const initiate = async () => {
        await getContent();
    };

    const handleDeleteButton = () => {
        cancelStartOver();
        popupClose();

    }

    const handleRemoveAgentSubmit = () => {
        removeAgentSubmit();
        popupClose();
    }


    return (!contentLoaded ? <FullPageLoader /> :
        <div className='delete-popup-wraper'>
            <Modal open={showPopup} onClose={() => popupClose()} title={pageContent?.REVIEW_NEEDED_TITLE}>
                <Modal.Body className="popup-body">
                    <div id="popup-model">
                        <div className="popup-container" id="popup-container" data-testid="popup-container">
                            <span><p id="popup-content">{pageContent?.SUBMIT_APPLICATION_POPUP_CONTENT}</p>
                            </span>
                        </div>
                        <Modal.Footer>
                            <div className="popup-buttons" id="popup-buttons-wrapper">

                                <Button btnType="secondary" id="logout-button" onClick={handleDeleteButton}
                                    data-testid="logout-button"
                                >
                                    {pageContent?.START_OVER_BUTTON}
                                </Button>
                                <Button btnType="primary" id="cancel-button" onClick={handleRemoveAgentSubmit}
                                    data-testid="cancel-button">
                                    {pageContent?.REMOVE_AGENT_BUTTON}
                                </Button>
                            </div>
                        </Modal.Footer>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default SubmitApplicationPopup;
