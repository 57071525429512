import { Button, ModalSlide } from '@sydney-broker-ui/ios';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { getCMSStaticContent, getCMSdynamicContent } from '../../../api/cms/cmsUtil';
import { STATUSES } from '../../../api/constants';
import EmailPrint from '../../../components/common/email-print/EmailPrint';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import { AGENT_ROLES, APPLICATION_TYPE, FINDCARE_URL_ANTHEM, FINDCARE_URL_WELLPOINT, LANGUAGE_CODES, PAGE_BLOCKS, PAGE_IDS, PLAN_TYPES, PLAN_TYPES_NAMES, SCREEN_NAMES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { fetchAlphaPrefix } from '../medical-plans/medicalPlansServices';
import { getCoverageTypeForDynamicAEM } from '../plan-recommendations/planRecommendationsUtils';
import { default as Content } from './DentalPlanDetails.json';
import './DentalPlanDetails.scss';

const DentalPlanDetailsSlider: React.FC<DentalPlanDetailsProps> = ({ contract, isCurrentCoveragePlan, closeDentalPlanDetails, showCloseButton, currentDateTimeInZone, pdfQueryParmRec }) => {
    const [loading, setLoading] = useState(false);
    const impRef = useRef<any>(null)
    const globalStore = useGlobalStore((store) => store);
    const [pageContent, setContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [providerNetworkType, setProviderNetworkType] = useState<string>('');
    const [pdfBrochureUrl, setPdfBrochureUrl] = useState<string>('');
    const { role } = useUserStore((store) => store);
    const { state, brand, year, agentSSOInfo, agentBrand, agentState, agentYear } = globalStore;
    const { isAtk } = agentSSOInfo;
    const { isWellpoint } = useUserStore.getState();
    const FINDCARE_URL = isWellpoint ? FINDCARE_URL_WELLPOINT : FINDCARE_URL_ANTHEM;

    const getPdfBroucher = async () => {
        let queryParam = '';
        if (isAtk) {
            queryParam = ';state=' + agentState + ';brand=' + agentBrand + ';role=' + AGENT_ROLES.BROKER.toLowerCase() + ';plantype=' + (contract.onExchange ? APPLICATION_TYPE.ON_EXCHANGE.toLowerCase() : APPLICATION_TYPE.OFF_EXCHANGE.toLowerCase()) + ';coveragetype=' + getCoverageTypeForDynamicAEM(SCREEN_NAMES.DENTAL_DETAILS_SCREEN_NAME) + ';year=' + agentYear;
        } else {
            queryParam = ';state=' + state + ';brand=' + brand + ';role=' + role.toLowerCase() + ';plantype=' + (contract.onExchange ? APPLICATION_TYPE.ON_EXCHANGE.toLowerCase() : APPLICATION_TYPE.OFF_EXCHANGE.toLowerCase()) + ';coveragetype=' + getCoverageTypeForDynamicAEM(SCREEN_NAMES.DENTAL_DETAILS_SCREEN_NAME) + ';year=' + year;
        }
        await getCMSdynamicContent(PAGE_IDS.PRODUCT_BROUCHER_LINK_ALL, PAGE_BLOCKS.PRODUCT_BROUCHER_LINK, pdfQueryParmRec ?? queryParam, currentDateTimeInZone ?? '')
            .then((response) => {
                setPdfBrochureUrl(response?.data.data?.iosdynamiccontentList?.items[0]?.textsummary);
            })
            .catch((error) => {
                console.warn('DenatlPlanDetails.tsx getPdfBroucher error :', error);
            });
    };

    const getContent = useCallback(async (): Promise<boolean> => {
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.DENTAL_DETAILS_SCREEN_NAME);
            let cmsResponse = response.data;
            setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.DentalPlanDetails);
            //setPdfBrochureUrl(cmsResponse.data.iospagecontentList.items[0].pageContent?.DentalPlanDetails?.PRODUCT_BROUCHER_LINK);
        } catch (error) {
            setContent(Content.data.iospagecontentList.items[0].pageContent.DentalPlanDetails);

            return false;
        } finally {
            setContentLoaded(true);
        }
        return true;
    }, []);

    const initiate = async () => {
        await getPdfBroucher();
        await getContent();
        const aplhaPrefixPayload: AlpaPrefixReq = {
            contractCode: contract.contractCode
        }
        getAlphaPrefix(aplhaPrefixPayload)
    };

    const getAlphaPrefix = (payload: AlpaPrefixReq) => {
        fetchAlphaPrefix(payload).then((response) => {
            if (response?.data?.message === STATUSES.SUCCESS) {
                setProviderNetworkType(response?.data?.providerNetworkType);
            } else {
                setProviderNetworkType('');
            }
        })
            .catch((error) => {
                setProviderNetworkType('');
            });
    }


    useEffect(() => {
        initiate();
    }, []);

    let FINDCARE_URL_WITH_CODE = FINDCARE_URL;
    useEffect(() => {
        //apend providerNetworkType to the findcare url
        if (providerNetworkType) {
            FINDCARE_URL_WITH_CODE = `${FINDCARE_URL}?planstate=${state}&plantype=${PLAN_TYPES_NAMES.DENTAL}&planname=${providerNetworkType}`;
        }
    }, [providerNetworkType])

    const handleSliderClose = useCallback(() => {
        closeDentalPlanDetails();
    }, [closeDentalPlanDetails]);

    const openViewSummaryLink = () => {
        window.open(`https://www.sbc.anthem.com/dps/CCD${contract?.contractCode}?tabId=`, "_blank");
    };

    return (
        <>
            <div>
                {!contentLoaded ? (
                    <FullPageLoader />
                ) : (
                    <ModalSlide
                        direction={'right'}
                        open={contract ? true : false}
                        onClose={() => handleSliderClose()}
                    >
                        <ModalSlide.Body>
                            <div className={'slider-container'}>
                                <div className='fwc-row fwc-row-wrap fwc-row-bm'>
                                    <div className='fwc-col-12 sm-12 md-12 dental-plan-details-container'>
                                        <h2 className='btm-xxs title' id='medical-plan-detail-title'>
                                            <span
                                                id='dental-plan-detail-heading'
                                                className=' heart-health-icon sae-icon sae-icon-tooth  right-xxs'
                                            ></span>
                                            {pageContent?.DENTAL_PLAN_DETAILS}
                                        </h2>
                                        {!loading && <>
                                            <div className='fwc-row info-div-align fwc-row-bm'>
                                                <span className='sae-icon sae-icon-info info-icon-size right-xxs'
                                                ></span>
                                                <p id='see'>{pageContent?.SEE} <span id={'important-link'} className='important-link' onClick={() => impRef.current.scrollIntoView()}> {pageContent?.IMPORTANT} </span>{pageContent?.NOTICE_ABOUT}</p>
                                            </div>

                                            {!isAtk && <div className='fwc-row hmo-row fwc-row-bm'>
                                                <div>
                                                    <EmailPrint isCurrentCoveragePlan={isCurrentCoveragePlan} pdfBrochureUrl={pdfBrochureUrl ? pdfBrochureUrl : ''} type={PLAN_TYPES.DENTAL} providerNetworkType={providerNetworkType} emailPrintContract={contract} />
                                                </div>
                                            </div>
                                            }

                                            <div className='plan-div fwc-row-bm'>
                                                <div className='fwc-row fwc-row-bm'>
                                                    <div className='fwc-col-8 sm-12 md-12'>
                                                        <p className='plan-name' id='plan-name'> {contract?.planMarketingName} - ({contract?.contractCode})</p>
                                                    </div>
                                                </div>
                                                {!isCurrentCoveragePlan && <div className='fwc-row'>
                                                    <div className='fwc-col-3 sm-12 md-3 plan-div-last-row'>
                                                        <span className='sae-icon sae-icon-pdf  right-xxs icon-style' onClick={() => window.open(pdfBrochureUrl, "_blank")}
                                                        ></span>
                                                        <p className='highlight-text' id='pdf-brocher' data-testid="pdf-brocher" onClick={() => window.open(pdfBrochureUrl, "_blank")}>{pageContent?.PDF_BROCHURE}</p>
                                                    </div>
                                                    {globalStore.state === "CA" && <div className='fwc-col-6 sm-12 md-6 plan-div-last-row'>
                                                        <span className='sae-icon sae-icon-external-link  right-xxs icon-style'
                                                        ></span>
                                                        <p className='highlight-text' id='view-summary' onClick={openViewSummaryLink}>{pageContent?.VIEW_SUMMARY_OF_BENEFITS_COVERAGE}</p>
                                                    </div>}
                                                    <div className='fwc-col-3 sm-12 md-3 plan-div-last-row'>
                                                        <span className='sae-icon sae-icon-search-1  right-xxs icon-style' id='find-care-span'
                                                            onClick={() => window.open(FINDCARE_URL_WITH_CODE, "_blank")}></span>
                                                        <p className='highlight-text' id='find-care' onClick={() => window.open(FINDCARE_URL_WITH_CODE, "_blank")}>{pageContent?.FIND_CARE}</p>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                            <div>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>{pageContent?.BENEFIT} </th>
                                                            <th>{pageContent?.BENEFIT_DETAILS}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {(contract?.rateData?.rate && Object.keys(contract?.rateData?.rate).length) ?
                                                            <tr>
                                                                <td> <h6>{pageContent?.MONTHLY_COST}</h6>  </td>
                                                                <td>
                                                                    <div className='fwc-row'>
                                                                        <div className='fwc-col-3 sm-12 md-4'>
                                                                            <h5 className="plan-label-text" >${contract?.rateData.rate.totSubsidizedPrem}</h5>
                                                                            <p className="plan-label-text">{pageContent?.MONTHLY_PREMIUM}</p>
                                                                        </div>
                                                                        <div className='fwc-col-9 sm-12 md-8'>
                                                                            <h6 className="plan-label-text">{pageContent?.MONTHLY_COST_BREAK}</h6>
                                                                            <div>
                                                                                <div className='fwc-row gap-breakdown'>
                                                                                    <div className='fwc-col-6 sm-6 md-6 plan-label-text'>
                                                                                        {pageContent?.TOTAL_PLAN_COST}
                                                                                    </div>
                                                                                    <div className='fwc-col-6 sm-6 md-6 plan-label-text-result'>
                                                                                        ${contract?.rateData.rate.totalPremium}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='fwc-row gap-breakdown'>
                                                                                    <div className='fwc-col-6 sm-6 md-6 plan-label-text'>
                                                                                        {pageContent?.SUBSIDY_APPLIED}
                                                                                    </div>
                                                                                    <div className='fwc-col-6 sm-6 md-6 plan-label-text-result'>
                                                                                        ${contract?.rateData.rate.subsidyAmtApplied?.toFixed(2)}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='fwc-row gap-total-mem'>
                                                                                    <div className='fwc-col-6 sm-6 md-6 plan-label-text'>
                                                                                        <h6 className='total-mem-wrap'>{pageContent?.TOTAL_MEMBER_RESP}</h6>
                                                                                    </div>
                                                                                    <div className='fwc-col-6 sm-6 md-6 plan-label-text-result plan-result'>
                                                                                        <h5>${contract?.rateData.rate.totSubsidizedPrem}</h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr> : null}

                                                        {
                                                            contract?.plan[0]?.planBenefits?.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td><h6>{pageContent?.PLAN_BENEFIT_NAMES[item.planBenefitCode]} </h6> </td>
                                                                        <td><span dangerouslySetInnerHTML={{ __html: item.planBenefitValue }}></span></td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                        }

                                    </div>
                                </div>
                                {!loading && <div id="important-link-div" ref={impRef} className='fwc-row-bm'>
                                    <h6 id='important'>{pageContent?.IMPORTANT}</h6>
                                    <div id='important-1' className='fwc-row fwc-row-bm'>
                                        {pageContent?.IMPORTANT_1}
                                    </div>
                                    <div id='important-2' className='fwc-row fwc-row-bm'>
                                        {pageContent?.IMPORTANT_2}
                                    </div>
                                </div>}
                            </div>
                        </ModalSlide.Body>
                        <ModalSlide.Footer>
                            <Button
                                btnType='secondary'
                                id='modal-slide-cancel'
                                className='right-xs'
                                onClick={handleSliderClose}
                                data-testid="modal-slide-cancel"
                            >
                                {showCloseButton ? pageContent?.CLOSE : pageContent?.VIEW_ALL_DENTAL_PLANS}
                            </Button>
                        </ModalSlide.Footer>
                    </ModalSlide>
                )}
            </div>
        </>
    );
}


const DentalPlanDetails = memo(DentalPlanDetailsSlider);

export default DentalPlanDetails;