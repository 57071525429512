import { Pagination } from '@sydney-broker-ui/ios';
import React, { memo } from 'react';
import FindYourDoctorCard from '../find-your-doctor-card/FindYourDoctorCard';
import { getFormHeaderClassName } from '../find-your-doctor-form/findYourDoctorFormUtils';
import './FindYourDoctorList.scss';

const FindYourDoctorList: React.FC<FindYourDoctorListProps> = ({
  label,
  description1,
  description2,
  metaData,
  data,
  selectedProviders,
  savedProiders,
  labels,
  useCase,
  renderCustomDescription2,
  onSelectionChange,
  onPageChange
}) => {
  const isProviderSelected = (provider: Provider) => {
    const providerObj = selectedProviders.find(
      (obj) => obj.providerIdentifier + obj.address.addressId === provider.providerIdentifier + provider.address.addressId
    );

    return providerObj ? true : false;
  };

  const isProviderSaved = (provider: Provider) => {
    const providerObj = savedProiders.find(
      (obj) => obj.providerIdentifier + obj.address.addressId === provider.providerIdentifier + provider.address.addressId
    );

    return providerObj ? true : false;
  };

  return (
    <div className="fwc-col-12 sm-12">
      <h2 className="btm-xxs title">
        <span id="find-your-doctor-form-heading" className={getFormHeaderClassName(useCase)}></span>
        {label}
      </h2>
      <p className="btm-sm">{description1}</p>
      {description2 && <p className="btm-sm">{description2}</p>}
      {renderCustomDescription2 && !description2 && renderCustomDescription2()}
      <div className="doctor-list-container">
        {data.map((provider, index) => {
          const isSelected = isProviderSelected(provider) || isProviderSaved(provider);
          const isSaved = isProviderSaved(provider);

          return (
            <FindYourDoctorCard
              index={index}
              key={provider.providerIdentifier + provider.address.addressId}
              provider={provider}
              showSelect={true}
              showDelete={false}
              isSelected={isSelected}
              isSaved={isSaved}
              labels={labels}
              onSelectionChange={onSelectionChange}
            />
          );
        })}
      </div>
      {parseInt(metaData.totalPageNumbers) > 1 && (
        <Pagination
          activePage={parseInt(metaData.pageNumber)}
          className=""
          onChange={(e: PaginationChange) => {
            onPageChange(e.detail);
          }}
          totalPages={parseInt(metaData.totalPageNumbers)}
        />
      )}
    </div>
  );
};

export default memo(FindYourDoctorList);
