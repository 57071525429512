import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { getCMSStaticContent } from "../../../api/cms/cmsUtil";
import FullPageLoader from "../../../components/common/full-page-loader/FullPageLoader";
import HelpMeChoosePopup from '../../../components/common/helpme-choose-popup/HelpMeChoosePopup';
import Calculator from '../../../images/Calculator.svg';
import { LANGUAGE_CODES, SCREEN_NAMES } from "../../../shared/globalConstants";
import { useGlobalStore } from "../../../store/globalStore";
import { MEDICAL_PLANS_TABS } from "../medical-plans/constants";
import './HereAreOptions.scss';
import { default as Content } from './messages.json';


function EstimateSavingsLandingPage() {
    const [pageContent, setContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const { zipCodeRes, brand, updateEstimateSavingsResponse, updateIsSubsidydataApplied, updateSelectedOptionInEstimateSavings, isSubsidydataApplied, updateHouseholdMemberValues, subsidyresponse, resetMedicalPlanStates, resetDentalPlanStates, resetVisionPlanStates } = useGlobalStore((store) => store);
    const getContent = useCallback(async (): Promise<boolean> => {
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.ESTIMATESAVINGS);
            let cmsResponse = response.data;

            setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.ESTIMATESAVINGS);
        } catch (error) {
            setContent(Content.data.iospagecontentList.items[0].pageContent.ESTIMATESAVINGS);

            return false;
        } finally {
            setContentLoaded(true);
        }
        return true;
    }, []);

    const initiate = async () => {
        await getContent();
    };

    useEffect(() => {
        initiate();
    }, []);

    return (!contentLoaded ? <FullPageLoader /> :
        <div className="fwc-row">
            <div className="fwc-col-12">
                <div className="estimateLandingPage-content" id="estimateLandingPage-content">
                    <h1 id="estimateLandingPageTitle">{pageContent?.estimateLandingPageTitle}</h1>
                    <p id="estimateLandingPageDescription" dangerouslySetInnerHTML={{ __html: pageContent?.estimateLandingPageDescription[zipCodeRes.stateCode] ? pageContent?.estimateLandingPageDescription[zipCodeRes.stateCode] : pageContent?.estimateLandingPageDescription['ALL'] }}></p>
                </div>
                <div className="discount-block">
                    <div className="estimate-options-flex dflex" id="estimate-options-flex">
                        <div className="icon-container" id="icon-container">
                            <img id="calculator-image" alt="" src={Calculator} />
                        </div>
                        <div className="estimateLandingPage-description-container">
                            <h3 id="getMonthlyDiscount-title">{pageContent?.getMonthlyDiscount.title}</h3>
                            <p id="getMonthlyDiscount-description">{pageContent?.getMonthlyDiscount.description}</p>
                        </div>
                    </div>
                </div>
                <div className="estimate-button-wrapper" id="button-wrapper">
                    <button className="fwc-btn fwc-btn-secondary" data-analytics="backBtnEstCostSavingsIos" id="backToBasics-button" onClick={() => navigate('/individuals/shop/basics')}>{pageContent?.estimateButtons.backToBasics}</button>
                    <div>
                        <button className="fwc-btn fwc-btn-secondary" data-analytics="skipBtnEstCostSavingsIos" id="skipThisStep-button" onClick={() => {
                            if (isSubsidydataApplied) {
                                updateEstimateSavingsResponse({});
                                updateHouseholdMemberValues({});
                                resetMedicalPlanStates();
                                resetDentalPlanStates();
                                resetVisionPlanStates();
                                updateIsSubsidydataApplied(false);
                            }
                            updateSelectedOptionInEstimateSavings(MEDICAL_PLANS_TABS.OFF_MARKET_PLANS);
                            setShowPopup(true)
                        }
                        }>{pageContent?.estimateButtons.skipThisStep}</button>
                        <button className="fwc-btn fwc-btn-primary" data-analytics="useCalcBtnCostSavingsIos" id="useCalculator-button" onClick={() => navigate('/individuals/shop/basics/credit-calculator')}>{pageContent?.estimateButtons.useCalculator}</button>
                    </div>
                </div>
                <div className="page-content">
                    <div dangerouslySetInnerHTML={{ __html: pageContent?.pageHtml }}></div>
                </div>
                <HelpMeChoosePopup showPopup={showPopup} popupClose={() => { setShowPopup(false) }} />
            </div>

        </div>
    )
}

export default EstimateSavingsLandingPage;
