import { PLAN_MARKET_TYPES } from '../../../components/common/compare-tray/constant';
import {
  AGENT_FLOW_CONSTANTS,
  AGENT_PARENTTIN_CONSTANTS,
  AGENT_ROLES,
  AGENT_SELECTION,
  APPLICANT_TYPES,
  APPLICATION_TYPE,
  BRAND_NAMES,
  DEFAULT_CAMPAIGNIDS,
  PLAN_TYPES,
  PLAN_TYPES_NAMES,
  SET_APPLICATION_CONSTANTS,
  SET_BRAND_NAMES,
  USER_ROLES
} from '../../../shared/globalConstants';
import { formatDateToYYYYMMDD, getRelationshipType, getcurrentAnchorPlan } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';

const getDemographic = (applicantDemoGraphic: DemographicData) => {
  const demographicData: Demographic = {
    lastName: applicantDemoGraphic.applicantDemographicInfo.lastName ? applicantDemoGraphic.applicantDemographicInfo.lastName : null,
    firstName: applicantDemoGraphic.applicantDemographicInfo.firstName,
    dateOfBirth: applicantDemoGraphic.applicantDemographicInfo.dateOfBirth,
    gender: applicantDemoGraphic.applicantDemographicInfo?.gender ? applicantDemoGraphic.applicantDemographicInfo.gender : null,
    id: 0,
    emailAddress: applicantDemoGraphic.emailAddress,
    isUseTobacco: applicantDemoGraphic.applicantDemographicInfo.isTabaccoUser ? applicantDemoGraphic.applicantDemographicInfo.isTabaccoUser : null,
    relationshipType: getRelationshipType(applicantDemoGraphic.applicantDemographicInfo.applicantType),
    action: SET_APPLICATION_CONSTANTS.UPDATE,
    yrResideInUS: 0,
    monthResideInUS: 0
  };
  return demographicData;
};

const getAddress = (address: PrimaryUserAddress[] | undefined) => {
  const applicantAddress: UserAddress[] = [];
  address?.map((item) => {
    applicantAddress.push({
      id: 0,
      addressLine1: item?.addressLine1 ? item?.addressLine1 : null,
      addressLine2: item?.addressLine2 ? item?.addressLine2 : null,
      city: item?.city ? item?.city : null,
      county: item?.county ? item?.county : null,
      state: item.state ? item.state : null,
      postalCode: item.postalCode ? item.postalCode : null,
      addressType: SET_APPLICATION_CONSTANTS.HOME,
      careOf: null,
      action: SET_APPLICATION_CONSTANTS.UPDATE,
      personalMailBoxNo: null,
      countyCode: item.countyCode ? item.countyCode : null
    });
  });
  return applicantAddress;
};

const agentAddress = (agentInfo: AgentAdress) => {
  const agentAddress: UserAddress = {
    id: 0,
    addressLine1: agentInfo?.addressLine1 ? agentInfo?.addressLine1 : null,
    addressLine2: null,
    city: agentInfo?.city ? agentInfo?.city : null,
    county: agentInfo?.county ? agentInfo?.county : null,
    state: agentInfo?.state ? agentInfo?.state : null,
    postalCode: agentInfo?.postalCode ? agentInfo?.postalCode : agentInfo?.zip ? agentInfo?.zip : null,
    addressType: null,
    careOf: null,
    action: SET_APPLICATION_CONSTANTS.UPDATE,
    personalMailBoxNo: null,
    countyCode: null
  };
  return agentAddress;
};

const getPlan = (plan: Contract, applicantType: string) => {
  const Plan: PlanDetails = {
    action: SET_APPLICATION_CONSTANTS.UPDATE,
    companyDescr: plan.companyDesc ? plan?.companyDesc : '',
    contractCode: plan.contractCode,
    eBHFlag: plan.ehbIndTypes.length > 0 ? SET_APPLICATION_CONSTANTS.YES : SET_APPLICATION_CONSTANTS.NO,
    ereAppliedAPTC: 0.0,
    id: 0,
    lifeCoverageAmt: 0.0,
    planId: plan.plan[0]?.planID,
    planName: plan.planMarketingName,
    planType: plan.plan[0]?.productType,
    premiumAmt: applicantType === SET_APPLICATION_CONSTANTS.PRIMARY ? (plan?.rateData?.rate?.totSubsidizedPrem ?? 0.0) : 0.0,
    productType: plan.plan[0]?.planType,
    qHPId: plan.qHPId,
    qHPVariation: plan.qHPVariation,
    renewalPremiumAmt: 0.0
  };
  return Plan;
};

const getPlanSelection = (planDetails: PayloadDetails, applicantType: string) => {
  const planTotalInfo: PlanDetails[] = planDetails.plan.map((item) => getPlan(item, applicantType));
  const planSelectionData: PlanSelection = {
    action: SET_APPLICATION_CONSTANTS.UPDATE,
    id: 0,
    optRider: [],
    plan: planTotalInfo
  };
  return planSelectionData;
};

const isPolicyMember = (applicantName: string) => {
  const { memberInfo } = useGlobalStore.getState();
  return memberInfo?.demographicInfo?.applicant?.find((item) => item.firstName === applicantName);
};

const getApplicantTotalInfo = (applicantDetails: PayloadDetails, hcid: string | null) => {
  const applicantInfo: ApplicantAllInfo = {
    id: 0,
    memberCode: 0,
    demographic: getDemographic(applicantDetails.demographicInfo),
    address: getAddress(applicantDetails?.address),
    planSelection: getPlanSelection(applicantDetails, applicantDetails.demographicInfo.applicantDemographicInfo.applicantType),
    priorCoverage: [],
    age: 0,
    action: SET_APPLICATION_CONSTANTS.UPDATE,
    existingPolicyId: 0,
    sequenceNo: 0,
    hcid: isPolicyMember(applicantDetails?.demographicInfo?.applicantDemographicInfo?.firstName) ? hcid : null
  };
  return applicantInfo;
};

const getPaidAgent = (agentInfo: AgentOfRecord) => {
  const PaidAgent: PaidAgent = {
    id: 0,
    agentTIN: agentInfo.paidTIN ? agentInfo.paidTIN : '',
    issuedPolicies: [],
    action: SET_APPLICATION_CONSTANTS.UPDATE
  };
  return PaidAgent;
};

const getParentAgent = (agentInfo: AgentOfRecord) => {
  const parentAgent: ParentAgent = {
    id: 0,
    agentTIN: agentInfo.parentTin ? agentInfo.parentTin : '',
    agencyName: agentInfo.agencyName ? agentInfo.agencyName : '',
    emailAddress: null,
    issuedPolicies: [],
    action: SET_APPLICATION_CONSTANTS.UPDATE
  };
  return parentAgent;
};

const agentType = (agentInfo: AgentOfRecord): string => {
  if (
    agentInfo &&
    agentInfo?.parentTin &&
    (agentInfo.parentTin === AGENT_PARENTTIN_CONSTANTS.PARENT_TIN_1E || agentInfo.parentTin === AGENT_PARENTTIN_CONSTANTS.ENCRYPTEDTIN_CAPTIVEAGENT)
  ) {
    return AGENT_ROLES.CAPTIVEAGENT;
  } else if (
    agentInfo &&
    agentInfo?.parentTin &&
    (agentInfo.parentTin === AGENT_PARENTTIN_CONSTANTS.PARENT_TIN_5E || agentInfo.parentTin === AGENT_PARENTTIN_CONSTANTS.ENCRYPTEDTIN_DIRECTSALES)
  ) {
    return AGENT_ROLES.DIRECTSALES;
  } else {
    return AGENT_ROLES.BROKER;
  }
};

const getAgentInfo = (agentInfo: AgentOfRecord) => {
  const agent: Agent = {
    id: 0,
    agentTIN: agentInfo?.writingTIN ? agentInfo?.writingTIN : (agentInfo?.agentId as string),
    firstName: agentInfo.agentFirstName ? agentInfo.agentFirstName : agentInfo.firstName ? agentInfo.firstName : '',
    lastName: agentInfo.agentLastName ? agentInfo.agentLastName : agentInfo.lastName ? agentInfo.lastName : '',
    agencyName: agentInfo.agencyName ? agentInfo.agencyName : agentInfo.agency ? agentInfo.agency : '',
    phoneNo: agentInfo.phone,
    faxNo: agentInfo.fax ? agentInfo.fax : '',
    emailAddress: agentInfo.email,
    parentAgent: getParentAgent(agentInfo),
    address: agentAddress(agentInfo?.address),
    agentType: agentType(agentInfo),
    issuedPolicies: [],
    action: SET_APPLICATION_CONSTANTS.UPDATE,
    npn: agentInfo.npn,
    licenseNumber: agentInfo.licenseNO ? agentInfo?.licenseNO : null,
    certificateNumber: agentInfo.certificateID ? agentInfo.certificateID : '',
    fFEUserId: '',
    phoneExt: agentInfo.phoneExtention ? agentInfo.phoneExtention : '',
    agentCode: agentInfo.agentCode ? agentInfo.agentCode : '',
    paidAgent: getPaidAgent(agentInfo)
  };
  return agent;
};

const getSetApplicationPayload = (applicantDetails: ApplicantPayloadDetails) => {
  const { deepLinkInfo } = useGlobalStore.getState();
  const { refID, call } = deepLinkInfo;
  let applicantTotalInfo: ApplicantAllInfo[] = applicantDetails.applicantPlanInfo.map((item) =>
    getApplicantTotalInfo(item, applicantDetails.applicationinfo.hcid)
  );

  const payload: SetApplicationPayload = {
    partnerId: SET_APPLICATION_CONSTANTS.OLS,
    user: {
      userId: applicantDetails.applicationinfo.userId,
      shopperRole: applicantDetails.applicationinfo.shopperRole
    },
    application: {
      state: applicantDetails.applicationinfo.state,
      isAgentAssisted: applicantDetails.agent ? SET_APPLICATION_CONSTANTS.YES : null,
      isAgentSubmitted: SET_APPLICATION_CONSTANTS.NO,
      premiumAmt: applicantDetails.applicationinfo.premiumAmt,
      campaignId: refID && refID !== '' ? refID : applicantDetails.applicationinfo.campaignId,
      brandName: applicantDetails.applicationinfo.brandName,
      applicationType: applicantDetails.applicationinfo.applicationType,
      reqEffDate: applicantDetails.applicationinfo.reqEffDate,
      accessControlList: [],
      agent: applicantDetails.agent ? getAgentInfo(applicantDetails.agent) : null,
      applicant: applicantTotalInfo,
      quotedPlans: [],
      pin: 0,
      action: SET_APPLICATION_CONSTANTS.UPDATE,
      additionalInfo: applicantDetails.applicationinfo.additionalInfo,
      isAddSpouse: applicantDetails.applicationinfo.isAddSpouse,
      isAddDependents: applicantDetails.applicationinfo.isAddDependents,
      initialPremium: 0.0,
      exchTotalAmtOwed: 0.0,
      exchAPTCAmt: 0.0,
      applicationSeqId: 0,
      exchTotPremiumAmt: 0.0,
      applicationLanguage: SET_APPLICATION_CONSTANTS.ENGLISH,
      aptcAttestationPersons: [],
      electedAPTCPercentage: 0,
      appliedAPTCAmount: 0.0,
      rateAreaId: 0,
      exchTransLogId: 0,
      exchAPTCPct: 0.0,
      renewalPremiumAmt: 0.0,
      currentAnchorPlan: applicantDetails.applicationinfo.currentAnchorPlan,
      currentPlanIndicator: applicantDetails.applicationinfo.currentPlanIndicator,
      ipAddress: applicantDetails.applicationinfo.ipAddress,
      cidphone: call && call !== '' ? call : undefined,
      hcid: applicantDetails.applicationinfo.hcid,
      eid: applicantDetails.applicationinfo.eid
    },
    isSubmit: null
  };
  return payload;
};

const getEncryptacnPayload = (responceAcn: string) => {
  let acnPayload = {
    acn: responceAcn
  };
  return acnPayload;
};

const isAgentInfoConsider = () => {
  const { agentInfo, macConflictSenario, memberInfo, deepLinkInfo } = useGlobalStore.getState();
  const { standaloneLoginUseCase } = deepLinkInfo;
  const { role } = useUserStore.getState();
  if (AGENT_FLOW_CONSTANTS.includes(standaloneLoginUseCase)) {
    return agentInfo &&
      agentInfo?.writingTIN &&
      agentInfo?.writingTIN !== '' &&
      ((agentInfo?.agencyName && agentInfo?.agencyName !== '') ||
        (agentInfo?.agentLastName && agentInfo?.agentLastName !== '') ||
        (agentInfo?.agentFirstName && agentInfo?.agentFirstName !== ''))
      ? agentInfo
      : null;
  } else if (deepLinkInfo?.isAgentConnectFlow) {
    return agentInfo &&
      agentInfo?.agentId &&
      agentInfo?.agentId !== '' &&
      ((agentInfo?.agencyName && agentInfo?.agencyName !== '') ||
        (agentInfo?.lastName && agentInfo?.lastName !== '') ||
        (agentInfo?.firstName && agentInfo?.firstName !== ''))
      ? agentInfo
      : null;
  } else if ((macConflictSenario.agentSelection === AGENT_SELECTION.AGENT_OF_RECORD && memberInfo?.agentOfRecord) || role === USER_ROLES.MEMBER) {
    return memberInfo?.agentOfRecord?.agentId &&
      memberInfo?.agentOfRecord?.agentId !== '' &&
      ((memberInfo?.agentOfRecord?.agency && memberInfo?.agentOfRecord?.agency !== '') ||
        (memberInfo?.agentOfRecord?.lastName && memberInfo?.agentOfRecord?.lastName !== '') ||
        (memberInfo?.agentOfRecord?.firstName && memberInfo?.agentOfRecord?.firstName !== ''))
      ? memberInfo?.agentOfRecord
      : null;
  } else if (macConflictSenario.agentSelection === AGENT_SELECTION.AGENT_CONNECT && agentInfo) {
    return agentInfo &&
      agentInfo?.agentId &&
      agentInfo?.agentId !== '' &&
      ((agentInfo?.agencyName && agentInfo?.agencyName !== '') ||
        (agentInfo?.lastName && agentInfo?.lastName !== '') ||
        (agentInfo?.firstName && agentInfo?.firstName !== ''))
      ? agentInfo
      : null;
  } else {
    return agentInfo &&
      agentInfo?.agentId &&
      agentInfo?.agentId !== '' &&
      ((agentInfo?.agencyName && agentInfo?.agencyName !== '') ||
        (agentInfo?.lastName && agentInfo?.lastName !== '') ||
        (agentInfo?.firstName && agentInfo?.firstName !== ''))
      ? agentInfo
      : null;
  }
  // in conflict senario agent selection based on userSelection
};

const isApplicantHavingPlan = (plans: SelectedPlan, applicantName: string, applicantId: string | undefined) => {
  let filterByName = plans?.applicants?.filter((applicant) =>
    applicantId && applicant.applicantId ? applicant.applicantId === applicantId : applicant.firstName === applicantName
  );
  return filterByName && filterByName?.length > 0;
};

const getApplicationType = (onExchange: boolean) => {
  if (onExchange) {
    return APPLICATION_TYPE.ON_EXCHANGE;
  }

  return APPLICATION_TYPE.OFF_EXCHANGE;
};

const isSpouseDependentAddedPlan = (plans: SelectedPlan, spouse: boolean) => {
  if (spouse) {
    return plans?.applicants.find((item) => item.applicantType === APPLICANT_TYPES.SPOUSE || item.applicantType === APPLICANT_TYPES.DOMESTICPARTNER);
  } else {
    const isDependentAdded = plans?.applicants.filter(
      (item) => ![APPLICANT_TYPES.PRIMARY, APPLICANT_TYPES.SPOUSE, APPLICANT_TYPES.DOMESTICPARTNER].includes(item.applicantType) && item
    );
    return isDependentAdded.length > 0;
  }
};

const getIsSpouseDependentAdd = (selectedPlan: SelectedPlan, selectedDentalPlan: SelectedPlan, selectedVisionPlan: SelectedPlan, spouse: boolean) => {
  if (selectedPlan?.plan && isSpouseDependentAddedPlan(selectedPlan, spouse)) {
    return SET_APPLICATION_CONSTANTS.YES;
  }
  if (selectedDentalPlan?.plan && isSpouseDependentAddedPlan(selectedDentalPlan, spouse)) {
    return SET_APPLICATION_CONSTANTS.YES;
  }
  if (selectedVisionPlan?.plan && isSpouseDependentAddedPlan(selectedVisionPlan, spouse)) {
    return SET_APPLICATION_CONSTANTS.YES;
  }
  return SET_APPLICATION_CONSTANTS.NO;
};

const getCurrentPlanIndicator = (currentCoverages: CurrentCoverages) => {
  const uniqueTypes: string[] = [...new Set(currentCoverages.map((item) => item.planType))];
  if (uniqueTypes.includes(PLAN_TYPES_NAMES.LIFE)) {
    const index = uniqueTypes.indexOf(PLAN_TYPES_NAMES.LIFE);
    uniqueTypes.splice(index, 1);
  }
  let currentPlanIndicator: string = '';
  if (uniqueTypes.includes(PLAN_TYPES_NAMES.MEDICAL)) {
    currentPlanIndicator += PLAN_TYPES.MEDICAL;
  }
  if (uniqueTypes.includes(PLAN_TYPES_NAMES.DENTAL)) {
    currentPlanIndicator += (currentPlanIndicator === '' ? '' : '|') + PLAN_TYPES.DENTAL;
  }
  if (uniqueTypes.includes(PLAN_TYPES_NAMES.VISION)) {
    currentPlanIndicator += (currentPlanIndicator === '' ? '' : '|') + PLAN_TYPES.VISION;
  }
  return currentPlanIndicator === '' ? null : currentPlanIndicator;
};

const getAdditionalInfo = (selectedPlan: SelectedPlan, selectedDentalPlan: SelectedPlan, selectedVisionPlan: SelectedPlan) => {
  let additionalInfo: string = '';
  if (selectedPlan?.plan) {
    additionalInfo += selectedPlan?.plan?.tiersType;
  }
  if (selectedDentalPlan?.plan) {
    additionalInfo += (additionalInfo === '' ? '' : ';') + selectedDentalPlan?.plan?.tiersType;
  }
  if (selectedVisionPlan?.plan) {
    additionalInfo += (additionalInfo === '' ? '' : ';') + selectedVisionPlan?.plan?.tiersType;
  }
  return additionalInfo === '' ? null : additionalInfo;
};

const getApplicantAddress = () => {
  const { memberInfo, county, zipcode, state, countyCode } = useGlobalStore.getState();
  let applicantAddress: PrimaryUserAddress[] = memberInfo.demographicInfo.applicant
    ? (memberInfo.demographicInfo.applicant.filter((item) => item.applicantType == APPLICANT_TYPES.PRIMARY)[0]?.address as PrimaryUserAddress[])
    : [];
  if (applicantAddress && applicantAddress?.length > 0 && applicantAddress[0]?.state === state) {
    applicantAddress = [
      {
        addressLine1: applicantAddress[0]?.addressLine1 || applicantAddress[0]?.addressLine1 === '' ? applicantAddress[0]?.addressLine1 : null,
        addressLine2: applicantAddress[0]?.addressLine2 || applicantAddress[0]?.addressLine2 === '' ? applicantAddress[0]?.addressLine2 : null,
        county: applicantAddress[0]?.county ? applicantAddress[0]?.county : county,
        state: applicantAddress[0]?.state ? applicantAddress[0]?.state : state,
        addressType: SET_APPLICATION_CONSTANTS.HOME,
        postalCode: applicantAddress[0]?.postalCode ? applicantAddress[0]?.postalCode : zipcode,
        countyCode: applicantAddress[0]?.countyCode ? applicantAddress[0]?.countyCode : countyCode,
        city: applicantAddress[0]?.city ? applicantAddress[0]?.city : county
      }
    ];
  } else {
    applicantAddress = [
      {
        addressLine1: null,
        addressLine2: null,
        county: county,
        state: state,
        addressType: SET_APPLICATION_CONSTANTS.HOME,
        postalCode: zipcode,
        countyCode: countyCode,
        city: null
      }
    ];
  }
  return applicantAddress;
};

const getSetApplicationData = (onExchange: boolean) => {
  const { agentInfo, selectedDentalPlan, selectedPlan, selectedVisionPlan, state, brand, countyCode, applicantFormDetails, coverageDate, memberInfo } =
    useGlobalStore.getState();
  const { loggedIn, webAccountGUID, role, demographicInfo, userEmailAddress, isWellpoint } = useUserStore.getState();

  const getTotalPremium = () => {
    let totalPremium: number = 0;
    if (selectedPlan?.plan) {
      totalPremium += selectedPlan?.plan?.rateData?.rate?.totSubsidizedPrem ?? 0;
    }
    if (selectedDentalPlan?.plan) {
      totalPremium += selectedDentalPlan?.plan?.rateData?.rate?.totalPremium ?? 0;
    }
    if (selectedVisionPlan?.plan) {
      totalPremium += selectedVisionPlan?.plan?.rateData?.rate?.totalPremium ?? 0;
    }
    return parseFloat(totalPremium.toFixed(2).toString());
  };

  let applicantDetails: PayloadDetails[] = [];

  const applicantInfo = demographicInfo?.applicant && demographicInfo?.applicant?.length > 0 ? demographicInfo?.applicant : applicantFormDetails;

  if (applicantInfo) {
    applicantInfo.map((item) => {
      let plans: Contract[] = [];
      if (selectedPlan?.plan && isApplicantHavingPlan(selectedPlan, item.firstName, item.applicantId)) {
        plans.push(selectedPlan?.plan);
      }
      if (selectedDentalPlan?.plan && isApplicantHavingPlan(selectedDentalPlan, item.firstName, item.applicantId)) {
        plans.push(selectedDentalPlan?.plan);
      }
      if (selectedVisionPlan?.plan && isApplicantHavingPlan(selectedVisionPlan, item.firstName, item.applicantId)) {
        plans.push(selectedVisionPlan?.plan);
      }

      applicantDetails.push({
        demographicInfo: { applicantDemographicInfo: item, emailAddress: userEmailAddress },
        address: getApplicantAddress(),
        plan: plans
      });
    });
  }

  const applicationinfo: ApplicationInfo = {
    userId: webAccountGUID,
    shopperRole: AGENT_ROLES.CONSUMER,
    state: state,
    premiumAmt: getTotalPremium(),
    campaignId: memberInfo.demographicInfo?.cid ? memberInfo.demographicInfo?.cid : DEFAULT_CAMPAIGNIDS[state],
    brandName: isWellpoint ? BRAND_NAMES.WELLPOINT_BRAND : brand === BRAND_NAMES.ABC ? SET_BRAND_NAMES.BCC : SET_BRAND_NAMES.ANTHEM,
    applicationType: getApplicationType(onExchange),
    reqEffDate: formatDateToYYYYMMDD(coverageDate) ?? '',
    hcid: memberInfo?.hcid ? memberInfo.hcid : null,
    eid: memberInfo.demographicInfo.eid && memberInfo.demographicInfo.eid !== '' ? memberInfo.demographicInfo.eid : null,
    ipAddress: null,
    isAddSpouse: getIsSpouseDependentAdd(selectedPlan, selectedDentalPlan, selectedVisionPlan, true),
    isAddDependents: getIsSpouseDependentAdd(selectedPlan, selectedDentalPlan, selectedVisionPlan, false),
    currentAnchorPlan: getcurrentAnchorPlan(memberInfo.currentCoverages),
    currentPlanIndicator: getCurrentPlanIndicator(memberInfo.currentCoverages),
    additionalInfo: getAdditionalInfo(selectedPlan, selectedDentalPlan, selectedVisionPlan)
  };
  return getSetApplicationPayload({ applicantPlanInfo: applicantDetails, applicationinfo: applicationinfo, agent: isAgentInfoConsider() });
};

const getSelectedPlanId = () => {
  const { selectedPlan, selectedDentalPlan } = useGlobalStore.getState();
  let selectedPlanIds = '';
  if (selectedPlan?.plan) {
    selectedPlanIds = selectedPlanIds + `&plan_hios_id=${selectedPlan?.plan?.qHPId}`;
  }
  if (selectedDentalPlan?.plan) {
    selectedPlanIds = selectedPlanIds + `&dental_plan_hios_id=${selectedDentalPlan?.plan?.qHPId}`;
  }
  return selectedPlanIds;
};

const getUpdateQuoteStatusPayload = (acn: string, quoteId: string) => {
  const payload: UpdateQuoteStatusPayload = {
    acn: acn,
    quoteId: quoteId
  };
  return payload;
};

const getMarketType = (poPlan: boolean, onExchange: boolean) => {
  if (poPlan) {
    return PLAN_MARKET_TYPES.COLARADO_CONNECT;
  } else if (onExchange) {
    return PLAN_MARKET_TYPES.MARKET_PLACE;
  } else {
    return PLAN_MARKET_TYPES.OFF_MARKET;
  }
};

const getApplicableMarketType = (medicalPlan: Contract | null, dentalPlan: Contract | null, visionPlan: Contract | null): string => {
  const primaryPlan = medicalPlan ? medicalPlan : dentalPlan ? dentalPlan : visionPlan;

  return primaryPlan ? getMarketType(primaryPlan?.poPlan, primaryPlan?.onExchange) : PLAN_MARKET_TYPES.MARKET_PLACE;
};

export {
  getAdditionalInfo,
  getApplicableMarketType,
  getApplicationType,
  getCurrentPlanIndicator,
  getEncryptacnPayload,
  getIsSpouseDependentAdd,
  getSelectedPlanId,
  getSetApplicationData,
  getSetApplicationPayload,
  getUpdateQuoteStatusPayload,
  isApplicantHavingPlan
};
