import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import './FindYourDoctorForm.scss';
import { getFormHeaderClassName } from './findYourDoctorFormUtils';

const FindYourDoctorFormComponent: React.FC<FindYourDoctorFormProps> = ({
  formHeading,
  formDescription,
  formSubHeading,
  nameConfig,
  providerConfig,
  specialisationConfig,
  specialityDetailsConfig,
  locatedWithinConfig,
  zipCodeConfig,
  moreDetailsLabel,
  moreDetailsHyperLinkText,
  moreDetailsHyperLink,
  useCase
}) => {
  const getErrorMessage = (message: string) => {
    return (
      <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="find-your-doctor-error">
        {message}
      </span>
    );
  };
  const showHelpTipContent = (message: string) => {
    return (
      <div className="tooltip-hint">
        <span className="tooltip-text">{message}</span>
      </div>
    );
  };

  return (
    <div className="fwc-col-12 sm-12 find-your-doctor-form-container">
      <h2 className="btm-xxs title">
        <span id="find-your-doctor-form-heading" className={getFormHeaderClassName(useCase)}></span>
        {formHeading}
      </h2>
      <p className="btm-sm">{formDescription}</p>
      <label className="fwc-label">{formSubHeading}</label>
      <div className="fwc-input margin-top">
        <label className="fwc-label name-label" htmlFor={'first-name-input'} id="find-your-doctor-name-label">
          {nameConfig.label1}
          <span id="find-your-doctor-form-label2" className="optional-label">{` (${nameConfig.label2})`}</span>
        </label>
        <span id="find-your-doctor-form-label3" className="btm-sm">
          {nameConfig.label3}
        </span>
        <input
          className="find-your-doctor-field"
          placeholder={nameConfig.placeHolder}
          id={'find-your-doctor-name-input'}
          onChange={nameConfig.onChange}
          onBlur={nameConfig.onBlur}
          name={'name'}
          type="text"
          value={nameConfig.value}
          onClick={nameConfig.onClick}
        />
        {nameConfig.helpTip && showHelpTipContent(nameConfig.helpTip)}
        {nameConfig.errorMessage && getErrorMessage(nameConfig.errorMessage)}
      </div>
      <div className="fwc-select-list margin-top">
        <label className="fwc-label" htmlFor="find-your-doctor-provider-select" id="find-your-doctor-provider-select-label">
          {providerConfig.label}
        </label>
        <select
          className="find-your-doctor-field"
          name="question1"
          id="find-your-doctor-provider-select"
          onChange={providerConfig.onChange}
          value={providerConfig.value}
        >
          {providerConfig.data.map((item, idx) => (
            <option id={item.id} key={item.id} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <div className="fwc-select-list margin-top">
        <label className="fwc-label" htmlFor="find-your-doctor-specialisation-select" id="find-your-doctor-specialisation-select-label">
          {specialisationConfig.label}
        </label>
        <select
          className="find-your-doctor-field"
          name="question1"
          id="find-your-doctor-specialisation-select"
          onChange={specialisationConfig.onChange}
          value={specialisationConfig.value}
        >
          {specialisationConfig.data.map((item, idx) => (
            <option id={item.id} key={item.id} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <div className="speciality-details-container">
        <span id={'find-your-doctor-form-details-toggle'} className="speciality-details-link" onClick={specialityDetailsConfig.onToggle}>
          {specialityDetailsConfig.label}
        </span>
        {specialityDetailsConfig.visible && (
          <div className="speciality-details-wrapper">
            <p id={'find-your-doctor-form-details'} className="btm-sm">
              {' '}
              {specialityDetailsConfig.description}
            </p>
          </div>
        )}
      </div>
      <div className="location-container margin-top">
        <div className="fwc-select-list located-witin-select">
          <label className="fwc-label" htmlFor="find-your-doctor-within-select" id="find-your-doctor-within-select-label">
            {locatedWithinConfig.label}
          </label>
          <select name="question1" id="find-your-doctor-within-select" onChange={locatedWithinConfig.onChange} value={locatedWithinConfig.value}>
            {locatedWithinConfig.data.map((item, idx) => (
              <option id={item.id} key={idx} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div className="fwc-input zipcode-input">
          <div className="zipcode-input-container zipcode-container-position">
            <input
              className="input-margin-bottom"
              id={'zipcode-input'}
              name={'name'}
              type="text"
              value={zipCodeConfig.value}
              onBlur={zipCodeConfig.onBlur}
              onChange={zipCodeConfig.onChange}
              disabled={true}
            />
            <span id="location-icon" className={'sae-icon sae-icon-marker zipcode-icon'} />
            {zipCodeConfig.errorMessage && (
              <span role="alert" className="fwc-inline-icon fwc-icon-delete error-position" id="find-your-doctor-error">
                {zipCodeConfig.errorMessage}
              </span>
            )}
          </div>
        </div>
        {zipCodeConfig.loading && <div className="loader" />}
      </div>
      <p className="btm-sm margin-top">
        {moreDetailsLabel}
        <Link className="more-hyper-link" target="_blank" to={moreDetailsHyperLink}>
          {moreDetailsHyperLinkText}
        </Link>
      </p>
    </div>
  );
};

const FindYourDoctorForm = memo(FindYourDoctorFormComponent);

export default FindYourDoctorForm;
