import { StateCreator } from 'zustand';

import { DENTAL_SORT_OPTIONS } from '../pages/public/dental-plans/constants';
import { MEDICAL_PLANS_TABS } from '../pages/public/medical-plans/constants';

export const createDentalPlansSlice: StateCreator<DentalPlanSlice> = (set) => ({
  dentalPlans: [],
  comparedDentalPlans: [],
  selectedDentalPlan: {
    plan: null,
    applicants: [],
    matchedProviders: []
  },
  dentalSortOptions: DENTAL_SORT_OPTIONS,
  selectedDentalPlanTab: MEDICAL_PLANS_TABS.OFF_MARKET_PLANS,
  favouriteDentalPlans: [],
  showFavourites: false,
  currentCoverageDentalPlan: {} as Contract,
  mappedDentalContractCode: '',
  updateDentalPlans: (plans: Contracts) => set(() => ({ dentalPlans: plans })),
  updateComparedDentalPlans: (plans: ComparedPlans) => set(() => ({ comparedDentalPlans: plans })),
  updateSelectedDentalPlan: (selectedPlan: SelectedPlan) => set(() => ({ selectedDentalPlan: selectedPlan })),
  updateDentalSortOptions: (sortOptions: SortOption[]) => set(() => ({ dentalSortOptions: sortOptions })),
  updateSelectedDentalPlanTab: (tab: string) => set(() => ({ selectedDentalPlanTab: tab })),
  updateFavouriteDentalPlans: (favouritePlansContractCodes: string[]) => set(() => ({ favouriteDentalPlans: favouritePlansContractCodes })),
  updateShowFavourites: (value: boolean) => set(() => ({ showFavourites: value })),
  updateCurrentCoverageDentalPlan: (plan: Contract, mappedContractCode: string) =>
    set(() => ({ currentCoverageDentalPlan: plan, mappedDentalContractCode: mappedContractCode })),
  resetDentalPlanStates: () =>
    set(() => ({
      dentalPlans: [],
      comparedDentalPlans: [],
      selectedDentalPlan: {
        plan: null,
        applicants: [],
        matchedProviders: []
      },
      dentalSortOptions: DENTAL_SORT_OPTIONS,
      selectedDentalPlanTab: MEDICAL_PLANS_TABS.OFF_MARKET_PLANS,
      favouriteDentalPlans: [],
      showFavourites: false
    })),
  resetCurrentCoverageDentalPlan: () =>
    set(() => ({
      currentCoverageDentalPlan: {} as Contract,
      mappedDentalContractCode: ''
    }))
});
