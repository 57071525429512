
import { Col, PageHeader, PreLoader, Row, Title } from "@sydney-broker-ui/ios";
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCMSStaticContent } from "../../../api/cms/cmsUtil";
import DeleteApplicationPopup from "../../../components/common/delete-application-popup/DeleteApplicationPopup";
import GlobalFooter from '../../../components/common/global-footer/GlobalFooter';
import GlobalHeader from '../../../components/common/global-header/GlobalHeader';
import PublicNavBar from '../../../components/common/public-nav-bar/PublicNavBar';
import { APPLICATION_STATUSES, CANCELLED_APPLICATION_STATUSES, IN_PROGRESS_APPLICATION_STATUSES, LANGUAGE_CODES, NAV_PATHS, SCREEN_NAMES, USER_DOMAINS, USER_ROLES } from '../../../shared/globalConstants';
import { useGlobalStore } from "../../../store/globalStore";
import { useUserStore } from '../../../store/userStore';
import './Dashboard.scss';
import DashboardCard from "./DashboardCard/DashboardCard";
import { CONTINUE_APPLICATION } from './DashboardCard/constants';
import { PAYLOAD_INPUT } from './constants';
import { default as Content } from './dashboard.json';
import { getDashboardApplications } from './dashboardServices';
import { getDashboardApplicationListingsPayload } from './dashboardUtils';

function Dashboard() {
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const { brand, zipCodeRes } = useGlobalStore((store) => store);
  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.DASHBOARD);
      content = response.data.data.iospagecontentList.items[0].pageContent?.Dashboard;
    } catch (error) {
      content = Content.data.iospagecontentList.items[0].pageContent.Dashboard;
      return false;
    } finally {
      setPageContent(content);
      setContentLoaded(true);
    }
    return true;
  }, []);

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    initiate();
  }, []);
  const navigate = useNavigate();
  const [apps, setApps] = useState<Array<getApplicationsList>>([]);
  const { webAccountGUID, demographicInfo, role } = useUserStore((state) => state);
  const [loading, setLoading] = useState(true);
  const [deletedInProgress, setDeletedInProgress] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [continueDelete, setContinueDelete] = useState(false);
  const [loadGetApp, setLoadGetApp] = useState(false);
  const { applications, updateApplications } = useGlobalStore(state => state)
  const [key, setKey] = useState(0);

  useEffect(() => {
    getAppListing();

  }, [])

  useEffect(() => {
    if (loadGetApp) {
      getAppListing();
      getInProgressApplications(apps);
      getSumbittedApplications(apps);
      reloadComponent();
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [loadGetApp])

  const reloadComponent = () => {
    setKey(prevKey => prevKey + 1);
  };

  const getInProgressApplications = (apps: Array<getApplicationsList>) => {
    return apps.filter((eachApp) => IN_PROGRESS_APPLICATION_STATUSES.includes(eachApp.applicationStatus));
  }
  const getSumbittedApplications = (apps: Array<getApplicationsList>) => {
    return (apps.filter((eachApp) => !IN_PROGRESS_APPLICATION_STATUSES.includes(eachApp.applicationStatus))).filter((each) => !CANCELLED_APPLICATION_STATUSES.includes(each.applicationStatus));
  }

  const getAppListing = () => {
    const payload: getApplicationListingsPayload = getDashboardApplicationListingsPayload(webAccountGUID, USER_DOMAINS.APP, PAYLOAD_INPUT.PARTNETID, PAYLOAD_INPUT.NOOFAPPLICATIONPERPAGE, PAYLOAD_INPUT.PAGENUMBER);
    getDashboardApplications(payload).then((response) => {
      const data: getApplicationListingsResponse = response.data;
      setApps(data.response.applications);
      updateApplications(data.response.applications);
      setLoading(false);
      setLoadGetApp(false);
    })
  }

  const handleReturnToShopping = () => {
    if (role === USER_ROLES.MEMBER) {
      navigate(NAV_PATHS.BASICS_LANDING);
    } else navigate(NAV_PATHS.INDIVIDUALS);
  }

  return (!contentLoaded ? <PreLoader /> :
    <div>
      <div className={'dashboard-container'}>
        <GlobalHeader notClickable={true} />
        <div className={'header-container'}>
          <PageHeader>
            <Row alignItems="center">
              <Col
                bottomMargin
                lg="5"
                sm="12"
              >
                <Title>
                  {pageContent?.HOME}
                </Title>
              </Col>
              <Col
                lg="7"
                sm="12"
              >
              </Col>
            </Row>
          </PageHeader>
          <PublicNavBar hideSaveAndResumeLater={false} />
        </div>
        <div className={'fwc-container'}>
          <div className={'fwc-row j-center'}>
            <div>
              {!loading && <div>
                {!deletedInProgress && getInProgressApplications(apps).length > 0 &&
                  <div>
                    {getInProgressApplications(apps).map((eachCard, index) =>
                      <div className={'btm-md'} key={eachCard.acn + '-inprogress'}>
                        {eachCard.applicationType === CONTINUE_APPLICATION.ONEXCHANGE && eachCard.state === CONTINUE_APPLICATION.KY ? <h2 className={'btm-xs top-md'}>{pageContent?.KY_ONEXCHANGE_INPROGRESS_HEADER}</h2> : eachCard.applicationStatus === APPLICATION_STATUSES.PENDATTESTATION ? <h2 className={'btm-xs top-md'}>{pageContent?.PENDING_APPLICATION_HEADER}</h2> : <h2 className={'btm-xs top-md'}>{pageContent?.INPROGRESS_HEADER}</h2>}
                        <DashboardCard
                          key={key}
                          cardData={eachCard}
                          index={index}
                          setDeletedInProgress={setDeletedInProgress}
                          setDeletePopUp={setDeletePopUp}
                          continueDelete={continueDelete}
                          setLoadGetApp={setLoadGetApp}
                          setLoading={setLoading}
                        />
                        <div />
                      </div>)}
                  </div>
                }
                {(continueDelete || getInProgressApplications(apps).length === 0) &&
                  <div>
                    <div className={'fwc-row btm-md left-xxs top-sm'}>
                      <span className={'top-xxs right-xs update-selection'}>{pageContent?.UPDATE_SELECTIONS}</span> <button className={'fwc-btn fwc-btn-primary'} data-analytics="returnShoppingBtnAppDb" onClick={() => handleReturnToShopping()}>{pageContent?.RETURN_SHOPPING}</button>
                    </div>
                  </div>
                }

                {getSumbittedApplications(apps).length > 0 &&
                  <div>
                    <h2 className={'btm-xs'}>{pageContent?.SUBMIT_HEADER}</h2>
                    {getSumbittedApplications(apps).sort((a) => (a.applicationStatus === APPLICATION_STATUSES.PENDINGVALIDATION || a.applicationStatus === APPLICATION_STATUSES.SUBMITTED) ? -1 : 1).map((eachCard, index) =>
                      <div className={'btm-md'} key={eachCard.acn + '-submitted'}>
                        <DashboardCard
                          key={key}
                          cardData={eachCard}
                          index={index}
                        />
                        <div />
                      </div>)}
                  </div>
                }
              </div>}
              {loading && <PreLoader />}
              <div className={'btm-sm'}>
                {pageContent?.FOOTER_CONTENT}
              </div>
            </div>
          </div>
          <div>
            <DeleteApplicationPopup showPopup={deletePopUp} deleteProposal={false} popupClose={() => setDeletePopUp(false)} confirmDelete={() => setContinueDelete(true)} />
          </div>
        </div>
        <GlobalFooter />
      </div>
    </div>);
}
export default Dashboard;
