import { StateCreator } from 'zustand';

export const createHelpMeChooseStore: StateCreator<HelpMeChooseStore> = (set, get) => ({
  hmcFilter: {
    questionaires: []
  },
  updateHmcFilter: (newHmcFilter: HmcFilter) => set({ hmcFilter: newHmcFilter }),
  resetHmcFilter: () =>
    set({
      hmcFilter: {
        questionaires: []
      }
    })
});
