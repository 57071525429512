import { Button, ModalSlide } from '@sydney-broker-ui/ios';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { getZipcodeValidation } from '../../../api/services/utilService';
import FindYourDoctorList from '../../../components/common/find-your-doctor-list/FindYourDoctorList';
import FindYourDoctoSelectedDoctors from '../../../components/common/find-your-doctor-selected-doctors/FindYourDoctoSelectedDoctors';
import FindYourEyeDoctorForm from '../../../components/common/find-your-eye-doctor-form/FindYourEyeDoctorForm';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import InfoBar from '../../../components/common/info-bar/InfoBar';
import NoResultsFound from '../../../components/common/no-results-found/NoResultsFound';
import { ERROR_ALERT_CONFIG, FINDCARE_URL_ANTHEM, FINDCARE_URL_WELLPOINT, LANGUAGE_CODES, SCREEN_NAMES, USER_DOMAINS, US_ZIP_CODE_REGX, ZIP_CODE_VALIDATION_SUCCESS_CODE } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import {
  ALPHA_REGX_WITH_NONUM,
  LOCATED_WITHIN_TYPES,
  PROVIDER_TYPES_SERVICES,
  PROVIDER_TYPES_VISION,
  PROVIDER_TYPE_USECASES,
  SECONDARY_BUTTON_HIDDEN_STATES,
  SLIDER_STATES,
  SPECIALITIES
} from '../find-your-doctor/constants';
import { EYEDOCTORS_NOT_FOUND_LABELS, EYEDOCTOR_NAME_LABELS } from './constants';
import { default as Content } from './findYourEyeDoctor.json';
import { searchEyeDoctors } from './findYourEyeDoctorServices';
import {
  getDefaultServicesValue,
  getDefaultSpecialisationValueByProviderType,
  getLocatedWithin,
  getPrimaryButtonText,
  getProviderTypes,
  getSearchProviderPayload,
  getServicesList,
  getSpecialisationByProviderType,
  getSpecialisationLabel,
  getSpecialities,
  getUpdatedSelectedProviders
} from './findYourEyeDoctorUtils';
const FindYourEyeDoctorSlider: React.FC<FindYourEyeDoctorProps> = ({ showFindYourEyeDoctor, contractCodes, closeFindYourEyeDoctor }) => {
  const [locatedWithinTypes, setLocatedWithinTypes] = useState(LOCATED_WITHIN_TYPES);
  const [providerType, setProviderType] = useState<string>('');
  const [specialisationTypes, setSpecialisationTypes] = useState<SelectOptionsString[]>([]);
  const [specialisation, setSpecialisation] = useState<string>('');
  const [providerTypesVision, setProviderTypesVision] = useState<SelectOptionsString[]>(PROVIDER_TYPES_VISION);
  const [specialities, setSpecialities] = useState<Specialities>(SPECIALITIES);
  const [servicesList, setServicesList] = useState<SelectOptionsString[]>([]);
  const [services, setServices] = useState('');
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.FIND_YOUR_EYE_DOCTOR);
      content = response.data.data.iospagecontentList.items[0].pageContent?.FindYourEyeDoctor;
    } catch (error) {
      content = Content.data.iospagecontentList.items[0].pageContent?.FindYourEyeDoctor;
      return false;
    } finally {
      setLocatedWithinTypes(getLocatedWithin(LOCATED_WITHIN_TYPES, content.LOCATED_WITHIN_TYPES));
      setProviderTypesVision(getProviderTypes(PROVIDER_TYPES_VISION, content));
      setProviderType(providerTypesVision[0].value);
      setSpecialities(getSpecialities(SPECIALITIES, content.SPECIALIZATION_TYPES, content.SPECIALISATION_DESCRIPTIONS));
      setSpecialisation(specialities.ALL.label);
      setServices(getDefaultServicesValue(PROVIDER_TYPES_SERVICES[0].value, specialities));
      setServicesList(getServicesList(content.SERVICES, specialities));
      setPageContent(content);
      setContentLoaded(true);
    }
    return true;
  }, [showFindYourEyeDoctor]);

  const initiate = async () => {
    await getContent();
  };
  useEffect(() => {
    initiate();
  }, [showFindYourEyeDoctor]);
  const [sliderState, setSliderState] = useState(SLIDER_STATES.SEARCH_DOCTORS);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [showSpecialityDetails, setShowSpecialityDetails] = useState(false);
  const {
    zipcode,
    state,
    brand,
    zipCodeRes,
    county,
    countyCode,
    year,
    planTypes,
    updateCoverageDate,
    coverageDates,
    coverageDate,
    updateCoverageType,
    savedEyeDoctors,
    agentSSOInfo,
    agentZipcode,
    agentZipCodeRes,
    agentState,
    agentBrand,
    agentCoverageDate,
    updateSavedEyeDoctors,
    updateMatchEyeDoctorsToPlansStatus
  } = useGlobalStore((store) => store);

  const { isAtk } = agentSSOInfo
  const zipCodeValue = isAtk ? agentZipcode : zipcode;
  const zipCodeResponse = isAtk ? agentZipCodeRes : zipCodeRes;
  const stateCode = isAtk ? agentState : state;
  const brandValue = isAtk ? agentBrand : brand;
  const coverageEffectiveDate = isAtk ? agentCoverageDate : coverageDate;


  const [locatedWithin, setLocatedWithin] = useState('20');
  const [zipCode, setZipCode] = useState(zipCodeValue);
  const [zipCodeObj, setZipCodeObj] = useState<ZipcodeObject>({
    code: zipCodeValue,
    state: stateCode,
    latitude: zipCodeResponse.latitude.toString(),
    longitude: zipCodeResponse.longitude.toString(),
    brand: brandValue
  });
  const [validZipCode, setValidZipCode] = useState(true);
  const [zipCodeError, setZipCodeError] = useState('');
  const [metaData, setMetaData] = useState({
    totalElements: 0,
    pageNumber: '1',
    totalPageNumbers: '1'
  });
  const [providers, setProviders] = useState<Provider[]>([]);
  const [selectedProviders, setSelectedProviders] = useState<Provider[]>([]);
  const [showFooterError, setShowFooterError] = useState(false);
  const [showPageLoader, setShowPageLoader] = useState(false);
  const [zipCodeLoader, setZipCodeLoader] = useState(false);
  const [showHelptip, setShowHelptip] = useState(false);
  const [selectedSpecialties, setSelectedSpecialties]: any = useState([]);

  const [helpTipContent, setHelpTipContent]: any = useState();

  const updateSpecialisations = useCallback(() => {
    if (pageContent) {
      let services = getServicesList(pageContent.SERVICES, specialities);
      setServicesList(services);
      const updatedSpecialisations = getSpecialisationByProviderType(providerType, providerTypesVision, specialities);
      setSpecialisationTypes(updatedSpecialisations);
      setSpecialisation(getDefaultSpecialisationValueByProviderType(providerType, providerTypesVision, specialities));
    }

  }, [providerType]);

  const resetFilters = useCallback(() => {
    // setName('');
    setProviderType(PROVIDER_TYPES_VISION[0].value);
    setSpecialisation(getDefaultSpecialisationValueByProviderType(providerTypesVision[0].value, providerTypesVision, specialities));
    // setServices(getDefaultServicesValue(PROVIDER_TYPES_SERVICES[0].value));
    setSelectedSpecialties([]);
    setLocatedWithin('20');
    setZipCode(zipCodeValue);
    setZipCodeObj({
      code: zipCodeValue,
      state: stateCode,
      latitude: zipCodeResponse.latitude.toString(),
      longitude: zipCodeResponse.longitude.toString(),
      brand: brandValue
    });
    setMetaData({
      totalElements: 0,
      pageNumber: '1',
      totalPageNumbers: '1'
    });
    setProviders([]);
    setSelectedProviders([]);
  }, []);

  const resetErrors = useCallback(() => {
    setNameError('');
    setZipCodeError('');
  }, []);

  const { isWellpoint } = useUserStore.getState();
  const FINDCARE_URL = isWellpoint ? FINDCARE_URL_WELLPOINT : FINDCARE_URL_ANTHEM;

  useEffect(() => {
    if (showFindYourEyeDoctor && savedEyeDoctors.length > 0) {
      setSliderState(SLIDER_STATES.DOCTORS_SELECTED);
    } else if (pageContent && showFindYourEyeDoctor && sliderState === SLIDER_STATES.SEARCH_DOCTORS) {
      resetFilters();
      resetErrors();
    }
  }, [resetErrors, resetFilters, showFindYourEyeDoctor]);

  useEffect(() => {
    if (pageContent) {
      updateSpecialisations();
    }

  }, [providerType, services, updateSpecialisations]);

  const handleSliderClose = useCallback(() => {
    closeFindYourEyeDoctor();
  }, [closeFindYourEyeDoctor]);

  const handleSearch = useCallback(
    (pageNumber = metaData.pageNumber) => {
      //Make an API call to get list of doctors
      setShowPageLoader(true);
      const payload: SearchEyeDoctorsPayload = getSearchProviderPayload(
        pageNumber,
        zipCodeObj,
        providerType,
        locatedWithin,
        name,
        contractCodes,
        selectedSpecialties,
        coverageEffectiveDate
      );

      searchEyeDoctors(payload)
        .then((response) => {
          const data: SearchEyeDoctorsResponse = response.data;
          const providerList = data.providerList;

          if (providerList.length === 0 && pageNumber === '1') {
            setSliderState(SLIDER_STATES.DOCTOR_LIST_UNAVAILABLE);
          } else {
            setMetaData({
              totalElements: parseInt(data.totalRowCount),
              pageNumber: data.requestPageNumber,
              totalPageNumbers: data.totalPages
            });
            setProviders(providerList);
            setSliderState(SLIDER_STATES.DOCTOR_LIST_AVAILABLE);
          }

          setShowPageLoader(false);
        })
        .catch((error) => {
          console.warn('searchProviders error :', error);
          setSelectedProviders([]);
          setSliderState(SLIDER_STATES.DOCTOR_LIST_UNAVAILABLE);
          setShowPageLoader(false);
        });
    },
    [locatedWithin, metaData.pageNumber, name, providerType, selectedSpecialties, zipCodeObj]
  );

  const handlePgination = useCallback(
    (pageDetails: PaginationChangeDetails) => {
      handleSearch(pageDetails.activePage.toString());
    },
    [handleSearch]
  );

  const validateZipCode = useCallback(async (): Promise<boolean> => {
    if (validZipCode) {
      return Promise.resolve(true);
    } else {
      if (US_ZIP_CODE_REGX.test(zipCode)) {
        setZipCodeLoader(true);

        const payload: ZipCodeValidationPayload = {
          zipCode: zipCode,
          marketSegment: USER_DOMAINS.MARKET_SEGMENT
        };

        try {
          const response = await getZipcodeValidation(payload);
          const data: ZipCodeValidationResponse = response.data;

          if (data.zipCodeResponse.responseMessage.responseCode === ZIP_CODE_VALIDATION_SUCCESS_CODE) {
            const { zipCodeResponse } = data;
            setZipCodeObj({
              code: zipCode,
              state: zipCodeResponse.zipCode.stateCode,
              latitude: zipCodeResponse.zipCode.latitude.toString(),
              longitude: zipCodeResponse.zipCode.longitude.toString(),
              brand: zipCodeResponse.zipCode.countyList.county[0].brand
            });
            setValidZipCode(true);
            setZipCodeLoader(false);
            setZipCodeError('');

            return true;
          } else {
            setZipCodeError(pageContent?.INVALID_ZIPCODE);
            setZipCodeLoader(false);

            return false;
          }
        } catch (error) {
          setZipCodeError(pageContent?.INVALID_ZIPCODE);
          setZipCodeLoader(false);

          return false;
        }
      } else {
        if (zipCode.length === 0) {
          setZipCodeError(pageContent?.REQUIRED_FIELD);
        } else {
          setZipCodeError(pageContent?.INVALID_ZIPCODE);
        }

        return Promise.resolve(false);
      }
    }
  }, [validZipCode, zipCode]);

  const validateName = useCallback(
    (doctor_name) => {
      let name = doctor_name;
      if (name.length === 0) {
        setNameError('');
        return true;
      } else {
        if (name.length < 3 && name.length > 0) {
          setNameError(pageContent?.INVALID_NAME);
          return false;
        } else if (name.length > 0 && !ALPHA_REGX_WITH_NONUM.test(name)) {
          setNameError(pageContent?.INVALID_NAME_DIGIT);
          return false;
        } else {
          setNameError('');
          return true;
        }
      }
    },
    [name]
  );

  const validateInputs = useCallback(async () => {
    const isValidZipCode = await validateZipCode();
    const isValidName = validateName(name);

    if (isValidZipCode && isValidName) {
      handleSearch();
    }
  }, [validateZipCode, handleSearch, validateName]);

  const handleSecondaryButtonClick = useCallback(() => {
    switch (sliderState) {
      case SLIDER_STATES.DOCTOR_LIST_AVAILABLE:
      case SLIDER_STATES.DOCTORS_SELECTED:
        setSliderState(SLIDER_STATES.SEARCH_DOCTORS);
        break;
      default:
        break;
    }
  }, [sliderState]);

  const addEyeDoctorsToMyList = useCallback(() => {
    if (selectedProviders.length > 0) {
      const updatedSavedProviders = Array.from(new Set([...savedEyeDoctors, ...selectedProviders]));

      updateSavedEyeDoctors(updatedSavedProviders);
      setShowFooterError(false);
      setSliderState(SLIDER_STATES.DOCTORS_SELECTED);
    } else {
      setShowFooterError(true);
    }
  }, [savedEyeDoctors, selectedProviders, updateSavedEyeDoctors]);

  const matchDoctorsWithPlans = useCallback(() => {
    updateMatchEyeDoctorsToPlansStatus(false);
    if (savedEyeDoctors.length) {
      updateMatchEyeDoctorsToPlansStatus(true);
    } else {
      setSliderState(SLIDER_STATES.SEARCH_DOCTORS);
      updateMatchEyeDoctorsToPlansStatus(false);
    }

    handleSliderClose();
  }, [handleSliderClose, savedEyeDoctors.length, updateMatchEyeDoctorsToPlansStatus]);

  const handlePrimaryButtonClick = useCallback(() => {
    switch (sliderState) {
      case SLIDER_STATES.SEARCH_DOCTORS:
        validateInputs();
        break;
      case SLIDER_STATES.DOCTOR_LIST_UNAVAILABLE:
        setSliderState(SLIDER_STATES.SEARCH_DOCTORS);
        break;
      case SLIDER_STATES.DOCTOR_LIST_AVAILABLE:
        addEyeDoctorsToMyList();
        break;
      case SLIDER_STATES.DOCTORS_SELECTED:
        matchDoctorsWithPlans();
        break;
      default:
        break;
    }
  }, [addEyeDoctorsToMyList, sliderState, validateInputs]);

  const handleSelectionChange = useCallback(
    (provider: Provider, isSelected: boolean) => {
      setSelectedProviders(getUpdatedSelectedProviders(provider, isSelected, selectedProviders));
    },
    [selectedProviders]
  );

  const handleRemove = useCallback(
    (provider: Provider, isSelected: boolean) => {
      updateSavedEyeDoctors(getUpdatedSelectedProviders(provider, isSelected, savedEyeDoctors));
    },
    [savedEyeDoctors, updateSavedEyeDoctors]
  );
  const handleInputClick = useCallback(() => {
    if (!showHelptip) {
      setShowHelptip(true);
      setHelpTipContent(pageContent?.HELPTIP_CONTENT_SEARCH_FIELD);
      return true;
    } else setHelpTipContent('');
    return false;
  }, []);

  const handleCheckboxChange = useCallback(
    (event) => {
      let isSelected = event.target.checked;
      let checkedId: any = event.target.value;

      if (isSelected) {
        setSelectedSpecialties([...selectedSpecialties, checkedId]);
      } else setSelectedSpecialties(selectedSpecialties.filter((id) => id !== checkedId));
    },
    [selectedSpecialties]
  );

  const renderDynamicDescription = () => {
    return (
      <span className="btm-sm">
        {pageContent?.THERE_ARE}
        <b>{` ${metaData.totalElements} ${pageContent?.DOCTORS} `}</b>
        {selectedSpecialties.length !== 0 ? pageContent?.WHO_SPECIALISES_IN + ' ' : ' '}
        {selectedSpecialties &&
          selectedSpecialties.map((item, index) => {
            return (
              <b>
                {getSpecialisationLabel(item, specialisationTypes)}

                {index !== selectedSpecialties.length - 1 ? ', ' : ' '}
              </b>
            );
          })}
        {pageContent?.WITHIN + ' '}
        <b>{`${locatedWithin} ${pageContent?.MILES} `}</b>
        {pageContent?.OF_THE + ' '}
        <b>{zipCode + ' '}</b>
        {pageContent?.ZIP_CODE}
      </span>
    );
  };

  const renderFooterButtons = () => {
    return (
      <div>
        <span className="right-xs">
          <Button
            btnType="secondary"
            id="modal-slide-cancel"
            onClick={() => {
              handleSliderClose();
            }}
          >
            {sliderState === SLIDER_STATES.DOCTOR_LIST_UNAVAILABLE ? pageContent?.OK : pageContent?.CANCEL}
          </Button>
        </span>
        {!SECONDARY_BUTTON_HIDDEN_STATES.includes(sliderState) && (
          <span className="right-xs">
            <Button
              btnType="secondary"
              id="modal-slide-cancel"
              disabled={zipCodeLoader}
              onClick={() => {
                handleSecondaryButtonClick();
              }}
            >
              {sliderState === SLIDER_STATES.DOCTOR_LIST_AVAILABLE ? pageContent?.SEARCH_AGAIN : pageContent?.SEARCH_FOR_ANOTHER_DOCTOR}
            </Button>
          </span>
        )}
        <span className="right-xs">
          <Button
            id="modal-slide-submit"
            onClick={() => {
              handlePrimaryButtonClick();
            }}
          >
            {getPrimaryButtonText(sliderState, pageContent)}
          </Button>
        </span>
      </div>
    );
  };

  const getModalSliderBody = () => {
    if (pageContent) {
      switch (sliderState) {
        case SLIDER_STATES.SEARCH_DOCTORS:
          return (
            <FindYourEyeDoctorForm
              formHeading={pageContent?.FIND_YOUR_DOCTOR}
              formDescription={pageContent?.DOCTORS_SHOWN}
              formSubHeading={pageContent?.ALL_FIELDS_REQUIRED}
              specialityDetails={[]}
              nameConfig={{
                ...EYEDOCTOR_NAME_LABELS(pageContent),
                value: name,
                errorMessage: nameError,
                helpTip: helpTipContent,
                onChange: ({ target }) => {
                  validateName(target.value);
                  setName(target.value);
                },
                onBlur: ({ target }) => {
                  validateName(target.value);
                  setHelpTipContent('');
                  setShowHelptip(false);
                },
                onClick: () => handleInputClick()
              }}
              providerConfig={{
                label: pageContent?.PROVIDER_TYPE,
                data: providerTypesVision,
                value: providerType,
                onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                  setProviderType(e.target.value);
                }
              }}
              specialisationConfig={{
                label: pageContent?.SPECIALTIES,
                data: specialisationTypes,
                value: specialisation,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  handleCheckboxChange(e);
                }
              }}
              servicesConfig={{
                label: pageContent?.SERVICES_LABEL,
                data: servicesList,
                value: services,
                onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                  setServices(e.target.value);
                }
              }}
              locatedWithinConfig={{
                label: pageContent?.LOCATED_WITHIN,
                data: locatedWithinTypes,
                value: locatedWithin,
                onChange: (e: React.ChangeEvent<HTMLSelectElement>) => setLocatedWithin(e.target.value)
              }}
              zipCodeConfig={{
                value: zipCode,
                errorMessage: zipCodeError,
                loading: zipCodeLoader,
                onChange: (e: { target: { value: React.SetStateAction<string> } }) => {
                  setValidZipCode(false);
                  setZipCode(e.target.value);
                },
                onBlur: () => validateZipCode()
              }}
              moreDetailsLabel={pageContent?.FOR_MORE_DETAILS}
              moreDetailsHyperLinkText={pageContent?.TRY_LOOKING}
              moreDetailsHyperLink={pageContent?.FIND_CARE}
              useCase={PROVIDER_TYPE_USECASES.VISION}
            />
          );
        case SLIDER_STATES.DOCTOR_LIST_UNAVAILABLE:
          return <NoResultsFound {...EYEDOCTORS_NOT_FOUND_LABELS(pageContent)} />;

        case SLIDER_STATES.DOCTOR_LIST_AVAILABLE:
          return (
            <FindYourDoctorList
              label={pageContent?.DOCTOR_NAME_MATCH}
              description1={pageContent?.PLEASE_SELECT}
              metaData={metaData}
              data={providers}
              selectedProviders={selectedProviders}
              savedProiders={savedEyeDoctors}
              labels={{
                MORE: pageContent?.SHOW_MORE,
                LESS: pageContent?.SHOW_LESS,
                ACCEPT_NEW_PATIENT: pageContent?.ACCEPT_NEW_PATIENTS,
                MILES: pageContent?.MILES
              }}
              renderCustomDescription2={() => renderDynamicDescription()}
              onSelectionChange={(provider: Provider, isSelected: boolean) => {
                handleSelectionChange(provider, isSelected);
              }}
              onPageChange={(pageDetails: PaginationChangeDetails) => {
                handlePgination(pageDetails);
              }}
              useCase={PROVIDER_TYPE_USECASES.VISION}
            />
          );
        case SLIDER_STATES.DOCTORS_SELECTED:
          return (
            <FindYourDoctoSelectedDoctors
              title={pageContent?.DOCTOR_LIST}
              label1={pageContent?.IF_YOU_WANT_TO}
              label2={pageContent?.PLEASE_REMEMBER}
              label3={pageContent?.FOLLOWING_IS_THE_LIST}
              labels={{
                MORE: pageContent?.SHOW_MORE,
                LESS: pageContent?.SHOW_LESS,
                ACCEPT_NEW_PATIENT: pageContent?.ACCEPT_NEW_PATIENTS,
                MILES: pageContent?.MILES,
                REMOVE: pageContent?.REMOVE
              }}
              selectedProviders={savedEyeDoctors}
              onSelectionChange={(provider: Provider, isSelected: boolean) => handleRemove(provider, isSelected)}
              useCase={PROVIDER_TYPE_USECASES.VISION}
            />
          );
        default:
          return <div />;
      }
    }

  };
  return (!contentLoaded ? <FullPageLoader /> :
    <ModalSlide direction={'right'} open={showFindYourEyeDoctor} onClose={() => handleSliderClose()}>
      <ModalSlide.Body>
        <div className={'slider-container'}>
          {showPageLoader && <FullPageLoader />}
          <div className="fwc-row fwc-row-wrap mgd-menu-row">{getModalSliderBody()}</div>
        </div>
      </ModalSlide.Body>
      {showFooterError && (
        <div className="error-container">
          <InfoBar
            backgroundColor={'error'}
            area={ERROR_ALERT_CONFIG.AREA}
            labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
            type={ERROR_ALERT_CONFIG.ERROR}
            onClose={() => {
              setShowFooterError(false);
            }}
          >
            <p className="message">{pageContent?.PLEASE_SELECT_AT_LEAST}</p>
          </InfoBar>
        </div>
      )}
      <ModalSlide.Footer>{renderFooterButtons()}</ModalSlide.Footer>
    </ModalSlide>
  );
};

const FindYourEyeDoctor = memo(FindYourEyeDoctorSlider);

export default FindYourEyeDoctor;
