import React from 'react';

import './SuccessModalBody.scss';

import { Button } from '@sydney-broker-ui/ios';

const SuccessModalBody = ({
  heading,
  description,
  closeButtonText,
  analyticsSuffix,
  closeModal,
}: {
  heading: string;
  description: string;
  closeButtonText: string;
  analyticsSuffix?: string;
  closeModal: () => void;
}) => {
  return (
    <div className='success-wrapper'>
      <span className='sae-icon sae-icon-checked success-icon'></span>
      <h2>{heading}</h2>
      <span className='success-message'>{description}</span>
      <Button
        id='success-close-btn'
        className='close-btn'
        data-analytics={"closeBtnSuccessModal" + analyticsSuffix}
        onClick={() => {
          closeModal();
        }}
      >
        {closeButtonText}
      </Button>
    </div>
  );
};

export default SuccessModalBody;
