import dayjs from 'dayjs';
import { formatDateToYYYYMMDD } from '../../../shared/utils/globalUtils';

const calculate_age = (dob) => {
  const date = dayjs(dob);
  const birth_month = date.month() + 1;
  const birth_day = date.date();
  const birth_year = date.year();
  const today_date = dayjs();
  const today_year = today_date.year();
  const today_month = today_date.month() + 1;
  const today_day = today_date.date();

  let age = today_year - birth_year;

  if (today_month < birth_month - 1) {
    age--;
  }

  if (birth_month - 1 === today_month && today_day < birth_day) {
    age--;
  }

  return age;
};

const getPayLoadForSubsidy = (data, zipcode, countyCode, coverageDate, applicantFormDetails) => {
  const payload: householdMember = {
    householdMemberCount: Number(data.dependents) + applicantFormDetails.length,
    householdMember: [],
    householdIncomeAmount: data.houseHoldIncome,
    domicileZipCode: zipcode,
    asOfDate: formatDateToYYYYMMDD(coverageDate),
    county: countyCode,
    unemployedIn2021: false
  };
  applicantFormDetails.forEach((val, index) => {
    const memberDetails = {
      personAge: calculate_age(val.dateOfBirth),
      householdMemberType: null,
      isPregnant: 'N',
      eligibility: null,
      memberTempId: val.applicantId
    };
    payload['householdMember'].push(memberDetails);
  });

  return payload;
};

export { getPayLoadForSubsidy };
