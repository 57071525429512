const getDashboardApplicationListingsPayload = (
  userId: string,
  applicationId: string,
  partnerId: string,
  numberOfApplicationPerPage: string,
  pageNumber: string
): getApplicationListingsPayload => {
  return {
    userId: userId,
    consumerRequestHeader: {
      applicationId: applicationId
    },
    partnerId: partnerId,
    numberOfApplicationPerPage: numberOfApplicationPerPage,
    pageNumber: pageNumber
  };
};

export { getDashboardApplicationListingsPayload };
