export const initialMemberInfo: MemberInfo = {
  hcid: '',
  firstName: '',
  lastName: '',
  dob: '',
  cmcEligible: '',
  memberType: '',
  status: '',
  demographicInfo: {
    applicant: [],
    zipCode: '',
    state: '',
    coverageEffectiveDate: '',
    eid: ''
  },
  currentCoverages: [],
  isRenewal: false,
  agentOfRecord: {
    agentId: '',
    phone: '',
    email: '',
    agentType: '',
    lobState: '',
    licenseNO: '',
    npn: '',
    agentCode: '',
    address: {
      addressLine1: '',
      city: '',
      state: ''
    }
  },
  ffmonExgCoverage: false
};
