import axios, { AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { useUserStore } from '../../store/userStore';
import { API_NAME_LIST } from '../api-name-list';
import { generateToken } from '../services/tokenService';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_NAME_LIST.BASEURL,
  timeout: 80000
});

// Define the structure of a retry queue item
interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

// Add global request interceptor
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    if (config.url?.includes('secure')) {
      config.headers.authorization = 'Bearer ' + useUserStore.getState().jwtToken;
    }
    return config;
  },
  (error) => {
    console.warn('axiosWrapper request interceptor error :', error);

    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest: AxiosRequestConfig = error.config;
    if (error.response && error.response.status === 403 && useUserStore.getState().jwtToken) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          // Refresh the access token
          await generateToken();

          // Update the request headers with the new access token
          // error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;

          // Retry all requests in the queue with the new token
          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            axiosInstance
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });

          // Clear the queue
          refreshAndRetryQueue.length = 0;

          // Retry the original request
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          // Handle token refresh error
          // You can clear all storage and redirect the user to the login page
          throw refreshError;
        } finally {
          isRefreshing = false;
        }
      }

      // Add the original request to the queue
      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }

    // Return a Promise rejection if the status code is not 401
    return Promise.reject(error);
  }
);

export default axiosInstance;
