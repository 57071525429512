export const deepLinkInfoInit: DeepLinkInfo = {
  brand: '',
  county: '',
  locale: '',
  refSource: '',
  state: '',
  year: '',
  zipcode: '',
  quoteId: '',
  proposalID: '',
  acn: '',
  medicalContractCode: '',
  dentalContractCode: '',
  visionContractCode: '',
  quoteSrc: '',
  language: '',
  demographicsId: '',
  standaloneLoginUseCase: '',
  agentBrokerLoginUseCase: '',
  householdIncome: '',
  agentConnectData: {
    agentId: '',
    agentType: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    state: '',
    rI: '',
    address: { addressLine1: '', city: '', state: '', zip: '' },
    city: '',
    zip: '',
    paidTIN: '',
    channel: '',
    agency: '',
    brand: '',
    npn: '',
    language: ''
    // parenttin: ''
  },
  evendorid: '',
  macLoginWithCredUseCase: '',
  evendorInfo: {
    id: '',
    appZipcode: '',
    appState: '',
    firstName: '',
    lastName: '',
    appDOB: '',
    appGender: '',
    tobaccoUser: '',
    reqEffDt: '',
    medicalProductId: '',
    dentalProductId: '',
    visionProductId: '',
    exchangeType: '',
    LOBState: '',
    quoteSrc: '',
    fname: '',
    lname: '',
    brand: '',
    parentTin: '',
    paidTIN: '',
    agentTin: '',
    agentCode: '',
    agency: '',
    certificationNO: '',
    agentfirstName: '',
    agentlastName: '',
    address1: '',
    city: '',
    state: '',
    zip: '',
    producerId: '',
    phone: '',
    phoneext: '',
    fax: '',
    faxext: '',
    email: '',
    licenseNO: '',
    npn: '',
    ffmUserId: '',
    agentType: '',
    language: '',
    appCounty: '',
    cid: '',
    cidPhone: '',
    vendorLeadID: ''
  },
  agentSSOInfo: {
    ssoID: '',
    channel: '',
    action: ''
  },
  source: '',
  refID: '',
  call: '',
  isAgentConnectFlow: false,
  cid: '',
  cidPhone: ''
};
