import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { API_NAME_LIST } from '../../../api/api-name-list';
import axiosInstance from '../../../api/axiosWrapper/axiosWrapper';

const registerShopper = (payload: RegisterShopperPayload) => {
  return axiosInstance.post(API_NAME_LIST.REGISTER_SHOPPER, payload);
};

const getUser = (config: AxiosRequestConfig) => {
  return axiosInstance.get(API_NAME_LIST.GET_USER, config);
};

export { registerShopper, getUser };
