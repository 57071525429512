import { EditableComponent, MapTo } from '@adobe/aem-react-editable-components';
import React from 'react';
import { withConditionalPlaceHolder } from '../core/util/withConditionalPlaceholder';
import { withStandardBaseCssClass } from '../core/util/withStandardBaseCssClass';
import CoreHero, { CoreHeroIsEmpty } from './CoreHero';

const RESOURCE_TYPE = 'ios/components/hero';

const EditConfig = {
  emptyLabel: 'Hero Component',
  isEmpty: CoreHeroIsEmpty,
  resourceType: RESOURCE_TYPE
};

export const WrappedQuote = (props) => {
  const Wrapped = withConditionalPlaceHolder(withStandardBaseCssClass(CoreHero, 'corehero-container'), CoreHeroIsEmpty, 'CoreHero');
  return <Wrapped {...props} />;
};

const EditableCoreHero = (props) => (
  <EditableComponent config={EditConfig} {...props}>
    <WrappedQuote />
  </EditableComponent>
);

MapTo(RESOURCE_TYPE)(EditableCoreHero);

export default EditableCoreHero;
