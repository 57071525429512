import { Modal } from '@sydney-broker-ui/ios';
import { AxiosError, isAxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AgentBrokerLogin.scss';

import QuoteConflict from '../../../../components/common/conflict-popups/QuoteConflict';
import FullPageLoader from '../../../../components/common/full-page-loader/FullPageLoader';
import GlobalFooter from '../../../../components/common/global-footer/GlobalFooter';
import GlobalHeader from '../../../../components/common/global-header/GlobalHeader';
import { APPLICATION_STATUSES, APPLICATION_TRANSFER_REDIRECTION_USE_CASES, CONFLICT_OPTION_LABELS, CONFLICT_USE_CASES, LANGUAGE_CODES, NAV_PATHS, SCREEN_NAMES } from '../../../../shared/globalConstants';
import { useUserStore } from '../../../../store/userStore';
import TwoFAModalBody from '../../two-fa-modal/TwoFAModalBody';

import { getCMSStaticContent } from '../../../../api/cms/cmsUtil';

import { HTTP_STATUS_CODES, STATUSES } from '../../../../api/constants';
import { checkApplicationStatus, transferApplicationBetweenAgentAndConsumer, validatePinAndDateOfBirthByAcn } from '../../../../api/services/utilService';
import InfoBar from '../../../../components/common/info-bar/InfoBar';
import PinDobValidation from '../../../../components/common/pin-dob-validation/PinDobValidation';
import { formatDateToYYYYMMDD } from '../../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../../store/globalStore';
import { DELETE_PAYLOAD_INPUT } from '../../../secure/dashboard/DashboardCard/constants';
import { cancelInProgressApplication } from '../../../secure/dashboard/DashboardCard/dashboardCardServices';
import { deleteInProgressDashboardCardPayload } from '../../../secure/dashboard/DashboardCard/dashboardCardUtils';
import { INFO_ALERT } from '../../registration/constants';
import { TWO_FA_USE_CASES } from '../../two-fa-modal/constants';
import { getErrorObj, getInProgressApplication } from '../login-modal/loginUtils';
import { default as Content } from './agentBrokerLogin.json';
import GuestLogin from './guest-login/GuestLogin';
import { getTransferApplicationPayload } from './guest-login/guestLoginUtils';
import UserLogin from './user-login/UserLogin';

const AgentBrokerLogin = () => {
    const [pageContent, setPageContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [showPinDobValidation, setShowPinDobValidation] = useState(false);
    const [showTwoFAModal, setShowTwoFAModal] = useState(false);
    const [modalHeading, setModalHeading] = useState('');
    const [loading, setLoading] = useState(false);
    const [showConflictPopup, setShowConflictPopup] = useState(false);
    const [conflictUseCase, setConflictUseCase] = useState('')
    const [selectedConflictOption, setSelectedConflictOption] = useState('');
    const [inProgressApplication, setInProgressApplication] = useState<Application>({} as Application);
    const [hideGuestLoginForm, setHideGuestLoginForm] = useState(false);
    const [applicationStatus, setApplicationStatus] = useState('');
    const [loginError, setLoginError] = useState({
        hasError: false,
        responseCode: '',
        message: ''
    });

    const { webAccountGUID, updateShowCreateProfileFlag, updateShowLoginButtonFlag, updateLoginStatus } = useUserStore((state) => state);
    const { deepLinkInfo } = useGlobalStore((state) => state);

    const { acn } = deepLinkInfo;

    const navigate = useNavigate();

    const getContent = useCallback(async (): Promise<boolean> => {
        let content: any;

        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.AGENT_BROKER_LOGIN);
            let cmsResponse = response.data;
            content = cmsResponse.data.iospagecontentList.items[0].pageContent?.AgentBrokerLogin;
        } catch (error) {
            content = Content.data.iospagecontentList.items[0].pageContent.AgentBrokerLogin;

            return false;
        } finally {
            setPageContent(content);
            setContentLoaded(true);
        }

        return true;
    }, []);

    const getApplicationStatus = () => {
        setLoading(true);
        if (useGlobalStore.getState().deepLinkInfo.acn) {
            let payload: checkApplicationStatusPayload = {
                acn: [useGlobalStore.getState().deepLinkInfo.acn]
            }
            checkApplicationStatus(payload)
                .then((response) => {
                    const data: checkApplicationStatusResponse = response.data;

                    if (data?.status === STATUSES.SUCCESS) {
                        const application = data?.response?.find((application) => application?.acn === useGlobalStore.getState().deepLinkInfo.acn);

                        setApplicationStatus(application?.applicationStatus ?? '');

                        if ([APPLICATION_STATUSES.SUBMITTED, APPLICATION_STATUSES.EXPIRED].includes(application?.applicationStatus ?? '')) {
                            setHideGuestLoginForm(true)
                        } else {
                            setHideGuestLoginForm(false);
                        }
                    } else {
                        console.warn('AgentBrokerLogin.tsx getApplication status error :', response);
                    }
                    setLoading(false);
                })
                .catch((error: AxiosError<LoginError>) => {
                    setLoading(false);
                    console.warn('AgentBrokerLogin.tsx getApplication status error :', error);
                });
        } else {
            setLoading(false);
        }
    }

    const initiate = async () => {
        await getContent();
        getApplicationStatus();
    };

    const initiateTwoFa = () => {
        setModalHeading(pageContent?.MODAL_HEADING);
        setShowTwoFAModal(true);
    }

    const handleTwoFAModalClose = () => {
        setShowTwoFAModal(false);
    };

    useEffect(() => {
        initiate()
    }, [])

    useEffect(() => {
        updateShowCreateProfileFlag(true);
        updateShowLoginButtonFlag(true);
        setShowPinDobValidation(false);
        return () => {
            updateShowCreateProfileFlag(false);
            updateShowLoginButtonFlag(false);
        };
    }, []);

    useEffect(() => {
        if (!showConflictPopup) {
            handleConflictResolution();
        }
    }, [showConflictPopup])

    const handleConflictResolution = async () => {
        if (selectedConflictOption === CONFLICT_OPTION_LABELS.OPTION1) {
            //User has chosen inprogress application
            navigate(NAV_PATHS.DASHBOARD)
        } else if (selectedConflictOption === CONFLICT_OPTION_LABELS.OPTION2) {
            if (inProgressApplication && inProgressApplication.acn) {
                const payload: deleteApplicationPayload = deleteInProgressDashboardCardPayload(inProgressApplication.acn, DELETE_PAYLOAD_INPUT.CANCELREASON, DELETE_PAYLOAD_INPUT.PARTNETID, inProgressApplication.accessControlList[0]?.user?.userId);
                await cancelInProgressApplication(payload)
            }
            setShowPinDobValidation(true);
        }
    }

    const checkInprogressApplication = async (data: LoginResponse | ValidateOTPResponse) => {
        updateLoginStatus(true);
        setLoading(true);
        const inProgressApplications = await getInProgressApplication(data.loginResponse.webAccountGUID);
        inProgressApplications && setInProgressApplication(inProgressApplications);
        setLoading(false);
        if (inProgressApplications) {
            //show conflict popup
            setConflictUseCase(CONFLICT_USE_CASES.APPLICATION)
            setShowConflictPopup(true);
        } else {
            setShowPinDobValidation(true);
        }
    }

    const handleConflictSelectedOption = (value: string) => {
        setSelectedConflictOption(value);
    };

    const initiateTransferApplication = () => {
        const payload: transferApplicationBetweenAgentAndConsumerPayload = getTransferApplicationPayload(acn, webAccountGUID, false);
        transferApplicationBetweenAgentAndConsumer(payload)
            .then((response) => {
                const data: transferApplicationBetweenAgentAndConsumerResponse = response.data;

                if (data.status === STATUSES.SUCCESS && data.response.valid) {
                    //Navigate to dashboard
                    setLoading(false);
                    navigate(NAV_PATHS.DASHBOARD);
                } else {
                    //Handle server error
                    handleLoginServerError(null);
                }
            })
            .catch((error: AxiosError<LoginError>) => {
                console.warn('GuestLogin.tsx transfer application error :', error);
                //Handle server error
                handleLoginServerError(error);
            });
    }

    const initiatePinandDOBValidation = (pin: string, dateOfBirth: string) => {
        setLoading(true);
        const payload = {
            pin: pin,
            acn: acn,
            dateOfBirth: formatDateToYYYYMMDD(dateOfBirth)
        };
        validatePinAndDateOfBirthByAcn(payload)
            .then((response) => {
                const data: ValidatePinAndDateOfBirthByAcnResponse = response.data;

                if (data.status === STATUSES.SUCCESS && data.response.valid) {
                    //initiate Transfer application
                    initiateTransferApplication();
                } else {
                    //Handle server error
                    setLoginError({
                        hasError: true,
                        responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
                        message: pageContent?.PIN_DOB_VALIDATION.INVALID_PIN_OR_DOB
                    });
                    setLoading(false);
                }
            })
            .catch((error: AxiosError<LoginError>) => {
                console.warn('AgentBrokerLogin.tsx validatePinAndDob error :', error);
                //Handle server error
                handleLoginServerError(error);
            });
    }

    const handleLoginServerError = (error: AxiosError<LoginError> | null) => {
        if (isAxiosError(error)) {
            const errorObj = error.response?.data?.error;
            const errorKey = errorObj?.errorKey ?? '';
            const loginErrorObj = getErrorObj(errorKey, pageContent.USER_LOGIN);
            setLoginError(loginErrorObj);
        } else {
            setLoginError({
                hasError: true,
                responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
                message: `<b>${pageContent?.USER_LOGIN.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.USER_LOGIN.SYSTEM_EXCEPTION_MESSAGE}`
            });
        }

        setLoading(false);
    };

    const resetLoginError = () => {
        setLoginError({
            hasError: false,
            message: '',
            responseCode: ''
        });
    };

    const getErrorMessage = () => {
        switch (applicationStatus) {
            case APPLICATION_STATUSES.SUBMITTED:
                return pageContent?.ERROR_MESSAGE_FOR_SUBMITTED_APPLICATION;
            case APPLICATION_STATUSES.EXPIRED:
                return pageContent?.ERROR_MESSAGE_FOR_EXPIRED_APPLICATION;
            default:
                break;
        }
    }

    if (!contentLoaded) {
        return <FullPageLoader />;
    } else {
        return (
            <div className="agent-login-container">
                {loading && <FullPageLoader />}
                <GlobalHeader />
                {!showPinDobValidation &&
                    <>
                        <div className='login-form-outer-wrapper'>
                            {hideGuestLoginForm &&
                                <div className="submitted-application-info">
                                    <div className="fwc-row">
                                        <InfoBar area={INFO_ALERT.area} labelAriaCloseBtn={INFO_ALERT.type} type={INFO_ALERT.type} onClose={{}}>
                                            <p className="reg-info-message">{getErrorMessage()}</p>
                                        </InfoBar>
                                    </div>
                                </div>
                            }
                            <div className="fwc-row">
                                <div className="login-form-container">
                                    <div className="user-login-form agent-form">
                                        <UserLogin labels={pageContent?.USER_LOGIN}
                                            initiateTwoFa={() => initiateTwoFa()} checkInprogressApplication={(data) => checkInprogressApplication(data)} />
                                    </div>
                                    {!hideGuestLoginForm &&
                                        <div className="separator">
                                            <span className="separator-text">{(pageContent.OR)}</span>
                                        </div>
                                    }
                                    {!hideGuestLoginForm &&
                                        <div className="guest-login-form agent-form">
                                            <GuestLogin labels={pageContent} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="fwc-row">
                            <div className="agent-create-profile-wrapper">
                                <div className="agent-cp-content-div">
                                    <h3 className="content-heading">{pageContent.DO_NOT_HAVE_A_PROFILE_YET}</h3>
                                    <p>{pageContent.CREATE_PROFILE_DESCRIPTION}</p>
                                </div>
                                <div className="agent-cp-button-div">
                                    <button
                                        id="agent-create-profile-button"
                                        className={'fwc-btn fwc-btn-secondary fwc-col-12 button-text'}
                                        onClick={() => {
                                            navigate(NAV_PATHS.REGISTRATION);
                                        }}
                                    >
                                        {pageContent.CREATE_PROFILE}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {showPinDobValidation &&
                    <PinDobValidation
                        labels={pageContent.PIN_DOB_VALIDATION}
                        error={loginError}
                        initiatePinDobValidation={(pin: string, dateOfBirth: string) => initiatePinandDOBValidation(pin, dateOfBirth)}
                        onErrorPopupClose={resetLoginError}
                    />
                }

                <Modal open={showTwoFAModal} onClose={handleTwoFAModalClose} title={modalHeading}>
                    <Modal.Body>
                        <TwoFAModalBody
                            useCase={TWO_FA_USE_CASES.LOGIN}
                            redirectionUseCase={true}
                            showModal={showTwoFAModal}
                            showFooter={true}
                            closeModal={handleTwoFAModalClose}
                            hideModalHeading={() => setModalHeading('')}
                            updateModalHeading={(heading) => setModalHeading(heading)}
                            onRedirectionUseCaseLogin={(data: ValidateOTPResponse) => checkInprogressApplication(data)}
                        />
                    </Modal.Body>
                </Modal>
                {showConflictPopup &&
                    <QuoteConflict showPopup={showConflictPopup} popupClose={() => { setShowConflictPopup(false) }} useCase={conflictUseCase}
                        onClick={handleConflictSelectedOption} redirectionUseCase={APPLICATION_TRANSFER_REDIRECTION_USE_CASES.TRANSFER_FLOW}
                    />
                }
                <GlobalFooter />
            </div>
        )
    }
}

export default AgentBrokerLogin