import { css, html, LitElement } from 'lit';
import React, { useEffect, useRef } from 'react';
import { IosFwcBase, sharedStyles } from './SharedBase';

function MotifProgressStandard(props) {
  const progressStandardRef = useRef(null);

  useEffect(() => {
    const fwcProgressStandard = new IosFwcProgressStandard();
    fwcProgressStandard.build();
    // const element = progressStandardRef.current;
    // const eventHandler = (event) => {
    //   props.stepClicked(event.detail.e);
    // };
    // // Add the event listener
    // element.addEventListener('stepClicked', eventHandler);

    // // Clean up on unmount
    // return () => {
    //   element.removeEventListener('stepClicked', eventHandler);
    // };
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <div>
      <ios-fwc-progress-standard type={props.type} dark={props.dark} data={JSON.stringify(props.steps)} id={props.id} ref={progressStandardRef} />
    </div>
  );
}
export default MotifProgressStandard;
class IosFwcProgressStandardComponent extends LitElement {
  static get styles() {
    return [
      sharedStyles,
      css`
        :host {
          display: inline;
          color: var(--color-gray-two);
        }
        ol {
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: var(--default-font-size);
          font-weight: bold;
        }
        li {
          flex: 1 1 auto;
          display: flex;
          align-items: center;
          position: relative;
        }
        li:last-child {
          flex: 0 0 auto;
        }
        div {
          height: calc(3.5 * var(--fwcu));
          width: calc(3.5 * var(--fwcu));
          background: white;
          border: 2px solid var(--color-gray-four);
          border-radius: 100%;
          display: flex;
          flex: 0 0 auto;
          justify-content: center;
          align-items: center;
        }
        div.complete {
          background: var(--color-primary);
          border-color: var(--color-primary);
          justify-content: center;
          align-items: center;
        }
        li[aria-label='complete'] + li[aria-label='incomplete'] > div {
          border: 2px solid var(--color-primary);
        }
        li[aria-label='complete'] + li[aria-label='incomplete'] > .step-number {
          font-weight: bold;
        }
        li[aria-label='incomplete']:first-of-type > div {
          border: 2px solid var(--color-primary);
        }
        li[aria-label='incomplete']:first-of-type > div > .step-number {
          font-weight: bold;
        }
        svg {
          width: calc(1.5 * var(--fwcu));
          height: calc(1.5 * var(--fwcu));
          display: none;
        }
        .step-number {
          font-size: var(--default-font-size);
          font-weight: normal;
          color: var(--color-white);
          display: inline;
        }
        div.complete svg {
          display: inline;
        }
        div.complete .step-number {
          display: none;
        }
        span {
          height: 2px;
          display: block;
          margin-left: calc(0.5 * var(--fwcu));
          margin-right: calc(0.5 * var(--fwcu));
          width: calc(100% - calc(3.5 * var(--fwcu)));
          background: var(--color-gray-four);
        }
        span.complete {
          background: var(--color-primary);
        }
        p {
          position: absolute;
          text-align: center;
          width: calc(15.5 * var(--fwcu));
          left: calc(-6.75 * var(--fwcu)); /*  15.5/2-1 */
          top: 100%;
          margin: 0;
          padding-top: var(--fwcu);
        }
        li[aria-label='complete'] + li[aria-label='incomplete'] > p,
        li[aria-label='incomplete']:first-of-type > p {
          color: var(--color-gray-one);
        }
        /* vertical */
        ol.vert {
          flex-direction: column;
          align-items: flex-start;
          height: 100%;
        }
        ol.vert span {
          width: calc(0.25 * var(--fwcu));
          height: calc(100% - calc(3.75 * var(--fwcu)));
          margin: calc(0.5 * var(--fwcu)) 0;
          margin-left: calc(1.25 * var(--fwcu));
          /* box-sizing: content-box; */
        }
        ol.vert li {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
        }
        ol.vert p {
          top: 0;
          bottom: calc(50% - calc(1.5 * var(--fwcu))); /* centers border on vertical line, not parent. Takes into account half of check circle height) */
          padding: 0;
          width: calc(100% - calc(3 * var(--fwcu)));
          left: calc(4 * var(--fwcu));
          text-align: left;
          border-bottom: 1px solid var(--color-gray-four, #cccccc);
        }
        ol.vert li:last-child p {
          border-bottom: none;
        }
        ol.dark {
          li[aria-label='incomplete']:first-of-type > p {
            color: #ffffff;
            font-weight: bold;
          }
          li[aria-label='complete'] > p {
            color: #ffffff;
            font-weight: bold;
          }
          li[aria-label='complete'] + li[aria-label='incomplete'] > p {
            color: #ffffff;
            font-weight: bold;
          }
          li[aria-label='incomplete']:first-of-type > div {
            border: 2px solid var(--color-white);
            background-color: rgba(255, 255, 255, 0.3);
          }
          li[aria-label='incomplete']:first-of-type > div .step-number {
            font-weight: bold;
          }
          li[aria-label='complete'] + li[aria-label='incomplete'] > div {
            border: 2px solid var(--color-white);
            background-color: rgba(255, 255, 255, 0.3);
          }
          li[aria-label='complete'] + li[aria-label='incomplete'] > div .step-number {
            font-weight: bold;
          }
          li[aria-label='complete'] > div {
            border: 2px solid var(--color-white);
            background-color: var(--color-white);
          }
          p {
            color: #ffffff;
            font-weight: normal;
          }
          div {
            background-color: var(--color-primary);
          }
          span.complete {
            background: var(--color-white);
          }
        }
      `
    ];
  }

  static get properties() {
    return {
      type: { type: String },
      data: { type: Array },
      steps: { type: Array },
      dark: { type: Boolean }
    };
  }

  constructor() {
    super();
    this.type = 'horizontal';
    this.steps = [];
    this.data = null;
  }

  firstUpdated() {
    if (this.data) {
      this._buildSteps();
    }
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      if (propName === 'data') {
        this._buildSteps();
      }
    });
  }

  onItemclick(e) {
    if (!e.complete && !e.current) {
      return;
    }
    const event = new CustomEvent('stepClicked', {
      detail: { e }
    });
    this.dispatchEvent(event);
  }

  _buildSteps() {
    const steps = html`${this.data.map((step) => {
      if (step.disabled) {
        return html`
          <li aria-label="disabled">
            <div class="disabled>
              <h7 class="step-number">${step.number + 1}</h7>
            </div>
            <span class=${step.complete ? 'complete' : ''}></span>
            <p>${step.label}</p>
          </li>
        `;
      } else {
        return html`
          <li aria-label="${step.complete ? 'complete' : 'incomplete'}" @click="${() => this.onItemclick(step)}">
            <div class=${step.complete ? 'complete' : ''}>
              <h7 class="step-number">${step.number + 1}</h7>
              ${this._drawCheckMark()}
            </div>
            <span class=${step.complete ? 'complete' : ''}></span>
            <p>${step.label}</p>
          </li>
        `;
      }
    })}`;
    this.steps = steps;
  }

  _drawCheckMark() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" focusable="false">
        <polygon
          fill="${this.dark ? '#2B67D0' : '#FFFFFF'}"
          points="40.771 7 19.443 26.724 9.229 17.197 0 26.109 18.365 43.545 19.443 44.5 20.519 43.545 50 15.912"
        />
      </svg>
    `;
  }

  render() {
    return html`
      <ol class="${this.type === 'vertical' ? 'vert' : ''} ${this.dark ? 'dark' : ''}">
        ${this.steps}
      </ol>
    `;
  }
}

class IosFwcProgressStandard extends IosFwcBase {
  constructor() {
    super('ios-fwc-progress-standard', IosFwcProgressStandardComponent);
  }
}
