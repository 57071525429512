import { ModalSlide } from "@sydney-broker-ui/ios";
import React from "react";
import { useUserStore } from "../../../../store/userStore";
import { default as GlobalHeaderContent } from '../globalHeaderContent.json';

interface MobileMenuProps {
    onLinkClick: (e: boolean) => void;
    onClose: (e: boolean) => void;
    showMobileSlider: boolean;
    contactPhone: string;
}
function MobileMenu({ showMobileSlider, onLinkClick, onClose, contactPhone }: MobileMenuProps) {
    let headerContent = GlobalHeaderContent.data.iospagecontentList.items[0].pageContent.GlobalHeader;
    const userState = useUserStore(state => state.state);
    const { loggedIn } = useUserStore(state => state);

    const handleSliderClose = (e) => {
        onClose(false);
    };
    const handleClick = (e) => {
        e.preventDefault();
        onLinkClick(e);
    };
    return (
        <div>
            <ModalSlide
                direction={'right'}
                open={showMobileSlider}
                onClose={handleSliderClose}
            >
                <ModalSlide.Body>
                    <div className={'slider-container'}>
                        <div className="fwc-row fwc-row-wrap mgd-menu-row fwc-row-bm">
                            <div className="fwc-col-12 sm-12">
                                <button id={'log-in'} className="fwc-btn fwc-btn-primary" onClick={(e) => {
                                    handleClick(e);
                                }}>
                                    {!loggedIn ? headerContent?.LOG_IN : headerContent?.LOG_OUT}
                                </button>
                            </div>
                        </div>
                        <div className="fwc-row fwc-row-wrap mobile-menu-link">
                            <div className="fwc-col-12 sm-12">
                                <a id={'contact-us mobile-nav-link'} onClick={(e) => {
                                    handleClick(e);
                                }} className="btm-xxs title"><span className="sae-icon sae-icon-phone right-xxs">
                                    </span>{headerContent.CONTACT_US}<span className={'header-phone-link left-xxs'}>{contactPhone}</span></a>
                            </div>
                        </div>
                    </div>
                </ModalSlide.Body>
            </ModalSlide>
        </div>
    );
}
export default MobileMenu;

