import React from 'react'

import { getDescriptionOne, getDescriptionTwo, getImage, getTitle } from './emptyPlansutils'

import './EmptyPlans.scss'

const EmptyPlans: React.FC<EmptyPlansProps> = ({ useCase, labels }) => {
    return (
        <div className='empty-plans-container fwc-row'>
            <img data-testid='empty-plans-image' id='empty-plans-image' className='empty-plans-element' src={getImage(useCase)} alt='' />
            <h4 id='empty-plans-title' className='empty-plans-element'>{getTitle(useCase, labels)}</h4>
            <p id='empty-plans-description-one' className='empty-plans-element'>{getDescriptionOne(useCase, labels)}</p>
            <p id='empty-plans-description-two' className='empty-plans-element'>{getDescriptionTwo(useCase, labels)}</p>
        </div>
    )
}

export default EmptyPlans