import { AxiosError, isAxiosError } from 'axios';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import DateInput from '../../../../../components/common/date-input/DateInput';
import FullPageLoader from '../../../../../components/common/full-page-loader/FullPageLoader';
import InfoBar from '../../../../../components/common/info-bar/InfoBar';
import './GuestLogin.scss';

import { OFF_EXCHANGE_BASE_URLS, WELLPOINT_OFF_EXCHANGE_BASE_URLS } from '../../../../../api/api-name-list';
import { HTTP_STATUS_CODES, STATUSES } from '../../../../../api/constants';
import { generateTokenAPI, getBuildVersion } from '../../../../../api/services/tokenService';
import { guestUserLogin, transferApplicationBetweenAgentAndConsumer, validatePinAndDateOfBirthByAcn } from '../../../../../api/services/utilService';
import { DATE_FORMATS, ERROR_ALERT_CONFIG, USER_DOMAINS } from '../../../../../shared/globalConstants';
import { formatDateToMMDDYYY, formatDateToYYYYMMDD } from '../../../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../../../store/globalStore';
import { useUserStore } from '../../../../../store/userStore';
import { getEncryptacnPayload } from '../../../plan-selection-summary/planSelectionSummaryUtils';
import { getEncryptAcn } from '../../../plan-selection-summary/planSummaryServices';
import { LOGIN_RESPONSE_CODES } from '../../login-modal/constants';
import { getErrorObj } from '../../login-modal/loginUtils';
import { validateDob } from '../../mac-login-with-anthem-card/macLoginWithAnthemCardUtils';
import { getTransferApplicationPayload, validatePin } from './guestLoginUtils';

const { REACT_APP_ENV } = process.env;

const GuestLogin = ({ labels }: { labels: any }) => {
    const [pin, setPin] = useState('');
    const [pinNumberError, setPinNumberError] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [dobError, setDOBError] = useState('');
    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState({
        hasError: false,
        responseCode: '',
        message: ''
    });

    const { deepLinkInfo } = useGlobalStore((state) => state);

    const { acn, state } = deepLinkInfo;

    const handlePINNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPinNumberError('');
        setLoginError({
            hasError: false,
            message: '',
            responseCode: ''
        });
        setPin(e.target.value);
    };

    const handleDateOfBirthChange = (dob) => {
        setDOBError('');
        setLoginError({
            hasError: false,
            message: '',
            responseCode: ''
        });
        if (dob) {
            const updatedDobValue = formatDateToMMDDYYY(dob);
            setDateOfBirth(updatedDobValue);
        } else {
            setDateOfBirth('');
        }
    };

    const resetErrors = () => {
        setPinNumberError('');
        setDOBError('');
        setLoginError({
            hasError: false,
            message: '',
            responseCode: ''
        });
    };

    const checkPinNumber = () => {
        const { isValidPin, pinErrorMessage } = validatePin(pin, labels.GUEST_LOGIN);

        if (!isValidPin) {
            setPinNumberError(pinErrorMessage);
        } else {
            setPinNumberError('');
        }

        return isValidPin;
    };

    const checkDob = (date: any, isContinueClicked = false) => {
        const { isValidDob, dobErrorMessage } = validateDob(date?.target?.value ? date?.target?.value : dateOfBirth, isContinueClicked, labels.GUEST_LOGIN);

        if (!isValidDob) {
            setDOBError(dobErrorMessage);
        } else {
            setDOBError('');
        }

        return isValidDob;
    };

    const initiateTransferApplication = () => {
        const payload: transferApplicationBetweenAgentAndConsumerPayload = getTransferApplicationPayload(acn, '', true);
        transferApplicationBetweenAgentAndConsumer(payload)
            .then(async (response) => {
                const data: transferApplicationBetweenAgentAndConsumerResponse = response.data;

                if (data.status === STATUSES.SUCCESS && data.response.valid) {
                    //Navigate to overview screen
                    const encryptedACNPayload: EncryptacnPayload = getEncryptacnPayload(acn)
                    const encryptedacnResponse = await getEncryptAcn(encryptedACNPayload);
                    setLoading(false);
                    window.open(`${useUserStore.getState().isWellpoint ? WELLPOINT_OFF_EXCHANGE_BASE_URLS[REACT_APP_ENV ?? ''] : OFF_EXCHANGE_BASE_URLS[REACT_APP_ENV ?? '']}/sales/eox/secure/apply?acn=${encryptedacnResponse?.data?.encryptedACN}&source=acaRefresh`, '_self');

                } else {
                    //Handle server error
                    handleLoginServerError(null);
                }
            })
            .catch((error: AxiosError<LoginError>) => {
                console.warn('GuestLogin.tsx transfer application error :', error);
                //Handle server error
                handleLoginServerError(error);
            });
    }

    const initiatePinandDOBValidation = () => {
        const payload = {
            pin: pin,
            acn: acn,
            dateOfBirth: formatDateToYYYYMMDD(dateOfBirth)
        };
        validatePinAndDateOfBirthByAcn(payload)
            .then((response) => {
                const data: ValidatePinAndDateOfBirthByAcnResponse = response.data;

                if (data.status === STATUSES.SUCCESS && data.response.valid) {
                    //initiate Transfer application
                    initiateTransferApplication();

                } else {
                    //Handle server error
                    setLoginError({
                        hasError: true,
                        responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
                        message: labels?.PIN_DOB_VALIDATION.INVALID_PIN_OR_DOB
                    });
                    setLoading(false);
                }
            })
            .catch((error: AxiosError<LoginError>) => {
                console.warn('GuestLogin.tsx validateCredentials error :', error);
                //Handle server error
                handleLoginServerError(error);
            });
    }

    const getToken = async (): Promise<boolean> => {
        let returnVal = false;
        try {
            const response = await getBuildVersion();
            if (response.status === 200) {
                try {
                    const tokenResponse = await generateTokenAPI();
                    if (tokenResponse && tokenResponse.data.token) {
                        useUserStore.getState().updateJWTToken({ jwtToken: tokenResponse.data.token });
                        returnVal = true;
                    } else {
                        returnVal = false;
                        handleLoginServerError(null);
                    }
                } catch (error: any) {
                    returnVal = false;
                    handleLoginServerError(null);
                }
            }
        }
        catch (error: any) {
            returnVal = false;
            handleLoginServerError(null);
        }
        finally {
            return returnVal;
        }
    }

    const initiateGuestUserLogin = () => {
        const payload = {
            userDomain: {
                marketSegment: USER_DOMAINS.MARKET_SEGMENT,
                brand: USER_DOMAINS.BRAND,
                app: USER_DOMAINS.APP,
                stateCd: state ?? ''
            },
            acn: acn ?? '',
            dateOfBirth: formatDateToYYYYMMDD(dateOfBirth)
        };
        guestUserLogin(payload)
            .then(async (response) => {
                const data: LoginResponse = response.data;

                if (data.status === STATUSES.SUCCESS && LOGIN_RESPONSE_CODES.AUTHENTICATION_SUCCESSFULL === data.responseMessage.responseCode) {
                    let tokenGenerated = await getToken();
                    if (tokenGenerated || REACT_APP_ENV === 'local') {
                        //initiate Pin and DOB validation
                        initiatePinandDOBValidation()
                    }
                    else {
                        setLoading(false);
                        handleLoginServerError(null);
                    }

                } else {
                    //Handle server error
                    handleLoginServerError(null);
                }
            })
            .catch((error: AxiosError<LoginError>) => {
                console.warn('GuestLogin.tsx validateCredentials error :', error);
                //Handle server error
                handleLoginServerError(error);
            });
    }

    const handleLoginServerError = (error: AxiosError<LoginError> | null) => {
        if (isAxiosError(error)) {
            const errorObj = error.response?.data?.error;
            const errorKey = errorObj?.errorKey ?? '';
            const loginErrorObj = getErrorObj(errorKey, labels.USER_LOGIN);
            setLoginError(loginErrorObj);
        } else {
            setLoginError({
                hasError: true,
                responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
                message: `<b>${labels?.USER_LOGIN.SYSTEM_EXCEPTION_HEADING}</b> <br> ${labels?.USER_LOGIN.SYSTEM_EXCEPTION_MESSAGE}`
            });
        }

        setLoading(false);
    };

    const validateCredentials = () => {
        resetErrors();
        setLoading(true);

        const isValidPin = checkPinNumber();
        const isValidDob = checkDob(dateOfBirth, true);

        if (isValidPin && isValidDob) {
            //Local validations passed Initiate guest user login
            initiateGuestUserLogin();
        } else {
            setLoading(false);
        }
    };

    return (
        <div className="guest-login-form-wrapper">
            {loading && <FullPageLoader />}
            <div className="guest-login-form-heading">
                <h2 className="heading">{labels.GUEST_LOGIN.LOGIN_FORM_HEADER}</h2>
                <span className="description">{labels.GUEST_LOGIN.LOGIN_FORM_DESCRIPTION}</span>
            </div>
            <div className={'fwc-input input-container'}>
                <label id="guest-login-pin-label" className="fwc-label" htmlFor={'guest-login-pin-input'}>
                    {labels.GUEST_LOGIN.PIN_FROM_YOUR_EMAIL}
                </label>
                <input id="guest-login-pin-input" type="text" value={pin} onChange={(e) => handlePINNumber(e)} maxLength={12} />
                {pinNumberError?.length > 0 && (
                    <span id="guest-login-pin-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {pinNumberError}
                    </span>
                )}
            </div>
            <div className={'fwc-input guest-dob-input-container'}>
                <label id="guest-login-dob-label" className="fwc-label" htmlFor={'guest-login-dob-input'}>
                    {labels.GUEST_LOGIN.DATE_OF_BIRTH} <span className="guest-dob-helper-text">{labels.GUEST_LOGIN.DOB_FORMAT}</span>
                </label>
                <DateInput
                    className="guest-login-dob-input"
                    data-testid="guest-login-dob-input"
                    id="guest-login-dob-input"
                    name="dob"
                    defaultDate={dateOfBirth}
                    maxDate={dayjs().format(DATE_FORMATS.YYYYMMDD)}
                    onDateChange={handleDateOfBirthChange}
                />
                {dobError && (
                    <span id="guest-login-dob-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {dobError}
                    </span>
                )}
            </div>

            {loginError.hasError && !loading && (
                <InfoBar
                    area={ERROR_ALERT_CONFIG.AREA}
                    labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
                    type={ERROR_ALERT_CONFIG.ERROR}
                    handleClose={() => {
                        setLoginError({
                            hasError: false,
                            message: '',
                            responseCode: ''
                        });
                    }}
                >
                    <p className="message" dangerouslySetInnerHTML={{ __html: loginError.message }} />
                </InfoBar>
            )}

            <div className="guest-login-button-wrapper">
                <button
                    id="guest-login-button"
                    className={'fwc-btn fwc-btn-primary fwc-col-12 button-text'}
                    onClick={() => {
                        validateCredentials();
                    }}
                >
                    {labels.GUEST_LOGIN.LOGIN_BUTTON}
                </button>
            </div>
        </div>
    )
}

export default GuestLogin