import { PreLoader } from '@sydney-broker-ui/ios';
import React from 'react';
import './FullPageLoader.scss';

function FullPageLoader() {
  return (
    <div className="full-page-loader loader-container" data-testid="full-page-loader">
      <p className={'sr-only'}>Loading...</p>
      <div className="loader">
        <PreLoader />
      </div>
    </div>
  );
}
export default FullPageLoader;
