import { Button, ModalSlide } from '@sydney-broker-ui/ios';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { getProviderDetails } from '../../../api/services/utilService';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import { formatPhoneNumber } from '../../../components/common/global-header/contact-us/contactUsUtils';
import building from '../../../images/building.svg';
import { LANGUAGE_CODES, PLAN_TYPES_NAMES, SCREEN_NAMES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import ProviderDetailsContent from './ProviderDetails.json';
import './ProviderDetailsSlider.scss';
import { formateTime, getTitleIcon } from './ProviderDetailsUtils';


const ProviderDetails: React.FC<ProviderDetailsProps> = ({ useCase }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [providerDetails, setProviderDetails] = useState<ProviderDetailsResponse>();
  const [locationDetails, setLocationDetails] = useState<ProviderLocations>();
  const [officeHours, setOfficeHours] = useState<string[]>();
  const [loading, setLoading] = useState(false);
  const [pageContent, setContent]: any = useState();
  const [contentLoaded, setContentLoaded] = useState(false);
  const [title, setTitle] = useState();
  const { brand, agentBrand, state, agentState, zipcode, agentZipcode, agentZipCodeRes, providerMetaData, agentSSOInfo, updateProviderMetaData } = useGlobalStore(state => state)

  const { isAtk } = agentSSOInfo
  const zipCodeValue = isAtk ? agentZipcode : zipcode;
  const stateCode = isAtk ? agentState : state;
  const brandValue = isAtk ? agentBrand : brand;

  const initiate = async () => {
    setLoading(true);
    await getContent();
    setLoading(false);
  };

  const getContent = useCallback(async (): Promise<boolean> => {

    let title;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.PROVIDER_DETAILS_SLIDER);
      let cmsResponse = response.data;
      title = cmsResponse.data.iospagecontentList.items[0].pageContent.ProviderDetails;
      setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.ProviderDetails);

    } catch (error) {
      setContent(ProviderDetailsContent.data.iospagecontentList.items[0].pageContent.ProviderDetails);
      title = ProviderDetailsContent.data.iospagecontentList.items[0].pageContent.ProviderDetails;
      return false;
    } finally {
      if (PLAN_TYPES_NAMES.MEDICAL === useCase)
        setTitle(title?.PAGE_TITLE?.MEDICAL);

      else if (PLAN_TYPES_NAMES.DENTAL === useCase)
        setTitle(title?.PAGE_TITLE?.DENTAL);
      if (PLAN_TYPES_NAMES.VISION === useCase)
        setTitle(title?.PAGE_TITLE?.VISION);
      setContentLoaded(true);
    }
    return true;
  }, []);

  useEffect(() => {
    initiate();
    if (providerMetaData !== null) {
      handleProviderDetails();
    }
  }, [providerMetaData]);

  useEffect(() => {
    if (providerDetails) {
      const locationDetails = providerDetails?.locations?.find((element) => element.addressId === providerMetaData?.address.addressId);
      const officeHours = locationDetails?.officeHours ? formateTime(locationDetails?.officeHours) : [];
      setLocationDetails(locationDetails);
      setOfficeHours(officeHours);
    }
  }, [providerDetails])

  const handleProviderDetails = async () => {
    const specialtyList = providerMetaData?.specialtyList.map((element) => element.specialtyId);
    const payload: GetProviderDetails = {
      providerIdentifier: providerMetaData?.providerIdentifier || '',
      addressIdentifier: providerMetaData?.address?.addressId || '',
      type: providerMetaData?.providerTypeCodeList[0] || '',
      brand: brandValue || '',
      state: providerMetaData?.address?.state ? providerMetaData?.address?.state : stateCode,
      specialtyType: specialtyList || [],
      contractCodes: providerMetaData?.contractCodes || [],
      latitude: providerMetaData?.address?.latitude ? +providerMetaData.address.latitude : 0,
      longitude: providerMetaData?.address?.longitude ? +providerMetaData.address.longitude : 0,
      zipCode: zipCodeValue || ''
    };
    try {
      setIsLoading(true);
      const response = await getProviderDetails(payload);

      if (response?.data) {

        setProviderDetails(response?.data);

      }
    } catch (error) {
      console.error('Error fetching county data:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const closeProviderDetails = () => {
    updateProviderMetaData(null)
  }

  const getAcceptMedicaidValue = () => {
    if (providerDetails?.providerDetail.acceptMedicaid !== null && providerDetails?.providerDetail.acceptMedicaid !== "" && providerDetails?.providerDetail.acceptMedicaid.toLocaleLowerCase() === pageContent?.ACCEPTS_MEDICAID_YES_VALUE.toLowerCase()) {
      return pageContent?.YES_TEXT;
    } else if (providerDetails?.providerDetail.acceptMedicaid !== null && providerDetails?.providerDetail.acceptMedicaid !== "" && providerDetails?.providerDetail.acceptMedicaid.toLocaleLowerCase() === pageContent?.ACCEPTS_MEDICAID_NO_VALUE.toLowerCase()) {
      return pageContent?.NO_TEXT;
    } else {
      return pageContent?.ACCEPTS_MEDICAID_DEFAULT_VALUE;
    }
  }

  return (
    <div>
      <ModalSlide direction={'right'} open={providerDetails !== null} onClose={() => closeProviderDetails()}>
        <ModalSlide.Body>
          <div className={'slider-container'} id="slider-container" data-testid='provider-details-body'>
            {(loading || !contentLoaded || isLoading) && <FullPageLoader />}
            <div className="fwc-row fwc-row-wrap" id="wraper">
              <div className="fwc-col-12 sm-12 md-12 provider-details" id="provider-details">
                <div className="provider-details-title-container" id="provider-details-title-container">
                  <div className="provider-details-title" id="provider-details-title">
                    {<i className={`sae-icon ${getTitleIcon(useCase)}`} id={`${getTitleIcon(useCase)}`}></i>}
                    <h2 id="provider-details-title">
                      {' '}
                      {title} {providerDetails?.providerDetail?.name?.providerName?.toLowerCase()}
                    </h2>
                  </div>
                </div>
                <div className="care-provider-container" id="care-provider-container">
                  <div className="care-provider-title-container" id="care-provider-title-container">
                    <i className="sae-icon sae-icon-doctors-bag" id="sae-icon-doctors-bag"></i>
                    <h5 id="care-provider-title">{pageContent?.CARE_PROVIDER_TITLE}</h5>
                  </div>
                  <p id="care-provider-name">
                    <span id="care-provider">{pageContent?.CARE_PROVIDER}</span>
                    {providerDetails?.providerDetail?.name?.providerName?.toLowerCase()}
                  </p>
                  <p id="accetpts-new-patients">
                    <span id="accepts-new-patients-title">{pageContent?.ACCEPTS_NEW_PATIENTS}</span>
                    {providerDetails?.providerDetail?.acceptsNewPatients?.toLowerCase()}
                  </p>
                  <p id="accept-medicaid">
                    <span className="accept-medicaid-title">{pageContent?.ACCEPTS_MEDICAID}</span>
                    {getAcceptMedicaidValue()}
                  </p>
                </div>
                <p id="specialites-title" className="specialites-title">
                  {pageContent?.SPECIALTIES}
                </p>
                {providerDetails?.providerDetail?.specialities && (
                  <div className="specialties-container" id="specialties-container">
                    {providerDetails?.providerDetail?.specialities.map((element, key) => {
                      return (
                        <p key={key}>
                          {element?.specialtyName} {'-'}
                          {element?.boardCert}
                        </p>
                      );
                    })}
                  </div>
                )}
                <div className="gender-container" id="gender-container">
                  <p id="gender">
                    <span id="gender-title">{pageContent?.GENDER}</span> {providerDetails?.providerDetail?.gender?.toLowerCase()}
                  </p>
                  <p id="language">
                    <span id="language-title">{pageContent?.LANGUAGES}</span> {providerDetails?.providerDetail?.languages.join(', ')}
                  </p>
                  <p id="education">
                    <span id="educaton-title">{pageContent?.MEDICAL_SCHOOL_EDUCATION}</span> {providerDetails?.providerDetail?.educations.join(', ')}
                  </p>
                </div>
                {useCase === PLAN_TYPES_NAMES.MEDICAL &&
                  <div className="affiliations-container" id="affiliations-container">
                    <div className="affiliations-title-container">
                      <img src={building} alt="" id="building-img" />
                      <h5 id="affiliation-title">{pageContent?.AFFILIATIONS}</h5>
                    </div>
                    {providerDetails?.affiliation ? (
                      <div className='affiliation-details'>
                        <p id='affiliation-hospital'><span>{pageContent?.HOSPITALS}</span>{providerDetails?.affiliation?.hospitals.join(', ')}</p>
                        <p id='affiliation-groups'><span>{pageContent?.GROUPS}</span>{providerDetails?.affiliation?.groups.join(', ')}</p>
                      </div>
                    ) : (
                      <p id="no-affiliation">{pageContent?.NO_AFFILIATION_MESSAGE}</p>
                    )}
                  </div>
                }
                <div className="locations-container" id="locations-container">
                  <div className="location-title-container" id="location-title-container">
                    <i className="sae-icon sae-icon-marker" id="sae-icon-marker"></i>
                    <h5 id="location-title">{pageContent?.lOCATIONS_TITLE}</h5>
                  </div>
                  {locationDetails &&
                    <p id="address" className="address">
                      {locationDetails?.addressOne} {locationDetails?.addressOne.trim() ? ',' : ''} {locationDetails?.addressTwo} {locationDetails?.addressTwo.trim() ? ',' : ''} {formatPhoneNumber(locationDetails?.phone)}
                    </p>
                  }
                  <div className="office-hours">
                    <p id="office-hours-title">{pageContent?.OFFICE_HOURS_TITLE}</p>
                    {officeHours?.map((element, key) => <p key={key}>{element}</p>)}
                  </div>
                  <div>
                    <p id="distance-title">{pageContent?.DISTANCE}
                      {locationDetails?.distance ?? 0} {pageContent?.MILES} </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalSlide.Body>
        <ModalSlide.Footer>
          <Button id="modal-slide-select" onClick={() => closeProviderDetails()} data-testid='provider-details-close-button'>
            {pageContent?.CLOSE}
          </Button>
        </ModalSlide.Footer>
      </ModalSlide>
    </div>
  );
};

export default memo(ProviderDetails);