
import React, { useEffect, useState } from 'react';

import lower_cost_blue from '../../../images/lower_cost_blue.svg';
import lower_cost_white from '../../../images/lower_cost_white.svg';
import richer_benefits_blue from '../../../images/richer_benefits_blue.svg';
import richer_benefits_white from '../../../images/richer_benefits_white.svg';
import { useGlobalStore } from '../../../store/globalStore';
import './RecommendationModal.scss';
import { getOriginalVisionPlans, getUpdatedRecommendationOptions } from './RecommendationModalUtils';
import { RECOMMENDATION_OPTIONS, VISION_RECOMMENDATION_BENEFITS } from './constants';

const RecommendationModal: React.FC<RecommendationModalProps> = ({ labels, selectedOption, onOptionChange }) => {
    const [recommendationOptions, setRecommendationOptions] = useState(RECOMMENDATION_OPTIONS);
    const { showRecommendationModal, visionRecommendations, visionPlans, updateVisionPlans } = useGlobalStore((state) => state);

    useEffect(() => {
        setRecommendationOptions(getUpdatedRecommendationOptions(recommendationOptions, labels));
    }, [])

    useEffect(() => {
        if (showRecommendationModal && visionPlans.length && visionRecommendations.originalVisionPlans.length) {
            resetVisionRecommendation();
        }
    }, [showRecommendationModal])

    const resetVisionRecommendation = () => {
        const { originalVisionPlans } = visionRecommendations;
        const originalPlans: Contracts = getOriginalVisionPlans(visionPlans, originalVisionPlans);

        updateVisionPlans(originalPlans);
    }

    const handleOptionChange = (value: string) => {
        onOptionChange(value)
    }

    const getIcon = (key: string) => {
        switch (key) {
            case VISION_RECOMMENDATION_BENEFITS.RICHER_BENEFITS:
                return (
                    <div>
                        <img src={richer_benefits_blue} className="richer-benefits-blue" alt="" id="richer-benefits-blue-img" />
                        <img src={richer_benefits_white} className="richer-benefits-white" alt="" id="richer-benefits-white-img" />
                    </div>
                );
            case VISION_RECOMMENDATION_BENEFITS.LOWER_BENEFITS:
                return (
                    <div>
                        <img src={lower_cost_blue} className="lower-benefits-blue" alt="" id="lower-cost-blue-img" />
                        <img src={lower_cost_white} className="lower-benefits-white" alt="" id="lower-cost-white-img" />
                    </div>
                );
        }
    }

    return (
        <div className='recommendation-options-wrapper'>
            {recommendationOptions?.map((option) => (
                <div data-testid="benefit-radio" data-analytics={`${option.key === VISION_RECOMMENDATION_BENEFITS.RICHER_BENEFITS ? "richBenTileBenPremModalIos" : "lowCostsTileBenPremModalIos"}`}
                    className={`benefit-radio-box-shadow ${selectedOption === option.value ? "selected-radio" : ''}`} key={option.key}
                    onClick={(e) => {
                        handleOptionChange(option.value);
                    }}>
                    <div className="fwc-radio " >
                        <input
                            type="radio"
                            className="benefit-radio-input"
                            id={option.key.toLocaleLowerCase()}
                            value={option.value}
                            checked={selectedOption === option.value}
                            onChange={(e) => {
                                handleOptionChange(option.value)
                            }}
                        />
                        <label className="fwc-label benefit-option-container" htmlFor={option.label}>
                            <span className="benefit-option-icon">
                                {getIcon(option.key)}
                            </span>
                            <div className="benefit-icon-label-wrp">
                                <span>
                                    <span className='option-name'>
                                        {option.label}</span>
                                </span>
                                <span className='benefit-radio'>
                                    <i className="sae-icon sae-icon-circled"></i>
                                </span>
                            </div>
                        </label>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default RecommendationModal;
