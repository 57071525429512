import { StateCreator } from 'zustand';

import { FILTER_OPTIONS, MEDICAL_PLANS_TABS, SORT_OPTIONS } from '../pages/public/medical-plans/constants';

export const createMedicalPlansSlice: StateCreator<MedicalPlansStore> = (set) => ({
  medicalPlans: [],
  currentCoverageMedicalPlan: {} as Contract,
  mappedMedicalContractCode: '',
  comparedMedicalPlans: [],
  selectedPlan: {
    plan: null,
    applicants: [],
    matchedProviders: [],
    matchedMedications: []
  },
  selectedTab: MEDICAL_PLANS_TABS.OFF_MARKET_PLANS,
  selectedOptionInEstimateSavings: MEDICAL_PLANS_TABS.OFF_MARKET_PLANS,
  monthlyCostFilters: FILTER_OPTIONS.MONTHLY_COST,
  medicalDeductibleFilters: FILTER_OPTIONS.MEDICAL_DEDUCTIBLES,
  sortOptions: SORT_OPTIONS,
  switchFilters: FILTER_OPTIONS.MORE_FILTERS.SWITCH_FILTERS,
  benefitsTiers: FILTER_OPTIONS.MORE_FILTERS.BENEFITS_TIER,
  favouriteMedicalPlans: [],
  showFavourites: false,
  updatePlans: (plans: Contracts) => set(() => ({ medicalPlans: plans })),
  updateComparedMedicalPlans: (plans: ComparedPlans) => set(() => ({ comparedMedicalPlans: plans })),
  updateSelectedPlan: (selectedPlan: SelectedPlan) => set(() => ({ selectedPlan: selectedPlan })),
  updateSelectedTab: (selectedTab: string) => set(() => ({ selectedTab: selectedTab })),
  updateSelectedOptionInEstimateSavings: (selectedOptionInEstimateSavings: string) =>
    set(() => ({ selectedOptionInEstimateSavings: selectedOptionInEstimateSavings })),
  updateMonthlyCostFilters: (monthlyCostFilters) => set(() => ({ monthlyCostFilters: monthlyCostFilters })),
  updateMedicleDeductibleFilters: (medicleDeductbibleFilters) => set(() => ({ medicalDeductibleFilters: medicleDeductbibleFilters })),
  updateSwitchFilters: (switchFilters) => set(() => ({ switchFilters: switchFilters })),
  updateSortOptions: (sortOptions) => set(() => ({ sortOptions: sortOptions })),
  updateBenefitsTiers: (benefitsTiers) => set(() => ({ benefitsTiers: benefitsTiers })),
  resetMedicalPlanStates: () =>
    set(() => ({
      medicalPlans: [],
      comparedMedicalPlans: [],
      selectedPlan: {
        plan: null,
        applicants: [],
        matchedProviders: [],
        matchedMedications: []
      },
      selectedTab: MEDICAL_PLANS_TABS.OFF_MARKET_PLANS,
      selectedOptionInEstimateSavings: MEDICAL_PLANS_TABS.OFF_MARKET_PLANS,
      monthlyCostFilters: FILTER_OPTIONS.MONTHLY_COST,
      medicalDeductibleFilters: FILTER_OPTIONS.MEDICAL_DEDUCTIBLES,
      sortOptions: SORT_OPTIONS,
      switchFilters: FILTER_OPTIONS.MORE_FILTERS.SWITCH_FILTERS,
      benefitsTiers: FILTER_OPTIONS.MORE_FILTERS.BENEFITS_TIER,
      favouriteMedicalPlans: [],
      showFavourites: false
    })),
  updateFavouriteMedicalPlans: (favouritePlansContractCodes: string[]) => set(() => ({ favouriteMedicalPlans: favouritePlansContractCodes })),
  updateShowFavourites: (value: boolean) => set(() => ({ showFavourites: value })),
  updateCurrentCoverageMedicalPlan: (plan: Contract, mappedContractCode: string) =>
    set(() => ({ currentCoverageMedicalPlan: plan, mappedMedicalContractCode: mappedContractCode })),
  resetMedicalPlanStatesOtherThanTab: () =>
    set(() => ({
      medicalPlans: [],
      comparedMedicalPlans: [],
      selectedPlan: {
        plan: null,
        applicants: [],
        matchedProviders: [],
        matchedMedications: []
      },
      monthlyCostFilters: FILTER_OPTIONS.MONTHLY_COST,
      medicalDeductibleFilters: FILTER_OPTIONS.MEDICAL_DEDUCTIBLES,
      sortOptions: SORT_OPTIONS,
      switchFilters: FILTER_OPTIONS.MORE_FILTERS.SWITCH_FILTERS,
      benefitsTiers: FILTER_OPTIONS.MORE_FILTERS.BENEFITS_TIER,
      favouriteMedicalPlans: [],
      showFavourites: false
    })),
  resetCurrentCoverageMedicalPlan: () =>
    set(() => ({
      currentCoverageMedicalPlan: {} as Contract,
      mappedMedicalContractCode: ''
    }))
});
