import { useUserStore } from '../../../../store/userStore';

const getShopperAgentConnectPayload = (agentInfo: AgentOfRecord) => {
  const { shopperId, webAccountGUID, webAccessId } = useUserStore.getState();

  const agentConnectPayload: SaveShopperAgentConnect = {
    agentConnect: {
      parentTin: agentInfo?.parentTin ?? '',
      agentType: agentInfo?.agentType ?? '',
      licenseNO: agentInfo?.licenseNO ?? '',
      phone: agentInfo?.phone ?? '',
      agencyName: agentInfo?.agencyName ?? '',
      brand: agentInfo?.brand ?? '',
      faxExt: agentInfo?.faxExt ?? '',
      agentId: agentInfo?.agentId ?? '',
      lastName: agentInfo?.lastName ?? '',
      npn: agentInfo?.npn ?? '',
      email: agentInfo?.email ?? '',
      agentCode: agentInfo?.agentCode ?? '',
      fax: agentInfo?.fax ?? '',
      firstName: agentInfo?.firstName ?? '',
      paidTIN: agentInfo?.paidTIN ?? '',
      phoneext: agentInfo?.phoneExtention ?? '',
      lobState: agentInfo?.lobState ?? '',
      address: {
        addressLine1: agentInfo?.address?.addressLine1 ?? '',
        zip: agentInfo?.address?.postalCode ?? '',
        city: agentInfo?.address?.city ?? '',
        state: agentInfo?.address?.state ?? ''
      },
      campaign: {
        cid: agentInfo?.campaign?.cid ?? ''
      },
      shopperId: shopperId ?? '',
      webAcctId: webAccessId ?? '',
      userId: webAccountGUID ?? ''
    }
  };
  return agentConnectPayload;
};

export { getShopperAgentConnectPayload };
