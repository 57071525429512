import React, { useCallback, useEffect, useState } from 'react';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { LANGUAGE_CODES, SCREEN_NAMES } from '../../../shared/globalConstants';
import { getFormattedCurrency } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import './SelectedPlans.scss';
import { default as Content } from './selectedPlans.json';
import { getIcon, getTotalAmount } from './selectedPlansUtils';

const SelectedPlans: React.FC<SelectedPlansProps> = ({ plans, handleChangeClick, screenPlanType }) => {
  const [pageContent, setContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const { zipCodeRes, brand } = useGlobalStore((store) => store);
  const getContent = useCallback(async (): Promise<boolean> => {
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.SELECTED_PLAN);
      let cmsResponse = response.data;

      setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.SELECTED_PLAN_CARD_LABELS);
    } catch (error) {
      setContent(Content.data.iospagecontentList.items[0].pageContent.SELECTED_PLAN_CARD_LABELS);

      return false;
    } finally {
      setContentLoaded(true);
    }
    return true;
  }, []);

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    initiate();
  }, []);
  //  const labels = content.data.iospagecontentList.items[0].pageContent?.SELECTED_PLAN_CARD_LABELS;
  return (
    <>
      {contentLoaded && plans.length > 0 && (
        <div className="selected-plans-wrapper">
          <div className="fwc-row fwc-row-bm">
            <div className="slected-plans-container">
              <div className="selected-plans-header">
                <h6>
                  {pageContent?.YOUR_SELECTED_PLAN} {`(${plans?.length})`}
                </h6>
                <h6>
                  {pageContent?.TOTAL} <span className="total-amount">{getTotalAmount(plans)}</span>
                </h6>
              </div>
              {plans?.map((plan) => {
                return (
                  <div key={plan.contractCode} className="plan-details">
                    <span className={`icon sae-icon ${getIcon(plan.plan[0].planType)}`} />
                    <h4 className="plan-name">{plan.planMarketingName}</h4>
                    <div className="change-button-wrapper">
                      {screenPlanType !== plan.plan[0].planType && (
                        <button data-analytics={"change" + plan.plan[0].planType.charAt(0).toUpperCase() + plan.plan[0].planType.slice(1).toLowerCase() + "PlanBtnPlansIos"} id="plan-change-button" className="fwc-btn fwc-btn-secondary small" onClick={() => handleChangeClick(plan.plan[0].planType)}>
                          {pageContent?.CHANGE}
                        </button>
                      )}
                    </div>
                    <span className="plan-amount">
                      {getFormattedCurrency(plan?.rateData?.rate?.totSubsidizedPrem ?? 0)}/{pageContent?.MONTH}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SelectedPlans;
