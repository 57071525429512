// import { DevTool } from "@hookform/devtools";
import { Button, Modal } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import { AGENT_ROLES, DEFAULT_YEAR, LANGUAGE_CODES, NAV_PATHS, NV_STATE, PLAN_TYPES, PLAN_TYPES_CONTENT, SCREEN_NAMES, SET_APPLICATION_CONSTANTS, TOBACCO_STATE_CODES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { MEDICAL_PLANS_TABS } from "../../public/medical-plans/constants";
import { getFormattedApplicantDetails, getSubsidyAmount } from "../../public/medical-plans/medicalPlansUtils";
import SubsidyBlockForm from "../subsidy-block-form/subsidyBlockForm";
import AgentHCIDApplicationInfo from './AgentHCIDApplicationInfo';
import { default as Content } from './agentHCIDBasicsLanding.json';
import './AgentHCIDBasicsLanding.scss';
import AgentHCIDComponent from './AgentHCIDComponent';
import AgentHCIDCoverages from './AgentHCIDCoverages';
import AgentZipcodeComponent from './AgentZipcodeComponent';

import { convertAgentApiDatatoFormDataFormat, replaceKeysInArrayOfObjects } from '../../../shared/utils/globalUtils';
const AgentHCIDBasicsLanding: React.FC = (props) => {


    const navigate = useNavigate()

    const [loading, setLoading] = useState(true);
    const [pageContent, setPageContent] = useState<any>(null);
    const [memberInfo, setMemberInfo] = useState<any>(null);
    const [openAlertModal, setAlertModalOpen] = useState(false);
    const [disableContinue, setDisableContinue] = useState(false);
    const [showSubsidyBlock, setShowSubsidyBlock] = useState<boolean>(false);
    const [dependentsDisable, setAddDependentsDisable] = useState<any>(false);
    const [zipError, setZipError] = useState<boolean>(false);
    /* --------- stores ------------*/

    const stores = {
        globalStore: useGlobalStore(),
        userStore: useUserStore(),
    };

    /* --------- stores ------------*/


    /* --------- Get Content ------------*/
    useEffect(() => {
        initiate();
        stores.globalStore.updateIsHCIDFlow(true);
    }, []);

    const initiate = async () => {
        setLoading(true);
        await getContent();
    };

    const getContent = useCallback(async (): Promise<boolean> => {
        let content: any = null;
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.AGENT_HCID_LANDING);
            let cmsResponse = response.data;
            content = cmsResponse.data.iospagecontentList.items[0].pageContent?.agentBasicsHcidLanding;
            //content = Content?.data.iospagecontentList.items[0].pageContent?.agentBasicsHcidLanding;

        } catch (error) {
            console.error('agentBasicsLanding.tsx getContent error: ', error);
            content = Content?.data.iospagecontentList.items[0].pageContent?.agentBasicsHcidLanding;
            return false;
        }
        finally {
            setPageContent(content);
            setLoading(false);
        }
        return true;
    }, []);

    /* --------- Get Content ------------*/

    /*---------- HCID getmember info ------------*/
    useEffect(() => {
        if (stores.globalStore.agentPlanTypes.length > 0) {
            if (stores.globalStore.agentCoverageType === PLAN_TYPES.MEDICAL) {
                stores.globalStore.agentPlanTypes.includes(PLAN_TYPES_CONTENT[stores.globalStore.agentCoverageType]) ? setDisableContinue(false) : setDisableContinue(true);
            } else {
                stores.globalStore.agentPlanTypes.includes(PLAN_TYPES_CONTENT.D) ? setDisableContinue(false) : setDisableContinue(true);
            }
        }
    }, [stores.globalStore.agentPlanTypes, stores.globalStore.agentCoverageType]);
    /*----------------- store data --------------*/
    let storeFormData = stores.globalStore.agentApplicantFormDetails.length > 0 ? convertAgentApiDatatoFormDataFormat(stores.globalStore.agentApplicantFormDetails) : convertAgentApiDatatoFormDataFormat(replaceKeysInArrayOfObjects(stores.globalStore.agentSSOInfo.applicantInfo?.applicants?.applicant, ['relationship', 'tobaccoUsage'], ['applicantType', 'isTabaccoUser']));
    /* ------------------ FORM -----------------*/
    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            hcid: '',
            zipcode: '',
            county: '',
            year: '',
            coverageType: '',
            agentTin: '',
            marketingRefId: '',
            applicant: {
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                applicantType: 'PRIMARY',
                isTabaccoUser: (stores.globalStore.agentState && stores.globalStore.agentState === SET_APPLICATION_CONSTANTS.KY && stores.globalStore.agentYear !== DEFAULT_YEAR) ? SET_APPLICATION_CONSTANTS.NO : !((stores.globalStore.agentState && TOBACCO_STATE_CODES.includes(stores.globalStore.agentState))) ? SET_APPLICATION_CONSTANTS.NO : '',
                email: '',
                gender: undefined,
                SSN: '',
                applicantId: '0',
                isPolicyApplicant: 'NO',
            },
            spouse: [],
            dependents: []
        },
        values: {
            hcid: stores.globalStore.agentHCID,
            zipcode: stores.globalStore.agentZipcode,
            county: stores.globalStore.agentCounty,
            year: stores.globalStore.agentYear,
            coverageType: stores.globalStore.agentCoverageType,
            agentTin: stores.globalStore.agentTin,
            marketingRefId: stores.globalStore.agentMarketingRefId,
            applicant: { ...storeFormData.applicant },
            spouse: storeFormData.spouse,
            dependents: storeFormData.dependents
        }
    });

    const { errors } = methods.formState;
    /* ------ form submit ---------*/

    const onSubmit = (data) => {
        if (zipError) {
            methods.setFocus('zipcode');
        } else {
            methods.trigger();
            let applicants = [data.applicant, ...data.spouse, ...data.dependents];
            stores.globalStore.agentUpdateApplicantFormDetails(applicants);
            showAgentSubsidy(stores.globalStore.agentSSOInfo, stores.globalStore.agentState, stores.globalStore.agentCoverageType, data);
        }
    }
    /* ------ form submit ---------*/

    const showAgentSubsidy = (agentSSO: AgentSSOInfo, stateCode: string, coverageType: string, data?) => {
        if (agentSSO.agentInfo.agent.length > 0) {
            const selectedStateObj = agentSSO.agentInfo.agent[0].states.state.find((state) => state.stateName === stateCode);
            const agentType = agentSSO.agentType;
            if (agentType === AGENT_ROLES.HPA && (coverageType === PLAN_TYPES.MEDICAL || coverageType === PLAN_TYPES.MDV)) {
                setShowSubsidyBlock(true);
                return true;
            } else if (agentType !== AGENT_ROLES.HPA && stateCode === NV_STATE && (selectedStateObj && selectedStateObj.licenseNumber && selectedStateObj.licenseNumber !== '') && (selectedStateObj && selectedStateObj.FFM && selectedStateObj.FFM !== '') && (coverageType === PLAN_TYPES.MEDICAL || coverageType === PLAN_TYPES.MDV)) {
                setShowSubsidyBlock(true);
                return true;
            } else if (agentType !== AGENT_ROLES.HPA && ((selectedStateObj && selectedStateObj.licenseNumber && selectedStateObj.licenseNumber !== '') && ((selectedStateObj && selectedStateObj.FFM && selectedStateObj.FFM !== '') || (selectedStateObj && selectedStateObj.certNo && selectedStateObj.certNo !== ''))) && (coverageType === PLAN_TYPES.MEDICAL || coverageType === PLAN_TYPES.MDV)) {
                setShowSubsidyBlock(true);
                return true;
            } else {
                setShowSubsidyBlock(false);
                if (data) {
                    formSubmit(data);
                }
                return false;
            }
        } else {
            setShowSubsidyBlock(false);
            if (data) {
                formSubmit(data);
            }
            return false;
        }
    }

    const formSubmit = (data) => {
        const formData = [{ ...data.applicant }, ...data.spouse, ...data.dependents];
        stores.globalStore.resetProductStates();
        stores.globalStore.resetDrugStoreStates();
        stores.globalStore.resetProviderStates();
        const isSubsidyApplied = getSubsidyAmount(useGlobalStore.getState().subsidyresponse)
        stores.globalStore.updateSelectedProduct({
            ...stores.globalStore.selectedProduct,
            medicalApplicants: [],
            dentalApplicants: [],
            visionApplicants: []
        })
        if (isSubsidyApplied) {
            useGlobalStore.getState().updateSelectedProductTab(MEDICAL_PLANS_TABS.MARKET_PLANS)
        } else {
            useGlobalStore.getState().updateSelectedProductTab(MEDICAL_PLANS_TABS.OFF_MARKET_PLANS)
        }

        const dependentsLength = [...data.spouse, ...data.dependents].length;
        const isAnthemBrand = !stores.userStore.isWellpoint;
        const updatedSelectedProduct = { ...stores.globalStore.selectedProduct };

        if (dependentsLength > 0 && isAnthemBrand && stores.globalStore.agentCoverageType !== PLAN_TYPES.VISION) {
            switch (stores.globalStore.agentCoverageType) {
                case PLAN_TYPES.MDV:
                    updatedSelectedProduct.medicalApplicants = getFormattedApplicantDetails(formData);
                    stores.globalStore.updateSelectedProduct(updatedSelectedProduct)
                    break;
                case PLAN_TYPES.DENTAL:
                    updatedSelectedProduct.dentalApplicants = getFormattedApplicantDetails(formData);
                    stores.globalStore.updateSelectedProduct(updatedSelectedProduct)
                    break;
                default:
                    navigate(NAV_PATHS.PRODUCT_SELECTION);
                    break;
            }
            stores.globalStore.updateOpenATKDepModal(stores.globalStore.agentCoverageType, true, false, '');
        } else {

            switch (stores.globalStore.agentCoverageType) {
                case PLAN_TYPES.MEDICAL:
                    updatedSelectedProduct.medicalApplicants = getFormattedApplicantDetails(formData);
                    stores.globalStore.updateSelectedProduct(updatedSelectedProduct)
                    break;
                case PLAN_TYPES.MDV:
                    updatedSelectedProduct.medicalApplicants = getFormattedApplicantDetails(formData);
                    updatedSelectedProduct.dentalApplicants = getFormattedApplicantDetails(formData);
                    updatedSelectedProduct.visionApplicants = getFormattedApplicantDetails(formData);
                    stores.globalStore.updateSelectedProduct(updatedSelectedProduct)
                    break;
                case PLAN_TYPES.DENTAL:
                    updatedSelectedProduct.dentalApplicants = getFormattedApplicantDetails(formData);
                    updatedSelectedProduct.visionApplicants = getFormattedApplicantDetails(formData);
                    stores.globalStore.updateSelectedProduct(updatedSelectedProduct)
                    break;
                case PLAN_TYPES.VISION:
                    updatedSelectedProduct.visionApplicants = getFormattedApplicantDetails(formData);
                    stores.globalStore.updateSelectedProduct(updatedSelectedProduct)
                    break;
                default:
                    navigate(NAV_PATHS.PRODUCT_SELECTION);
                    break;
            }
            navigate(NAV_PATHS.PRODUCT_SELECTION);
        }
        if (showSubsidyBlock) {
            stores.globalStore.agentUpdateFormSubmitedWithSubsidy(true);
        }
    }

    const editBasicsForm = () => {
        stores.globalStore.resetHouseHoldMemberValues();
        stores.globalStore.resetEstimateSavings();
        stores.globalStore.agentUpdateFormSubmitedWithSubsidy(false);
        setShowSubsidyBlock(false);
    }

    const checkIfBasePlanSelected = (value) => {
        const valueToBeSet = (stores.globalStore.agentMacPlanTypes.length === 1 && stores.globalStore.agentMacPlanTypes[0] === PLAN_TYPES_CONTENT.V) ? false : (value === PLAN_TYPES.MDV ? PLAN_TYPES.MEDICAL !== PLAN_TYPES[stores.globalStore.agentMacPlanTypes[0]] : value !== PLAN_TYPES[stores.globalStore.agentMacPlanTypes[0]]);
        setAddDependentsDisable(valueToBeSet);
    }

    useEffect(() => {
        if (stores.globalStore.agentCoverageType !== '') {
            checkIfBasePlanSelected(stores.globalStore.agentCoverageType);
        }
    }, [stores.globalStore.agentCoverageType]);

    /*----- Dependents alert Alert modal modal ----*/
    const closeAlertPopup = () => {
        setAlertModalOpen(false);
    }
    /*----- Dependents alert Alert modal modal ----*/

    /* ----------- hcid outputs -----------*/
    const handleMemberInfo = (memberInfo) => {
        setMemberInfo(memberInfo);
    }

    const handleZipError = (value) => {
        setZipError(value);
    }
    /* ----------- hcid outputs -----------*/

    const closeTab = () => {
        navigate(NAV_PATHS.ATK_SESSION_END);
    }

    if (loading) {
        return <FullPageLoader />
    } else {
        return (
            <>
                <div className={'agent-landing-container'}>
                    <div className='fwc-row'>
                        <div className='fwc-col-12'>
                            {dependentsDisable && <p className="custom-alert">
                                <span className="sae-icon sae-icon-error"></span>
                                {pageContent?.ALERTS.ZIPCODE}
                            </p>}
                        </div>
                    </div>
                    <div className='fwc-row'>
                        <div className='fwc-col-12'>
                            <div className='title-wrapper'>
                                <h2 className='title'>{pageContent.CONTENT.TITLE}</h2>
                                <p>{pageContent.CONTENT.TITLE_DESC}</p>
                            </div>
                            <h4 className='sub-title'>{pageContent.CONTENT.SUB_TITLE}</h4>
                        </div>
                    </div>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <div className="row-relative">
                                {showSubsidyBlock && <div className='overlayCustom'></div>}
                                <AgentHCIDComponent pageContent={pageContent} memberInfo={handleMemberInfo}></AgentHCIDComponent>
                                <AgentZipcodeComponent pageContent={pageContent} memberInfo={memberInfo} dependentsDisable={dependentsDisable} zipCodeError={handleZipError}></AgentZipcodeComponent>
                                <AgentHCIDCoverages pageContent={pageContent} memberInfo={memberInfo} dependentsDisable={dependentsDisable}></AgentHCIDCoverages>
                                <AgentHCIDApplicationInfo pageContent={pageContent} memberInfo={memberInfo} errors={errors} dependentsDisable={dependentsDisable}></AgentHCIDApplicationInfo>
                            </div>
                            {!showSubsidyBlock && <div className="form-actions-wrapper">
                                <div className="fwc-row">
                                    <div className="fwc-col-12 submit-button">
                                        <button className="fwc-btn fwc-btn-secondary" type="button" id="cancel" onClick={() => closeTab()}>
                                            {pageContent?.FORM_CONTENT.LABELS.CANCEL}
                                        </button>
                                        <Button data-analytics="continueBtnBasicsIos" id="btContinue" role="submit" type="submit" disabled={disableContinue}>
                                            {pageContent?.FORM_CONTENT.LABELS.CONTINUE}
                                        </Button>
                                    </div>
                                </div>
                            </div>}
                            {showSubsidyBlock &&
                                <div className='subsidyBlock'>
                                    <SubsidyBlockForm></SubsidyBlockForm>
                                    <div className='fwc-row'>
                                        <div className='fwc-col-12 submit-button'>
                                            <div>
                                                <button className="fwc-btn fwc-btn-secondary cancel-button" type="button" id="cancel" onClick={() => closeTab()}>
                                                    {pageContent?.FORM_CONTENT.LABELS.CANCEL}
                                                </button>
                                                <button data-analytics="continueBtnBasicsIos" className="fwc-btn fwc-btn-secondary" id="btContinue" onClick={() => editBasicsForm()}>
                                                    Edit Basics
                                                </button>
                                            </div>
                                            <Button data-analytics="continueBtnBasicsIos" id="btContinue" onClick={() => formSubmit(methods.getValues())}>
                                                {pageContent?.FORM_CONTENT.LABELS.CONTINUE}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </form>
                    </FormProvider>

                    {/* <DevTool control={methods.control} /> */}
                </div >
                <div className='alertModals'>
                    {openAlertModal && <Modal
                        id="alertModal"
                        onClose={closeAlertPopup}
                        className={'alertModal'}
                        title={'You May Need To Re-Add Dependents'}
                        open={openAlertModal}
                    >
                        <Modal.Body>
                            <p>{pageContent.MODALS.ALERT_MODAL.DESCRIPTION}</p>
                        </Modal.Body>
                    </Modal>}
                </div>
            </>
        );
    }
};

export default AgentHCIDBasicsLanding;
