import React, { useCallback } from 'react';
import './InputSelect.scss';
import PropTypes from 'prop-types';

function InputSelect(props) {
  return (
    <div className={'fwc-input-select-combo'}>
      <div className={'fwc-input'}>
        <label className={'fwc-label'}>{props.label}</label>
        <input
          id={props.inputId}
          value={props.inputValue}
          name={props.inputName}
          onChange={props.onInputChange}
          onBlur={props.onInputBlur}
        />
      </div>
      <div className={'fwc-select-list'}>
        <select
          id={props.selectId}
          onChange={props.onSelectChange}
          name={props.selectName}
          value={props.selectValue ? props.selectValue : ''}
        >
          {props.selectPlaceholder && (
            <option key={'placeholder'} value={props.selectPlaceholder}>
              {props.selectPlaceholder}
            </option>
          )}
          {props.options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
export default InputSelect;

InputSelect.propTypes = {
  label: PropTypes.string,
  inputId: PropTypes.string,
  inputName: PropTypes.string,
  inputValue: PropTypes.string,
  onSelectChange: PropTypes.func,
  onInputChange: PropTypes.func,
  onInputBlur: PropTypes.func,
  options: PropTypes.array,
  selectId: PropTypes.string,
  selectName: PropTypes.string,
  selectPlaceholder: PropTypes.string,
  selectValue: PropTypes.string,
};
