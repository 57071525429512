import noresult from '../../../images/no_results.svg';
import serverError from '../../../images/service_error.svg';
import { EMPTY_PLANS_USE_CASES } from './constants';

const getImage = (useCase: string): string | undefined => {
  switch (useCase) {
    case EMPTY_PLANS_USE_CASES.NO_PLANS_AVAILABLE:
    case EMPTY_PLANS_USE_CASES.NO_PLANS_AVAILABLE_WITH_FILTERS:
      return noresult;
    case EMPTY_PLANS_USE_CASES.SERVER_ERROR:
      return serverError;
    default:
      return undefined;
  }
};

const getTitle = (useCase: string, labels: EmptyPlansLabels): string => {
  switch (useCase) {
    case EMPTY_PLANS_USE_CASES.NO_PLANS_AVAILABLE:
      return labels?.NO_MEDICAL_PLANS_TO_SHOW ?? '';
    case EMPTY_PLANS_USE_CASES.NO_PLANS_AVAILABLE_WITH_FILTERS:
      return labels.NO_MATCHING_PLANS_FOUND ?? '';
    case EMPTY_PLANS_USE_CASES.SERVER_ERROR:
      return labels?.SOMETHING_WENT_WRONG ?? '';
    default:
      return '';
  }
};

const getDescriptionOne = (useCase: string, labels: EmptyPlansLabels): string => {
  switch (useCase) {
    case EMPTY_PLANS_USE_CASES.NO_PLANS_AVAILABLE:
      return labels?.SORRY_NO_MDEDICAL_PLANS_AVAILABLE ?? '';
    case EMPTY_PLANS_USE_CASES.NO_PLANS_AVAILABLE_WITH_FILTERS:
      return labels?.CONSIDER_ADJUSTING_YOUR_FILTERS ?? '';
    case EMPTY_PLANS_USE_CASES.SERVER_ERROR:
      return labels?.OUR_SYSTEM_IS_FACING_ISSUES ?? '';
    default:
      return '';
  }
};

const getDescriptionTwo = (useCase: string, labels: EmptyPlansLabels): string => {
  switch (useCase) {
    case EMPTY_PLANS_USE_CASES.NO_PLANS_AVAILABLE:
      return '';
    case EMPTY_PLANS_USE_CASES.SERVER_ERROR:
      return labels?.PLEASE_TRY_AGAIN_LATER ?? '';
    default:
      return '';
  }
};

export { getDescriptionOne, getDescriptionTwo, getImage, getTitle };
