//Add service functions here

import axiosInstance from '../../../api/axiosWrapper/axiosWrapper';
import { API_NAME_LIST } from '../../../api/api-name-list';
import { getPayLoadForSubsidy } from './estimateSavings.util';

const subsidyCalculator = (data, zipcode, countyCode, coverageDate, applicantFormDetails) => {
  const payload = getPayLoadForSubsidy(data, zipcode, countyCode, coverageDate, applicantFormDetails);
  return axiosInstance.post(API_NAME_LIST.SUBSIDY_CALCULATOR, payload);
};

export { subsidyCalculator };
