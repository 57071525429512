import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { FwcProgressStandard } from "../../../fwc-components/fwc-lib";
import { useGlobalStore } from "../../../store/globalStore";
import MotifProgressStandard from "../motif-components/MotifProgressStandard";
import './QuoteProgress.scss';



function QuoteProgress(props: any) {
    const { progressSteps, updateProgressSteps, setPreviousStep } = useGlobalStore(state => state);
    const location = useLocation();
    useEffect(() => {
        updateProgressSteps(progressSteps);
    }, []);


    useEffect(() => {
        const indexOfPath = progressSteps.findIndex(step => step.url === location.pathname);
        const setPreviousUrl = () => {
            if (indexOfPath > 0) {
                const previousStepUrl = progressSteps[indexOfPath - 1].url;
                setPreviousStep(previousStepUrl);
            }
        };
        setPreviousUrl();
        if (indexOfPath > -1) {
            updateSteps(progressSteps[indexOfPath].number);
        }
    }, [location]);

    useEffect(() => {
        const fwcProgressStandard = new FwcProgressStandard();
        fwcProgressStandard.build();
    });

    const updateSteps = (stepNumber: number) => {
        progressSteps.forEach((step, index) => {
            step.current = index === stepNumber;
            step.complete = step.number < stepNumber;
        });
        updateProgressSteps(progressSteps);
    }

    return (
        <div className="quote-progress">
            <MotifProgressStandard type={'horizontal'} dark={true} steps={progressSteps} id="quote-progress" />
        </div>
    );
}
export default QuoteProgress;
