import { API_NAME_LIST } from '../../../api/api-name-list';
import axiosInstance from '../../../api/axiosWrapper/axiosWrapper';
import { TWO_FA_USE_CASES } from './constants';

const sendOtp = (payload: SendOtpPayload, useCase: string) => {
  const endPoint =
    useCase === TWO_FA_USE_CASES.LOGIN
      ? API_NAME_LIST.LOGIN_SEND_OTP
      : API_NAME_LIST.REGISTER_SEND_OTP;

  return axiosInstance.post(endPoint, payload);
};

const forgotPwdSendOtp = (payload: ForgotPwdSendOtpPayload) => {
  return axiosInstance.post(API_NAME_LIST.FORGOT_PWD_SEND_OTP, payload);
};

const forgotUsernameSendOtp = (payload: ForgotUsernameSendOtpPayload) => {
  return axiosInstance.post(API_NAME_LIST.FORGOT_USERNAME_SEND_OTP, payload);
};

const validateOTPAndLogin = (payload: ValidateOTPPayload) => {
  return axiosInstance.post(API_NAME_LIST.LOGIN_VALIDATE_OTP, payload);
};

const validateOTPAndRegister = (payload: ValidateOTPAndRegPayload) => {
  return axiosInstance.post(API_NAME_LIST.VALIDATE_OTP_AND_REGISTER, payload);
};

const forgotPwdValidateOTP = (payload: ForgotPwdValidateOtpPayload) => {
  return axiosInstance.post(API_NAME_LIST.FORGOT_PWD_VALIDATEOTP, payload);
};

const changePassword = (payload: ChangePasswordPayload) => {
  return axiosInstance.post(API_NAME_LIST.CHANGE_PASSWORD, payload);
};

const validateOTPAndEmailUsername = (
  payload: ForgotUsernameValidateOtpPayload
) => {
  return axiosInstance.post(API_NAME_LIST.VALIDATE_OTP_EMAIL_USERNAME, payload);
};

const validateAnswerAndLogin = (payload: LoginwithQAndAPayload) => {
  return axiosInstance.post(API_NAME_LIST.LOGIN_VALIDATE_OTP, payload);
};

const validateAnswerAndEmailUsername = (
  payload: EmailUsernamewithQAndAPayload
) => {
  return axiosInstance.post(API_NAME_LIST.VALIDATE_OTP_EMAIL_USERNAME, payload);
};

const fogotPasswordValidateAnswer = (
  payload: ForgotPasswordWithQAndAPayload
) => {
  return axiosInstance.post(API_NAME_LIST.FORGOT_PWD_VALIDATEOTP, payload);
};

export {
  sendOtp,
  validateOTPAndLogin,
  validateOTPAndRegister,
  forgotPwdSendOtp,
  forgotPwdValidateOTP,
  changePassword,
  forgotUsernameSendOtp,
  validateOTPAndEmailUsername,
  validateAnswerAndLogin,
  validateAnswerAndEmailUsername,
  fogotPasswordValidateAnswer,
};
