import { ResponsiveGrid } from "@adobe/aem-react-editable-components";
import { Button, Col, Modal, PageHeader, PreLoader, Row, Title } from "@sydney-broker-ui/ios";
import React, { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getCMSStaticContent } from "../../../api/cms/cmsUtil";
import { HTTP_STATUS_CODES } from "../../../api/constants";
import { getVisionRecommendations } from "../../../api/services/utilService";
import DependentSelectionModal from "../../../components/common/dependent-selection-modal/DependentSelectionModal";
import GlobalFooter from "../../../components/common/global-footer/GlobalFooter";
import GlobalHeader from "../../../components/common/global-header/GlobalHeader";
import InfoBar from "../../../components/common/info-bar/InfoBar";
import PublicNavBar from "../../../components/common/public-nav-bar/PublicNavBar";
import QuoteProgress from "../../../components/common/quote-progress/QuoteProgress";
import RecommendationModal from "../../../components/common/recommendation-modal/RecommendationModal";
import { getVisionRecommendationPayload } from "../../../components/common/recommendation-modal/RecommendationModalUtils";
import { VISION_RECOMMENDATION_RESPONSE_CODES } from "../../../components/common/recommendation-modal/constants";
import { AEM_PATHS, ERROR_ALERT_CONFIG, LANGUAGE_CODES, NAV_PATHS, PLAN_TYPES, PLAN_TYPES_NAMES, SCREEN_NAMES } from "../../../shared/globalConstants";
import { getNotMedicaidEligibleDependents } from "../../../shared/utils/globalUtils";
import { useGlobalStore } from "../../../store/globalStore";
import { useUserStore } from "../../../store/userStore";
import { getPrimaryProductTypeByCoverageType } from "../../secure/product-selection/productSelectionUtils";
import { getFormattedApplicantDetails } from "../medical-plans/medicalPlansUtils";
import './Basics.scss';
import { default as BasicsContent } from './basics.json';
function Basics() {
    const [basicsPageContent, setBacicsContent] = useState<any>(null);
    const [selectedDependents, setSelectedDependents] = useState<Applicant[]>([]);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [showRecommendationError, setShowRecommendationError] = useState(false);
    const [loader, setLoader] = useState(false);
    const [loginError, setLoginError] = useState({
        hasError: false,
        responseCode: '',
        message: ''
    });
    const location = useLocation()
    const { isWellpoint } = useUserStore((state) => state);

    const locale = window.location.href.includes('/es/') ? AEM_PATHS.SPANISH : AEM_PATHS.ENGLISH;
    const PAGE_PATH = isWellpoint ? `/content/ios/wellpoint/us/${locale}/individuals/shop/basics` : `/content/ios/anthem/us/${locale}/individuals/shop/basics`;

    const { agentSSOInfo, selectedProduct, isPlanSummaryEditable, state, zipcode, coverageDate, zipCodeRes, applicantFormDetails, updateSelectedPlan, updateSelectedDentalPlan, resetDentalPlanStates, resetVisionPlanStates, basicPlanType, isEditCoverage, updateIsEditCoverage, selectedPlan, selectedDentalPlan,
        updateSelectedVisionPlan, updateBasicPlanType, updateSelectedProduct, selectedVisionPlan, updateOpenDepModal, openDepModal, agentCoverageType, openATKDepModal, updateOpenATKDepModal, updateCloseDepModal, showRecommendationModal, updateShowRecommendationModal, updateVisionRecommendations } = useGlobalStore((store) => store);
    let primaryProductType = getPrimaryProductTypeByCoverageType(agentCoverageType);

    const navigate = useNavigate();

    const getContent = useCallback(async (): Promise<boolean> => {
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.BASICS);
            let cmsResponse = response.data;

            setBacicsContent(cmsResponse.data.iospagecontentList.items[0].pageContent?.Basics);
        } catch (error) {
            setBacicsContent(BasicsContent.data.iospagecontentList.items[0].pageContent.Basics);

            return false;
        } finally {
            setContentLoaded(true);
        }
        return true;
    }, [zipCodeRes.stateCode]);

    const initiate = async () => {
        await getContent();
    };

    useEffect(() => {
        initiate();
    }, []);

    const continueWithSelectedPlan = () => {
        document.body.classList.remove("no-scroll");
        updateOpenDepModal(basicPlanType, false, false, '')
        updateIsEditCoverage(false)
        if (isEditCoverage) {
            if (basicPlanType === PLAN_TYPES_NAMES.MEDICAL) {
                updateSelectedPlan({
                    plan: selectedPlan.plan,
                    applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(applicantFormDetails)),
                    matchedProviders: selectedPlan.matchedProviders,
                    matchedMedications: selectedPlan.matchedMedications
                });
                resetDentalPlanStates();
                updateSelectedDentalPlan({
                    plan: null,
                    applicants: getFormattedApplicantDetails(selectedDependents),
                    matchedProviders: []
                });
                updateBasicPlanType(PLAN_TYPES_NAMES.DENTAL);
            } else {
                updateSelectedDentalPlan({
                    plan: selectedDentalPlan.plan,
                    applicants: selectedDentalPlan.applicants,
                    matchedProviders: selectedDentalPlan.matchedProviders,
                });
                resetVisionPlanStates()
                updateSelectedVisionPlan({
                    plan: null,
                    applicants: getFormattedApplicantDetails(selectedDependents),
                    matchedProviders: []
                });
                updateBasicPlanType(PLAN_TYPES_NAMES.VISION);
            }
        } else {
            if (basicPlanType === PLAN_TYPES_NAMES.MEDICAL) {
                updateSelectedDentalPlan({
                    plan: null,
                    applicants: getFormattedApplicantDetails(selectedDependents),
                    matchedProviders: []
                });
            } else {
                updateSelectedVisionPlan({
                    plan: null,
                    applicants: getFormattedApplicantDetails(selectedDependents),
                    matchedProviders: []
                });
            }
            updateCloseDepModal(basicPlanType, false, true)
        }
    };

    const closeATKDependentModal = () => {
        updateOpenATKDepModal('', false, false, '');
        navigate(NAV_PATHS.PRODUCT_SELECTION);
    }

    const closeDependentModal = () => {
        updateOpenDepModal(basicPlanType, false, false, '')
        updateIsEditCoverage(false)
        if (!isEditCoverage) {
            updateCloseDepModal(basicPlanType, true, false);
        }
    }
    const handleVisionRecommendationError = () => {
        setLoader(false);
        updateVisionRecommendations([], []);
        setLoginError({
            hasError: true,
            responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: basicsPageContent?.RECOMMENDATION_MODAL.SYSTEM_ERROR
        });
    }
    const continueWithRecommendation = async () => {
        if (loginError.hasError) {
            setLoginError({
                hasError: false,
                responseCode: '',
                message: ''
            });
            updateShowRecommendationModal(false);
            navigate(NAV_PATHS.VISION_LISTING);
        } else {
            if (selectedOption === '') {
                setShowRecommendationError(true);
            } else {
                setShowRecommendationError(false);
                setLoader(true);
                try {
                    const paylaod: VisionRecommendationPayload = getVisionRecommendationPayload(selectedVisionPlan.applicants, coverageDate, state, zipcode, selectedOption);
                    getVisionRecommendations(paylaod)
                        .then((visionRecommendationsResponse) => {
                            if (visionRecommendationsResponse.data.responseMessage.responseCode === VISION_RECOMMENDATION_RESPONSE_CODES.SUCCESS) {
                                setLoader(false);
                                const recommendedPlanContractCodes = visionRecommendationsResponse.data.ProductList.map((item) => item.ContractCd);
                                updateVisionRecommendations(recommendedPlanContractCodes, [])
                                setSelectedOption('');
                                updateShowRecommendationModal(false);
                                navigate(NAV_PATHS.VISION_LISTING);
                            } else {
                                handleVisionRecommendationError();
                            }
                        }).catch((error) => {
                            console.warn('Basics.tsx Vision Recommendation error :', error);
                            handleVisionRecommendationError();
                        });
                } catch (error) {
                    console.warn('Basics.tsx Vision Recommendation error :', error);
                    handleVisionRecommendationError();
                }
            }
        }

    }

    const handleRecommendationClose = () => {
        setSelectedOption('');
        updateShowRecommendationModal(false);
        setLoader(false)
        document.body.classList.remove("no-scroll");
        setShowRecommendationError(false);
        setLoginError({
            hasError: false,
            message: '',
            responseCode: ''
        });
    }

    return (
        <div>
            <GlobalHeader notClickable={!isPlanSummaryEditable} />
            <div className={'header-container'}>
                <PageHeader>
                    <Row alignItems="center">
                        <Col
                            bottomMargin
                            lg="5"
                            sm="12"
                        >
                            <Title>
                                {basicsPageContent?.NEW_QUOTE}
                            </Title>
                        </Col>
                        {isPlanSummaryEditable && <Col
                            lg="7"
                            sm="12"
                        ><QuoteProgress />
                        </Col>
                        }
                    </Row>
                </PageHeader>
                {isPlanSummaryEditable && location.pathname !== NAV_PATHS.AGENTS_BASICS && <PublicNavBar hideSaveAndResumeLater={true} />}
            </div>
            <Outlet />
            <ResponsiveGrid
                // @ts-ignore
                pagePath={PAGE_PATH}
                itemPath="root/responsivegrid"
            />
            <GlobalFooter />
            {(openATKDepModal.show && contentLoaded && agentSSOInfo.isAtk && openATKDepModal.planType !== PLAN_TYPES.MEDICAL) &&
                <Modal id={'dependent-modal'} open={openATKDepModal.show} onClose={() => {
                    selectedProduct.medicalApplicants = [];
                    selectedProduct.visionApplicants = [];
                    selectedProduct.dentalApplicants = [];
                    updateSelectedProduct(selectedProduct);
                    updateOpenATKDepModal('', false, false, '');
                }}
                    title={agentCoverageType === PLAN_TYPES.DENTAL ? basicsPageContent?.ATK?.VISION_ONLY_TITLE : basicsPageContent?.ATK?.DENTAL_VISION_TITLE}>
                    <Modal.Body>
                        {primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT && <DependentSelectionModal
                            planType={PLAN_TYPES_NAMES.DENTAL_PRODUCT}
                            labels={basicsPageContent.DENTAL_WITH_DEPENDENTS}
                            onDependentClicked={(value: Applicant[]) => {
                                selectedProduct.dentalApplicants = value;
                                updateSelectedProduct(selectedProduct);
                            }}
                            isEditCoverage={isEditCoverage}
                        />}
                        <br></br>
                        {(primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT || primaryProductType === PLAN_TYPES_NAMES.DENTAL_PRODUCT) && <DependentSelectionModal
                            planType={PLAN_TYPES_NAMES.VISION_PRODUCT}
                            labels={basicsPageContent.VISION_WITH_DEPENDENTS}
                            onDependentClicked={(value: Applicant[]) => {
                                selectedProduct.visionApplicants = value;
                                updateSelectedProduct(selectedProduct);
                            }}
                            isEditCoverage={isEditCoverage}
                        />}
                    </Modal.Body>
                    <Modal.Footer>
                        {' '}
                        <Button
                            className="dependent-continue-button"
                            onClick={() => {
                                closeATKDependentModal();
                            }}
                        >
                            {basicPlanType === PLAN_TYPES_NAMES.MEDICAL ? basicsPageContent?.DENTAL_DEPENDENT_MODAL.CONTINUE : basicsPageContent?.VISION_DEPENDENT_MODAL.CONTINUE}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            {(openDepModal.show && contentLoaded) &&
                <Modal id={'dependent-modal'} open={openDepModal.show} onClose={closeDependentModal}
                    title={basicsPageContent[openDepModal.popUpType]?.TITLE}>
                    <Modal.Body>
                        <DependentSelectionModal
                            planType={basicPlanType === PLAN_TYPES_NAMES.MEDICAL ? PLAN_TYPES_NAMES.DENTAL : PLAN_TYPES_NAMES.VISION}
                            labels={basicsPageContent[openDepModal.popUpType]}
                            onDependentClicked={(value: Applicant[]) => {
                                setSelectedDependents(value)
                            }}
                            isEditCoverage={isEditCoverage}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        {' '}
                        <Button
                            className="dependent-continue-button"
                            onClick={() => {
                                continueWithSelectedPlan();
                            }}
                        >
                            {basicPlanType === PLAN_TYPES_NAMES.MEDICAL ? basicsPageContent?.DENTAL_DEPENDENT_MODAL.CONTINUE : basicsPageContent?.VISION_DEPENDENT_MODAL.CONTINUE}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

            {showRecommendationModal && contentLoaded &&
                <Modal open={showRecommendationModal} onClose={handleRecommendationClose} title={basicsPageContent?.RECOMMENDATION_MODAL.TITLE}>
                    <Modal.Body>
                        {loader ?
                            <PreLoader id={'two-fa-modal-loader'} /> :
                            <>
                                <RecommendationModal labels={basicsPageContent?.RECOMMENDATION_MODAL} selectedOption={selectedOption} onOptionChange={(value) => {
                                    setSelectedOption(value)
                                    setShowRecommendationError(false);
                                }} />
                                <div className="fwc-input vision-benefit-selection-error-wrp">
                                    {showRecommendationError && (
                                        <span id="vision-benefit-selection-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                                            {basicsPageContent?.RECOMMENDATION_MODAL.ERROR_MESSAGE}
                                        </span>
                                    )}
                                    {loginError.hasError && (
                                        <InfoBar
                                            area={ERROR_ALERT_CONFIG.AREA}
                                            labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
                                            type={ERROR_ALERT_CONFIG.ERROR}
                                            handleClose={() => {
                                                setLoginError({
                                                    hasError: false,
                                                    message: '',
                                                    responseCode: ''
                                                });
                                            }}
                                        >
                                            <p className="message">{loginError.message}</p>
                                        </InfoBar>
                                    )}
                                </div>
                            </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {' '}
                        <Button
                            data-analytics={"continueBtnBenPremModalIos"}
                            className="recommendation-continue-button"
                            id="recommendation-continue-btn"
                            onClick={() => {
                                continueWithRecommendation();
                            }}
                        >
                            {basicsPageContent?.RECOMMENDATION_MODAL.CONTINUE}
                        </Button>
                    </Modal.Footer>
                </Modal>

            }

        </div>
    );
}
export default Basics;
