import React from 'react';
import InfoBar from '../../common/info-bar/InfoBar';

const TextRich = (props) => {
  const text = props.infobartext;
  const infoAlert = {
    type: 'info',
    area: 'global'
  };
  const handleClose = () => {
    // console.log('close');
  };
  const id = props.id ? props.id : props.cqPath ? props.cqPath.substr(props.cqPath.lastIndexOf('/') + 1) : '';
  return (
    <InfoBar area={infoAlert.area} labelAriaCloseBtn={infoAlert.type} type={infoAlert.type} onClose={handleClose}>
      <div dangerouslySetInnerHTML={{ __html: text }}></div>
    </InfoBar>
  );
};

const InfoBarText = (props) => {
  if (!props.baseCssClass) {
    props.baseCssClass = 'infobar-text';
  }

  return <TextRich {...props} />;
};

export default InfoBarText;

export function InfoBarTextIsEmpty(props) {
  return props.infobartext == null || props.infobartext.length === 0;
}
