import { USER_ROLES } from '../../../../shared/globalConstants';
import { formatDateToYYYYMMDD, isFutureDate } from '../../../../shared/utils/globalUtils';
import { INVALID_DATES, REGEXS, REQUIRED_FIELD } from '../../account-recovery/constants';

const validateHcid = (value: string, labels: any) => {
  const errorConfig = {
    isValidHcid: true,
    hcidErrorMessage: ''
  };

  if (value.length === 0) {
    errorConfig.isValidHcid = false;
    errorConfig.hcidErrorMessage = labels.REQUIRED_FIELD;
  } else if (!REGEXS.IDENTIFICATION_NUMBER.test(value)) {
    errorConfig.isValidHcid = false;
    errorConfig.hcidErrorMessage = labels.INVALID_IDENTIFICATION_NUMBER;
  }

  return errorConfig;
};

const validateDob = (value: string, isContinueClicked: boolean, labels: any) => {
  const errorConfig = {
    isValidDob: true,
    dobErrorMessage: ''
  };

  if (value.length === 0 || value === REQUIRED_FIELD || (isContinueClicked && INVALID_DATES.includes(value))) {
    errorConfig.isValidDob = false;
    errorConfig.dobErrorMessage = labels.REQUIRED_FIELD;
  } else if (INVALID_DATES.includes(value)) {
    errorConfig.isValidDob = false;
    errorConfig.dobErrorMessage = labels.INVALID_DATE_1;
  } else if (isFutureDate(value)) {
    errorConfig.isValidDob = false;
    errorConfig.dobErrorMessage = labels.INVALID_DATE_2;
  }

  return errorConfig;
};

const getPhiLoginPayload = (hcid: string, firstName: string, lastName: string, dateOfBirth: string, zipcode: string): PhiLoginPayload => {
  return {
    hcid,
    firstName,
    lastName,
    dateOfBirth: formatDateToYYYYMMDD(dateOfBirth),
    zipcode
  };
};

const getMemberPHILoginSuccessPayload = (data: PhiLoginResponse): MemberPHILoginSuccessPayload => {
  return {
    role: USER_ROLES.MEMBER,
    userFirstName: data.member?.demographicInfo?.applicant?.[0]?.firstName ?? '',
    userLastName: data.member?.demographicInfo?.applicant?.[0]?.lastName ?? '',
    userDateOfBirth: data.member?.demographicInfo?.applicant?.[0]?.dateOfBirth ?? ''
  };
};

const getPhiMemberInfo = (data: PhiLoginResponse) => {
  const memberInfo: MemberInfo = {
    hcid: data.member.hcid ?? '',
    firstName: data.member?.demographicInfo?.applicant?.[0]?.firstName ?? '',
    lastName: data.member?.demographicInfo?.applicant?.[0]?.lastName ?? '',
    dob: data.member?.demographicInfo?.applicant?.[0]?.dateOfBirth ?? '',
    cmcEligible: data.member.cmcEligible ?? '',
    memberType: '',
    status: data.member.status ?? '',
    demographicInfo: data.member.demographicInfo,
    currentCoverages: data.member.currentCoverages ?? [],
    isRenewal: data.member.isRenewal ?? false,
    agentOfRecord: {
      agentId: data.member.agentOfRecord?.agentId ?? '',
      phone: data.member.agentOfRecord?.phone ?? '',
      email: data.member.agentOfRecord?.email ?? '',
      agentType: data.member.agentOfRecord?.agentType ?? '',
      lobState: data.member.agentOfRecord?.lobState ?? '',
      licenseNO: data.member.agentOfRecord?.licenseNO ?? '',
      npn: data.member.agentOfRecord?.npn ?? '',
      agentCode: data.member.agentOfRecord?.agentCode ?? '',
      address: {
        addressLine1: data.member.agentOfRecord?.address.addressLine1 ?? '',
        city: data.member.agentOfRecord?.address.city ?? '',
        state: data.member.agentOfRecord?.address.state ?? ''
      }
    },
    ffmonExgCoverage: data.member.ffmonExgCoverage ?? false
  };

  return memberInfo;
};

export { getMemberPHILoginSuccessPayload, getPhiLoginPayload, getPhiMemberInfo, validateDob, validateHcid };
