import axiosInstance from '../../../../api/axiosWrapper/axiosWrapper';

import { API_NAME_LIST } from '../../../../api/api-name-list';

const fetchQuoteDetails = (quoteId: string) => {
  return axiosInstance.get(API_NAME_LIST.GET_AGENT_QUOTE + quoteId);
};

const validatePinAndDobByQuoteId = (payload: PinAndDobByQuotePayload) => {
  return axiosInstance.post(API_NAME_LIST.VALIDATE_PIN_AND_DOB_BY_QUOTEID, payload);
};

const getEvendorInfo = (evendorid: string) => {
  return axiosInstance.get(API_NAME_LIST.GET_EVENDOR_INFO + evendorid);
};

const getQuoteByDemographcisId = (demographicId: string) => {
  return axiosInstance.get(API_NAME_LIST.GET_QUOTE_BY_DEMOGRAPHICS + demographicId);
};

export { fetchQuoteDetails, getEvendorInfo, getQuoteByDemographcisId, validatePinAndDobByQuoteId };
