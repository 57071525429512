import { PLAN_TYPES_NAMES } from '../../../shared/globalConstants';

const getFormateTime = (time: string) => {
  const [day, startTime, am, to, endTime, pm] = time.split(' ');

  let fullDay;
  switch (day) {
    case 'M':
      fullDay = 'Mon';
      break;
    case 'Tu':
      fullDay = 'Tue';
      break;
    case 'W':
      fullDay = 'Wed';
      break;
    case 'Th':
      fullDay = 'Thu';
      break;
    case 'F':
      fullDay = 'Fri';
      break;
    case 'Sa':
      fullDay = 'Sat';
      break;
    case 'Su':
      fullDay = 'Sun';
      break;
    default:
      fullDay = day;
      break;
  }
  return `${fullDay} - ${startTime ? startTime : ''} ${am ? am.toLocaleUpperCase() : ''} - ${endTime ? endTime : ''} ${pm ? pm.toLocaleUpperCase() : ''}`;
};

const formateTime = (timeRange: string[]) => {
  const newTime = timeRange.map((element) => getFormateTime(element));
  return newTime;
};

const getTitleIcon = (useCase: string) => {
  switch (useCase) {
    case PLAN_TYPES_NAMES.MEDICAL:
      return 'sae-icon-stethoscope';
    case PLAN_TYPES_NAMES.DENTAL:
      return 'sae-icon-tooth';
    case PLAN_TYPES_NAMES.VISION:
      return 'sae-icon-eye';
    default:
      return 'sae-icon-stethoscope';
  }
};

export { formateTime, getTitleIcon };
