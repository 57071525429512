import dayjs from 'dayjs';
import { getProviderTypeCode } from '../../../components/common/public-nav-bar/SaveAndResumeLater.utils';
import {
  AGENT_ROLES,
  APPLICANT_TYPES,
  BRAND_NAMES,
  COVERAGE_PERIOD,
  DEFAULT_CAMPAIGNIDS,
  LANGUAGE_CODES,
  PLAN_TYPES_CONTENT,
  PLAN_TYPES_NAMES,
  SCREEN_NAMES,
  SET_APPLICATION_CONSTANTS,
  SET_BRAND_NAMES,
  USER_DOMAINS
} from '../../../shared/globalConstants';
import { formatDateToYYYYMMDD, getcurrentAnchorPlan } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { PLAN_TIERS_ALL } from '../medical-plans/constants';
import { getFormattedApplicantDetails, setDomesticPartnerAsSpouse } from '../medical-plans/medicalPlansUtils';
import {
  getAdditionalInfo,
  getApplicationType,
  getCurrentPlanIndicator,
  getIsSpouseDependentAdd,
  getSetApplicationPayload,
  isApplicantHavingPlan
} from '../plan-selection-summary/planSelectionSummaryUtils';

const getRecommendedPlansPayload = (
  plantType: string,
  applicantsData: Applicant[],
  demographicInfo: DemographicInfo,
  contractBundle: contractBundle[]
): PlanSummaryPayload => {
  const payload: PlanSummaryPayload = {
    brand: demographicInfo.brand ? demographicInfo.brand : '',
    planTypes: [plantType],
    metalTierTypes: PLAN_TIERS_ALL,
    marketSegment: USER_DOMAINS.MARKET_SEGMENT_FOR_PLAN_SUMMARY,
    zipCode: demographicInfo.zipCode ? demographicInfo.zipCode : '',
    county: demographicInfo.county ? demographicInfo.county : '',
    state: demographicInfo.state ? demographicInfo.state : '',
    coverageEffectiveDate: formatDateToYYYYMMDD(demographicInfo.coverageEffectiveDate ? demographicInfo.coverageEffectiveDate : ''), //Update the format
    planYear: demographicInfo.coverageEffectiveDate ? demographicInfo.coverageEffectiveDate.split('-')[0] : '',
    applicants: setDomesticPartnerAsSpouse(getFormattedApplicantDetails(applicantsData, false)),
    subsidy: {
      subsidyAmt: plantType === PLAN_TYPES_NAMES.MEDICAL ? (demographicInfo.subsidy?.subsidyAmount?.toString() ?? null) : null,
      costShareReduction: plantType === PLAN_TYPES_NAMES.MEDICAL ? (demographicInfo.subsidy?.costShareReductionValue ?? null) : null
    },
    language: LANGUAGE_CODES.EN,
    countyCode: demographicInfo.countyCode ? demographicInfo.countyCode : '',
    contractList: {
      contractBundle: contractBundle
    }
  };

  return payload;
};

const getProviderListByIdPayload = (
  savedUserEntities: SavedUserEntities,
  demographicInfo: DemographicInfo,
  latitude: number,
  longitude: number,
  contractCodes: string[]
) => {
  const providersData: RecProviderDetail[] = savedUserEntities.map((entity) => {
    return {
      providerIdentifier: entity.entityId,
      addressIdentifier: entity.compositeEntityId,
      type: getProviderTypeCode(entity.entitySubType)
    };
  });
  if (providersData.length) {
    const payload: RecProviderListByIdPayload = {
      providerDetail: providersData,
      brand: demographicInfo?.brand ?? '',
      state: demographicInfo?.state ?? '',
      latitude: latitude,
      longitude: longitude,
      contractCodes: contractCodes
    };
    return payload;
  } else {
    return {} as RecProviderListByIdPayload;
  }
};

const getDrugSummaryPayload = (quoteRecContracts: QuoteRecContracts, drugLists: DrugLists) => {
  let planIds: PlanIds[] = [];
  quoteRecContracts.map((quote) => {
    if (quote.planType === PLAN_TYPES_NAMES.MEDICAL) {
      planIds.push({ planId: quote.contractCode });
    }
  });
  const payload: DrugSummaryPayload = { planList: { plan: planIds }, drugList: { drug: drugLists } };
  return payload;
};

const getRecommededPlanCardLabels = (labels: any): PlanCardLabels => {
  return {
    mostPopular: labels?.MOST_POPULAR,
    recommended: labels?.RECOMMENDED,
    currentCoverage: labels?.CURRENT_COVERAGE,
    monthlyPremium: labels?.MONTHLY_PREMIUM,
    benefitLabelOne: labels?.ROUTINE_EYE_EXAM,
    benefitLabelTwo: labels?.EYE_GLASS_LENSES,
    benefitLabelThree: labels?.EYE_GLASS_FRAMES,
    referToSbc: labels?.REFER_TO_SBC,
    select: labels?.SELECT,
    deselect: labels?.DESELECT,
    showMore: labels?.SHOW_MORE,
    showLess: labels?.SHOW_LESS,
    addToFavourites: labels?.ADD_TO_FAVOURITES,
    removeFromFavourites: labels?.REMOVE_FROM_FAVOURITES,
    compareEmailPrint: labels?.COMPARE_EMAIL_PRINT,
    monthlyCostBreakdown: labels?.MONTHLY_COST_BREAKDOWN,
    totalPlanCost: labels?.TOTAL_PLAN_COST,
    subsidyApplied: labels?.SUBSIDY_APPLIED,
    totalMemberResponsibility: labels?.TOTAL_MEMBER_RESPONSIBILITY,
    rateNotAvailable: labels?.RATE_NOT_AVAILABLE,
    doctorsListLabel: labels?.VISION_LIST_LABEL,
    doctors: labels?.EYE_DOCTORS,
    matchedDoctorsTooltipText: labels?.MATCHED_DOCTORS_TOOLTIP_TEXT,
    transitionCoverage: labels?.TRANSITION_COVERAGE,
    transitionCoverageTooltip: labels?.TRANSITION_PLAN_TOOLTIP_CONTENT
  };
};

const getTotalPremiums = (recommendedPlans: RecComparedPlans, quoteOrderings: QuoteOrderings) => {
  let totalPremiums: TotalPremiums = [];
  quoteOrderings.map((el: QuoteOrdering) => {
    totalPremiums.push({
      quoteId: el.quoteId,
      totalPremium: recommendedPlans
        ?.filter((plan: RecComparedContract) => plan.quoteId === el.quoteId)
        ?.map((el: RecComparedContract) => (el.contract.rateData ? el.contract.rateData.rate.totSubsidizedPrem : 0))
        .reduce((a, b) => a + b, 0)
    });
  });
  return totalPremiums;
};

const getRecApplicantAddress = (demographicInfo: DemographicInfo) => {
  const county = demographicInfo.county ?? '';
  const zipcode = demographicInfo.zipCode ?? '';
  const state = demographicInfo.state ?? '';
  const countyCode = demographicInfo.countyCode ?? '';
  let applicantAddress: PrimaryUserAddress[] = demographicInfo.applicant
    ? (demographicInfo.applicant.filter((item) => item.applicantType == APPLICANT_TYPES.PRIMARY)[0]?.address as PrimaryUserAddress[])
    : [];
  if (applicantAddress && applicantAddress?.length > 0 && applicantAddress[0]?.state === state) {
    applicantAddress = [
      {
        addressLine1: applicantAddress[0]?.addressLine1 || applicantAddress[0]?.addressLine1 === '' ? applicantAddress[0]?.addressLine1 : null,
        addressLine2: applicantAddress[0]?.addressLine2 || applicantAddress[0]?.addressLine2 === '' ? applicantAddress[0]?.addressLine2 : null,
        county: applicantAddress[0]?.county ? applicantAddress[0]?.county : county,
        state: applicantAddress[0]?.state ? applicantAddress[0]?.state : state,
        addressType: SET_APPLICATION_CONSTANTS.HOME,
        postalCode: applicantAddress[0]?.postalCode ? applicantAddress[0]?.postalCode : zipcode,
        countyCode: applicantAddress[0]?.countyCode ? applicantAddress[0]?.countyCode : countyCode,
        city: applicantAddress[0]?.city ? applicantAddress[0]?.city : county
      }
    ];
  } else {
    applicantAddress = [
      {
        addressLine1: null,
        addressLine2: null,
        county: county,
        state: state,
        addressType: SET_APPLICATION_CONSTANTS.HOME,
        postalCode: zipcode,
        countyCode: countyCode,
        city: null
      }
    ];
  }
  return applicantAddress;
};

const getTotalPremiumForApplicantData = (selectedPlan: SelectedPlan, selectedDentalPlan: SelectedPlan, selectedVisionPlan: SelectedPlan) => {
  let totalPremium: number = 0;
  if (selectedPlan?.plan) {
    totalPremium += selectedPlan?.plan?.rateData?.rate?.totSubsidizedPrem ?? 0;
  }
  if (selectedDentalPlan?.plan) {
    totalPremium += selectedDentalPlan?.plan?.rateData?.rate?.totalPremium ?? 0;
  }
  if (selectedVisionPlan?.plan) {
    totalPremium += selectedVisionPlan?.plan?.rateData?.rate?.totalPremium ?? 0;
  }
  return parseFloat(totalPremium.toFixed(2).toString());
};

const isAgentInfoConsiderForPlanRec = (agentInfo: AgentOfRecord) => {
  return agentInfo &&
    agentInfo?.writingTIN &&
    agentInfo?.writingTIN !== '' &&
    ((agentInfo?.agencyName && agentInfo?.agencyName !== '') ||
      (agentInfo?.agentLastName && agentInfo?.agentLastName !== '') ||
      (agentInfo?.agentFirstName && agentInfo?.agentFirstName !== ''))
    ? agentInfo
    : null;
};

const getRecommendedSetApplicationData = (
  onExchange: boolean,
  demographicInfo: DemographicInfo,
  agentInfo: AgentOfRecord,
  applicantInfo: Applicant[],
  selectedPlan: SelectedPlan,
  selectedDentalPlan: SelectedPlan,
  selectedVisionPlan: SelectedPlan
) => {
  const { memberInfo } = useGlobalStore.getState();
  const { webAccountGUID, role, userEmailAddress, isWellpoint } = useUserStore.getState();
  let totalPremium: number = 0;

  let applicantDetails: PayloadDetails[] = [];

  if (applicantInfo) {
    applicantInfo.map((item) => {
      let plans: Contract[] = [];
      if (selectedPlan?.plan && isApplicantHavingPlan(selectedPlan, item.firstName, item.applicantId)) {
        plans.push(selectedPlan?.plan);
      }
      if (selectedDentalPlan?.plan && isApplicantHavingPlan(selectedDentalPlan, item.firstName, item.applicantId)) {
        plans.push(selectedDentalPlan?.plan);
      }
      if (selectedVisionPlan?.plan && isApplicantHavingPlan(selectedVisionPlan, item.firstName, item.applicantId)) {
        plans.push(selectedVisionPlan?.plan);
      }

      plans.map((item) => (totalPremium += item?.rateData?.rate?.totSubsidizedPrem ?? 0));

      applicantDetails.push({
        demographicInfo: { applicantDemographicInfo: item, emailAddress: userEmailAddress },
        address: getRecApplicantAddress(demographicInfo),
        plan: plans
      });
    });
  }

  const applicationinfo: ApplicationInfo = {
    userId: webAccountGUID,
    shopperRole: AGENT_ROLES.CONSUMER,
    state: demographicInfo.state ?? '',
    premiumAmt: getTotalPremiumForApplicantData(selectedPlan, selectedDentalPlan, selectedVisionPlan),
    campaignId: demographicInfo?.cid ? demographicInfo.cid : DEFAULT_CAMPAIGNIDS[demographicInfo?.state ?? ''],
    brandName: isWellpoint ? BRAND_NAMES.WELLPOINT_BRAND : demographicInfo.brand === BRAND_NAMES.ABC ? SET_BRAND_NAMES.BCC : SET_BRAND_NAMES.ANTHEM,
    applicationType: getApplicationType(onExchange),
    reqEffDate: formatDateToYYYYMMDD(demographicInfo.coverageEffectiveDate ?? '') ?? '',
    hcid: memberInfo?.hcid ? memberInfo.hcid : null,
    eid: memberInfo.demographicInfo.eid && memberInfo.demographicInfo.eid !== '' ? memberInfo.demographicInfo.eid : null,
    ipAddress: null,
    isAddSpouse: getIsSpouseDependentAdd(selectedPlan, selectedDentalPlan, selectedVisionPlan, true),
    isAddDependents: getIsSpouseDependentAdd(selectedPlan, selectedDentalPlan, selectedVisionPlan, false),
    currentAnchorPlan: getcurrentAnchorPlan(memberInfo.currentCoverages),
    currentPlanIndicator: getCurrentPlanIndicator(memberInfo.currentCoverages),
    additionalInfo: getAdditionalInfo(selectedPlan, selectedDentalPlan, selectedVisionPlan)
  };
  return getSetApplicationPayload({ applicantPlanInfo: applicantDetails, applicationinfo: applicationinfo, agent: isAgentInfoConsiderForPlanRec(agentInfo) });
};

const getCoverageTypeForDynamicAEM = (screenName: String) => {
  let screenNameForAEM = '';
  switch (screenName) {
    case SCREEN_NAMES.MEDICAL_PLAN_DETAILS:
      screenNameForAEM = PLAN_TYPES_CONTENT.M.toLowerCase();
      break;
    case SCREEN_NAMES.DENTAL_DETAILS_SCREEN_NAME:
      screenNameForAEM = PLAN_TYPES_CONTENT.D.toLowerCase();
      break;
    case SCREEN_NAMES.VISION_PLAN_DETAILS:
      screenNameForAEM = PLAN_TYPES_CONTENT.V.toLowerCase();
      break;
    default:
      screenNameForAEM = '';
      break;
  }
  return screenNameForAEM;
};

const openEnrollmentStartedForPlanRec = (currentDateTimeInZone: string): boolean => {
  const { coverageRule } = useGlobalStore.getState();
  let today = currentDateTimeInZone ? dayjs(currentDateTimeInZone).toISOString() : dayjs().toISOString();
  const coverageRuleObj = coverageRule.find((period) => period.period === COVERAGE_PERIOD.OE);

  return coverageRuleObj ? today >= dayjs(coverageRuleObj?.startDate).toISOString() : false;
};

export {
  getCoverageTypeForDynamicAEM,
  getDrugSummaryPayload,
  getProviderListByIdPayload,
  getRecommededPlanCardLabels,
  getRecommendedPlansPayload,
  getRecommendedSetApplicationData,
  getTotalPremiums,
  openEnrollmentStartedForPlanRec
};
