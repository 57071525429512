import { Tooltip } from '@sydney-broker-ui/ios';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { getZipcodeValidation } from '../../../api/services/utilService';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import { AGENT_ROLES, BRAND_NAMES, COVERAGE_EFFECTIVE_CONSTS, PLAN_TYPES, PLAN_TYPES_CONTENT, STATE_CODES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';



interface AgentZipcodeComponentProps {
    pageContent: any;
    memberInfo: any;
    dependentsDisable: boolean;
    zipCodeError: any;
}

const AgentZipcodeComponent: React.FC<AgentZipcodeComponentProps> = ({ pageContent, memberInfo, dependentsDisable, zipCodeError }) => {
    /* --------- stores ------------*/

    const stores = {
        globalStore: useGlobalStore(),
        userStore: useUserStore(),
    };

    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [countyList, setCountyList] = useState<any>([stores.globalStore.agentCountyList]);
    const [zipError, setZipError] = useState<boolean>(false)
    const [agentStateError, setAgentStateError] = useState<boolean>(false);
    const [editFlow, setEditFlow] = useState<boolean>(location?.state?.editFlow ? location?.state?.editFlow : false);
    const [showZipCodeBlocks, setZipCodeShowBlocks] = useState<any>(false);

    const { register, watch, setValue, getValues, setError, control, formState: { errors } } = useFormContext();

    /* --------- stores ------------*/


    useEffect(() => {
        if (stores.globalStore.agentHCIDBlocks.zipcodeBlock) {
            setZipCodeShowBlocks(true);
        }
    }, [stores.globalStore.agentHCIDBlocks.zipcodeBlock])
    /*------------ Zipcode Vlaidations ------------*/

    /*------------  FORM MEHTODS --------------*/

    const handleZipCodeChange = (event) => {
        const value = event.target.value.replace(/[^0-9]/g, '');
        event.target.value = value;
    };

    const isNotSelect = (value, countyList) => {
        if (value === 'select') {
            stores.globalStore.agentUpdateHCIDBlocks('coveragesBlock', false);
            return pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD;
        } else {
            let data = countyList.find((county) => {
                return county.countyName === value;
            });
            if (data && data?.inService.value === 'Y' && getAgentStates(stores.globalStore.agentSSOInfo, data.stateCode) && (stores.userStore.isWellpoint ? data.brand === BRAND_NAMES.WELLPOINT_BRAND : data.brand != BRAND_NAMES.WELLPOINT_BRAND)) {
                setZipError(false);
                //zipCodeError(false);
                stores.globalStore.agentUpdateCounty(data.countyName, data.countyCode, data.brand);
                stores.globalStore.agentupdatePlanTypes(data.countyName, getValues('year'), stores.globalStore.agentSSOInfo.agentType);
                getAgentTin(data.stateCode);
                stores.globalStore.agentUpdateHCIDBlocks('coveragesBlock', true);
                return true;
            } else {
                stores.globalStore.agentUpdateHCIDBlocks('coveragesBlock', false);
                if (!getAgentStates(stores.globalStore.agentSSOInfo, data.stateCode)) {
                    setAgentStateError(true);
                    zipCodeError(true);
                } else if (data?.inService.value === 'N') {
                    setZipError(true);
                } else {
                    setZipError(true);
                }
                //zipCodeError(true);
                return false
            }
        }
    };

    const changePlanYear = (event) => {
        setValue('year', event.target.value);
        stores.globalStore.agentUpdateYear(event.target.value);
        stores.globalStore.agentUpdateCoverageDates(stores.globalStore.agentZipCodeRes.coverageEffectiveDate.coverageDt, event.target.value);
        stores.globalStore.agentupdatePlanTypes(getValues('county'), getValues('year'), stores.globalStore.agentSSOInfo.agentType);
    }

    const validateZipcodeMethod = (value) => {
        if (value.length === 5) {
            return true;
        } else {
            return false;
        }
    }


    /*------------  FORM MEHTODS --------------*/


    /*------------ Zipcode Vlaidations ------------*/



    /*----------------- Agent SSO get Methods -----------*/
    const getAgentStates = (agentSSO: AgentSSOInfo, stateCode: string) => {
        if (agentSSO.agentInfo.agent.length > 0) {
            const stateNames = agentSSO.agentInfo.agent[0].states.state.map((state) => state.stateName);
            const uniqueStateNames = [...new Set(stateNames)];
            return uniqueStateNames.includes(stateCode);
        }
    }
    /*----------------- Agent SSO get Methods -----------*/

    /*-------------- get AGebt TIn ----------------*/
    const getAgentTin = (state) => {
        if (state) {
            const agentInfo = stores.globalStore.agentSSOInfo.agentInfo.agent[0];
            const filteredAgentState = stores.globalStore.agentSSOInfo.agentInfo.agent[0].states.state.find((stateObj) => {
                return state === stateObj.stateName
            });
            stores.globalStore.agentUpdateTin(
                (agentInfo.agentFName ? (agentInfo.agentFName + "-") : '') +
                (agentInfo.writingTIN ? (agentInfo.writingTIN + "-") : '') +
                (filteredAgentState?.agencyInfo.agency.paidTIN ? (filteredAgentState?.agencyInfo.agency.paidTIN + "-") : '') +
                (filteredAgentState?.agencyInfo.agency.parentTIN ? (filteredAgentState?.agencyInfo.agency.parentTIN + "-") : '') +
                filteredAgentState?.agencyInfo.agency.agentCode);
            setValue('agentTin',
                (agentInfo.agentFName ? (agentInfo.agentFName + "-") : '') +
                (agentInfo.writingTIN ? (agentInfo.writingTIN + "-") : '') +
                (filteredAgentState?.agencyInfo.agency.paidTIN ? (filteredAgentState?.agencyInfo.agency.paidTIN + "-") : '') +
                (filteredAgentState?.agencyInfo.agency.parentTIN ? (filteredAgentState?.agencyInfo.agency.parentTIN + "-") : '') +
                filteredAgentState?.agencyInfo.agency.agentCode)
        } else {
            stores.globalStore.agentUpdateTin('');
            setValue('agentTin', '');
        }
    }
    /*-------------- get AGebt TIn ----------------*/

    /*------------- use effects -------------*/
    useEffect(() => {
        if (memberInfo && memberInfo.demographicInfo && memberInfo.demographicInfo.zipCode) {
            setValue('zipcode', memberInfo.demographicInfo.zipCode);
        }
    }, [memberInfo, stores.globalStore.agentZipcode])

    const zipCodeWatch = watch('zipcode');

    useEffect(() => {
        if (zipCodeWatch.length === 5) {
            validateZipCodeWithAPICall(zipCodeWatch);
        }
    }, [zipCodeWatch]);

    useEffect(() => {
        if (dependentsDisable || ((stores.globalStore.agentMacPlanTypes.length === 1 && stores.globalStore.agentMacPlanTypes[0] === PLAN_TYPES_CONTENT.V) && (getValues("coverageType") === PLAN_TYPES.DENTAL || getValues("coverageType") === PLAN_TYPES.VISION))) {
            if (dependentsDisable || ((stores.globalStore.agentMacPlanTypes.length === 1 && stores.globalStore.agentMacPlanTypes[0] === PLAN_TYPES_CONTENT.V) && (getValues("coverageType") === PLAN_TYPES.DENTAL || getValues("coverageType") === PLAN_TYPES.VISION))) {
                setValue('zipcode', stores.globalStore.memberInfo.demographicInfo.zipCode);
            }
        }
    }, [dependentsDisable])
    /*------------- use effects -------------*/



    /* -------------- API CALLS --------------*/

    const validateZipCodeWithAPICall = async (zipcode) => {
        setAgentStateError(false);
        zipCodeError(false);
        //agentError(false);
        try {
            setLoading(true);
            const response = await getZipcodeValidation({ zipCode: zipcode, marketSegment: 'UNDER_65' });
            if (response.data.zipCodeResponse.responseMessage.message === 'SUCCESSFULLY FETCHED') {
                if (!editFlow) {
                    let year = stores.globalStore.agentYear || JSON.parse(pageContent?.ZIPCODE_DYNAMIC_DATA.JSON_AREA).planYear[0];
                    let data = response.data.zipCodeResponse.zipCode.countyList.county.find((county) => {
                        return county.countyName === stores.globalStore.agentCounty;
                    });
                    if (data) {
                        stores.globalStore.agentupdateZipCodeResponse(response.data.zipCodeResponse.zipCode, response.data.zipCodeResponse.zipCode.countyList.county, year, data.countyName, data.countyCode, data.brand);
                    } else {
                        stores.globalStore.agentupdateZipCodeResponse(response.data.zipCodeResponse.zipCode, response.data.zipCodeResponse.zipCode.countyList.county, year);
                    }
                    stores.globalStore.agentUpdateYear(year);
                    setValue('year', year);
                    setCountyList(response.data.zipCodeResponse.zipCode.countyList.county);
                    setZipcodeFieldsandErrors(response);
                } else {
                    stores.globalStore.agentUpdateHPAZipCodeResponse(response.data.zipCodeResponse.zipCode, stores.globalStore.agentYear);
                    stores.globalStore.agentHPAUpdateCoverageType(stores.globalStore.agentCoverageType, response.data.zipCodeResponse.zipCode.coverageEffectiveDate.coverageDt.filter((type) => {
                        return COVERAGE_EFFECTIVE_CONSTS.indexOf(type.type) !== -1 && type.planYear.indexOf(stores.globalStore.agentYear) !== -1;
                    }));
                    getAgentTin(stores.globalStore.agentState);
                    setEditFlow(false)
                    if (stores.globalStore.agentSSOInfo.agentType === AGENT_ROLES.HPA) {
                        stores.globalStore.updateIsHPAUser(true);
                    }
                    stores.globalStore.updateIsHCIDFlow(true);
                    setCountyList(response.data.zipCodeResponse.zipCode.countyList.county);
                    stores.globalStore.agentUpdateHCIDBlocks('coveragesBlock', true);
                }
            }
            else if (response.data.zipCodeResponse.responseMessage.message === 'INVALID ZIPCODE') {
                stores.globalStore.resetAgentUpdateZipCode();
                setCountyList([]);
                setError('zipcode', { type: 'validate', message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_ZIP });
                getAgentTin('');
                stores.globalStore.agentUpdateHCIDBlocks('coveragesBlock', false);
            } else {
                setError('zipcode', { type: 'validate', message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_ZIP });
                stores.globalStore.agentUpdateHCIDBlocks('coveragesBlock', false);
                getAgentTin('');
            }
        } catch (error) {
            console.error('Error fetching county data:', error);
        } finally {
            setLoading(false);
        }
        return true
    };


    const setZipcodeFieldsandErrors = (response) => {
        if (response.data.zipCodeResponse.zipCode.countyList.county.length === 1) {
            if (getAgentStates(stores.globalStore.agentSSOInfo, response.data.zipCodeResponse.zipCode.stateCode) && response.data.zipCodeResponse.zipCode.countyList.county[0].inService.value === 'Y' && (stores.userStore.isWellpoint ? response.data.zipCodeResponse.zipCode.countyList.county[0].brand === BRAND_NAMES.WELLPOINT_BRAND : response.data.zipCodeResponse.zipCode.countyList.county[0].brand != BRAND_NAMES.WELLPOINT_BRAND)) {
                setAgentStateError(false);
                zipCodeError(false);
                //agentError(false);
                setValue('county', response.data.zipCodeResponse.zipCode.countyList.county[0].countyName);
                stores.globalStore.agentupdatePlanTypes(response.data.zipCodeResponse.zipCode.countyList.county[0].countyName, getValues('year'), stores.globalStore.agentSSOInfo.agentType);
                getAgentTin(response.data.zipCodeResponse.zipCode.stateCode);
                setZipError(false);
                stores.globalStore.agentUpdateHCIDBlocks('coveragesBlock', true);
                //zipCodeError(false);
            } else {
                if (response.data.zipCodeResponse.zipCode.countyList.county[0].inService.value === 'N') {
                    setZipError(true);
                    stores.globalStore.agentUpdateHCIDBlocks('coveragesBlock', false);
                    //zipCodeError(true);
                } else if (response.data.zipCodeResponse.zipCode.countyList.county[0].inService.value === 'Y' && !getAgentStates(stores.globalStore.agentSSOInfo, response.data.zipCodeResponse.zipCode.stateCode)) {
                    setAgentStateError(true);
                    zipCodeError(true);
                    stores.globalStore.agentUpdateHCIDBlocks('coveragesBlock', false);
                    //agentError(true);
                } else {
                    setError('zipcode', { type: 'validate', message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_ZIP });
                    stores.globalStore.agentUpdateHCIDBlocks('coveragesBlock', false);
                }
                // if (!editFlow) {
                //     setValue('county', response.data.zipCodeResponse.zipCode.countyList.county[0].countyName);
                //     getAgentTin('');
                // }
            }
        } else {
            setValue('county', stores.globalStore.agentCounty ? stores.globalStore.agentCounty : 'select');
        }
    }
    /* -------------- API CALLS --------------*/
    if (loading) {
        return <FullPageLoader />
    } else {
        return (
            <>
                <div className='fwc-row'>
                    <div className='fwc-col-4 md-6 sm-12'>
                        <div className='form-group more'>
                            <div className="fwc-input">
                                <label className="fwc-label" htmlFor="zipcode">
                                    {pageContent?.FORM_CONTENT.LABELS.ZIPCODE}
                                </label>
                                <div className='zipcode-wrapper'>
                                    <input
                                        id="zipcode"
                                        maxLength={5}
                                        placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.ZIP_CODE}
                                        readOnly={!showZipCodeBlocks || dependentsDisable}
                                        type="text"
                                        {...register('zipcode', {
                                            required: {
                                                value: true,
                                                message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD
                                            },
                                            minLength: {
                                                value: 5,
                                                message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_ZIP
                                            },
                                            onChange: (e) => {
                                                handleZipCodeChange(e);
                                            },
                                            validate: (value) => validateZipcodeMethod(value)
                                        })}
                                    />
                                    <span className='sae-icon sae-icon-marker'></span>
                                </div>
                                {errors.zipcode?.message && errors.zipcode.type !== "validate" && (
                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="zip_error">
                                        {String(errors.zipcode?.message)}
                                    </span>
                                )}
                                {errors && errors.zipcode && errors.zipcode && errors.zipcode.type === "validate" && (
                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                        <span dangerouslySetInnerHTML={{ __html: String(errors.zipcode.message) || '' }}></span>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    {countyList && countyList.length === 1 && <div className='fwc-col-4'>
                        <div className="fwc-input ">
                            <label className="fwc-label" htmlFor="county-select">
                                {pageContent?.FORM_CONTENT.LABELS.COUNTY}
                            </label>
                            <input disabled type="text" {...register('county', { value: countyList[0].countyName })} />
                        </div>
                    </div>}
                    {countyList && countyList.length > 1 && <div className='fwc-col-4'>
                        <div className="fwc-select-list ">
                            <label className="fwc-label" htmlFor="county-select">
                                {pageContent?.FORM_CONTENT.LABELS.COUNTY}
                            </label>
                            <Controller
                                name="county"
                                control={control}
                                defaultValue={'select'}
                                rules={{ validate: (value) => isNotSelect(value, countyList) }}
                                render={({ field }) => (
                                    <select id="county-select" {...field} onChange={(e) => {
                                        field.onChange(e);
                                    }}>
                                        <option value="select">{pageContent?.FORM_CONTENT.LABELS.SELECT}</option>
                                        {countyList.map((county, index) => {
                                            return (
                                                <option key={index} value={county['countyName']}>
                                                    {county['countyName']}
                                                </option>
                                            );
                                        })}
                                    </select>
                                )}
                            />
                            {errors.county?.message && (
                                <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="zip_error">
                                    {String(errors.county?.message)}
                                </span>
                            )}
                        </div>
                    </div>}
                    <div className="fwc-col-3 md-6 sm-12">
                        <div className={'fwc-flex'}>
                            <label className="fwc-label right-xxs" htmlFor="year-select">
                                {pageContent?.ZIPCODE_DYNAMIC_DATA.PLAN_YEAR}{' '}
                            </label>
                            <Tooltip icon message={pageContent?.ZIPCODE_DYNAMIC_DATA.PLAN_YEAR_TOOLTIP}></Tooltip>
                        </div>

                        <div className="fwc-select-list">
                            <Controller
                                name="year"
                                control={control}
                                disabled={!showZipCodeBlocks}
                                rules={{
                                    required: {
                                        value: true,
                                        message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD
                                    }
                                }}
                                render={({ field }) => (
                                    <select
                                        data-analytics={'planYearLpIos'}
                                        {...field}
                                        onChange={(e) => {
                                            changePlanYear(e)
                                        }}
                                    >
                                        {JSON.parse(pageContent?.ZIPCODE_DYNAMIC_DATA.JSON_AREA).planYear.map((yearvalue, index) => {
                                            return (
                                                <option key={index} value={yearvalue} data-analytics={yearvalue.toString() === new Date().getFullYear().toString() ? 'currentYearLpIos' : 'futureYearLpIos'}>
                                                    {yearvalue}
                                                </option>
                                            );
                                        })}
                                    </select>
                                )}
                            />
                        </div>

                    </div>
                </div>
                {zipError && <div className="fwc-row">
                    <div className="fwc-col-12 form-group">
                        <div className="error-callout">
                            <div className="fwc-flex a-start">
                                <div className="fwc-inline-icon fwc-icon-info error-icon"></div>
                                <div>
                                    <div id="zip_error" dangerouslySetInnerHTML={{ __html: stores.userStore.isWellpoint ? pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_COUNTY_LINK_FOR_WELLPOINT : pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_COUNTY_LINK_FOR_ANTHEM }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {
                    agentStateError && <div className="fwc-row">
                        <div className="fwc-col-12 form-group">
                            <div className="error-callout">
                                <div className="fwc-flex a-start">
                                    <div className="fwc-inline-icon fwc-icon-info error-icon"></div>
                                    <div>
                                        <div id="agent_state_error" dangerouslySetInnerHTML={{ __html: pageContent?.FORM_CONTENT.ERROR_MESSAGES.AGENT_STATES_NOT_ALLOWED }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    stores.globalStore.agentState === STATE_CODES.NY && <div className='fwc-row'>
                        <div className='fwc-col-12 form-group'>
                            <div className='error-callout'>
                                <div className="fwc-flex a-start">
                                    <div className="fwc-inline-icon fwc-icon-info error-icon"></div>
                                    <div>
                                        <div id="agent_state_info" dangerouslySetInnerHTML={{ __html: pageContent?.FORM_CONTENT.ERROR_MESSAGES.AGENT_STATE_INFO }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </>
        )
    }
};

export default AgentZipcodeComponent;