import { Toggle } from '@sydney-broker-ui/ios';
import dayjs from "dayjs";
import React, { useEffect } from 'react';
import { getFormattedApplicantDetails } from '../../../pages/public/medical-plans/medicalPlansUtils';
import { APPLICANT_TYPES, DATE_FORMATS, MACFLOW_POPUP_TYPE, PLAN_TYPES_NAMES } from '../../../shared/globalConstants';
import { filterDependentsOnRelationType, getNotMedicaidEligibleDependents } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import './DependentSelectionModal.scss';
const DependentSelectionModal = ({
  labels,
  planType,
  onDependentClicked,
  isEditCoverage
}: DependentSelectionModalProps) => {
  const [selectedDependents, setSelectedDependents] = React.useState<Applicant[]>([]);
  const { agentSSOInfo, applicantFormDetails, selectedDentalPlan, selectedVisionPlan, openDepModal, selectedProduct, agentApplicantFormDetails } = useGlobalStore((store) => store);
  let formattedApplicantData;
  if (agentSSOInfo.isAtk) {
    formattedApplicantData = filterDependentsOnRelationType(getFormattedApplicantDetails(agentApplicantFormDetails));
  } else {
    formattedApplicantData = (planType === PLAN_TYPES_NAMES.DENTAL || planType === PLAN_TYPES_NAMES.VISION) ? filterDependentsOnRelationType(getNotMedicaidEligibleDependents(applicantFormDetails)) : getNotMedicaidEligibleDependents(applicantFormDetails);
  }

  const applicantDetails: Applicant = formattedApplicantData.find((item) => item.applicantType === APPLICANT_TYPES.PRIMARY && item) as Applicant;
  const spouseDetails = formattedApplicantData.find((item) => (item.applicantType === APPLICANT_TYPES.SPOUSE || item.applicantType === APPLICANT_TYPES.DOMESTICPARTNER) && item);
  const dependentDetails = formattedApplicantData
    .filter((item) => ![APPLICANT_TYPES.PRIMARY, APPLICANT_TYPES.SPOUSE, APPLICANT_TYPES.DOMESTICPARTNER].includes(item.applicantType) && item);

  useEffect(() => {
    if (isEditCoverage) {
      if (planType === PLAN_TYPES_NAMES.DENTAL && selectedDentalPlan?.applicants?.length > 0) {
        setSelectedDependents([...selectedDentalPlan?.applicants]);
        onDependentClicked([...selectedDentalPlan?.applicants]);
      } else if (planType === PLAN_TYPES_NAMES.VISION && selectedVisionPlan?.applicants?.length > 0) {
        setSelectedDependents([...selectedVisionPlan?.applicants]);
        onDependentClicked([...selectedVisionPlan?.applicants]);
      } else {
        setSelectedDependents([...selectedDependents]);
        onDependentClicked([...selectedDependents]);
      }

    } else {
      const tmpIndex = selectedDependents.findIndex((item) => item?.applicantId === applicantDetails?.applicantId);
      if (tmpIndex === -1) {
        selectedDependents.push(applicantDetails);
      }
      setSelectedDependents([...selectedDependents]);
      onDependentClicked([...selectedDependents]);
    }
  }, []);

  const checkIfSelected = (id) => {
    const matchedApplicant = selectedDependents.find((item) => item.applicantId === id);

    return matchedApplicant ? true : false;
  };

  return (
    <div className="dependent-selection-modal">
      <div className="plantype-description">
        {openDepModal.isMacFlow &&
          <div className='about-your-plan'>
            {labels?.DESCRIPTION?.DESC4 !== "" && labels?.DESCRIPTION?.DESC5 !== "" ?
              <div>
                <p>{labels?.DESCRIPTION?.DESC4}</p>
                <p>{labels?.DESCRIPTION?.DESC5}</p>
              </div>
              : null}
            {labels?.DESCRIPTION?.DESC3 !== "" && (<h3>{labels?.DESCRIPTION?.DESC3}</h3>)}
          </div>
        }
        {labels?.DESCRIPTION?.DESC1 !== "" && labels?.DESCRIPTION?.DESC2 && (<div>{labels?.DESCRIPTION?.DESC1} {' '}<b>{labels?.DESCRIPTION?.DESC2}</b></div>)}
      </div>
      {labels?.PRIMARY_APPLICANT_DESCRIPTION !== "" && (<div className="primary-applicant-description">{labels?.PRIMARY_APPLICANT_DESCRIPTION}</div>)}
      <div>{openDepModal.popUpType !== MACFLOW_POPUP_TYPE.DENTALWITHOUTDEPENDENTS && openDepModal.popUpType !== MACFLOW_POPUP_TYPE.VISIONWITHOUTDEPENDENTS && (<div className="dependents-row">

        <div className='dependents-column'>
          <div className="icon-container">
            <span className="sae-icon sae-icon-reviewer-male" />
          </div>
        </div>
        <div className="dependents-column">
          <div className="dependent-name bold-content">{applicantDetails?.firstName}</div>
        </div>
        <div className="dependents-column">{dayjs(applicantDetails?.dateOfBirth).format(DATE_FORMATS.MMDDYYYY)}</div>
        <div className="dependents-column">
          <Toggle checked={true} disabled label="Cover" name={applicantDetails?.firstName + 'applicant'} />
        </div>
      </div>)}
        {spouseDetails && (
          <div className="dependents-row">
            <div className='dependents-column'>
              <div className="icon-container">
                <span className="sae-icon sae-icon-contacts" />
              </div>
            </div>
            <div className="dependents-column">
              <div className="dependent-name bold-content">{spouseDetails?.firstName}</div>
            </div>
            <div className="dependents-column">{dayjs(spouseDetails?.dateOfBirth).format(DATE_FORMATS.MMDDYYYY)}</div>
            <div className="dependents-column">
              <Toggle
                checked={checkIfSelected(spouseDetails.applicantId)}
                label="Cover"
                name={agentSSOInfo.isAtk ? spouseDetails?.firstName + planType + 'spouse' : spouseDetails?.firstName + 'spouse'}
                onChange={() => {
                  const tmpIndex = selectedDependents.findIndex((item) => item.applicantId === spouseDetails.applicantId);
                  if (tmpIndex !== -1) {
                    selectedDependents.splice(tmpIndex, 1);
                  } else {
                    selectedDependents.push(spouseDetails);
                  }
                  setSelectedDependents([...selectedDependents]);
                  onDependentClicked([...selectedDependents]);
                }}
              />
            </div>
          </div>
        )}
        {dependentDetails?.map((dependent, idx) => {
          return (
            <div className="dependents-row" key={idx}>
              <div className="dependents-column">
                <div className="icon-container">
                  <span className="sae-icon sae-icon-contacts" />
                </div>
              </div>
              <div className="dependents-column">
                <div className="dependent-name bold-content">{dependent.firstName}</div>
              </div>
              <div className="dependents-column">{dayjs(dependent?.dateOfBirth).format(DATE_FORMATS.MMDDYYYY)}</div>
              <div className="dependents-column">
                <Toggle
                  label="Cover"
                  checked={checkIfSelected(dependent.applicantId)}
                  name={agentSSOInfo.isAtk ? dependent.firstName + planType + idx : dependent.firstName + idx}
                  onChange={() => {
                    const tmpIndex = selectedDependents.findIndex((item) => item.applicantId === dependent.applicantId);
                    if (tmpIndex !== -1) {
                      selectedDependents.splice(tmpIndex, 1);
                    } else {
                      selectedDependents.push(dependent);
                    }
                    setSelectedDependents([...selectedDependents]);
                    onDependentClicked([...selectedDependents]);
                  }}
                />
              </div>
            </div>
          );
        })}</div>

    </div>
  );
};

export default DependentSelectionModal;
