import { API_NAME_LIST } from '../../../api/api-name-list';
import axiosInstance from '../../../api/axiosWrapper/axiosWrapper';

const getProposalID = (userID: string, proposalId: string) => {
  return axiosInstance.get(`${API_NAME_LIST.GET_PROPOSAL_ID}/${proposalId}`, {
    headers: {
      userID: userID
    }
  });
};

export { getProposalID };
