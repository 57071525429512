import { Button, ModalSlide } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import HelpMeChoosePopup from '../../../components/common/helpme-choose-popup/HelpMeChoosePopup';
import Calculator from '../../../images/Calculator.svg';
import Discount from '../../../images/Discount.svg';
import MedicaidEligible from '../../../images/MedicaidEligible.svg';
import User from '../../../images/User.svg';
import ViewPlans from '../../../images/ViewPlans.svg';
import Colarado from '../../../images/colaradoPlans.svg';
import { COLARADO_CODE, GEORGIA_CODE, LANGUAGE_CODES, SCREEN_NAMES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { MEDICAL_PLANS_TABS } from '../medical-plans/constants';
import './HereAreOptions.scss';
import { ELIGIBILITIES } from './constants';
import { default as Content } from './messages.json';


function HereAreOptions() {
  const [pageContent, setContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [openModal, setModalOpen] = useState(false);
  const [subsidyAvailable, setSubsidyAvailable] = useState(false);
  const [medicaidligibilitylist, setMedicaidEligibilitylist] = useState<Applicant[]>([]);
  const [notMedicaidligibilitylist, setNotMedicaidEligibilitylist] = useState<Applicant[]>([]);
  const [isPrimaryApplicantEligible, setisPrimaryApplicantEligible] = useState(false);
  const { zipCodeRes, brand } = useGlobalStore((store) => store);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const { subsidyresponse, applicantFormDetails, householdMemberValues, state, updateSubsidyValue, updateApplicantFormDetails, subsidyValue, resetMedicalPlanStates, resetDentalPlanStates, resetVisionPlanStates, updateEstimateSavingsResponse, updateHouseholdMemberValues } = useGlobalStore((state) => state);
  const globalStore = useGlobalStore((store) => store);
  const { updateSelectedTab, updateIsSubsidydataApplied, updateSelectedOptionInEstimateSavings } = globalStore;

  const navigate = useNavigate();
  const getContent = useCallback(async (): Promise<boolean> => {
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.ESTIMATESAVINGS);
      let cmsResponse = response.data;

      setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.ESTIMATESAVINGS);
    } catch (error) {
      setContent(Content.data.iospagecontentList.items[0].pageContent.ESTIMATESAVINGS);

      return false;
    } finally {
      setContentLoaded(true);
    }
    return true;
  }, []);

  const initiate = async () => {
    await getContent();
  };



  useEffect(() => {
    initiate();
    updateOptions();
  }, []);

  const openSlideModal = () => {
    setModalOpen(!openModal);
  };

  const navigateToCalc = () => {
    if (subsidyresponse && subsidyresponse?.subsidyCalc && subsidyresponse.subsidyCalc[0].subsidyAmount) {
      updateSubsidyValue(subsidyresponse.subsidyCalc[0].subsidyAmount);
    }
    navigate("/individuals/shop/basics/credit-calculator");
  }

  const updateOptions = () => {
    if (subsidyresponse && subsidyresponse?.subsidyCalc && subsidyresponse.subsidyCalc[0].warningMessage === 'NOT ELIGIBLE FOR SUBSIDY') {
      getMedicAidEligibilityList();
      setSubsidyAvailable(false);
    } else {
      getMedicAidEligibilityList();
      setSubsidyAvailable(true);
    }
    resetMedicalPlanStates();
    resetDentalPlanStates();
    resetVisionPlanStates();
  };

  const getMedicAidEligibilityList = () => {
    const responseData = subsidyresponse && subsidyresponse?.subsidyCalc && subsidyresponse.subsidyCalc[0].householdMemberElig;
    if (responseData) {
      const result = applicantFormDetails.filter(
        (o1) => !responseData.some((o2) => Number(o1.applicantId) === Number(o2.memberTempId) && !ELIGIBILITIES.includes(o2.eligibility))
      );
      setMedicaidEligibilitylist(result);
      const notEligibleList = applicantFormDetails.filter(
        (o1) => !responseData.some((o2) => o1.applicantId === o2.memberTempId && ELIGIBILITIES.includes(o2.eligibility))
      );
      setNotMedicaidEligibilitylist(notEligibleList);
      const medicaidLookUp = new Map<string | undefined, boolean>();
      responseData.forEach(obj => medicaidLookUp.set(obj.memberTempId, ELIGIBILITIES.includes(obj.eligibility)));
      let updatedResult = applicantFormDetails.map(obj => {
        const isMedicAidEligible = medicaidLookUp.get(obj.applicantId);
        return { ...obj, isMedicAidEligible };
      });
      updateApplicantFormDetails([]);
      const uniqueGroups = [...new Set(result.map((item) => item.applicantId))];
      if (uniqueGroups.includes(applicantFormDetails[0].applicantId)) {
        setisPrimaryApplicantEligible(true);
      }
      updateApplicantFormDetails(updatedResult);
    }
  };

  const addDependentBlock = () => {
    return (
      <div className="discount-block" id="discount-calculator">
        <div className="estimate-options-flex" id="discount-calculator-flex">
          <div className='text-container'>
            <div className="icon-container" id="calculator-icon-container">
              <img src={Calculator} alt="" id="calculator-image" />
            </div>
            <div className="description-container" id="calculator-description-container">
              <h3 id="addotherdependent-title">{pageContent?.addOtherDependent.title}</h3>
              <h6 id="addotherdependent-question">{pageContent?.addOtherDependent.question}</h6>
              <p id="addotherdependent-description">{pageContent?.addOtherDependent.descriptionone}</p>
              {householdMemberValues.dependents !== '' && (
                <p id="addotherdependent-description2">
                  <span>{pageContent?.addOtherDependent.descriptiontwo.one}</span>
                  &nbsp;<b>{householdMemberValues.dependents}</b>&nbsp;
                  <span dangerouslySetInnerHTML={{ __html: pageContent?.addOtherDependent.descriptiontwo.two }}></span>
                </p>
              )}
            </div>
          </div>
          <div className="buttons-container" id="addotherdependent-buttons-container">
            <button className="fwc-btn fwc-btn-primary" data-analytics="useCalculatorBtnOptionsIos" id="usecalculator-button" onClick={navigateToCalc}>
              {pageContent?.estimateButtons.useCalculator}
            </button>
            <p id="learnmore-text">
              {pageContent?.learnMore}&nbsp;
              <a href="javascript:void(0)" className="link-text" data-analytics="dependentsLinkOptionsIos" id="dependents-link" onClick={openSlideModal}>
                <b>{pageContent?.links.dependents}</b>
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  };

  const notQualifyBlock = () => {
    return (
      <div className="estimate-message-block discount-block" id="estimate-message-block">
        <div className="estimate-options-flex" id="estimate-notqualifyfordiscount-flex">
          <div className='text-container'>
            <div className="icon-container" id="user-icon">
              <img src={User} alt="" id="user-image" />
            </div>
            <div className="description-container" id="notqualifyfordiscount-description-container">
              <h3 id="notqualifyfordiscount-message">{pageContent?.notQualifyForDiscount.message}</h3>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const noSubsidyHtml = () => {
    if (medicaidligibilitylist.length !== 0) {
      return (
        <>
          <div className="estimate-message-block discount-block" id="medicaideligible-message-block">
            <div className="estimate-options-flex" id="medicaidEligible-options-flex">
              <div className='text-container'>
                <div className="icon-container" id="medicaidEligible-icon-container">
                  <img src={MedicaidEligible} alt="" id="medicaidEligible-icon" />
                </div>
                <div className="description-container" id="medicaidEligible-description-container">
                  {state === GEORGIA_CODE && <h3 id="medicaidEligible-message">{pageContent?.eligibleForMedicaid.gaMesage}</h3>}
                  {state !== GEORGIA_CODE && <h3 id="medicaidEligible-message">{pageContent?.eligibleForMedicaid.message}</h3>}
                  <h3 className="MedicaidName" id="medicaid-name">
                    {medicaidligibilitylist.map((item, index) => {
                      return (
                        <span className="applicant-name" key={index}>
                          {item.firstName}{index < (medicaidligibilitylist.length - 1) && <span>,</span>}
                        </span>
                      );
                    })}
                  </h3>
                </div>
              </div>
              <div className="buttons-container" id="medicaid-buttons-container">
                <p id="learnmore-text">{pageContent?.learnMore}&nbsp;</p>
                <a href="javascript:void(0)" id="medicaideligibility-link" onClick={openSlideModal}>
                  <b>{pageContent?.links.medicaidEligibility}</b>
                </a>
              </div>
            </div>
          </div>

          <br></br>
          <br></br>
          <h2 id="estimate-title">{pageContent?.estimateTitle}</h2>
          <br></br>
          <div className="discount-block" id="user-block">
            <div className="estimate-options-flex" id="user-options-flex">
              <div className='text-container'>
                <div className="icon-container" id="user-icon-container">
                  <img src={User} alt="" id="user-image" />
                </div>
                <div className="description-container" id="user-description-container">
                  {state !== GEORGIA_CODE &&
                    <>
                      <h3>{pageContent?.notQualifyForMarketplacePlans.heading}</h3>
                      <p>
                        {medicaidligibilitylist.map((item, index) => {
                          return (
                            <span className="applicant-name" key={index}>
                              {item.firstName}{index < (medicaidligibilitylist.length - 1) && <span>,</span>}
                            </span>
                          );
                        })}
                        {pageContent?.notQualifyForMarketplacePlans.description}
                      </p></>}
                  {state === GEORGIA_CODE && (<><h3>{pageContent?.notQualifyForMarketplacePlans.gaState.heading}</h3>
                    <p className="mb10">{pageContent?.notQualifyForMarketplacePlans.gaState.description}</p>
                    <p>{pageContent?.notQualifyForMarketplacePlans.gaState.description2}</p>
                  </>
                  )}
                </div>
              </div>
              <div className="buttons-container" id="user-buttons-container">
                <p id="learn-text">{pageContent?.learnMore}</p>
                <a href="javascript:void(0)" id="marketplaceplans-link" onClick={openSlideModal}>
                  {pageContent?.links.marketplacePlans}
                </a>&nbsp;
                <span>and</span>&nbsp;
                <a href="javascript:void(0)" id="medicaideligibility-link" onClick={openSlideModal}>
                  {pageContent?.links.medicaidEligibility}
                </a>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {notQualifyBlock()}
          <br></br>
          <br></br>
          <h2 id="estimate-title">{pageContent?.estimateTitle}</h2>
          <br></br>
          {addDependentBlock()}
        </>
      );
    }
  };

  const OnClickviewMarketplacePlans = () => {
    updateSelectedTab(MEDICAL_PLANS_TABS.MARKET_PLANS);
    updateSelectedOptionInEstimateSavings(MEDICAL_PLANS_TABS.MARKET_PLANS);
    updateIsSubsidydataApplied(true);
    setShowPopup(true);
  }

  const subsidyBlock = () => {
    return (
      <>
        {subsidyValue !== -1 && Number(subsidyresponse && subsidyresponse.subsidyCalc && subsidyresponse.subsidyCalc[0].subsidyAmount) > Number(subsidyValue) && <div className='fwc-row'>
          <div className='fwc-col-12 alert-info'>
            <p><i className="sae-icon sae-icon-high-importance"></i>
              {pageContent?.alerts.increase} <span className='price'>-${subsidyresponse && subsidyresponse.subsidyCalc && subsidyresponse.subsidyCalc[0].subsidyAmount?.toFixed(2)}</span></p>
          </div>
        </div>}
        {subsidyValue !== -1 && Number(subsidyresponse && subsidyresponse.subsidyCalc && subsidyresponse.subsidyCalc[0].subsidyAmount) < Number(subsidyValue) && <div className='fwc-row'>
          <div className='fwc-col-12 alert-info'>
            <p><i className="sae-icon sae-icon-high-importance"></i>
              {pageContent?.alerts.decrease} <span className='price'>-${subsidyresponse && subsidyresponse.subsidyCalc && subsidyresponse.subsidyCalc[0].subsidyAmount?.toFixed(2)}</span></p>
          </div>
        </div>}
        {medicaidligibilitylist.length !== 0 && <div className="estimate-message-block discount-block" id="medicaideligible-message-block">
          <div className="estimate-options-flex" id="medicaidEligible-options-flex">
            <div className='text-container'>
              <div className="icon-container" id="medicaidEligible-icon-container">
                <img src={MedicaidEligible} alt="" id="medicaidEligible-icon" />
              </div>
              <div className="description-container" id="medicaidEligible-description-container">
                {state === GEORGIA_CODE && <h3 id="medicaidEligible-message">{pageContent?.eligibleForMedicaid.gaMesage}</h3>}
                {state !== GEORGIA_CODE && <h3 id="medicaidEligible-message">{pageContent?.eligibleForMedicaid.message}</h3>}
                <h3 className="MedicaidName" id="medicaid-name">
                  {medicaidligibilitylist.map((item, index) => {
                    return (
                      <span className="applicant-name" key={index}>
                        {item.firstName}{index < (medicaidligibilitylist.length - 1) && <span>,</span>}
                      </span>
                    );
                  })}
                </h3>
              </div>
            </div>
            <div className="buttons-container" id="medicaid-buttons-container">
              <p id="learnmore-text">{pageContent?.learnMore}&nbsp;</p>
              <a href="javascript:void(0)" id="medicaideligibility-link" onClick={openSlideModal}>
                <b>{pageContent?.links.medicaidEligibility}</b>
              </a>
            </div>
          </div>
        </div>
        }
        <h2 id="estimate-title">{pageContent?.estimateTitle}</h2>
        <br></br>
        <div className="estimate-message-block discount-block" id="discount-block">
          <div className="estimate-options-flex" id="estimate-options-flex">
            <div className='text-container'>
              <div className="icon-container" id="icon-container">
                <img src={Discount} alt="" id="discount-image" />
              </div>
              <div className="description-container" id="description-container">
                <h3 id="discount-description1">
                  {pageContent?.discount.description1}
                  <span className="discount" id="discount-amount">
                    -${subsidyresponse && subsidyresponse.subsidyCalc && subsidyresponse?.subsidyCalc?.[0]?.subsidyAmount?.toFixed(2)}
                  </span>
                </h3>
                <h3 id="discount-description1">{pageContent?.discount.description2}</h3>
                {medicaidligibilitylist && medicaidligibilitylist.length !== 0 && (
                  <>
                    <p>
                      {medicaidligibilitylist.map((item, index) => {
                        return (
                          <span className="applicant-name" key={index}>
                            {item.firstName}{index < (medicaidligibilitylist.length - 1) && <span>,</span>}
                          </span>
                        );
                      })}
                      <span>{pageContent?.discount.notIncluded.descone}</span>
                    </p>
                    <ul className='listofUsers'>
                      {medicaidligibilitylist.map((item, index) => {
                        return <li key={index}>{item.firstName}{index < (medicaidligibilitylist.length - 1) && <span>,</span>}</li>;
                      })}
                    </ul>
                    <p>{pageContent?.discount.notIncluded.desctwo}</p>
                  </>
                )}
              </div>
            </div>
            <div className="buttons-container" id="buttons-container">
              <button data-analytics="marketplacePlansBtnOptionsIos" className="fwc-btn fwc-btn-primary" id="viewmarketplace-button" onClick={OnClickviewMarketplacePlans}>
                {pageContent?.estimateButtons.viewMarketplacePlans}
              </button>
              <p id="learnmore-text" onClick={openSlideModal}>
                {pageContent?.learnMore}&nbsp;
                <a href="javascript:void(0)" className="link-text" data-analytics="marketplacePlansLinkOptionsIos" id="marketplans-link" onClick={openSlideModal}>
                  <b>{pageContent?.links.marketplacePlans}</b>
                </a>
              </p>
            </div>
          </div>
        </div>
        {addDependentBlock()}
      </>
    );
  };

  const OnClickviewOffMarketplacePlans = () => {
    updateSelectedTab(MEDICAL_PLANS_TABS.OFF_MARKET_PLANS);
    updateSelectedOptionInEstimateSavings(MEDICAL_PLANS_TABS.OFF_MARKET_PLANS);
    updateIsSubsidydataApplied(true);
    setShowPopup(true);
  }

  const viewOffMarketBlocks = () => {
    return (
      <div className="discount-block" id="viewplans-block">
        <div className="estimate-options-flex" id="viewplans-options-flex">
          <div className='text-container'>
            <div className="icon-container" id="viewplans-icon-container">
              <img src={ViewPlans} alt="" id="viewplans-image" />
            </div>
            <div className="description-container" id="viewplans-description-container">
              <h3 id="viewplans-title">{pageContent?.viewOffMarketplaceplans.title}</h3>
              {isPrimaryApplicantEligible && <p id="viewoffmarketplaceplans-description">{pageContent?.viewOffMarketplaceplans.description}</p>}
              {!isPrimaryApplicantEligible && <p id="viewoffmarketplaceplans-description">{subsidyAvailable && pageContent?.viewOffMarketplaceplans.gadescription}
                {!subsidyAvailable && pageContent?.viewOffMarketplaceplans.description}
                {medicaidligibilitylist.length !== 0 && <span className='including'>{'( ' + pageContent?.viewOffMarketplaceplans.including}
                  {medicaidligibilitylist.map((item, index) => {
                    return (
                      <>
                        <span className="applicant-name" key={index}>
                          {item.firstName}{index < (medicaidligibilitylist.length - 1) && <span>,</span>}
                        </span>
                        {index === (medicaidligibilitylist.length - 1) && <>)</>}
                      </>
                    );
                  })}.</span>}</p>}
            </div>
          </div>
          <div className="buttons-container" id="viewoffmarket-buttons-container">
            <button className="fwc-btn fwc-btn-primary" data-analytics="offMarketplacePlansBtnOptionsIos" id="viewoffmarketplaceplans-button" onClick={OnClickviewOffMarketplacePlans} >
              {pageContent?.estimateButtons.viewOffMarketplacePlans}
            </button>
            <p id="learnmore-text">
              {pageContent?.learnMore}&nbsp;
              <a href="javascript:void(0)" className="link-text" data-analytics="offMarketplaceLinkOptionsIos" id="offmarketplaceplans-link" onClick={openSlideModal}>
                <b>{pageContent?.links.offMarketplacePlans}</b>
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  };

  const onClickcolarodo = () => {
    // if (subsidyresponse.subsidyCalc?.[0]?.subsidyAmount) {
    //   updateEstimateSavingsResponse({});
    //   updateHouseholdMemberValues({});
    // }

    updateIsSubsidydataApplied(true);
    updateSelectedTab(MEDICAL_PLANS_TABS.COLARADO_CONNECT_PLANS);
    // Behavior for coloarado connect plans is similar to off market plans for depenendents and subsidy
    updateSelectedOptionInEstimateSavings(MEDICAL_PLANS_TABS.OFF_MARKET_PLANS);
    navigate('/individuals/shop/basics/medical-plans');
  }

  const colaradoStateBlock = () => {
    return (
      <div className="discount-block colarado-state" id="colarado-state-block">
        <div className="estimate-options-flex" id="viewplans-options-flex">
          <div className='text-container'>
            <div className="icon-container" id="viewplans-icon-container">
              <img src={Colarado} alt="" id="colarado-image" />
            </div>
            <div className="description-container" id="viewplans-description-container">
              <h3 id="viewplans-title">{pageContent?.colarado.title}</h3>
              <p id="viewoffmarketplaceplans-description">{pageContent?.colarado.description}&nbsp;
                {medicaidligibilitylist.length > 0 && '(' + pageContent?.colarado.including}
                {medicaidligibilitylist.map((item, index) => {
                  return (
                    <>
                      <span className="applicant-name" key={index}>
                        {item.firstName}{index < (medicaidligibilitylist.length - 1) && <span>,</span>}
                      </span>
                      {index === (medicaidligibilitylist.length - 1) && ')'}
                    </>
                  );
                })}
              </p>
            </div>
          </div>
          <div className="buttons-container" id="viewoffmarket-buttons-container">
            <button className="fwc-btn fwc-btn-primary" id="viewoffmarketplaceplans-button" onClick={onClickcolarodo}>
              {pageContent?.colarado.button}
            </button>
            <p id="learnmore-text">
              {pageContent?.learnMore}&nbsp;
              <a href="javascript:void(0)" className="link-text" id="offmarketplaceplans-link" onClick={openSlideModal}>
                <b>{pageContent?.colarado.link}</b>
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  }

  const primaryApplicantNotEligibleBlock = () => {
    return (
      <div className="estimate-message-block discount-block" id="estimate-message-block">
        <div className="estimate-options-flex" id="estimate-notqualifyfordiscount-flex">
          <div className='text-container'>
            <div className="icon-container" id="user-icon">
              <img src={User} alt="" id="user-image" />
            </div>
            <div className="description-container" id="notqualifyfordiscount-description-container">
              <h3 id="notqualifyfordiscount-message">
                {pageContent?.primaryApplicantNotEligible.title}
              </h3>
              <p>
                {pageContent?.primaryApplicantNotEligible.desc1} <b>{medicaidligibilitylist.length > 0 && medicaidligibilitylist[0].firstName}</b> {pageContent?.primaryApplicantNotEligible.desc2} <b>{notMedicaidligibilitylist.length > 0 && notMedicaidligibilitylist[0].firstName}</b>
              </p>
            </div>
          </div>
          <div className="buttons-container" id="addotherdependent-buttons-container">
            <button className="fwc-btn fwc-btn-primary" id="usecalculator-button" onClick={() => navigate('/individuals/shop/basics/')}>
              {pageContent?.primaryApplicantNotEligible.basics}
            </button>
            <p id="learnmore-text">
              {pageContent?.learnMore}&nbsp;
              <a href="javascript:void(0)" id="marketplaceplans-link" onClick={openSlideModal}>
                {pageContent?.links.marketplacePlans}
              </a>&nbsp;
            </p>
          </div>
        </div>
      </div >
    )
  }

  return (
    <>
      {(contentLoaded) &&
        <div className={`hereareoptions ${state}`}>
          <div className={`fwc-row`}>
            <div className="fwc-col-12">
              <br />
              <br />
              <br />
              {subsidyAvailable ? isPrimaryApplicantEligible ? primaryApplicantNotEligibleBlock() : subsidyBlock() : noSubsidyHtml()}

              {state === COLARADO_CODE && colaradoStateBlock()}

              {viewOffMarketBlocks()}

              <div className="estimate-button-wrapper" id="button-wrapper">
                <button data-analytics="backBtnOptionsIos" className="fwc-btn fwc-btn-secondary" id="backToBasics-button" onClick={navigateToCalc}>
                  {pageContent?.estimateButtons.back}
                </button>
                <button data-analytics="skipStepBtnOptionsIos" className="fwc-btn fwc-btn-secondary" id="skipThisStep-button" onClick={() => { setShowPopup(true) }}>
                  {pageContent?.estimateButtons.skipThisStep}
                </button>
              </div>
              <div className="page-content" id="page-content">
                <div dangerouslySetInnerHTML={{ __html: pageContent?.pageHtml }} id="page-footer"></div>
              </div>
            </div>
          </div>
          <ModalSlide direction="right" onClose={openSlideModal} open={openModal}>
            <ModalSlide.Body>
              <div className="popupContent" dangerouslySetInnerHTML={{ __html: pageContent?.popUpHtml }}></div>
            </ModalSlide.Body>
            <ModalSlide.Footer>
              <Button btnType="primary" id="modal-slide-cancel" onClick={openSlideModal}>
                Close
              </Button>
            </ModalSlide.Footer>
          </ModalSlide>
          <HelpMeChoosePopup showPopup={showPopup} popupClose={() => { setShowPopup(false) }} />
        </div>
      }
    </>
  );
}

export default HereAreOptions;
