import React from 'react';
import './MediaCard.scss';
const MediaCardsInner = (props) => {
  //if only one card is populated set class to fwc-col-12 sm-12 md-12 fwc-image-grid
  // if both cards are populated set class to fwc-col-6 sm-6 md-6 fwc-image-grid
  //and then show only populated items
  const classValue = (props.enableMemberCard === "true" && props.enableThinkingCard === "true") ? "fwc-col-6 sm-6 md-6 fwc-image-grid" : "fwc-col-12 sm-12 md-12 fwc-image-grid";

  return (
    <div className="fwc-container media-card">
      <div className="fwc-row">
        {props.enableMemberCard === "true" && (
          <div className={classValue} data-analytics-context="Media Card" data-analytics-name="" aria-label="">
            {props.membericon && (
              <picture>
                <img src={props.membericon} alt="member image" className="sm load" />
              </picture>
            )}
            {props.membertitle && (
              <div className="content">
                <h3>{props.membertitle}</h3>
              </div>
            )}
            {props.memberbody && (
              <div className="content">
                <span className="body">
                  <p dangerouslySetInnerHTML={{ __html: props.memberbody }}></p>
                </span>
              </div>
            )}
          </div>
        )}

        {props.enableThinkingCard === "true" && (
          <div className={classValue} data-analytics-context="Media Card" data-analytics-name="" aria-label="">
            {props.thinkingicon && (
              <picture>
                <img src={props.thinkingicon} alt="Thinking icon" className="sm load" />
              </picture>
            )}
            {props.thinkingtitle && (
              <div>
                <h3>{props.thinkingtitle}</h3>
              </div>
            )}
            {props.thinkingbody && (
              <div>
                <span className="body">
                  <p dangerouslySetInnerHTML={{ __html: props.thinkingbody }}></p>
                </span>
              </div>
            )}
          </div>
        )}
      </div>

    </div>
  );
};

const MediaCard = (props) => {
  return <MediaCardsInner {...props} />;
};
export default MediaCard;

//if all fields are empty, set media card to be empty
export const MediaCardIsEmpty = (props) => !props.memberbody && !props.membericon && !props.membertitle && !props.thinkingbody && !props.thinkingicon && !props.thinkingtitle;



