import { Modal, Tabs } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCMSStaticContent, getCMSdynamicContent } from '../../../api/cms/cmsUtil';
import { getResetFilters } from '../../../components/common/check-box-list-filter/checkBoxListFilterUtils';
import CompareTray from "../../../components/common/compare-tray/CompareTray";
import EmptyPlans from '../../../components/common/empty-plans/EmptyPlans';
import { EMPTY_PLANS_USE_CASES } from '../../../components/common/empty-plans/constants';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import GlobalHeader from '../../../components/common/global-header/GlobalHeader';
import PlanCard from '../../../components/common/plan-card/PlanCard';
import PlanCoverageAccordion from '../../../components/common/plan-coverage-accordion/PlanCoverageAccordion';
import PlanListingTitles from '../../../components/common/plan-listing-titles/PlanListingTitles';
import { getProviderTypeCode } from '../../../components/common/public-nav-bar/SaveAndResumeLater.utils';
import SelectedPlans from '../../../components/common/selected-plans/SelectedPlans';
import { getResetSwitchFilters } from '../../../components/common/switch-list-filter/switchFIlterUtils';
import { APPLICANT_TYPES, ENTITY_TYPES, LANGUAGE_CODES, MACFLOW_POPUP_TYPE, NAV_PATHS, PAGE_BLOCKS, PAGE_IDS, PLAN_TYPES, PLAN_TYPES_NAMES, SCREEN_NAMES, STATE_CODES, USER_ROLES } from '../../../shared/globalConstants';
import { getNotMedicaidEligibleDependents, recordActionSatelliteTrack } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { getSelectedPlans } from '../dental-plans/dentalPlansUtils';
import FindYourDoctor from '../find-your-doctor/FindYourDoctor';
import { PROVIDER_TYPE_IDS } from '../find-your-doctor/constants';
import MedicalPlanDetails from '../medical-plan-details/MedicalPlanDetails';
import ProviderDetails from '../provider-details/ProviderDetails';
import { getDrugsPlanSummary } from '../show-my-medications/medicationSearchServices';
import { getContractCodesInFormat } from '../show-my-medications/medicationServicesUtils';
import ShowMyMedications from '../show-my-medications/showMyMedications';
import { PLAN_ERROR_CODES } from '../vision-plans/constants';
import Filters from './Filters';
import './MedicalPlans.scss';
import { MEDICAL_PLANS_TABS, MONTHLY_COST_SORT_OPTION } from './constants';
import { default as Content } from './medicalPlans.json';
import { fetchPlanList, getProviderListById } from './medicalPlansServices';
import {
  getActiveSortValue,
  getContractCodes,
  getEmptyPlansUseCase,
  getFavouritePlans,
  getFilteredPlans,
  getFormattedApplicantDetails,
  getMatchedDoctorsForPlan,
  getMatchedMedicationsForPlan,
  getMedicalPlansPayload,
  getPlanCardLabels,
  getPlansForTab,
  getSortedPlans,
  getUpdatedFavouritePlans,
  setDomesticPartnerAsSpouse
} from './medicalPlansUtils';


const MedicalPlans = () => {
  const [selectedPlanToUpdate, setSelectedPlanToUpdate] = useState<SelectedPlan>({} as SelectedPlan);
  const [showDepSelectionModal, setShowDepSelectionModal] = useState(false);
  const [selectedDependents, setSelectedDependents] = useState<Applicant[]>([]);
  const [showFindYourDoctor, setShowFindYourDoctor] = useState(false);
  const [showMyMedications, setShowMyMedications] = useState(false);
  const [isCurrentCoveragePlan, setIsCurrentCoveragePlan] = useState(false);
  const [applicants, setApplicants] = useState<Applicant[]>([]);
  const [filteredPlans, setFilteredPlans] = useState<Contracts>([]);
  const divRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState<Contract | null>(null);
  const [drugList, setDrugList] = useState('');
  const [comparedPlans, setComparedPlans] = useState<Contract[]>([]);
  const [clikedCurrentCoverage, setClickedCurrentCoverage] = useState<CurrentCoverage | undefined>(undefined);
  const [serverError, setServerError] = useState(false);
  const [showHelpmeChooseModal, setshowHelpmeChooseModal] = useState<boolean>(false);
  const filtersRef = useRef<FilterRef>(null);
  const [noPopupDisplay, setNoPopupDisplay] = useState(false);
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);

  const importantNotice = useRef<HTMLDivElement>(null);

  const { role, loggedIn, demographicInfo, isPHILogin } = useUserStore((store) => store)
  const globalStore = useGlobalStore((store) => store);
  const {
    matchProvidersToPlans,
    savedProviders,
    medicalPlans,
    comparedMedicalPlans,
    selectedPlan,
    selectedDentalPlan,
    selectedVisionPlan,
    selectedTab,
    monthlyCostFilters,
    medicalDeductibleFilters,
    switchFilters,
    benefitsTiers,
    sortOptions,
    hmcFilter,
    matchMedicationsToPlans,
    coveredMedicationsList,
    drugsList,
    providerMetaData,
    favouriteMedicalPlans,
    showFavourites,
    memberInfo,
    currentCoverageMedicalPlan,
    year,
    updateSelectedTab,
    updatePlans,
    updateComparedMedicalPlans,
    updateSelectedPlan,
    updateSelectedDentalPlan,
    resetDentalPlanStates,
    resetVisionPlanStates,
    updateBasicPlanType,
    updateOpenDepModal,
    basicPlanType,
    closeDepModal,
    updateFavouriteMedicalPlans,
    updateShowFavourites,
    coverageType,
    applicantFormDetails,
    updateShowRecommendationModal,
    previousStep,
    updatedCoveredMedicationsList,
    updateMatchProvidersToPlansStatus,
    updateSavedProviders,
    subsidyresponse,
    updateMonthlyCostFilters,
    updateBenefitsTiers,
    updateSwitchFilters,
    updateMedicleDeductibleFilters
  } = globalStore;
  const { currentCoverages } = memberInfo
  const navigate = useNavigate();
  const { updateUsername, zipCodeRes } = useGlobalStore((state) => state);


  const getContent = useCallback(async (): Promise<boolean> => {
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.MEDICAL_PLANS);
      let cmsResponse = response.data;
      setPageContent(cmsResponse.data.iospagecontentList.items[0].pageContent?.MedicalPlans);
    } catch (error) {
      setPageContent(Content?.data.iospagecontentList.items[0].pageContent?.MedicalPlans);

      return false;
    }
    finally {
      setContentLoaded(true);
    }
    return true;
  }, []);

  useEffect(() => {
    initiate();
  }, []);

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    if (comparedMedicalPlans.length === 0) {
      document.body.classList.remove("no-scroll");
    }
  }, [comparedMedicalPlans]);

  const isColoaradoZipCode = globalStore.zipCodeRes.stateCode === STATE_CODES.CO;

  const getDrugList = async () => {
    let queryParam = ';state=' + globalStore.zipCodeRes.stateCode + ';brand=' + globalStore.brand + ';year=' + year;
    await getCMSdynamicContent(PAGE_IDS.DRUG_LIST_ALL, PAGE_BLOCKS.DRUG_LIST, queryParam)
      .then((response) => {
        setDrugList(response?.data.data?.iosdynamiccontentList?.items[0]?.textsummary);
      })
      .catch((error) => {
        console.warn('MedicalPlans.tsx getDrugList error :', error);
        // check what to do here
      });
  };

  useEffect(() => {
    let payload = getMedicalPlansPayload(useGlobalStore.getState(), hmcFilter, selectedTab);
    setApplicants(payload.applicants);
    selectedPlan.applicants = payload.applicants;
    updateSelectedPlan(selectedPlan);
    if (medicalPlans.length === 0) {
      payload.applicants = setDomesticPartnerAsSpouse(payload.applicants);
      loadMedicalPlans(payload);
    } else {
      if (showFavourites) {
        const favouritePlans = getFavouritePlans(getPlansForTab(selectedTab, medicalPlans), favouriteMedicalPlans);
        setFilteredPlans(favouritePlans);
      } else {
        parseInitialPlans(medicalPlans, false);
      }
    }
    getDrugList();
    updateComparedMedicalPlans([]);
    updateBasicPlanType(PLAN_TYPES_NAMES.MEDICAL)
  }, []);

  useEffect(() => {
    if (closeDepModal.cancel && closeDepModal.planType === PLAN_TYPES_NAMES.MEDICAL) {
      setSelectedPlanToUpdate({} as SelectedPlan);
    }
  }, [closeDepModal.cancel])

  useEffect(() => {
    if (closeDepModal.continue && closeDepModal.planType === PLAN_TYPES_NAMES.MEDICAL) {
      continueWithSelectedPlan();
    }

  }, [closeDepModal.continue])

  useEffect(() => {
    if (noPopupDisplay) {
      continueWithSelectedPlan();
    }
  }, [noPopupDisplay])


  useEffect(() => {
    if (drugsList.length && !coveredMedicationsList.length) {
      let payload: DrugSummaryPayload = { planList: { plan: getContractCodesInFormat(getContractCodes(useGlobalStore.getState().medicalPlans)) }, drugList: { drug: drugsList } };
      getDrugsPlanSummary(payload)
        .then((response) => {
          const data: DrugsSummaryResponse = response.data;
          const planSummaryList: MedicationsPlanSummary[] = data.response.planSummaryList.planSummary;
          updatedCoveredMedicationsList(planSummaryList);
        })
        .catch((error) => {
          console.warn('retrieving the matched drugs info error :', error);
        });
    }
  }, [matchMedicationsToPlans, medicalPlans])

  useEffect(() => {
    if (demographicInfo?.savedUserEntity?.length && medicalPlans.length && savedProviders.length === 0) {
      let providersData = [];
      demographicInfo?.savedUserEntity?.map((data) => {
        if (data.entityType === ENTITY_TYPES.PROVIDER && data.entitySubType !== PROVIDER_TYPE_IDS.DENTIST && PROVIDER_TYPE_IDS.VISION !== data.entitySubType) {
          const providerDetail = {
            providerIdentifier: data.entityId,
            addressIdentifier: data.compositeEntityId,
            type: getProviderTypeCode(data.entitySubType)
          };

          providersData.push(providerDetail as never);
        }
      })
      const payload = {
        providerDetail: providersData,
        brand: globalStore.brand,
        state: globalStore.zipCodeRes.stateCode,
        latitude: globalStore.zipCodeRes.latitude,
        longitude: globalStore.zipCodeRes.longitude,
        contractCodes: getContractCodes(useGlobalStore.getState().medicalPlans)
      };
      getProviderListById(payload)
        .then((response) => {
          const data = response.data;
          const providerDetails = data.providerList;
          updateSavedProviders(providerDetails);
          providerDetails.length && updateMatchProvidersToPlansStatus(true);
        })
        .catch((error) => {
          console.warn('retrieving the provider details error :', error);
        });
    }
  }, [medicalPlans])


  const parseInitialPlans = (plans: Contracts, updatePlansToStore: boolean = true) => {
    const filterObj: MedicalPlansFilters = {
      selectedTab,
      monthlyCostFilters,
      medicalDeductibleFilters,
      switchFilters,
      benefitsTiers,
    };

    if (updatePlansToStore) {
      updatePlans(plans);
    }

    applyFilters(filterObj, MONTHLY_COST_SORT_OPTION, plans);
  };

  const loadMedicalPlans = (payload: PlanSummaryPayload) => {
    setServerError(false)
    setLoading(true);

    fetchPlanList(payload)
      .then((response) => {
        const data: PlanSummaryResponse = response.data;
        const medicalPlans = data.planSummaryResponse.plans.contract;


        parseInitialPlans(medicalPlans);
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.data?.error?.errorKey === PLAN_ERROR_CODES.NO_PLANS_FOUND) {
          setServerError(false);
          updatePlans([])
          setLoading(false);
        }
        else {
          setServerError(true);
          setLoading(false);
        }
      });
  };

  const handleTabChange = (tab: string) => {
    const filterObj: MedicalPlansFilters = {
      selectedTab: tab,
      monthlyCostFilters: [],
      medicalDeductibleFilters: [],
      switchFilters: [],
      benefitsTiers: []
    };

    if (tab === MEDICAL_PLANS_TABS.OFF_MARKET_PLANS) {
      recordActionSatelliteTrack("offMarketTabMedicalPlansIos");
    } else if (tab === MEDICAL_PLANS_TABS.MARKET_PLANS) {
      recordActionSatelliteTrack("marketTabMedicalPlansIos");
    } else {
      recordActionSatelliteTrack("colaradoConnectTabMedicalPlansIos");
    }

    updateSelectedTab(tab);
    if (useGlobalStore.getState().showFavourites) {
      const favouritePlans = getFavouritePlans(getPlansForTab(tab, useGlobalStore.getState().medicalPlans), favouriteMedicalPlans);
      setFilteredPlans(favouritePlans);
    } else {
      resetAllFilters(tab);
    }
  };

  const handelMacFlowPopups = () => {
    const isHavingDentalPlan = currentCoverages.filter((item) => item.planType === PLAN_TYPES_NAMES.DENTAL);
    const isDependentAdded = applicantFormDetails.filter((item) => item.applicantType != APPLICANT_TYPES.PRIMARY)
    if (isHavingDentalPlan?.length > 0 && isDependentAdded?.length > 0) {
      updateOpenDepModal(basicPlanType, true, true, MACFLOW_POPUP_TYPE.DENTALWITHDEPENDENTS);
    } else if (isHavingDentalPlan?.length > 0 && isDependentAdded?.length <= 0) {
      updateOpenDepModal(basicPlanType, true, true, MACFLOW_POPUP_TYPE.DENTALWITHOUTDEPENDENTS);
    } else if (isHavingDentalPlan?.length <= 0 && isDependentAdded?.length > 0) {
      updateOpenDepModal(basicPlanType, true, true, MACFLOW_POPUP_TYPE.DENTALWITHOUTPLANWITHDEPENDENTS);
    } else {
      setNoPopupDisplay(true)

    }
  }

  const handleMedicationsListSubmit = () => { };

  const updatePlanSelection = (contract: Contract, matchedProviders: Provider[], matchedMedications: DrugDetails[], isSelected: boolean) => {
    if ((loggedIn || isPHILogin) && role === USER_ROLES.MEMBER) {
      if (isSelected) {
        const updatedSelectedPlan: SelectedPlan = {
          plan: null,
          applicants: [],
          matchedProviders,
          matchedMedications
        };
        updateSelectedPlan(updatedSelectedPlan);
      } else {
        const updatedMedicalPlan: SelectedPlan = {
          plan: contract,
          applicants: [],
          matchedProviders,
          matchedMedications
        };
        setSelectedPlanToUpdate(updatedMedicalPlan);
        handelMacFlowPopups()
      }
    } else {
      if (isSelected) {
        const updatedSelectedPlan: SelectedPlan = {
          plan: null,
          applicants: [],
          matchedProviders,
          matchedMedications
        };
        updateSelectedPlan(updatedSelectedPlan);
      } else if (!isSelected && globalStore.applicantFormDetails.length === 1) {
        resetDentalPlanStates();
        resetVisionPlanStates()
        updateSelectedPlan({
          plan: contract,
          applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(globalStore.applicantFormDetails)),
          matchedProviders,
          matchedMedications
        });
        updateSelectedDentalPlan({
          plan: null,
          applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(globalStore.applicantFormDetails)),
          matchedProviders: []
        });
        if (coverageType === PLAN_TYPES.MEDICAL) {
          navigate(NAV_PATHS.PLAN_SUMMARY);
        } else {
          navigate(NAV_PATHS.DENTAL_LISTING);
        }
      } else {
        const updatedSelectedPlan: SelectedPlan = {
          plan: contract,
          applicants: [],
          matchedProviders,
          matchedMedications
        };
        setSelectedPlanToUpdate(updatedSelectedPlan);
        if (coverageType === PLAN_TYPES.MEDICAL) {
          updateSelectedPlan({
            plan: contract,
            applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(globalStore.applicantFormDetails)),
            matchedProviders: matchedProviders,
            matchedMedications: matchedMedications
          });
          navigate(NAV_PATHS.PLAN_SUMMARY);
        } else {
          updateOpenDepModal(basicPlanType, true, false, MACFLOW_POPUP_TYPE.DENTALDEPENDENTMODAL);
        }
      }
    }
  };

  const continueWithSelectedPlan = () => {
    resetDentalPlanStates();
    resetVisionPlanStates()
    updateSelectedPlan({
      plan: selectedPlanToUpdate.plan,
      applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(globalStore.applicantFormDetails)),
      matchedProviders: selectedPlanToUpdate.matchedProviders,
      matchedMedications: selectedPlanToUpdate.matchedMedications
    });
    if (globalStore.applicantFormDetails.length === 1) {
      updateSelectedDentalPlan({
        plan: null,
        applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(globalStore.applicantFormDetails)),
        matchedProviders: []
      });
    } else {
      updateSelectedDentalPlan({
        plan: null,
        applicants: getFormattedApplicantDetails(selectedDentalPlan.applicants),
        matchedProviders: []
      });
    }
    navigate(NAV_PATHS.DENTAL_LISTING);
    setNoPopupDisplay(false);
  };

  const handleChangeClick = (palnType: string) => {
    switch (palnType) {
      case PLAN_TYPES_NAMES.DENTAL:
        return navigate(NAV_PATHS.DENTAL_LISTING);
      case PLAN_TYPES_NAMES.VISION:
        return updateShowRecommendationModal(true);
      default:
        break;
    }
  };
  const handleContinueClick = () => {
    if (coverageType === PLAN_TYPES.MEDICAL && role !== USER_ROLES.MEMBER) {
      navigate(NAV_PATHS.PLAN_SUMMARY);
    } else {
      if ((loggedIn || isPHILogin) && role === USER_ROLES.MEMBER) {
        if (selectedDentalPlan?.applicants?.length === 0) {
          handelMacFlowPopups()
        } else {
          navigate(NAV_PATHS.DENTAL_LISTING);
        }

      } else {
        if (selectedDentalPlan?.applicants?.length === 0 && applicantFormDetails?.length === 1) {
          resetDentalPlanStates();
          resetVisionPlanStates()
          updateSelectedDentalPlan({
            plan: null,
            applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(globalStore.applicantFormDetails)),
            matchedProviders: []
          });
          navigate(NAV_PATHS.DENTAL_LISTING);
        } else if (selectedDentalPlan?.applicants?.length === 0 && applicantFormDetails?.length > 1) {
          resetDentalPlanStates();
          resetVisionPlanStates();
          updateOpenDepModal(basicPlanType, true, false, MACFLOW_POPUP_TYPE.DENTALDEPENDENTMODAL);
        } else {
          navigate(NAV_PATHS.DENTAL_LISTING);
        }
      }
    }
  }

  const renderSubText = () => {
    return (
      <div className="important-label-container">
        <span id="medical-plans-coverage-help-icon" className={'sae-icon sae-icon-info info-icon'} />
        <p id="medical-plans-coverage-important-text" className="btm-sm">
          {pageContent?.SEE + ' '}
          <a data-analytics="importantNoticeMedicalPlansIos" href={'javascript:void(0)'} onClick={() => scrolltoImprtantNotice()} >{pageContent?.IMPORTANT}</a>
          {' ' + pageContent?.NOTICE_ABOUT}
        </p>
      </div>
    );
  };

  const scrolltoImprtantNotice = () => {
    importantNotice.current && importantNotice.current.scrollIntoView({ behavior: 'smooth' })
  }

  const applyFilters = (filtersObj: MedicalPlansFilters, sortOption: SortOption, plans: Contracts = useGlobalStore.getState().medicalPlans) => {
    const applicablePlans = plans.length > 0 ? plans : medicalPlans;
    const filteredPlans = getFilteredPlans(
      filtersObj,
      applicablePlans,
      savedProviders,
      matchProvidersToPlans,
      matchMedicationsToPlans,
      drugsList,
      coveredMedicationsList
    );
    const sortedPlans = getSortedPlans(filteredPlans, sortOption);

    setFilteredPlans(sortedPlans);
  };

  const sortMedicalPlans = (sortOption: SortOption) => {
    const sortedPlans = getSortedPlans(filteredPlans, sortOption);
    setFilteredPlans(sortedPlans);
  };

  const openMedicalDetailsSlider = (contract: Contract, isCurrentCoveragePlan: boolean) => {
    setContract(contract);
    setIsCurrentCoveragePlan(isCurrentCoveragePlan);
  };

  const handleClickOnYes = () => {
    filtersRef.current?.resetAllFilters();
    setshowHelpmeChooseModal(false);
    setTimeout(() => {
      navigate('/individuals/shop/basics/help-me-choose');
    }, 0)
  }

  const renderFilters = () => {
    if (!showFavourites) {
      return (
        <div className="fwc-row">
          <Filters
            labels={pageContent?.FILTER_LABELS}
            plans={medicalPlans}
            ref={filtersRef}
            showFavourites={showFavourites}
            applyFilters={(filters) => applyFilters(filters, getActiveSortValue(sortOptions))}
            onSortApply={(sortOption) => sortMedicalPlans(sortOption)}
          />
        </div>
      )
    }
  }

  const resetAllFilters = (tab: string = selectedTab) => {
    const filters: MedicalPlansFilters = {
      selectedTab: tab,
      monthlyCostFilters: getResetFilters(monthlyCostFilters),
      medicalDeductibleFilters: getResetFilters(medicalDeductibleFilters),
      benefitsTiers: getResetFilters(benefitsTiers),
      switchFilters: getResetSwitchFilters(switchFilters)
    };

    updateMonthlyCostFilters(filters.monthlyCostFilters);
    updateMedicleDeductibleFilters(filters.medicalDeductibleFilters);
    updateSwitchFilters(filters.switchFilters);
    updateBenefitsTiers(filters.benefitsTiers)
    applyFilters(filters, MONTHLY_COST_SORT_OPTION)
  };

  const getFavouritePlan = (favouritePlanContractCode: string) => {
    const updatedFavouritePlans = getUpdatedFavouritePlans(favouritePlanContractCode, favouriteMedicalPlans);
    if (updatedFavouritePlans.length > 0) {
      updateFavouriteMedicalPlans(updatedFavouritePlans)
      if (showFavourites) {
        const favouritePlans = getFavouritePlans(getPlansForTab(selectedTab, medicalPlans), updatedFavouritePlans);
        setFilteredPlans(favouritePlans);
      }
    }
    else {
      updateShowFavourites(false)
      resetAllFilters()
    }
  }

  const hideOrShowFavouritePlans = () => {
    if (!showFavourites) {
      const favouritePlans = getFavouritePlans(getPlansForTab(selectedTab, medicalPlans), favouriteMedicalPlans);
      setFilteredPlans(favouritePlans);
    } else {
      resetAllFilters()
    }

    updateShowFavourites(!showFavourites);
  }

  const renderFavouriteToggle = () => {
    return (
      <div className="fwc-row">
        <div className="show-fav-all-plans-wrapper">
          {favouriteMedicalPlans.length > 0 &&
            <span className="show-fav-plans" onClick={() => hideOrShowFavouritePlans()}>
              <span id="favourite-icon" className={`sae-icon ${!showFavourites ? 'sae-icon-star' : 'sae-icon-heart-health'}`} />
              {!showFavourites ? `${pageContent?.SHOW_MY} ${favouriteMedicalPlans?.length} ${pageContent?.FAVOURITE_PLANS}` : `${pageContent?.SHOW_ALL} ${medicalPlans.length} ${pageContent?.MEDICAL_PLANS}`}
            </span>
          }
        </div>
      </div>
    )
  }

  const handleMedicalBackButton = () => {
    updateSelectedPlan({
      plan: null,
      applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(globalStore.applicantFormDetails)),
      matchedProviders: [],
    });
    !subsidyresponse.subsidyCalc?.[0]?.subsidyAmount ? navigate(previousStep) : navigate(NAV_PATHS.OPTIONS);

  }
  const handleCompareTraySelect = (contract) => {
    if (contract.contractCode === selectedPlan?.plan?.contractCode) {
      const updatedSelectedPlan: SelectedPlan = {
        plan: null,
        applicants: [],
        matchedProviders: getMatchedDoctorsForPlan(matchProvidersToPlans, savedProviders, contract.contractCode),
        matchedMedications: getMatchedMedicationsForPlan(matchMedicationsToPlans, drugsList, contract.contractCode, coveredMedicationsList)
      };
      updateSelectedPlan(updatedSelectedPlan);
      window.scrollTo(0, 0);
    } else {
      resetDentalPlanStates();
      resetVisionPlanStates()
      const updatedMedicalPlan: SelectedPlan = {
        plan: contract,
        applicants: [],
        matchedProviders: getMatchedDoctorsForPlan(matchProvidersToPlans, savedProviders, contract.contractCode),
        matchedMedications: getMatchedMedicationsForPlan(matchMedicationsToPlans, drugsList, contract.contractCode, coveredMedicationsList)
      };
      setSelectedPlanToUpdate(updatedMedicalPlan);
      updateSelectedPlan({
        plan: contract,
        applicants: getFormattedApplicantDetails(globalStore.applicantFormDetails),
        matchedProviders: getMatchedDoctorsForPlan(matchProvidersToPlans, savedProviders, contract.contractCode),
        matchedMedications: getMatchedMedicationsForPlan(matchMedicationsToPlans, drugsList, contract.contractCode, coveredMedicationsList)
      });
      window.scrollTo(0, 0);
    }
  };
  const getCurrentCoverage = () => {
    if ((loggedIn || isPHILogin) && role === USER_ROLES.MEMBER && currentCoverageMedicalPlan.contractCode) {
      return <PlanCard
        planType={'medical'}
        key={currentCoverageMedicalPlan.contractCode}
        labels={getPlanCardLabels(pageContent?.PLAN_CARD_LABELS)}
        contract={currentCoverageMedicalPlan}
        selectedPlan={selectedPlan}
        drugList={''}
        matchedProviders={[]}
        matchedMedications={[]}
        matchedMedicationsFlag={false}
        matchedProvidersFlag={false}
        isFavouritePlan={favouriteMedicalPlans.includes(currentCoverageMedicalPlan.contractCode)}
        onPlanNameClick={(clickedPlan: Contract) => openMedicalDetailsSlider(clickedPlan, true)}
        onSelectClick={() => { }}
        onFavouriteClick={(favouritePlanContractCode) => getFavouritePlan(favouritePlanContractCode)}
        isCurrentCoveragePlan={true}
        scrolltoImprtantNotice={() => scrolltoImprtantNotice()}
      />

    }

    return <div />
  }

  return (!contentLoaded ? <FullPageLoader /> :
    <div className="medical-plans-container" ref={divRef}>
      <div className={'fwc-row'}>
        <a data-analytics="backHeaderIos" className='global-back-link' id={'medical-back'} href={'javascript:void(0)'} onClick={() => handleMedicalBackButton()}>
          Back
        </a>
      </div>
      {loading && <FullPageLoader />}
      {showFindYourDoctor && (
        <FindYourDoctor
          showFindYourDoctor={showFindYourDoctor}
          contractCodes={getContractCodes(medicalPlans)}
          closeFindYourDoctor={() => setShowFindYourDoctor(false)}
        />
      )}
      {showMyMedications && (
        <ShowMyMedications
          showSlider={showMyMedications}
          onSubmit={handleMedicationsListSubmit}
          contractCodes={getContractCodes(medicalPlans)}
          onChange={() => setShowMyMedications(false)}
        />
      )}

      {contract && (
        <MedicalPlanDetails
          isCurrentCoveragePlan={isCurrentCoveragePlan}
          closeMedicalPlanDetails={() => setContract(null)}
          contract={contract}
          divRef={divRef}
        />
      )}
      <GlobalHeader />
      <div className="medical-plans-body">
        <SelectedPlans
          plans={getSelectedPlans(selectedPlan, selectedDentalPlan, selectedVisionPlan)}
          handleChangeClick={(planType) => handleChangeClick(planType)}
          screenPlanType={PLAN_TYPES_NAMES.MEDICAL}
        />
        <div className="fwc-row j-between" data-testid='title-id'>
          <PlanListingTitles
            title={pageContent?.VIEW_AND_SELECT_MEDICAL_PLANS}
            description={pageContent?.ALL_OF_YOUR_PLANS_INCLUDE}
            renderSubText={() => renderSubText()}
          />
          {getSelectedPlans(selectedPlan, selectedDentalPlan, selectedVisionPlan).length > 0 &&
            <button data-analytics="continueBtnMedicalPlansIos" className={"fwc-btn fwc-btn-secondary small"} onClick={() => handleContinueClick()}>{pageContent?.SELECTED_PLAN_CARD_LABELS.CONTINUE}</button>}
        </div>
        <div className="fwc-row">
          <PlanCoverageAccordion planType={PLAN_TYPES_NAMES.MEDICAL} labels={pageContent?.ACCORDION_LABELS} colaradoPlans={selectedTab === MEDICAL_PLANS_TABS.COLARADO_CONNECT_PLANS} applicants={applicants} />
        </div>
        <div className="fwc-row">
          <div className="tabs-container">
            <Tabs value={selectedTab || MEDICAL_PLANS_TABS.OFF_MARKET_PLANS} onChange={handleTabChange}>
              <Tabs.Tab
                id="medical-plans-market-plans-tab-off-market-plans"
                value={MEDICAL_PLANS_TABS.OFF_MARKET_PLANS}
                analytics={'offMarketTabMedicalPlansIos'}
                label={pageContent?.OFF_MARKET_PLANS}
              />
              <Tabs.Tab
                id="medical-plans-off-market-plans-tab-market-plans"
                value={MEDICAL_PLANS_TABS.MARKET_PLANS}
                analytics={'marketTabMedicalPlansIos'}
                label={pageContent?.MARKET_PLANS}
              />
              {isColoaradoZipCode ? (
                <Tabs.Tab
                  id="medical-plans-off-market-plans-tab-colarado-connect-plans"
                  value={MEDICAL_PLANS_TABS.COLARADO_CONNECT_PLANS}
                  label={pageContent?.COLARADO_CONNECT_PLANS}
                />
              ) : (
                <div />
              )}
            </Tabs>
          </div>
        </div>
        {!loading && (filteredPlans.length === 0 || medicalPlans.length == 0) ? (<div className="filters-list-outer-wrapper">
          {getEmptyPlansUseCase(serverError, medicalPlans) === EMPTY_PLANS_USE_CASES.NO_PLANS_AVAILABLE_WITH_FILTERS && renderFilters()}
          {renderFavouriteToggle()}
          <EmptyPlans useCase={getEmptyPlansUseCase(serverError, medicalPlans)} labels={medicalPlans.length == 0 ? pageContent?.MEDICAL_EMPTY_PLANS : pageContent?.EMPTY_PLANS} /></div>) : (
          <div>
            <div className="fwc-row">
              <div className="utilities-container">
                {selectedTab !== MEDICAL_PLANS_TABS.COLARADO_CONNECT_PLANS && (
                  <a data-analytics="helpChooseMedicalPlansIos" href={'javascript:void(0);'} id="medical-plans-help-me-choose" className="utility-option" onClick={() => { setshowHelpmeChooseModal(true) }}>
                    <span id="plan-coverage-help" className={'sae-icon sae-icon-help utility-icon'} />
                    <span>{pageContent?.HELP_ME_CHOOSE}</span>
                  </a>
                )}
                <a data-analytics="showDoctorMedicalPlansIos" href={'javascript:void(0);'} id="medical-plans-show-my-doctor" className="utility-option" data-testid='find-your-doctor-button' onClick={() => setShowFindYourDoctor(true)}>
                  <span id="plan-coverage-help" className={'sae-icon sae-icon-doctors-bag utility-icon'} />
                  <span>{pageContent?.SHOW_MY_DOCTOR}</span>
                </a>
                <a data-analytics="showMyMedsMedicalPlansIos" href={'javascript:void(0);'} id="medical-plans-show-my-medications" className="utility-option" onClick={() => setShowMyMedications(true)}>
                  <span className={'sae-icon sae-icon-prescription-pill-bottle utility-icon'} />
                  <span>{pageContent?.SHOW_MY_MEDICATIONS}</span>
                </a>
              </div>
            </div>
            <div className="filters-list-outer-wrapper">
              {!showFavourites && renderFilters()}
              <div className="fwc-row label-sort-wrapper">
                {showFavourites && filteredPlans.length > 0 ?
                  <span className="view-count-text">
                    <>{pageContent?.YOU_ARE_VIEWING} {pageContent?.YOUR} <b>{favouriteMedicalPlans.length} {pageContent?.FAVOURITE} {pageContent?.MEDICAL_PLANS}</b></>
                  </span>
                  :
                  <span>
                    {pageContent?.YOU_ARE_VIEWING + ' '}
                    <span className="medical-plans-label">
                      {filteredPlans.length} {pageContent?.MEDICAL_PLANS}
                    </span>
                  </span>

                }
                <div>
                  {renderFavouriteToggle()}
                </div>
              </div>
              <div className="fwc-row">
                <div className="plan-list-container">
                  {getCurrentCoverage()}
                  {filteredPlans.map((contract: Contract) => {
                    return (
                      <PlanCard
                        planType={'medical'}
                        key={contract.contractCode}
                        labels={getPlanCardLabels(pageContent?.PLAN_CARD_LABELS)}
                        contract={contract}
                        selectedPlan={selectedPlan}
                        drugList={drugList}
                        matchedProviders={getMatchedDoctorsForPlan(matchProvidersToPlans, savedProviders, contract.contractCode)}
                        matchedMedications={getMatchedMedicationsForPlan(matchMedicationsToPlans, drugsList, contract.contractCode, coveredMedicationsList)}
                        matchedMedicationsFlag={matchMedicationsToPlans}
                        matchedProvidersFlag={matchProvidersToPlans}
                        isFavouritePlan={favouriteMedicalPlans.includes(contract.contractCode)}
                        onPlanNameClick={(clickedPlan: Contract) => openMedicalDetailsSlider(clickedPlan, false)}
                        onSelectClick={(contract, matchedProviders, matchedMedications, isSelected) => {
                          updatePlanSelection(contract, matchedProviders, matchedMedications, isSelected);
                        }}
                        onFavouriteClick={(favouritePlanContractCode) => getFavouritePlan(favouritePlanContractCode)}
                        scrolltoImprtantNotice={() => scrolltoImprtantNotice()}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>)}
        <div className="footer-wrapper">
          <div className="fwc-row footer-buttons-container" ref={importantNotice}>
            <button
              id="medical-plans-back-to-estimate"
              className="footer-button fwc-btn fwc-btn-secondary"
              onClick={handleMedicalBackButton}
              data-analytics="backBtnMedicalPlansIos"
            >
              {pageContent?.BACK}
            </button>
            <button
              id="medical-plans-go-to-top"
              className="footer-button fwc-btn fwc-btn-secondary"
              data-analytics="goToTopBtnMedicalPlansIos"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              {pageContent?.GO_TO_TOP}
            </button>
          </div>
          <div className="fwc-row" >
            <div className="important-message" >
              <p>
                <b>{pageContent?.IMPORTANT}</b>
              </p>
              <p dangerouslySetInnerHTML={{ __html: pageContent?.IMPORTNAT_NOTICE_PARA_ONE }}></p>
              <p dangerouslySetInnerHTML={{ __html: pageContent?.IMPORTNAT_NOTICE_PARA_TWO }}></p>
              <p dangerouslySetInnerHTML={{ __html: pageContent?.IMPORTNAT_NOTICE_PARA_THREE }}></p>
            </div>
          </div>
        </div>
      </div>


      <Modal open={showHelpmeChooseModal} onClose={() => { setshowHelpmeChooseModal(false) }} title={pageContent?.HELPMECHOOSE_MODAL.TITLE}>
        <Modal.Body>
          <div className="helpmechoose-popup-body" id="helpmechoose-popup-body">
            <p id="helpmechoose-content">{pageContent?.HELPMECHOOSE_MODAL.CONTENT}</p>
          </div>
          <Modal.Footer>
            <div className="helpmechoose-buttons" id="helpmechoose-buttons-wrapper">
              <button
                id="lan-button"
                className="fwc-btn fwc-btn-secondary"
                onClick={() => { setshowHelpmeChooseModal(false) }}
              >
                {pageContent?.HELPMECHOOSE_MODAL.NO}
              </button>
              <button
                id="lan-button"
                className="fwc-btn-primary"
                onClick={handleClickOnYes}
              >
                {pageContent?.HELPMECHOOSE_MODAL.YES}
              </button>
            </div>
          </Modal.Footer>
        </Modal.Body>
      </Modal>

      {providerMetaData && (
        <ProviderDetails
          useCase={PLAN_TYPES_NAMES.MEDICAL}
        />
      )}
      {comparedMedicalPlans.length > 0 &&
        <CompareTray
          planType={'medical'}
          plans={comparedMedicalPlans}
          matchedProvidersFlag={matchProvidersToPlans}
          matchedMedicationsFlag={matchMedicationsToPlans}
          handleSelectPlan={handleCompareTraySelect}
          labels={getPlanCardLabels(pageContent?.PLAN_CARD_LABELS)}
          onFavouriteClick={getFavouritePlan}
        ></CompareTray>}
    </div>
  );
};

export default MedicalPlans;
