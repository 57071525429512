import { Button, Modal, PageHeader, Row, Title } from '@sydney-broker-ui/ios';
import { AxiosError, isAxiosError } from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { HTTP_STATUS_CODES, STATUSES } from '../../../api/constants';
import AccountRecoveryForm from '../../../components/common/account-recovery-form/AccountRecoveryForm';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import GlobalFooter from '../../../components/common/global-footer/GlobalFooter';
import GlobalHeader from '../../../components/common/global-header/GlobalHeader';
import InfoBar from '../../../components/common/info-bar/InfoBar';
import PublicNavBar from '../../../components/common/public-nav-bar/PublicNavBar';
import SuccessModalBody from '../../../components/common/success-modal-body/SuccessModalBody';
import { DATE_FORMATS, ERROR_ALERT_CONFIG, LANGUAGE_CODES, SCREEN_NAMES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import TwoFAModalBody from '../two-fa-modal/TwoFAModalBody';
import './AccountRecovery.scss';
import {
  getForgotPasswordPayload,
  getForgotUserNamePayload,
  getForgotUsernameSuccessPayload,
  validateDob,
  validateEmail,
  validateFirstName,
  validateLastName
} from './AccountRecoveryUtils';
import { default as Content } from './accountRecovery.json';
import { forgotPassword, forgotUserName } from './accountRecoveryServices';
import {
  ACCOUNT_RECOVERY_USE_CASES,
  FORGOT_PASSWORD_ERROR_CODES,
  FORGOT_PASSWORD_RESPONSE_CODES,
  FORGOT_USERNAME_ERROR_CODES,
  FORGOT_USERNAME_RESPONSE_CODES
} from './constants';

const { FORGOT_USERNAME } = ACCOUNT_RECOVERY_USE_CASES;

function AccountRecovery({ useCase = FORGOT_USERNAME }: { useCase: string }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState({
    title: '',
    label1: '',
    label2: '',
    label3: ''
  });
  const [dobError, setDobError] = useState('');
  const [loading, setLoading] = useState(false);
  const [accountRecoveryError, setAccountRecoveryError] = useState({
    hasError: false,
    responseCode: '',
    message: ''
  });
  const [showTwoFAModal, setShowTwoFAModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [modalHeading, setModalHeading] = useState('');
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const { zipCodeRes, brand } = useGlobalStore((store) => store);

  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.ACCOUNT_RECOVERY);
      content = response.data.data.iospagecontentList.items[0].pageContent?.AccountRecovery;
    } catch (error) {
      content = Content.data.iospagecontentList.items[0].pageContent?.AccountRecovery;
      return false;
    } finally {
      setPageContent(content);
      setContentLoaded(true);
    }
    return true;
  }, [zipCodeRes.stateCode]);

  const initiate = async () => {
    await getContent();
  };
  useEffect(() => {
    initiate();
  }, []);

  const navigate = useNavigate();

  const updateLoginResponse = useUserStore((state) => state.updateLoginResponse);

  useEffect(() => {
    resetInputs();
    resetFieldErrors();
    resetPageErrors();
  }, [useCase]);

  const resetInputs = () => {
    setFirstName('');
    setLastName('');
    setEmailAddress('');
    setDateOfBirth('');
  };

  useEffect(() => {
    dateOfBirth && checkDob(dateOfBirth, false);
  }, [dateOfBirth]);

  const resetFieldErrors = () => {
    setFirstNameError('');
    setLastNameError('');
    setEmailError({
      title: '',
      label1: '',
      label2: '',
      label3: ''
    });
    setDobError('');
  };

  const resetPageErrors = () => {
    setAccountRecoveryError({
      hasError: false,
      responseCode: '',
      message: ''
    });
  };

  const validateInputs = () => {
    resetPageErrors();
    resetFieldErrors();
    const isValidFirstName = checkFirstName();
    const isValidLastName = checkLastName();
    const isValidEmail = checkEmail();
    const isValidDob = checkDob(dateOfBirth, true);

    if (isValidFirstName && isValidLastName && isValidEmail && isValidDob) {
      handleAccountRecovery();
      //Valid inputs, so make API call
    }
  };

  const checkFirstName = () => {
    const { isValidFirstName, firstNameErrorMessage } = validateFirstName(firstName);

    if (!isValidFirstName) {
      setFirstNameError(firstNameErrorMessage);
    } else {
      setFirstNameError('');
    }

    return isValidFirstName;
  };

  const checkLastName = () => {
    const { isValidLastName, lastNameErrorMessage } = validateLastName(lastName);

    if (!isValidLastName) {
      setLastNameError(lastNameErrorMessage);
    } else {
      setLastNameError('');
    }

    return isValidLastName;
  };

  const checkEmail = () => {
    const { isValidEmail, emailErrorObj } = validateEmail(emailAddress);

    if (!isValidEmail) {
      setEmailError(emailErrorObj);
    } else {
      setEmailError({
        title: '',
        label1: '',
        label2: '',
        label3: ''
      });
    }

    return isValidEmail;
  };

  const checkDob = (date: string, isNextClicked = false) => {
    updateDob(date);
    const { isValidDob, dobErrorMessage } = validateDob(date, isNextClicked);

    if (!isValidDob) {
      setDobError(dobErrorMessage);
    } else {
      setDobError('');
    }

    return isValidDob;
  };

  const updateDob = (dob) => {
    if (dob) {
      const updatedDobValue = moment(dob).format(DATE_FORMATS.YYYYMMDD);
      setDateOfBirth(updatedDobValue);
    } else {
      setDateOfBirth('');
    }
  };

  const handleAccountRecovery = () => {
    setLoading(true);

    if (useCase === ACCOUNT_RECOVERY_USE_CASES.FORGOT_USERNAME) {
      handleForgotUserName();
    } else {
      handleForgotPassword();
    }
  };

  const handleTwoFAModalClose = () => {
    setShowTwoFAModal(false);
  };

  const handleForgotUserName = () => {
    const payload: ForgotUserNamePayload = getForgotUserNamePayload(firstName, lastName, emailAddress, dateOfBirth);

    forgotUserName(payload)
      .then((response) => {
        const data: ForgotUsernameResponse = response.data;

        if (
          response.status === HTTP_STATUS_CODES.SUCCESS &&
          data.status === STATUSES.SUCCESS &&
          data.responseMessage.responseCode === FORGOT_USERNAME_RESPONSE_CODES.USER_FIND_SUCCESSFULL
        ) {
          setLoading(false);
          setShowSuccessMessage(false);
          //update User Store
          const forgotUsernameSuccessPayload = getForgotUsernameSuccessPayload(data, firstName, lastName, emailAddress, dateOfBirth);
          updateLoginResponse(forgotUsernameSuccessPayload);
          setModalHeading(pageContent.MODAL_HEADING);
          setShowTwoFAModal(true);
        } else {
          setLoading(false);
          setAccountRecoveryError({
            hasError: true,
            responseCode: data.responseMessage.responseCode,
            message: data.responseMessage.message
          });
        }
      })
      .catch((error: AxiosError<ForgotUsernameError>) => {
        if (isAxiosError(error)) {
          const errorObj = error.response?.data?.error;

          setAccountRecoveryError({
            hasError: true,
            responseCode: errorObj?.errorKey ? errorObj?.errorKey : '',
            message:
              errorObj?.errorKey === FORGOT_USERNAME_ERROR_CODES.INVALID_INPUTS
                ? pageContent.INVALID_INPUTS
                : `<b>${pageContent.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent.SYSTEM_EXCEPTION_MESSAGE} `
          });
        } else {
          setAccountRecoveryError({
            hasError: true,
            responseCode: '',
            message: `<b>${pageContent.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent.SYSTEM_EXCEPTION_MESSAGE} `
          });
        }
        setLoading(false);
      });
  };

  const handleForgotPassword = () => {
    const payload: ForgotPasswordPayload = getForgotPasswordPayload(firstName, lastName, emailAddress, dateOfBirth);

    forgotPassword(payload)
      .then((response) => {
        const data: ForgotPasswordResponse = response.data;

        if (
          response.status === HTTP_STATUS_CODES.SUCCESS &&
          data.status === STATUSES.SUCCESS &&
          data.responseMessage.responseCode === FORGOT_PASSWORD_RESPONSE_CODES.USER_FIND_SUCCESSFULL
        ) {
          setLoading(false);
          setModalHeading('');
          setShowTwoFAModal(true);
          setShowSuccessMessage(true);
          window._satellite.track('successModalForgotPassIos');
        } else {
          setLoading(false);
          setAccountRecoveryError({
            hasError: true,
            responseCode: data.responseMessage.responseCode,
            message: data.responseMessage.message
          });
        }
      })
      .catch((error: AxiosError<ForgotUsernameError>) => {
        if (isAxiosError(error)) {
          const errorObj = error.response?.data?.error;

          setAccountRecoveryError({
            hasError: true,
            responseCode: errorObj?.errorKey ? errorObj?.errorKey : '',
            message:
              errorObj?.errorKey === FORGOT_PASSWORD_ERROR_CODES.INVALID_INPUTS
                ? pageContent.INVALID_INPUTS
                : `<b>${pageContent.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent.SYSTEM_EXCEPTION_MESSAGE} `
          });
        } else {
          setAccountRecoveryError({
            hasError: true,
            responseCode: '',
            message: `<b>${pageContent.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent.SYSTEM_EXCEPTION_MESSAGE} `
          });
        }
        setLoading(false);
      });
  };

  return (
    !contentLoaded ? <FullPageLoader /> : <div>
      <GlobalHeader />
      {loading && <FullPageLoader />}
      <div className={'header-container'}>
        <PageHeader>
          <Row alignItems="center">
            <div className="fwc-row">
              <div className="fwc-col-6">
                <Title>{useCase === FORGOT_USERNAME ? pageContent.FORGOT_USER_NAME : pageContent.FORGOT_PASSWORD}</Title>
              </div>
            </div>
          </Row>
        </PageHeader>
        <PublicNavBar hideSaveAndResumeLater={false} />
      </div>
      <AccountRecoveryForm
        formHeading={useCase === FORGOT_USERNAME ? pageContent.TO_RETRIEVE_YOUR_USER_NAME : pageContent.TO_RETRIEVE_YOUR_PASSWORD}
        firstNameConfig={{
          value: firstName,
          label: pageContent.FIRST_NAME,
          error: firstNameError,
          onChange: (e) => setFirstName(e.target.value),
          onBlur: () => checkFirstName()
        }}
        lastNameConfig={{
          value: lastName,
          label: pageContent.LAST_NAME,
          error: lastNameError,
          onChange: (e) => setLastName(e.target.value),
          onBlur: () => checkLastName()
        }}
        emailConfig={{
          value: emailAddress,
          label: pageContent.EMAIL,
          error: emailError,
          onChange: (e) => setEmailAddress(e.target.value),
          onBlur: () => checkEmail()
        }}
        dobConfig={{
          label: pageContent.DOB,
          error: dobError,
          value: dateOfBirth,
          onChange: (dob) => {
            updateDob(dob);
          },
          onBlur: (date, isNextClicked) => {
            checkDob(date, isNextClicked);
          }
        }}
      />

      {accountRecoveryError.hasError && !loading && (
        <div className="fwc-row error-container">
          <InfoBar
            area={ERROR_ALERT_CONFIG.AREA}
            backgroundColor={'error'}
            labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
            type={ERROR_ALERT_CONFIG.ERROR}
            handleClose={() => {
              resetPageErrors();
            }}
          >
            <p className="message" dangerouslySetInnerHTML={{ __html: accountRecoveryError.message }} />
          </InfoBar>
        </div>
      )}

      <div className={'fwc-row bottom-button-container'}>
        <Button btnType="secondary" data-analytics={useCase === FORGOT_USERNAME ? "cancelBtnInfoForgotUsereIos" : "cancelBtnInfoForgotPassIos"} id="sq-previous" onClick={() => navigate(-1)}>
          {pageContent.CANCEL}
        </Button>
        <Button
          data-analytics={useCase === FORGOT_USERNAME ? "nextBtnInfoForgotUserIos" : "nextBtnInfoForgotPassIos"}
          onClick={() => {
            validateInputs();
          }}
        >
          {useCase === FORGOT_USERNAME ? pageContent.NEXT : pageContent.SEND_EMAIL}
        </Button>
      </div>
      <Modal open={showTwoFAModal} onClose={handleTwoFAModalClose} title={modalHeading}>
        <Modal.Body>
          {showSuccessMessage ? (
            <SuccessModalBody
              heading={pageContent.SUCCESS_MODAL_HEADING}
              description={pageContent.SUCCESS_MODAL_DESCRIPTION}
              closeButtonText={pageContent.SUCCESS_MODAL_CLOSE_BUTTON}
              closeModal={handleTwoFAModalClose}
              analyticsSuffix={'ForgotPassIos'}
            />
          ) : (
            <TwoFAModalBody
              useCase={useCase}
              showModal={showTwoFAModal}
              showFooter={true}
              closeModal={handleTwoFAModalClose}
              hideModalHeading={() => setModalHeading('')}
              updateModalHeading={(heading) => setModalHeading(heading)}
            />
          )}
        </Modal.Body>
      </Modal>
      <GlobalFooter />
    </div>
  );
}

export default AccountRecovery;
