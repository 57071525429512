// import { DevTool } from "@hookform/devtools";
import { Button, Modal, Tooltip } from '@sydney-broker-ui/ios';
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from 'react-router-dom';
import { getCMSStaticContent, getCMSdynamicContent } from '../../../api/cms/cmsUtil';
import { getMarketingIdValidated, getZipcodeValidation } from '../../../api/services/utilService';
import CustomRadioGroup from '../../../components/common/custom-radio/CustomRadio';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import { AGENT_ROLES, ALPHA_NUMERIC_REGX, BRAND_NAMES, CALIFORNIA_CODE, DATE_FORMATS, DEFAULT_YEAR, DENTAL_PLAN_TYPES, LANGUAGE_CODES, MEDICAL_PLAN_TYPES, NAV_PATHS, NV_STATE, PAGE_BLOCKS, PAGE_IDS, PARENT_VALUES, PLAN_TYPES, PLAN_TYPES_CONTENT, PLAN_TYPES_NAMES, SCREEN_NAMES, SET_APPLICATION_CONSTANTS, STATE_CODES, TOBACCO_STATE_CODES } from '../../../shared/globalConstants';
import { arraysEqual, convertAgentApiDatatoFormDataFormat, filterDependentsOnParentRelationType, isNullOrUndefined, replaceKeysInArrayOfObjects } from "../../../shared/utils/globalUtils";
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { MEDICAL_PLANS_TABS } from '../../public/medical-plans/constants';
import { getFormattedApplicantDetails, getSubsidyAmount } from '../../public/medical-plans/medicalPlansUtils';
import SubsidyBlockForm from '../subsidy-block-form/subsidyBlockForm';
import AgentBasicsApplicantForm from './AgentBasicsApplicantForm';
import './AgentBasicsLanding.scss';
import { default as Content } from './agentBasicsLanding.json';

const AgentBasicsLanding: React.FC = (props) => {
    const location = useLocation();

    const [loading, setLoading] = useState(false)
    const [pageContent, setPageContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [coverageTypeOptions, setCoverageTypeOptions] = useState<any>([]);
    const [countyList, setCountyList] = useState<any>([]);
    const [zipError, setZipError] = useState<boolean>(false)
    const [marketingError, setMarketingError] = useState<boolean>(false)
    const [agentStateError, setAgentStateError] = useState<boolean>(false);
    const [showSubsidyBlock, setShowSubsidyBlock] = useState<boolean>(false);
    const [openAlertModal, setAlertModalOpen] = useState(false);
    const [editFlow, setEditFlow] = useState<boolean>(location?.state?.editFlow ? location?.state?.editFlow : false)
    const [demographicContent, setDemographicContent] = useState<any>();
    const [medicalPLansNotAvailable, setMedicalPlansNotAvailable] = useState<boolean>(false);
    const [showParentTextBlock, setShowParentTextBlock] = useState<boolean>(false);
    const [removeDeps, setRemoveDeps] = useState<boolean>(false);

    const navigate = useNavigate()

    const { selectedProduct, updateOpenATKDepModal, updateSelectedProduct, agentCoverageType, agentBrand, agentYear, agentZipCodeRes, agentState } = useGlobalStore();
    /* --------- stores ------------*/

    const stores = {
        globalStore: useGlobalStore(),
        userStore: useUserStore(),
    };

    /* --------- stores ------------*/


    /* --------- Get Content ------------*/
    useEffect(() => {
        initiate();
        if (stores.globalStore.agentZipCodeRes.zipCode === stores.globalStore.agentZipcode) {
            setCountyList(stores.globalStore.agentZipCodeRes.countyList.county);
        }
    }, []);



    useEffect(() => {
        if (pageContent && JSON.parse(pageContent?.ZIPCODE_DYNAMIC_DATA.JSON_AREA).planYear.length !== 0) {
            methods.setValue('year', JSON.parse(pageContent?.ZIPCODE_DYNAMIC_DATA.JSON_AREA).planYear[0]);
        }
    }, [stores.globalStore.agentZipcode]);


    useEffect(() => {
        if (stores.globalStore.agentFormSubmitedWithSubsidy) {
            setShowSubsidyBlock(true);
        }
    }, [stores.globalStore.agentFormSubmitedWithSubsidy])

    const initiate = async () => {
        setLoading(true);
        await getContent();
    };


    const getContent = useCallback(async (): Promise<boolean> => {
        let content: any = null;
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.AGENT_LANDING);
            let cmsResponse = response.data;
            content = cmsResponse.data.iospagecontentList.items[0].pageContent?.agentBasicsLanding;
            //content = Content?.data.iospagecontentList.items[0].pageContent?.agentBasicsLanding;

        } catch (error) {
            console.error('agentBasicsLanding.tsx getContent error: ', error);
            content = Content?.data.iospagecontentList.items[0].pageContent?.agentBasicsLanding;
            return false;
        }
        finally {
            setPageContent(content);
            setCoverageTypeOptions(stores.userStore.isWellpoint ? content?.ATK_WELLPOINT_OPTIONS?.options : content?.ATK_ANTHEM_OPTIONS?.options);
            setContentLoaded(true);
        }
        return true;
    }, []);

    const getdynamicContent = async (coverageType: string) => {
        let selectedCoverageType = PLAN_TYPES_CONTENT[coverageType]?.toLocaleLowerCase();
        let queryParam = '';
        if (agentYear && agentYear !== '') {
            queryParam = ';state=' + (agentState && agentState !== "" ? agentState : STATE_CODES.UNKNOWN) + ';brand=' + agentBrand + ';role=' + AGENT_ROLES.BROKER.toLowerCase() + ';coveragetype=' + selectedCoverageType + ';year=' + agentYear;
        } else {
            queryParam = ';state=' + (agentState && agentState !== "" ? agentState : STATE_CODES.UNKNOWN) + ';brand=' + agentBrand + ';role=' + AGENT_ROLES.BROKER.toLowerCase() + ';coveragetype=' + selectedCoverageType;
        }
        await getCMSdynamicContent(PAGE_IDS.BASIC_LANDING, PAGE_BLOCKS.ATK_BASIC_DEMOGRAPHIC, queryParam)
            .then((response) => {
                setDemographicContent(response.data?.data.iosdynamiccontentList?.items[0]?.textsummary);
            })
            .catch((error) => {
                setDemographicContent('');
            });
    };

    useEffect(() => {
        if (!isNullOrUndefined(agentCoverageType)) {
            getdynamicContent(agentCoverageType);
        }
    }, [agentCoverageType, agentYear, agentState])


    /* --------- Get Content ------------*/

    /*----------------- Agent SSO get Methods -----------*/
    const getAgentStates = (agentSSO: AgentSSOInfo, stateCode: string) => {
        if (agentSSO.agentInfo.agent.length > 0) {
            const stateNames = agentSSO.agentInfo.agent[0].states.state.map((state) => state.stateName);
            const uniqueStateNames = [...new Set(stateNames)];
            return uniqueStateNames.includes(stateCode);
        }
    }

    const showAgentSubsidy = (agentSSO: AgentSSOInfo, stateCode: string, coverageType: string, data?) => {
        if (agentSSO.agentInfo.agent.length > 0) {
            const selectedStateObj = agentSSO.agentInfo.agent[0].states.state.find((state) => state.stateName === stateCode);
            const agentType = agentSSO.agentType;
            if (agentType === AGENT_ROLES.HPA && (coverageType === PLAN_TYPES.MEDICAL || coverageType === PLAN_TYPES.MDV)) {
                setShowSubsidyBlock(true);
                return true;
            } else if (agentType !== AGENT_ROLES.HPA && stateCode === NV_STATE && (selectedStateObj && selectedStateObj.licenseNumber && selectedStateObj.licenseNumber !== '') && (selectedStateObj && selectedStateObj.FFM && selectedStateObj.FFM !== '') && (coverageType === PLAN_TYPES.MEDICAL || coverageType === PLAN_TYPES.MDV)) {
                setShowSubsidyBlock(true);
                return true;
            } else if (agentType !== AGENT_ROLES.HPA && ((selectedStateObj && selectedStateObj.licenseNumber && selectedStateObj.licenseNumber !== '') && ((selectedStateObj && selectedStateObj.FFM && selectedStateObj.FFM !== '') || (selectedStateObj && selectedStateObj.certNo && selectedStateObj.certNo !== ''))) && (coverageType === PLAN_TYPES.MEDICAL || coverageType === PLAN_TYPES.MDV)) {
                setShowSubsidyBlock(true);
                return true;
            } else {
                setShowSubsidyBlock(false);
                if (data) {
                    formSubmit(data);
                }
                return false;
            }
        } else {
            setShowSubsidyBlock(false);
            if (data) {
                formSubmit(data);
            }
            return false;
        }
    }
    /*----------------- Agent SSO get Methods -----------*/

    /*----------------- store data --------------*/
    let storeFormData = stores.globalStore.agentApplicantFormDetails.length > 0 ? convertAgentApiDatatoFormDataFormat(stores.globalStore.agentApplicantFormDetails) : convertAgentApiDatatoFormDataFormat(replaceKeysInArrayOfObjects(stores.globalStore.agentSSOInfo.applicantInfo?.applicants?.applicant, ['relationship', 'tobaccoUsage'], ['applicantType', 'isTabaccoUser']));
    /* ------------------ FORM -----------------*/

    const methods = useForm<AgentBasicsForm>({
        mode: 'onChange',
        defaultValues: {
            zipcode: '',
            county: '',
            year: '',
            coverageType: '',
            agentTin: '',
            marketingRefId: '',
            applicant: {
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                applicantType: 'PRIMARY',
                isTabaccoUser: (stores.globalStore.agentState && stores.globalStore.agentState === SET_APPLICATION_CONSTANTS.KY && stores.globalStore.agentYear !== DEFAULT_YEAR) ? SET_APPLICATION_CONSTANTS.NO : !((stores.globalStore.agentState && TOBACCO_STATE_CODES.includes(stores.globalStore.agentState))) ? SET_APPLICATION_CONSTANTS.NO : '',
                email: '',
                gender: undefined,
                SSN: '',
                applicantId: '0'
            },
            spouse: [],
            dependents: []
        },
        values: {
            zipcode: stores.globalStore.agentZipcode,
            county: stores.globalStore.agentCounty,
            year: stores.globalStore.agentYear ? stores.globalStore.agentYear : pageContent ? JSON.parse(pageContent?.ZIPCODE_DYNAMIC_DATA.JSON_AREA).planYear[0] : '',
            coverageType: stores.globalStore.agentCoverageType,
            agentTin: stores.globalStore.agentTin,
            marketingRefId: stores.globalStore.agentMarketingRefId,
            applicant: storeFormData.applicant,
            spouse: storeFormData.spouse,
            dependents: storeFormData.dependents
        },
        resetOptions: {
            keepDirtyValues: true, // user-interacted input will be retained
            keepErrors: true, // input errors will be retained with value update
        },
    });

    const { errors } = methods.formState;


    useEffect(() => {
        if (stores.globalStore.agentSSOInfo.applicantInfo?.zipCode.length === 5) {
            methods.setValue('zipcode', stores.globalStore.agentSSOInfo.applicantInfo.zipCode);
        }
    }, [stores.globalStore.agentSSOInfo.applicantInfo?.zipCode]);


    /* ------ form submit ---------*/

    const onSubmit: SubmitHandler<AgentBasicsForm> = (data) => {
        const formData = [{ ...data.applicant }, ...data.spouse, ...data.dependents];
        stores.globalStore.agentUpdateApplicantFormDetails(formData);
        stores.globalStore.agentUpdateTinandMarketingRefId(data.agentTin, data.marketingRefId);
        stores.globalStore.agentUpdateYear(data.year);
        showAgentSubsidy(stores.globalStore.agentSSOInfo, stores.globalStore.agentZipCodeRes.stateCode, data.coverageType, data);
    }

    const formSubmit = (data) => {
        const formData = [{ ...data.applicant }, ...data.spouse, ...data.dependents];
        stores.globalStore.resetProductStates();
        stores.globalStore.resetDrugStoreStates();
        stores.globalStore.resetProviderStates();
        const isSubsidyApplied = getSubsidyAmount(useGlobalStore.getState().subsidyresponse)
        updateSelectedProduct({
            ...selectedProduct,
            medicalApplicants: [],
            dentalApplicants: [],
            visionApplicants: []
        })
        if (isSubsidyApplied) {
            useGlobalStore.getState().updateSelectedProductTab(MEDICAL_PLANS_TABS.MARKET_PLANS)
        } else {
            useGlobalStore.getState().updateSelectedProductTab(MEDICAL_PLANS_TABS.OFF_MARKET_PLANS)
        }

        const dependentsLength = [...data.spouse, ...data.dependents].length;
        const isAnthemBrand = !stores.userStore.isWellpoint;
        const updatedSelectedProduct = { ...selectedProduct };

        if (dependentsLength > 0 && isAnthemBrand && stores.globalStore.agentCoverageType !== PLAN_TYPES.VISION) {
            switch (stores.globalStore.agentCoverageType) {
                case PLAN_TYPES.MDV:
                    updatedSelectedProduct.medicalApplicants = getFormattedApplicantDetails(formData);
                    updateSelectedProduct(updatedSelectedProduct)
                    break;
                case PLAN_TYPES.DENTAL:
                    updatedSelectedProduct.dentalApplicants = getFormattedApplicantDetails(formData);
                    updateSelectedProduct(updatedSelectedProduct)
                    break;
                default:
                    navigate(NAV_PATHS.PRODUCT_SELECTION);
                    break;
            }
            updateOpenATKDepModal(stores.globalStore.agentCoverageType, true, false, '');
        } else {

            switch (stores.globalStore.agentCoverageType) {
                case PLAN_TYPES.MEDICAL:
                    updatedSelectedProduct.medicalApplicants = getFormattedApplicantDetails(formData);
                    updateSelectedProduct(updatedSelectedProduct)
                    break;
                case PLAN_TYPES.MDV:
                    updatedSelectedProduct.medicalApplicants = getFormattedApplicantDetails(formData);
                    updatedSelectedProduct.dentalApplicants = getFormattedApplicantDetails(formData);
                    updatedSelectedProduct.visionApplicants = getFormattedApplicantDetails(formData);
                    updateSelectedProduct(updatedSelectedProduct)
                    break;
                case PLAN_TYPES.DENTAL:
                    updatedSelectedProduct.dentalApplicants = getFormattedApplicantDetails(formData);
                    updatedSelectedProduct.visionApplicants = getFormattedApplicantDetails(formData);
                    updateSelectedProduct(updatedSelectedProduct)
                    break;
                case PLAN_TYPES.VISION:
                    updatedSelectedProduct.visionApplicants = getFormattedApplicantDetails(formData);
                    updateSelectedProduct(updatedSelectedProduct)
                    break;
                default:
                    navigate(NAV_PATHS.PRODUCT_SELECTION);
                    break;
            }
            navigate(NAV_PATHS.PRODUCT_SELECTION);
        }
        if (showSubsidyBlock) {
            stores.globalStore.agentUpdateFormSubmitedWithSubsidy(true);
        }
    }

    const editBasicsForm = () => {
        stores.globalStore.resetHouseHoldMemberValues();
        stores.globalStore.resetEstimateSavings();
        stores.globalStore.agentUpdateFormSubmitedWithSubsidy(false);
        setShowSubsidyBlock(false);
    }
    /* ------ form submit ---------*/

    const closeTab = () => {
        navigate(NAV_PATHS.ATK_SESSION_END);
    }

    /*-------- subscribe with slectors -----------*/
    let agentPlanTypesdestroyed = false;

    const unsub = useGlobalStore.subscribe(
        (currState) => currState.agentPlanTypes,
        (agentPlanTypes, prevState) => {
            if (agentPlanTypes.length > 0 && !arraysEqual(prevState, agentPlanTypes)) {
                updateCoverageTypeOptions(stores.userStore.isWellpoint, stores.userStore.isWellpoint ? Content?.data.iospagecontentList.items[0].pageContent?.agentBasicsLanding?.ATK_WELLPOINT_OPTIONS?.options : Content?.data.iospagecontentList.items[0].pageContent?.agentBasicsLanding?.ATK_ANTHEM_OPTIONS?.options, agentPlanTypes);
                agentPlanTypesdestroyed = true;
                showMedicalNotAvailable(agentPlanTypes)
            }
        },
        { fireImmediately: true }
    );

    if (agentPlanTypesdestroyed) {
        unsub();
    }

    let agentTindestroyed = false;

    const tinSub = useGlobalStore.subscribe(
        (currState) => currState.agentTin,
        (agentTin, prevState) => {
            if (agentTin && prevState !== agentTin) {
                methods.setValue('agentTin', agentTin);
            }
        },
        { fireImmediately: true }
    );

    if (agentTindestroyed) {
        tinSub();
    }
    /*-------- subscribe with slectors -----------*/

    /* ------------------ FORM -----------------*/

    const showMedicalNotAvailable = (agentPlanTypes) => {

        if ((stores.globalStore.agentState === STATE_CODES.NY || (!agentPlanTypes.includes(PLAN_TYPES_NAMES.MEDICAL)))) {
            setMedicalPlansNotAvailable(true);
        } else {
            setMedicalPlansNotAvailable(false);
        }
    }

    const checkforParentValue = () => {
        if ((MEDICAL_PLAN_TYPES.includes(stores.globalStore.agentCoverageType) && stores.globalStore.agentState === CALIFORNIA_CODE)) {
            let dependentValues = methods.getValues().dependents.length > 0 ? methods.getValues().dependents : stores.globalStore.agentApplicantFormDetails.filter((data) => data.applicantType === "DEPENDENT");
            if (dependentValues?.length && dependentValues.some(e => PARENT_VALUES.includes(e.applicantType))) {
                setShowParentTextBlock(true);
            } else {
                setShowParentTextBlock(false);
            }
        } else {
            setShowParentTextBlock(false);
        }
    }

    /*------------  FORM MEHTODS --------------*/

    const handleZipCodeChange = (event) => {
        const value = event.target.value.replace(/[^0-9]/g, '');
        event.target.value = value;
    };


    const handelChange = (event) => {
        stores.globalStore.agentUpdateCoverageType(event.target.value);
        showAlertModal(event);
    }

    const showAlertModal = (event) => {
        if (event && event.target.value && stores.globalStore.agentState === CALIFORNIA_CODE && DENTAL_PLAN_TYPES.includes(event && event.target.value) && filterDependentsOnParentRelationType(methods.getValues().dependents).length > 0) {
            setAlertModalOpen(true);
            setRemoveDeps(true);

        } else if (stores.globalStore.agentState !== CALIFORNIA_CODE && filterDependentsOnParentRelationType(methods.getValues().dependents).length > 0) {
            setAlertModalOpen(true);
            setRemoveDeps(true);
        }
    }

    const changePlanYear = (event) => {
        stores.globalStore.agentUpdateYear(event.target.value);
        stores.globalStore.agentUpdateCoverageDates(stores.globalStore.agentZipCodeRes.coverageEffectiveDate.coverageDt, event.target.value);
        stores.globalStore.agentupdatePlanTypes(methods.getValues('county'), event.target.value, stores.globalStore.agentSSOInfo.agentType);
        methods.trigger(['zipcode']);
    }

    /*------------  FORM MEHTODS --------------*/

    /*----- Dependents alert Alert modal modal ----*/
    const closeAlertPopup = () => {
        setAlertModalOpen(false);
    }

    const handleUpdateDependents = (value) => {
        setRemoveDeps(value);
    }
    /*----- Dependents alert Alert modal modal ----*/

    /*---------------- FORM VALIDATIONS -------------------*/

    const martketIDWatch = methods.watch('marketingRefId');

    useEffect(() => {
        if ((ALPHA_NUMERIC_REGX.test(martketIDWatch) && martketIDWatch.length === 4)) {
            validateMarketIDApi(martketIDWatch);
        } else {
            setMarketingError(false);
        }
    }, [martketIDWatch])

    const validateMarketIDApi = async (value) => {
        const response = await getMarketingIdValidated(value);
        if (response?.data?.valid !== 'Y') {
            setMarketingError(true);
        } else {
            setMarketingError(false);
        }
    }

    const updateCoverageTypeOptions = (isWellpoint, options, newPlanTypes?) => {
        if (isWellpoint) {
            setCoverageTypeOptions(options);
            stores.globalStore.agentUpdateCoverageType(options[0]?.value);
            methods.setValue('coverageType', options[0]?.value);
        } else {
            const updateOptions = options?.filter((type) => {
                return newPlanTypes.indexOf(type.planType) !== -1;
            });
            setCoverageTypeOptions(updateOptions);
            stores.globalStore.agentUpdateCoverageType(updateOptions[0]?.value);
            methods.setValue('coverageType', updateOptions[0]?.value);
        }
    };

    const validateMarketingId = async (value) => {
        if (!(ALPHA_NUMERIC_REGX.test(value) && value.length === 4)) {
            return pageContent?.FORM_CONTENT.ERROR_MESSAGES.MARKETING_ID;
        }
    }

    const isNotSelect = (value, countyList) => {
        if (value === 'select') {
            return pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD;
        } else {
            let data = countyList.find((county) => {
                return county.countyName === value;
            });
            if (data?.inService.value === 'Y' && getAgentStates(stores.globalStore.agentSSOInfo, data.stateCode) && (stores.userStore.isWellpoint ? data.brand === BRAND_NAMES.WELLPOINT_BRAND : data.brand != BRAND_NAMES.WELLPOINT_BRAND)) {
                setZipError(false);
                getAgentTin(data.stateCode);
                stores.globalStore.agentUpdateCounty(data.countyName, data.countyCode, data.brand);
                stores.globalStore.agentupdatePlanTypes(data.countyName, methods.getValues('year'), stores.globalStore.agentSSOInfo.agentType);
                return true;
            } else {
                setZipError(true);
                methods.setValue('coverageType', '');
                getAgentTin('');
                stores.globalStore.agentUpdateCoverageType('');
                showAgentSubsidy(stores.globalStore.agentSSOInfo, '', '');
                stores.globalStore.resetAgentPlanTypes();
                return false
            }
        }
    };


    const zipcodeWatch = methods.watch('zipcode');

    useEffect(() => {
        if ((editFlow && stores.globalStore?.householdMemberValues?.houseHoldIncome)) {
            setShowSubsidyBlock(true)
        }
        if (editFlow || (zipcodeWatch && zipcodeWatch.length === 5 && stores.globalStore.agentZipcode !== zipcodeWatch)) {
            validateZipCodeWithAPICall(zipcodeWatch);
        }
    }, [zipcodeWatch]);

    /*---------------- FORM VALIDATIONS -------------------*/

    const handlesetShowParentTextBlock = (value) => {
        setShowParentTextBlock(value);
    }

    /*-------------- get AGebt TIn ----------------*/
    const getAgentTin = (state) => {
        if (state) {
            const agentInfo = stores.globalStore.agentSSOInfo.agentInfo.agent[0];
            const filteredAgentState = stores.globalStore.agentSSOInfo.agentInfo.agent[0].states.state.find((stateObj) => {
                return state === stateObj.stateName
            });
            stores.globalStore.agentUpdateTin(
                (agentInfo.agentFName ? (agentInfo.agentFName + "-") : '') +
                (agentInfo.writingTIN ? (agentInfo.writingTIN + "-") : '') +
                (filteredAgentState?.agencyInfo.agency.paidTIN ? (filteredAgentState?.agencyInfo.agency.paidTIN + "-") : '') +
                (filteredAgentState?.agencyInfo.agency.parentTIN ? (filteredAgentState?.agencyInfo.agency.parentTIN + "-") : '') +
                filteredAgentState?.agencyInfo.agency.agentCode);
            methods.setValue('agentTin',
                (agentInfo.agentFName ? (agentInfo.agentFName + "-") : '') +
                (agentInfo.writingTIN ? (agentInfo.writingTIN + "-") : '') +
                (filteredAgentState?.agencyInfo.agency.paidTIN ? (filteredAgentState?.agencyInfo.agency.paidTIN + "-") : '') +
                (filteredAgentState?.agencyInfo.agency.parentTIN ? (filteredAgentState?.agencyInfo.agency.parentTIN + "-") : '') +
                filteredAgentState?.agencyInfo.agency.agentCode)
        } else {
            stores.globalStore.agentUpdateTin('');
            methods.setValue('agentTin', '');
        }
    }


    /* -------------- API CALLS --------------*/

    const validateZipCodeWithAPICall = async (zipcode) => {
        setAgentStateError(false);
        try {
            setLoading(true);
            const response = await getZipcodeValidation({ zipCode: zipcode, marketSegment: 'UNDER_65' });
            if (response.data.zipCodeResponse.responseMessage.message === 'SUCCESSFULLY FETCHED') {
                stores.globalStore.agentupdateZipCodeResponse(response.data.zipCodeResponse.zipCode, response.data.zipCodeResponse.zipCode.countyList.county, methods.getValues('year'));
                setCountyList(response.data.zipCodeResponse.zipCode.countyList.county);
                setZipcodeFieldsandErrors(response);
            }
            else if (response.data.zipCodeResponse.responseMessage.message === 'INVALID ZIPCODE') {
                stores.globalStore.resetAgentUpdateZipCode();
                setCountyList([]);
                methods.setError('zipcode', { type: 'validate', message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_ZIP });
                getAgentTin('');
            } else {
                methods.setError('zipcode', { type: 'validate', message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_ZIP });
                getAgentTin('');
            }
        } catch (error) {
            console.error('Error fetching county data:', error);
        } finally {
            setLoading(false);
        }
        return true
    };


    const setZipcodeFieldsandErrors = (response) => {
        if (response.data.zipCodeResponse.zipCode.countyList.county.length === 1) {
            if (getAgentStates(stores.globalStore.agentSSOInfo, response.data.zipCodeResponse.zipCode.stateCode) && response.data.zipCodeResponse.zipCode.countyList.county[0].inService.value === 'Y' && (stores.userStore.isWellpoint ? response.data.zipCodeResponse.zipCode.countyList.county[0].brand === BRAND_NAMES.WELLPOINT_BRAND : response.data.zipCodeResponse.zipCode.countyList.county[0].brand != BRAND_NAMES.WELLPOINT_BRAND)) {
                setAgentStateError(false);
                methods.setValue('county', response.data.zipCodeResponse.zipCode.countyList.county[0].countyName);
                stores.globalStore.agentupdatePlanTypes(response.data.zipCodeResponse.zipCode.countyList.county[0].countyName, methods.getValues('year'), stores.globalStore.agentSSOInfo.agentType);
                getAgentTin(response.data.zipCodeResponse.zipCode.stateCode);
                setZipError(false);
                if (stores.userStore.isWellpoint) {
                    stores.globalStore.agentUpdateCoverageType(PLAN_TYPES.MEDICAL);
                }
            } else {
                if (response.data.zipCodeResponse.zipCode.countyList.county[0].inService.value === 'N') {
                    setZipError(true);
                } else if (response.data.zipCodeResponse.zipCode.countyList.county[0].inService.value === 'Y' && !getAgentStates(stores.globalStore.agentSSOInfo, response.data.zipCodeResponse.zipCode.stateCode)) {
                    setAgentStateError(true);
                } else {
                    methods.setError('zipcode', { type: 'validate', message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_ZIP });
                }

                if (!editFlow) {
                    methods.setValue('county', response.data.zipCodeResponse.zipCode.countyList.county[0].countyName);
                    getAgentTin('');
                    stores.globalStore.resetAgentPlanTypes();
                }


            }
        }
    }
    /* -------------- API CALLS --------------*/


    if (!contentLoaded) {
        return <FullPageLoader />
    } else {
        return (
            <>
                {demographicContent && <div className='basics-banner-container'>
                    <div className="member-banner">
                        <div className="fwc-row">
                            <div className="fwc-col-12">
                                <div className='banner-content-flex'>
                                    <span className="sae-icon sae-icon-high-importance"></span>
                                    <div dangerouslySetInnerHTML={{ __html: demographicContent || '' }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                <div className={'agent-landing-container'}>
                    <div className='fwc-row'>
                        <div className='fwc-col-12'>
                            <div className='title-wrapper'>
                                <h2 className='title'>{pageContent.CONTENT.TITLE}</h2>
                                <p>{pageContent.CONTENT.TITLE_DESC}</p>
                            </div>
                            <h4 className='sub-title'>{pageContent.CONTENT.SUB_TITLE}</h4>
                        </div>
                    </div>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                            <div className='fwc-row row-relative'>
                                {showSubsidyBlock && <div className='overlayCustom'></div>}
                                <div className='fwc-col-12'>
                                    <div className='fwc-row'>
                                        <div className='fwc-col-4 md-6 sm-12'>
                                            <div className='form-group more'>
                                                <div className="fwc-input">
                                                    <label className="fwc-label" htmlFor="zipcode">
                                                        {pageContent?.FORM_CONTENT.LABELS.ZIPCODE}
                                                    </label>
                                                    <div className='zipcode-wrapper'>
                                                        <input
                                                            id="zipcode"
                                                            maxLength={5}
                                                            placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.ZIP_CODE}
                                                            type="text"
                                                            {...methods.register('zipcode', {
                                                                required: {
                                                                    value: true,
                                                                    message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD
                                                                },
                                                                minLength: {
                                                                    value: 5,
                                                                    message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_ZIP
                                                                },
                                                                onChange: (e) => {
                                                                    handleZipCodeChange(e);
                                                                }
                                                            })}
                                                        />
                                                        <span className='sae-icon sae-icon-marker'></span>
                                                    </div>
                                                    {errors.zipcode?.message && errors.zipcode.type !== "validate" && (
                                                        <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="zip_error">
                                                            {errors.zipcode?.message}
                                                        </span>
                                                    )}
                                                    {/* {errors && errors.zipcode && errors.zipcode && errors.zipcode.type === "required" && (
                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                </span>
                                            )} */}
                                                    {errors && errors.zipcode && errors.zipcode && errors.zipcode.type === "validate" && (
                                                        <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                            <span dangerouslySetInnerHTML={{ __html: errors.zipcode.message || '' }}></span>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {countyList && countyList.length === 1 && <div className='fwc-col-4'>
                                            <div className="fwc-input ">
                                                <label className="fwc-label" htmlFor="county-select">
                                                    {pageContent?.FORM_CONTENT.LABELS.COUNTY}
                                                </label>
                                                <input disabled type="text" {...methods.register('county', { value: countyList[0].countyName })} />
                                            </div>
                                        </div>}
                                        {countyList && countyList.length > 1 && <div className='fwc-col-4'>
                                            <div className="fwc-select-list ">
                                                <label className="fwc-label" htmlFor="county-select">
                                                    {pageContent?.FORM_CONTENT.LABELS.COUNTY}
                                                </label>
                                                <Controller
                                                    name="county"
                                                    control={methods.control}
                                                    defaultValue={'select'}
                                                    rules={{ validate: (value) => isNotSelect(value, countyList) }}
                                                    render={({ field }) => (
                                                        <select id="county-select" {...field} onChange={(e) => {
                                                            field.onChange(e);
                                                        }}>
                                                            <option value="select" selected>{pageContent?.FORM_CONTENT.LABELS.SELECT}</option>
                                                            {countyList.map((county, index) => {
                                                                return (
                                                                    <option key={index} value={county['countyName']}>
                                                                        {county['countyName']}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    )}
                                                />
                                                {errors.county?.message && (
                                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="zip_error">
                                                        {errors.county?.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>}
                                        <div className="fwc-col-3 md-6 sm-12">
                                            <div className={'fwc-flex'}>
                                                <label className="fwc-label right-xxs" htmlFor="year-select">
                                                    {pageContent?.ZIPCODE_DYNAMIC_DATA.PLAN_YEAR}{' '}
                                                </label>
                                                <Tooltip icon message={pageContent?.ZIPCODE_DYNAMIC_DATA.PLAN_YEAR_TOOLTIP}></Tooltip>
                                            </div>

                                            <div className="fwc-select-list">
                                                <Controller
                                                    name="year"
                                                    control={methods.control}
                                                    defaultValue={JSON.parse(pageContent?.ZIPCODE_DYNAMIC_DATA.JSON_AREA).planYear[0]}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <select
                                                            data-analytics={'planYearLpIos'}
                                                            {...field}
                                                            onChange={(e) => {
                                                                changePlanYear(e)
                                                            }}
                                                        >
                                                            {JSON.parse(pageContent?.ZIPCODE_DYNAMIC_DATA.JSON_AREA).planYear.map((yearvalue, index) => {
                                                                return (
                                                                    <option key={index} value={yearvalue} data-analytics={yearvalue.toString() === new Date().getFullYear().toString() ? 'currentYearLpIos' : 'futureYearLpIos'}>
                                                                        {yearvalue}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    )}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    {zipError && <div className="fwc-row">
                                        <div className="fwc-col-12 form-group">
                                            <div className="error-callout">
                                                <div className="fwc-flex a-start">
                                                    <div className="fwc-inline-icon fwc-icon-info error-icon"></div>
                                                    <div>
                                                        <div id="zip_error" dangerouslySetInnerHTML={{ __html: stores.userStore.isWellpoint ? pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_COUNTY_LINK_FOR_WELLPOINT : pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_COUNTY_LINK_FOR_ANTHEM }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {agentStateError && <div className="fwc-row">
                                        <div className="fwc-col-12 form-group">
                                            <div className="error-callout">
                                                <div className="fwc-flex a-start">
                                                    <div className="fwc-inline-icon fwc-icon-info error-icon"></div>
                                                    <div>
                                                        <div id="agent_state_error" dangerouslySetInnerHTML={{ __html: pageContent?.FORM_CONTENT.ERROR_MESSAGES.AGENT_STATES_NOT_ALLOWED }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {medicalPLansNotAvailable && <div className='fwc-row'>
                                        <div className='fwc-col-12 form-group'>
                                            <div className='error-callout'>
                                                <div className="fwc-flex a-start">
                                                    <div className="fwc-inline-icon fwc-icon-info error-icon"></div>
                                                    <div>
                                                        <div id="agent_state_info" dangerouslySetInnerHTML={{ __html: pageContent?.FORM_CONTENT.ERROR_MESSAGES.AGENT_STATE_INFO }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    <div className='fwc-row'>
                                        <div className='fwc-col-12'>
                                            <div className='form-group more'>
                                                {coverageTypeOptions.length > 0 && (
                                                    <>
                                                        <Controller
                                                            name="coverageType"
                                                            control={methods.control} // Set your desired default value
                                                            rules={{ required: { value: true, message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD } }}
                                                            render={({ field }) => (
                                                                <CustomRadioGroup
                                                                    field={field}
                                                                    label={stores.userStore.isWellpoint ? pageContent?.ATK_WELLPOINT_OPTIONS.label : pageContent?.ATK_ANTHEM_OPTIONS.label}
                                                                    name={stores.userStore.isWellpoint ? pageContent?.ATK_WELLPOINT_OPTIONS.name : pageContent?.ATK_ANTHEM_OPTIONS.name}
                                                                    options={coverageTypeOptions}
                                                                    onCustomRadioSelect={handelChange} // Set your desired default value
                                                                    role={''}
                                                                />
                                                            )}
                                                        />
                                                        <div className="fwc-row">
                                                            <div className="fwc-input fwc-col-12">
                                                                {errors.coverageType?.message && (
                                                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="zip_error">
                                                                        {errors.coverageType?.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='fwc-row'>
                                        <div className='fwc-col-6'>
                                            <div className='form-group more'>
                                                <div className="fwc-input">
                                                    <label className="fwc-label" htmlFor="agentTin">
                                                        {pageContent?.FORM_CONTENT.LABELS.AGENT_TIN}
                                                    </label>
                                                    <input
                                                        id="agentTin"
                                                        readOnly
                                                        placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.AGENT_TIN}
                                                        type="text"
                                                        {...methods.register('agentTin', {
                                                            required: {
                                                                value: true,
                                                                message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='fwc-col-6'>
                                            <div className='form-group more'>
                                                <div className="fwc-input">
                                                    <div className='fwc-flex'>
                                                        <label className="fwc-label" htmlFor="marketingRefId">
                                                            {pageContent?.FORM_CONTENT.LABELS.MARKETING_REFERENCE}
                                                        </label>
                                                        <Tooltip icon message={pageContent?.FORM_CONTENT.TOOL_TIPS.MARKETING_ID} />
                                                    </div>
                                                    <input
                                                        id="marketingRefId"
                                                        placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.MARKETING_REFERENCE}
                                                        type="text"
                                                        {...methods.register('marketingRefId', {
                                                            validate: (value) => value === '' || validateMarketingId(value)
                                                        })}
                                                    />
                                                    {errors && errors.marketingRefId && errors.marketingRefId?.type === "validate" && (
                                                        <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                            {errors.marketingRefId?.message}
                                                        </span>
                                                    )}
                                                    {marketingError && (
                                                        <div className="banner">
                                                            <div className="fwc-row">
                                                                <div className="fwc-col-12">
                                                                    <p><span className="sae-icon sae-icon-high-importance"></span>
                                                                        {pageContent?.FORM_CONTENT.ERROR_MESSAGES.MARKETING_ID_NOT_Valid}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {stores.globalStore.agentCoverageType && <div className='fwc-row'>
                                        <div className='fwc-col-12'>
                                            <div className="update-zipcode-block">
                                                <div className="autopopulatefiedls">
                                                    <p id="coverage-date"><span className="sae-icon sae-icon-checked"></span>{pageContent?.FORM_CONTENT.LABELS.COVERAGE_DATE}<span>{dayjs(stores.globalStore.agentCoverageDate).format(DATE_FORMATS.MMDDYYYY)}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <AgentBasicsApplicantForm pageContent={pageContent} showSubsidyBlock={showSubsidyBlock} agentStateError={agentStateError} zipError={zipError} errors={errors} updateShowParentTextBlock={handlesetShowParentTextBlock} showParentTextBlock={showParentTextBlock} removeDeps={removeDeps} onUpdateDependents={handleUpdateDependents}></AgentBasicsApplicantForm>
                            {stores.globalStore.agentState && !zipError && !agentStateError && !showSubsidyBlock && <div className="fwc-row">
                                <div className="fwc-col-12 submit-button">
                                    <button className="fwc-btn fwc-btn-secondary cancel-button" type="button" id="cancel" onClick={() => closeTab()}>
                                        {pageContent?.FORM_CONTENT.LABELS.CANCEL}
                                    </button>
                                    <Button data-analytics="continueBtnBasicsIos" id="btContinue" type="submit">
                                        {pageContent?.FORM_CONTENT.LABELS.CONTINUE}
                                    </Button>
                                </div>
                            </div>}
                        </form >
                    </FormProvider>
                    {showSubsidyBlock &&
                        <div className='subsidyBlock'>
                            <SubsidyBlockForm></SubsidyBlockForm>
                            <div className='fwc-row'>
                                <div className='fwc-col-12 submit-button'>
                                    <div>
                                        <button className="fwc-btn fwc-btn-secondary cancel-button" type="button" id="cancel" onClick={() => closeTab()}>
                                            {pageContent?.FORM_CONTENT.LABELS.CANCEL}
                                        </button>
                                        <button data-analytics="continueBtnBasicsIos" className="fwc-btn fwc-btn-secondary" id="btContinue" onClick={() => editBasicsForm()}>
                                            Edit Basics
                                        </button>
                                    </div>
                                    <Button data-analytics="continueBtnBasicsIos" id="btContinue" onClick={() => formSubmit(methods.getValues())}>
                                        {pageContent?.FORM_CONTENT.LABELS.CONTINUE}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='fwc-row disclaimer'>
                        <p dangerouslySetInnerHTML={{ __html: pageContent?.FOOTER_CONTENT.DESCRIPTION }}></p>
                    </div>
                    {/* <DevTool control={control} /> */}
                </div >
                <div className='alertModals'>
                    {openAlertModal && <Modal
                        id="alertModal"
                        onClose={closeAlertPopup}
                        className={'alertModal'}
                        title={'You May Need To Re-Add Dependents'}
                        open={openAlertModal}
                    >
                        <Modal.Body>
                            <p>This change may have required us to remove dependents you have added. If you have previously added dependents to your quote, please re-add them before continuing.</p>
                        </Modal.Body>
                    </Modal>}
                </div>
            </>
        );
    }
};

export default AgentBasicsLanding;
