import { Paragraph, Popover } from '@sydney-broker-ui/ios';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import CheckBoxListFilter from '../../../components/common/check-box-list-filter/CheckBoxListFilter';
import { getResetFilters } from '../../../components/common/check-box-list-filter/checkBoxListFilterUtils';
import { getResetSwitchFilters } from '../../../components/common/switch-list-filter/switchFIlterUtils';
import { useGlobalStore } from '../../../store/globalStore';
import './Filters.scss';
import MoreFilters from './MoreFilters';
import { FILTER_TYPES } from './constants';
import {
  getActiveSortValue,
  getInitialBenefitTierFilters,
  getInitialSwitchFilters,
  getMedicalDeductibleFilters,
  getMontlyCostFilterOptions,
  getPlansForTab,
  getSortOptions,
  getUpdatedSortOptions,
  isAnyFilterApplied
} from './medicalPlansUtils';


const Filters = forwardRef<FilterRef, FilterProps>((FilterProps, ref) => {
  const { labels, plans, showFavourites, applyFilters, onSortApply } = FilterProps
  const [activeFilter, setActiveFilter] = useState('');
  const [anchor, setAnchor] = useState<React.SetStateAction<null> | (EventTarget & HTMLSpanElement)>(null);

  const {
    monthlyCostFilters,
    medicalDeductibleFilters,
    switchFilters,
    benefitsTiers,
    selectedTab,
    sortOptions,
    savedProviders,
    matchProvidersToPlans,
    matchMedicationsToPlans,
    drugsList,
    coveredMedicationsList,
    updateMonthlyCostFilters,
    updateMedicleDeductibleFilters,
    updateSortOptions,
    updateSwitchFilters,
    updateBenefitsTiers
  } = useGlobalStore((state) => state);

  const onSortValueChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const updatedSortValue = getUpdatedSortOptions(e.target.value, sortOptions);
    updateSortOptions(updatedSortValue);
    onSortApply(getActiveSortValue(updatedSortValue));
  };

  const initialiseFilters = useCallback(() => {
    const plansForTab = getPlansForTab(selectedTab, plans);
    const initialMontlyCostFilters = getMontlyCostFilterOptions(monthlyCostFilters, plansForTab, labels);
    const initialMedicalDeductibleFilters = getMedicalDeductibleFilters(medicalDeductibleFilters, plansForTab, labels);
    const initialSortOptions = getSortOptions(sortOptions, labels);
    const initialSwitchFilters = getInitialSwitchFilters(switchFilters, plansForTab, labels, savedProviders, matchProvidersToPlans, matchMedicationsToPlans, drugsList, coveredMedicationsList);
    const initialBenefitsTierFilters = getInitialBenefitTierFilters(benefitsTiers, plansForTab, labels)

    updateMonthlyCostFilters(initialMontlyCostFilters);
    updateMedicleDeductibleFilters(initialMedicalDeductibleFilters);
    updateSortOptions(initialSortOptions);
    updateSwitchFilters(initialSwitchFilters);
    updateBenefitsTiers(initialBenefitsTierFilters)

  }, [benefitsTiers, coveredMedicationsList, drugsList, labels, matchMedicationsToPlans, matchProvidersToPlans, medicalDeductibleFilters, monthlyCostFilters, plans, savedProviders, selectedTab, sortOptions, switchFilters, updateBenefitsTiers, updateMedicleDeductibleFilters, updateMonthlyCostFilters, updateSortOptions, updateSwitchFilters]);

  useEffect(() => {
    if (!isAnyFilterApplied()) {
      initialiseFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, plans]);

  const handleFilterClick = (type: string, currentTarget: React.SetStateAction<null> | (EventTarget & HTMLSpanElement)) => {
    setAnchor(currentTarget);
    setActiveFilter(type);
  };

  useEffect(() => {
    if (matchProvidersToPlans || matchMedicationsToPlans) {
      const plansForTab = getPlansForTab(selectedTab, plans);
      const initialSwitchFilters = getInitialSwitchFilters(switchFilters, plansForTab, labels, savedProviders, matchProvidersToPlans, matchMedicationsToPlans, drugsList, coveredMedicationsList, false);

      updateSwitchFilters(initialSwitchFilters);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchProvidersToPlans, matchMedicationsToPlans])

  useImperativeHandle(ref, () => ({
    resetAllFilters() {
      resetAllFilters()
    }
  }))

  const resetAllFilters = () => {
    const filters: MedicalPlansFilters = {
      selectedTab,
      monthlyCostFilters: getResetFilters(monthlyCostFilters),
      medicalDeductibleFilters: getResetFilters(medicalDeductibleFilters),
      benefitsTiers: getResetFilters(benefitsTiers),
      switchFilters: getResetSwitchFilters(switchFilters)
    };

    updateMonthlyCostFilters(filters.monthlyCostFilters);
    updateMedicleDeductibleFilters(filters.medicalDeductibleFilters);
    updateSwitchFilters(filters.switchFilters);
    updateBenefitsTiers(filters.benefitsTiers)
    applyFilters(filters);
  };

  const getFilterBody = () => {
    switch (activeFilter) {
      case FILTER_TYPES.MONTHLY_COST:
        return (
          <CheckBoxListFilter
            filterOptions={monthlyCostFilters}
            showFooterButtons={false}
            onApplyClick={(appliedFilters: CheckBoxFilterOption[]) => {
              const filters: MedicalPlansFilters = { selectedTab, monthlyCostFilters: appliedFilters, medicalDeductibleFilters: medicalDeductibleFilters, switchFilters, benefitsTiers };
              updateMonthlyCostFilters(appliedFilters);
              applyFilters(filters);
            }}
          />
        );
      case FILTER_TYPES.MEDICAL_DEDUCTIBLE:
        return (
          <CheckBoxListFilter
            filterOptions={medicalDeductibleFilters}
            showFooterButtons={false}
            onApplyClick={(appliedFilters: CheckBoxFilterOption[]) => {
              const filters: MedicalPlansFilters = { selectedTab, monthlyCostFilters, medicalDeductibleFilters: appliedFilters, switchFilters, benefitsTiers };
              updateMedicleDeductibleFilters(appliedFilters);
              applyFilters(filters);
            }}
          />
        );
      case FILTER_TYPES.MORE:
        return (
          <MoreFilters
            labels={{
              BENEFIT_TIER: labels.BENEFIT_TIER,
            }}
            switchFilters={switchFilters}
            checkBoxFilters={benefitsTiers}
            onSwitchFilterApply={(appliedFilters: SwitchFilter[]) => {
              const filters: MedicalPlansFilters = { selectedTab, monthlyCostFilters, medicalDeductibleFilters, switchFilters: appliedFilters, benefitsTiers };
              updateSwitchFilters(appliedFilters);
              applyFilters(filters);
            }}
            onCheckBoxFilterApply={(appliedFilters: CheckBoxFilterOption[]) => {
              const filters: MedicalPlansFilters = { selectedTab, monthlyCostFilters, medicalDeductibleFilters, switchFilters, benefitsTiers: appliedFilters };
              updateBenefitsTiers(appliedFilters);
              applyFilters(filters);
            }}
          />
        );
      default:
        return <div />;
    }
  };

  const isFilterApplied = (FilterType: string) => {
    if (FilterType === 'MEDICAL_DEDUCTIBLE' && useGlobalStore.getState().medicalDeductibleFilters.some((filter) => filter.isChecked)) {
      return 'active';
    } else if (FilterType === 'MONTHLY_COST' && useGlobalStore.getState().monthlyCostFilters.some((filter) => filter.isChecked)) {
      return 'active';
    } else if (FilterType === 'MORE' && (useGlobalStore.getState().benefitsTiers.some((filter) => filter.isChecked) || useGlobalStore.getState().switchFilters.some((filter) => filter.enabled))) {
      return 'active';
    } else {
      return '';
    }
  }

  return (
    <div className="filters-wrapper">
      <div className="filters">
        {activeFilter && anchor && (
          <div className="filter-popover-wrapper">
            <Popover id="plan-filter-popover" placement={'bottom'} anchorEl={anchor} onClickOutside={() => setActiveFilter('')}>
              <Paragraph>
                <div className="filter-body-container">{getFilterBody()}</div>
              </Paragraph>
            </Popover>
          </div>
        )}
        <div className="monthly-cost">
          <button
            data-analytics="sortMonthlyCostsMedicalPlansIos"
            className={"filter-button " + isFilterApplied(FILTER_TYPES.MONTHLY_COST)}
            onClick={(e) => {
              handleFilterClick(FILTER_TYPES.MONTHLY_COST, e.currentTarget);
            }}
          >
            {labels.MONTHLY_COST}
          </button>
        </div>
        <div className={"medical-deductible "}>
          <button
            data-analytics="sortDeductibleMedicalPlansIos"
            className={"filter-button " + isFilterApplied(FILTER_TYPES.MEDICAL_DEDUCTIBLE)}
            onClick={(e) => {
              handleFilterClick(FILTER_TYPES.MEDICAL_DEDUCTIBLE, e.currentTarget);
            }}
          >
            {labels.MEDICAL_DEDUCTIBLE}
          </button>
        </div>
        <div className="more-filters">
          <button
            className={"filter-button " + isFilterApplied(FILTER_TYPES.MORE)}
            onClick={(e) => {
              handleFilterClick(FILTER_TYPES.MORE, e.currentTarget);
            }}
          >
            {labels.MORE_FILTERS}
          </button>
        </div>
        <div className="more-filters">
          <a
            data-analytics="resetFiltersMedicalPlansIos"
            href={'javascript:void(0);'}
            onClick={() => {
              resetAllFilters();
            }}
            className="reset-filters"
          >
            {labels.RESET_FILTERS}
          </a>
        </div>
      </div>
      <div>
        <div className="sort-by-wrapper">
          <span className="sort-by-label"> {labels.SORT_BY}</span>
          <div className="fwc-select-list sort-drop-down">
            <select name="sort-by" id="sort-by" onChange={(e) => onSortValueChange(e)} onBlur={() => { }} value={getActiveSortValue(sortOptions)?.value}>
              {sortOptions?.map((option) => {
                return (
                  <option data-analytics={option.analytics} key={option.key} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Filters;
