const MAX_WORDS_ON_SHOW_LESS_PRIMARY_CARE_VISIT = 20;
const MAX_WORDS_ON_SHOW_LESS_PREVENTIVE_CARE = 20;
const MAX_WORDS_ON_SHOW_LESS_DENTAL_BENEFITS = 20;
const MAX_WORDS_ON_SHOW_LESS_ROUTINE_EYE_EXAM = 20;
const MAX_WORDS_ON_SHOW_LESS_EYEGLASS_FRAMES = 15;
const MAX_WORDS_ON_SHOW_LESS_DEDUCTIBLE = 20;
const MAX_WORDS_ON_SPECIALIST_VISIT = 5;

export {
  MAX_WORDS_ON_SHOW_LESS_DEDUCTIBLE,
  MAX_WORDS_ON_SHOW_LESS_DENTAL_BENEFITS,
  MAX_WORDS_ON_SHOW_LESS_EYEGLASS_FRAMES,
  MAX_WORDS_ON_SHOW_LESS_PREVENTIVE_CARE,
  MAX_WORDS_ON_SHOW_LESS_PRIMARY_CARE_VISIT,
  MAX_WORDS_ON_SHOW_LESS_ROUTINE_EYE_EXAM,
  MAX_WORDS_ON_SPECIALIST_VISIT
};
