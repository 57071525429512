import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';

import { initialMemberInfo } from '../../../shared/memberInfoDefault';

const resetUserData = () => {
  useUserStore.getState().resetUserStore();
  useGlobalStore.getState().resetQuoteStore();
  useGlobalStore.getState().resetDentalPlanStates();
  useGlobalStore.getState().resetMedicalPlanStates();
  useGlobalStore.getState().resetVisionPlanStates();
  useGlobalStore.getState().resetDentistsStates();
  useGlobalStore.getState().resetEyeDoctorStates();
  useGlobalStore.getState().resetProviderStates();
  useGlobalStore.getState().resetProgressSteps();
  useGlobalStore.getState().resetEstimateSavings();
  useGlobalStore.getState().resetDrugStoreStates();
  useGlobalStore.getState().resetHmcFilter();
  useGlobalStore.getState().resetRegistrationStore();
  useGlobalStore.getState().resetAgentInfo();
  useGlobalStore.getState().resetApplications();
  useGlobalStore.getState().updateMemberInfo(initialMemberInfo);
  useGlobalStore.getState().resetPlanSummaryStates();
  useGlobalStore.getState().resetDeepLinkInfoStore();
  useGlobalStore.getState().resetAgentSSOInfo();
  useGlobalStore.getState().resetProductStates();
  useGlobalStore.getState().resetAgentBasicLandingStates();
  useGlobalStore.getState().resetCurrentCoverageVisionPlan();
  useGlobalStore.getState().resetCurrentCoverageDentalPlan();
  useGlobalStore.getState().resetCurrentCoverageMedicalPlan();
};

const resetSessionData = () => {
  useGlobalStore.getState().resetQuoteStore();
  useGlobalStore.getState().resetDentalPlanStates();
  useGlobalStore.getState().resetMedicalPlanStates();
  useGlobalStore.getState().resetVisionPlanStates();
  useGlobalStore.getState().resetDentistsStates();
  useGlobalStore.getState().resetEyeDoctorStates();
  useGlobalStore.getState().resetProviderStates();
  useGlobalStore.getState().resetProgressSteps();
  useGlobalStore.getState().resetEstimateSavings();
  useGlobalStore.getState().resetDrugStoreStates();
  useGlobalStore.getState().resetHmcFilter();
  useGlobalStore.getState().resetAgentInfo();
  useGlobalStore.getState().resetApplications();
  useGlobalStore.getState().resetPlanSummaryStates();
  useGlobalStore.getState().resetDeepLinkInfoStore();
};

export { resetSessionData, resetUserData };
