import { Alert, Button, ModalSlide, PreLoader } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from 'react';
import { getCMSStaticContent } from '../../../../api/cms/cmsUtil';
import { getContactUs } from '../../../../api/services/utilService';
import celebration from '../../../../images/celebration.svg';
import { REGEXS } from '../../../../pages/public/account-recovery/constants';
import { ALPHA_REGX_WITH_SPACE, LANGUAGE_CODES, PHONE_FORMATTED, SCREEN_NAMES } from '../../../../shared/globalConstants';
import { useGlobalStore } from '../../../../store/globalStore';
import './ContactUs.scss';
import * as Strings from './contactUsMessages.json';
import { formatPhoneNumber, getPayloadForContactUs } from './contactUsUtils';


interface ContactUsProps {
  onChange: (e: boolean) => void;
  onSubmit: (data: ContactUsData) => void;
  showSlider: boolean;
  contactPhone: string;
}
interface ContactUsData {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  preferredDay: string;
  preferredTime: string;
}

const patterns = ['/individuals'];

function ContactUs({ showSlider, onSubmit, onChange, contactPhone }: ContactUsProps) {
  const [formErrors, setFormErrors] = useState({ firstName: '', lastName: '', phone: '', email: '', preferredDay: '', preferredTime: '' });
  const [phone, setPhone] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showConfirmBlock, setShowConfirmBlock] = useState(false);
  const { zipCodeRes, validState, county, brand } = useGlobalStore((store) => store);

  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [showError, setError] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    zip: zipCodeRes.zipCode || '',
    preferredDay: '',
    preferredTime: ''
  });

  let ZipcodeData;
  const countyData = zipCodeRes?.countyList.county;
  if (county) {
    const countyValue = countyData.find((val) => val.countyName === county);
    ZipcodeData = countyValue ? countyValue : countyData[0];
  } else {
    ZipcodeData = countyData[0];
  }

  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.CONTACT_US);
      let cmsResponse = response.data;
      content = cmsResponse.data.iospagecontentList.items[0].pageContent;
    } catch (error) {
      content = Strings;
      return false;
    } finally {
      setPageContent(content);
      setContentLoaded(true);
      if (content) {
        setFormData({
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          zip: zipCodeRes.zipCode,
          preferredDay: content?.LABELS?.PREFERRED_DAY_PLACEHOLDER,
          preferredTime: content?.LABELS?.PREFERRED_TIME_PLACEHOLDER
        });
      }

    }
    return true;
  }, []);

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    initiate();
  }, [validState]);

  const contactUsSubmit = async () => {
    let data;
    let err;
    let btn = document.getElementById('modal-slide-submit');
    const payload = getPayloadForContactUs(
      formData.firstName,
      formData.lastName,
      formData.phone,
      formData.email,
      formData.preferredDay,
      formData.preferredTime,
      ZipcodeData.brand,
      zipCodeRes.stateCode,
      formData.zip
    );
    try {
      setShowSpinner(true);
      setShowForm(false);
      const response = await getContactUs(payload);
      data = response?.data;
      setShowSpinner(false);
      if (data.response.responseCode == 'CALLME_SUCCESS_000') {
        btn?.setAttribute('disabled', 'true');
        setError(false);
        setShowSuccess(true);
        clearFormData();
        clearErrorData();
        setFormData({
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          zip: zipCodeRes.zipCode,
          preferredDay: pageContent.LABELS?.PREFERRED_DAY_PLACEHOLDER,
          preferredTime: pageContent.LABELS?.PREFERRED_TIME_PLACEHOLDER
        });
      } else {
        setShowSpinner(false);
        setError(true);
        setShowForm(true);
      }
    } catch (error) {
      err = error;
      setShowSpinner(false);
      setShowForm(true);
      setError(true);
    }
    return data;
  };

  const handleSliderClose = () => {
    // clearFormData();
    // clearErrorData();
    if (showConfirmBlock) {
      setShowForm(false);
      setShowConfirmBlock(true);
    } else {
      setShowForm(true);
      setShowConfirmBlock(false);
    }
    setShowSuccess(false);
    let btn = document.getElementById('modal-slide-submit');
    btn?.removeAttribute('disabled');
    onChange(false);
  };
  const clearFormData = () => {
    const newFormData = { ...formData };
    Object.keys(newFormData).forEach((key) => {
      newFormData[key] = '';
    });
    setPhone('');
    setFormData(newFormData);
  };
  const clearErrorData = () => {
    const newFormErrors = { ...formErrors };
    Object.keys(newFormErrors).forEach((key) => {
      newFormErrors[key] = '';
    });
    setFormErrors(newFormErrors);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowForm(false);
      setShowConfirmBlock(true);
    } else {
      setShowConfirmBlock(false);
    }
  };

  const closeConfirmContactUsPopup = () => {
    setShowForm(true);
    setShowConfirmBlock(false);
  }

  const confirmContactUsSubmit = () => {
    setShowConfirmBlock(false);
    /* form submit*/
    contactUsSubmit().then((r) => {
    });
    onSubmit(formData);
    /* form submit*/
  };

  const checkForEmptyData = () => {
    let valid = true;
    const newFormErrors = { ...formErrors };
    if (formData.firstName === '') {
      const name = 'firstName';
      newFormErrors[name] = pageContent.VALIDATIONS.FIRST_NAME_EMPTY;
      valid = false;
    }
    if (formData.lastName === '') {
      const name = 'lastName';
      newFormErrors[name] = pageContent.VALIDATIONS.LAST_NAME_EMPTY;
      valid = false;
    }
    if (formData.phone === '') {
      const name = 'phone';
      newFormErrors[name] = pageContent.VALIDATIONS.PHONE_EMPTY;
      valid = false;
    }
    if (formData.preferredTime === pageContent.LABELS?.PREFERRED_TIME_PLACEHOLDER) {
      const name = 'preferredTime';
      newFormErrors[name] = pageContent.VALIDATIONS.PREFERRED_TIME;
      valid = false;
    }
    if (formData.preferredDay === pageContent.LABELS?.PREFERRED_DAY_PLACEHOLDER) {
      const name = 'preferredDay';
      newFormErrors[name] = pageContent.VALIDATIONS.PREFERRED_DAY;
      valid = false;
    }
    setFormErrors(newFormErrors);
    return valid;
  };
  const setFormValue = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleFormChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    switch (name) {
      case 'firstName':
        if (value.length === 0) {
          setFormErrors({ ...formErrors, [name]: pageContent.VALIDATIONS.FIRST_NAME_EMPTY });
        } else {
          let errFirstNameValue = ALPHA_REGX_WITH_SPACE.test(value) ? '' : pageContent.VALIDATIONS.FIRST_NAME;
          setFormErrors({ ...formErrors, [name]: errFirstNameValue });
        }
        break;
      case 'lastName':
        if (value.length === 0) {
          setFormErrors({ ...formErrors, [name]: pageContent.VALIDATIONS.LAST_NAME_EMPTY });
        } else {
          let errLastNameValue = ALPHA_REGX_WITH_SPACE.test(value) ? '' : pageContent.VALIDATIONS.LAST_NAME;
          setFormErrors({ ...formErrors, [name]: errLastNameValue });
        }
        break;
      case 'email':
        if (value.length > 0) {
          let errEmailValue = REGEXS.EMAIL.test(value) ? '' : pageContent.VALIDATIONS.EMAIL;
          setFormErrors({ ...formErrors, [name]: errEmailValue });
        } else {
          setFormErrors({ ...formErrors, [name]: '' });
        }
        break;
      case 'phone':
        if (value.length === 0) {
          setFormErrors({ ...formErrors, [name]: pageContent.VALIDATIONS.PHONE_EMPTY });
        } else {
          let errPhoneValue = PHONE_FORMATTED.test(value) ? '' : pageContent.VALIDATIONS.PHONE;
          setFormErrors({ ...formErrors, [name]: errPhoneValue });
        }
        break;
      case 'zip':
        if (value.length === 0) {
          setFormErrors({ ...formErrors, [name]: pageContent.VALIDATIONS.ZIP });
        }
        break;
      case 'preferredDay':
        let errDayValue = value !== pageContent.LABELS?.PREFERRED_DAY_PLACEHOLDER ? '' : pageContent.VALIDATIONS.PREFERRED_DAY;
        setFormErrors({ ...formErrors, [name]: errDayValue });
        break;
      case 'preferredTime':
        let errTimeValue = value !== pageContent.LABELS?.PREFERRED_TIME_PLACEHOLDER ? '' : pageContent.VALIDATIONS.PREFERRED_TIME;
        setFormErrors({ ...formErrors, [name]: errTimeValue });
        break;
      default:
        break;
    }
  };
  const validateForm = () => {
    if (!checkForEmptyData()) {
      return false;
    } else {
      let valid = true;
      Object.values(formErrors).forEach((val: any) => val.length > 0 && (valid = false));
      return valid;
    }
  };
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.replace(/\D/g, '');
    setFormValue(event);
    setPhone(formatPhoneNumber(event.target.value));
  };
  return (!contentLoaded ? <PreLoader /> :
    <div className={'contact-us-container'}>
      <ModalSlide direction={'right'} open={showSlider} onClose={handleSliderClose}>
        <ModalSlide.Body>
          <div className={'slider-container'}>
            <div className="fwc-row fwc-row-wrap mgd-menu-row">
              <div className="fwc-col-12 sm-12">
                <h2 className="btm-xxs title">
                  <span className="sae-icon sae-icon-phone right-xxs"></span>
                  {pageContent.HEADER_1}
                </h2>
                {contactPhone && (
                  <a href={`tel:${contactPhone}`}>
                    {contactPhone}
                  </a>
                )}
              </div>
              {zipCodeRes.zipCode && <div className="fwc-col-12 sm-12">
                <h2 className="btm-xxs top-sm title">
                  <span className="sae-icon sae-icon-customer-support right-xxs"></span>
                  {pageContent.HEADER_2}
                </h2>
                <p className="btm-sm">{pageContent.INTRO}</p>
                {showSpinner && <PreLoader />}
                {showError &&
                  <div className='error-container'>
                    <Alert
                      labelAriaCloseBtn="urgent"
                      onClose={function noRefCheck() { }}
                      type="urgent"
                    >{pageContent.ERROR_MESSAGE}</Alert>
                  </div>}
                {showForm && (
                  <div>
                    <div className="fwc-row fwc-row-bm">
                      <div className="fwc-col-6 sm-12">
                        <div className="fwc-input">
                          <label className="fwc-label" htmlFor={'first-name-input'}>
                            {pageContent.LABELS?.FIRST_NAME}
                          </label>
                          <input
                            placeholder={pageContent.LABELS?.FIRST_NAME_PLACEHOLDER}
                            required
                            id={'first-name-input'}
                            onChange={setFormValue}
                            onBlur={handleFormChange}
                            name={'firstName'}
                            type="text"
                            value={formData.firstName}
                            aria-invalid={formErrors.firstName.length > 0}
                          />
                          {formErrors.firstName.length > 0 && (
                            <span role={'alert'} className="fwc-inline-icon fwc-icon-delete">
                              {formErrors.firstName}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="fwc-col-6 sm-12">
                        <div className="fwc-input">
                          <label className="fwc-label" htmlFor={'last-name-input'}>
                            {pageContent.LABELS?.LAST_NAME}
                          </label>
                          <input
                            placeholder={pageContent.LABELS?.LAST_NAME_PLACEHOLDER}
                            required
                            id={'last-name-input'}
                            onChange={setFormValue}
                            onBlur={handleFormChange}
                            name={'lastName'}
                            type="text"
                            value={formData.lastName}
                            aria-invalid={formErrors.lastName.length > 0}
                          />
                          {formErrors.lastName.length > 0 && (
                            <span role={'alert'} className="fwc-inline-icon fwc-icon-delete">
                              {formErrors.lastName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="fwc-row fwc-row-bm">
                      <div className="fwc-col-6 sm-12">
                        <div className="fwc-input">
                          <label className="fwc-label" htmlFor={'email-input'}>
                            {pageContent.LABELS?.EMAIL}
                          </label>
                          <input
                            placeholder={pageContent.LABELS?.EMAIL_PLACEHOLDER}
                            id={'email-input'}
                            onChange={setFormValue}
                            onBlur={handleFormChange}
                            name={'email'}
                            type="text"
                            value={formData.email}
                            aria-invalid={formErrors.email.length > 0}
                          />
                          {formErrors.email.length > 0 && (
                            <span role={'alert'} className="fwc-inline-icon fwc-icon-delete">
                              {formErrors.email}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="fwc-col-6 sm-12">
                        <div className="fwc-input">
                          <label className="fwc-label" htmlFor={'phone-input'}>
                            {pageContent.LABELS?.PHONE}
                          </label>
                          <input
                            placeholder={pageContent.LABELS?.PHONE_PLACEHOLDER}
                            required
                            id={'phone-input'}
                            onChange={handlePhoneChange}
                            onBlur={handleFormChange}
                            name={'phone'}
                            type="tel"
                            maxLength={14}
                            value={phone}
                            aria-invalid={formErrors.phone.length > 0}
                          />
                          {formErrors.phone.length > 0 && (
                            <span role={'alert'} className="fwc-inline-icon fwc-icon-delete">
                              {formErrors.phone}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="fwc-row fwc-row-bm">
                      <div className={'fwc-col-9 md-12 sm-12'}>
                        <div className={'disclaimer-cont'}>
                          <div className={'sae-icon sae-icon-info right-xs'}></div>
                          <div>{pageContent.LABELS?.EMAIL_DISCLAIMER}</div>
                        </div>
                      </div>
                    </div>
                    <div className="fwc-row fwc-row-bm">
                      <div className="fwc-col-6 sm-12">
                        <div className="fwc-select-list">
                          <label className="fwc-label" htmlFor="day-select">
                            {pageContent.LABELS?.PREFERRED_DAY}
                          </label>
                          <select
                            id="day-select"
                            onChange={setFormValue}
                            onBlur={handleFormChange}
                            name={'preferredDay'}
                            value={formData.preferredDay}
                            aria-invalid={formErrors.preferredDay.length > 0}
                          >
                            <option key={'preferred_day_placeholder'} value={pageContent.LABELS?.PREFERRED_DAY_PLACEHOLDER}>
                              {pageContent.LABELS?.PREFERRED_DAY_PLACEHOLDER}
                            </option>
                            {pageContent.LABELS?.PREFERRED_DAY_OPTIONS.map((day, index) => {
                              return (
                                <option key={index} value={day}>
                                  {day}
                                </option>
                              );
                            })}
                          </select>
                          {formErrors.preferredDay.length > 0 && (
                            <span role={'alert'} className="fwc-inline-icon fwc-icon-delete">
                              {formErrors.preferredDay}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="fwc-col-6 sm-12">
                        <div className="fwc-select-list">
                          <label className="fwc-label" htmlFor="time-select">
                            {pageContent.LABELS?.PREFERRED_TIME}
                          </label>
                          <select
                            id="time-select"
                            onChange={setFormValue}
                            onBlur={handleFormChange}
                            name={'preferredTime'}
                            value={formData.preferredTime}
                            aria-invalid={formErrors.preferredTime.length > 0}
                          >
                            <option key={'preferred_time_placeholder'} value={pageContent.LABELS?.PREFERRED_TIME_PLACEHOLDER}>
                              {pageContent.LABELS?.PREFERRED_TIME_PLACEHOLDER}
                            </option>
                            {pageContent.LABELS?.PREFERRED_TIME_OPTIONS.map((time, index) => {
                              return (
                                <option key={index} value={time}>
                                  {time}
                                </option>
                              );
                            })}
                          </select>
                          {formErrors.preferredTime.length > 0 && (
                            <span role={'alert'} className="fwc-inline-icon fwc-icon-delete">
                              {formErrors.preferredTime}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {showConfirmBlock &&
                  <div className='confirm_block'>
                    <h2>{pageContent.LABELS?.THANK_YOU} {formData.firstName}</h2>
                    <p><b>{pageContent.LABELS?.CONFIRM_CALL}</b></p>
                    <p>
                      {pageContent.LABELS?.CUSTOMER_SERVICE} <b>{formData.phone}</b> {pageContent.LABELS?.ON} <b>{formData.preferredDay}</b> {pageContent.LABELS?.BETWEEN} <b>{formData.preferredTime}</b> <b>{zipCodeRes.timeZone}</b>
                    </p>
                  </div>
                }
                {showSuccess && (
                  <div className={'success-container'}>
                    <img src={celebration} />
                    <h3 className={'top-xs btm-xxs'}>{pageContent.SUCCESS.TITLE}</h3>
                    <p>{pageContent.SUCCESS.MESSAGE}</p>
                  </div>
                )}
              </div>}
            </div>
          </div>
        </ModalSlide.Body>
        <ModalSlide.Footer>
          {showForm && <Button data-analytics={"closeBtnCallUsModalIos"} btnType="secondary" id="modal-slide-cancel" onClick={handleSliderClose}>
            {pageContent.LABELS?.CLOSE}
          </Button>}
          {zipCodeRes.zipCode && showForm && <Button
            data-analytics={"submitBtnCallUsModalIos"}
            id="modal-slide-submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            {pageContent.LABELS?.SUBMIT}
          </Button>}
          {showConfirmBlock && (
            <>
              <Button btnType="secondary" id="modal-action-cancel" onClick={closeConfirmContactUsPopup}>
                {pageContent.LABELS?.EDIT}
              </Button>
              <Button id="modal-action-ok" onClick={confirmContactUsSubmit}>
                {pageContent.LABELS?.OK}
              </Button></>
          )

          }
          {showSuccess && <Button btnType="secondary" id="modal-slide-cancel" onClick={handleSliderClose}>
            {pageContent.LABELS?.CLOSE}
          </Button>}
        </ModalSlide.Footer>
      </ModalSlide>
    </div>
  );
}
export default ContactUs;
