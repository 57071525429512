const UPDATE_SHOPPER_PERSONAL_INFORMATION_RESPONSE_CODES = {
  RESPONSE_CODE: 'UPDSHOPPERACCT000'
};

const INPUT_TYPES = {
  FIRSTNAME: 'FIRSTNAME',
  LASTNAME: 'LASTNAME',
  EMAIL: 'EMAIL',
  DOB: 'DOB',
  PHONE: 'PHONE'
};

const REQUIRED_FIELD = 'REQUIRED_FIELD';
export { INPUT_TYPES, REQUIRED_FIELD, UPDATE_SHOPPER_PERSONAL_INFORMATION_RESPONSE_CODES };

