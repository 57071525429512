import { Button } from '@sydney-broker-ui/ios';
import React, { useEffect, useState } from 'react';


import './MacRedirectionPopup.scss';
import { getUpdatedStateOptions } from './MacRedirectionPopupUtils';
import { STATES_OPTIONS, STATE_VALUES } from './constants';

const MacRedirectionPopup: React.FC<MacRedirectionPopupProps> = ({ labels, useCase, onClose, onContinue }) => {
    const [stateOptions, setStateOptions] = useState(STATES_OPTIONS);
    const [selectedState, setSelectedState] = useState('');
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        setStateOptions(getUpdatedStateOptions(stateOptions, labels));
    }, [])

    useEffect(() => {
        setShowError(false);
    }, [useCase])

    const getSelectedState = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setShowError(false)
        setSelectedState(e.target.value)
    }
    const validateState = () => {
        if (selectedState && selectedState !== STATE_VALUES.SELECT_YOUR_STATE) {
            onContinue(selectedState);
            setSelectedState('')
        } else {
            setShowError(true);
        }
    }
    return (
        <div className="mac-redirection-modal-body-wrapper">
            <span>{labels.PLEASE_SELECT_STATE}</span>
            <div className="fwc-select-list state-select-field-wrapper">
                <label className="fwc-label" htmlFor="state-select">
                    {labels.SELECT_YOUR_STATE}:
                </label>
                <select
                    name="state"
                    id="state-select"
                    onChange={(e) => getSelectedState(e)}
                    onBlur={() => { }}
                    value={selectedState}
                    data-testid="state-select"
                >
                    {stateOptions.map(
                        (item, idx) =>
                            <option key={idx} value={item.value}>
                                {item.label}
                            </option>
                    )}
                </select>
                {showError && (
                    <span id="mac-redirection-state-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {labels?.REQUIRED_FIELD}
                    </span>
                )}
            </div>
            <div className='mac-redirection-buttons-wrapper'>
                <Button onClick={() => onClose()}>
                    {labels?.CANCEL}
                </Button>
                <Button onClick={() => validateState()} >
                    {labels?.CONTINUE}
                </Button>
            </div>
        </div >
    );
}

export default MacRedirectionPopup;