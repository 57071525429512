const CHINESE_LINKS = {
  MEDICAL_APPLICATION_LINK: 'https://file.anthem.com/2024/OFF_CT_CA_2024.pdf',
  MEDICAL_BROCHURE_LINK: 'http://anthem.ly/IND-CA-CT-KIT-2024',
  DENTAL_APPLICATION_LINK: 'https://file.anthem.com/2024/OFF_CT_CA_DENTAL_2024.pdf',
  DENTAL_BROCHURE_LINK: 'http://anthem.ly/IND-CA-CT-SPC-KIT-2024'
};

const VIETNAMESE_LINKS = {
  MEDICAL_APPLICATION_LINK: 'https://file.anthem.com/2024/OFF_VT_CA_2024.pdf',
  MEDICAL_BROCHURE_LINK: 'http://anthem.ly/IND-CA-VN-KIT-2024',
  DENTAL_APPLICATION_LINK: 'https://file.anthem.com/2024/OFF_VT_CA_DENTAL_2024.pdf',
  DENTAL_BROCHURE_LINK: 'http://anthem.ly/IND-CA-VN-SPC-KIT-2024'
};

const KOREAN_LINKS = {
  MEDICAL_APPLICATION_LINK: 'https://file.anthem.com/2024/OFF_KO_CA_2024.pdf',
  MEDICAL_BROCHURE_LINK: 'http://anthem.ly/IND-CA-KO-KIT-2024',
  DENTAL_APPLICATION_LINK: 'https://file.anthem.com/2024/OFF_KO_CA_DENTAL_2024.pdf',
  DENTAL_BROCHURE_LINK: 'http://anthem.ly/IND-CA-KO-SPC-KIT-2024'
};

const TAGALOG_LINKS = {
  MEDICAL_APPLICATION_LINK: 'https://file.anthem.com/2024/OFF_TA_CA_2024.pdf',
  MEDICAL_BROCHURE_LINK: 'http://anthem.ly/IND-CA-TA-KIT-2024',
  DENTAL_APPLICATION_LINK: 'https://file.anthem.com/2024/OFF_TA_CA_DENTAL_2024.pdf',
  DENTAL_BROCHURE_LINK: 'http://anthem.ly/IND-CA-TA-SPC-KIT-2024'
};

const SPANISH_LINKS = {
  MEDICAL_APPLICATION_LINK: 'https://file.anthem.com/2024/OFF_SP_CA_2024.pdf',
  MEDICAL_BROCHURE_LINK: 'http://anthem.ly/IND-CA-SP-KIT-2024',
  DENTAL_APPLICATION_LINK: 'https://file.anthem.com/2024/OFF_SP_CA_DENTAL_2024.pdf',
  DENTAL_BROCHURE_LINK: 'http://anthem.ly/IND-CA-SP-SPC-KIT-2024'
};

const INITIAL_DATA = {
  TITLE: '',
  SUB_TITLE: '',
  DESCRIPTION: '',
  BROCHURE_TITLE: '',
  BROCHURE_DESCRIPTION: '',
  MEDICAL_TITLE: '',
  MEDICAL_COUNTY: '',
  MEDICAL_PDF_NAME: '',
  MEDICL_BROCHURE_NAME: '',
  MEDICAL_COUNTY_SUB: '',
  DENTAL_TITLE: '',
  DENTAL_COUNTY: '',
  DENTAL_COUNTY_SUB: '',
  DENTAL_PDF_NAME: '',
  DENTAL_BROCHURE_NAME: '',
  BUTTON: ''
};

const LANGUAGE_NAME = {
  SPANISH: 'Español',
  CHINESE: '中文',
  KOREAN: '한국어',
  VIETNAMESE: 'Tiếng Việt',
  TAGALONG: 'Tagalog',
  ENGLISH: 'English',
  ALBANIAN: 'Shqip',
  ARMENIAN: 'հայերեն',
  AMHARIC: 'አማርኛ',
  PYCCK: 'Русский',
  DUTCH: 'Nederlands',
  IGBO: 'Igbo',
  INDONESIA: 'Bahasa Indonesia',
  KIRUNDI: 'Kirundi',
  OROMO: 'Oromifaa',
  PADUTCH: 'Deitsch',
  THAI: 'ไทย',
  UKRANIAN: 'Українська',
  BASSA: 'Ɓǎsɔ́ɔ̀ Wùɖù',
  BENGALI: 'বাংলা',
  DINKA: 'Dinka',
  FRENCH: 'Français',
  GERMAN: 'Deutsch',
  GUJARATI: 'ગુજરાતી',
  HAITIAN: 'Kreyò',
  HINDI: 'हिंदी',
  HMONG: 'White Hmong',
  ILOKANO: 'Ilokano',
  ITALIAN: 'Italiano',
  JAPANESE: '日本語',
  KHMER: 'ខ្មែរ',
  LANGLAO: 'ພາສາລາວ',
  NEPALI: 'नेपाली',
  POLISH: 'polski',
  PORTUGUESE: 'Português',
  PANJABI: 'ਪੰਜਾਬੀ',
  ROMANIAN: 'Română',
  SAMOAN: 'Samoa',
  SERBIAN: 'Srpski',
  YOURUBA: 'Yorùbá',
  YIDDISH: 'אידיש',
  URDU: 'اردو',
  FARSI: 'فارسي',
  ARABIC: 'العربية',
  GREEK: 'Ελληνικά',
  NAVAJO: 'Diné',
  BURMESE: 'Burmese'
};

const CHINESE = 'chinese';
const SPANISH = 'spanish';
const TAGALOG = 'tagalog';
const KOREAN = 'korean';
const VIETNAMESE = 'vietnamese';
export {
  CHINESE,
  SPANISH,
  KOREAN,
  TAGALOG,
  VIETNAMESE,
  INITIAL_DATA,
  LANGUAGE_NAME,
  CHINESE_LINKS,
  VIETNAMESE_LINKS,
  KOREAN_LINKS,
  TAGALOG_LINKS,
  SPANISH_LINKS
};
