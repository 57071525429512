import { create } from 'zustand';
import { createJSONStorage, devtools, persist, subscribeWithSelector } from 'zustand/middleware';

import { createAgentQuoteStoreSlice } from './agentBasicsLandingSlice';
import { createAgentSSOInfoSlice } from './agentSSOInfoSlice';
import { createAgentSlice } from './agentSlice';
import { createApplicationSlice } from './applicationSlice';
import { createDeepLinkInfoSlice } from './deepLinkInfoSlice';
import { createDentalPlansSlice } from './dentalPlansSlice';
import { createDentistStoreSlice } from './dentistStoreSlice';
import { createDrugStoreSlice } from './drugStoreSlice';
import { createEstimateSavingsStore } from './estimateSavingsStoreSlice';
import { createEyeDoctorStore } from './eyeDoctorStoreSlice';
import { createHelpMeChooseStore } from './helpMeChooseStoreSlice';
import { createMedicalPlansSlice } from './medicalPlansSlice';
import { createMemberInfoSlice } from './memberInfoSlice';
import { createPlanSummaryStore } from './planSummarySlice';
import { createProductStoreSlice } from './productStoreSlice';
import { createProviderStoreSlice } from './providerStoreSlice';
import { createQuoteProgressSlice } from './quoteProgressStoreSlice';
import { createQuoteStoreSlice } from './quoteStoreSlice';
import { createRegistrationStoreSlice } from './registrationStoreSlice';
import { createStateConfigSlice } from './stateConfigSlice';
import { createVisionPlansSlice } from './visionPlansSlice';

export const useGlobalStore = create<GlobalStore>()(
  devtools(
    subscribeWithSelector(
      persist(
        (...a) => ({
          ...createQuoteProgressSlice(...a),
          ...createMedicalPlansSlice(...a),
          ...createQuoteStoreSlice(...a),
          ...createEstimateSavingsStore(...a),
          ...createDrugStoreSlice(...a),
          ...createDentistStoreSlice(...a),
          ...createEyeDoctorStore(...a),
          ...createHelpMeChooseStore(...a),
          ...createProviderStoreSlice(...a),
          ...createRegistrationStoreSlice(...a),
          ...createDentalPlansSlice(...a),
          ...createVisionPlansSlice(...a),
          ...createAgentSlice(...a),
          ...createMemberInfoSlice(...a),
          ...createDeepLinkInfoSlice(...a),
          ...createApplicationSlice(...a),
          ...createPlanSummaryStore(...a),
          ...createProductStoreSlice(...a),
          ...createAgentSSOInfoSlice(...a),
          ...createAgentQuoteStoreSlice(...a),
          ...createStateConfigSlice(...a)
        }),
        {
          partialize: (state) => Object.fromEntries(Object.entries(state).filter(([key]) => ![''].includes(key))),
          name: 'quote-store',
          storage: createJSONStorage(() => sessionStorage)
        }
      )
    )
  )
);
