import { APPLICANT_TYPES } from '../../../shared/globalConstants';
import { formatDateToYYYYMMDD, getAgeByDob } from '../../../shared/utils/globalUtils';

const getUpdatedRecommendationOptions = (options: States, labels: any) => {
  const updatedOptions = options.map((option) => {
    return {
      ...option,
      label: labels[option.key]
    };
  });

  return updatedOptions;
};

const getYoungestDependentAge = (applicantFormDetails: Applicant[]) => {
  let youngestAge = Infinity;
  applicantFormDetails.forEach((applicant) => {
    if (![APPLICANT_TYPES.PRIMARY, APPLICANT_TYPES.SPOUSE, APPLICANT_TYPES.DOMESTICPARTNER].includes(applicant.applicantType)) {
      const age = getAgeByDob(applicant.dateOfBirth);
      youngestAge = Math.min(youngestAge, age);
    }
  });
  return youngestAge === Infinity ? 0 : youngestAge;
};

const getVisionRecommendationPayload = (
  visionPlanApplicants: Applicant[],
  coverageDate: string,
  state: string,
  zipCode: string,
  selectedOption: string
): VisionRecommendationPayload => {
  return {
    StateCd: state,
    GroupSize: '1',
    EffectiveDt: formatDateToYYYYMMDD(coverageDate),
    ZipCode: zipCode,
    SubscriberAge: getAgeByDob(visionPlanApplicants[0].dateOfBirth).toString(),
    ...(visionPlanApplicants?.length > 1 && { YoungestDependentAge: getYoungestDependentAge(visionPlanApplicants).toString() }),
    ProductTypeList: [
      {
        ProductType: 'Vision',
        BenefitLevel: selectedOption
      }
    ]
  };
};

const getOriginalVisionPlans = (visionPlans: Contracts, originalVisionPlans: Contracts): Contracts => {
  let originalPlans: Contracts = [];
  visionPlans.forEach((plan) => {
    const originalPlan = originalVisionPlans.find((originalPlan) => originalPlan.contractCode === plan.contractCode);
    if (originalPlan) {
      originalPlans.push(originalPlan);
    } else {
      originalPlans.push(plan);
    }
  });
  return originalPlans;
};

export { getOriginalVisionPlans, getUpdatedRecommendationOptions, getVisionRecommendationPayload };
