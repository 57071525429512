const HOUSEHOLDINCOME = {
  MAX_LENGTH: 10,
  MAX_VALUE: 9999999
};

const DEPENDENTS = {
  MAX_LENGTH: 2,
  MIN_VALUE: 0,
  MAX_VALUE: 19
};

const ELIGIBILITIES = ['MEDICAID', 'CHIP'];

export { DEPENDENTS, ELIGIBILITIES, HOUSEHOLDINCOME };
