const COMPARE_PLAN_TYPES = {
  MEDICAL: 'medical',
  DENTAL: 'dental',
  VISION: 'vision'
};

const PLAN_MARKET_TYPES = {
  COLARADO_CONNECT: 'CC',
  MARKET_PLACE: 'M',
  OFF_MARKET: 'OM'
};

export { COMPARE_PLAN_TYPES, PLAN_MARKET_TYPES };
