import { API_NAME_LIST } from '../../../api/api-name-list';
import axiosInstance from '../../../api/axiosWrapper/axiosWrapper';

const saveDemographic = (payload: SaveDemographicRequest, headers: Record<string, string>) => {
  return axiosInstance.post(API_NAME_LIST.SAVE_DEMOGRAPHIC_INFO, payload, { headers });
};

const saveQuote = (payload: SaveQuoteRequest, headers: Record<string, string>) => {
  return axiosInstance.post(API_NAME_LIST.SAVE_QUOTE, payload, { headers });
};

const saveNavigation = (payload: SaveNavigationRequest, headers: Record<string, string>) => {
  return axiosInstance.post(API_NAME_LIST.SAVE_NAVIGATION, payload, { headers });
};

const saveDemographicsAndQuote = (payload: SaveDemographicsAndQuoteRequest, headers: Record<string, string>) => {
  return axiosInstance.post(API_NAME_LIST.SAVE_DEMOGRAPHIC_AND_QUOTE, payload, { headers });
};

const getDemographic = (demographicId: string) => {
  return axiosInstance.get(`${API_NAME_LIST.GET_DEMOGRAPHIC}/${demographicId}`);
};

const getNavigation = (headers: Record<string, string>) => {
  return axiosInstance.post(API_NAME_LIST.GET_NAVIGATION, '', { headers });
};
export { getDemographic, getNavigation, saveDemographic, saveDemographicsAndQuote, saveNavigation, saveQuote };
