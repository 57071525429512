/**
 * Renders the MacLoginWithCreds component.
 *
 * @component
 * @param {MacLoginWithCredsProps} props - The component props.
 * @param {Object} props.labels - The labels object containing text for UI elements.
 * @returns {JSX.Element} The rendered MacLoginWithCreds component.
 * MacLoginWithCreds is a React functional component used for user authentication.
 * It provides a form for the user to enter their username and password.
 * It also provides options for the user to toggle password visibility, 
 * and links for users who are not registered or have forgotten their username or password.
 */

import { Button, Modal } from '@sydney-broker-ui/ios';
import { AxiosError, isAxiosError } from 'axios';
import React, { memo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FullPageLoader from '../../../../components/common/full-page-loader/FullPageLoader';
import InfoBar from '../../../../components/common/info-bar/InfoBar';
import MacRedirectionPopup from '../../../../components/common/mac-redirection-popup/MacRedirectionPopup';

import { useShopperContext } from '../../../../context/shopper-context/ShopperContext';
import { useGlobalStore } from '../../../../store/globalStore';
import { useUserStore } from '../../../../store/userStore';
import { loginUser } from '../login-modal/loginServices';
import { getDemographicChanged, getErrorMessage, getErrorObj, getInProgressApplication, getLoginSuccessPayload, getNavigationInfo, getWebAccount, populateUserDetails } from '../login-modal/loginUtils';

import { HTTP_STATUS_CODES, STATUSES } from '../../../../api/constants';
import { generateTokenAPI, getBuildVersion } from '../../../../api/services/tokenService';
import { ERROR_ALERT_CONFIG, LOGIN_TYPES, NAV_PATHS, USER_ROLES } from '../../../../shared/globalConstants';
import { INPUT_TYPES, LOGIN_RESPONSE_CODES, USER_AUTHENTICATED_RESPONSE_CODES } from '../login-modal/constants';
import './MacLoginWithCreds.scss';
import { NAVIGATION_POPUP_USECASES, REDIRECTION_URLS } from './constants';

const { REACT_APP_ENV } = process.env;

const MacLoginWithCreds: React.FC<MacLoginWithCredsProps> = ({ labels, initiateTwoFa }) => {
    const [userName, setUserName] = useState('');
    const [validUserName, setValidUserName] = useState(true);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [validPassword, setValidPassword] = useState(true);
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [redirectionModal, setRedirectionModal] = useState({ show: false, useCase: '', heading: '' });
    const [loginError, setLoginError] = useState({
        hasError: false,
        responseCode: '',
        message: ''
    });

    const navigate = useNavigate();

    const { updateAgentInfo, updateMemberInfo, updateCurrentCoverageMedicalPlan, updateCurrentCoverageDentalPlan, updateCurrentCoverageVisionPlan, updateApplicantFormDetails,
        updateZipCodeResponse, updateCoverageDate, updateZipCode, updateCoverageType, updateZipCodeFormDetails,
        updateSelectedPlan, updateSelectedDentalPlan, updateSelectedVisionPlan, updatePlanTypes, updateFavouriteMedicalPlans, updateFavouriteDentalPlans, updateFavouriteVisionPlans,
        updatedDrugsList, updateMatchMedicationsToPlansStatus, deepLinkInfo, updateEditZipCodeRes, updateEditZipCodeFormDetails, updateEditCoverageType, updateEditPlanTypes, updateLoginType
    } = useGlobalStore((state) => state);
    const { macLoginWithCredUseCase } = deepLinkInfo;
    const { updateLoginResponse, updateLoginStatus, updateWebAccount, updateDemographicInfo, updateQuotes, updateFavourites, updateRole } = useUserStore((state) => state);
    const { setUserPassword } = useShopperContext()

    const handleUserNameChange = useCallback((e: { target: { value: React.SetStateAction<string> } }) => {
        setValidUserName(true);
        setUserName(e.target.value);
    }, []);

    const handlePasswordChange = useCallback((e: { target: { value: React.SetStateAction<string> } }) => {
        setValidPassword(true);
        setPassword(e.target.value);
    }, []);

    const resetLoginError = useCallback(() => {
        setLoginError({
            hasError: false,
            message: '',
            responseCode: ''
        });
    }, []);


    const validateCredentials = useCallback(() => {
        resetLoginError();
        setLoading(true);

        setValidUserName(userName.length > 0);
        setValidPassword(password.length > 0);

        if (userName.length > 0 && password.length > 0) {
            //Local validations passed
            authenticateUser();
        } else {
            //local validations failed
            setLoading(false);
        }
    }, [userName, password]); // dependencies

    const handleUserLogin = useCallback(async (data: LoginResponse) => {
        const loginSuccesspayload: LoginSuccessPayload = getLoginSuccessPayload(data, userName);
        setUserPassword(password)
        updateLoginResponse(loginSuccesspayload);
        updateLoginType(LOGIN_TYPES.MAC_LOGIN);
        if (data.responseMessage.responseCode === LOGIN_RESPONSE_CODES.TWO_FA_NEEDED && loginSuccesspayload.contacts.length > 0) {
            setLoading(false);
            //User must complete 2fa to login
            initiateTwoFa();
        } else {
            //User doesn't need 2fa
            let tokenGenerated = await getToken();
            if (tokenGenerated || REACT_APP_ENV === 'local') {
                const updateWA = getWebAccount(data);
                updateWebAccount(updateWA);
                try {
                    await populateUserDetails(data);
                } catch (error) {
                    console.warn('Error in MacLoginWithCreds.tsx :', error);
                    setLoginError({
                        hasError: true,
                        responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
                        message: `<b>${labels?.SYSTEM_EXCEPTION_HEADING}</b> <br> ${labels?.SYSTEM_EXCEPTION_MESSAGE}`
                    });
                    setLoading(false);
                }
                const inProgressApplications = await getInProgressApplication(data.loginResponse.webAccountGUID);
                if (inProgressApplications) {
                    setLoading(false);
                    navigate(NAV_PATHS.DASHBOARD)
                } else {
                    const checkDempographicCheck = getDemographicChanged();
                    if (checkDempographicCheck) {
                        useGlobalStore.getState().updateApplicantFormDetails(useGlobalStore.getState().memberInfo.demographicInfo.applicant || []);
                    }
                    const getURL = await getNavigationInfo(data.shopperProfile?.demographicInfo?.demographicId, useUserStore.getState().role, checkDempographicCheck);
                    setLoading(false);
                    navigate(getURL);
                    if (macLoginWithCredUseCase) {
                        navigate(NAV_PATHS.PLAN_RECOMMENDATIONS)
                    } else {
                        navigate(getURL);
                    }
                }
            }
            else {
                setLoading(false);
            }
        }
    }, [userName, password, updateLoginResponse, setLoading, updateLoginStatus]); // dependencies


    const getToken = async (): Promise<boolean> => {
        let returnVal = false;
        try {
            const response = await getBuildVersion();
            if (response.status === 200) {
                try {
                    const tokenResponse = await generateTokenAPI();
                    if (tokenResponse && tokenResponse.data.token) {
                        useUserStore.getState().updateJWTToken({ jwtToken: tokenResponse.data.token });
                        returnVal = true;
                    } else {
                        returnVal = false;
                        setTokenError(tokenResponse.data.responseMessage ? tokenResponse.data.responseMessage.responseCode : null, tokenResponse.data.responseMessage ? tokenResponse.data.responseMessage.message : null)
                    }
                } catch (error: any) {
                    returnVal = false;
                    setTokenError(error.response?.data?.error?.errorKey, error.response?.data?.error?.message);
                }
            }
        }
        catch (error: any) {
            returnVal = false;
            setTokenError(error.response?.data?.error?.errorKey, error.response?.data?.error?.message);
        }
        finally {
            return returnVal;
        }
    }

    const setTokenError = (responseCode, responseMessage) => {
        setLoginError({
            hasError: true,
            responseCode: responseCode ? responseCode : HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: responseMessage ? responseMessage : `<b>${labels?.SYSTEM_EXCEPTION_HEADING}</b> <br> ${labels?.SYSTEM_EXCEPTION_MESSAGE}`
        });

    }
    const handleLoginServerError = useCallback((error: AxiosError<LoginError> | null) => {
        if (isAxiosError(error)) {
            const errorObj = error?.response?.data?.error;
            const errorKey = errorObj?.errorKey ?? '';
            const loginErrorObj = getErrorObj(errorKey, labels);
            setLoginError(loginErrorObj);
        } else {
            setLoginError({
                hasError: true,
                responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
                message: `<b>${labels?.SYSTEM_EXCEPTION_HEADING}</b> <br> ${labels?.SYSTEM_EXCEPTION_MESSAGE}`
            });
        }

        setLoading(false);
    }, [labels, setLoginError, setLoading]);

    const authenticateUser = useCallback(() => {
        loginUser(userName, password, USER_ROLES.MEMBER)
            .then((response) => {
                const data: LoginResponse = response.data;

                if (response.status === HTTP_STATUS_CODES.SUCCESS && data.status === STATUSES.SUCCESS) {
                    const { responseCode } = data.responseMessage;

                    if (USER_AUTHENTICATED_RESPONSE_CODES.includes(responseCode) && data.loginResponse.role === USER_ROLES.MEMBER) {
                        //User has logged in
                        handleUserLogin(data);
                    } else {
                        //User has not logged in due to an error
                        //Add UI to handle user account locked etc
                        //Replace the error message later
                        setLoginError({
                            hasError: true,
                            responseCode: responseCode,
                            message: 'User is not a member'
                        });

                        updateLoginType('');
                        setLoading(false);
                    }
                } else {
                    //Handle server error
                    handleLoginServerError(null);
                }
            })
            .catch((error: AxiosError<LoginError>) => {
                console.warn('MacLoginWithCreds.tsx validateCredentials error :', error);
                //Handle server error
                handleLoginServerError(error);
            });
    }, [userName, password, handleUserLogin, setLoginError, setLoading, handleLoginServerError]); // dependencies

    const handleNavigation = (useCase: string, heading: string) => {
        setRedirectionModal({ show: true, useCase: useCase, heading: heading });
    }

    const handleModalClose = () => {
        setRedirectionModal({ show: false, useCase: '', heading: '' })
    }

    const initiateRedirection = (selectedState: string) => {
        const url = REDIRECTION_URLS[redirectionModal.useCase][selectedState];
        window.open(url, '_blank');
        handleModalClose();
    }

    return (
        <div className='creds-container'>
            {loading && <FullPageLoader />}
            <div className={'fwc-input mac-input-container'}>
                <label id="mac-login-modal-username-label" className="fwc-label" htmlFor={'login-modal-username-input'}>
                    {labels?.USERNAME}
                </label>
                <input data-testid="mac-user-name-input" id="mac-login-modal-username-input" className='input-shadow' type="text" value={userName} onChange={handleUserNameChange} />
                {!validUserName && (
                    <div className="fwc-row fwc-row-bm fwc-col-12">
                        <div className="fwc-input">
                            <span id="mac-login-modal-username-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                                {' '}
                                {getErrorMessage(labels, INPUT_TYPES.USERNAME, userName)}
                            </span>
                        </div>
                    </div>
                )}
            </div>

            <div className={'fwc-input mac-input-container'}>
                <label id="mac-login-modal-password-label" className="fwc-label" htmlFor={'login-modal-password-input'}>
                    {labels?.PASSWORD}
                </label>
                <div className="password-input-container password-container-position">
                    <input data-testid="mac-password-input" id="mac-login-modal-password-input" className='input-shadow' type={passwordVisible ? 'text' : 'password'} value={password} onChange={handlePasswordChange} />

                    <span
                        id="mac-login-modal-show-password-icon"
                        onClick={() => setPasswordVisible(!passwordVisible)}
                        className={passwordVisible ? 'sae-icon sae-icon-visualy-impaired password-icon' : 'sae-icon sae-icon-eye password-icon'}
                    />
                </div>
                {!validPassword && (
                    <div className="fwc-row fwc-row-bm fwc-col-12">
                        <div className="fwc-input">
                            <span id="mac-login-modal-password-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                                {' '}
                                {getErrorMessage(labels, INPUT_TYPES.PASSWORD, password)}
                            </span>
                        </div>
                    </div>
                )}
            </div>

            {loginError.hasError && !loading && (
                <InfoBar
                    area={ERROR_ALERT_CONFIG.AREA}
                    backgroundColor={'error'}
                    labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
                    type={ERROR_ALERT_CONFIG.ERROR}
                    handleClose={() => {
                        resetLoginError();
                    }}
                >
                    <p className="message" dangerouslySetInnerHTML={{ __html: loginError.message }} />
                </InfoBar>
            )}

            <div className='login-button-container'>
                <span id='mac-login-not-registered' className='not-registered-link' onClick={() => handleNavigation(NAVIGATION_POPUP_USECASES.NOT_REGISTERED, labels?.NOT_REGISTERED)}>{labels?.NOT_REGISTERED}</span>
                <Button onClick={() => { validateCredentials() }}>
                    {labels?.LOGIN_BUTTON_TEXT}
                </Button>
            </div>

            <div className='account-recovery-buttons-container'>
                <span>
                    <span id='mac-login-not-registered' className='not-registered-link' onClick={() => handleNavigation(NAVIGATION_POPUP_USECASES.FORGOT_USERNAME, labels?.FORGOT_USERNAME)}>{labels?.FORGOT_USERNAME}</span>
                    <span id='mac-login-not-registered' className='or-text' onClick={() => { }}>{' ' + labels?.OR + ' '}</span>
                    <span id='mac-login-not-registered' className='not-registered-link' onClick={() => handleNavigation(NAVIGATION_POPUP_USECASES.FORGOT_PASSWORD, labels?.FORGOT_PASSWORD)}>{labels?.FORGOT_PASSWORD}</span>
                </span>
            </div>
            <Modal open={redirectionModal.show} onClose={handleModalClose} title={redirectionModal.heading}>
                <Modal.Body>
                    <MacRedirectionPopup labels={labels} onClose={() => handleModalClose()} onContinue={(selectedState) => initiateRedirection(selectedState)} useCase={redirectionModal.useCase} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default memo(MacLoginWithCreds);
