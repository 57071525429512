import { APPLICANT_TYPES } from '../../../shared/globalConstants';

const MEDICAL_PLANS_TABS = {
  MARKET_PLANS: 'MARKET_PLANS',
  OFF_MARKET_PLANS: 'OFF_MARKET_PLANS',
  COLARADO_CONNECT_PLANS: 'COLARADO_CONNECT_PLANS'
};

const GRADE_LEVELS = {
  POPULAR: 'POPULAR',
  RECOMMENDED: 'RECOMMENDED'
};

const COST_COVERAGE_CODES = {
  DEDUCTIBLE: 'MEDDEDUCTIBLEINDV'
};

const PLAN_BENEFIT_CODES = {
  DEDUCTIBLE: 'MEDDEDUCTIBLEINDV',
  MEDICAL_DEDUCTIBLE: 'MEDDEDUCTIBLEFAM',
  PRIMARY_CARE_VISIT: 'PRIMOFFVIS',
  PREVENTIVE_CARE: 'PREVENTIVECARE',
  HSA_COMPATIBLE: 'HSACOMPAT',
  OUT_OF_POCKET_MAX_IND: 'OOPMAXIND',
  OUT_OF_POCKET_MAX_FAM: 'OOPMAXFAM',
  BLUE_CARD: 'BLUECARD',
  SPECIALIST_VISIT: 'SPCLOFFVIS',
  TIER_ONE_DRUG: 'GENPREDRUGS'
};

const PLAN_TIERS = {
  GOLD: 'GOLD',
  BRONZE: 'BRONZE',
  SILVER: 'SILVER',
  PLATINUM: 'PLATINUM',
  CATASTROPHIC: 'CATASTROPHIC',
  ALL: 'ALL'
};

const FILTER_TYPES = {
  MONTHLY_COST: 'MONTHLY_COST',
  MEDICAL_DEDUCTIBLE: 'MEDICAL_DEDUCTIBLE',
  MORE: 'MORE',
  RESET: 'RESET'
};

const MONTHLY_COST_SORT_OPTION = {
  key: 'MONTHLY_COST',
  value: 'MONTHLY_COST',
  label: '',
  isSelected: true,
  analytics: 'sortMonthlyCostsMedicalPlansIos'
};

const MEDICAL_DEDUCTIBLE_SORT_OPTION = {
  key: 'MEDICAL_DEDUCTIBLE',
  value: 'MEDICAL_DEDUCTIBLE',
  label: '',
  isSelected: false,
  analytics: 'sortDeductibleMedicalPlansIos'
};

const SWITCH_FILTERS_KEYS = {
  RECOMMENDED: 'RECOMMENDED',
  MOST_POPULAR: 'MOST_POPULAR',
  HSA_COMPATIBLE: 'HSA_COMPATIBLE',
  SHOW_MY_DOCTOR: 'SHOW_MY_DOCTOR',
  SHOW_MY_MEDICATION: 'SHOW_MY_MEDICATION',
  EASY_PRICING: 'EASY_PRICING'
};

const SORT_OPTIONS = [MONTHLY_COST_SORT_OPTION, MEDICAL_DEDUCTIBLE_SORT_OPTION];

const FILTER_OPTIONS: InitialPlanFilters = {
  MONTHLY_COST: [
    {
      key: 'UP_TO_TWO_HUNDREAD',
      value: '0-200',
      label: '',
      isChecked: false,
      disabled: true,
      analytics: '200CostFilterMedicalPlansIos'
    },
    {
      key: 'TWO_HUNDREAD_TO_FOUR_FIFTY',
      value: '201-450',
      label: '',
      isChecked: false,
      disabled: true,
      analytics: '450CostFilterMedicalPlansIos'
    },
    {
      key: 'FOUR_FIFTY_TO_SEVEN_HUNDREAD',
      value: '451-700',
      label: '',
      isChecked: false,
      disabled: true,
      analytics: '700CostFilterMedicalPlansIos'
    },
    {
      key: 'SEVEN_HUNDREAD_TO_THOUSAND',
      value: '701-1000',
      label: '',
      isChecked: false,
      disabled: true,
      analytics: '1000CostFilterMedicalPlansIos'
    },
    {
      key: 'ABOVE_THOUSAND',
      value: '1000-null',
      label: '',
      isChecked: false,
      disabled: true,
      analytics: 'above1000CostFilterMedicalPlansIos'
    }
  ],
  MEDICAL_DEDUCTIBLES: [
    {
      key: 'MD_OPTION_ONE',
      value: '0-1500',
      label: '',
      isChecked: false,
      disabled: true,
      analytics: 'under1500DeductibleFilterMedicalPlansIos'
    },
    {
      key: 'MD_OPTION_TWO',
      value: '1501-3500',
      label: '',
      isChecked: false,
      disabled: true,
      analytics: '3500DeductibleFilterMedicalPlansIos'
    },
    {
      key: 'MD_OPTION_THREE',
      value: '3501-4500',
      label: '',
      isChecked: false,
      disabled: true,
      analytics: '4500DeductibleFilterMedicalPlansIos'
    },
    {
      key: 'MD_OPTION_FOUR',
      value: '4501-5500',
      label: '',
      isChecked: false,
      disabled: true,
      analytics: '4501DeductibleFilterMedicalPlansIos'
    },
    {
      key: 'MD_OPTION_FIVE',
      value: '5500-null',
      label: '',
      isChecked: false,
      disabled: true,
      analytics: '5500DeductibleFilterMedicalPlansIos'
    }
  ],
  MORE_FILTERS: {
    SWITCH_FILTERS: [
      { key: SWITCH_FILTERS_KEYS.RECOMMENDED, label: '', tooltipText: '', enabled: false, disabled: true },
      { key: SWITCH_FILTERS_KEYS.MOST_POPULAR, label: '', tooltipText: '', enabled: false, disabled: true },
      { key: SWITCH_FILTERS_KEYS.HSA_COMPATIBLE, label: '', tooltipText: '', enabled: false, disabled: true },
      { key: SWITCH_FILTERS_KEYS.SHOW_MY_DOCTOR, label: '', tooltipText: '', enabled: false, disabled: true },
      { key: SWITCH_FILTERS_KEYS.SHOW_MY_MEDICATION, label: '', tooltipText: '', enabled: false, disabled: true },
      { key: SWITCH_FILTERS_KEYS.EASY_PRICING, label: '', tooltipText: '', enabled: false, disabled: true }
    ],
    BENEFITS_TIER: [
      {
        key: 'CATASTROPHIC',
        value: PLAN_TIERS.CATASTROPHIC,
        label: '',
        isChecked: false,
        disabled: true,
        analytics: 'catastrophicFilterMedicalPlansIos'
      },
      {
        key: 'BRONZE',
        value: PLAN_TIERS.BRONZE,
        label: '',
        isChecked: false,
        disabled: true,
        analytics: 'bronzeFilterMedicalPlansIos'
      },
      {
        key: 'SILVER',
        value: PLAN_TIERS.SILVER,
        label: '',
        isChecked: false,
        disabled: true,
        analytics: 'silverFilterMedicalPlansIos'
      },
      {
        key: 'GOLD',
        value: PLAN_TIERS.GOLD,
        label: '',
        isChecked: false,
        disabled: true,
        analytics: 'goldFilterMedicalPlansIos'
      },
      {
        key: 'PLATINUM',
        value: PLAN_TIERS.PLATINUM,
        label: '',
        isChecked: false,
        disabled: true,
        analytics: 'platinumFilterMedicalPlansIos'
      }
    ]
  }
};

const APPLICANT_TYPES_FOR_PLAN_SUMMARY = [APPLICANT_TYPES.PRIMARY, APPLICANT_TYPES.SPOUSE, APPLICANT_TYPES.DEPENDENT];
const APPLICANT_TYPES_EXCLUDED_FOR_PLAN_SUMMARY_API = [
  APPLICANT_TYPES.PARENT,
  APPLICANT_TYPES.STEPPARENT,
  APPLICANT_TYPES.PARENTINLAW,
  APPLICANT_TYPES.CHILD,
  APPLICANT_TYPES.OTHER
];

const PLAN_TIERS_ALL = [PLAN_TIERS.ALL];
const PLAN_TIERS_NON_CATASTROPHIC = [PLAN_TIERS.GOLD, PLAN_TIERS.SILVER, PLAN_TIERS.PLATINUM, PLAN_TIERS.BRONZE];

const COVERED_FLAG = 'COVERED';

const QHP_TO_CSR_MAP = {
  '00': null,
  '1': 100,
  '2': null,
  '3': null,
  '4': 73,
  '5': 87,
  '6': 94
};

const EMEDDED_HEALTH_BENEFITS = {
  MED_EHB: 'MED_EHB',
  DEN_EHB: 'DEN_EHB',
  VIS_EHB: 'VIS_EHB'
};

const HSA_COMPATIBLE_VALUE = 'Yes';

const DEFAULT_MEDICAL_CURRENT_COVERAGE: Contract = {
  contractCode: '',
  contractEffectiveDate: '',
  tiersType: '',
  onExchange: false,
  poPlan: false,
  coloradoOptions: false,
  contractExpiryDate: '',
  planYear: '',
  ehbIndTypes: [],
  planMarketingName: '',
  avValue: '',
  rateData: {
    rate: {
      totalPremium: 0.0,
      totSubsidizedPrem: 0.0,
      subsidyAmtApplied: 0.0
    }
  },
  plan: [
    {
      planID: '',
      planDisplayName: '',
      planName: '',
      planType: '',
      costCoverages: [
        {
          coverageType: 'Cost',
          description: 'Deductible(s)',
          coverageValue: '0',
          coverageValueType: 'UNIT',
          coverageCode: 'MEDDEDUCTIBLEFAM'
        },
        {
          coverageType: 'Cost',
          description: 'Out of Pocket Max',
          coverageValue: '0',
          coverageValueType: 'UNIT',
          coverageCode: 'OOPMAXFAM'
        },
        {
          coverageType: 'Cost',
          description: 'HSA Compatible',
          coverageValue: '0',
          coverageValueType: 'UNIT',
          coverageCode: 'HSACOMPAT'
        }
      ],
      planBenefits: [
        {
          planBenefitDisplayName: 'Deductible(s)',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'SUMMARY',
          planBenefitSortOrder: 2,
          planBenefitName: 'Deductible(s)',
          planBenefitCode: 'MEDDEDUCTIBLEFAM'
        },
        {
          planBenefitDisplayName: 'Primary Care Visit',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'SUMMARY',
          planBenefitSortOrder: 5,
          planBenefitName: 'Primary Care Visit',
          planBenefitCode: 'PRIMOFFVIS'
        },
        {
          planBenefitDisplayName: 'Preventive Care/Screening/Immunization',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DETAIL',
          planBenefitSortOrder: 6,
          planBenefitName: 'Preventive Care/Screening/Immunization',
          planBenefitCode: 'PREVENTIVECARE'
        },
        {
          planBenefitDisplayName: 'Medical Chats and Virtual Visits (including Primary Care) from Our Online Provider K Health',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'SUMMARY',
          planBenefitSortOrder: 7,
          planBenefitName: 'Medical Chats and Virtual Visits (including Primary Care) from Our Online Provider K Health',
          planBenefitCode: 'KHEALTHCHAT'
        },
        {
          planBenefitDisplayName: 'Live Health Online',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'SUMMARY',
          planBenefitSortOrder: 8,
          planBenefitName: 'Live Health Online',
          planBenefitCode: 'LIVEHLTHONLN'
        },
        {
          planBenefitDisplayName: 'Specialist Visit',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'SUMMARY',
          planBenefitSortOrder: 9,
          planBenefitName: 'Specialist Visit',
          planBenefitCode: 'SPCLOFFVIS'
        },
        {
          planBenefitDisplayName: 'Out of Pocket Max',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'SUMMARY',
          planBenefitSortOrder: 11,
          planBenefitName: 'Out of Pocket Max',
          planBenefitCode: 'OOPMAXFAM'
        },
        {
          planBenefitDisplayName: 'Urgent Care Centers or Facilities',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DETAIL',
          planBenefitSortOrder: 12,
          planBenefitName: 'Urgent Care Centers or Facilities',
          planBenefitCode: 'OUTPATURGCARE'
        },
        {
          planBenefitDisplayName: 'Emergency Room Services',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'SUMMARY',
          planBenefitSortOrder: 13,
          planBenefitName: 'Emergency Room Services',
          planBenefitCode: 'EMERGENCYROOM'
        },
        {
          planBenefitDisplayName: 'Most Generic Drugs (Tier 1)',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'SUMMARY',
          planBenefitSortOrder: 14,
          planBenefitName: 'Most Generic Drugs (Tier 1)',
          planBenefitCode: 'GENPREDRUGS'
        },
        {
          planBenefitDisplayName: 'Most Preferred Brand Drugs (Tier 2)',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'SUMMARY',
          planBenefitSortOrder: 15,
          planBenefitName: 'Most Preferred Brand Drugs (Tier 2)',
          planBenefitCode: 'PREFPREDRUGS'
        },
        {
          planBenefitDisplayName: 'Most Non-Preferred Brand Drugs (Tier 3)',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'SUMMARY',
          planBenefitSortOrder: 16,
          planBenefitName: 'Most Non-Preferred Brand Drugs (Tier 3)',
          planBenefitCode: 'NONPREDRUGS'
        },
        {
          planBenefitDisplayName: 'Most Specialty Drugs (Tier 4)',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'SUMMARY',
          planBenefitSortOrder: 17,
          planBenefitName: 'Most Specialty Drugs (Tier 4)',
          planBenefitCode: 'SPCLPREDRUGS'
        },
        {
          planBenefitDisplayName: 'HSA Compatible',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'SUMMARY',
          planBenefitSortOrder: 18,
          planBenefitName: 'HSA Compatible',
          planBenefitCode: 'HSACOMPAT'
        },
        {
          planBenefitDisplayName: 'X-rays and Diagnostic Imaging',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DETAIL',
          planBenefitSortOrder: 19,
          planBenefitName: 'X-rays and Diagnostic Imaging',
          planBenefitCode: 'OTHERXRAYS'
        },
        {
          planBenefitDisplayName: 'Imaging (CT/PET Scans, MRIs)',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DETAIL',
          planBenefitSortOrder: 20,
          planBenefitName: 'Imaging (CT/PET Scans, MRIs)',
          planBenefitCode: 'OTHERIMGNG'
        },
        {
          planBenefitDisplayName: 'Durable Medical Equipment',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DETAIL',
          planBenefitSortOrder: 21,
          planBenefitName: 'Durable Medical Equipment',
          planBenefitCode: 'OTHERDURABLE'
        },
        {
          planBenefitDisplayName: 'Laboratory Outpatient and Professional Services',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DETAIL',
          planBenefitSortOrder: 22,
          planBenefitName: 'Laboratory Outpatient and Professional Services',
          planBenefitCode: 'OTHERLABRTRY'
        },
        {
          planBenefitDisplayName: 'Outpatient Surgery Physician/Surgical Services',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'SUMMARY',
          planBenefitSortOrder: 23,
          planBenefitName: 'Outpatient Surgery Physician/Surgical Services',
          planBenefitCode: 'SURGERYOUTPAT'
        },
        {
          planBenefitDisplayName: 'Outpatient Facility Fee (e.g.,  Ambulatory Surgery Center)',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DETAIL',
          planBenefitSortOrder: 24,
          planBenefitName: 'Outpatient Facility Fee (e.g.,  Ambulatory Surgery Center)',
          planBenefitCode: 'OUTPATFACLTY'
        },
        {
          planBenefitDisplayName: 'Outpatient Rehabilitation Services',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DETAIL',
          planBenefitSortOrder: 25,
          planBenefitName: 'Outpatient Rehabilitation Services',
          planBenefitCode: 'HABREHABOUTPAT'
        },
        {
          planBenefitDisplayName: 'Mental/Behavioral Health Outpatient Services',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DETAIL',
          planBenefitSortOrder: 26,
          planBenefitName: 'Mental/Behavioral Health Outpatient Services',
          planBenefitCode: 'MNTLHLTHOUTPAT'
        },
        {
          planBenefitDisplayName: 'Inpatient Physician and Surgical Services',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DETAIL',
          planBenefitSortOrder: 27,
          planBenefitName: 'Inpatient Physician and Surgical Services',
          planBenefitCode: 'SURGERYINPAT'
        },
        {
          planBenefitDisplayName: 'Inpatient Hospital Services (e.g., Hospital Stay)',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'SUMMARY',
          planBenefitSortOrder: 28,
          planBenefitName: 'Inpatient Hospital Services (e.g., Hospital Stay)',
          planBenefitCode: 'INPATIENTHOSPTL'
        },
        {
          planBenefitDisplayName: 'Mental/Behavioral Health Inpatient Services',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DETAIL',
          planBenefitSortOrder: 29,
          planBenefitName: 'Mental/Behavioral Health Inpatient Services',
          planBenefitCode: 'MNTLHLTHINPAT'
        },
        {
          planBenefitDisplayName: 'Prenatal and Postnatal Care',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DETAIL',
          planBenefitSortOrder: 30,
          planBenefitName: 'Prenatal and Postnatal Care',
          planBenefitCode: 'METERNITYPRENATAL'
        },
        {
          planBenefitDisplayName: 'Delivery and All Inpatient Services for Maternity Care',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DETAIL',
          planBenefitSortOrder: 31,
          planBenefitName: 'Delivery and All Inpatient Services for Maternity Care',
          planBenefitCode: 'METERNITYDELIVERY'
        },
        {
          planBenefitDisplayName: 'Pediatric Dental and Vision',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'SUMMARY',
          planBenefitSortOrder: 32,
          planBenefitName: 'Pediatric Dental and Vision',
          planBenefitCode: 'EMBDEDBNFT'
        },
        {
          planBenefitDisplayName: 'Spinal Manipulation',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DETAIL',
          planBenefitSortOrder: 33,
          planBenefitName: 'Spinal Manipulation',
          planBenefitCode: 'HABREHABCHIRPRACT'
        },
        {
          planBenefitDisplayName: 'Allergy Testing',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DETAIL',
          planBenefitSortOrder: 34,
          planBenefitName: 'Allergy Testing',
          planBenefitCode: 'ALRGYTSTING'
        },
        {
          planBenefitDisplayName: 'Emergency Transportation/Ambulance',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'DETAIL',
          planBenefitSortOrder: 35,
          planBenefitName: 'Emergency Transportation/Ambulance',
          planBenefitCode: 'EMERGENCYTRANS'
        },
        {
          planBenefitDisplayName: 'Blue Card (Out Of Area)',
          planBenefitValue: 'Refer to SBC',
          planBenefitType: 'SUMMARY',
          planBenefitSortOrder: 36,
          planBenefitName: 'Blue Card (Out Of Area)',
          planBenefitCode: 'BLUECARD'
        }
      ],
      productType: ''
    }
  ],
  providerNetworkId: '',
  qHPVariation: '',
  qHPId: '',
  companyDesc: '',
  gradeLevels: [],
  qhpvariation: '',
  pcp: false
};

export {
  APPLICANT_TYPES_EXCLUDED_FOR_PLAN_SUMMARY_API,
  APPLICANT_TYPES_FOR_PLAN_SUMMARY,
  COST_COVERAGE_CODES,
  COVERED_FLAG,
  DEFAULT_MEDICAL_CURRENT_COVERAGE,
  EMEDDED_HEALTH_BENEFITS,
  FILTER_OPTIONS,
  FILTER_TYPES,
  GRADE_LEVELS,
  HSA_COMPATIBLE_VALUE,
  MEDICAL_DEDUCTIBLE_SORT_OPTION,
  MEDICAL_PLANS_TABS,
  MONTHLY_COST_SORT_OPTION,
  PLAN_BENEFIT_CODES,
  PLAN_TIERS,
  PLAN_TIERS_ALL,
  PLAN_TIERS_NON_CATASTROPHIC,
  QHP_TO_CSR_MAP,
  SORT_OPTIONS,
  SWITCH_FILTERS_KEYS
};
