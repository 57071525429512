import { Button } from '@sydney-broker-ui/ios';
import { AxiosError, isAxiosError } from 'axios';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DateInput from '../../../../components/common/date-input/DateInput';
import FullPageLoader from '../../../../components/common/full-page-loader/FullPageLoader';
import InfoBar from '../../../../components/common/info-bar/InfoBar';

import { formatDateToMMDDYYY, getFormattedCurrentCoveragePlan, getPlanYear } from '../../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../../store/globalStore';
import { useUserStore } from '../../../../store/userStore';
import { validateFirstName, validateLastName } from '../../account-recovery/AccountRecoveryUtils';
import { getCurrentCoverageDentalPlan } from '../../dental-plans/dentalPlansUtils';
import { getCurrentCoverageMedicalPlan } from '../../medical-plans/medicalPlansUtils';
import { getCurrentCoverageVisionPlan } from '../../vision-plans/visionPlansUtils';
import { loginUserWithPhi } from './macLoginWithAnthemCardServices';
import { getMemberPHILoginSuccessPayload, getPhiLoginPayload, getPhiMemberInfo, validateDob, validateHcid } from './macLoginWithAnthemCardUtils';

import { HTTP_STATUS_CODES } from '../../../../api/constants';
import { generateTokenAPI, getBuildVersion } from '../../../../api/services/tokenService';
import { getZipcodeValidation } from '../../../../api/services/utilService';
import { DATE_FORMATS, ERROR_ALERT_CONFIG, PLAN_TYPES, PLAN_TYPES_ORDER, USER_DOMAINS, USER_ROLES } from '../../../../shared/globalConstants';
import { DEFAULT_DENTAL_CURRENT_COVERAGE } from '../../dental-plans/constants';
import { DEFAULT_MEDICAL_CURRENT_COVERAGE } from '../../medical-plans/constants';
import { DEFAULT_VISION_CURRENT_COVERAGE } from '../../vision-plans/constants';
import { getNavigationInfo } from '../login-modal/loginUtils';
import { PHI_LOGIN_ERROR_CODES, PHI_LOGIN_SUCCESS_CODES } from '../mac-login/constants';
import "./MacLoginWithAnthemCard.scss";


const MacLoginWithAnthemCard: React.FC<MacLoginWithAnthemCardProps> = ({ labels }) => {
    const [hcidnumber, setHcidnumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [hcidNumberError, setHcidNumberError] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [zipCodeError, setZipCodeError] = useState('');
    const [dobError, setDOBError] = useState('');
    const [enlargeImage, setEnlargeImage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState<PHILoginError>({
        hasError: false,
        responseCode: '',
        message: ''
    });

    const { updateMemberPHILoginResponse, updateLoginStatus, updateWebAccount, updateDemographicInfo } = useUserStore((state) => state);
    const { updateAgentInfo, updateMemberInfo, updateCurrentCoverageMedicalPlan, updateCurrentCoverageDentalPlan, updateCurrentCoverageVisionPlan, updateApplicantFormDetails,
        updateZipCodeResponse, updateCoverageDate, updateZipCode, updateCoverageType, updateZipCodeFormDetails, updateSelectedPlan, updateSelectedDentalPlan, updateSelectedVisionPlan, updatePlanTypes, subsidyresponse, updateEditZipCodeRes, updateEditZipCodeFormDetails, updateEditCoverageType, updateEditPlanTypes } = useGlobalStore((state) => state);
    const navigate = useNavigate();

    const toggleImageSize = () => {
        setEnlargeImage(!enlargeImage);
    }

    const handleHcidNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHcidNumberError('');
        setHcidnumber(e.target.value);
    }

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFirstNameError('');
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLastNameError('');
        setLastName(e.target.value);
    };

    const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setZipCodeError('');
        setZipCode(e.target.value);
    };
    const handleDateOfBirthChange = (dob) => {
        setDOBError('');
        if (dob) {
            const updatedDobValue = formatDateToMMDDYYY(dob);
            setDateOfBirth(updatedDobValue);
        } else {
            setDateOfBirth('');
        }
    };

    const checkHcid = () => {
        const { isValidHcid, hcidErrorMessage } = validateHcid(hcidnumber, labels);

        if (!isValidHcid) {
            setHcidNumberError(hcidErrorMessage);
        } else {
            setHcidNumberError('');
        }

        return isValidHcid;
    };

    const checkFirstName = () => {
        const { isValidFirstName, firstNameErrorMessage } = validateFirstName(firstName);

        if (!isValidFirstName) {
            setFirstNameError(firstNameErrorMessage);
        } else {
            setFirstNameError('');
        }

        return isValidFirstName;
    };

    const checkLastName = () => {
        const { isValidLastName, lastNameErrorMessage } = validateLastName(lastName);

        if (!isValidLastName) {
            setLastNameError(lastNameErrorMessage);
        } else {
            setLastNameError('');
        }

        return isValidLastName;
    };

    const checkZipCode = () => {
        if (zipCode?.length === 0) {
            setZipCodeError(labels.REQUIRED_FIELD);
            return false;
        } else {
            setZipCodeError('');
            return true;
        }
    };

    const checkDob = (date: any, isContinueClicked = false) => {
        const { isValidDob, dobErrorMessage } = validateDob(date?.target?.value ? date?.target?.value : dateOfBirth, isContinueClicked, labels);

        if (!isValidDob) {
            setDOBError(dobErrorMessage);
        } else {
            setDOBError('');
        }

        return isValidDob;
    };

    const resetErrors = () => {
        setHcidNumberError('')
        setFirstNameError('');
        setLastNameError('');
        setDOBError('');
        setLoginError({
            hasError: false,
            message: '',
            responseCode: ''
        });
    };

    const validateCredentials = () => {
        resetErrors();
        const isValidHcid = checkHcid();
        const isValidFirstName = checkFirstName();
        const isValidLastName = checkLastName();
        const isValidZipCode = checkZipCode();
        const isValidDob = checkDob(dateOfBirth, true);

        if (isValidHcid && isValidFirstName && isValidLastName && isValidZipCode && isValidDob) {
            initiatePhiLogin();
        }
    }

    const getErrorMessage = () => (
        <>
            {labels?.AUTHENTICATION_FAILED} {labels.OR}, {' '}
            <span className="link-text" onClick={() => navigate('/individuals')}>
                {labels.CONTINUE_WITHOUT_LOGGING_IN}
            </span>
        </>
    );

    const getSystemExceptionErrorMessage = () => (
        <>
            <b>{labels?.SYSTEM_EXCEPTION_HEADING} </b> <br /> {labels?.SYSTEM_EXCEPTION_MESSAGE}
        </>
    );


    const handleCurrentCoverages = (currentCoverages: CurrentCoverages) => {
        if (currentCoverages) {
            const currentCoverageMedicalPlan = getCurrentCoverageMedicalPlan(currentCoverages)
            const currentCoverageDentalPlan = getCurrentCoverageDentalPlan(currentCoverages)
            const currentCoverageVisionPlan = getCurrentCoverageVisionPlan(currentCoverages)

            if (currentCoverageMedicalPlan?.contractCode) {
                const formattedCurrentCoverageMedicalPlan = getFormattedCurrentCoveragePlan(DEFAULT_MEDICAL_CURRENT_COVERAGE, currentCoverageMedicalPlan)
                updateCurrentCoverageMedicalPlan(formattedCurrentCoverageMedicalPlan, currentCoverageMedicalPlan?.mappedContractCode ?? '')
            }
            if (currentCoverageDentalPlan?.contractCode) {
                const formattedCurrentCoverageDentalPlan = getFormattedCurrentCoveragePlan(DEFAULT_DENTAL_CURRENT_COVERAGE, currentCoverageDentalPlan)
                updateCurrentCoverageDentalPlan(formattedCurrentCoverageDentalPlan, currentCoverageDentalPlan?.mappedContractCode ?? '')
            }
            if (currentCoverageVisionPlan?.contractCode) {
                const formattedCurrentCoverageVisionPlan = getFormattedCurrentCoveragePlan(DEFAULT_VISION_CURRENT_COVERAGE, currentCoverageVisionPlan)
                updateCurrentCoverageVisionPlan(formattedCurrentCoverageVisionPlan, currentCoverageVisionPlan?.mappedContractCode ?? '')
            }
        }
    }

    const getToken = async (): Promise<any> => {
        return getBuildVersion().then((response) => {
            if (response.status === 200) {
                generateTokenAPI()
                    .then((response) => {
                        const data: any = response.data;
                        if (data && data.token) {
                            useUserStore.getState().updateJWTToken({ jwtToken: data.token });
                        } else {
                            setLoginError({
                                hasError: true,
                                responseCode: data.responseMessage.responseCode,
                                message: data.responseMessage.message
                            });
                        }
                    })
                    .catch((error: AxiosError<SendtOTPError>) => {
                        console.warn('MacLoginWithAnthemCard.tsx genearte token response :', error);
                        if (isAxiosError(error)) {
                            const errorObj = error.response?.data?.error;

                            setLoginError({
                                hasError: true,
                                responseCode: errorObj?.errorKey ? errorObj?.errorKey : '',
                                message: getSystemExceptionErrorMessage()
                            });
                        } else {
                            setLoginError({
                                hasError: true,
                                responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
                                message: getSystemExceptionErrorMessage()
                            });
                        }
                        setLoading(false);
                    });
            }
        })
            .catch((error: AxiosError<SendtOTPError>) => {
                console.warn('MacLoginWithAnthemCard.tsx getBuildVersion response :', error);
                if (isAxiosError(error)) {
                    const errorObj = error.response?.data?.error;

                    setLoginError({
                        hasError: true,
                        responseCode: errorObj?.errorKey ? errorObj?.errorKey : '',
                        message: getSystemExceptionErrorMessage()
                    });
                } else {
                    setLoginError({
                        hasError: true,
                        responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
                        message: getSystemExceptionErrorMessage()
                    });
                }
                setLoading(false);
            });

    };

    const processDemographicInfo = async (demographicInfo: DemographicInfo) => {
        const response = await getZipcodeValidation({ zipCode: demographicInfo?.zipCode || '', marketSegment: USER_DOMAINS.MARKET_SEGMENT });
        const planYear = getPlanYear(response.data.zipCodeResponse?.zipCode?.coverageEffectiveDate?.coverageDt, demographicInfo?.coverageTypeFlag || '', response.data.zipCodeResponse?.zipCode?.currentDateTimeInZone);
        const county = response.data.zipCodeResponse?.zipCode?.countyList?.county[0].countyName;
        updateZipCodeResponse(response.data.zipCodeResponse.zipCode);
        updateZipCodeFormDetails(
            demographicInfo?.zipCode || '',
            demographicInfo?.county ?? county,
            planYear
        );
        updatePlanTypes(demographicInfo?.county ?? county, planYear);
        updateApplicantFormDetails(demographicInfo?.applicant || []);
        updateEditZipCodeRes(response.data.zipCodeResponse.zipCode);
        updateEditZipCodeFormDetails(demographicInfo?.zipCode || '',
            demographicInfo?.county ?? county,
            planYear);
        updateEditPlanTypes(demographicInfo?.county ?? county, planYear);
    }

    const populateUserDetails = async (loginData: PhiLoginResponse) => {
        if (loginData.member?.demographicInfo) {
            await processDemographicInfo(loginData.member.demographicInfo)
            const updateWA = {
                webAccountGUID: '',
                shopperId: '',
                webAccessId: ''
            };
            loginData.member?.agentOfRecord && updateAgentInfo(loginData.member.agentOfRecord);
            // updateLoginStatus(true);
            // await getToken(); //need to check if this is needed
            updateWebAccount(updateWA);
            updateDemographicInfo(loginData.member?.demographicInfo);
            const sortedPlanTypes = loginData.member?.currentCoverages.sort((a, b) => PLAN_TYPES_ORDER[a.planType] - PLAN_TYPES_ORDER[b.planType]);
            updateCoverageType(loginData.member.currentCoverages.length > 0 ? PLAN_TYPES[sortedPlanTypes[0].planType] : '');
            updateMemberInfo(getPhiMemberInfo(loginData));
            handleCurrentCoverages(loginData.member?.currentCoverages);
        } else {
            setLoginError({
                hasError: true,
                responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
                message: getSystemExceptionErrorMessage()
            });

            setLoading(false);
        }
    };

    const initiatePhiLogin = () => {
        setLoading(true);
        const payload: PhiLoginPayload = getPhiLoginPayload(hcidnumber, firstName, lastName, dateOfBirth, zipCode);
        loginUserWithPhi(payload)
            .then(async (response) => {
                const data: PhiLoginResponse = response.data;
                setLoading(false);
                if (response.status === HTTP_STATUS_CODES.SUCCESS && data.responseMessage.responseCode === PHI_LOGIN_SUCCESS_CODES.PHI_LOGIN_SUCCESS) {
                    //login success                   
                    updateMemberPHILoginResponse(getMemberPHILoginSuccessPayload(data));
                    // await getToken();
                    await populateUserDetails(data);
                    let getURL = await getNavigationInfo(data.member?.demographicInfo?.demographicId, USER_ROLES.MEMBER);
                    setLoading(false);
                    navigate(getURL);
                } else {
                    if (data.responseMessage.responseCode === PHI_LOGIN_ERROR_CODES.PHI_LOGIN_AUTHENTICATION_FAILED) {
                        setLoginError({
                            hasError: true,
                            responseCode: data.responseMessage.responseCode,
                            message: getErrorMessage()
                        });
                    } else {
                        setLoginError({
                            hasError: true,
                            responseCode: data.responseMessage.responseCode,
                            message: getSystemExceptionErrorMessage()
                        });
                    }
                }
            })
            .catch((error: AxiosError<ValidateOTPError>) => {
                console.warn('MacLoginWithAnthemCard.tsx validateOTPAndLogin error :', error);
                setLoginError({
                    hasError: true,
                    responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
                    message: getSystemExceptionErrorMessage()
                });

                setLoading(false);
            });
    }

    return (
        <div className="mac-login-with-card-form-wrapper">
            {loading && <FullPageLoader />}
            <div className="card-image-input-wrapper">
                <div className={'fwc-input hcid-input-container'}>
                    <label id="mac-login-card-number-label" className="fwc-label" htmlFor={'mac-login-card-number-input'}>
                        {labels.NINE_DIGIT_NUMBER} <br /> <span className="hcid-helper-text">{labels.DO_NOT_INCLUDE}</span>
                    </label>
                    <input data-testid="mac-hcid-input" id="mac-login-card-number-input" className="input-shadow" type="text" value={hcidnumber} onChange={(e) => handleHcidNumber(e)} maxLength={12} />
                    {hcidNumberError?.length > 0 && (
                        <span id="mac-login-card-number-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                            {hcidNumberError}
                        </span>
                    )}
                </div>
                <div className="hcidcard-image-wrapper">
                    <span className="enlarge-option-text" onClick={toggleImageSize}>{enlargeImage ? labels.REDUCE_ID_CARD_IMAGE : labels.ENLARGE_ID_CARD_IMAGE}</span>
                    <img src={enlargeImage ? labels?.HCID_CARD_ENLARGED : labels?.HCID_CARD} className={`hcidcard-image ${enlargeImage ? "enlarged-image" : ""}`} id={'hcid-image'} alt="" />
                </div>
            </div>
            <div className={'fwc-input card-input-container'}>
                <label id="mac-login-firstname-label" className="fwc-label" htmlFor={'mac-login-firstname-input'}>
                    {labels.FIRST_NAME} <span className="from-card">{labels.FROM_CARD}</span>
                </label>
                <input data-testid="mac-first-name-input" id="mac-login-firstname-input" className="input-shadow" type="text" value={firstName} onChange={(e) => handleFirstNameChange(e)} />
                {firstNameError?.length > 0 && (
                    <span id="mac-login-firstname-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {firstNameError}
                    </span>
                )}
            </div>
            <div className={'fwc-input card-input-container'}>
                <label id="mac-login-lastname-label" className="fwc-label" htmlFor={'mac-login-lastname-input'}>
                    {labels.LAST_NAME} <span className="from-card">{labels.FROM_CARD}</span>
                </label>
                <input data-testid="mac-last-name-input" id="mac-login-lastname-input" className="input-shadow" type="text" value={lastName} onChange={(e) => handleLastNameChange(e)} />
                {lastNameError?.length > 0 && (
                    <span id="mac-login-lastname-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {lastNameError}
                    </span>
                )}
            </div>
            <div className="zipcode-dob-input-wrapper">
                <div className={'fwc-input mac-zipcode-input-container'}>
                    <label id="mac-login-zipcode-label" className="fwc-label" htmlFor={'mac-login-zipcode-input'}>
                        {labels.ZIP_CODE}
                    </label>
                    <input data-testid="mac-zipcode-input" id="mac-login-zipcode-input" className="input-shadow" type="text" value={zipCode} onChange={(e) => handleZipCodeChange(e)}
                        onInput={(e) => {
                            e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '');
                        }}
                        maxLength={5} />
                    {zipCodeError?.length > 0 && (
                        <span id="mac-login-zipcode-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                            {zipCodeError}
                        </span>
                    )}
                </div>
                <div className={'fwc-input mac-dob-input-container'}>
                    <label id="mac-login-dob-label" className="fwc-label" htmlFor={'mac-login-dob-input'}>
                        {labels.DATE_OF_BIRTH} <span className="mac-dob-helper-text">{labels.DOB_FORMAT}</span>
                    </label>
                    <DateInput
                        className="mac-login-dob-input"
                        data-testid="mac-login-dob-input"
                        id="mac-login-dob-input"
                        name="dob"
                        defaultDate={dateOfBirth}
                        maxDate={dayjs().format(DATE_FORMATS.YYYYMMDD)}
                        onDateChange={handleDateOfBirthChange}
                    />
                    {dobError && (
                        <span id="mac-login-dob-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                            {dobError}
                        </span>
                    )}
                </div>
            </div>
            {loginError.hasError && !loading && (
                <InfoBar
                    area={ERROR_ALERT_CONFIG.AREA}
                    labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
                    type={ERROR_ALERT_CONFIG.ERROR}
                    handleClose={() => {
                        setLoginError({
                            hasError: false,
                            message: '',
                            responseCode: ''
                        });
                    }}
                >
                    <p className="message">{loginError.message}</p>
                </InfoBar>
            )}
            <div className='mac-continue-button-container'>
                <Button onClick={() => { validateCredentials() }}>
                    {labels?.CONTINUE}
                </Button>
            </div>
        </div>
    )
}

export default MacLoginWithAnthemCard