import { EditableComponent, MapTo } from '@adobe/aem-react-editable-components';
import React from 'react';
import { withConditionalPlaceHolder } from '../core/util/withConditionalPlaceholder';
import { withStandardBaseCssClass } from '../core/util/withStandardBaseCssClass';
import InfoBarText, { InfoBarTextIsEmpty } from './InfoBarText';

const RESOURCE_TYPE = 'ios/components/infobar';

const EditConfig = {
  emptyLabel: 'Info Bar Text',
  isEmpty: InfoBarTextIsEmpty,
  resourceType: RESOURCE_TYPE
};

export const WrappedText = (props) => {
  const Wrapped = withConditionalPlaceHolder(withStandardBaseCssClass(InfoBarText, 'infobar-text'), InfoBarTextIsEmpty, 'empty-text');
  return <Wrapped {...props} />;
};

const EditableInfoBarText = (props) => (
  <EditableComponent config={EditConfig} {...props}>
    <WrappedText />
  </EditableComponent>
);

MapTo(RESOURCE_TYPE)(EditableInfoBarText);

export default EditableInfoBarText;
