import dayjs from 'dayjs';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';

const getlink = (lan: string, footerLinks: any) => {
  const links = footerLinks;
  let linkobj;
  const selectedYear = useGlobalStore.getState().selectedYear.length ? useGlobalStore.getState().selectedYear : dayjs().year().toString();
  if (!useUserStore.getState().isWellpoint && !useGlobalStore.getState().agentSSOInfo.isAtk) {
    switch (lan) {
      case 'chinese':
        linkobj = links.CHINESE[selectedYear];
        break;
      case 'vietnamese':
        linkobj = links.VIETNAMESE[selectedYear];
        break;
      case 'korean':
        linkobj = links.KOREAN[selectedYear];
        break;
      case 'tagalog':
        linkobj = links.TAGALOG[selectedYear];
        break;
      case 'spanish':
        linkobj = links.SPANISH[selectedYear];
        break;
      default:
        linkobj = {};
    }
    return linkobj;
  } else {
    return {};
  }
};

export { getlink };
