import React, {useCallback} from "react";
import './InfoBar.scss';
import {Alert} from "@sydney-broker-ui/ios";

function InfoBar(props) {
    const handleClose = useCallback(e => props.onClose?.(e), [props.onClose]);
    return (
        <span>
            <Alert
                labelAriaCloseBtn={props.closeBtn}
                type={props.type}
                onClose={handleClose}
                backgroundColor={props.backgroundColor}
                area={props.area}
            >
                {props.children}
    </Alert>
    </span>
    );
}
export default InfoBar;
