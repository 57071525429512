import { EditableComponent, MapTo } from '@adobe/aem-react-editable-components';
import React from 'react';
import { withConditionalPlaceHolder } from '../core/util/withConditionalPlaceholder';
import { withStandardBaseCssClass } from '../core/util/withStandardBaseCssClass';
import Questioncards, { QuestioncardsIsEmpty } from './QuestionCards';

const RESOURCE_TYPE = 'ios/components/homepagequestioncards';

const EditConfig = {
  emptyLabel: 'Question Component',
  isEmpty: QuestioncardsIsEmpty,
  resourceType: RESOURCE_TYPE
};

export const WrappedQuote = (props) => {
  const Wrapped = withConditionalPlaceHolder(withStandardBaseCssClass(Questioncards, 'Question-container'), QuestioncardsIsEmpty, 'Containerv2');
  return <Wrapped {...props} />;
};

const EditableQuestioncards = (props) => (
  <EditableComponent config={EditConfig} {...props}>
    <WrappedQuote />
  </EditableComponent>
);

MapTo(RESOURCE_TYPE)(EditableQuestioncards);

export default EditableQuestioncards;
