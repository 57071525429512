const DELETE_PAYLOAD_INPUT = {
  CANCELREASON: 'application cancelled to start new quote',
  PARTNETID: 'OLS'
};

const CONTINUE_APPLICATION = {
  ACN: 'acn',
  SOURCE: 'source',
  ACAREFRESH: 'acaRefresh',
  OFFEXCHANGE: 'OFFEXCHANGE',
  ONEXCHANGE: 'ONEXCHANGE',
  KY: 'KY'
};

export { CONTINUE_APPLICATION, DELETE_PAYLOAD_INPUT };
