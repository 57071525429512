import { css } from 'lit';

const breakpoints = {
  md: 900,
  sm: 600
};

const baseUnit = 8;

const fwcConfig = {
  assetsPath: '/individuals/'
};
const sharedStyles = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  :host {
    display: block;
    width: 100%;
    line-height: var(--default-line-height);
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  button:focus,
  a:focus,
  .fwc-toggle input:focus + label,
  .fwc-select-list select:focus,
  .fwc-radio input:focus,
  .fwc-input input:focus,
  input:focus + label,
  .fwc-checkbox input:focus + label:before,
  .fwc-carousel-generic-wrapper .fwc-list-cont:focus,
  div.tip-btn:focus {
    outline: var(--fwc-focus);
  }
`;
class IosFwcBase {
  constructor(name, component) {
    this.element = customElements.get(name);
    this.name = name;
    this.component = component;
  }

  build() {
    if (!this.element) {
      customElements.define(this.name, this.component);
    }
  }
}
export { baseUnit, breakpoints, fwcConfig, IosFwcBase, sharedStyles };
