import React from 'react'

import './ShopperTabs.scss'

const ShopperTabs: React.FC<ShopperTabsProps> = ({ tabs, activeTab, onChange }) => {
    return (
        <div className="tabs-container" id="shopper-tabs">
            {tabs.map(tab => (
                <div
                    id="shopper-tab-button"
                    key={tab.value}
                    className={`tab ${activeTab === tab.value ? 'active' : ''}`}
                    onClick={() => onChange(tab.value)}
                >
                    <span>{tab.label}</span>

                </div>
            ))}
        </div>
    )
}


export default ShopperTabs