const HTTP_STATUS_CODES = {
  SUCCESS: 200,
  NO_CONTENT: 204,
  REDIRECTION: 300,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
};

const STATUSES = {
  SUCCESS: 'SUCCESS',
};

export { HTTP_STATUS_CODES, STATUSES };
