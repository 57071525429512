import axiosInstance from '../../../api/axiosWrapper/axiosWrapper';

import { API_NAME_LIST } from '../../../api/api-name-list';

const saveAgentQuote = (payload: AgentQuoteRequestPayload) => {
  return axiosInstance.post(API_NAME_LIST.SAVE_AGENT_QUOTE, payload);
};

const atkSaveProposal = (payload: ATKSaveProposalRequestPayload) => {
  return axiosInstance.post(API_NAME_LIST.ATK_SAVE_PROPOSAL, payload);
};

export { atkSaveProposal, saveAgentQuote };
