import { Button, ModalSlide } from '@sydney-broker-ui/ios';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { getZipcodeValidation } from '../../../api/services/utilService';
import FindYourDoctorForm from '../../../components/common/find-your-doctor-form/FindYourDoctorForm';
import FindYourDoctorList from '../../../components/common/find-your-doctor-list/FindYourDoctorList';
import FindYourDoctoSelectedDoctors from '../../../components/common/find-your-doctor-selected-doctors/FindYourDoctoSelectedDoctors';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import InfoBar from '../../../components/common/info-bar/InfoBar';
import NoResultsFound from '../../../components/common/no-results-found/NoResultsFound';
import { ERROR_ALERT_CONFIG, LANGUAGE_CODES, SCREEN_NAMES, USER_DOMAINS, US_ZIP_CODE_REGX, ZIP_CODE_VALIDATION_SUCCESS_CODE } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import {
  ALPHA_REGX_WITH_NONUM,
  LOCATED_WITHIN_TYPES,
  PROVIDER_TYPES_DENTAL,
  PROVIDER_TYPE_USECASES,
  SECONDARY_BUTTON_HIDDEN_STATES,
  SLIDER_STATES,
  SPECIALITIES
} from '../find-your-doctor/constants';
import { default as Content } from './findYourDentist.json';
import { searchDentists } from './findYourDentistServices';
import {
  getDefaultSpecialisationValueByProviderType,
  getLocatedWithin,
  getPrimaryButtonText,
  getProviderTypes,
  getSearchProviderPayload,
  getSpecialisationByProviderType,
  getSpecialisationLabel,
  getSpecialities,
  getSpecialityDetails,
  getUpdatedSelectedProviders
} from './findYourDentistUtils';


const FindYourDentistSlider: React.FC<FindYourDentistProps> = ({ showFindYourDentist, contractCodes, closeFindYourDentist }) => {
  const [sliderState, setSliderState] = useState(SLIDER_STATES.SEARCH_DOCTORS);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [providerType, setProviderType] = useState('');
  const [providerTypesDental, setProviderTypesDental] = useState<SelectOptionsString[]>(PROVIDER_TYPES_DENTAL);
  const [specialisationTypes, setSpecialisationTypes] = useState<SelectOptionsString[]>([]);
  const [specialisation, setSpecialisation] = useState('');
  const [showSpecialityDetails, setShowSpecialityDetails] = useState(false);
  const {
    zipcode,
    state,
    brand,
    zipCodeRes,
    county,
    countyCode,
    year,
    planTypes,
    updateCoverageDate,
    coverageDates,
    coverageDate,
    updateCoverageType,
    savedDentists,
    agentSSOInfo,
    agentZipcode,
    agentZipCodeRes,
    agentState,
    agentBrand,
    agentCoverageDate,
    updateSavedDentists,
    updateMatchDentistsToPlansStatus
  } = useGlobalStore((store) => store);

  const { isAtk } = agentSSOInfo
  const zipCodeValue = isAtk ? agentZipcode : zipcode;
  const zipCodeResponse = isAtk ? agentZipCodeRes : zipCodeRes;
  const stateCode = isAtk ? agentState : state;
  const brandValue = isAtk ? agentBrand : brand;
  const coverageEffectiveDate = isAtk ? agentCoverageDate : coverageDate;

  const [locatedWithin, setLocatedWithin] = useState('20');
  const [zipCode, setZipCode] = useState(zipCodeValue);
  const [zipCodeObj, setZipCodeObj] = useState<ZipcodeObject>({
    code: zipCodeValue,
    state: stateCode,
    latitude: zipCodeResponse.latitude.toString(),
    longitude: zipCodeResponse.longitude.toString(),
    brand: brandValue
  });
  const [validZipCode, setValidZipCode] = useState(true);
  const [zipCodeError, setZipCodeError] = useState('');
  const [metaData, setMetaData] = useState({
    totalElements: 0,
    pageNumber: '1',
    totalPageNumbers: '1'
  });
  const [providers, setProviders] = useState<Provider[]>([]);
  const [selectedProviders, setSelectedProviders] = useState<Provider[]>([]);
  const [showFooterError, setShowFooterError] = useState(false);
  const [showPageLoader, setShowPageLoader] = useState(false);
  const [zipCodeLoader, setZipCodeLoader] = useState(false);
  const [showHelptip, setShowHelptip] = useState(false);
  const [helpTipContent, setHelpTipContent] = useState('');
  const [locatedWithinTypes, setLocatedWithinTypes] = useState(LOCATED_WITHIN_TYPES);
  const [pageContent, setContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [specialities, setSpecialities] = useState<Specialities>(SPECIALITIES);

  const updateSpecialisations = useCallback(() => {
    if (pageContent) {
      const updatedSpecialisations = getSpecialisationByProviderType(providerType, providerTypesDental, specialities);
      setSpecialisationTypes(updatedSpecialisations);
      setSpecialisation(getDefaultSpecialisationValueByProviderType(providerType, providerTypesDental, specialities));
    }

  }, [providerType]);

  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.FIND_YOUR_DENTIST);
      let cmsResponse = response.data;
      content = cmsResponse.data.iospagecontentList.items[0].pageContent.FINDYOURDENTIST;
    } catch (error) {
      content = Content.data.iospagecontentList.items[0].pageContent.FINDYOURDENTIST;

      return false;
    } finally {
      setLocatedWithinTypes(getLocatedWithin(LOCATED_WITHIN_TYPES, content.LOCATED_WITHIN_TYPES));
      setProviderTypesDental(getProviderTypes(PROVIDER_TYPES_DENTAL, content));
      setProviderType(providerTypesDental[0].value);
      setSpecialities(getSpecialities(SPECIALITIES, content.SPECIALIZATION_TYPES, content.SPECIALISATION_DESCRIPTIONS));
      setSpecialisation(specialities.ALL.label);
      setContent(content);
      setContentLoaded(true);
    }
    return true;
  }, []);

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    initiate();
  }, [showFindYourDentist]);

  const resetFilters = useCallback(() => {
    setName('');
    setProviderType(providerTypesDental[0].value);
    setSpecialisation(getDefaultSpecialisationValueByProviderType(providerTypesDental[0].value, providerTypesDental, specialities));
    setLocatedWithin('20');
    setZipCode(zipCodeValue);
    setZipCodeObj({
      code: zipCodeValue,
      state: stateCode,
      latitude: zipCodeResponse.latitude.toString(),
      longitude: zipCodeResponse.longitude.toString(),
      brand: brandValue
    });
    setMetaData({
      totalElements: 0,
      pageNumber: '1',
      totalPageNumbers: '1'
    });
    setProviders([]);
    setSelectedProviders([]);
  }, []);

  const resetErrors = useCallback(() => {
    setNameError('');
    setZipCodeError('');
  }, []);

  useEffect(() => {
    if (showFindYourDentist && savedDentists.length > 0) {
      setSliderState(SLIDER_STATES.DOCTORS_SELECTED);
    } else if (pageContent && showFindYourDentist && sliderState === SLIDER_STATES.SEARCH_DOCTORS) {
      resetFilters();
      resetErrors();
    }
  }, [resetErrors, resetFilters, showFindYourDentist]);

  useEffect(() => {
    if (pageContent) {
      updateSpecialisations();
    }
  }, [providerType, updateSpecialisations]);

  const handleSliderClose = useCallback(() => {
    closeFindYourDentist();
  }, [closeFindYourDentist]);

  const handleSearch = useCallback(
    (pageNumber = metaData.pageNumber) => {
      //Make an API call to get list of doctors
      setShowPageLoader(true);
      const payload: SearchDentistsPayload = getSearchProviderPayload(
        pageNumber,
        zipCodeObj,
        providerType,
        locatedWithin,
        name,
        contractCodes,
        specialisation,
        coverageEffectiveDate
      );

      searchDentists(payload)
        .then((response) => {
          const data: SearchDentistResponse = response.data;
          const providerList = data.providerList;

          if ((!providerList || providerList.length === 0) && pageNumber === '1') {
            setSliderState(SLIDER_STATES.DOCTOR_LIST_UNAVAILABLE);
          } else {
            setMetaData({
              totalElements: parseInt(data.totalRowCount),
              pageNumber: data.requestPageNumber,
              totalPageNumbers: data.totalPages
            });
            setProviders(providerList);
            setSliderState(SLIDER_STATES.DOCTOR_LIST_AVAILABLE);
          }

          setShowPageLoader(false);
        })
        .catch((error) => {
          console.warn('searchProviders error :', error);
          setSelectedProviders([]);
          setSliderState(SLIDER_STATES.DOCTOR_LIST_UNAVAILABLE);
          setShowPageLoader(false);
        });
    },
    [locatedWithin, metaData.pageNumber, name, providerType, specialisation, zipCodeObj]
  );

  const handlePgination = useCallback(
    (pageDetails: PaginationChangeDetails) => {
      handleSearch(pageDetails.activePage.toString());
    },
    [handleSearch]
  );

  const validateZipCode = useCallback(async (): Promise<boolean> => {
    if (validZipCode) {
      return Promise.resolve(true);
    } else {
      if (US_ZIP_CODE_REGX.test(zipCode)) {
        setZipCodeLoader(true);

        const payload: ZipCodeValidationPayload = {
          zipCode: zipCode,
          marketSegment: USER_DOMAINS.MARKET_SEGMENT
        };

        try {
          const response = await getZipcodeValidation(payload);
          const data: ZipCodeValidationResponse = response.data;

          if (data.zipCodeResponse.responseMessage.responseCode === ZIP_CODE_VALIDATION_SUCCESS_CODE) {
            const { zipCodeResponse } = data;
            setZipCodeObj({
              code: zipCode,
              state: zipCodeResponse.zipCode.stateCode,
              latitude: zipCodeResponse.zipCode.latitude.toString(),
              longitude: zipCodeResponse.zipCode.longitude.toString(),
              brand: zipCodeResponse.zipCode.countyList.county[0].brand
            });
            setValidZipCode(true);
            setZipCodeLoader(false);
            setZipCodeError('');

            return true;
          } else {
            setZipCodeError(pageContent?.INVALID_ZIPCODE);
            setZipCodeLoader(false);

            return false;
          }
        } catch (error) {
          setZipCodeError(pageContent?.INVALID_ZIPCODE);
          setZipCodeLoader(false);

          return false;
        }
      } else {
        if (zipCode.length === 0) {
          setZipCodeError(pageContent?.REQUIRED_FIELD);
        } else {
          setZipCodeError(pageContent?.INVALID_ZIPCODE);
        }

        return Promise.resolve(false);
      }
    }
  }, [validZipCode, zipCode]);

  const validateName = useCallback(
    (doctor_name) => {
      let name = doctor_name;
      if (name.length === 0) {
        setNameError('');
        return true;
      } else {
        if (name.length < 3 && name.length > 0) {
          setNameError(pageContent?.INVALID_NAME);
          return false;
        } else if (name.length > 0 && !ALPHA_REGX_WITH_NONUM.test(name)) {
          setNameError(pageContent?.INVALID_NAME_DIGIT);
          return false;
        } else {
          setNameError('');
          return true;
        }
      }
    },
    [name]
  );
  const validateInputs = useCallback(async () => {
    const isValidZipCode = await validateZipCode();
    const isValidName = validateName(name);

    if (isValidZipCode && isValidName) {
      handleSearch();
    }
  }, [validateZipCode, handleSearch, validateName]);

  const handleSecondaryButtonClick = useCallback(() => {
    switch (sliderState) {
      case SLIDER_STATES.DOCTOR_LIST_AVAILABLE:
      case SLIDER_STATES.DOCTORS_SELECTED:
        setSliderState(SLIDER_STATES.SEARCH_DOCTORS);
        break;
      default:
        break;
    }
  }, [sliderState]);

  const addDentistsToMyList = useCallback(() => {
    if (selectedProviders.length > 0) {
      const updatedSavedProviders = Array.from(new Set([...savedDentists, ...selectedProviders]));

      updateSavedDentists(updatedSavedProviders);
      setShowFooterError(false);
      setSliderState(SLIDER_STATES.DOCTORS_SELECTED);
    } else {
      setShowFooterError(true);
    }
  }, [savedDentists, selectedProviders, updateSavedDentists]);

  const matchDoctorsWithPlans = useCallback(() => {
    updateMatchDentistsToPlansStatus(false);

    if (savedDentists.length) {
      updateMatchDentistsToPlansStatus(true);
    } else {
      setSliderState(SLIDER_STATES.SEARCH_DOCTORS);
      updateMatchDentistsToPlansStatus(false);
    }

    handleSliderClose();
  }, [handleSliderClose, savedDentists.length, updateMatchDentistsToPlansStatus]);

  const handlePrimaryButtonClick = useCallback(() => {
    switch (sliderState) {
      case SLIDER_STATES.SEARCH_DOCTORS:
        validateInputs();
        break;
      case SLIDER_STATES.DOCTOR_LIST_UNAVAILABLE:
        setSliderState(SLIDER_STATES.SEARCH_DOCTORS);
        break;
      case SLIDER_STATES.DOCTOR_LIST_AVAILABLE:
        addDentistsToMyList();
        break;
      case SLIDER_STATES.DOCTORS_SELECTED:
        matchDoctorsWithPlans();
        break;
      default:
        break;
    }
  }, [addDentistsToMyList, sliderState, validateInputs]);

  const handleSelectionChange = useCallback(
    (provider: Provider, isSelected: boolean) => {
      setSelectedProviders(getUpdatedSelectedProviders(provider, isSelected, selectedProviders));
    },
    [selectedProviders]
  );

  const handleRemove = useCallback(
    (provider: Provider, isSelected: boolean) => {
      updateSavedDentists(getUpdatedSelectedProviders(provider, isSelected, savedDentists));
    },
    [savedDentists, updateSavedDentists]
  );
  const handleInputClick = useCallback(() => {
    if (!showHelptip) {
      setShowHelptip(true);
      setHelpTipContent(pageContent?.HELPTIP_CONTENT_SEARCH_FIELD);
      return true;
    } else setHelpTipContent('');
    return false;
  }, []);

  const renderDynamicDescription = () => {
    return (
      <span className="btm-sm">
        {pageContent?.THERE_ARE}
        <b>{` ${metaData.totalElements} ${pageContent?.DOCTORS} `}</b>
        {pageContent?.WHO_SPECIALISES_IN + ' '}
        <b>{getSpecialisationLabel(specialisation, specialisationTypes) + ' '}</b>
        {pageContent?.WITHIN + ' '}
        <b>{`${locatedWithin} ${pageContent?.MILES} `}</b>
        {pageContent?.OF_THE + ' '}
        <b>{zipCode + ' '}</b>
        {pageContent?.ZIP_CODE}
      </span>
    );
  };

  const renderFooterButtons = () => {
    return (
      <div>
        <span className="right-xs">
          <Button
            btnType="secondary"
            id="modal-slide-cancel"
            onClick={() => {
              handleSliderClose();
            }}
          >
            {sliderState === SLIDER_STATES.DOCTOR_LIST_UNAVAILABLE ? pageContent?.OK : pageContent?.CANCEL}
          </Button>
        </span>
        {!SECONDARY_BUTTON_HIDDEN_STATES.includes(sliderState) && (
          <span className="right-xs">
            <Button
              btnType="secondary"
              id="modal-slide-cancel"
              disabled={zipCodeLoader}
              onClick={() => {
                handleSecondaryButtonClick();
              }}
            >
              {sliderState === SLIDER_STATES.DOCTOR_LIST_AVAILABLE ? pageContent?.SEARCH_AGAIN : pageContent?.SEARCH_FOR_ANOTHER_DOCTOR}
            </Button>
          </span>
        )}
        <span className="right-xs">
          <Button
            id="modal-slide-submit"
            onClick={() => {
              handlePrimaryButtonClick();
            }}
          >
            {getPrimaryButtonText(sliderState, pageContent)}
          </Button>
        </span>
      </div>
    );
  };

  const getModalSliderBody = () => {
    if (pageContent) {
      switch (sliderState) {
        case SLIDER_STATES.SEARCH_DOCTORS:
          return (
            <FindYourDoctorForm
              formHeading={pageContent?.FIND_YOUR_DOCTOR}
              formDescription={pageContent?.DOCTORS_SHOWN}
              formSubHeading={pageContent?.ALL_FIELDS_REQUIRED}
              nameConfig={{
                label1: pageContent?.DENTIST_NAME,
                label2: pageContent?.OPTIONAL,
                label3: pageContent?.PLEASE_ENTER,
                placeHolder: pageContent?.EXAMPLE_NAME,
                value: name,
                errorMessage: nameError,
                helpTip: helpTipContent,
                onChange: ({ target }) => {
                  validateName(target.value);
                  setName(target.value);
                },
                onBlur: ({ target }) => {
                  validateName(target.value);
                  setHelpTipContent('');
                  setShowHelptip(false);
                },
                onClick: () => handleInputClick()
              }}
              providerConfig={{
                label: pageContent?.PROVIDER_TYPE,
                data: providerTypesDental,
                value: providerType,
                onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                  setProviderType(e.target.value);
                }
              }}
              specialisationConfig={{
                label: pageContent?.WHO_SPECIALIZES_IN,
                data: specialisationTypes,
                value: specialisation,
                onChange: (e: React.ChangeEvent<HTMLSelectElement>) => setSpecialisation(e.target.value)
              }}
              specialityDetailsConfig={{
                visible: showSpecialityDetails,
                label: showSpecialityDetails ? pageContent?.HIDE_SPECIALITY : pageContent?.SHOW_SPECIALITY,
                description: getSpecialityDetails(specialisation, specialisationTypes),
                onToggle: () => setShowSpecialityDetails(!showSpecialityDetails)
              }}
              locatedWithinConfig={{
                label: pageContent?.LOCATED_WITHIN,
                data: locatedWithinTypes,
                value: locatedWithin,
                onChange: (e: React.ChangeEvent<HTMLSelectElement>) => setLocatedWithin(e.target.value)
              }}
              zipCodeConfig={{
                value: zipCode,
                errorMessage: zipCodeError,
                loading: zipCodeLoader,
                onChange: (e: { target: { value: React.SetStateAction<string> } }) => {
                  setValidZipCode(false);
                  setZipCode(e.target.value);
                },
                onBlur: () => validateZipCode()
              }}
              moreDetailsLabel={pageContent?.FOR_MORE_DETAILS}
              moreDetailsHyperLinkText={pageContent?.TRY_LOOKING}
              moreDetailsHyperLink={pageContent?.FIND_CARE}
              useCase={PROVIDER_TYPE_USECASES.DENTIST}
            />
          );
        case SLIDER_STATES.DOCTOR_LIST_UNAVAILABLE:
          return <NoResultsFound label={pageContent?.DENTIST_NOT_FOUND}
            description={pageContent?.WE_ARE_ALWAYS_WORKING}
            sublabel={pageContent?.PLEASE_SEARCH_AGAIN}
            hints={[pageContent?.CHECK_THE_SPELLING, pageContent?.WIDEN_YOUR_SEARCH, pageContent?.EXPAND_THE_SEARCH_RADIUS, pageContent?.CHECK_THE_ZIP_CODE]} />;

        case SLIDER_STATES.DOCTOR_LIST_AVAILABLE:
          return (
            <FindYourDoctorList
              label={pageContent?.DOCTOR_NAME_MATCH}
              description1={pageContent?.PLEASE_SELECT}
              metaData={metaData}
              data={providers}
              selectedProviders={selectedProviders}
              savedProiders={savedDentists}
              labels={{
                MORE: pageContent?.SHOW_MORE,
                LESS: pageContent?.SHOW_LESS,
                ACCEPT_NEW_PATIENT: pageContent?.ACCEPT_NEW_PATIENTS,
                MILES: pageContent?.MILES
              }}
              renderCustomDescription2={() => renderDynamicDescription()}
              onSelectionChange={(provider: Provider, isSelected: boolean) => {
                handleSelectionChange(provider, isSelected);
              }}
              onPageChange={(pageDetails: PaginationChangeDetails) => {
                handlePgination(pageDetails);
              }}
              useCase={PROVIDER_TYPE_USECASES.DENTIST}
            />
          );
        case SLIDER_STATES.DOCTORS_SELECTED:
          return (
            <FindYourDoctoSelectedDoctors
              title={pageContent?.DOCTOR_LIST}
              label1={pageContent?.IF_YOU_WANT_TO}
              label2={pageContent?.PLEASE_REMEMBER}
              label3={pageContent?.FOLLOWING_IS_THE_LIST}
              labels={{
                MORE: pageContent?.SHOW_MORE,
                LESS: pageContent?.SHOW_LESS,
                ACCEPT_NEW_PATIENT: pageContent?.ACCEPT_NEW_PATIENTS,
                MILES: pageContent?.MILES,
                REMOVE: pageContent?.REMOVE
              }}
              selectedProviders={savedDentists}
              onSelectionChange={(provider: Provider, isSelected: boolean) => handleRemove(provider, isSelected)}
              useCase={PROVIDER_TYPE_USECASES.DENTIST}
            />
          );
        default:
          return <div />;
      }
    }

  };

  return (
    <>{contentLoaded &&
      <ModalSlide direction={'right'} open={showFindYourDentist} onClose={() => handleSliderClose()}>
        <ModalSlide.Body>
          <div className={'slider-container'}>
            {showPageLoader && <FullPageLoader />}
            <div className="fwc-row fwc-row-wrap mgd-menu-row" data-testid='find-your-dentist-body'>{getModalSliderBody()}</div>
          </div>
        </ModalSlide.Body>
        {showFooterError && (
          <div className="error-container">
            <InfoBar
              area={ERROR_ALERT_CONFIG.AREA}
              backgroundColor={'error'}
              labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
              type={ERROR_ALERT_CONFIG.ERROR}
              onClose={() => {
                setShowFooterError(false);
              }}
            >
              <p className="message">{pageContent?.PLEASE_SELECT_AT_LEAST}</p>
            </InfoBar>
          </div>
        )}
        <ModalSlide.Footer>{renderFooterButtons()}</ModalSlide.Footer>
      </ModalSlide>
    }
    </>
  );
};

const FindYourDentist = memo(FindYourDentistSlider);

export default FindYourDentist;
