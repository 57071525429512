import React, { createContext, useContext, useState } from 'react';

const ShopperContext = createContext<ShopperContextProps>({ userPassword: '', setUserPassword: password => { } });

export const ShopperProvider: React.FC<ShopperProviderProps> = ({ children }) => {
  const [userPassword, setUserPassword] = useState('');

  return (
    <ShopperContext.Provider value={{ userPassword, setUserPassword }}>
      {children}
    </ShopperContext.Provider>
  );
};

export const useShopperContext = () => useContext(ShopperContext);