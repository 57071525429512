import { Tooltip } from '@sydney-broker-ui/ios';
import dayjs from "dayjs";
import React, { useState } from 'react';
import { MEDICAL_PLANS_TABS } from '../../../pages/public/medical-plans/constants';
import { getSubsidyAmount } from '../../../pages/public/medical-plans/medicalPlansUtils';
import { APPLICANT_TYPES, DATE_FORMATS, MACFLOW_POPUP_TYPE, NO, PLAN_TYPES_NAMES, USER_ROLES } from '../../../shared/globalConstants';
import { getFormattedCurrency } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { getAgentDetailsByState } from '../email-print/emailPrintUtils';
import './PlanCoverageAccordion.scss';
import { enableEditCoverage } from './planCoverageAccordionUtils';

const PlanCoverageAccordion: React.FC<PlanCoverageAccordionTypes> = ({ labels, colaradoPlans, planType, applicants, hideForQuoteDetails = false }) => {
  const { applicantFormDetails, coverageDate, agentCoverageDate, agentSSOInfo, zipcode, state, agentState, agentZipcode, selectedDentalPlan, selectedVisionPlan, selectedTab, subsidyresponse, updateIsEditCoverage, updateBasicPlanType, updateOpenDepModal, coverageType, memberInfo, selectedProduct } = useGlobalStore((store) => store);
  const { role, loggedIn } = useUserStore((store) => store)
  const { currentCoverages } = memberInfo;
  const estimatedTaxCredit = getSubsidyAmount(subsidyresponse) ?? '0';

  const coverageEffectiveDate = agentSSOInfo.isAtk ? agentCoverageDate : coverageDate;
  const stateCode = agentSSOInfo.isAtk ? agentState : state;
  const zipCodeValue = agentSSOInfo.isAtk ? agentZipcode : zipcode;

  const [collapsed, setCollapsed] = useState(true);
  let applicantInfo: Applicant[] = [];
  let agentDetailsByState: AgentSSOStateInfo = {} as AgentSSOStateInfo;
  if (agentSSOInfo.isAtk) {
    agentDetailsByState = getAgentDetailsByState(agentSSOInfo, stateCode) ?? {} as AgentSSOStateInfo;
  }

  switch (planType) {
    case PLAN_TYPES_NAMES.MEDICAL:
      applicantInfo = applicants ?? [];
      break;
    case PLAN_TYPES_NAMES.DENTAL:
      applicantInfo = selectedDentalPlan.applicants
      break;
    case PLAN_TYPES_NAMES.VISION:
      applicantInfo = selectedVisionPlan.applicants
      break;
    case PLAN_TYPES_NAMES.MEDICAL_PRODUCT:
      applicantInfo = selectedProduct.medicalApplicants;
      break;
    case PLAN_TYPES_NAMES.DENTAL_PRODUCT:
      applicantInfo = selectedProduct.dentalApplicants;
      break;
    case PLAN_TYPES_NAMES.VISION_PRODUCT:
      applicantInfo = selectedProduct.visionApplicants;
      break
    default:
      applicantInfo = applicants ?? [];
      break;
  }
  const applicantDetails = applicantInfo.find((item) => item.applicantType === APPLICANT_TYPES.PRIMARY && item);
  const spouseDetails = applicantInfo.find((item) => (item.applicantType === APPLICANT_TYPES.SPOUSE || item.applicantType === APPLICANT_TYPES.DOMESTICPARTNER) && item);
  const dependentDetails = applicantInfo.filter((item) => ![APPLICANT_TYPES.PRIMARY, APPLICANT_TYPES.SPOUSE, APPLICANT_TYPES.DOMESTICPARTNER].includes(item.applicantType) && item);

  const getPlanCoveredFor = () => {
    return `${applicantDetails?.firstName} (${labels?.APPLICANT}) ${spouseDetails ? `+ ${labels?.SPOUSE}` : ''} ${dependentDetails?.length > 0 ? `+ ${dependentDetails?.length} ${labels?.DEPENDENTS}` : ''}`;
  };

  const getOpenDepModal = () => {
    const isHavingDentalPlan = currentCoverages.filter((item) => item.planType === PLAN_TYPES_NAMES.DENTAL);
    const isHavingVisionPlan = currentCoverages.filter((item) => item.planType === PLAN_TYPES_NAMES.VISION);
    const isDependentAdded = applicantFormDetails.filter((item) => item.applicantType != APPLICANT_TYPES.PRIMARY)
    if (planType === PLAN_TYPES_NAMES.DENTAL) {
      if (loggedIn && role === USER_ROLES.MEMBER) {
        if (isHavingDentalPlan?.length > 0 && isDependentAdded?.length > 0) {
          updateOpenDepModal('', true, true, MACFLOW_POPUP_TYPE.DENTALWITHDEPENDENTS);
        } else if (isHavingDentalPlan?.length > 0 && isDependentAdded?.length <= 0) {
          updateOpenDepModal('', true, true, MACFLOW_POPUP_TYPE.DENTALWITHOUTDEPENDENTS);
        } else if (isHavingDentalPlan?.length <= 0 && isDependentAdded?.length > 0) {
          updateOpenDepModal('', true, true, MACFLOW_POPUP_TYPE.DENTALWITHOUTPLANWITHDEPENDENTS);
        }
      } else {
        updateOpenDepModal('', true, false, MACFLOW_POPUP_TYPE.DENTALDEPENDENTMODAL);
      }
    } else if (planType === PLAN_TYPES_NAMES.VISION) {
      if (loggedIn && role === USER_ROLES.MEMBER) {
        if (isHavingVisionPlan?.length > 0 && isDependentAdded?.length > 0) {
          updateOpenDepModal('', true, true, MACFLOW_POPUP_TYPE.VISIONWITHDEPENDENTS);
        } else if (isHavingVisionPlan?.length > 0 && isDependentAdded?.length <= 0) {
          updateOpenDepModal('', true, true, MACFLOW_POPUP_TYPE.VISIONWITHOUTDEPENDENTS);
        } else if (isHavingVisionPlan?.length <= 0 && isDependentAdded?.length > 0) {
          updateOpenDepModal('', true, true, MACFLOW_POPUP_TYPE.VISIONWITHOUTPLANWITHDEPENDENTS);
        }
      } else {
        updateOpenDepModal('', true, false, MACFLOW_POPUP_TYPE.VISIONDEPENDENTMODAL);
      }
    } else {
      updateOpenDepModal('', true, false, MACFLOW_POPUP_TYPE.DENTALDEPENDENTMODAL);
    }
  }

  return (
    <div className="plan-coverage-accordion-container">
      <div className="toggle-container">
        <span
          id="plan-coverage-toggle"
          onClick={() => setCollapsed(!collapsed)}
          className={collapsed ? 'sae-icon sae-icon-angle-down toggle-icon' : 'sae-icon sae-icon-angle-up toggle-icon'}
        />
      </div>
      <div className="details-container-div">
        <div className="details-container">
          <div className="dependents-container">
            <span id="plan-coverage-label" className="details-key">
              {labels?.COVERAGE_FOR}
            </span>
            <div className="create-icon-flex">
              <span id="plan-coverage-value" className="details-value">
                {getPlanCoveredFor()}
              </span>
              {applicantFormDetails?.length > 1 && enableEditCoverage(planType, coverageType) && !agentSSOInfo.isAtk && <span className="sae-icon sae-icon-create-new create-icon" onClick={() => { updateIsEditCoverage(true); getOpenDepModal(); updateBasicPlanType(planType === PLAN_TYPES_NAMES.DENTAL ? PLAN_TYPES_NAMES.MEDICAL : PLAN_TYPES_NAMES.DENTAL); }}></span>}
            </div>
            {!collapsed && (
              <div className="dependents-additional-info">
                <div className="dependent-row dependent-margin">
                  <span id="plan-coverage-dependent-icon" className={'sae-icon sae-icon-user-checked user-icon'} />
                  <div className="dependents-info-container">
                    <span id="plan-coverage-name" className="dependent-info">
                      <span className="dependent-name">{applicantDetails?.firstName}</span>
                      <span className="dependent-name">{` (${labels?.APPLICANT})`}</span>
                    </span>
                    <span id="plan-coverage-dob" className="dependent-info">{`${labels?.DATE_OF_BIRTH}:  ${dayjs(applicantDetails?.dateOfBirth).format(DATE_FORMATS.MMDDYYYY)}`}</span>
                    <span id="plan-coverage-tobaco-user" className="dependent-info">{`${labels?.TABACCO_USER}: ${applicantDetails?.isTabaccoUser}`}</span>
                  </div>
                </div>
                {spouseDetails && (
                  <div className="dependent-row dependent-margin">
                    <span id="plan-coverage-dependent-icon" className={'sae-icon sae-icon-user-checked user-icon'} />
                    <div className="dependents-info-container">
                      <span id="plan-coverage-name" className="dependent-info">
                        <span className="dependent-name">{spouseDetails?.firstName}</span>
                        <span className="dependent-name">{` (${labels?.SPOUSE_DOMESTIC_PARTNER})`}</span>
                      </span>
                      <span className="dependent-info">{`${labels?.DATE_OF_BIRTH}: ${dayjs(spouseDetails?.dateOfBirth).format(DATE_FORMATS.MMDDYYYY)}`}</span>
                      <span className="dependent-info">{`${labels?.TABACCO_USER}: ${spouseDetails?.isTabaccoUser ? spouseDetails.isTabaccoUser : NO}`}</span>
                    </div>
                  </div>
                )}
                {dependentDetails?.map((dependents, idx) => {
                  return (
                    <div className="dependent-row dependent-margin" key={idx}>
                      <span id="plan-coverage-dependent-icon" className={'sae-icon sae-icon-user-checked user-icon'} />
                      <div className="dependents-info-container">
                        <span className="dependent-info">
                          <span className="dependent-name">{dependents.firstName}</span>
                          <span className="dependent-name">{` (${labels?.DEPENDENT})`}</span>
                        </span>
                        <span className="dependent-info">{`${labels?.DATE_OF_BIRTH}: ${dayjs(dependents?.dateOfBirth).format(DATE_FORMATS.MMDDYYYY)}`}</span>
                        <span className="dependent-info">{`${labels?.TABACCO_USER}: ${dependents?.isTabaccoUser ? dependents.isTabaccoUser : NO}`}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {
            !hideForQuoteDetails && <div className="date-and-state-container">
              <span id="plan-coverage-date" className="details-key">
                {labels?.EFFECTIVE_DATE}
              </span>
              <span id="plan-coverage-date-value" className="details-value">
                {dayjs(coverageEffectiveDate).format(DATE_FORMATS.MMDDYYYY)}
              </span>
              {!collapsed && (
                <div className="state-zip-container">
                  <span id="plan-coverage-zip-code" className="details-key">
                    {labels?.STATE_ZIP}
                  </span>
                  <span id="plan-coverage-zip-code-value" className="details-value">
                    {`${stateCode}, ${zipCodeValue}`}
                  </span>
                </div>
              )}
            </div>
          }
          {!hideForQuoteDetails && (planType === PLAN_TYPES_NAMES.MEDICAL) && <div className="tax-container">
            <div id="plan-coverage-amount-label" className="details-key">
              {colaradoPlans ? labels?.SILVER_ENHANCED_SAVINGS : labels?.ESTIMATE_TAX_CREDIT_APPLIED}
              <Tooltip className="left-xxs tooltip-icon " icon message={colaradoPlans ? labels?.THOSE_WHOSE_INCOME : labels?.YOUR_STATE_EXCHANGE} />
            </div>
            <span id="plan-coverage-amount-value" className="amount-value">
              {useGlobalStore.getState().selectedTab !== MEDICAL_PLANS_TABS.COLARADO_CONNECT_PLANS ? getFormattedCurrency(parseFloat(estimatedTaxCredit)) : '$0.00'}
            </span>
          </div>}
        </div>
        {
          agentSSOInfo.isAtk && currentCoverages && currentCoverages.length > 0 && <div className="current-coverage-container">
            <div id="plan-coverage-current-coverage" className="coverage-item">
              {labels?.CURRENT_COVERAGE}
              {currentCoverages.map((coverage, idx) => {
                return (
                  <div key={idx} className='coverage-item'>
                    <span id="plan-coverage-current-coverage-name" className="coverage-details-key"> {coverage.planName},{coverage.planType}</span>-<span id='plan-current-coverage-premium' className='premium-amount'>{getFormattedCurrency(Number(coverage.totalPremium))}</span>
                  </div>
                );
              })}
            </div>
          </div>
        }
        {
          agentSSOInfo.isAtk && agentDetailsByState && agentDetailsByState?.licenseNumber && <div className='top-sm'>
            <div>{agentDetailsByState?.licenseNumber}, {agentDetailsByState?.agencyInfo.agency.agencyName}</div>
            <div>{agentDetailsByState?.agentAddressInfo.agentAddress.address1} | {agentDetailsByState?.phone} | {agentDetailsByState?.email} | {agentDetailsByState?.agentAddressInfo.agentAddress.zip}</div>
          </div>
        }
      </div>
    </div>
  );
};

export default PlanCoverageAccordion;
