import { PHONE_FORMATTED, USER_DOMAINS } from '../../../../shared/globalConstants';
import { useGlobalStore } from '../../../../store/globalStore';

const getUpdateProfileInformationPayload = (
  username: string,
  webAccountGUID: string,
  shopperID: string,
  role: string,
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  emailAddress: string,
  emailConsent: string,
  number: string,
  type: string
): UpdatePersonalInformationPayload => {
  return {
    userDomain: {
      app: USER_DOMAINS.APP,
      marketSegment: USER_DOMAINS.MARKET_SEGMENT,
      brand: useGlobalStore.getState().brand
    },
    shopperAccount: {
      username,
      webAccountGUID,
      shopperID,
      role
    },
    shopperProfile: {
      person: {
        firstName,
        lastName,
        dateOfBirth,
        emailAddress,
        emailConsent,
        phone: {
          type,
          number
        }
      }
    }
  };
};

const validatePhoneNumber = (value: string, phoneNumberType: string, Strings: any) => {
  const errorConfig = {
    isValidPhoneNumber: true,
    phoneNumberErrorMessage: ''
  };
  if (value.length === 0) {
    errorConfig.isValidPhoneNumber = false;
    errorConfig.phoneNumberErrorMessage = Strings.ERROR_MESSAGES.REQUIRED_FIELD;
  } else if (!PHONE_FORMATTED.test(value)) {
    errorConfig.isValidPhoneNumber = false;
    errorConfig.phoneNumberErrorMessage = Strings.ERROR_MESSAGES.INVALID_PHONE;
  } else if (!phoneNumberType) {
    errorConfig.isValidPhoneNumber = false;
    errorConfig.phoneNumberErrorMessage = Strings.SELECT_PHONE_TYPE;
  }
  return errorConfig;
};

export { getUpdateProfileInformationPayload, validatePhoneNumber };
