const MEDICAL_PLAN_BENEFITS_ARR = [
  'MEDDEDUCTIBLEINDV',
  'MEDDEDUCTIBLEFAM',
  'OOPMAXIND',
  'OOPMAXFAM',
  'BLUECARD',
  'PRIMOFFVIS',
  'SPCLOFFVIS',
  'GENPREDRUGS',
  'PREFPREDRUGS',
  'NONPREDRUGS',
  'SPCLPREDRUGS'
];

const DENTAL_PLAN_BENEFITS_ARR = ['DENTDEDUCTIBLE', 'DENTOOPMAX'];
const VISION_PLAN_BENEFITS_ARR = ['VISRTNEYEEXM', 'VISEYEGLSLENS', 'VISEYEGLSFRMS', 'VISCNTCTLENS'];
export { DENTAL_PLAN_BENEFITS_ARR, MEDICAL_PLAN_BENEFITS_ARR, VISION_PLAN_BENEFITS_ARR };
