const ALPHA_NUMERIC_REGX: RegExp = /^[a-zA-Z0-9@]+$/;

const INPUT_TYPES = {
  CURRENTPASSWORD: 'CURRENTPASSWORD',
  PASSWORD: 'PASSWORD',
  CONFIRMPASSWORD: 'CONFIRMPASSWORD'
};

const PASSWORD_VALIDATION_CHECK =(Strings:any)=>{
  const passwordCheckRules = [
    {
      id: 'rule1',
      isPassed: false,
      label: Strings.PASSWORD_CHECK.RULE1,
      regex: /^.{8,20}$/
    },
    {
      id: 'rule2',
      isPassed: false,
      label: Strings.PASSWORD_CHECK.RULE2,
      regex: /[a-zA-Z].*\d|\d.*[a-zA-Z]/
    },
    {
      id: 'rule3',
      isPassed: false,
      label: Strings.PASSWORD_CHECK.RULE3,
      regex: /^\S+$/
    },
    {
      id: 'rule4',
      isPassed: false,
      label: Strings.PASSWORD_CHECK.RULE4,
      regex: /^S+$/
    },
    {
      id: 'rule5',
      isPassed: false,
      label: Strings.PASSWORD_CHECK.RULE5,
      regex: /^(?!.*(.).*\1\1).+$/
    },
    {
      id: 'rule6',
      isPassed: false,
      label: Strings.PASSWORD_CHECK.RULE6,
      regex: /^[^&<>\\]*$/
    },
    {
      id: 'rule7',
      isPassed: false,
      label: Strings.PASSWORD_CHECK.RULE7,
      regex: /^[a-zA-Z0-9_!#$%^*()+,.?:;{}\[\]\|@]*$/
    }
  ];
  return passwordCheckRules
}
 

  

const CHANGE_SHOPPER_PASSWORD_RESPONSE_CODES = {
  RESPONSE_CODE: 'CHANGEPWD000'
};

const REQUIRED_FIELD = 'REQUIRED_FIELD';
export { CHANGE_SHOPPER_PASSWORD_RESPONSE_CODES, INPUT_TYPES, PASSWORD_VALIDATION_CHECK, REQUIRED_FIELD };

