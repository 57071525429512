import { Button, ModalSlide, Tooltip } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import HelpMeChoosePopup from '../../../components/common/helpme-choose-popup/HelpMeChoosePopup';
import { FwcProgressStandard } from '../../../fwc-components/fwc-lib';
import { LANGUAGE_CODES, NUMBER_REGX, NUMBER_REGX_DECIMAL, SCREEN_NAMES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import './EstimateSavings.scss';
import { DEPENDENTS, HOUSEHOLDINCOME } from './constants';
import { subsidyCalculator } from './estimateSavings.service';
import { default as Content } from './messages.json';

interface calculateForm {
  houseHoldIncome: string;
  dependents: string;
}

function EstimateSavings() {
  const navigate = useNavigate();
  const [pageContent, setContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [openModal, setModalOpen] = useState(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const { zipCodeRes, brand } = useGlobalStore((store) => store);
  const { register, control, handleSubmit, formState } = useForm<calculateForm>({
    mode: 'all'
  });

  const { subsidyresponse, zipcode, countyCode, coverageDate, applicantFormDetails, updateEstimateSavingsResponse, householdMemberValues, updateHouseholdMemberValues, updateIsSubsidydataApplied } = useGlobalStore((state) => state);
  const [inputs, setInputs] = useState(householdMemberValues ? householdMemberValues : {
    houseHoldIncome: '',
    dependents: '0'
  });

  const { errors } = formState;

  const getContent = useCallback(async (): Promise<boolean> => {
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.ESTIMATESAVINGS);
      let cmsResponse = response.data;

      setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.ESTIMATESAVINGS);
    } catch (error) {
      setContent(Content.data.iospagecontentList.items[0].pageContent.ESTIMATESAVINGS);

      return false;
    } finally {
      setContentLoaded(true);
    }
    return true;
  }, []);

  const initiate = async () => {
    await getContent();
  };

  const submitCaculateForm = handleSubmit(async (data) => {
    updateHouseholdMemberValues(inputs);
    setIsLoading(true);
    subsidyCalculator(data, zipcode, countyCode, coverageDate, setMemTempId(applicantFormDetails)).then((response) => {
      setIsLoading(false);
      updateEstimateSavingsResponse(response.data);
      navigate('/individuals/shop/basics/options');
    });
  });

  const setMemTempId = (data) => {
    data.forEach((element, index) => {
      if (!element.hasOwnProperty('applicantId')) {
        element.applicantId = index;
      }
    })
    return data;
  }

  const openSlideModal = () => {
    setModalOpen(!openModal);
  };

  useEffect(() => {
    initiate();
    const fwcProgressStandard = new FwcProgressStandard();
    fwcProgressStandard.build();
  }, []);

  const handleValue = (event) => {
    const name = event.target.name;
    const value = event.target.value.replace(/[^0-9]/g, '').replace(/^0+(?!$)/, '');
    event.target.value = value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  if (!contentLoaded || isLoading) {
    return <FullPageLoader />;
  } else {
    return (
      <div>
        <div className="estimatesavings">
          <form className="registration-wrapper" onSubmit={submitCaculateForm} noValidate>
            <div className="fwc-row">
              <div className="fwc-col-12">
                <br></br>
                <br></br>
                <h1 id="title">{pageContent?.title}</h1>
                <p className="desc" id="description">
                  {pageContent?.description}
                </p>
                <div className="discount-block">
                  <h3 className="title" id="estimate-discount-block">
                    {pageContent?.discountBlock.title}
                  </h3>
                  <div className="steps">
                    <div className="step">
                      <h4 className="title" id="estimate-discount-step1-title">
                        <i className="sae-icon sae-icon-ok"></i>
                        {pageContent?.discountBlock.step1.title}
                      </h4>
                      <p className="description" id="estimate-discount-step1-description">
                        {pageContent?.discountBlock.step1.description}
                      </p>
                      <div className="dependents-info">
                        {applicantFormDetails.map((user) => (
                          <p key={user.firstName}>
                            <i className="sae-icon sae-icon-user-checked"></i> {user.firstName}
                          </p>
                        ))}
                      </div>
                    </div>
                    <div className="step">
                      <h4 className="title" id="estimate-discount-step2-title">
                        <i className="sae-icon sae-icon-circle"></i>
                        {pageContent?.discountBlock.step2.title}
                      </h4>
                      <p className="description" id="estimate-discount-step2-description">
                        {pageContent?.discountBlock.step2.description}
                      </p>
                      <div className="form-field-area">
                        <div className="fwc-input">
                          <div className="form-control bold-label">
                            <div className="label-wrapper">
                              <label htmlFor="householdincome">{pageContent?.discountBlock.step2.field.label}</label>
                              <Tooltip icon message={pageContent?.discountBlock.step2.field.tooltip}></Tooltip>
                            </div>
                            <div className="input-icon-wrapper">
                              <i className="sae-icon sae-icon-cheap-2"></i>
                              <input
                                id="householdincome"
                                type="text"
                                maxLength={HOUSEHOLDINCOME.MAX_LENGTH}
                                className={errors.houseHoldIncome ? 'is-invalid' : ''}
                                {...register('houseHoldIncome', {
                                  value: Number(inputs.houseHoldIncome) > 0 ? inputs.houseHoldIncome : useUserStore.getState().demographicInfo?.subsidy?.houseHoldIncome?.toString(),
                                  required: {
                                    value: true,
                                    message: pageContent?.errors.houseHoldIncome.required
                                  },
                                  pattern: {
                                    value: NUMBER_REGX_DECIMAL,
                                    message: pageContent?.errors.houseHoldIncome.numbersOnly
                                  },
                                  maxLength: {
                                    value: HOUSEHOLDINCOME.MAX_LENGTH,
                                    message: pageContent?.errors.houseHoldIncome.max
                                  },
                                  max: {
                                    value: HOUSEHOLDINCOME.MAX_VALUE,
                                    message: pageContent?.errors.houseHoldIncome.invalidIncome
                                  },
                                  onChange: (e) => {
                                    handleValue(e);
                                  }
                                })}
                              />
                            </div>
                            {errors.houseHoldIncome?.message && <p className="error">{errors.houseHoldIncome?.message}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dependents-block">
                  <h3 className="title" id="other-dependents-title">
                    {pageContent?.addOtherDependents.title}
                  </h3>
                  <p className="description" id="other-dependents-description">
                    {pageContent?.addOtherDependents.description}
                  </p>
                  <div className="form-field-area">
                    <div className="fwc-input">
                      <div className="form-control bold-label">
                        <div className="label-wrapper">
                          <label htmlFor="dependentscount">{pageContent?.addOtherDependents.field.label}</label>
                          {/* <Tooltip
                                                icon
                                                message={pageContent?.addOtherDependents.field.tooltip}
                                            ></Tooltip> */}
                        </div>
                        <input
                          id="dependentscount"
                          type="string"
                          maxLength={DEPENDENTS.MAX_LENGTH}
                          className={errors.dependents ? 'is-invalid' : ''}
                          {...register('dependents', {
                            value: Number(inputs.dependents) > 0 ? inputs.dependents : useUserStore.getState().demographicInfo?.subsidy?.houseHoldSize ? (Number(useUserStore.getState().demographicInfo?.subsidy?.houseHoldSize) - (useUserStore.getState().demographicInfo?.applicant?.length || 0)).toString() : inputs.dependents,
                            required: false,
                            pattern: {
                              value: NUMBER_REGX,
                              message: pageContent?.errors.houseHoldIncome.numbersOnly
                            },
                            maxLength: {
                              value: DEPENDENTS.MAX_LENGTH,
                              message: pageContent?.errors.dependents.max
                            },
                            max: {
                              value: DEPENDENTS.MAX_VALUE,
                              message: pageContent?.errors.dependents.invalidValue
                            },
                            min: {
                              value: DEPENDENTS.MIN_VALUE,
                              message: pageContent?.errors.dependents.invalidValue
                            },
                            onChange: (e) => {
                              handleValue(e);
                            }
                          })}
                        />
                        {errors.dependents?.message && <p className="error">{errors.dependents?.message}</p>}
                      </div>
                    </div>
                  </div>
                  <div className="learn-more">
                    <p>
                      <b>{pageContent?.addOtherDependents.field.leanMore}</b>{' '}
                      <a href="javascript:void(0)" id="other-dependents-learn-more" onClick={openSlideModal}>
                        {pageContent?.addOtherDependents.field.dependents}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="cost-actions fwc-row">
            <button data-analytics="backBtnFedTaxCreditIos" className="fwc-btn fwc-btn-secondary" id="backtoEstimateSavings" onClick={() => navigate('/individuals/shop/basics/estimate-cost-savings')}>
              {pageContent?.buttons.backtoEstimateSavings}
            </button>
            <div className="button-wrapper">
              <button data-analytics="skipBtnFedTaxCreditIos" className="fwc-btn fwc-btn-secondary" onClick={() => {
                // if (subsidyresponse.subsidyCalc?.[0]?.subsidyAmount) {
                //   updateEstimateSavingsResponse({})
                //   updateHouseholdMemberValues({})
                // }
                setShowPopup(true)
              }
              }>
                {pageContent?.buttons.skipthisStep}
              </button>
              <button data-analytics="calcDiscountBtnFedTaxCreditIos" className="fwc-btn fwc-btn-primary" id="calculateDiscount" type="submit" onClick={submitCaculateForm}>
                {pageContent?.buttons.calculateDiscount}
              </button>
            </div>
          </div>
          <HelpMeChoosePopup
            showPopup={showPopup}
            popupClose={() => {
              setShowPopup(false);
            }}
          />
          <div className="fwc-row page-content">
            <div dangerouslySetInnerHTML={{ __html: pageContent?.pageHtml }}></div>
          </div>
        </div>
        <ModalSlide direction="right" onClose={openSlideModal} open={openModal}>
          <ModalSlide.Body>
            <div className="popupContent" dangerouslySetInnerHTML={{ __html: pageContent?.popUpHtml }}></div>
          </ModalSlide.Body>
          <ModalSlide.Footer>
            <Button btnType="primary" id="modal-slide-cancel" onClick={openSlideModal}>
              {pageContent?.buttons.close}
            </Button>
          </ModalSlide.Footer>
        </ModalSlide>
      </div>
    )
  }
}
export default EstimateSavings;
