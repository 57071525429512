//Add service functions here

import axios from 'axios';
import { API_NAME_LIST, smDetails } from '../../../../api/api-name-list';
import axiosInstance from '../../../../api/axiosWrapper/axiosWrapper';
import { NAV_PATHS, USER_DOMAINS, USER_ROLES } from '../../../../shared/globalConstants';
import { getPayloadForUserLogin } from './loginUtils';

const loginUser = (userName: string = '', password: string = '', role: string = USER_ROLES.SHOPPER) => {
  const payload = getPayloadForUserLogin(userName, password, role);
  return axiosInstance.post(API_NAME_LIST.LOGIN, payload);
};

const getMemberInfo = (webGUID: string = '') => {
  return axiosInstance.get(API_NAME_LIST.MEMBER_INFO, {
    headers: {
      webGUID: webGUID,
      app: USER_DOMAINS.APP
    }
  });
};

const getMemberInfoHCID = (hcid: string = '') => {
  return axiosInstance.get(API_NAME_LIST.MEMBER_INFO, {
    headers: {
      hcid: hcid,
      app: USER_DOMAINS.APP
    }
  })
}

const getPlanDetails = (payload: GetPlanDetailsPayload) => {
  return axiosInstance.post(API_NAME_LIST.GET_PLAN_DETAILS, payload);
};

const logoutRedirect = (userRole: string) => {
  const timeStamp = new Date().getTime();
  axios.get(smDetails.SM_LOGOUT_URL + '?t=' + timeStamp, { responseType: 'text' }).then((res) => {
    let responseData: any = res;
    let logoutUrl: string = smDetails.OIDC_SECURE_REDIRECT_URL;
    let targetUrl: string = userRole === USER_ROLES.MEMBER ? NAV_PATHS.MAC_LOGIN : NAV_PATHS.STANDALONE_LOGIN;
    if (responseData) {
      let finalLogoutUrl = logoutUrl + '?logout=' + targetUrl + '?t=' + timeStamp + '&TARGET=' + targetUrl;
      window.location.href = finalLogoutUrl;
    }
  });
};

export { getMemberInfo, getMemberInfoHCID, getPlanDetails, loginUser, logoutRedirect };
