const INPUT_TYPES = {
  FIRSTNAME: "FIRSTNAME",
  LASTNAME: "LASTNAME",
  EMAIL: "EMAIL",
  DOB: "DOB",
  PHONE: "PHONE",
};

const MOBILE_INPUT_OPTIONS = [
  { label: "Mobile", value: "Mobile" },
  { label: "Home", value: "Home" },
  { label: "Office", value: "Office" },
  { label: "Other", value: "Other" },
];

const REGEX = {
  NAME: /^[a-zA-Z0-9-_]+$/,
  EMAIL: /^[\w.-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9]+)*(?:\.[a-zA-Z]+)$/,
  DOB: /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
};

export { INPUT_TYPES, REGEX, MOBILE_INPUT_OPTIONS };
