import { API_NAME_LIST } from '../../../../api/api-name-list';
import axiosInstance from '../../../../api/axiosWrapper/axiosWrapper';

const cancelInProgressApplication = (payload: deleteApplicationPayload) => {
  return axiosInstance.post(API_NAME_LIST.CANCEL_APPLICATION, payload);
};

const getDashboardApplications = (payload: getApplicationPayload) => {
  return axiosInstance.post(API_NAME_LIST.GET_APPLICATION, payload);
};

const submitApplication = (payload: submitApplicationPayload) => {
  return axiosInstance.post(API_NAME_LIST.SUBMIT_APPLICATION, payload);
};

const generateVoiceVaultLetter = (payload: getApplicationPayload) => {
  return axiosInstance.post(API_NAME_LIST.VOICE_VAULT_LETTER, payload);
};
export { cancelInProgressApplication, generateVoiceVaultLetter, getDashboardApplications, submitApplication };
