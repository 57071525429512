import { ROWS_PER_PAGE } from '../../../shared/globalConstants';
import { formatDateToYYYYMMDD } from '../../../shared/utils/globalUtils';
import {
  DEFAULT_SPECIALISATION_VALUES_BY_PROVIDER_TYPE,
  PROVIDER_TYPE_IDS,
  SLIDER_STATES,
  SORT_OPTION,
  SPECIALISATION_BY_PROVIDER_TYPE
} from '../find-your-doctor/constants';

const getLocatedWithin = (locatedWithin: SelectOptionsNumber[], Strings: any) => {
  return locatedWithin.map((locatedWithinType) => {
    return {
      ...locatedWithinType,
      label: Strings[locatedWithinType.id]
    };
  });
};

const getProviderTypes = (providerTypes: SelectOptionsString[], Strings: any): SelectOptionsString[] => {
  return providerTypes.map((providertype) => {
    return {
      ...providertype,
      label: Strings[providertype.id]
    };
  });
};

const getSpecialities = (specialities: Specialities, type: any, desc: any): Specialities => {
  return Object.entries(specialities).reduce((newSpecialities, [key, speciality]) => {
    newSpecialities[key] = {
      ...speciality,
      label: type[speciality.id],
      desc: desc[speciality.id]
    };
    return newSpecialities;
  }, {} as Specialities);
};

const getPrimaryButtonText = (sliderState: string, Strings) => {
  switch (sliderState) {
    case SLIDER_STATES.SEARCH_DOCTORS:
      return Strings.SEARCH;
    case SLIDER_STATES.DOCTOR_LIST_UNAVAILABLE:
      return Strings.SEARCH_AGAIN;
    case SLIDER_STATES.DOCTOR_LIST_AVAILABLE:
      return Strings.ADD_DENTISTS_TO_MY_LIST;
    case SLIDER_STATES.DOCTORS_SELECTED:
      return Strings.MATCH_DENTISTS;
    default:
      return Strings.SEARCH;
  }
};

const getSpecialisationByProviderType = (providerValue: string, providerTypes: SelectOptionsString[], specialities: Specialities): SelectOptionsString[] => {
  const providerObj = providerTypes.find((provider) => provider.value === providerValue);

  if (providerObj) {
    const providerId = providerObj.id;
    const specialisations = SPECIALISATION_BY_PROVIDER_TYPE(specialities)[providerId];

    if (specialisations.length > 0) {
      return specialisations;
    }
  }

  return SPECIALISATION_BY_PROVIDER_TYPE(specialities)[PROVIDER_TYPE_IDS.DENTIST];
};

const getSpecialityDetails = (specialisation: string, specialisations: SelectOptionsString[]): string => {
  const specialisationObj = specialisations.find((option) => option.value === specialisation);

  return specialisationObj?.desc || '';
};

const getDefaultSpecialisationValueByProviderType = (providerValue: string, providerTypes: SelectOptionsString[], specialities: Specialities): string => {
  const providerObj = providerTypes.find((provider) => provider.value === providerValue);

  if (providerObj) {
    const providerId = providerObj.id;
    const defaultSpecialisation = DEFAULT_SPECIALISATION_VALUES_BY_PROVIDER_TYPE(specialities)[providerId];

    return defaultSpecialisation;
  }

  return specialities['ALL'].value;
};

const getSpecialisationLabel = (specialisationValue: string, specialisationTypes: SelectOptionsString[]): string => {
  const specialisationObj = specialisationTypes.find((specialisation) => specialisation.value === specialisationValue);

  if (specialisationObj) {
    return specialisationObj.label;
  }
  return '';
};

const getUpdatedSelectedProviders = (provider: Provider, isSelected: boolean, selectedProviders: Provider[]): Provider[] => {
  let updatedSelectedProviders = [...selectedProviders];

  if (isSelected) {
    updatedSelectedProviders = selectedProviders.filter(
      (providerObj) => providerObj.providerIdentifier + providerObj.address.addressId !== provider.providerIdentifier + provider.address.addressId
    );
  } else {
    updatedSelectedProviders.push(provider);
  }

  return updatedSelectedProviders;
};

const getSearchProviderPayload = (
  pageNumber: string,
  zipCodeObj: ZipcodeObject,
  providerType: string,
  locatedWithin: string,
  providerName: string,
  contractCodes: string[],
  specialisation: string,
  effectiveDate: string
): SearchDentistsPayload => {
  return {
    requestPageNumber: pageNumber,
    clientRowsPerPage: ROWS_PER_PAGE,
    zipCode: zipCodeObj.code,
    type: providerType,
    state: zipCodeObj.state,
    distance: locatedWithin,
    providerName: providerName,
    contractCodes: contractCodes,
    specialtyType: specialisation.length ? [specialisation] : [],
    effectiveDate: formatDateToYYYYMMDD(effectiveDate),
    latitude: zipCodeObj.latitude,
    longitude: zipCodeObj.longitude,
    brand: zipCodeObj.brand,
    sortOption: SORT_OPTION
  };
};

export {
  getDefaultSpecialisationValueByProviderType,
  getLocatedWithin,
  getPrimaryButtonText,
  getProviderTypes,
  getSearchProviderPayload,
  getSpecialisationByProviderType,
  getSpecialisationLabel,
  getSpecialities,
  getSpecialityDetails,
  getUpdatedSelectedProviders
};
