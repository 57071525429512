import { Button, Modal } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from 'react';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { STANDALONE_LOGIN_REDIRECTION_USE_CASES } from '../../../pages/public/login/standalone-login/constants';
import { APPLICATION_TRANSFER_REDIRECTION_USE_CASES, CONFLICT_OPTION_LABELS, CONFLICT_USE_CASES, ERROR_ALERT_CONFIG, LANGUAGE_CODES, SCREEN_NAMES } from '../../../shared/globalConstants';
import { recordActionSatelliteTrack } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import InfoBar from '../info-bar/InfoBar';
import Content from './QuoteConflict.json';
import './QuoteConflict.scss';

const QuoteConflict: React.FC<QuoteConflictProps> = ({ showPopup, useCase, redirectionUseCase = '', onClick, popupClose }) => {
    const content = Content.data.iospagecontentList.items[0].pageContent.QuoteConflict;
    const [selectedOption, setSelectedOption] = useState('');
    const [pageContent, setContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const { zipCodeRes } = useGlobalStore(state => state);
    const [labels, setLabels] = useState<any>();
    const [notSelectedError, setNotSelectedError] = useState(false);

    const getContent = useCallback(async (): Promise<boolean> => {
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.QUOTE_CONFLICT_POPUP);
            let cmsResponse = response.data;
            setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.QuoteConflict);
        } catch (error) {
            setContent(content);

            return false;
        } finally {
            setContentLoaded(true);
        }
        return true;
    }, []);

    const handleContinue = () => {
        if (selectedOption) {
            popupClose();
        }
        else {
            setNotSelectedError(true);
        }
    };

    const handleOptionChange = (selectedVal: string) => {
        setSelectedOption(selectedVal);
        if (selectedVal === CONFLICT_OPTION_LABELS.OPTION1) {
            recordActionSatelliteTrack("currentQuoteOptionModalIos");
        } else if (selectedVal === CONFLICT_OPTION_LABELS.OPTION2) {
            recordActionSatelliteTrack("savedBeforeQuoteOptionModalIos");
        }
    };

    const initiate = async () => {
        await getContent();
    }

    useEffect(() => {
        initiate();
    }, []);


    useEffect(() => {
    }, [selectedOption]);

    useEffect(() => {
        if (contentLoaded) {
            if (redirectionUseCase === STANDALONE_LOGIN_REDIRECTION_USE_CASES.AGENT_TELESALES_PROPOSAL) {
                if (useCase === CONFLICT_USE_CASES.QUOTE) {
                    setLabels(pageContent.QUOTE_PROPOSAL.QUOTE_LABELS);
                } else {
                    setLabels(pageContent.QUOTE_PROPOSAL.APPLICATION_LABELS);
                }
            } else if (redirectionUseCase === APPLICATION_TRANSFER_REDIRECTION_USE_CASES.TRANSFER_FLOW && useCase === CONFLICT_USE_CASES.APPLICATION) {
                setLabels(pageContent?.APPLICATION_TRANSFER.APPLICATION_LABELS);
            }
            else if (useCase === CONFLICT_USE_CASES.QUOTE) {
                setLabels(pageContent?.QUOTE_LABELS);
            } else if (useCase === CONFLICT_USE_CASES.APPLICATION) {
                setLabels(pageContent?.APPLICATION_LABELS);
            }
        }
    }, [useCase, contentLoaded]);

    return (
        !contentLoaded ? <div></div> : (
            <div className='logout-popup-wraper'>
                <Modal open={showPopup} onClose={() => popupClose()} title={labels?.TITLE} >
                    <Modal.Body className="popup-body" data-testid="quote-conflict">
                        <fieldset className="fwc-radio-group-conflict-wrapper">
                            <legend>{labels?.LEGEND}</legend>
                            <div className="fwc-radio-group-conflict">
                                <div className={`radio-box-shadow ${selectedOption === CONFLICT_OPTION_LABELS.OPTION1 ? "selected-radio" : ''}`}
                                    onClick={(e) => {
                                        handleOptionChange(CONFLICT_OPTION_LABELS.OPTION1);
                                        onClick(CONFLICT_OPTION_LABELS.OPTION1);
                                    }}>
                                    <div className="fwc-radio " >
                                        <input
                                            type="radio"
                                            value={CONFLICT_OPTION_LABELS.OPTION1}
                                            checked={selectedOption === CONFLICT_OPTION_LABELS.OPTION1}
                                            onChange={(e) => {
                                                handleOptionChange(CONFLICT_OPTION_LABELS.OPTION1)
                                            }}
                                        />
                                        <label className="fwc-label" htmlFor="option1">
                                            <span>
                                                <i className="sae-icon sae-icon-circled"></i>
                                            </span>
                                            <span>
                                                <span className='option-name'>
                                                    {labels?.OPTION1}</span>
                                                <p className='description'>
                                                    {labels?.LABEL1}
                                                </p>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className='text-or-class'> {pageContent?.OR}</div>
                                <div className={`radio-box-shadow ${selectedOption === CONFLICT_OPTION_LABELS.OPTION2 ? "selected-radio" : ''}`}
                                    onClick={(e) => {
                                        handleOptionChange(CONFLICT_OPTION_LABELS.OPTION2);
                                        onClick(CONFLICT_OPTION_LABELS.OPTION2);
                                    }}>
                                    <div className="fwc-radio " >
                                        <input
                                            type="radio"
                                            value={CONFLICT_OPTION_LABELS.OPTION2}
                                            checked={selectedOption === CONFLICT_OPTION_LABELS.OPTION2}
                                            onChange={(e) => {
                                                handleOptionChange(CONFLICT_OPTION_LABELS.OPTION2)
                                            }}
                                        />
                                        <label className="fwc-label" htmlFor="option2">
                                            <span>
                                                <i className="sae-icon sae-icon-circled"></i>
                                            </span>
                                            <span>
                                                <span className='option-name'>  {labels?.OPTION2}</span>
                                                <p className='description'>{labels?.LABEL2}</p>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <span className='description' dangerouslySetInnerHTML={{ __html: labels?.NOTE }}></span>
                        <Modal.Footer>
                            <div className="popup-buttons" id="popup-buttons-wrapper">
                                <Button btnType="primary" id="continue-button" onClick={handleContinue}
                                    data-testid="continue-button" data-analytics="continueBtnQuoteOptionModalIos">
                                    {labels?.CONTINUE}
                                </Button>
                            </div>
                        </Modal.Footer>
                        {notSelectedError && (
                            <div className="error-container">
                                <InfoBar
                                    area={ERROR_ALERT_CONFIG.AREA}
                                    backgroundColor={'error'}
                                    labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
                                    type={ERROR_ALERT_CONFIG.ERROR}
                                    onClose={() => {
                                        setNotSelectedError(false);
                                    }}
                                >
                                    <p className="message">{pageContent?.ALERT}</p>
                                </InfoBar>
                            </div >

                        )}
                    </Modal.Body>
                </Modal>


            </div >
        )
    );
};

export default QuoteConflict;