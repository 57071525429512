import { Button, ModalSlide } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from "react";
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { LANGUAGE_CODES, SCREEN_NAMES } from '../../../shared/globalConstants';
import FullPageLoader from '../full-page-loader/FullPageLoader';
import { default as Content } from './InsuranceBasics.json';
import './InsuranceBasics.scss';

const InsuranceBasics: React.FC<InsuranceBasicsProps> = ({ showInsuranceBasicsSlider, CloseInsuranceBasicsSlider }) => {

    const [showPageLoader, setShowPageLoader] = useState(false);

    const [pageContent, setContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const getContent = useCallback(async (): Promise<boolean> => {
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.INSURANCE_BASICS);
            let cmsResponse = response.data;
            setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.InsuranceBasics);
        } catch (error) {
            setContent(Content.data.iospagecontentList.items[0].pageContent.InsuranceBasics);

            return false;
        } finally {
            setContentLoaded(true);
        }
        return true;
    }, []);
    const initiate = async () => {
        setLoading(true);
        await getContent();
        setLoading(false)

    }
    const handleSliderClose = useCallback(() => {
        CloseInsuranceBasicsSlider();
    }, [CloseInsuranceBasicsSlider]);

    useEffect(() => {
        if (showInsuranceBasicsSlider) {
            setShowPageLoader(true);
            initiate();
            setShowPageLoader(false);
        }

    }, [showInsuranceBasicsSlider])

    return (
        <>
            {!contentLoaded ? (<FullPageLoader />) : (
                <ModalSlide direction={'right'} open={showInsuranceBasicsSlider} onClose={() => handleSliderClose()}>
                    <ModalSlide.Body>
                        <div className={'insurance-basics-slider-container'}>
                            <div className="fwc-row fwc-row-wrap mgd-menu-row">
                                <div className="fwc-col-12 sm-12 ">
                                    <h2 className="btm-xxs title">
                                        <span className="sae-icon sae-icon-help right-xxs help-icon-color"></span>
                                        <span>{pageContent?.SLIDER_HEADING}</span>
                                    </h2>
                                </div>
                                <div className="fwc-col-12 sm-12 headings">
                                    <h4>
                                        <span> {pageContent?.HELPFUL_INFORMATION}</span>
                                    </h4>
                                    <div className='links-gap'>
                                        {pageContent?.INSURANCE_BASICS && (
                                            <a
                                                href={pageContent?.INSURANCE_BASICS}
                                                className="insurance-plans-hyper-link"
                                                id={'understanding-health-insurance-link'}
                                                target="_blank"
                                            >
                                                <span>
                                                    <span className='link-text'>  {pageContent?.UNDERSTANDING}</span>
                                                    <span id="understanding-health-insurance-link-icon" className="sae-icon sae-icon-external-link link-icon" />
                                                </span>
                                            </a>)}
                                    </div>

                                </div>
                                <div className="fwc-col-12 sm-12 headings">

                                    <h4>
                                        <span data-testid="plan-type-heading">  {pageContent?.PLAN_TYPES_HEADING}</span>
                                    </h4>
                                    <div>
                                        <span>{pageContent?.PLAN_TYPES_DESC}</span>
                                    </div>
                                    <div>
                                        <span>{pageContent?.PLAN_TYPES_DESC_1}</span></div>

                                    <div className='links-gap'>
                                        {pageContent?.PLAN_TYPES && (
                                            <a
                                                href={pageContent?.PLAN_TYPES}
                                                className="insurance-plans-hyper-link"
                                                id={'types-of-health-plans-link'}
                                                target="_blank"
                                                data-testid="plan-type-link"
                                            >
                                                <span>
                                                    <span className='link-text'>
                                                        {pageContent?.PLAN_TYPES_LINK}</span>
                                                    <span id="plan-types-link-icon" className="sae-icon sae-icon-external-link link-icon" />
                                                </span>
                                            </a>)}
                                    </div>
                                </div>
                                <div className="fwc-col-12 sm-12 headings">

                                    <h4>
                                        <span>  {pageContent?.FAQ_HEADING}</span>
                                    </h4>

                                    <div className='links-gap'>
                                        {pageContent?.FAQs && (
                                            <a
                                                href={pageContent?.FAQs}
                                                className="insurance-plans-hyper-link"
                                                id={'faqs-link'}
                                                target="_blank"
                                            >
                                                <span>
                                                    <span className='link-text'> {pageContent?.FAQ_LINK}</span>
                                                    <span id="faq-link-icon" className="sae-icon sae-icon-external-link link-icon" />
                                                </span>
                                            </a>)}
                                    </div>
                                </div>

                                <div className="fwc-col-12 sm-12 headings">

                                    <h4>
                                        <span>  {pageContent?.GLOSSARY_HEADING}</span>
                                    </h4>
                                    <div>
                                        <span>{pageContent?.GLOSSARY_DESC}</span>

                                    </div>
                                    <div>
                                        <span>{pageContent?.GLOSSARY_DESC_1}</span>

                                    </div>
                                    <div className='links-gap'>
                                        {pageContent?.GLOSSARY && (
                                            <a
                                                href={pageContent?.GLOSSARY}
                                                className="insurance-plans-hyper-link"
                                                id={'glossary-link'}
                                                target="_blank" >
                                                <span>
                                                    <span className='link-text'>{pageContent?.GLOSSARY_LINK}</span>
                                                    <span id="glossary-link-icon" className="sae-icon sae-icon-external-link link-icon" />
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ModalSlide.Body>

                    <ModalSlide.Footer>
                        <Button
                            onClick={() => {
                                handleSliderClose();
                            }}
                            data-testid="back-button"
                        >
                            {pageContent?.BACK_BUTTON_LABEL}
                        </Button>

                    </ModalSlide.Footer>
                </ModalSlide>
            )}
        </>
    );

}

export default InsuranceBasics;