const ALPHA_NUMERIC_REGX: RegExp = /^[a-zA-Z0-9@]+$/;

const INPUT_TYPES = {
  USERNAME: 'USERNAME',
  PASSWORD: 'PASSWORD',
  CONFIRMPASSWORD: 'CONFIRMPASSWORD'
};
const USERNAME_VALIDATION_CHECK = (Strings: any) => {
  let usernamecheck = [
    {
      id: 'rule1',
      isPassed: false,
      label: Strings?.USER_NAME_CHECK?.RULE1,
      regex: /^.{6,19}$/
    },
    // {
    //   id: "rule2",
    //   isPassed: false,
    //   label: Strings?.USER_NAME_CHECK?.RULE2,
    //   regex: /(?=.*[a-z])(?=.*\d)/i,
    // },
    {
      id: 'rule3',
      isPassed: false,
      label: Strings?.USER_NAME_CHECK?.RULE3,
      regex: /^\S+$/
    },
    {
      id: 'rule4',
      isPassed: false,
      label: Strings?.USER_NAME_CHECK?.RULE8,
      regex: /^\S+$/
    },
    {
      id: 'rule5',
      isPassed: false,
      label: Strings?.USER_NAME_CHECK?.RULE4,
      regex: /^(?![a-zA-Z]{2}\d+$).*/
    },
    {
      id: 'rule6',
      isPassed: false,
      label: Strings?.USER_NAME_CHECK?.RULE5,
      regex: /^(?![0-9]).*/
    },
    {
      id: 'rule7',
      isPassed: false,
      label: Strings?.USER_NAME_CHECK?.RULE6,
      regex: /^.*[^\\.]\s*$/
    },
    {
      id: 'rule8',
      isPassed: false,
      label: Strings?.USER_NAME_CHECK?.RULE7,
      regex: /^[^#=\]\[?\]/\\|&;%'",<>()+* ,]+$/
    }
  ];
  return usernamecheck;
};

const PASSWORD_VALIDATION_CHECK = (Strings: any) => {
  let passwordcheck = [
    {
      id: 'rule1',
      isPassed: false,
      label: Strings?.PASSWORD_CHECK?.RULE1,
      regex: /^.{8,20}$/
    },
    {
      id: 'rule2',
      isPassed: false,
      label: Strings?.PASSWORD_CHECK?.RULE2,
      regex: /[a-zA-Z].*\d|\d.*[a-zA-Z]/
    },
    {
      id: 'rule3',
      isPassed: false,
      label: Strings?.PASSWORD_CHECK?.RULE3,
      regex: /^\S+$/
    },
    {
      id: 'rule4',
      isPassed: false,
      label: Strings?.PASSWORD_CHECK?.RULE4,
      regex: /^S+$/
    },
    {
      id: 'rule5',
      isPassed: false,
      label: Strings?.PASSWORD_CHECK?.RULE5,
      regex: /^(?!.*(.).*\1\1).+$/
    },
    {
      id: 'rule6',
      isPassed: false,
      label: Strings?.PASSWORD_CHECK?.RULE6,
      regex: /^[^&<>\\]*$/
    },
    {
      id: 'rule7',
      isPassed: false,
      label: Strings?.PASSWORD_CHECK?.RULE7,
      regex: /^[a-zA-Z0-9_!#$%^*()+,.?:;{}\[\]\|@]*$/
    }
  ];

  return passwordcheck;
};
export { ALPHA_NUMERIC_REGX, INPUT_TYPES, PASSWORD_VALIDATION_CHECK, USERNAME_VALIDATION_CHECK };
