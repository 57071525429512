const ALPHA_NUMERIC_REGX: RegExp = /^[a-zA-Z0-9@]+$/;
const ALPHA_REGX_WITH_SPACE: RegExp = /^[a-zA-Z\s]+$/;
const NUMBER_REGX: RegExp = /^[0-9]+$/;
const NUMBER_REGX_DECIMAL: RegExp = /^\d*\.?\d*$/;
const PHONE_FORMATTED: RegExp = /^(\(\d{3}\)\s?\d{3}-\d{4}|\d{3}-\d{3}-\d{4}|\d{3}\s\d{3}\s\d{4}|\d{10})$/;
const EMAIL_REGX_FOR_ATK = /^([^\.](?!.*\.{2})[a-zA-Z0-9._%+-]+(?<!\.)(?<!\.{2})@(?!.*\.{2})[a-zA-Z0-9.-]+(?<!\.)(?<!\.{2})\.[a-zA-Z]{2,}(,\s*)?)*,?$/;
const ALPHA_REGX_WITH_SPACE_HYPEN: RegExp = /^[a-zA-Z\s-]+$/;
const US_ZIP_CODE_REGX: RegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
const CURRENCY_FORMAT = /(\d)(?=(\d{3})+(?!\d))/g;

const INPUT_TYPES = {
  USERNAME: 'USERNAME',
  PASSWORD: 'PASSWORD'
};

const USER_DOMAINS = {
  APP: 'ACA',
  MARKET_SEGMENT: 'UNDER_65',
  MARKET_SEGMENT_FOR_PLAN_SUMMARY: 'UNDER65',
  ROLE: 'SHOPPER', //May change
  BRAND: 'ABCBS',
  WELLPOINT_BRAND: 'WLP'
};

const ERROR_ALERT_CONFIG = {
  ERROR: 'error',
  TYPE: 'info',
  AREA: 'global'
};

const DATE_FORMATS = {
  MMDDYYYY: 'MM/DD/YYYY',
  YYYYMMDD: 'YYYY-MM-DD',
  DDMMYYYY: 'DD/MM/YYYY',
  YYYYMMDD_WITH_SLASH: 'YYYY/MM/DD',
  YYYYMMDDHHMMSS: 'YYYY-MM-DDTHH:mm:ss'
};
const DATE_BEFORE_1900 = '1900/01/01';
const YES = 'YES';
const ROWS_PER_PAGE = '20';
const ZIP_CODE_VALIDATION_SUCCESS_CODE = 'VALIDTZIP000';

const REMEMBER_MY_DEVICE_YES = 'Y';

const REMEMBER_MY_DEVICE_NO = 'N';

const NONWLP = 'NONWLP';

const PLAN_TYPES = {
  MEDICAL: 'M',
  DENTAL: 'D',
  VISION: 'V',
  MDV: 'MDV'
};

const PLAN_TYPES_CONTENT = {
  M: 'MEDICAL',
  D: 'DENTAL',
  V: 'VISION',
  MDV: 'MEDICALDENTALVISION'
};

const MEDICAL_PLAN_TYPES = ['M', 'MDV'];
const DENTAL_PLAN_TYPES = ['D', 'V'];

const RELATIONSHIP = {
  CHILD: 'CHILD',
  PARENT: 'PARENT',
  STEPPARENT: 'STEPPARENT',
  PARENTINLAW: 'PARENTINLAW',
  OTHER: 'OTHER'
};

const PROFILETYPES = {
  PROFILE: 'profile',
  POLICY: 'policy'
};

const APPLICANT_TYPES = {
  PRIMARY: 'PRIMARY',
  SPOUSE: 'SPOUSE',
  DEPENDENT: 'DEPENDENT',
  DOMESTICPARTNER: 'DOMESTICPARTNER',
  SELF: 'SELF',
  PARENT: 'PARENT',
  STEPPARENT: 'STEPPARENT',
  PARENTINLAW: 'PARENTINLAW',
  CHILD: 'CHILD',
  OTHER: 'OTHER'
};

const PLAN_TYPES_NAMES = {
  MEDICAL: 'MEDICAL',
  DENTAL: 'DENTAL',
  VISION: 'VISION',
  LIFE: 'LIFE',
  MEDICAL_PRODUCT: 'MEDICAL_PRODUCT',
  DENTAL_PRODUCT: 'DENTAL_PRODUCT',
  VISION_PRODUCT: 'VISION_PRODUCT'
};

const PLAN_TYPES_ORDER = {
  MEDICAL: 1,
  DENTAL: 2,
  VISION: 3
};

const ATK_ROLES_FOR_ROLE = ['HPA', 'TELESALES', 'TELEASSISTANT'];

const STATE_CODES = {
  CO: 'CO',
  UNKNOWN: 'UNKNOWN',
  ME: 'ME',
  VA: 'VA',
  FL: 'FL',
  MD: 'MD',
  TX: 'TX',
  NY: 'NY'
};

const LOGIN_TYPES = {
  MAC_LOGIN: 'MAC-LOGIN'
};

const PLAN_TYPES_ARRAY = ['MEDICAL', 'DENTAL'];

const COVERAGE_EFFECTIVE_CONSTS = ['OffExchange', 'OffExDental'];

const TOBACCO_STATE_CODES = ['MO', 'NH', 'WI', 'IN', 'CO', 'CT', 'GA', 'KY', 'NV'];
const LQE_STATES = ['CT', 'GA', 'IN', 'KY', 'ME', 'MO', 'NH', 'NV', 'OH', 'VA', 'WI'];
const LQE_NY_STATE = 'NY';

const PARENT_VALUES = ['PARENT', 'STEPPARENT', 'PARENTINLAW'];

const CALIFORNIA_CODE = 'CA';
const COLARADO_CODE = 'CO';
const GEORGIA_CODE = 'GA';
const FINDCARE_URL_ANTHEM = 'https://www.anthem.com/find-care/';
const FINDCARE_URL_WELLPOINT = 'https://www.wellpoint.com/find-care/';
const FOOTER_LINK_URL = {
  ABOUT_US: 'https://www.anthem.com/about',
  LEGAL: 'https://www.anthem.com/terms-of-use',
  PRIVACY: 'https://www.anthem.com/privacy',
  PRESS_ROOM: 'https://www.anthem.com/press/',
  CAREERS: 'https://careers.elevancehealth.com/en'
};

const ROLE_ALL = 'ALL';
const MQUOTINGFLAG = 'MQuoting';

const BCBS_URL = 'https://www.bcbs.com/';
const BSCS_LINK_TEXT = 'www.bcbs.com';

const OVERVIEW_RATING_URL = 'https://www.healthcare.gov/quality-ratings/?tabId=';

const PAGE_IDS = {
  DRUG_LIST_ALL: 'druglistall',
  PLAN_SELECTION_SUMMARY: 'planselectionsummary',
  BASIC_LANDING: 'basiclanding',
  PRODUCT_BROUCHER_LINK_ALL: 'productbroucherlinkall'
};

const PAGE_BLOCKS = {
  DRUG_LIST: 'druglist',
  COVERAGE_TYPE: 'coveragetype',
  PRODUCT_BROUCHER_LINK: 'productbroucherlink',
  ATK_BASIC_DEMOGRAPHIC: 'atkBasicDemographic'
};

const SCREEN_NAMES = {
  SUBSIDY_BLOCK_FORM: 'subsidyBlockForm',
  CONTACT_US: 'ContactUs',
  REGISTRATION: 'Registration',
  BASICS_LANDING: 'BasicsLanding',
  AGENT_HCID_LANDING: 'agentBasicsHcidLanding',
  AGENT_LANDING: 'AgentLanding',
  DENTAL_PLANS: 'DentalPlans',
  LOGIN_PAGE: 'login',
  VISION_PLANS: 'VisionPlans',
  VISION_PLAN_DETAILS: 'VisionPlanDetails',
  MEDICAL_PLAN_DETAILS: 'MedicalPlanDetails',
  PROVIDER_DETAILS_SLIDER: 'ProviderDetails',
  DENTAL_DETAILS_SCREEN_NAME: 'dentalPlanDetails',
  GLOBAL_HEADER: 'globalHeader',
  BASICS: 'Basics',
  INSURANCE_BASICS: 'insuranceBasics',
  PUBLIC_NAV_BAR: 'publicNavigationBar',
  GLOBAL_FOOTER: 'GlobalFooter',
  LEARN_MORE_MODAL: 'LearnMoreModal',
  MAC_LOGIN: 'MacLogin',
  AGENT_BROKER_LOGIN: 'AgentBrokerLogin',
  EMAIL_PRINT: 'EmailPrint',
  ABOUT_YOUR_DENTAL_PLAN: 'aboutYourDentalPlan',
  ABOUT_YOUR_PLAN_POPUP: 'aboutYourPlanPopup',
  DENTAL_APPLICANTS_POPUP: 'dentalApplicantsPopup',
  DENTAL_PLAN_APPLICANTS_POPUP: 'dentalPlanApplicantsPopup',
  VISION_PLAN_APPLICANTS_POPUP: 'visionPlanApplicantsPopup',
  VISION_APPLICANTS_POPUP: 'visionApplicantsPopup',
  LOG_OUT_POPUP: 'logoutConfirmPopup',
  QUOTE_CONFLICT_POPUP: 'quoteConflictPopup',
  COMPARE_SLIDER: 'compareSlider',
  COMPARE_TRAY: 'compareTray',
  SELECTED_PLAN: 'selectedPlan',
  ESTIMATESAVINGS: 'EstimateSavingsPage',
  ZIPCODE: 'ZipCode',
  ACCOUNT_RECOVERY: 'accountRecovery',
  HELP_ME_CHOOSE: 'HelpMeChoose',
  APPLICANT_INFO: 'ApplicantInfo',
  PASSWORD_CREATION: 'PasswordCreation',
  PERSONAL_INFO: 'PersonalInfo',
  FIND_YOUR_DOCTOR: 'FindYourDoctor',
  FIND_YOUR_EYE_DOCTOR: 'FindYourEyeDoctor',
  FIND_YOUR_DENTIST: 'findYourDentist',
  HELP_ME_CHOOSE_POPUP: 'helpMeChoosePopup',
  MEDICATIONS: 'Medications',
  PLAN_SELECTION_SUMMERY: 'PlanSelectionSummary',
  TWO_FA: 'twoFA',
  DASHBOARD: 'dashboard',
  DASHBOARD_CARD: 'dashboardCard',
  UPDATE_PERSONAL_INFO: 'updatePersonalInfo',
  UPDATE_SECURITY_QA: 'updateSecurityQuestions',
  CHANGE_PASSWORD: 'changePassword',
  MEDICAL_PLANS: 'medicalPlans',
  SECURITY_QUESTIONS: 'securityQuestions',
  PRODUCT_SELECTION: 'ProductSelection',
  ATK_SESSION_END: 'AtkSessionEnd',
  DELETEAPPLICATIONCONTENT: 'deleteApplicationPopup',
  UPDATEPROFILE: 'updateProfile',
  PLAN_RECOMMENDATIONS: 'PlanRecommendations',
  REVIEW_PLANS: 'ReviewPlans',
  ATKPLAN_SELECTION_SUMMARY: 'AtkPlanSelectionSummary',
  PROPOSAL_QUOTE_OVERVIEW: 'ProposalQuoteOverview',
  PROPOSAL_PLAN_DETAILS: 'ProposalPlanDetails',
  AGENT_CONFLICT_POPUP: 'AgentConflictPopup',
  STATE_BASED_CONFIG: 'stateBasedQuoteApply'
};

const AEM_PATHS = {
  WELLPOINT_REGISTERATION: '/content/ios/wellpoint/us/en/individuals/profile/registration',
  ANTHEM_REGISTERATION: '/content/ios/anthem/us/en/individuals/profile/registration',
  ENGLISH: 'en',
  SPANISH: 'es'
};

const GENDER = {
  MALE: 'M',
  FEMALE: 'F',
  OTHER: 'X'
};

const LANGUAGE_CODES = {
  ENGLISH: 'en_US',
  EN: 'en'
};
const BRAND_NAMES = {
  ABCBS: 'ABCBS',
  ABC: 'ABC',
  WELLPOINT_BRAND: 'WLP'
};
const COVERAGE_TYPES = {
  DENTAL_WITH_VISION: 'D'
};

const NAV_PATHS = {
  HOME: '/individuals/',
  INDIVIDUALS_HOME: '/individuals/home',
  INDIVIDUALS: '/individuals',
  INDIVIDUALS_ES: '/es/individuals/',
  INDIVIDUALS_ES_HOME: '/es/individuals/home',
  BASICS_LANDING: '/individuals/shop/basics',
  MEDICAL_LISTING: '/individuals/shop/basics/medical-plans',
  DENTAL_LISTING: '/individuals/shop/basics/dental-plans',
  VISION_LISTING: '/individuals/shop/basics/vision-plans',
  ESTIMATE_COST_SAVINGS: '/individuals/shop/basics/estimate-cost-savings',
  MAC_LOGIN: '/individuals/profile/mac-login',
  MAC_LOGIN_WITH_CREDS: '/individuals/profile/mac-login/',
  PLAN_SUMMARY: '/individuals/shop/basics/plan-selection-summary',
  CREDIT_CALCULATOR: '/individuals/shop/basics/credit-calculator',
  HELP_ME_CHOOSE: '/individuals/shop/basics/help-me-choose',
  GUEST_LOGIN: '/individuals/profile/guest-login',
  REGISTRATION: '/individuals/profile/registration',
  FORGOT_PASSWORD: '/individuals/profile/forgot-password',
  FORGOT_USERNAME: '/individuals/profile/forgot-username',
  DASHBOARD: '/individuals/secure/profile/dashboard',
  STANDALONE_LOGIN: '/individuals/profile/login-page',
  OPTIONS: '/individuals/shop/basics/options',
  MEMBER_SSO: '/individuals/secure/sso/member',
  AGENT_SSO: '/individuals/secure/sso/agent',
  PLAN_RECOMMENDATIONS: '/individuals/plan-recommendations',
  AGENTS_BASICS: '/individuals/secure/agent/shop/basics',
  AGENTS_BASICS_HCID: '/individuals/secure/agent/shop/basics/hcid',
  PRODUCT_SELECTION: '/individuals/secure/agent/shop/basics/product-selection',
  PLAN_SELECTION_NESTED: 'product-selection',
  APP_ERROR_PAGE: '/individuals/app-error',
  UPDATE_PROFILE: '/individuals/secure/profile/update',
  REVIEW_PLANS: '/individuals/secure/agent/shop/basics/review-plans',
  AGENT_PLAN_SELECTION_SUMMARY: '/individuals/secure/agent/shop/basics/plan-selection-summary',
  PROPOSAL_PLAN_DETAILS: '/individuals/proposal/plan-details',
  AGENTS_QUOTE_OVERVIEW: '/individuals/secure/agent/shop/quote-overview',
  ATK_SESSION_END: '/individuals/secure/agent/shop/atk-session-end'
};

const NON_CONFLICT_SCREENS = [
  NAV_PATHS.HOME,
  NAV_PATHS.INDIVIDUALS,
  NAV_PATHS.INDIVIDUALS_HOME,
  NAV_PATHS.BASICS_LANDING,
  NAV_PATHS.ESTIMATE_COST_SAVINGS,
  NAV_PATHS.CREDIT_CALCULATOR,
  NAV_PATHS.MAC_LOGIN,
  NAV_PATHS.MAC_LOGIN_WITH_CREDS,
  NAV_PATHS.STANDALONE_LOGIN
];

const AGENT_TYPES = {
  HPA: 'HPA',
  AGENCY: 'AGENCY',
  DELEGATE: 'DELEGATE',
  GENERAL_AGENT: 'GENERAL_AGENT',
  TELESALES: 'TELESALES',
  TELEASSISTANT: 'TELEASSISTANT'
};

const USER_ROLES = {
  SHOPPER: 'SHOPPER',
  MEMBER: 'MEMBER'
};

const DEFAULT_YEAR = '2025';

const VA_ZIPCODES = [
  '20137',
  '20187',
  '20198',
  '22025',
  '22026',
  '22030',
  '22039',
  '22079',
  '22101',
  '22102',
  '22103',
  '22106',
  '22107',
  '22108',
  '22118',
  '22124',
  '22181',
  '22183',
  '22192',
  '22199',
  '22038',
  '22180',
  '22182',
  '22191',
  '22039',
  '22079',
  '22194',
  '22195'
];

const QUOTE_TYPES = {
  QUOTE: 'QUOTE',
  FAVOURITE: 'FAVOURITE'
};

const QUOTE_STATUS = {
  ACTIVE: 'A'
};

const NAV_HISTORY = {
  HOME: {
    PATH: NAV_PATHS.HOME,
    PAGE_NAME: 'Home'
  },
  BASICS_LANDING: {
    PATH: NAV_PATHS.BASICS_LANDING,
    PAGE_NAME: 'Basics'
  },
  MEDICAL_LISTING: {
    PATH: NAV_PATHS.MEDICAL_LISTING,
    PAGE_NAME: 'MedicalPlans'
  },
  DENTAL_LISTING: {
    PATH: NAV_PATHS.DENTAL_LISTING,
    PAGE_NAME: 'DentalPlans'
  },
  VISION_LISTING: {
    PATH: NAV_PATHS.VISION_LISTING,
    PAGE_NAME: 'VisionPlans'
  },
  ESTIMATE_COST_SAVINGS: {
    PATH: NAV_PATHS.ESTIMATE_COST_SAVINGS,
    PAGE_NAME: 'EstimateCostSavings'
  },
  PLAN_SUMMARY: {
    PATH: NAV_PATHS.PLAN_SUMMARY,
    PAGE_NAME: 'PlanSummary'
  },
  CREDIT_CALCULATOR: {
    PATH: NAV_PATHS.CREDIT_CALCULATOR,
    PAGE_NAME: 'EstimateCostSavings'
  },
  HELP_ME_CHOOSE: {
    PATH: NAV_PATHS.HELP_ME_CHOOSE,
    PAGE_NAME: 'EstimateCostSavings'
  },
  OPTIONS: {
    PATH: NAV_PATHS.OPTIONS,
    PAGE_NAME: 'EstimateCostSavings'
  },
  DASHBOARD: {
    PATH: NAV_PATHS.DASHBOARD,
    PAGE_NAME: 'DashBoard'
  }
};

const GLOBAL_STATUS = {
  COMPLETED: 'COMPLETED',
  INPROGRESS: 'INPROGRESS'
};

const PATH_TO_PAGE_NAME = {
  [NAV_HISTORY.BASICS_LANDING.PATH]: NAV_HISTORY.BASICS_LANDING.PAGE_NAME,
  [NAV_HISTORY.ESTIMATE_COST_SAVINGS.PATH]: NAV_HISTORY.ESTIMATE_COST_SAVINGS.PAGE_NAME,
  [NAV_HISTORY.MEDICAL_LISTING.PATH]: NAV_HISTORY.MEDICAL_LISTING.PAGE_NAME,
  [NAV_HISTORY.DENTAL_LISTING.PATH]: NAV_HISTORY.DENTAL_LISTING.PAGE_NAME,
  [NAV_HISTORY.VISION_LISTING.PATH]: NAV_HISTORY.VISION_LISTING.PAGE_NAME,
  [NAV_HISTORY.PLAN_SUMMARY.PATH]: NAV_HISTORY.PLAN_SUMMARY.PAGE_NAME,
  [NAV_HISTORY.HOME.PATH]: NAV_HISTORY.HOME.PAGE_NAME,
  [NAV_HISTORY.CREDIT_CALCULATOR.PATH]: NAV_HISTORY.CREDIT_CALCULATOR.PAGE_NAME,
  [NAV_HISTORY.HELP_ME_CHOOSE.PATH]: NAV_HISTORY.HELP_ME_CHOOSE.PAGE_NAME,
  [NAV_HISTORY.OPTIONS.PATH]: NAV_HISTORY.OPTIONS.PAGE_NAME,
  [NAV_HISTORY.DASHBOARD.PATH]: NAV_HISTORY.DASHBOARD.PAGE_NAME
};

const PAGE_TO_PATH_NAME = {
  [NAV_HISTORY.BASICS_LANDING.PAGE_NAME]: NAV_HISTORY.BASICS_LANDING.PATH,
  [NAV_HISTORY.ESTIMATE_COST_SAVINGS.PAGE_NAME]: NAV_HISTORY.ESTIMATE_COST_SAVINGS.PATH,
  [NAV_HISTORY.MEDICAL_LISTING.PAGE_NAME]: NAV_HISTORY.MEDICAL_LISTING.PATH,
  [NAV_HISTORY.DENTAL_LISTING.PAGE_NAME]: NAV_HISTORY.DENTAL_LISTING.PATH,
  [NAV_HISTORY.VISION_LISTING.PAGE_NAME]: NAV_HISTORY.VISION_LISTING.PATH,
  [NAV_HISTORY.PLAN_SUMMARY.PAGE_NAME]: NAV_HISTORY.PLAN_SUMMARY.PATH,
  [NAV_HISTORY.HOME.PAGE_NAME]: NAV_HISTORY.HOME.PATH,
  [NAV_HISTORY.DASHBOARD.PAGE_NAME]: NAV_HISTORY.DASHBOARD.PATH
};

const NO = 'NO';

const DASHBOARD_STATUS = { INPROGRESS: 'In Progress', ENROLLED: 'You’re Enrolled!', ELGV: 'Eligibility Review', SUBMITTED: 'Application Submitted' };
const CONFLICT_USE_CASES = {
  QUOTE: 'Quote',
  APPLICATION: 'Application'
};

const QUESTION_CARDS_URL_ANTHEM = '/content/ios/anthem/us/en/individuals/jcr:content/root/responsivegrid/homepagequestioncard.model.json';
const QUESTION_CARDS_URL_WELLPOINT = '/content/ios/wellpoint/us/en/individuals/jcr:content/root/responsivegrid/homepagequestioncard.model.json';
const HERO_CARD_URL_ANTHEM = '/content/ios/anthem/us/en/individuals/jcr:content/root/responsivegrid/hero.model.json';
const HERO_CARD_URL_WELLPOINT = '/content/ios/wellpoint/us/en/individuals/jcr:content/root/responsivegrid/hero.model.json';

const NO_REPLY = 'IOS-noreply@anthem.com';
const NO_REPLY_WELLPOINT = 'IOS-noreply@wellpoint.com';

const ISSUEDBY_STATECODES = ['CA', 'VA'];

const FINDCARE_ALPHAPREFIX = 'https://www.anthem.com/find-care/?alphaprefix=';

const QUOTE_SUMMARY = 'quoteSummary';

const PLAN_SUMMARY_STR = 'planSummary';

const MEDICAL_PLAN_LIST = 'medicalplanList';

const PLAN_LIST = 'planList';

const REQUESTED_FROM = 'Requested Information from';
const CONFLICT_OPTION_LABELS = {
  OPTION1: 'Option1',
  OPTION2: 'Option2'
};

const NV_STATE = 'NV';

const PROPOSAL_REDIRECTION_MARKET_PLACE_STATES = ['ME', 'VA'];
const NON_FFM_STATES = ['CA', 'NY', 'CO', 'CT', 'VA', 'ME', 'NV'];

const NON_FFM_REC_STATES = ['CA', 'NY', 'CO', 'CT', 'NV'];

const FFM_STATES = ['MO', 'OH', 'NH', 'WI', 'IN'];

const MACFLOW_POPUP_TYPE = {
  DENTALWITHDEPENDENTS: 'DENTAL_WITH_DEPENDENTS',
  DENTALWITHOUTDEPENDENTS: 'DENTAL_WITHOUT_DEPENDENTS',
  DENTALWITHOUTPLANWITHDEPENDENTS: 'DENTAL_WITHOUT_PLANWITH_DEPENDENTS',
  VISIONWITHDEPENDENTS: 'VISION_WITH_DEPENDENTS',
  VISIONWITHOUTDEPENDENTS: 'VISION_WITHOUT_DEPENDENTS',
  VISIONWITHOUTPLANWITHDEPENDENTS: 'VISION_WITHOUT_PLANWITH_DEPENDENTS',
  VISIONDEPENDENTMODAL: 'VISION_DEPENDENT_MODAL',
  DENTALDEPENDENTMODAL: 'DENTAL_DEPENDENT_MODAL'
};
const APPLICATION_STATUSES = {
  INPROGRESS: 'INPROGRESS',
  SUBMITREJECT: 'SUBMITREJECT',
  ELIGRECV: 'ELIGRECV',
  PENDATTESTATION: 'PENDATTESTATION',
  PENDING: 'PENDING',
  SUBMITTED: 'SUBMITTED',
  APPROVED: 'APPROVED',
  PENDINGVALIDATION: 'PENDINGVALIDATION',
  CANCELLED: 'CANCELLED',
  EXPIRED: 'EXPIRED',
  ADDINFOREQD: 'ADDINFOREQD',
  QENOTVALID: 'QENOTVALID',
  SEPAPPROVED: 'SEPAPPROVED',
  WEMCANCELLED: 'WEMCANCELLED'
};
const IN_PROGRESS_APPLICATION_STATUSES = [
  APPLICATION_STATUSES.INPROGRESS,
  APPLICATION_STATUSES.SUBMITREJECT,
  APPLICATION_STATUSES.PENDATTESTATION,
  APPLICATION_STATUSES.PENDING
];

const SUBMITTED_APPLICATION_STATUSES = [
  APPLICATION_STATUSES.APPROVED,
  APPLICATION_STATUSES.SUBMITTED,
  APPLICATION_STATUSES.PENDINGVALIDATION,
  APPLICATION_STATUSES.ELIGRECV
];

const CANCELLED_APPLICATION_STATUSES = [APPLICATION_STATUSES.CANCELLED, APPLICATION_STATUSES.EXPIRED];

const MARKET_VALUE = 'MARKET';

const COMPARE_PLANS = 'comparePlans';

const GUEST_LOGIN_URL_PARAMS = {
  ACN: 'acn',
  STATE: 'state'
};

const APPLICATION_TYPE = {
  ON_EXCHANGE: 'ONEXCHANGE',
  OFF_EXCHANGE: 'OFFEXCHANGE',
  TRANSPLAN: 'TRANSPLAN',
  PAYMENT: 'PAYMENT',
  COLORADO_CONNECT: 'COLORADOCONNECT'
};

const AGENT_ROLES = {
  CONSUMER: 'CONSUMER',
  ASSISTANT: 'ASSISTANT',
  DELEGATE: 'DELEGATE',
  TELEASSISTANT: 'TELEASSISTANT',
  HPA: 'HPA',
  AGENT: 'AGENT',
  BROKER: 'BROKER',
  DIRECTSALES: 'DIRECTSALES',
  CAPTIVEAGENT: 'CAPTIVEAGENT'
};

const SET_APPLICATION_CONSTANTS = {
  YES: 'YES',
  NO: 'NO',
  UPDATE: 'UPDATE',
  HOME: 'HOME',
  KY: 'KY',
  ENGLISH: 'ENGLISH',
  SUCCESS: 'SUCCESS',
  OLS: 'OLS',
  APPLICANT: 'APPLICANT',
  PRIMARY: 'PRIMARY',
  DELETE: 'DELETE'
};

const SET_BRAND_NAMES = {
  BCC: 'BCC',
  ANTHEM: 'ANTHEM'
};

const ENTITY_TYPES = {
  DRUG: 'DRUG',
  PROVIDER: 'PROVIDER',
  HMC: 'HMC'
};

const BOOLEAN_VALUES = {
  TRUE: 'true',
  FALSE: 'false'
};

const YES_NO_VALUES = {
  YES: 'Y',
  NO: 'N'
};

const ME_REDIRECTION_URL = 'https://www.coverme.gov/';
const VA_REDIRECTION_URL = 'https://www.marketplace.virginia.gov/';

const APPLICATION_TRANSFER_REDIRECTION_USE_CASES = {
  TRANSFER_FLOW: 'Transfer-flow'
};

const MARKET_TAGS = {
  MARKET: 'M',
  OFF_MARKET: 'OM',
  COLORADO: 'CC'
};

const FORGOT_PASSWORD_REDIRECTION_URLS = {
  NEW_YORK: 'https://www.anthembluecross.com/forgot-username-password/',
  CALIFORNIA: 'https://www.anthem.com/ca/forgot-username-password/',
  ALL_OTHER_STATES: 'https://www.anthem.com/forgot-username-password/'
};

const REGISTRATION_REDIRECTION_URLS = {
  NEW_YORK: 'https://www.anthembluecross.com/register/',
  CALIFORNIA: 'https://www.anthem.com/ca/register/',
  ALL_OTHER_STATES: 'https://www.anthem.com/register/'
};

const PTB_CONTACT_US_URL = 'https://brokerportal.anthem.com/apps/ptb/public/contactus';

const ATK_APPLY_NOW_AVAILABLE_STATES = ['FL', 'TX'];

const ATK_APPLY_NOW_NOT_AVAILABLE_STATES_FOR_ANTHEM = ['CO', 'CT', 'NV', 'NY', 'CA'];

const ATHENTICATE_AGENT_RESPONSE_CODE = {
  SUCCESS: 'AUTHENTICATEAGENT000'
};

const SEND_PROPOSAL_RESPONSE_CODE = {
  SUCCESS: 'SENDPROPOSAL000'
};

const DEFAULT_CAMPAIGNIDS = {
  CA: 'DY88',
  CO: 'DY89',
  CT: 'DY90',
  GA: 'DY91',
  IN: 'DY92',
  KY: 'DY93',
  ME: 'DY94',
  MO: 'DY95',
  NH: 'DY96',
  NV: 'DY97',
  NY: 'DY98',
  OH: 'DY99',
  VA: 'DZ00',
  WI: 'DZ01'
};

const WELLPOINT_MD_STATE = 'MD';

const WELLPOINT_FOR_EMAIL = 'Wellpoint';

const ATK_PRINT_TEMPLATENAME = 'atkPrintProposal';

const ATK_PUBLIC_TEMPLATENAME = 'planBenefit';

const AGENT_PARENTTIN_CONSTANTS = {
  PARENT_TIN_1E: '000000001E',
  PARENT_TIN_5E: '00000000005E',
  ENCRYPTEDTIN_CAPTIVEAGENT: 'CCDFGHJKLY',
  ENCRYPTEDTIN_DIRECTSALES: 'HCDFGHJKLY'
};

const WELLPOINT_STATES = ['FL', 'TX', 'MD', 'UNKNOWN'];

const ANTHEM_STATES = ['CA', 'CO', 'CT', 'GA', 'IN', 'KY', 'ME', 'MO', 'NH', 'NV', 'NY', 'OH', 'VA', 'WI', 'UNKNOWN'];

const FINDCARE_URL_ALPHAPREFIX_ANTHEM = 'https://www.anthem.com/find-care/?alphaprefix=';
const FINDCARE_URL_ALPHAPREFIX_WELLPOINT = 'https://www.wellpoint.com/find-care/?alphaprefix=';

const SBC_URL_ANTHEM = 'https://www.sbc.anthem.com/dps/CCD';
const SBC_URL_WELLPOINT = ' https://www.sbc.wellpoint.com/dps/CCD';

const AGENT_SELECTION = {
  AGENT_CONNECT: 'agentconnect',
  AGENT_OF_RECORD: 'agentofrecord'
};

const CATASTROPHIC_PLAN = 'CATASTROPHIC';

const PROPOSAL_STATUSES = {
  SENT_TO_CUST: 'SENT_TO_CUST',
  CREATED: 'CREATED',
  APP_STARTED: 'APP_STARTED'
};

const ATK_REDIRECTION_STATES = ['CA', 'CO', 'CT', 'VA', 'ME', 'NV'];

const AGENT_FLOW_CONSTANTS = ['AGENT_TELESALES_PROPOSAL', 'EVENDOR'];

const AGENT_ASSISTED_FLOW = ['AGENT_TELESALES_PROPOSAL', 'EVENDOR', 'SELF_SENT_EMAIL'];

const COVERAGE_PERIOD = {
  OE: 'OE',
  WS: 'WS',
  EQ: 'EQ'
};
const EASY_PRICING_KEY = 'standard';

const USER_NAME_LENGTH = 19;

const NEW_PLAN = 'N';

const NOT_RATED_PLAN = 'R';

const EMAIL_CAMPAIGN_IDS = {
  SELECTED: 'CS',
  NOT_SELECTED: 'CNS'
};

const STATES_TO_DISABLE_EDIT_QUOTE = ['CA', 'CO', 'NV'];

const BUTTON_LABELS = {
  PRINT: 'Print',
  SEND: 'Send',
  EDIT: 'Edit',
  DELETE: 'Delete'
};

export {
  AEM_PATHS,
  AGENT_ASSISTED_FLOW,
  AGENT_FLOW_CONSTANTS,
  AGENT_PARENTTIN_CONSTANTS,
  AGENT_ROLES,
  AGENT_SELECTION,
  AGENT_TYPES,
  ALPHA_NUMERIC_REGX,
  ALPHA_REGX_WITH_SPACE,
  ALPHA_REGX_WITH_SPACE_HYPEN,
  ANTHEM_STATES,
  APPLICANT_TYPES,
  APPLICATION_STATUSES,
  APPLICATION_TRANSFER_REDIRECTION_USE_CASES,
  APPLICATION_TYPE,
  ATHENTICATE_AGENT_RESPONSE_CODE,
  ATK_APPLY_NOW_AVAILABLE_STATES,
  ATK_APPLY_NOW_NOT_AVAILABLE_STATES_FOR_ANTHEM,
  ATK_PRINT_TEMPLATENAME,
  ATK_PUBLIC_TEMPLATENAME,
  ATK_REDIRECTION_STATES,
  ATK_ROLES_FOR_ROLE,
  BCBS_URL,
  BOOLEAN_VALUES,
  BRAND_NAMES,
  BSCS_LINK_TEXT,
  BUTTON_LABELS,
  CALIFORNIA_CODE,
  CANCELLED_APPLICATION_STATUSES,
  CATASTROPHIC_PLAN,
  COLARADO_CODE,
  COMPARE_PLANS,
  CONFLICT_OPTION_LABELS,
  CONFLICT_USE_CASES,
  COVERAGE_EFFECTIVE_CONSTS,
  COVERAGE_PERIOD,
  COVERAGE_TYPES,
  CURRENCY_FORMAT,
  DASHBOARD_STATUS,
  DATE_BEFORE_1900,
  DATE_FORMATS,
  DEFAULT_CAMPAIGNIDS,
  DEFAULT_YEAR,
  DENTAL_PLAN_TYPES,
  EASY_PRICING_KEY,
  EMAIL_CAMPAIGN_IDS,
  EMAIL_REGX_FOR_ATK,
  ENTITY_TYPES,
  ERROR_ALERT_CONFIG,
  FFM_STATES,
  FINDCARE_ALPHAPREFIX,
  FINDCARE_URL_ALPHAPREFIX_ANTHEM,
  FINDCARE_URL_ALPHAPREFIX_WELLPOINT,
  FINDCARE_URL_ANTHEM,
  FINDCARE_URL_WELLPOINT,
  FOOTER_LINK_URL,
  FORGOT_PASSWORD_REDIRECTION_URLS,
  GENDER,
  GEORGIA_CODE,
  GLOBAL_STATUS,
  GUEST_LOGIN_URL_PARAMS,
  HERO_CARD_URL_ANTHEM,
  HERO_CARD_URL_WELLPOINT,
  INPUT_TYPES,
  IN_PROGRESS_APPLICATION_STATUSES,
  ISSUEDBY_STATECODES,
  LANGUAGE_CODES,
  LOGIN_TYPES,
  LQE_NY_STATE,
  LQE_STATES,
  MACFLOW_POPUP_TYPE,
  MARKET_TAGS,
  MARKET_VALUE,
  MEDICAL_PLAN_LIST,
  MEDICAL_PLAN_TYPES,
  ME_REDIRECTION_URL,
  MQUOTINGFLAG,
  NAV_HISTORY,
  NAV_PATHS,
  NEW_PLAN,
  NO,
  NONWLP,
  NON_CONFLICT_SCREENS,
  NON_FFM_REC_STATES,
  NON_FFM_STATES,
  NOT_RATED_PLAN,
  NO_REPLY,
  NO_REPLY_WELLPOINT,
  NUMBER_REGX,
  NUMBER_REGX_DECIMAL,
  NV_STATE,
  OVERVIEW_RATING_URL,
  PAGE_BLOCKS,
  PAGE_IDS,
  PAGE_TO_PATH_NAME,
  PARENT_VALUES,
  PATH_TO_PAGE_NAME,
  PHONE_FORMATTED,
  PLAN_LIST,
  PLAN_SUMMARY_STR,
  PLAN_TYPES,
  PLAN_TYPES_ARRAY,
  PLAN_TYPES_CONTENT,
  PLAN_TYPES_NAMES,
  PLAN_TYPES_ORDER,
  PROFILETYPES,
  PROPOSAL_REDIRECTION_MARKET_PLACE_STATES,
  PROPOSAL_STATUSES,
  PTB_CONTACT_US_URL,
  QUESTION_CARDS_URL_ANTHEM,
  QUESTION_CARDS_URL_WELLPOINT,
  QUOTE_STATUS,
  QUOTE_SUMMARY,
  QUOTE_TYPES,
  REGISTRATION_REDIRECTION_URLS,
  RELATIONSHIP,
  REMEMBER_MY_DEVICE_NO,
  REMEMBER_MY_DEVICE_YES,
  REQUESTED_FROM,
  ROLE_ALL,
  ROWS_PER_PAGE,
  SBC_URL_ANTHEM,
  SBC_URL_WELLPOINT,
  SCREEN_NAMES,
  SEND_PROPOSAL_RESPONSE_CODE,
  SET_APPLICATION_CONSTANTS,
  SET_BRAND_NAMES,
  STATES_TO_DISABLE_EDIT_QUOTE,
  STATE_CODES,
  SUBMITTED_APPLICATION_STATUSES,
  TOBACCO_STATE_CODES,
  USER_DOMAINS,
  USER_NAME_LENGTH,
  USER_ROLES,
  US_ZIP_CODE_REGX,
  VA_REDIRECTION_URL,
  VA_ZIPCODES,
  WELLPOINT_FOR_EMAIL,
  WELLPOINT_MD_STATE,
  WELLPOINT_STATES,
  YES,
  YES_NO_VALUES,
  ZIP_CODE_VALIDATION_SUCCESS_CODE
};
