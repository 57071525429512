import { ChangeEvent } from 'react';
import { USER_DOMAINS } from '../../../../shared/globalConstants';
import { useGlobalStore } from '../../../../store/globalStore';
import { ANSWERFIELDS, ANSWER_POSSIBLE_ERROR, QUESTIONFIELDS, SPECIAL_CHARACTERS } from './constants';

const checkQuestionHasError = (selectedquestions = QUESTIONFIELDS, answers = ANSWERFIELDS) => {
  return Object.values(selectedquestions).some((value) => ['', 'Select'].includes(value)) || Object.values(answers).some((value) => value === '');
};

const checkDuplicateQuestion = (selectedquestions) => {
  if (
    selectedquestions.question1 === selectedquestions.question2 ||
    selectedquestions.question2 === selectedquestions.question3 ||
    selectedquestions.question3 === selectedquestions.question1
  ) {
    return true;
  }
};

const checkAnswerHasError = (showAnswerError = ANSWER_POSSIBLE_ERROR) => {
  let hasError = false;
  Object.values(showAnswerError).forEach((item) => {
    if (Object.values(item).includes(true)) {
      hasError = true;
    }
  });
  return hasError;
};

const getUpdatedQuestions = (questions, e: ChangeEvent<HTMLSelectElement>) => {
  const updatedQuestions = questions.map((question) => {
    if (question.value === e.target.value) {
      question.questionId = e.target.name;
      question.isSelected = true;
    } else if (question.questionId === e.target.name) {
      question.questionId = '';
      question.isSelected = false;
    }
    return question;
  });
  return updatedQuestions;
};

const getUpdatedAnswerErrors = (
  id: string,
  values = [{ label: '', value: '' }],
  answers = { answer1: '', answer2: '', answer3: '' },
  showAnswerError = ANSWER_POSSIBLE_ERROR,
  username: string,
  password: string
) => {
  const questions = values.map((question) => question.value);
  const updatedAnswerError = { ...showAnswerError };
  updatedAnswerError[id] = {
    isempty: answers[id] === '',
    minValueValidation: answers[id]?.length <= 3,
    isSpaceAdded: answers[id]?.startsWith(' ') || answers[id]?.endsWith(' '),
    specialCharacters: SPECIAL_CHARACTERS.test(answers[id]),
    isQuestionAdded: questions.includes(answers[id]),
    isUsernameAdded: answers[id] === username,
    isPasswordAdded: answers[id] === password,
    id: id
  };
  return updatedAnswerError;
};

const getUpdateSecurityQAPayload = (
  username: string,
  webAccountGUID: string,
  shopperID: string,
  role: string,
  changeSecretQuestion: boolean,
  firstQAIndex: number,
  firstSecurityQuestion: string,
  firstSecurityAnswer: string,
  secondQAIndex: number,
  secondSecurityQuestion: string,
  secondSecurityAnswer: string,
  thirdQAIndex: number,
  thirdSecurityQuestion: string,
  thirdSecurityAnswer: string,
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  emailAddress: string,
  emailConsent: string,
  number: string,
  type: string
): UpdateSecurityQAPayload => {
  return {
    userDomain: {
      app: USER_DOMAINS.APP,
      marketSegment: USER_DOMAINS.MARKET_SEGMENT,
      brand: useGlobalStore.getState().brand
    },
    shopperAccount: {
      username: username,
      webAccountGUID: webAccountGUID,
      shopperID: shopperID,
      role: role,
      changeSecretQuestion: changeSecretQuestion,
      secretQuestionAnswers: [
        {
          index: firstQAIndex,
          securityQuestion: firstSecurityQuestion,
          securityAnswer: firstSecurityAnswer
        },
        {
          index: secondQAIndex,
          securityQuestion: secondSecurityQuestion,
          securityAnswer: secondSecurityAnswer
        },
        {
          index: thirdQAIndex,
          securityQuestion: thirdSecurityQuestion,
          securityAnswer: thirdSecurityAnswer
        }
      ]
    },
    shopperProfile: {
      person: {
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dateOfBirth,
        emailAddress: emailAddress,
        emailConsent: emailConsent,
        phone: {
          type: type,
          number: number
        }
      }
    }
  };
};

export { checkAnswerHasError, checkDuplicateQuestion, checkQuestionHasError, getUpdateSecurityQAPayload, getUpdatedAnswerErrors, getUpdatedQuestions };

