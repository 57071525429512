import { StateCreator } from 'zustand';
import { NAV_PATHS } from '../shared/globalConstants';

const INITIAL_PROGRESS_STEPS = [
  { complete: false, label: 'Basics', stepName: 'basics', number: 0, disabled: false, current: false, url: NAV_PATHS.BASICS_LANDING },
  { complete: false, label: 'Savings', stepName: 'estimate', number: 1, disabled: false, current: false, url: NAV_PATHS.ESTIMATE_COST_SAVINGS },
  { complete: false, label: 'Medical', stepName: 'medical', number: 2, disabled: false, current: false, url: NAV_PATHS.MEDICAL_LISTING },
  { complete: false, label: 'Dental', stepName: 'dental', number: 3, disabled: false, current: false, url: NAV_PATHS.DENTAL_LISTING },
  { complete: false, label: 'Vision', stepName: 'vision', number: 4, disabled: false, current: false, url: NAV_PATHS.VISION_LISTING },
  { complete: false, label: 'Summary', stepName: 'summary', number: 5, disabled: false, current: false, url: NAV_PATHS.PLAN_SUMMARY }
];
const MEDICAL_PROGRESS_STEPS = [
  { complete: false, label: 'Basics', stepName: 'basics', number: 0, disabled: false, current: false, url: NAV_PATHS.BASICS_LANDING },
  { complete: false, label: 'Savings', stepName: 'estimate', number: 1, disabled: false, current: false, url: NAV_PATHS.ESTIMATE_COST_SAVINGS },
  { complete: false, label: 'Medical', stepName: 'medical', number: 2, disabled: false, current: false, url: NAV_PATHS.MEDICAL_LISTING },
  { complete: false, label: 'Summary', stepName: 'summary', number: 3, disabled: false, current: false, url: NAV_PATHS.PLAN_SUMMARY }
];
const DENTAL_PROGRESS_STEPS = [
  { complete: false, label: 'Basics', stepName: 'basics', number: 0, disabled: false, current: false, url: NAV_PATHS.BASICS_LANDING },
  { complete: false, label: 'Dental', stepName: 'dental', number: 1, disabled: false, current: false, url: NAV_PATHS.DENTAL_LISTING },
  { complete: false, label: 'Vision', stepName: 'vision', number: 2, disabled: false, current: false, url: NAV_PATHS.VISION_LISTING },
  { complete: false, label: 'Summary', stepName: 'summary', number: 3, disabled: false, current: false, url: NAV_PATHS.PLAN_SUMMARY }
];
const VISION_PROGRESS_STEPS = [
  { complete: false, label: 'Basics', stepName: 'basics', number: 0, disabled: false, current: false, url: NAV_PATHS.BASICS_LANDING },
  { complete: false, label: 'Vision', stepName: 'vision', number: 1, disabled: false, current: false, url: NAV_PATHS.VISION_LISTING },
  { complete: false, label: 'Summary', stepName: 'summary', number: 2, disabled: false, current: false, url: NAV_PATHS.PLAN_SUMMARY }
];

const ATK_PROGRESS_STEPS = [
  { complete: false, label: 'Demographics', stepName: 'demographics', number: 0, disabled: false, current: false, url: NAV_PATHS.AGENTS_BASICS },
  { complete: false, label: 'Plan Selection', stepName: 'planSelection', number: 1, disabled: false, current: false, url: NAV_PATHS.PRODUCT_SELECTION },
  { complete: false, label: 'Summary', stepName: 'summary', number: 2, disabled: false, current: false, url: NAV_PATHS.AGENT_PLAN_SELECTION_SUMMARY }
];

export const createQuoteProgressSlice: StateCreator<QuoteProgressStore> = (set) => ({
  progressSteps: INITIAL_PROGRESS_STEPS,
  medicalSteps: MEDICAL_PROGRESS_STEPS,
  dentalSteps: DENTAL_PROGRESS_STEPS,
  visionSteps: VISION_PROGRESS_STEPS,
  medicalDentalVisionSteps: INITIAL_PROGRESS_STEPS,
  atkSteps: ATK_PROGRESS_STEPS,
  previousStep: '',
  setPreviousStep: (step: string) => set({ previousStep: step }),
  updateProgressSteps: (newProgressSteps: ProgressStep[]) => set({ progressSteps: newProgressSteps }),
  resetProgressSteps: () =>
    set(() => ({
      progressSteps: INITIAL_PROGRESS_STEPS
    }))
});
