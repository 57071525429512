import { LANGUAGE_CODES, PLAN_TYPES_NAMES, USER_DOMAINS } from '../../../shared/globalConstants';
import { formatDateToYYYYMMDD } from '../../../shared/utils/globalUtils';
import { PLAN_TIERS_ALL } from '../medical-plans/constants';
import { getFormattedApplicantDetails, setDomesticPartnerAsSpouse } from '../medical-plans/medicalPlansUtils';

const getSortOptionsWithLabels = (sortOptions: SortOption[], labels: any) => {
  return sortOptions.map((option) => {
    return {
      ...option,
      label: labels[option.key]
    };
  });
};

const getDentalPlanCardLabels = (labels: any): PlanCardLabels => {
  return {
    mostPopular: labels?.MOST_POPULAR,
    recommended: labels?.RECOMMENDED,
    currentCoverage: labels?.CURRENT_COVERAGE,
    monthlyPremium: labels?.MONTHLY_PREMIUM,
    benefitLabelOne: labels?.DEDUCTIBLE,
    benefitLabelTwo: labels?.ANUAL_MAX,
    benefitLabelThree: labels?.OUT_OF_POCKET_MAX,
    referToSbc: labels?.REFER_TO_SBC,
    select: labels?.SELECT,
    deselect: labels?.DESELECT,
    pediatrics: labels?.PEDIATRICS,
    showMore: labels?.SHOW_MORE,
    showLess: labels?.SHOW_LESS,
    addToFavourites: labels?.ADD_TO_FAVOURITES,
    removeFromFavourites: labels?.REMOVE_FROM_FAVOURITES,
    compareEmailPrint: labels?.COMPARE_EMAIL_PRINT,
    monthlyCostBreakdown: labels?.MONTHLY_COST_BREAKDOWN,
    totalPlanCost: labels?.TOTAL_PLAN_COST,
    subsidyApplied: labels?.SUBSIDY_APPLIED,
    totalMemberResponsibility: labels?.TOTAL_MEMBER_RESPONSIBILITY,
    rateNotAvailable: labels?.RATE_NOT_AVAILABLE,
    doctorsListLabel: labels?.DENTISTS_LIST_LABEL,
    medicationsListLabel: labels?.MEDICATIONS_LIST_LABEL,
    doctors: labels?.DENTISTS,
    medications: labels?.MEDICATIONS,
    matchedDoctorsTooltipText: labels?.MATCHED_DOCTORS_TOOLTIP_TEXT,
    transitionCoverage: labels?.TRANSITION_COVERAGE,
    transitionCoverageTooltip: labels?.TRANSITION_PLAN_TOOLTIP_CONTENT
  };
};

const getSelectedPlans = (medicalPlan: SelectedPlan, dentalSelectedPlan: SelectedPlan, visionSelectedPlan: SelectedPlan) => {
  const selectedPlans: Contracts = [];

  if (medicalPlan.plan) {
    selectedPlans.push(medicalPlan.plan);
  }
  if (dentalSelectedPlan.plan) {
    selectedPlans.push(dentalSelectedPlan.plan);
  }
  if (visionSelectedPlan.plan) {
    selectedPlans.push(visionSelectedPlan.plan);
  }

  return selectedPlans;
};

const getDentalPlansPayload = (globalStore: GlobalStore, selectedMedicalPlan: Contract | null): PlanSummaryPayload => {
  let payload: PlanSummaryPayload = {
    brand: globalStore.brand,
    planTypes: [PLAN_TYPES_NAMES.DENTAL],
    metalTierTypes: PLAN_TIERS_ALL,
    marketSegment: USER_DOMAINS.MARKET_SEGMENT_FOR_PLAN_SUMMARY,
    zipCode: globalStore.zipcode,
    county: globalStore.county,
    state: globalStore.state,
    coverageEffectiveDate: formatDateToYYYYMMDD(globalStore.coverageDate), //Update the format
    planYear: globalStore.year,
    applicants: setDomesticPartnerAsSpouse(getFormattedApplicantDetails(globalStore.selectedDentalPlan.applicants ?? globalStore.applicantFormDetails, false)),
    subsidy: {
      subsidyAmt: null,
      costShareReduction: null
    },
    language: LANGUAGE_CODES.EN,
    countyCode: globalStore.countyCode
  };

  if (selectedMedicalPlan) {
    payload.onExchange = selectedMedicalPlan.onExchange;
  }

  return payload;
};

const getCurrentCoverageDentalPlan = (currentCoverages: CurrentCoverages) => {
  return currentCoverages.find((coverage) => coverage.planType === PLAN_TYPES_NAMES.DENTAL);
};

export { getCurrentCoverageDentalPlan, getDentalPlanCardLabels, getDentalPlansPayload, getSelectedPlans, getSortOptionsWithLabels };
