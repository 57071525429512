import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NAV_PATHS } from '../../../shared/globalConstants';
import { resetSessionData, resetUserData } from '../../secure/logout/LogoutPupupUtils';

const AppErrorPage: React.FC = () => {

    const navigate = useNavigate();
    const handleLogoutButton = async () => {
        resetUserData();
        resetSessionData();

    }
    return (<div>Some Went wrong. Please contact admininstrator
        <div onClick={() => { handleLogoutButton(); navigate(NAV_PATHS.INDIVIDUALS) }}>Go To Home</div>
    </div>)
}

export default AppErrorPage;