import { PLAN_TYPES_NAMES } from '../../../shared/globalConstants';
import {
  getAnualMaxValue,
  getContactLensesValue,
  getDeductibleValue,
  getDiagnosticsPreventiveValue,
  getEyeglassFramesValue,
  getEyeglassLensesValue,
  getOutOfPocketMaxValue,
  getPrimaryCareVisitValue,
  getRoutineEyeExamValue
} from '../plan-card/planCardUtils';
import { getOutOfPocketMaxBenefitValueMedical, getTierOneDrugBenefitValue } from '../product-card/productCardUtils';

const getBenefitLabelTwo = (labels: any, productType: string) => {
  switch (productType) {
    case PLAN_TYPES_NAMES.VISION_PRODUCT:
      return labels?.EYE_GLASS_LENSES;
    default:
      return labels?.DEDUCTIBLE;
  }
};

const getProductBenefitTwoDescription = (plan: Plan, showMore: boolean): string => {
  const { planType } = plan;

  switch (planType) {
    case PLAN_TYPES_NAMES.MEDICAL:
      return getDeductibleValue(plan, showMore);
    case PLAN_TYPES_NAMES.DENTAL:
      return getDeductibleValue(plan, showMore);
    case PLAN_TYPES_NAMES.VISION:
      return getEyeglassLensesValue(plan, showMore);
    default:
      return '';
  }
};

const getBenefitLabelThree = (labels: any, productType: string) => {
  switch (productType) {
    case PLAN_TYPES_NAMES.MEDICAL_PRODUCT:
      return labels?.OUT_OF_POCKET_MAX;
    case PLAN_TYPES_NAMES.DENTAL_PRODUCT:
      return labels?.ANNUAL_MAX;
    case PLAN_TYPES_NAMES.VISION_PRODUCT:
      return labels?.ROUTINE_EYE_EXAM;
    default:
      return '';
  }
};

const getProductBenefitThreeDescription = (plan: Plan, showMore: boolean): string => {
  const { planType } = plan;

  switch (planType) {
    case PLAN_TYPES_NAMES.MEDICAL:
      return getOutOfPocketMaxBenefitValueMedical(plan, showMore);
    case PLAN_TYPES_NAMES.DENTAL:
      return getAnualMaxValue(plan, showMore);
    case PLAN_TYPES_NAMES.VISION:
      return getRoutineEyeExamValue(plan, showMore);
    default:
      return '';
  }
};

const getBenefitLabelFive = (labels: any, productType: string) => {
  switch (productType) {
    case PLAN_TYPES_NAMES.MEDICAL_PRODUCT:
      return labels?.PRIMARY_CARE_VISIT;
    case PLAN_TYPES_NAMES.DENTAL_PRODUCT:
      return labels?.OUT_OF_POCKET_MAX;
    case PLAN_TYPES_NAMES.VISION_PRODUCT:
      return labels?.EYE_GLASS_FRAMES;
    default:
      return '';
  }
};

const getProductBenefitFiveDescription = (plan: Plan, showMore: boolean): string => {
  const { planType } = plan;

  switch (planType) {
    case PLAN_TYPES_NAMES.MEDICAL:
      return getPrimaryCareVisitValue(plan, showMore);
    case PLAN_TYPES_NAMES.DENTAL:
      return getOutOfPocketMaxValue(plan, showMore);
    case PLAN_TYPES_NAMES.VISION:
      return getEyeglassFramesValue(plan, showMore);
    default:
      return '';
  }
};

const getBenefitLabelSeven = (labels: any, productType: string) => {
  switch (productType) {
    case PLAN_TYPES_NAMES.MEDICAL_PRODUCT:
      return labels?.TIER_DRUGS;
    case PLAN_TYPES_NAMES.DENTAL_PRODUCT:
      return labels?.DIAGNOSTICS_PREVENTIVE;
    case PLAN_TYPES_NAMES.VISION_PRODUCT:
      return labels?.CONTACT_LENSES;
    default:
      return '';
  }
};

const getProductBenefitSevenDescription = (plan: Plan, showMore: boolean): string => {
  const { planType } = plan;

  switch (planType) {
    case PLAN_TYPES_NAMES.MEDICAL:
      return getTierOneDrugBenefitValue(plan, showMore);
    case PLAN_TYPES_NAMES.DENTAL:
      return getDiagnosticsPreventiveValue(plan, showMore);
    case PLAN_TYPES_NAMES.VISION:
      return getContactLensesValue(plan, showMore);
    default:
      return '';
  }
};

export {
  getBenefitLabelFive,
  getBenefitLabelSeven,
  getBenefitLabelThree,
  getBenefitLabelTwo,
  getProductBenefitFiveDescription,
  getProductBenefitSevenDescription,
  getProductBenefitThreeDescription,
  getProductBenefitTwoDescription
};
