import axiosInstance from '../../../api/axiosWrapper/axiosWrapper';

import { API_NAME_LIST } from '../../../api/api-name-list';

const getProposalPlanInfo = (proposalID) => {
  return axiosInstance.get(API_NAME_LIST.ATK_PROPOSAL_PLAN_INFO + '?proposalId=' + proposalID);
};

const printProposalPlanInfo = (payload: AtkPublicPrintPayload) => {
  return axiosInstance.post(API_NAME_LIST.PUBLIC_ATK_PRINT, payload);
};

export { getProposalPlanInfo, printProposalPlanInfo };
